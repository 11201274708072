import React, { useContext, createContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import useCRUD from '../_Hooks/useCRUD';

const ContextObject = createContext({});

const Context = ({
  data,
  model,
  id,
  observe = null,
  children,
  options = {},
  pathOptions,
  immediatelyLoadData = true,
  ...otherProps
}) => {
  const getById = id ? `/${id}` : '';
  const oldObserve = useRef(null);
  /* data, totalItems, loading, error, setError, setData, handleGet, handleUpdate, handleDelete, handleCreate */
  const useCRUDObject = useCRUD({ model, options, immediatelyLoadData, pathOptions: pathOptions || getById });
  useEffect(() => {
    if (observe && observe !== oldObserve.current) {
      oldObserve.current = observe;
      useCRUDObject.handleGet({ refetchOptions: options }).then();
    }
  }, [observe]);

  const contextData = { ...useCRUDObject, ...(data && { data }), model, ...otherProps };

  return <ContextObject.Provider value={contextData}>{children}</ContextObject.Provider>;
};

const useContextHook = () => useContext(ContextObject);

export { Context, useContextHook };

Context.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.instanceOf(Object),
  options: PropTypes.instanceOf(Object),
  observe: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array, PropTypes.string]),
  pathOptions: PropTypes.string,
  immediatelyLoadData: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  model: PropTypes.string.isRequired
};
Context.defaultProps = {
  options: {},
  observe: null,
  id: null,
  pathOptions: '',
  immediatelyLoadData: true
};
