import PropTypes from 'prop-types';
import React from 'react';
import Modal from './Modal';

const PaymentLoadingModal = ({ open, title }) => {
  return (
    <Modal
      open={open}
      closable={false}
      hideCloseBtn
      hideFooter
      title={title}
      width="500px"
      height="240px"
      loading
      loadingText="Aguarde..."
      zIndex={999}
    />
  );
};

PaymentLoadingModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  open: PropTypes.bool
};

export default PaymentLoadingModal;
