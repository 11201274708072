import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown as AntDropDown, Input, Menu, Button as AntButton } from 'antd';
import styled from 'styled-components';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import Button from '../Button/Button';
import { removeAccents } from '../../lib/helpers/helper';

import { spaces, colors, fonts, breakpoints } from '../../styles/style';
import useCRUD from '../../_Hooks/useCRUD';
import Modal from '../Modal/Modal';
import useViewport from '../../_Hooks/useViewport';
import CenteredLoader from '../Loader/CenteredLoader';

const StyledDropDown = styled(AntDropDown)`
  cursor: pointer;
  padding: ${spaces.space1} ${spaces.space2};
`;

const StyledMenu = styled.div`
  padding: 0;
  max-height: 300px;
  max-width: 500px;
  overflow: hidden auto;
  box-shadow: 0px 0px 20px 0 rgb(0 0 0 / 8%);
  border-radius: ${spaces.space1};

  .ant-dropdown-menu-item {
    &:first-of-type {
      border-radius: ${spaces.space1} ${spaces.space1} 0 0;
    }

    &:last-of-type {
      border-radius: 0 0 ${spaces.space1} ${spaces.space1};
    }

    &:hover {
      background-color: ${colors.neutral90};

      .ant-btn {
        font-weight: 500;
        color: ${colors.neutralSecondary40};
      }
    }

    .ant-btn {
      font-weight: 300;
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    box-shadow: none;
    border: none;
  }
`;

const StyledButton = styled(AntButton)`
  width: ${props => props.width};
  align-items: center;
  justify-content: space-between;
  padding: ${spaces.space0};
  background-position: center;
  cursor: pointer;
  font-family: ${fonts.family};
  font-size: ${fonts.sizeSm};
  border: 1px solid transparent;
  color: ${colors.neutral40};
  height: auto;
  min-width: ${spaces.space5};
  border-radius: ${spaces.space1};
  text-align: left;
  white-space: ${props => (props.$hideOverflow ? 'nowrap' : 'normal')};
  text-overflow: ellipsis;
  overflow: hidden;

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${fonts.sizeLg};
  }

  &:after {
    animation: none !important;
  }

  &:focus {
    color: inherit;
  }

  &:hover {
    color: inherit;
    border: 1px solid ${colors.neutral70};
  }
`;

const StyledItemButton = styled(AntButton)`
  width: 100%;
  display: flex;
  border: none;
  background-color: transparent;
  font-family: ${fonts.family};
  font-size: ${fonts.sizeSm};
  box-shadow: none;

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${fonts.sizeLg};
  }

  &:hover {
    background-color: transparent;
  }
`;

const StyledSpan = styled.span`
  margin-left: ${spaces.space1};
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-left: ${spaces.space1};
  color: ${colors.neutral60};
`;

const SelectDropdown = ({
  data,
  loadList,
  model,
  width = '100%',
  options = {},
  pathOptions,
  onSelect,
  canCreate = true,
  createData = {},
  children,
  IconSelector,
  preventLoad,
  refetch = () => {},
  showClean = true,
  cleanValue = 1,
  hideOverflow = false,
  isOpen,
  onClose = f => f,
  placeholder = 'Opções',
  initialFocus = true,
  ...props
}) => {
  const { list, handleCreate, loading, totalItems, handleGet } = useCRUD({
    model,
    options,
    pathOptions,
    immediatelyLoadData: false
  });

  const offset = useRef(0);
  const [isVisibleClean, setVisibleClean] = useState(false);
  const [isVisible, setVisible] = useState(isOpen);
  const [search, setSearch] = useState('');
  const [create, setCreate] = useState(false);
  const [showRenderModal, setShowRenderModal] = useState(isOpen);
  const [fullList, setFullList] = useState([]);
  const { isMobile } = useViewport(window.innerWidth);

  const loadMore = () => {
    const { where } = options;
    const newOptions = {
      ...options,
      where: {
        ...where,
        ...(search && { ulike: { [`${model}.name`]: `%${removeAccents(search)}%` } })
      },
      offset: offset.current,
      limit: 10
    };
    handleGet({ refetchOptions: { ...newOptions } });
  };

  const [infiniteRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage: totalItems > fullList.length,
    onLoadMore: () => {
      offset.current += 1;
      loadMore();
    },
    disabled: false,
    rootMargin: '0px 0px 0px 0px'
  });

  useEffect(() => {
    setFullList(prev => (offset.current > 1 ? [...prev, ...list] : list));
  }, [list]);

  useEffect(() => {
    if (loadList) {
      const _list = search
        ? loadList?.filter(l => {
            const name = l?.name || l?.label;
            if (name === search) {
              setCreate(false);
            }
            return removeAccents(name)?.includes(removeAccents(search));
          })
        : loadList;

      setFullList(_list);

      return () => {};
    }

    const timer = setTimeout(() => {
      offset.current = 1;
      loadMore();
    }, 400);

    return () => clearTimeout(timer);
  }, [search]);

  const memoList = useMemo(() => {
    setCreate(true);

    return fullList?.length ? fullList : loadList;
  }, [fullList, loadList]);

  const handleSelect = (e, item) => {
    e.stopPropagation();
    onSelect(item);
    setSearch('');
    refetch && refetch();
    setVisible(false);
    setShowRenderModal(false);
  };

  const handleSelectClean = e => {
    e.stopPropagation();
    onSelect({ id: cleanValue, value: cleanValue });
    setSearch('');
    refetch && refetch();
    setVisible(false);
  };

  const handleCreateOption = e => {
    e.stopPropagation();
    return handleCreate({ values: { ...createData, name: search }, refresh: false }).then(newItem =>
      handleSelect(e, { ...newItem, isNewRecord: true })
    );
  };

  const callbackRef = useCallback(inputElement => {
    if (inputElement && initialFocus) {
      setTimeout(() => inputElement.focus(), 100);
    }
  }, []);

  const _menu =
    isVisible || isMobile() ? (
      <StyledMenu ref={rootRef} role="presentation" onClick={e => e.stopPropagation()}>
        <Menu>
          <Menu.Item>
            <div>
              <Input.Search
                ref={callbackRef}
                value={search}
                allowClear
                type="text"
                onChange={e => setSearch(e.target.value)}
              />
            </div>
          </Menu.Item>
          {create && search && canCreate ? (
            <Menu.Item>
              <Button type="link" onClick={handleCreateOption}>
                {`+ criar "${search}"`}
              </Button>
            </Menu.Item>
          ) : null}
          {memoList?.map(item => {
            return (
              <React.Fragment key={item.id || item.value}>
                <Menu.Item>
                  <StyledItemButton onClick={e => handleSelect(e, item)}>
                    {IconSelector && <IconSelector item={item} />}
                    <StyledSpan>{item.name || item.label}</StyledSpan>
                  </StyledItemButton>
                </Menu.Item>
              </React.Fragment>
            );
          })}
          {totalItems > fullList.length && (
            <li ref={infiniteRef}>
              <CenteredLoader />
            </li>
          )}
        </Menu>
      </StyledMenu>
    ) : (
      <StyledMenu />
    );

  return isMobile() ? (
    <>
      <Button
        shape="text"
        ghost
        onClick={e => {
          e.stopPropagation();
          setShowRenderModal(true);
        }}
      >
        {children ? (
          <div role="presentation">{children}</div>
        ) : (
          <>
            {data?.name || data?.label || '-'}
            {isVisibleClean && data?.id !== 1 && showClean && (
              <StyledFontAwesomeIcon icon={faTimes} onClick={e => handleSelectClean(e)} />
            )}
          </>
        )}
      </Button>
      {showRenderModal && (
        <Modal
          title={placeholder}
          open
          hideFooter
          onClose={e => {
            e.stopPropagation();
            setShowRenderModal(false);
            onClose();
          }}
        >
          {_menu}
        </Modal>
      )}
    </>
  ) : (
    <>
      <StyledDropDown
        trigger="click"
        overlay={_menu}
        overlayClassName="c-dropdown"
        slim
        placeholder={placeholder}
        {...props}
        visible={isVisible}
        onVisibleChange={() => {
          setVisible(!isVisible);
          isVisible && onClose();
        }}
      >
        {children ? (
          <span role="presentation" onClick={e => e.stopPropagation()}>
            {children}
          </span>
        ) : (
          <StyledButton
            width={width}
            $hideOverflow={hideOverflow}
            onMouseEnter={() => setVisibleClean(true)}
            onMouseLeave={() => setVisibleClean(false)}
            onClick={e => e.stopPropagation()}
            type="link"
          >
            <>
              {data?.name || data?.label || '-'}
              {isVisibleClean && data?.id !== 1 && showClean && (
                <StyledFontAwesomeIcon icon={faTimes} onClick={e => handleSelectClean(e)} />
              )}
            </>
          </StyledButton>
        )}
      </StyledDropDown>
    </>
  );
};

SelectDropdown.propTypes = {
  data: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  loadList: PropTypes.instanceOf(Array),
  model: PropTypes.string,
  width: PropTypes.string,
  options: PropTypes.instanceOf(Object),
  pathOptions: PropTypes.string,
  onSelect: PropTypes.func,
  canCreate: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  IconSelector: PropTypes.instanceOf(Object),
  createData: PropTypes.instanceOf(Object),
  preventLoad: PropTypes.bool,
  refetch: PropTypes.func,
  showClean: PropTypes.bool,
  cleanValue: PropTypes.number,
  hideOverflow: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  placeholder: PropTypes.string,
  initialFocus: PropTypes.bool
};

export default SelectDropdown;
