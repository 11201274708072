import React from 'react';
import { faPresentation, faTrash, faEdit, faXmark, faInboxOut } from '@fortawesome/pro-regular-svg-icons';
import { faFolderOpen, faUserGroup, faHandshake, faListCheck, faPersonDolly } from '@fortawesome/pro-duotone-svg-icons';
import { store } from '../../config/redux-store';

import { colors } from '../../../styles/style';
import {
  nameAsc,
  nameDesc,
  createdAtAsc,
  createdAtDesc,
  topSale,
  priceAsc,
  priceDesc,
  editItem,
  deleteAction,
  addItem,
  duplicateItem,
  removeItemFromGroup,
  cancelProject,
  duplicateProject,
  finishProject,
  addToMyProducts,
  applyTemplatetoProject,
  bar,
  taskDetails,
  copyLinkButton,
  attachmentButton,
  cancelPayment,
  paidInstallment,
  unpaidInstallment
} from './_dropdown';
import { getPaymentLabel, getViewPaymentUrl } from '../../helpers/payment';

const allDropdown = {
  default: [deleteAction],
  sortCustom: [nameAsc, nameDesc, createdAtAsc, createdAtDesc],
  shareLink: [copyLinkButton],
  shareLinkWithWhatsapp: [
    copyLinkButton,
    {
      button: 'shareWhatsappLink',
      buttonParams: {
        text: true,
        children: 'WhatsApp'
      }
    }
  ],
  task: [
    deleteAction,
    {
      label: 'Duplicar',
      verb: 'post',
      pathOptions: '/duplicate'
    },
    {
      button: 'copyLink',
      buttonParams: {
        text: true,
        children: 'Copiar link'
      }
    },
    taskDetails,
    {
      label: 'Vincular',
      modal: 'linkTaskModal'
    }
  ],
  generalTask: [
    deleteAction,
    {
      label: 'Duplicar',
      verb: 'post',
      pathOptions: '/duplicate'
    },
    copyLinkButton,
    taskDetails
  ],
  sortCatalog: [
    topSale,
    priceAsc,
    priceDesc,
    { ...nameAsc, order: ['name', 'ASC'] },
    { ...nameDesc, order: ['name', 'DESC'] }
  ],
  sortFiles: [
    { ...createdAtDesc, label: 'Mais recente' },
    { ...createdAtAsc, label: 'Mais antigo' },
    { ...nameAsc, order: [['filename', 'ASC']] },
    { ...nameDesc, order: [['filename', 'DESC']] }
  ],
  sortOrderSummary: [createdAtAsc, createdAtDesc],
  instalmentItem: [
    { ...editItem, label: 'Anexo', modalParams: { hideDetails: true } },
    {
      label: 'Ver detalhes',
      modal: 'itemDetailModal',
      modalParams: {
        isInstalment: true,
        model: 'refurbish-item-instalment',
        options: {
          include: [
            {
              model: 'RefurbishItems',
              include: ['supplier', 'refurbish']
            },
            'files'
          ]
        }
      }
    }
  ],
  instalmentParent: [{ ...editItem, label: 'Anexo', modalParams: { hideDetails: true, tab: '1' } }],
  instalmentParentGrouped: [
    { ...editItem, label: 'Anexo', modalParams: { hideDetails: true } },
    {
      label: 'Ver itens desse agrupamento',
      modal: 'modal'
    }
  ],
  instalmentChildren: ({ allowSetPaid, isPaid, allowClone, allowDelete }) => [
    { ...editItem, label: 'Anexo', modalParams: { hideDetails: true, tab: '1', isInstalment: true } },
    ...(allowSetPaid
      ? [
          {
            label: isPaid ? 'Marcar como aguardando solicitação' : 'Marcar como pago',
            verb: 'put',
            values: { status: isPaid ? 1 : 6 }
          }
        ]
      : []),
    ...(allowClone
      ? [
          {
            label: 'Duplicar',
            verb: 'post',
            pathOptions: '/clone'
          }
        ]
      : []),
    ...(allowDelete ? [deleteAction] : [])
  ],
  quickCreateActions: [
    {
      label: 'Projeto',
      id: 'quick-create-project',
      modal: 'templateModal',
      roles: ['project'],
      iconProps: {
        color: colors.neutral60,
        icon: faFolderOpen
      }
    },
    {
      label: 'Oportunidade',
      id: 'quick-create-opportunity',
      redirectTo: '/profissional/oportunidades/novo',
      roles: ['opportunity'],
      iconProps: {
        color: colors.neutral60,
        icon: faHandshake
      }
    },
    {
      label: 'Tarefa',
      id: 'quick-create-task',
      modal: 'editInfoModal',
      roles: ['tasks'],
      iconProps: {
        color: colors.neutral60,
        icon: faListCheck
      }
    },
    {
      label: 'Cliente',
      id: 'quick-create-customer',
      redirectTo: '/profissional/contatos/clientes/novo',
      roles: ['customer'],
      iconProps: {
        color: colors.neutral60,
        icon: faUserGroup
      }
    },
    {
      label: 'Fornecedor',
      id: 'quick-create-supplier',
      redirectTo: '/profissional/contatos/fornecedores/novo',
      roles: ['supplier'],
      iconProps: {
        color: colors.neutral60,
        icon: faPersonDolly
      }
    }
  ],
  templateList: [
    {
      label: 'Duplicar template',
      modal: 'useAsTemplateModal',
      modalParams: {
        model: 'template',
        pathOptions: '/duplicate',
        successText: 'Template duplicado com sucesso',
        template: true,
        modalType: 'Template',
        showTemplateType: true,
        text: `Escolha o que deseja duplicar deste template.`
      }
    },
    deleteAction
  ],
  templateItem: [
    {
      label: 'Duplicar este item',
      verb: 'post',
      pathOptions: '/duplicate'
    },
    editItem,
    {
      label: 'Agrupar item',
      verb: 'post',
      pathOptions: '/bulk'
    },
    deleteAction
  ],
  specificationItem: [
    duplicateItem,
    {
      label: 'Agrupar item',
      verb: 'post',
      pathOptions: '/bulk'
    },
    {
      label: 'Adicionar observações',
      modal: 'editItem',
      id: 'add-notes',
      verb: 'update',
      modalParams: { tab: '1' }
    },
    editItem,
    {
      label: 'Comentar',
      modal: 'editItem',
      id: 'comment-items',
      modalParams: { tab: '3' }
    },
    attachmentButton,
    copyLinkButton,
    deleteAction,
    addToMyProducts
  ],
  templateItemParent: [
    duplicateItem,
    {
      ...deleteAction,
      label: 'Desfazer agrupamento',
      modalParams: {
        text: `Deseja realmente apagar esse item? Todos os sub-itens serão colocados como itens avulsos no template.`
      }
    }
  ],
  specificationParent: [
    duplicateItem,
    { ...editItem, label: 'Anexo', modalParams: { hideDetails: true, tab: '1' } },
    {
      ...deleteAction,
      label: 'Desfazer agrupamento',
      modalParams: {
        text: `Deseja realmente apagar esse item? Todos os sub-itens serão colocados como itens avulsos no orçamento.`
      }
    }
  ],
  templateItemChildren: [duplicateItem, editItem, removeItemFromGroup, deleteAction],
  specificationChildren: [
    duplicateItem,
    removeItemFromGroup,
    editItem,
    attachmentButton,
    copyLinkButton,
    deleteAction,
    addToMyProducts
  ],
  shoppingItem: isPaid => [
    {
      label: isPaid ? 'Marcar como aguardando compra' : 'Marcar como comprado',
      verb: 'put',
      values: { purchaseStatus: isPaid ? 1 : 6, event: 'project-shopping-edited' }
    }
  ],
  export: [{ label: 'Exportar orçamento', verb: 'get', pathOptions: '/export' }],
  projectList: row => [
    {
      label: 'Editar',
      redirectTo: `/profissional/projetos/editar/${row.id}`,
      hasId: true,
      iconProps: {
        icon: faEdit
      }
    },
    duplicateProject,
    cancelProject,
    finishProject,
    {
      ...deleteAction,
      label: 'Excluir projeto',
      iconProps: {
        icon: faTrash
      },
      modalParams: {
        title: `Atenção, seu projeto será excluído!`,
        text: (
          <p>
            Ao confirmar essa ação, o projeto será <b>EXCLUÍDO</b> por completo.
          </p>
        )
      }
    },
    bar,
    applyTemplatetoProject,
    {
      id: 'apply-template',
      label: 'Aplicar um template',
      modal: 'templatesModal',
      verb: 'post',
      values: { type: 2 },
      iconProps: {
        icon: faPresentation
      }
    }
  ],
  projectItemList: (id, userType, idStatus) => [
    {
      id: 'edit-project',
      label: 'Editar',
      redirectTo: `/${userType}/projetos/editar/${id}`,
      hasId: true,
      iconProps: {
        icon: faEdit
      }
    },
    duplicateProject,
    idStatus === 7
      ? {
          ...{
            label: 'Reativar projeto',
            iconProps: {
              icon: faInboxOut
            },
            verb: 'put',
            modal: 'confirmModal',
            modalParams: {
              text: 'Deseja realmente reativar o projeto?'
            },
            values: { idStatus: 5 }
          },
          displayToast: 'Seu projeto foi reativado.'
        }
      : {
          ...cancelProject,
          displayToast: 'Seu projeto foi cancelado. Para vê-lo na lista de projetos, utilize os filtros disponíveis'
        },
    {
      ...finishProject,
      displayToast: 'Seu projeto foi finalizado. Para vê-lo na lista de projetos, utilize os filtros disponíveis'
    },
    {
      ...deleteAction,
      modalParams: {
        title: `Atenção, seu projeto será excluído!`,
        text: (
          <p>
            Ao confirmar essa ação, o projeto será <b>EXCLUÍDO</b> por completo.
          </p>
        ),
        redirectTo: '/profissional/projetos'
      },
      label: 'Excluir projeto',
      iconProps: {
        icon: faTrash
      },
      displayToast: 'Operação realizada com sucesso!'
    },
    bar,
    applyTemplatetoProject,
    {
      id: 'apply-template',
      label: 'Aplicar um template',
      modal: 'templatesModal',
      verb: 'post',
      values: { type: 2 },
      iconProps: {
        icon: faPresentation
      }
    }
  ],
  projectListEnd: [
    duplicateProject,
    {
      label: 'Reativar projeto',
      iconProps: {
        icon: faInboxOut
      },
      verb: 'put',
      modal: 'confirmModal',
      modalParams: {
        text: 'Deseja realmente reativar o projeto?'
      },
      values: { idStatus: 5 }
    },
    {
      ...deleteAction,
      label: 'Excluir projeto',
      iconProps: {
        icon: faTrash
      },
      modalParams: {
        title: `Atenção, seu projeto será excluído!`,
        text: (
          <p>
            Ao confirmar essa ação, o projeto será <b>EXCLUÍDO</b> por completo.
          </p>
        ),
        redirectTo: '/profissional/projetos'
      }
    },
    applyTemplatetoProject
  ],
  projectKanban: idStatus => [
    idStatus === 6
      ? {
          ...{
            label: 'Reativar projeto',
            iconProps: {
              icon: faInboxOut
            },
            verb: 'put',
            modal: 'confirmModal',
            modalParams: {
              text: 'Deseja realmente reativar o projeto?'
            },
            values: { idStatus: 5 }
          },
          displayToast: 'Seu projeto foi reativado.'
        }
      : finishProject,
    duplicateProject,
    idStatus === 7
      ? {
          ...{
            label: 'Reativar projeto',
            iconProps: {
              icon: faInboxOut
            },
            verb: 'put',
            modal: 'confirmModal',
            modalParams: {
              text: 'Deseja realmente reativar o projeto?'
            },
            values: { idStatus: 5 }
          },
          displayToast: 'Seu projeto foi reativado.'
        }
      : {
          ...cancelProject,
          displayToast: 'Seu projeto foi cancelado. Para vê-lo na lista de projetos, utilize os filtros disponíveis'
        },
    {
      label: 'Remover da etapa',
      verb: 'put',
      iconProps: {
        icon: faXmark
      },
      pathOptions: '',
      values: { idStep: null }
    },

    {
      ...deleteAction,
      label: 'Excluir projeto',
      iconProps: {
        icon: faTrash
      },
      modalParams: {
        title: `Atenção, seu projeto será excluído!`,
        text: (
          <p>
            Ao confirmar essa ação, o projeto será <b>EXCLUÍDO</b> por completo.
          </p>
        )
      }
    },
    applyTemplatetoProject
  ],
  opportunityKanban: [
    {
      label: 'Marcar como ganho',
      verb: 'put',
      modal: 'TemplatesModal',
      modalParams: {
        idStatus: 5,
        title: 'Parabéns! Sua oportunidade será convertida em projeto!'
      }
    },
    {
      label: 'Arquivar',
      verb: 'put',
      modal: 'confirmModal',
      modalParams: {
        title: 'Deseja realmente arquivar esta oportunidade?',
        text: 'Qual o motivo de arquivamento da oportunidade? Você poderá consultá-lo nos detalhes da oportunidade.',
        showReason: true,
        placeholder: 'Insira uma descrição do arquivamento para registrar no seu histórico de oportunidades.'
      },
      values: { idStatus: 4, winnerOrArchived: new Date() }
    },
    { ...deleteAction, label: 'Remover' }
  ],
  listOpportunityItemArchived: [
    {
      label: 'Desarquivar',
      verb: 'put',
      modal: 'confirmModal',
      modalParams: {
        text: 'Deseja realmente desarquivar esta oportunidade?'
      },
      values: { idStatus: 1, winnerOrArchived: null }
    },
    deleteAction
  ],
  listOpportunityItem: [
    {
      label: 'Arquivar',
      verb: 'put',
      modal: 'confirmModal',
      modalParams: {
        text: 'Deseja realmente arquivar esta oportunidade?',
        showReason: true
      },
      values: { idStatus: 4, winnerOrArchived: new Date() }
    },
    deleteAction
  ],
  myProducts: [
    {
      label: 'Adicionar em um projeto',
      modal: 'addProductToProject'
    },
    {
      label: 'Fazer uma cópia',
      modal: 'confirmModal',
      verb: 'post',
      pathOptions: '/duplicate',
      modalParams: {
        text: 'Deseja realmente clonar este item?'
      }
    },
    {
      label: 'Remover',
      modal: 'confirmModal',
      verb: 'delete',
      modalParams: {
        text: 'Deseja realmente apagar este produto?'
      }
    }
  ],
  addItems: [
    ...addItem,
    {
      label: 'Usar template de orçamento',
      modal: 'templatesModal',
      verb: 'post',
      values: { type: 2 }
    }
  ],
  addTemplateItems: addItem,
  proposal: isProject => [
    {
      label: 'Arquivar',
      verb: 'put',
      modal: 'confirmModal',
      modalParams: {
        text: 'Deseja realmente arquivar esta proposta?'
      },
      values: { idStatus: 4, winnerOrArchived: new Date(), archived: true, isProject }
    }
  ],
  notificationDropdown: userType => [
    {
      label: 'Marcar todas como lidas',
      verb: 'markAllRead'
    },
    {
      label: 'Ver todas as notificações',
      redirectTo: `/${userType}/notificacoes`
    },
    {
      label: 'Configurar notificações',
      redirectTo: `/${userType}/notificacoes/configuracoes`
    }
  ],
  notificationListItem: title => [
    {
      label: title,
      verb: 'markRead'
    }
  ],
  usersProvider: [
    {
      label: 'Reenviar e-mail',
      verb: 'post',
      modal: 'confirmModal',
      pathOptions: '/resend-welcome',
      displayToast: 'E-mail reenviado com sucesso!',
      model: 'user',
      modalParams: {
        text: 'Deseja reenviar o email?'
      }
    },
    deleteAction
  ],
  noteActions: [deleteAction],
  payment: (payment, idRefurbish, paymentStatuses, refurbishType, isOpportunity) => {
    const result = [
      {
        button: 'redirectLink',
        buttonParams: {
          text: true,
          children: `${payment.paymentStatus?.id === paymentStatuses?.draft ? 'Editar' : 'Ver detalhes'}`,
          path: `/profissional/cobrancas/${
            payment.paymentStatus?.id === paymentStatuses?.draft ? 'editar' : 'visualizar'
          }/${payment.id}${idRefurbish ? `?${refurbishType}=${idRefurbish}` : ''}`
        }
      },
      ...(payment.paymentStatus?.id !== paymentStatuses?.draft &&
      payment.paymentStatus?.id !== paymentStatuses?.cancelled
        ? [
            {
              label: 'Compartilhar cobrança',
              modal: 'sharePayment',
              modalParams: { paymentId: payment?.id, idRefurbish: payment?.idRefurbish, isOpportunity }
            }
          ]
        : [])
    ];
    if (payment.paymentStatus?.id !== paymentStatuses?.cancelled) result.push(cancelPayment);
    return result;
  },
  paymentChildren: ({ installment, payment, idRefurbish, refurbishType }) => {
    const { setup } = store.getState();

    const { installmentStatuses, billType: billTypeEnum } = setup.enums;

    const _payment = installment?.payment || payment;
    const viewPaymentLabel = `Ver ${getPaymentLabel({ ..._payment, billTypeEnum }) || 'cobrança'}`;
    const viewPaymentUrL = getViewPaymentUrl({ ..._payment, billTypeEnum, idRefurbish, refurbishType });

    const result = [
      ...(installment?.idInstallmentStatus === installmentStatuses.pendingPayment
        ? [{ ...paidInstallment, modalParams: { installment } }]
        : [unpaidInstallment]),
      attachmentButton,
      { label: 'Detalhes da parcela', id: 'edit', modal: 'editPaymentModal', modalParams: { installment } }
    ];

    if (_payment)
      result.push({
        button: 'redirectLink',
        buttonParams: {
          text: true,
          children: viewPaymentLabel,
          path: viewPaymentUrL
        }
      });

    return result;
  },
  markAsPaid: [
    {
      button: 'markAsPaid',
      buttonParams: {
        text: true,
        children: 'Marcar como pago'
      }
    }
  ]
};

export { allDropdown };
