import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowLeft } from '@fortawesome/pro-light-svg-icons';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Typography } from 'antd';
import { colors, breakpoints, spaces } from '../../styles/style';
import { mappingMobileSubHeader } from '../../lib/mapping/Header/mappingMobileSubHeader';
import Button from '../Button/Button';
import useBackToContext from '../../_Hooks/useBackToContext';

const { Text } = Typography;

const StyledHeaderDiv = styled.div`
  background-color: ${colors.white};
  color: ${colors.neutralSecondary70};
  padding: ${spaces.space1} ${spaces.space2};
  display: ${props => (props.hide ? 'none' : 'flex')};
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #ded8d8;
  text-align: center;
  font-weight: 500;
  justify-content: space-between;
  @media (min-width: ${breakpoints.tablet}) {
    display: none;
  }
  ${props =>
    props.fixed &&
    css`
      position: absolute;
      top: ${props.isCustomerView ? props.top + 35 : props.top}px;
      left: 0;
    `}
`;

const MobileSubHeader = ({ goBack = false, children, fixed = false, top = 52 }) => {
  const params = useParams();
  const backToContext = useBackToContext();
  const { user } = useSelector(state => state.authReducer);
  const { rootPage, page, subpage, action } = params;

  const { title, EndContent } =
    mappingMobileSubHeader[rootPage]?.[page]?.[subpage]?.[action] ||
    mappingMobileSubHeader[rootPage]?.[page]?.[subpage] ||
    mappingMobileSubHeader[rootPage]?.[page] ||
    {};
  const CenterContent = () => {
    if (typeof title === 'string') return <Text>{title || 'Vobi'}</Text>;
    if (!title) return <div />;
    const CenterRender = title;
    return <CenterRender />;
  };

  return (
    <StyledHeaderDiv
      hide={!children && !title}
      fixed={fixed}
      top={top}
      isCustomerView={user.isCustomerView || user.anonymous}
    >
      {children ? (
        <>
          {goBack && (
            <Button text noPadding onClick={backToContext}>
              <FontAwesomeIcon icon={faLongArrowLeft} />
            </Button>
          )}
          {children}
        </>
      ) : (
        <>
          <Button text noPadding onClick={backToContext}>
            <FontAwesomeIcon icon={faLongArrowLeft} />
          </Button>
          <CenterContent />
          {EndContent && <EndContent />}
        </>
      )}
    </StyledHeaderDiv>
  );
};

MobileSubHeader.propTypes = {
  goBack: PropTypes.bool,
  fixed: PropTypes.bool,
  children: PropTypes.instanceOf(Object),
  top: PropTypes.number
};

export default MobileSubHeader;
