import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';

import { useSelector } from 'react-redux';
import useCRUD from '../../../_Hooks/useCRUD';

import Steps from '../../Settings/Steps';
import CenteredLoader from '../../../components/Loader/CenteredLoader';
import { Columns, Content, StepBox } from './Onboarding.styled';
import Image from '../../../components/Images/myProjects.svg';
import NavigationButton from '../../../components/Button/NavigationButton';

const { Text } = Typography;

const StepsSettings = ({ onReturn, onSubmit }) => {
  const { user } = useSelector(state => state.authReducer);
  const { loading, handleGet, handleCreate } = useCRUD({
    model: 'step',
    options: {
      where: {
        isKanban: true,
        idCompany: { eq: user.idCompany }
      },
      onlyMine: true,
      order: [['order']]
    },
    immediatelyLoadData: false
  });

  useEffect(() => {
    handleGet().then(list => {
      if (!list?.length) {
        handleCreate({ values: { onlyKanban: true }, postPathOptions: '/default' });
      }
    });
  }, []);

  return (
    <Content>
      <Columns>
        <div className="stepColumn">
          <h6>Configure as etapas</h6>
          <Text>
            Organize seus projetos e fique por dentro de tudo o que está acontecendo em sua empresa. Defina as etapas do
            jeito que precisar, de acordo com seu negócio.
          </Text>

          {loading ? (
            <CenteredLoader />
          ) : (
            <StepBox>
              <Steps hideHeader skipConfirmation isOnboarding $listItemStyles={{ $maxWidth: '80%' }} />
            </StepBox>
          )}
          <NavigationButton onSubmit={onSubmit} onReturn={onReturn} $fullWidth />
        </div>
        <div className="secondColumn">
          <img src={Image} alt="Imagem" />
        </div>
      </Columns>
    </Content>
  );
};

StepsSettings.propTypes = {
  onReturn: PropTypes.func,
  onSubmit: PropTypes.func
};

export default StepsSettings;
