import styled from 'styled-components';

import { spaces } from '../../styles/style';
import Drawer from '../Drawer/Drawer';

const StyledDrawer = styled(Drawer)`
  header {
    display: flex;

    span {
      margin-left: ${spaces.space2};
    }
  }
`;

const Container = styled.div`
  margin-top: -${spaces.space4};
`;

const TabContent = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0 ${spaces.space2};
  padding-top: ${spaces.space1};
`;

export { Container, TabContent, StyledDrawer };
