import { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Qs from 'qs';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { parse } from 'cookie';

import { getUserAnonymousId } from '../lib/analytics/analytics';

const baseURL = `${
  process.env.REACT_APP_CUSTOM_NODE_ENV !== `development`
    ? document.location.origin.replace('plataforma', 'api')
    : process.env.REACT_APP_API_URL
}/v1`;
const isDev = process.env.REACT_APP_CUSTOM_NODE_ENV === `development`;
axios.defaults.withCredentials = true;

const getCookies = () => {
  const { cookie } = document || {};
  return isDev ? { Cook1e: `vobi.cookies=${encodeURIComponent(parse(cookie)[`vobi.cookies`])}` } : {};
};

const useCRUD = ({
  model,
  newBaseURL,
  options = {},
  pathOptions = '',
  immediatelyLoadData = true,
  headerOptions = {},
  showToast = true
}) => {
  const history = useHistory();
  const { anonymousToken } = useSelector(state => state.authReducer) || {};
  const { pathname } = history.location;
  const isRequestCancelled = useRef(false);
  const [data, setData] = useState(null);
  const [list, setList] = useState([]);
  const [mapping, setMapping] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(immediatelyLoadData);
  const [error, setError] = useState('');
  const [devCookies] = useState(getCookies());

  const headers = options.headers || {
    'Content-Type': headerOptions.contentType || 'application/json',
    ...devCookies
  };

  if (!newBaseURL) headers['Anonymous-Id'] = getUserAnonymousId();
  if (!newBaseURL && anonymousToken) headers['Anonymous-Token'] = anonymousToken;

  const throwError = useCallback((errorParam, displayToast = true) => {
    if (!isRequestCancelled.current) {
      setLoading(false);
    }
    const { response: { data: errorData } = {} } = errorParam;
    const { code = '', statusCode, logout: shouldLogout, message } = errorData || {};

    if (code && shouldLogout) {
      return pathname === '/login' ? null : history.push(`/logout`);
    }

    const errMsg = message || `Ocorreu um erro ${code}`;
    setError(errorData);
    if (showToast && displayToast && statusCode && statusCode !== 500) toast.error(errMsg);
    return { error: { message: errMsg } };
  }, []);

  const paramsSerializer = ({ offset, limit, ...params }) => {
    return Qs.stringify(
      {
        // TODO change offset value to use page in component
        offset: offset && limit > 0 && offset > 0 ? (offset - 1) * limit : 0,
        limit,
        ...params
      },
      { strictNullHandling: true }
    );
  };

  const handleGet = useCallback(
    ({ refetchOptions, refetchPathOptions, generateLoading = true, refresh = true, displayToast, keepState } = {}) => {
      if (!refresh) {
        setLoading(false);
        return;
      }
      if (generateLoading && !isRequestCancelled.current) setLoading(true);

      // eslint-disable-next-line consistent-return
      return axios
        .get(`${baseURL}/${model}${refetchPathOptions || pathOptions}`, {
          paramsSerializer,
          params: refetchOptions || options,
          headers
        })
        .then(({ data: responseData }) => {
          const { rows, count: _totalItems = 0, data: __data, allowData = false, mapping: _mapping = null } =
            responseData || {};

          const isList =
            Array.isArray(responseData) || (typeof responseData?.count === 'number' && rows && Array.isArray(rows));

          const _list = isList ? rows || responseData : [];
          const _data = (!isList && responseData?.constructor === Object) || allowData ? __data || responseData : null;

          if (!isRequestCancelled.current && !keepState) {
            setMapping(_mapping);
            setList(_list);
            setTotalItems(_totalItems);
            setData(_data);
          }

          return isList ? _list : _data;
        })
        .catch(err => throwError(err, displayToast))
        .finally(() => {
          if (!isRequestCancelled.current) setLoading(false);
        });
    },
    [model, pathOptions, isRequestCancelled]
  );

  useEffect(() => {
    isRequestCancelled.current = false;
    if (immediatelyLoadData && !isRequestCancelled.current) handleGet({}).then();
    return () => {
      isRequestCancelled.current = true;
    };
  }, [immediatelyLoadData, handleGet, isRequestCancelled.current]);

  const handleCreate = useCallback(
    ({ values, postOptions, postPathOptions, displayToast, refresh = true, noLoading, customCatch }) => {
      if (!isRequestCancelled.current) setLoading(!noLoading);
      return axios
        .post(`${newBaseURL || baseURL}/${model}${postPathOptions || pathOptions}`, values, {
          headers,
          paramsSerializer,
          params: postOptions
        })
        .then(({ data: responseData }) => {
          handleGet({
            refetchOptions: postOptions,
            generateLoading: false,
            refresh
          });

          if (showToast && displayToast) {
            toast.success(typeof displayToast === 'string' ? displayToast : 'Operação realizada com sucesso');
          }
          return responseData;
        })
        .catch(err => {
          if (customCatch) {
            throw err;
          } else {
            return throwError(err, displayToast);
          }
        });
    },
    [data, handleGet, model, newBaseURL, options]
  );

  const handleDelete = useCallback(
    ({ id, values, deleteOptions, deletePathOptions, displayToast, refresh = true, noLoading }) => {
      const url = `${baseURL}/${model}${id ? `/${id}` : ''}${deletePathOptions || pathOptions}`;
      if (!isRequestCancelled.current) setLoading(!noLoading);
      return axios
        .delete(url, {
          data: values,
          paramsSerializer(params) {
            return Qs.stringify(params, { strictNullHandling: true });
          },
          headers,
          params: deleteOptions || options
        })
        .then(() => {
          if (showToast && displayToast) {
            toast.success(typeof displayToast === 'string' ? displayToast : 'Operação realizada com sucesso');
          }
          if (data && data.length) {
            setData(data.filter(dataItem => dataItem.id !== id));
          }
          return handleGet({
            generateLoading: false,
            refetchOptions: deleteOptions,
            refresh
          });
        })
        .catch(err => throwError(err, displayToast));
    },
    [data, model]
  );

  const handleUpdate = useCallback(
    ({
      id = '',
      values,
      updatePathOptions = '',
      displayToast,
      updateOptions,
      refresh = true,
      noLoading,
      verb = 'put'
    }) => {
      // eslint-disable-next-line no-nested-ternary
      const idPath = !id ? '' : id.toString().includes('/') ? id : `/${id}`;
      const url = `${baseURL}/${model}${idPath}${updatePathOptions || pathOptions}`;
      if (!isRequestCancelled.current) setLoading(!noLoading);
      return axios[verb](url, values, {
        paramsSerializer({ offset, limit, ...params }) {
          return Qs.stringify(
            {
              // TODO change offset value to use page in component
              offset: offset && limit > 0 && offset > 0 ? (offset - 1) * limit : 0,
              limit,
              ...params
            },
            { strictNullHandling: true }
          );
        },
        params: updateOptions || options,
        headers
      })
        .then(({ data: responseData }) => {
          if (showToast && displayToast) {
            toast.success(typeof displayToast === 'string' ? displayToast : 'Operação realizada com sucesso');
          }
          handleGet({
            refetchOptions: updateOptions,
            refetchPathOptions: updatePathOptions,
            generateLoading: false,
            refresh
          });
          return responseData;
        })
        .catch(err => throwError(err, displayToast));
    },
    [handleGet, model, options]
  );

  return {
    data,
    list,
    mapping,
    totalItems,
    setTotalItems,
    setLoading,
    loading,
    options,
    error,
    setError,
    setData,
    setList,
    handleGet,
    handleUpdate,
    handleDelete,
    handleCreate
  };
};

export default useCRUD;
