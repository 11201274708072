import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendar,
  faCheckCircle,
  faTrash,
  faEllipsisV,
  faFolder,
  faUser,
  faFlag,
  faSquare,
  faTrashAlt,
  faCopy
} from '@fortawesome/pro-regular-svg-icons';

import useCRUD from '../../_Hooks/useCRUD';
import useViewport from '../../_Hooks/useViewport';

import BulkActions from './BulkAction';
import BulkActionsSelect from '../Select/BulkActionsSelect';
import RangePicker from '../Datepicker/RangePicker';
import ConfirmModal from '../Modal/ConfirmModal';
import TooltipIcon from '../Icons/TooltipIcon';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import Dropdown from '../Dropdown/Dropdown';

import { BulkActionsChildrenContainer, ModalItem } from './BulkActions.styles';
import MobileSelectMenu from './MobileSelectMenu';

const TaskBulkActions = ({
  handleChange,
  handleDelete,
  handleClose,
  counter,
  selectedRowKeys,
  generalTask,
  idCompany,
  handleRefresh,
  afterSubmit,
  isDefaultOrder
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showOptionsModal, setShowOptionsModal] = useState(false);

  const { taskPriority: taskPriorityEnum } = useSelector(state => state.setup.systemData);
  const { taskStatus: taskStatusEnum } = useSelector(state => state.setup.enums);

  const { isMobile } = useViewport(window.innerWidth);

  const { list: refurbishList, handleGet: getRefurbish } = useCRUD({
    model: 'refurbish',
    options: { where: { idCompany } },
    immediatelyLoadData: false
  });

  const { list: userList, handleGet: getUser } = useCRUD({
    model: 'user',
    options: { where: { idCompany } },
    immediatelyLoadData: false
  });

  const { handleCreate: duplicateTasks } = useCRUD({
    model: 'task/bulk/duplicate',
    immediatelyLoadData: false
  });

  useEffect(() => {
    getRefurbish();
    getUser();
  }, []);

  const [statusList, priorityList] = useMemo(() => {
    const status = Object.keys(taskStatusEnum)
      ?.filter(key => !!taskStatusEnum[key].id)
      .map(key => ({ ...taskStatusEnum[key] }));

    const priorities = taskPriorityEnum.map(priority => ({ value: priority.value, label: priority.label }));

    return [status, priorities];
  }, [taskStatusEnum, taskPriorityEnum]);

  const plural = useMemo(() => (counter > 1 ? 's' : ''), [counter]);

  const handleCompleteTask = () => {
    handleChange({ status: 4 });
    toast.success(`${counter} tarefa${plural} concluída${plural} com sucesso`);
  };

  const handleDuplicateTasks = () => {
    duplicateTasks({
      values: {
        ids: selectedRowKeys
      },
      refresh: false,
      postOptions: { include: ['user', 'refurbish', 'step'] }
    }).then(resp => {
      // eslint-disable-next-line
      !generalTask || !isDefaultOrder ? handleRefresh(resp) : afterSubmit(resp);
      toast.success(`
        ${counter} tarefa${plural} duplicada${plural} ${isDefaultOrder && !generalTask ? '(no final da lista)' : ''}
        ${isDefaultOrder && generalTask ? '(no topo da lista)' : ''}.
      `);
    });
  };

  return counter <= 0 ? null : (
    <BulkActions text={`${counter} tarefa${plural} selecionada${plural}`} counter={counter} handleClose={handleClose}>
      {!isMobile() && (
        <BulkActionsChildrenContainer>
          {generalTask && (
            <BulkActionsSelect
              options={[{ label: '-', value: null }, ...refurbishList]}
              placeholder="Projeto"
              onChange={value => {
                handleChange({ idRefurbish: value });
              }}
              observe={selectedRowKeys}
            />
          )}
          <BulkActionsSelect
            options={[{ label: '-', value: null }, ...userList]}
            placeholder="Responsável"
            onChange={value => handleChange({ idResponsible: value })}
            observe={selectedRowKeys}
          />
          <BulkActionsSelect
            options={[{ label: '-', value: null }, ...priorityList]}
            placeholder="Prioridade"
            onChange={value => handleChange({ priority: value })}
            observe={selectedRowKeys}
          />
          <BulkActionsSelect
            placeholder="Status"
            options={statusList}
            onChange={value => handleChange({ status: value })}
            observe={selectedRowKeys}
          />
          <RangePicker
            size="small"
            format="DD MMM"
            value={Date.now()}
            onChange={value => {
              handleChange({ endDate: value.end, startDate: value.start });
            }}
            buttonProps={{ shape: 'text' }}
          >
            <TooltipIcon text="Alterar datas" icon={faCalendar} style={{ height: 18, width: 18, color: '#FFF' }} />
          </RangePicker>
          <TooltipIcon
            style={{ height: 18, width: 18 }}
            icon={faCheckCircle}
            text="Concluir tarefas"
            onClick={handleCompleteTask}
          />
          <TooltipIcon
            style={{ height: 18, width: 18 }}
            icon={faCopy}
            text="Duplicar tarefas"
            onClick={handleDuplicateTasks}
          />
          <TooltipIcon
            style={{ height: 18, width: 18 }}
            icon={faTrash}
            text="Excluir tarefas"
            onClick={() => {
              setShowConfirmModal(true);
            }}
          />
        </BulkActionsChildrenContainer>
      )}

      {isMobile() && (
        <>
          <Button noPadding text onClick={() => setShowOptionsModal(true)}>
            <FontAwesomeIcon icon={faEllipsisV} size="2x" />
          </Button>
          {showOptionsModal && (
            <Modal title="Opções" open hideFooter onClose={() => setShowOptionsModal(false)}>
              {generalTask && (
                <Dropdown
                  menu={
                    <MobileSelectMenu
                      onClick={(field, option) => {
                        handleChange({ [field]: option.value || option.id });
                        setShowOptionsModal(false);
                      }}
                      options={[{ label: '-', value: null }, ...refurbishList]}
                      field="idRefurbish"
                    />
                  }
                >
                  <ModalItem>
                    <FontAwesomeIcon icon={faFolder} />
                    <p>Alterar projeto</p>
                  </ModalItem>
                </Dropdown>
              )}

              <Dropdown
                menu={
                  <MobileSelectMenu
                    onClick={(field, option) => {
                      handleChange({ [field]: option.value || option.id });
                      setShowOptionsModal(false);
                    }}
                    options={[{ label: '-', value: null }, ...userList]}
                    field="idSupplier"
                  />
                }
              >
                <ModalItem>
                  <FontAwesomeIcon icon={faUser} />
                  <p>Alterar responsável</p>
                </ModalItem>
              </Dropdown>

              <Dropdown
                menu={
                  <MobileSelectMenu
                    onClick={(field, option) => {
                      handleChange({ [field]: option.value || option.id });
                      setShowOptionsModal(false);
                    }}
                    options={[{ label: '-', value: null }, ...priorityList]}
                    field="priority"
                  />
                }
              >
                <ModalItem>
                  <FontAwesomeIcon icon={faFlag} />
                  <p>Alterar prioridade</p>
                </ModalItem>
              </Dropdown>

              <Dropdown menu={<MobileSelectMenu options={statusList} field="status" />}>
                <ModalItem>
                  <FontAwesomeIcon icon={faSquare} />
                  <p>Alterar status</p>
                </ModalItem>
              </Dropdown>

              <ModalItem onClick={handleCompleteTask}>
                <FontAwesomeIcon icon={faCheckCircle} />
                <p>Concluir tarefas</p>
              </ModalItem>

              <ModalItem onClick={() => setShowConfirmModal(true)}>
                <FontAwesomeIcon icon={faTrashAlt} />
                <p>Excluir tarefas selecionadas</p>
              </ModalItem>

              <ModalItem onClick={handleDuplicateTasks}>
                <FontAwesomeIcon icon={faCopy} />
                <p>Duplicar tarefas</p>
              </ModalItem>
            </Modal>
          )}
        </>
      )}

      {showConfirmModal && (
        <ConfirmModal
          text={`Tem certeza que deseja apagar ${counter} tarefa${plural} selecionada${plural}?`}
          onSubmit={() => {
            handleDelete(selectedRowKeys);
            handleClose();
          }}
          onClose={() => setShowConfirmModal(false)}
        />
      )}
    </BulkActions>
  );
};

TaskBulkActions.propTypes = {
  handleChange: PropTypes.func,
  handleDelete: PropTypes.func,
  handleClose: PropTypes.func,
  counter: PropTypes.number,
  selectedRowKeys: PropTypes.instanceOf(Array),
  generalTask: PropTypes.bool,
  idCompany: PropTypes.number,
  handleRefresh: PropTypes.func,
  afterSubmit: PropTypes.func,
  isDefaultOrder: PropTypes.bool
};

export default TaskBulkActions;
