export default {
  general: {
    default: 'Vobi'
  },
  login: {
    fallback: '',
    default: 'Vobi',
    profissional: 'Vobi | Profissional',
    operador: 'Vobi | Operador',
    cliente: 'Vobi | Cliente',
    cadastrar: 'Vobi | Cadastrar',
    'recuperar-senha': 'Vobi | Recuperar Senha'
  },
  profissional: {
    fallback: '',
    dashboard: 'Home',
    contatos: {
      clientes: 'Meus Clientes',
      fornecedores: 'Meus Fornecedores'
    },
    oportunidades: {
      default: 'Minhas Oportunidades',
      subpage: 'Oportunidade'
    },
    produtividade: {
      'gestao-tarefas': 'Gestão de Tarefas',
      'cronograma-geral': 'Cronograma Geral'
    },
    ferramentas: {
      'meus-templates': 'Templates'
    },
    catalogo: {
      default: 'Catálogo',
      categorias: 'Categorias',
      produtos: 'Produtos',
      produto: 'Produto',
      'grupos-e-categorias': 'Meus Grupos e categorias',
      'meus-produtos-servicos': 'Meus Produtos e Serviços'
    },
    projetos: {
      default: 'Meus Projetos',
      subpage: 'Projeto'
    },
    financeiro: {
      default: 'Financeiro',
      subpage: 'Financeiro Administrativo'
    },
    configuracoes: {
      default: 'Configurações',
      'dados-pessoais': 'Dados Pessoais',
      'meu-negocio': 'Meu Negócio',
      'dados-bancarios': 'Dados Bancários',
      'trocar-senha': 'Trocar Senha',
      equipe: 'Minha Equipe',
      etapas: 'Etapas'
    },
    'meu-site': 'Meu Site',
    notificacoes: 'Notificações',
    moodboard: 'Moodboard',
    pagamentos: 'Financeiro de projetos',
    cobrancas: 'Financeiro'
  },
  cliente: {
    fallback: '',
    dashboard: 'Dashboard',
    orcamento: 'Orçamento',
    projeto: {
      default: 'Projeto',
      geral: 'Projeto',
      arquivos: 'Arquivos',
      propostas: 'Propostas'
    },
    'meus-dados': 'Gerenciamento Conta',
    'compras-e-contratacoes': 'Compras e contratações',
    solicitacoes: 'Solicitações de pagamento',
    arquivos: 'Arquivos',
    propostas: 'Propostas'
  },
  operador: {
    fallback: '',
    dashboard: 'Dashboard',
    orcamento: {
      ambientes: 'Ambientes',
      composicoes: 'Composições',
      'centros-de-custo': 'Centro de Custo',
      marcas: 'Marcas',
      materiais: 'Materiais',
      orcamentos: 'Orçamentos',
      servicos: 'Serviços',
      'tipos-de-ambientes': 'Tipos de Ambientes',
      'tipos-de-mao-de-obra': 'Tipos de Mão de Obra',
      'tipos-de-materiais': 'Tipos de Materiais',
      'tipos-de-servicos': 'Tipos de Serviços'
    },
    obra: {
      reformas: 'Reformas'
    },
    usuarios: {
      clientes: 'Clientes',
      operadores: 'Operadores',
      profissionais: 'Profissionais'
    },
    configuracao: {
      pipedrive: 'Exportar Pipedrive',
      search: 'Configurações do Search '
    },
    'meus-dados': 'Gerenciamento de Conta'
  }
};
