import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { StepFooter } from '../Steps/Steps.styled';
import Button from './Button';
import useViewport from '../../_Hooks/useViewport';
import { colors, spaces } from '../../styles/style';

const NavigationButton = ({ canSubmit, onReturn, onSubmit, ...props }) => {
  const { isMobile } = useViewport(window.innerWidth);
  return (
    <StepFooter {...props}>
      {onReturn ? (
        <Button className="back" text size={4} onClick={onReturn}>
          <FontAwesomeIcon icon={faChevronLeft} /> Voltar
        </Button>
      ) : (
        <div />
      )}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {onSubmit ? (
          <Button type="primary" size={4} onClick={onSubmit} fullWidth={isMobile()} disabled={!canSubmit}>
            Avançar
          </Button>
        ) : (
          <div />
        )}
        {!isMobile() && (
          <div id="onboard-info" style={{ marginLeft: spaces.space2 }}>
            <FontAwesomeIcon icon={faCircleInfo} color={colors.primary40} />
          </div>
        )}
      </div>
    </StepFooter>
  );
};

NavigationButton.propTypes = {
  canSubmit: PropTypes.bool,
  onReturn: PropTypes.func,
  onSubmit: PropTypes.func
};

NavigationButton.defaultProps = {
  canSubmit: true
};

export default NavigationButton;
