import { useState, useEffect, useRef } from 'react';
import isEqual from 'react-fast-compare';

const validate = (object, schema) => schema?.validate(object, { abortEarly: false });

const formatYupErrors = yupErrs => {
  if (!yupErrs.inner) {
    throw new Error(`Error in Validation Schema: ${yupErrs.message}`);
  }
  return yupErrs.inner.reduce((errs, err) => ({ ...errs, [err.path]: err.errors }), []);
};

// https://stackoverflow.com/a/36321/7543165
const curry = fn => (...args) => (fn.length > args.length ? fn.bind(fn, ...args) : fn(...args));

let timeout;

const useFormState = (initValues, validationSchema, enableReinitialize = false, validateOnSubmit = false) => {
  const [values, _setValues] = useState(initValues);
  const [errors, setErrors] = useState({});
  const [touched, _setTouched] = useState({});
  const initValuesRef = useRef(initValues);
  const valuesChangedRef = useRef(false);
  const onBlurRef = useRef(false);
  const keyChangedRef = useRef(null);

  const isValid = () => Object.values(errors).reduce((e, acc) => !!e.length && acc, true);

  const handleChange = (key, e, noSubmit) => {
    keyChangedRef.current = key;
    const newValue = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    _setValues(prev => ({
      ...prev,
      [key]: newValue
    }));
    valuesChangedRef.current = !noSubmit;
    onBlurRef.current = false;
  };

  const handleFocus = key => {
    keyChangedRef.current = key;
    onBlurRef.current = false;
  };

  const handleBlur = (key, e) => {
    e.preventDefault();
    onBlurRef.current = true;
    _setTouched(prevTouched => ({ ...prevTouched, [key]: true }));
  };

  const validateValues = () =>
    validate(values, validationSchema)
      .then(() => setErrors({}))
      .catch(errs => setErrors(formatYupErrors(errs)));

  const handleSubmit = (callback, e) => {
    e.preventDefault();
    e.stopPropagation();
    const _touched = Object.keys(values).reduce((acc, key) => ({ ...acc, [key]: true }), {});

    _setTouched(_touched);
    validateOnSubmit && validateValues();
    if (isValid()) {
      valuesChangedRef.current = false;
      callback(values);
    }
  };

  const setField = (key, value, noSubmit) => {
    _setValues(prev => ({
      ...prev,
      [key]: value
    }));
    valuesChangedRef.current = !noSubmit;
    onBlurRef.current = true;
  };

  const setTouched = (key, value) => {
    const changed = { ...touched, [key]: value };
    _setTouched(changed);
    onBlurRef.current = false;
  };

  const setValues = changed => {
    _setValues(changed);
    valuesChangedRef.current = true;
  };

  const reset = data => {
    if (enableReinitialize) initValuesRef.current = data || initValues;
    _setValues(initValuesRef.current);
    const _touched = Object.keys(values).reduce((acc, key) => ({ ...acc, [key]: false }), {});
    _setTouched(_touched);
    valuesChangedRef.current = false;
    onBlurRef.current = false;
    keyChangedRef.current = null;
  };

  // Goal, only save when the value is different from a initial state
  // we are using deep comparison
  useEffect(() => {
    if (onBlurRef.current && Object.keys(values) && !isEqual(values, initValuesRef.current)) {
      valuesChangedRef.current = true;
    }
  }, [values]);
  // - //

  // Added use effect to validate data
  useEffect(() => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      validationSchema && !validateOnSubmit && validateValues();
    }, 250);

    return () => {
      clearTimeout(timeout);
    };
  }, [values, touched, validationSchema]);

  useEffect(() => {
    if (enableReinitialize && !isEqual(initValues, initValuesRef.current)) reset();
  }, [initValues]);

  return {
    formState: { values, errors, touched },
    handleSubmit: curry(handleSubmit),
    handleChange: curry(handleChange),
    handleBlur: curry(handleBlur),
    setField: curry(setField),
    setTouched: curry(setTouched),
    handleFocus,
    valuesChanged: valuesChangedRef.current,
    onBlur: onBlurRef.current,
    keyChanged: keyChangedRef,
    setValues,
    isValid,
    reset
  };
};

export default useFormState;
