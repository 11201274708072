import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faRightFromLine,
  faCommentsQuestion,
  faImageSlash,
  faCaretDown,
  faCaretUp,
  faLock
} from '@fortawesome/pro-duotone-svg-icons';
import { Layout, Menu, Space, Tooltip } from 'antd';
import { faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import useViewport from '../../_Hooks/useViewport';
import LogoIcon from '../Icons/Logo';
import SelectCustomerRefurbish from '../Select/SelectCustomerRefurbish';
import Drawer from '../Drawer/Drawer';

import { colors, spaces } from '../../styles/style';

import { useContextHook } from '../../contexts/GeneralContext';
import {
  StyledCompanyDiv,
  StyledCompanyName,
  StyledFooter,
  StyledHeader,
  StyledLogo,
  StyledLogoDiv,
  StyledSider
} from './NavBar.style';
import CompanyProgressDropDown from '../Dropdown/CompanyProgressDropDown';
import BubbleModalButton from '../Button/BubbleModalButton';
import { hasPermission } from '../../routes/Common/PrivateRoute';
import eventBus from '../../lib/helpers/eventBus';
import UserMenuOptions from './UserMenuOptions';
import openNewTab from '../../lib/helpers/openNewTab';

const NavBar = ({ paths }) => {
  const { isOpenNavBar, handleOpenCloseNavBar } = useContextHook();
  const { user } = useSelector(state => state.authReducer);
  const {
    plans,
    permissions,
    enums: { userType }
  } = useSelector(state => state.setup);
  const { isMobile } = useViewport(window.innerWidth);
  const refurbish = useSelector(state => state.refurbishReducer);
  const isCustomer = user.userType === userType.customer.value;
  const isProvider = user.userType === userType.provider.value;
  const [collapsed, setCollapsed] = useState(true);
  const [selectedKey, setSelectedKey] = useState({});

  const [openKeys, setOpenKeys] = useState([]);

  const history = useHistory();
  const location = useLocation();
  const rootSubmenuKeys = useMemo(() => Object.keys(paths), [paths]);

  const { ChangePasswordDrawer, options: userAreaMapping, UpgradeOption } = UserMenuOptions({});

  const facebookUrl = 'https://www.facebook.com/vobibr/';
  const instagramUrl = 'https://www.instagram.com/vobibr/';
  const youtubeUrl = 'https://www.youtube.com/channel/UCaOA4b_G2UXA9KNYB4twu9A/';

  const onOpenChange = keys => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  useEffect(() => {
    const pathname = location?.pathname + location?.search;
    let child;
    const _paths = Object.values(paths)?.concat(Object.values(userAreaMapping));
    const selectedMenu = Object.values(_paths)?.find(path => {
      child = path?.children?.find(childPath => pathname?.includes(childPath?.to));
      return child || pathname?.includes(path?.to);
    });
    setSelectedKey({
      childKey: child?.key,
      key: selectedMenu?.key
    });
  }, [location]);

  useEffect(() => {
    const isMenuCollapsed = () => eventBus.dispatch('menuCollapsed', { collapsed });
    eventBus.on('isMenuCollapsed', isMenuCollapsed);

    isMenuCollapsed();

    return () => eventBus.remove('isMenuCollapsed', isMenuCollapsed);
  }, [collapsed]);

  return isMobile() ? (
    <Drawer
      open={isOpenNavBar}
      placement="left"
      title=""
      width="90%"
      onClose={handleOpenCloseNavBar}
      hideFooter
      key="nav-key"
      padding="0"
      headerStyle={{ height: spaces.space8, borderRadius: '0', backgroundColor: colors.neutral95 }}
      bodyStyle={{ backgroundColor: colors.neutral95 }}
      $isMenuMobile
    >
      <ChangePasswordDrawer />
      {isCustomer && !user.anonymous ? <SelectCustomerRefurbish style={{ margin: `${spaces.space2} 0` }} /> : null}
      <StyledSider $isMobile width="100%" trigger={null}>
        {isProvider && (
          <StyledCompanyDiv $isMobile>
            <StyledLogoDiv>
              {user?.company && user?.company?.logo ? (
                <StyledLogo $url={user?.company?.logo} />
              ) : (
                <FontAwesomeIcon icon={faImageSlash} />
              )}
            </StyledLogoDiv>
            <div className="company-name">
              <StyledCompanyName>{user?.company?.name || user?.name}</StyledCompanyName>
            </div>
          </StyledCompanyDiv>
        )}
        <Menu
          inlineIndent={16}
          triggerSubMenuAction="click"
          theme="light"
          mode="inline"
          expandIcon={item => (
            <FontAwesomeIcon
              style={{ right: spaces.space1, position: 'absolute' }}
              icon={item?.isOpen ? faCaretUp : faCaretDown}
            />
          )}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
        >
          {paths
            ? Object.values(paths).map(content => {
                const { key, icon, to, label, roles, children, permissionId } = content;

                if (isCustomer && permissionId) {
                  const itemsToShare = refurbish?.itemsToShare || { [permissionId]: true };
                  if (!refurbish?.itemsToShare || !itemsToShare[permissionId]) return null;
                }

                const _hasPermission = hasPermission(user, roles, plans, permissions);
                const canRead = content.canRead && (!isMobile() ? !content.onlyMobile : true);

                if (_hasPermission === false || !canRead) {
                  return null;
                }

                return !children ? (
                  <Menu.Item
                    id={key}
                    onClick={() => {
                      if (_hasPermission) {
                        handleOpenCloseNavBar();
                        history.push(to);
                      }
                    }}
                    title={<span>{label}</span>}
                    key={key}
                  >
                    {_hasPermission === null ? (
                      <BubbleModalButton feature={roles[0]}>
                        <div role="presentation" className={`list-item ${selectedKey?.key === key ? 'selected' : ''}`}>
                          <div className="item-icon">{icon}</div>
                          <span>{label}</span>
                          <FontAwesomeIcon icon={faLock} color={colors.neutralSecondary80} />
                        </div>
                      </BubbleModalButton>
                    ) : (
                      <div className={`list-item ${selectedKey?.key === key ? 'selected' : ''}`}>
                        <div className="item-icon">{icon}</div>
                        <span>{label}</span>
                      </div>
                    )}
                  </Menu.Item>
                ) : (
                  <Menu.SubMenu
                    popupClassName="vobi-submenu-popup"
                    id={key}
                    title={
                      <div style={{ width: '100%' }}>
                        <div className={`list-item ${selectedKey?.key === key ? 'selected' : ''}`}>
                          <div className="item-icon">{icon}</div>
                          <span>{label}</span>
                        </div>
                      </div>
                    }
                    key={key}
                  >
                    {children.map(child => {
                      const hasChildPermission = hasPermission(user, child?.roles, plans, permissions);

                      if (hasChildPermission === false) {
                        return null;
                      }

                      return hasChildPermission ? (
                        <Menu.Item
                          className={`${selectedKey?.childKey === child?.key ? 'submenu-item-selected' : ''}`}
                          id={child?.key}
                          onClick={() => {
                            handleOpenCloseNavBar();
                            if (child?.external) {
                              return openNewTab(child?.to);
                            }

                            return history.push(child?.to);
                          }}
                          key={child?.key}
                        >
                          <span>{child?.label}</span>
                        </Menu.Item>
                      ) : (
                        <Menu.Item
                          className={`${selectedKey?.childKey === child?.key ? 'submenu-item-selected' : ''}`}
                          id={child?.key}
                          key={child?.key}
                        >
                          <BubbleModalButton feature={roles[0]}>
                            <div
                              role="presentation"
                              className={`list-item ${selectedKey?.key === key ? 'selected' : ''}`}
                            >
                              <span>{child?.label}</span>
                              <FontAwesomeIcon icon={faLock} color={colors.neutralSecondary80} />
                            </div>
                          </BubbleModalButton>
                        </Menu.Item>
                      );
                    })}
                  </Menu.SubMenu>
                );
              })
            : null}
        </Menu>
        <StyledCompanyDiv $isMobile $myArea>
          <div className="my-area">
            <span>Minha área</span>
          </div>
        </StyledCompanyDiv>
        <Menu
          inlineIndent={16}
          triggerSubMenuAction="click"
          theme="light"
          mode="inline"
          expandIcon={item => (
            <FontAwesomeIcon
              style={{ right: spaces.space1, position: 'absolute' }}
              icon={item?.isOpen ? faCaretUp : faCaretDown}
            />
          )}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
        >
          {userAreaMapping
            ? Object.values(userAreaMapping).map(content => {
                const { title, icon, onClick, to, shouldRender, key, external, upgrade } = content;
                if (shouldRender && upgrade) {
                  return <UpgradeOption />;
                }
                return (
                  shouldRender && (
                    <Menu.Item
                      id={key}
                      onClick={() => {
                        handleOpenCloseNavBar();
                        if (onClick) {
                          return onClick();
                        }
                        if (to && external) {
                          return openNewTab(to);
                        }
                        if (to) {
                          return history.push(`/${user.type + to}`);
                        }
                        return null;
                      }}
                      title={<span>{title}</span>}
                      key={key}
                    >
                      <div className={`list-item ${selectedKey?.key === key ? 'selected' : ''}`}>
                        <div className="item-icon">
                          <FontAwesomeIcon icon={icon} />
                        </div>
                        <span>{title}</span>
                      </div>
                    </Menu.Item>
                  )
                );
              })
            : null}
        </Menu>
        <Space direction="vertical" size={16} className="social-media-footer">
          <div>
            <LogoIcon scale={0.8} />
          </div>
          <div>
            <span>Acesse as nossas mídias sociais:</span>
          </div>
          <div style={{ display: 'flex' }}>
            <div className="social-icon-box facebook">
              <FontAwesomeIcon onClick={() => openNewTab(facebookUrl)} icon={faFacebook} />
            </div>
            <div className="social-icon-box instagram">
              <FontAwesomeIcon onClick={() => openNewTab(instagramUrl)} icon={faInstagram} />
            </div>
            <div className="social-icon-box youtube">
              <FontAwesomeIcon onClick={() => openNewTab(youtubeUrl)} icon={faYoutube} />
            </div>
          </div>
        </Space>
      </StyledSider>
    </Drawer>
  ) : (
    <Layout style={{ minHeight: '100%' }}>
      <StyledSider width={256} trigger={null} collapsible collapsed={collapsed}>
        <StyledHeader>
          <LogoIcon scale={0.6} />
          {!collapsed ? (
            <FontAwesomeIcon
              className="fa-flip-horizontal"
              style={{ padding: `0 ${spaces.space2}`, cursor: 'pointer' }}
              icon={faRightFromLine}
              onClick={() => {
                setCollapsed(true);
              }}
            />
          ) : (
            <div id="open-menu-button" className="open-menu-icon">
              <FontAwesomeIcon
                id="open-menu-icon"
                icon={faRightFromLine}
                onClick={() => {
                  setCollapsed(false);
                }}
              />
            </div>
          )}
        </StyledHeader>
        {isProvider && (
          <StyledCompanyDiv>
            <StyledLogoDiv>
              {user?.company && user?.company?.logo ? (
                <StyledLogo $url={user?.company?.logo} />
              ) : (
                <FontAwesomeIcon icon={faImageSlash} />
              )}
            </StyledLogoDiv>
            <div style={{ display: collapsed ? 'none' : 'contents' }}>
              <CompanyProgressDropDown />
            </div>
          </StyledCompanyDiv>
        )}
        <Menu
          inlineIndent={16}
          triggerSubMenuAction="click"
          theme="light"
          mode="inline"
          expandIcon={item =>
            !collapsed ? (
              <FontAwesomeIcon
                style={{ right: spaces.space1, position: 'absolute' }}
                icon={item?.isOpen ? faCaretUp : faCaretDown}
              />
            ) : null
          }
          openKeys={openKeys}
          onOpenChange={onOpenChange}
        >
          {paths
            ? Object.values(paths).map(content => {
                const { key, icon, to, label, roles, children, permissionId } = content;

                if (isCustomer && permissionId) {
                  const itemsToShare = refurbish?.itemsToShare || { [permissionId]: true };
                  if (!refurbish?.itemsToShare || !itemsToShare[permissionId]) return null;
                }

                const _hasPermission = hasPermission(user, roles, plans, permissions);
                const canRead = content.canRead && (!isMobile() ? !content.onlyMobile : true);

                if (_hasPermission === false || !canRead) {
                  return null;
                }

                return !children ? (
                  <Menu.Item
                    id={key}
                    onClick={() => {
                      if (_hasPermission) {
                        history.push(to);
                      }
                    }}
                    title={<span>{label}</span>}
                    key={key}
                  >
                    {_hasPermission === null ? (
                      <BubbleModalButton feature={roles[0]}>
                        <div
                          role="presentation"
                          className={`list-item ${collapsed ? 'collapsed' : ''} ${
                            selectedKey?.key === key ? 'selected' : ''
                          }`}
                        >
                          <div className="item-icon">{icon}</div>
                          {!collapsed && (
                            <>
                              <span>{label}</span>
                            </>
                          )}
                          <FontAwesomeIcon icon={faLock} color={colors.neutralSecondary80} />
                        </div>
                      </BubbleModalButton>
                    ) : (
                      <div
                        className={`list-item ${collapsed ? 'collapsed' : ''} ${
                          selectedKey?.key === key ? 'selected' : ''
                        }`}
                      >
                        <div className="item-icon">{icon}</div>
                        {!collapsed && <span>{label}</span>}
                      </div>
                    )}
                  </Menu.Item>
                ) : (
                  <Menu.SubMenu
                    popupClassName="vobi-submenu-popup"
                    id={key}
                    title={
                      <Tooltip placement="right" title={collapsed && label}>
                        <div style={{ width: '100%' }}>
                          <div
                            className={`list-item ${collapsed ? 'collapsed' : ''} ${
                              selectedKey?.key === key ? 'selected' : ''
                            }`}
                          >
                            <div className="item-icon">{icon}</div>
                            {!collapsed && <span>{label}</span>}
                          </div>
                        </div>
                      </Tooltip>
                    }
                    key={key}
                  >
                    {children.map(child => {
                      const hasChildPermission = hasPermission(user, child?.roles, plans, permissions);

                      if (hasChildPermission === false) {
                        return null;
                      }

                      return hasChildPermission ? (
                        <Menu.Item
                          className={`${selectedKey?.childKey === child?.key ? 'submenu-item-selected' : ''}`}
                          id={child?.key}
                          onClick={() => {
                            if (collapsed) {
                              setOpenKeys([]);
                            }
                            if (child?.external) {
                              return openNewTab(child?.to);
                            }
                            return history.push(child?.to);
                          }}
                          key={child?.key}
                        >
                          <span>{child?.label}</span>
                        </Menu.Item>
                      ) : (
                        <Menu.Item
                          className={`${selectedKey?.childKey === child?.key ? 'submenu-item-selected' : ''}`}
                          id={child?.key}
                          key={child?.key}
                        >
                          <BubbleModalButton feature={roles[0]}>
                            <div
                              role="presentation"
                              className={`list-item ${collapsed ? 'collapsed' : ''} ${
                                selectedKey?.key === key ? 'selected' : ''
                              }`}
                            >
                              <span>{child?.label}</span>

                              <FontAwesomeIcon icon={faLock} color={colors.neutralSecondary80} />
                            </div>
                          </BubbleModalButton>
                        </Menu.Item>
                      );
                    })}
                  </Menu.SubMenu>
                );
              })
            : null}
        </Menu>
        <StyledFooter id="menu-help">
          <FontAwesomeIcon icon={faCommentsQuestion} />
          {!collapsed && <span>Ajuda e guia de introdução</span>}
        </StyledFooter>
      </StyledSider>
    </Layout>
  );
};

NavBar.propTypes = {
  paths: PropTypes.instanceOf(Object)
};

export default NavBar;
