import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Space } from 'antd';
import * as dayjs from 'dayjs';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { MaskedInput } from '@homehero/hero-style';
import { toast } from 'react-toastify';
import { faPenToSquare, faCog } from '@fortawesome/pro-solid-svg-icons';
import { useContextHook } from '../../contexts/GeneralContext';
import Input from '../Input/Input';
import Select from '../Select/Select';
import DatePicker from '../Datepicker/Datepicker';
import Label from '../Label/Label';
import { Logo } from '../CoverImage/CoverImage.style';
import { colors, fonts } from '../../styles/style';
import {
  Container,
  ViewContainer,
  SubTitle,
  Text,
  ErrorStyled,
  TitleContainer,
  CustomerInformation,
  SelectDivider,
  IconCog
} from './PaymentBasicForm.styled';
import formatNumber from '../../lib/helpers/formatNumber';
import formatCurrency from '../../lib/helpers/formatCurrency';
import TooltipIcon from '../Icons/TooltipIcon';
import FinancialCategories from '../Drawer/FinancialCategories';
import EditOrCreateCustomer from '../Drawer/EditOrCreateCustomer';
import Button from '../Button/Button';

const selectOpts = { where: { isActive: true }, order: ['name'] };

const PaymentBasicForm = () => {
  const { billType: billTypeEnum } = useSelector(state => state.setup.enums);
  const [showCustomerDrawer, setCustomerDrawer] = useState(false);
  const {
    setField,
    isView,
    touched,
    errors,
    handleChange,
    handleBlur,
    values,
    statusColor,
    getRefurbish,
    getCustomer,
    isCharge,
    _refurbish,
    setRefurbish,
    handleCancel,
    isPublic,
    isCustomerView
  } = useContextHook();
  const {
    id,
    paymentStatus,
    name,
    idFinancialCategory,
    idCompanyCustomer,
    idSupplier,
    idRefurbish,
    company,
    companyCustomer,
    billingDate,
    value,
    billType
  } = values || {};
  const billTypeIncome = billType === 'income';
  const [_companyCustomer, setCompanyCustomer] = useState(companyCustomer);
  const [showFinancials, setShowFinancials] = useState(false);
  const typeLabel = isCharge ? 'cobrança' : billTypeEnum[billType];
  const showEditCustomer = _companyCustomer && billTypeIncome && !isPublic && !isCustomerView && id;

  const handleChangeRefurbish = val => {
    if (setRefurbish) setRefurbish(null);
    setField('idRefurbish')(val);
    setField('refurbish')(null);
  };

  const handleChangeCompanyCustomer = val => {
    setCompanyCustomer(null);
    setField('idCompanyCustomer')(val);
    setField('companyCustomer')(null);
  };

  const handleChangeSupplier = val => {
    setField('idSupplier')(val);
  };

  useEffect(() => {
    if (idRefurbish && !isView) {
      getRefurbish({ refetchPathOptions: `/${idRefurbish}` }).then(res => {
        if (!res) {
          toast.error('Projeto/Oportunidade vinculado a essa cobrança não foi encontrado.');
          handleCancel && handleCancel();
          return;
        }
        if (setRefurbish) setRefurbish(res);
        if (res.idCompanyCustomer && !idCompanyCustomer) setField('idCompanyCustomer')(res.idCompanyCustomer);
      });
    }
  }, [idRefurbish]);

  useEffect(() => {
    if (idCompanyCustomer && !isView) {
      getCustomer({ refetchPathOptions: `/${idCompanyCustomer}` }).then(res => {
        if (setCompanyCustomer) setCompanyCustomer(res);
      });
    }
  }, [idCompanyCustomer]);

  return (
    <>
      {isView ? (
        <ViewContainer>
          <div className="title">
            <TitleContainer>
              <h6>{name}</h6>
              {paymentStatus?.name && (
                <div className="statusContainer">
                  Status: <span style={{ color: statusColor }}>{paymentStatus?.name}</span>
                </div>
              )}
            </TitleContainer>
            {billingDate && (
              <Text>
                Data da {typeLabel}: {dayjs(billingDate).format('DD/MM/YYYY')}
              </Text>
            )}
          </div>
          <div className="displayData">
            <div className="companyData">
              <div className="logoContainer">
                <Logo $image={company?.logo} />
              </div>
              <Space direction="vertical" size={8}>
                <SubTitle>{company?.name || company?.legalName || 'Dados da empresa não informado'}</SubTitle>
                {company?.phone && <Text>{company?.phone}</Text>}
                {company?.email && <Text>{company?.email}</Text>}
                <Text>CNPJ/CPF: {company?.cnpj || '-'}</Text>
                <Text>
                  {company?.street ? `${company?.street}, ${company?.number || ''}` : ''}
                  {company?.complement ? `, ${company?.complement}` : ''}
                  {company?.neighborhood ? ` - ${company?.neighborhood}` : ''}
                  {company?.city ? `, ${company?.city}` : ''}
                  {company?.state ? `, ${company?.state}` : ''}
                </Text>
              </Space>
            </div>
            <div className="customerData">
              <Space direction="vertical" size={8}>
                <CustomerInformation>
                  <p style={{ fontSize: fonts.sizeSm }}>INFORMAÇÕES DO CLIENTE</p>
                  {showEditCustomer && (
                    <TooltipIcon
                      style={{ color: colors.primary40 }}
                      text="Editar informações do cliente"
                      icon={faPenToSquare}
                      onClick={() => setCustomerDrawer(true)}
                    />
                  )}
                </CustomerInformation>
                {_companyCustomer?.name ? (
                  <Text>
                    {_companyCustomer?.name}
                    {_companyCustomer?.phone ? ` - ${_companyCustomer?.phone}` : ''}
                  </Text>
                ) : (
                  <Text>Cliente não informado</Text>
                )}
                {_refurbish?.name && <Text>Projeto: {_refurbish.name}</Text>}
                {_companyCustomer?.email && <Text>{_companyCustomer?.email}</Text>}
                {_companyCustomer?.doc && <Text>CNPJ/CPF: {_companyCustomer?.doc}</Text>}
                <Text>
                  {_companyCustomer?.street ? `${_companyCustomer?.street}, ${_companyCustomer?.number || ''}` : ''}
                  {_companyCustomer?.complement ? `, ${_companyCustomer?.complement}` : ''}
                  {_companyCustomer?.neighborhood ? ` - ${_companyCustomer?.neighborhood}` : ''}
                  {_companyCustomer?.city ? `, ${_companyCustomer?.city}` : ''}
                  {_companyCustomer?.state ? `, ${_companyCustomer?.state}` : ''}
                </Text>
              </Space>
            </div>
          </div>
        </ViewContainer>
      ) : (
        <Container>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <SubTitle>Dados da {typeLabel}</SubTitle>
            {!isCharge && id && (
              <Text>Data de criação: {dayjs(values.createdAt).format('DD/MM/YYYY [às] HH:mm:ss')}</Text>
            )}
          </div>
          <Row gutter={[16, 16]}>
            <Col md={9} xs={24}>
              <Label htmlFor="paymentName">Nome da {typeLabel}*</Label>
              {isCharge && (
                <TooltipIcon
                  style={{ color: colors.primary40, marginLeft: '5px' }}
                  icon={faInfoCircle}
                  color={colors.primary40}
                  text="O nome será exibido para o cliente e te ajuda a identificar a cobrança."
                  size="lg"
                  trigger={['hover', 'click']}
                />
              )}
              <Input
                type="text"
                id="paymentName"
                size="middle"
                name="paymentName"
                placeholder="Ex: Projeto casa na praia."
                value={name}
                onChange={handleChange('name')}
                onBlur={handleBlur('name')}
              />
              {touched.name && errors?.name && <ErrorStyled>{errors?.name}</ErrorStyled>}
            </Col>
            <Col md={9} xs={24}>
              <Label>Categoria financeira</Label>
              {!showFinancials && (
                <Select
                  name="financialCategory"
                  id="financialCategory"
                  model="financialCategory"
                  modelOptions={{
                    ...selectOpts,
                    where: { ...selectOpts.where, billType: { or: [billType, null] } },
                    order: [['name', 'asc']]
                  }}
                  placeholder="Selecione a categoria"
                  value={idFinancialCategory}
                  onChange={setField('idFinancialCategory')}
                  onBlur={handleBlur('idFinancialCategory')}
                  allowClear
                  showSearch
                  allowCreate
                  dropdownRender={menu => (
                    <>
                      {menu}
                      <SelectDivider />
                      <Button type="primary" text onClick={() => setShowFinancials(true)}>
                        <IconCog icon={faCog} />
                        Editar opções
                      </Button>
                    </>
                  )}
                  createParams={{ billType, isActive: true }}
                />
              )}
              {touched.idFinancialCategory && errors?.idFinancialCategory && (
                <ErrorStyled>{errors?.idFinancialCategory}</ErrorStyled>
              )}
            </Col>
            <Col md={6} xs={24}>
              <div className="paymentDate">
                <Label>Data da {typeLabel}*</Label>
                <TooltipIcon
                  style={{ color: colors.primary40 }}
                  icon={faInfoCircle}
                  color={colors.primary40}
                  text={
                    <span>
                      Também chamada <strong>data de competência</strong>, é a data em que o lançamento foi gerado,
                      independente dos pagamentos das parcelas
                    </span>
                  }
                  size="lg"
                  trigger={['hover', 'click']}
                />
              </div>
              <DatePicker
                id="billingDate"
                name="billingDate"
                style={{ width: '100%' }}
                format="DD/MM/YYYY"
                value={billingDate ? dayjs(billingDate) : dayjs()}
                allowClear={false}
                onChange={setField('billingDate')}
                onBlur={handleBlur('billingDate')}
              />
              {touched.billingDate && errors?.billingDate && <ErrorStyled>{errors?.billingDate}</ErrorStyled>}
            </Col>
            <Col md={9} xs={24}>
              <Label>Projeto/Oportunidade{isCharge ? ' *' : ''}</Label>
              <Select
                name="paymentRefurbish"
                id="paymentRefurbish"
                model="refurbish"
                modelOptions={{ ...selectOpts, order: [['name', 'asc']] }}
                placeholder="Selecione o projeto/oportunidade"
                value={idRefurbish}
                onChange={handleChangeRefurbish}
                onBlur={handleBlur('idRefurbish')}
                allowClear
                showSearch
              />
              {touched.idRefurbish && errors?.idRefurbish && <ErrorStyled>{errors?.idRefurbish}</ErrorStyled>}
            </Col>
            <Col md={9} xs={24}>
              <CustomerInformation>
                <Label>{`${billTypeIncome ? 'Cliente' : 'Fornecedor'}${isCharge ? ' *' : ''}`}</Label>
                {showEditCustomer && (
                  <TooltipIcon
                    style={{ color: colors.primary40 }}
                    text="Editar informações do cliente"
                    icon={faPenToSquare}
                    onClick={() => setCustomerDrawer(true)}
                  />
                )}
              </CustomerInformation>
              {!showCustomerDrawer && (
                <Select
                  name={`payment${billTypeIncome ? 'companyCustomer' : 'supplier'}`}
                  id={`payment${billTypeIncome ? 'companyCustomer' : 'supplier'}`}
                  model={billTypeIncome ? 'companyCustomer' : 'supplier'}
                  modelOptions={selectOpts}
                  placeholder={`Selecione o ${billTypeIncome ? 'cliente' : 'fornecedor'}`}
                  value={billTypeIncome ? idCompanyCustomer : idSupplier}
                  onChange={billTypeIncome ? handleChangeCompanyCustomer : handleChangeSupplier}
                  onBlur={handleBlur(`${billTypeIncome ? 'idCompanyCustomer' : 'idSupplier'}`)}
                  allowClear
                  showSearch
                  allowCreate
                />
              )}
              {billTypeIncome && touched.idCompanyCustomer && errors?.idCompanyCustomer && (
                <ErrorStyled>{errors?.idCompanyCustomer}</ErrorStyled>
              )}
              {!billTypeIncome && touched.idSupplier && errors?.idSupplier && (
                <ErrorStyled>{errors?.idSupplier}</ErrorStyled>
              )}
            </Col>
            {!isCharge && (
              <Col md={6} xs={24}>
                <Label>Valor:*</Label>
                <MaskedInput
                  placeholder=""
                  type="currency"
                  onChange={e => setField('value')(formatNumber(e.target.value))}
                  onBlur={handleBlur('value')}
                  defaultValue={formatCurrency(value, {
                    currencySymbol: 'R$ '
                  })}
                  maskOptions={{
                    prefix: 'R$',
                    thousandsSeparatorSymbol: '.',
                    allowDecimal: true
                  }}
                  style={{ height: '32px', flex: 2 }}
                />
                {touched.value && errors?.value && <ErrorStyled>{errors?.value}</ErrorStyled>}
              </Col>
            )}
          </Row>
        </Container>
      )}
      {showCustomerDrawer && (
        <EditOrCreateCustomer
          idCustomer={_companyCustomer?.id}
          onClose={() => setCustomerDrawer(false)}
          setCompanyCustomer={setCompanyCustomer}
        />
      )}
      {showFinancials && <FinancialCategories onClose={() => setShowFinancials(false)} tab={billType} />}
    </>
  );
};

export default PaymentBasicForm;
