import React, { useEffect, useState } from 'react';
import { Menu, Tooltip } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { getInitials } from '../../lib/helpers/helper';
// hooks
import useCRUD from '../../_Hooks/useCRUD';
import { useContextHook as useRefurbish } from '../../contexts/Context';

import Avatar from '../AvatarEditor/Avatar';
import Dropdown from '../Dropdown/Dropdown';
import Button from '../Button/Button';
import { colors } from '../../styles/style';

const AddButton = styled(Button)`
  border: 1px dashed ${colors.neutralSecondary90} !important;
  border-radius: 20px;
  width: 32px;
  position: absolute;
  right: -20px;
  padding: 0;
`;

const RemoveDiv = styled.div`
  position: relative;

  button {
    display: none;
  }

  &:hover {
    button {
      font-weight: 500;
      display: block;
      color: #fff;
      background-color: ${colors.neutral50};
      padding: 2px 4px;
      border-radius: 50%;
      position: absolute;
      right: 0;
      z-index: 3;
      font-size: 5px;
      cursor: pointer;
      border: 2px solid white;
      height: 15px;
    }
  }
`;
const ProjectResponsibleActions = ({ shouldUpdate = true }) => {
  const { data, handleGet, handleUpdate, handleDelete } = useRefurbish();
  const [usersDropdown, setUsersDropdown] = useState([]);
  const { user } = useSelector(state => state.authReducer);
  const [reload, setReload] = useState(0);
  const [list, setList] = useState([]);
  const history = useHistory();
  const { handleGet: getUsers } = useCRUD({
    model: 'user',
    immediatelyLoadData: false
  });
  const deleteResponsible = (e, responsible) => {
    handleDelete({
      id: data.id,
      deletePathOptions: `/user/${responsible?.id}`,
      refresh: false,
      noLoading: true
    }).then(() => {
      if (shouldUpdate) {
        if (responsible.id === user.id && user.limitAccess) return history.push(`/profissional/projetos`);
        return handleGet({ generateLoading: false });
      }
      return setReload(prev => prev + 1);
    });
  };

  const addResponsible = (e, responsible) => {
    handleUpdate({
      id: data.id,
      updatePathOptions: `/responsible/${responsible.id}`,
      refresh: false,
      noLoading: true
    }).then(() => {
      if (shouldUpdate) {
        handleGet({ generateLoading: false });
      } else {
        setReload(prev => prev + 1);
      }
    });
  };

  useEffect(() => {
    if (data) {
      getUsers({
        refetchOptions: {
          where: { userType: 'provider', idCompany: data?.idCompany },
          order: [['name']],
          include: [{ model: 'refurbish', where: { id: data?.id }, required: false }]
        }
      }).then(_users => {
        setList(_users?.filter(u => u?.refurbishes.length));
        setUsersDropdown(_users?.filter(u => !u?.refurbishes.length));
      });
    }
  }, [data, reload]);

  return (
    <div>
      <small style={{ color: colors.neutral60 }}>Colaboradores</small>
      <div style={{ display: 'flex', margin: '8px 40px 0 0', position: 'relative', minHeight: '30px' }}>
        <Avatar.Group style={{ zIndex: 2 }}>
          {list?.map(refurbishUser => (
            <div key={refurbishUser.id}>
              <Tooltip title={refurbishUser?.name}>
                <RemoveDiv key={`remove_${refurbishUser.id}`} style={{ position: 'relative' }}>
                  {list?.length > 1 && (
                    <Button id="delete-responsible" onClick={e => deleteResponsible(e, refurbishUser)}>
                      x
                    </Button>
                  )}
                  <Avatar key={`avatar${refurbishUser.id}`} src={refurbishUser.avatarFullpath}>
                    {getInitials(refurbishUser?.name)}
                  </Avatar>
                </RemoveDiv>
              </Tooltip>
            </div>
          ))}
        </Avatar.Group>
        <div id="add-collaborator">
          <Dropdown
            trigger={['click']}
            slim
            menu={
              <Menu style={{ borderRadius: '8px' }}>
                {usersDropdown?.map(refurbishUser => (
                  <Menu.Item key={refurbishUser.id}>
                    <Button text onClick={e => addResponsible(e, refurbishUser)}>
                      {refurbishUser.name}
                    </Button>
                  </Menu.Item>
                ))}
                <Menu.Item>
                  <Link to="/profissional/configuracoes/equipe">
                    <Button id="addCollaborator" text onClick={() => {}}>
                      + Adicionar colaborador
                    </Button>
                  </Link>
                </Menu.Item>
              </Menu>
            }
          >
            <Tooltip title="Adicionar membros da equipe">
              <span style={{ padding: 0 }}>
                <AddButton> + </AddButton>
              </span>
            </Tooltip>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

ProjectResponsibleActions.propTypes = {
  shouldUpdate: PropTypes.bool
};

export default ProjectResponsibleActions;
