import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../store/auth/actions/action';
import useCRUD from '../_Hooks/useCRUD';

const Logout = () => {
  const dispatch = useDispatch();

  const { handleGet } = useCRUD({ model: 'auth', immediatelyLoadData: false, pathOptions: '/logout' });

  useEffect(() => {
    handleGet();
    if (window.flutter_inappwebview) window.flutter_inappwebview.callHandler('vobiLogout', {}).then(() => {});
    dispatch(logout());
  }, []);
  return <Redirect to="/login" />;
};
export default Logout;
