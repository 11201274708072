import React from 'react';
import PropTypes from 'prop-types';
import * as dayjs from 'dayjs';
import { MaskedInput } from '@homehero/hero-style';
import { getColorByStatusInstallment } from '../../lib/helpers/helper';
import TableActionDropDown from '../Dropdown/TableActionDropDown';
import { allDropdown } from '../../lib/mapping/Dropdown/allDropdown';
import {
  CardFooter,
  InlineItems,
  StyledCardMobile,
  StyledCardRow,
  StyledStatus,
  StyledText,
  Text
} from './PaymentConfigurationMobileCard.styles';
import DatePicker from '../Datepicker/Datepicker';
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import { Line } from '../Form/PaymentFormItem.styled';
import formatCurrency from '../../lib/helpers/formatCurrency';
import formatNumber from '../../lib/helpers/formatNumber';
import { spaces } from '../../styles/style';
import { ErrorStyled } from '../Form/PaymentBasicForm.styled';

const PaymentConfigurationMobileCard = ({
  list = [],
  isView,
  installmentStatuses,
  numberOfInstalments,
  handleChange,
  handleBlur,
  handleLoad,
  errors,
  touched,
  showInstallmentActions
}) => {
  return (
    list &&
    list?.map((item, index) => {
      const statusColor = getColorByStatusInstallment(item.idInstallmentStatus, installmentStatuses);
      return (
        <StyledCardMobile key={item?.id}>
          <InlineItems isView={isView}>
            <StyledCardRow className="installment-field-wrapper">
              <StyledText>Parcela</StyledText>
              <Text className="field">{`${index + 1}/${numberOfInstalments}`}</Text>
            </StyledCardRow>
            {!isView && (
              <StyledCardRow className="percentual-field-wrapper">
                <StyledText>Percentual</StyledText>

                <MaskedInput
                  type="currency"
                  value={formatCurrency(item.percentage, {
                    currencySymbol: ''
                  })}
                  onChange={e => handleChange(index, 'percentage', formatNumber(e.target.value))}
                  onBlur={handleBlur}
                  maskOptions={{
                    suffix: '%',
                    prefix: '',
                    includeThousandsSeparator: false,
                    allowDecimal: true
                  }}
                  style={{ paddingLeft: `${spaces.space1}`, paddingRight: `${spaces.space1}`, height: '32px' }}
                />
                {touched.installments && errors[`installments[${index}].percentage`] && (
                  <ErrorStyled>{errors[`installments[${index}].percentage`]}</ErrorStyled>
                )}
              </StyledCardRow>
            )}

            <StyledCardRow className="value-field-wrapper">
              <StyledText>Valor</StyledText>
              {!isView ? (
                <MaskedInput
                  type="currency"
                  value={formatCurrency(item.price, {
                    currencySymbol: 'R$ '
                  })}
                  onChange={e => handleChange(index, 'price', formatNumber(e.target.value))}
                  onBlur={handleBlur}
                  maskOptions={{
                    prefix: 'R$',
                    thousandsSeparatorSymbol: '.',
                    allowDecimal: true
                  }}
                  style={{ paddingLeft: `${spaces.space1}`, paddingRight: `${spaces.space1}`, height: '32px' }}
                />
              ) : (
                <Text>
                  {formatCurrency(item.price, {
                    currencySymbol: 'R$ '
                  })}
                </Text>
              )}
              {touched.installments && errors[`installments[${index}].price`] && (
                <ErrorStyled>{errors[`installments[${index}].price`]}</ErrorStyled>
              )}
            </StyledCardRow>
          </InlineItems>
          <InlineItems>
            <StyledCardRow className="flex--1">
              <StyledText>Vencimento</StyledText>
              {!isView ? (
                <DatePicker
                  id="billingDate"
                  name="billingDate"
                  style={{ width: '100%' }}
                  format="DD/MM/YYYY"
                  allowClear={false}
                  value={item?.dueDate ? dayjs(item?.dueDate) : null}
                  onChange={value => handleChange(index, 'dueDate', value)}
                />
              ) : (
                <Text>{item?.dueDate ? dayjs(item?.dueDate).format('DD/MM/YYYY') : '-'}</Text>
              )}
              {touched.installments && errors[`installments[${index}].dueDate`] && (
                <ErrorStyled>{errors[`installments[${index}].dueDate`]}</ErrorStyled>
              )}
            </StyledCardRow>
            <StyledCardRow className="flex--1">
              <StyledText>Status</StyledText>
              <StyledStatus statusColor={statusColor} isView={isView}>
                {item?.installmentStatus?.name || ''}
              </StyledStatus>
            </StyledCardRow>
          </InlineItems>
          <StyledCardRow style={{ paddingRight: spaces.space2, paddingLeft: spaces.space2 }}>
            <StyledText>Descrição</StyledText>
            {!isView ? (
              <RichTextEditor
                value={item?.description || ''}
                style={{ width: '100%' }}
                height={100}
                toolbar={false}
                statusbar={false}
                outputFormat="text"
                disabled={isView}
                onChange={value => handleChange(index, 'description', value)}
              />
            ) : (
              <Text>{item.description}</Text>
            )}
            {touched.installments && errors[`installments[${index}].description`] && (
              <ErrorStyled>{errors[`installments[${index}].description`]}</ErrorStyled>
            )}
          </StyledCardRow>
          {showInstallmentActions && (
            <>
              <Line />
              <CardFooter>
                <TableActionDropDown
                  key={`action${item.id}`}
                  item={item}
                  mappingObj={allDropdown.paymentChildren({ installment: item })}
                  afterSubmit={handleLoad}
                  model="installment"
                  noPadding
                />
              </CardFooter>
            </>
          )}
        </StyledCardMobile>
      );
    })
  );
};

PaymentConfigurationMobileCard.propTypes = {
  list: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  isView: PropTypes.bool,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleLoad: PropTypes.func
};

export default PaymentConfigurationMobileCard;
