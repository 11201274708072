import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import Modal from './Modal';
import Form from '../Form/Form';
import {
  schema,
  templateMapping,
  applyTemplateMapping,
  schemaApplyTemplate
} from '../../lib/mapping/Form/templateSchema';
import { projectSchema, projectMapping } from '../../lib/mapping/Form/projectSchema';
import useCRUD from '../../_Hooks/useCRUD';
import useViewport from '../../_Hooks/useViewport';
import { useContextHook } from '../../contexts/Context';

const { Text } = Typography;

const UseProjectAsTemplateModal = ({
  onClose,
  id,
  model,
  pathOptions,
  name,
  necessityDescription,
  modalType,
  title,
  text,
  type,
  image,
  successText,
  description,
  showTemplateType,
  idStep,
  textHeader,
  source,
  idTemplate,
  afterSubmit
}) => {
  const { isMobile } = useViewport(window.innerWidth);
  const { handleGet, refreshOnSubmit } = useContextHook();
  const { userType } = useSelector(state => state.setup.enums);
  const { user } = useSelector(state => state.authReducer);
  const isOperator = user?.userType === userType.operator.value;

  const { handleCreate } = useCRUD({
    model,
    pathOptions,
    immediatelyLoadData: false
  });

  const itemName = modalType === 'Template' ? `Cópia de ${name}` : name;

  const { handleGet: handleGetTemplate } = useCRUD({
    model: 'template',
    immediatelyLoadData: false
  });

  const onSubmit = values => {
    handleCreate({
      values: {
        ...values,
        idReference: id || values.idReference,
        type,
        image: image || values.image,
        idStep,
        source,
        idTemplate
      },
      refresh: false,
      displayToast: successText
    }).then(resp => {
      if (!resp?.error) {
        if (afterSubmit) {
          afterSubmit(resp);
        }
        const _handleGetTemplate = refreshOnSubmit === false ? Promise.resolve.bind(Promise) : handleGetTemplate;
        const get = modalType !== 'Projeto' ? _handleGetTemplate() : handleGet();
        get.then(onClose());
      }
    });
  };

  const defineSchema = () => {
    if (modalType === 'Projeto') {
      return projectSchema(true);
    }
    if (modalType === 'Template') {
      return schema(true);
    }
    return schemaApplyTemplate();
  };

  return (
    <Modal title={title} hideFooter open onClose={() => onClose(false)} sectionStyle={{ paddingTop: '0px' }}>
      <div style={{ display: 'block' }}>
        <Text>{textHeader}</Text>
        <Form
          style={{ marginTop: '8px' }}
          customButtonTitle={modalType === 'ApplyProject' ? 'Adicionar' : 'Confirmar'}
          id="templateForm"
          mapping={() => {
            if (modalType === 'Projeto') {
              return projectMapping({ isMobile: isMobile(), isModal: true });
            }
            if (modalType === 'Template') {
              return templateMapping({ isMobile: isMobile(), isModal: true, showTemplateType, type, isOperator });
            }
            return applyTemplateMapping();
          }}
          schema={defineSchema()}
          onSubmit={onSubmit}
          data={{
            fieldsHeader: text,
            name: itemName,
            description: modalType === 'Template' ? description : necessityDescription,
            image,
            type
          }}
        />
      </div>
    </Modal>
  );
};

UseProjectAsTemplateModal.propTypes = {
  id: PropTypes.number,
  onClose: PropTypes.func,
  model: PropTypes.string,
  pathOptions: PropTypes.string,
  name: PropTypes.string,
  modalType: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  necessityDescription: PropTypes.string,
  type: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  image: PropTypes.instanceOf(Array),
  successText: PropTypes.string,
  description: PropTypes.string,
  showTemplateType: PropTypes.bool,
  idStep: PropTypes.number,
  textHeader: PropTypes.string,
  source: PropTypes.string,
  idTemplate: PropTypes.number,
  afterSubmit: PropTypes.func
};

export default UseProjectAsTemplateModal;
