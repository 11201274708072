import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import * as icons from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Meta from 'antd/lib/card/Meta';
import { Tooltip } from 'antd';
import { StyledDiv, StyledCard, StyleTag } from './TemplateCard.styled';
import emptyImage from '../Images/empty-state-image.svg';
import vobiLogo from '../Images/vobi-logo.svg';

import Button from '../Button/Button';
import { colors } from '../../styles/style';

const TemplateCard = ({ data = {}, onSelect = null, onApply, icon, logo = false }) => {
  const _image = data.image || (data.images && data.images[0]);

  return (
    <StyledDiv role="presentation" onClick={onSelect}>
      <Button
        id="use-template-button"
        type="primary"
        onClick={e => {
          e.stopPropagation();
          onApply();
        }}
      >
        {' '}
        Usar
      </Button>

      <StyledCard
        cover={
          <Tooltip
            title={
              data.description
                ? parse(data.description.length > 110 ? `${data.description.substring(0, 110)}...` : data.description)
                : null
            }
          >
            <img
              style={{
                objectPosition: _image ? 'top' : 'center',
                objectFit: 'cover'
              }}
              height={150}
              src={_image || emptyImage}
              alt={data.name}
            />
          </Tooltip>
        }
      >
        {logo && data.idCompany === null && (
          <StyleTag>
            <img src={vobiLogo} alt="Template" />
          </StyleTag>
        )}
        <Tooltip title={data.name}>
          <Meta avatar={<FontAwesomeIcon icon={icons[icon]} color={colors.primary40} />} title={data.name} />
        </Tooltip>
      </StyledCard>
    </StyledDiv>
  );
};

TemplateCard.propTypes = {
  data: PropTypes.instanceOf(Object),
  onSelect: PropTypes.func,
  onApply: PropTypes.func,
  icon: PropTypes.string,
  logo: PropTypes.bool
};

export default TemplateCard;
