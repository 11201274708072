import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Badge } from 'antd';
import * as dayjs from 'dayjs';
import { colors } from '../../styles/style';
import {
  StyledNotificationDropdown,
  StyledNotificationDropdownItem,
  ItemText,
  ItemTextDescription,
  ItemTextTime,
  Title,
  Footer
} from './NotificationDropdownStyled';

import Avatar from '../AvatarEditor/Avatar';
import NoContent from '../NoContent/NoContent';
import { getInitials } from '../../lib/helpers/helper';

const NotificationDropdown = ({ items, userType, markRead = () => {}, clearAll = f => f, ...antProps }) => {
  return (
    <StyledNotificationDropdown {...antProps}>
      <Title>
        <h6>Notificações</h6>
      </Title>
      {items?.length ? (
        items.map(({ id, producerName, message, createdAt, location, hasSeen }) => {
          const internal = location?.indexOf('plataforma.') >= 0 || location?.[0] === '/';

          return (
            <StyledNotificationDropdownItem
              key={id}
              onClick={() => markRead(id)}
              href={location}
              target={internal ? '' : '_blank'}
            >
              <Avatar size="large">{getInitials(producerName)}</Avatar>
              <ItemText>
                <ItemTextDescription>{message}</ItemTextDescription>
                <ItemTextTime>{dayjs(createdAt).fromNow()}</ItemTextTime>
              </ItemText>
              {!hasSeen && <Badge color={colors.primary40} dot />}
            </StyledNotificationDropdownItem>
          );
        })
      ) : (
        <NoContent description="Você não possui nenhuma notificação" renderTitle={false} renderButton={false} />
      )}
      <Link onClick={clearAll} key={(items?.length || 0) + 1} to={`/${userType}/notificacoes`}>
        <Footer>Ver todas</Footer>
      </Link>
    </StyledNotificationDropdown>
  );
};

NotificationDropdown.propTypes = {
  items: PropTypes.arrayOf(Object),
  userType: PropTypes.string,
  markRead: PropTypes.func,
  clearAll: PropTypes.func
};

export default NotificationDropdown;
