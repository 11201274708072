import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Typography, Space } from 'antd';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import useCRUD from '../../_Hooks/useCRUD';
import Drawer from '../Drawer/Drawer';
import Watcher from '../Watcher/Watcher';
import useViewport from '../../_Hooks/useViewport';
import Checklist from '../Checklist/Checklist';
import { taskMapping, taskSchema } from '../../lib/mapping/Form/taskSchema';
import Form from '../Form/Form';
import CenteredLoader from '../Loader/CenteredLoader';
import { getMinutesFromHHMM } from '../../lib/helpers/helper';
import useReplaceUrl from '../../_Hooks/useReplaceUrl';
import ShareLinkDropdown from '../Dropdown/ShareLinkDropdown';

const { Text } = Typography;

const EditInfoModal = ({
  id = '',
  initData = {},
  onClose = f => f,
  title = 'Editar',
  model = 'task',
  options = {},
  idRefurbish,
  idTemplate,
  idCompany,
  canChangeRefurbish = false,
  afterSubmit = f => f,
  generalTask = false,
  copyLinkParam = 't'
}) => {
  const { data, handleUpdate, handleCreate, loading } = useCRUD({
    model,
    pathOptions: id ? `/${id}` : '',
    options,
    immediatelyLoadData: !!id
  });

  const { checklist } = data || {};

  const [_checklist, setChecklist] = useState(checklist || []);
  const [loadData, setLoadData] = useState({});
  const [editingData, setEditingData] = useState({});
  const { isMobile } = useViewport(window.innerWidth);
  const { execution } = useSelector(state => state.setup.enums.refurbishStatus);

  const { returnToOriginalUrl } = useReplaceUrl({
    preventTrigger: !copyLinkParam || !!idTemplate || !id,
    urlToReplace: `/${copyLinkParam}/${id}`
  });

  const handleSubmit = values => {
    const { realDuration, estimativeDuration, startDate, endDate, startHours, endHours } = values;

    const _startDate = startDate
      ? dayjs(startDate)
          ?.hour(dayjs(startHours || '00:00', 'HH:mm')?.hour())
          ?.minute(dayjs(startHours || '00:00', 'HH:mm')?.minute())
          ?.second(0)
      : startDate;
    const _endDate = endDate
      ? dayjs(endDate)
          ?.hour(dayjs(endHours || '23:59', 'HH:mm')?.hour())
          ?.minute(dayjs(endHours || '23:59', 'HH:mm')?.minute())
          ?.second(0)
      : endDate;

    const func = !id ? handleCreate : handleUpdate;

    const _data = {
      idRefurbish,
      ...values,
      startDate: _startDate,
      endDate: _endDate,
      ...(realDuration && { realDuration: getMinutesFromHHMM(realDuration) }),
      ...(estimativeDuration && { estimativeDuration: getMinutesFromHHMM(estimativeDuration) }),
      checklist: _checklist,
      idStep: values.idStep || 1
    };
    setEditingData(_data);
    return func({
      values: _data,
      refresh: false
    }).then(resp => {
      if (!resp.error) {
        returnToOriginalUrl();
        onClose(true, { task: resp });
        afterSubmit(resp);
      }
    });
  };

  useEffect(() => {
    setChecklist(checklist);
  }, [checklist]);

  useEffect(() => {
    if (data) {
      setLoadData({
        ...data,
        ...(data?.startDate && { startHours: dayjs(data?.startDate).format('HH:mm') }),
        ...(data?.endDate && { endHours: dayjs(data?.endDate).format('HH:mm') })
      });
    }
  }, [data]);

  const _taskMapping = useMemo(
    () =>
      taskMapping({
        idRefurbish,
        idTemplate,
        idCompany,
        canChangeRefurbish,
        idTask: id,
        generalTask,
        execution
      }),
    []
  );

  const HeaderExtraButton = useCallback(() => {
    return id ? (
      <>
        <Watcher idTask={id} idCompany={idCompany} />
        <ShareLinkDropdown id={id} urlContext="t" />
      </>
    ) : null;
  }, [id, idCompany]);

  return (
    <Drawer
      open
      formId="taskForm"
      title={title}
      onClose={() => {
        returnToOriginalUrl();
        onClose();
      }}
      width={isMobile() ? '100%' : '60%'}
      headerExtraContent={HeaderExtraButton}
      styleDiv={{ display: 'flex', alignItems: 'center' }}
    >
      {loading ? (
        <CenteredLoader />
      ) : (
        <Space direction="vertical" size={8}>
          <Form
            loading={loading}
            schema={taskSchema}
            mapping={_taskMapping}
            initialParentValues={{
              startDate: data?.startDate || initData?.startDate,
              endDate: data?.endDate || initData?.endDate
            }}
            data={!id ? { ...initData, ...editingData } : loadData}
            id="taskForm"
            onSubmit={handleSubmit}
            displayButtons={false}
          />
          <Text>Checklist</Text>
          <Checklist list={_checklist ? [..._checklist] : []} onChange={setChecklist} />
        </Space>
      )}
    </Drawer>
  );
};

EditInfoModal.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  initData: PropTypes.instanceOf(Object),
  onClose: PropTypes.func,
  title: PropTypes.string,
  model: PropTypes.string,
  options: PropTypes.instanceOf(Object),
  idRefurbish: PropTypes.number,
  idTemplate: PropTypes.number,
  idCompany: PropTypes.number,
  canChangeRefurbish: PropTypes.bool,
  afterSubmit: PropTypes.func,
  generalTask: PropTypes.bool,
  copyLinkParam: PropTypes.string
};

export default EditInfoModal;
