import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Space, Typography } from 'antd';
import { AboutContent, AboutYouCardGroup, Card, Content } from './Onboarding.styled';
import Select from '../../../components/Select/Select';
import lowXP from '../../../components/Images/lowXP.svg';
import midXP from '../../../components/Images/midXP.svg';
import highXP from '../../../components/Images/highXP.svg';
import useViewport from '../../../_Hooks/useViewport';

const { Text } = Typography;

const AboutYou = ({ data, onChange }) => {
  const { isMobile } = useViewport(window.innerWidth);
  const [_data, setData] = useState(data);
  const [selectedCard, setSelectedCard] = useState(data?.onboarding?.configExperience);

  const handleChange = newData => {
    setData(prev => {
      const newOnBoarding = { ...prev?.onboarding, ...newData?.onboarding };
      const _newData = { ...prev, ...newData, onboarding: newOnBoarding };
      onChange(_newData);
      return _newData;
    });
  };

  return (
    <Content>
      <AboutContent>
        <Space size={isMobile() ? 16 : 48} direction="vertical">
          <h6>Configurando a sua conta</h6>
          <Space size={16} direction="vertical">
            <Text>
              <b>Como você descreve o seu negócio?</b>
            </Text>
            <Select
              id="configOption"
              name="configOption"
              type="select"
              placeholder="Escolha uma opção"
              value={_data?.idType}
              onChange={e => handleChange({ idType: e, onboarding: { configDescription: e } })}
              model="companyType"
            />
          </Space>
          <div>
            <Space size={16} direction="vertical">
              <Text>
                <b>Qual sua familiaridade com ferramentas de gestão? </b>
              </Text>
              <AboutYouCardGroup>
                <Card
                  position={1}
                  onClick={() => {
                    handleChange({ onboarding: { configExperience: 1 } });
                    setSelectedCard(1);
                  }}
                  $isSelected={selectedCard === 1}
                >
                  <img src={lowXP} alt="Não tenho experiência" />
                  <Text>
                    <b>Não tenho experiência</b>
                  </Text>
                  <Text className="info-text">Estou começando agora.</Text>
                </Card>
                <Card
                  position={2}
                  onClick={() => {
                    handleChange({ onboarding: { configExperience: 2 } });
                    setSelectedCard(2);
                  }}
                  $isSelected={selectedCard === 2}
                  middle
                >
                  <img src={midXP} alt="Tenho pouco conhecimento" />
                  <Text>
                    <b>Tenho pouco conhecimento</b>
                  </Text>
                  <Text className="info-text">Gostaria de ajuda.</Text>
                </Card>
                <Card
                  position={3}
                  onClick={() => {
                    handleChange({ onboarding: { configExperience: 3 } });
                    setSelectedCard(3);
                  }}
                  $isSelected={selectedCard === 3}
                >
                  <img src={highXP} alt="Não tenho dificuldades" />
                  <Text>
                    <b>Não tenho dificuldades</b>
                  </Text>
                  <Text className="info-text">Não preciso de ajuda.</Text>
                </Card>
              </AboutYouCardGroup>
            </Space>
          </div>
        </Space>
        <Space size={16} direction="vertical" />
      </AboutContent>
    </Content>
  );
};

AboutYou.propTypes = {
  onChange: PropTypes.func,
  data: PropTypes.instanceOf(Object)
};

export default AboutYou;
