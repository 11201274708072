import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';
import useCRUD from '../../_Hooks/useCRUD';
import Modal from './Modal';
import formatCurrency from '../../lib/helpers/formatCurrency';
import { StyledDatePicker, StyledDiv, StyledInput, StyledSelect, StyledText } from './RegisterInstallmentModal.styles';
import useViewport from '../../_Hooks/useViewport';

const formId = 'modal-installemt-paid';

const RegisterInstallmentModal = ({ idRefurbish, onClose = f => f, installment, onSubmit, handleConfirmPayment }) => {
  const [paymentType, setPaymentType] = useState(1);
  const [date, setDate] = useState(dayjs(new Date()));
  const { isMobile } = useViewport(window.innerWidth);

  const { handleUpdate } = useCRUD({
    model: 'installment',
    immediatelyLoadData: false
  });

  const updateInstallment = () => {
    const updateData = {
      updatePathOptions: `/${installment.id}`,
      where: { id: installment?.id, idRefurbish },
      values: { idInstallmentStatus: 2, paidDate: date, idPaymentType: paymentType }
    };
    const update = handleConfirmPayment
      ? handleConfirmPayment(updateData)
      : handleUpdate(updateData).then(() => onSubmit());
    Promise.resolve(update);
  };

  return (
    installment && (
      <Modal
        title="Registrar pagamento"
        open
        onClose={e => {
          e.stopPropagation();
          onClose(null, false);
        }}
        onSubmit={updateInstallment}
        width={304}
        formId={formId}
        headerLine
        zIndex={!isMobile() && 100}
      >
        <StyledDiv>
          <StyledText>Valor Pago</StyledText>
          <StyledInput disabled value={formatCurrency(installment?.price, { currencySymbol: 'R$' })} />
        </StyledDiv>
        <StyledDiv>
          <StyledText>Forma de pagamento</StyledText>
          <StyledSelect value={paymentType} model="paymentType" onChange={setPaymentType} />
        </StyledDiv>
        <StyledDiv noPadding>
          <StyledText>Data de pagamento</StyledText>
          <StyledDatePicker
            value={date}
            format="DD/MM/YYYY"
            placeholderText={dayjs().format('DD/MM/YYYY')}
            onChange={_date => setDate(_date)}
          />
        </StyledDiv>
      </Modal>
    )
  );
};

RegisterInstallmentModal.propTypes = {
  installment: PropTypes.instanceOf(Object).isRequired,
  idRefurbish: PropTypes.number,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  handleConfirmPayment: PropTypes.func
};

export default memo(RegisterInstallmentModal);
