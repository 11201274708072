import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faFileInvoiceDollar,
  faConstruction,
  faFolderOpen,
  faUserGroup,
  faHandshake,
  faLoveseat,
  faCog,
  faFile,
  faClipboardListCheck,
  faNotebook,
  faGaugeHigh,
  faScrewdriverWrench,
  faCoins,
  faBagShopping,
  faMoneyCheckDollar
} from '@fortawesome/pro-duotone-svg-icons';

import CollapseItem from '../../../components/NavBar/CollapseItem';

const operator = {
  dashboard: {
    key: 'dashboard',
    to: '/operador/dashboard',
    noShowCollapse: true,
    label: 'Dashboard',
    canRead: true,
    icon: <FontAwesomeIcon icon={faHome} />
  },
  catalog: {
    key: 'catalog',
    to: '/operador/catalogo',
    label: 'Catálogo',
    canRead: true,
    roles: ['admin'],
    icon: <FontAwesomeIcon icon={faLoveseat} />,
    children: [
      {
        key: 'vobi',
        to: '/operador/catalogo',
        label: 'Produtos Vobi'
      },
      {
        key: 'categorias',
        to: '/operador/catalogo/minhas-categorias',
        label: 'Categorias'
      },
      {
        key: 'produtos',
        to: '/operador/catalogo/meus-produtos-servicos',
        label: 'Produtos'
      },
      {
        key: 'marcas',
        to: '/operador/catalogo/marcas',
        label: 'Marcas'
      }
    ],
    renderInCollapse: to => (
      <>
        <CollapseItem to={`${to}`} title="Produtos Vobi" />
        <CollapseItem to={`${to}/minhas-categorias`} title="Categorias" />
        <CollapseItem to={`${to}/meus-produtos`} title="Produtos" />
        <CollapseItem to={`${to}/marcas`} title="Marcas" />
      </>
    )
  },
  work: {
    key: 'work',
    to: '/operador/projetos',
    label: 'Projetos',
    canRead: true,
    noShowCollapse: true,
    icon: <FontAwesomeIcon icon={faConstruction} />
  },
  users: {
    key: 'users',
    to: '/operador/usuarios',
    label: 'Usuários',
    canRead: true,
    icon: <FontAwesomeIcon icon={faUserGroup} />,
    children: [
      {
        key: 'clientes',
        to: '/operador/usuarios/clientes',
        label: 'Clientes e Fornecedores'
      },
      {
        key: 'operadores',
        to: '/operador/usuarios/operadores',
        label: 'Operadores'
      },
      {
        key: 'profissionais',
        to: '/operador/usuarios/profissionais',
        label: 'Profissionais'
      }
    ],
    renderInCollapse: to => (
      <>
        <CollapseItem to={`${to}/clientes`} title="Clientes e Fornecedores" />
        <CollapseItem to={`${to}/operadores`} title="Operadores" />
        <CollapseItem to={`${to}/profissionais`} title="Profissionais" />
      </>
    )
  },
  configuration: {
    key: 'configuration',
    to: '/operador/configuracao',
    label: 'Configuração',
    canRead: true,
    icon: <FontAwesomeIcon icon={faCog} />,
    children: [
      {
        key: 'pipedrive',
        to: '/operador/configuracao/pipedrive',
        label: 'Exportar Pipedrive'
      },
      {
        key: 'search',
        to: '/operador/configuracao/search',
        label: 'Configurações do Search'
      },
      {
        key: 'notificacoes',
        to: '/operador/configuracao/notificacoes',
        label: 'Notificações'
      },
      {
        key: 'etapas',
        to: '/operador/configuracao/etapas',
        label: 'Etapas'
      },
      {
        key: 'templates',
        to: '/operador/configuracao/templates/todos',
        label: 'Templates'
      },
      {
        key: 'broadcasting',
        to: '/operador/configuracao/broadcasting',
        label: 'Enviar Notificação'
      }
    ],
    renderInCollapse: to => (
      <>
        <CollapseItem to={`${to}/pipedrive`} title="Exportar Pipedrive" />
        <CollapseItem to={`${to}/search`} title="Configurações do Search" />
        <CollapseItem to={`${to}/notificacoes`} title="Notificações" />
        <CollapseItem to={`${to}/etapas`} title="Etapas" />
        <CollapseItem to={`${to}/templates/todos`} title="Templates" />
        <CollapseItem to={`${to}/broadcasting`} title="Enviar Notificação" />
      </>
    )
  }
};

const provider = {
  dashboard: {
    key: 'dashboard',
    to: '/profissional/dashboard',
    noShowCollapse: true,
    label: 'Home',
    canRead: true,
    icon: <FontAwesomeIcon icon={faHome} />
  },
  projects: {
    key: 'projects',
    to: '/profissional/projetos',
    label: 'Projetos',
    canRead: true,
    roles: ['project'],
    noShowCollapse: true,
    icon: <FontAwesomeIcon icon={faFolderOpen} />
  },
  opportunities: {
    key: 'opportunities',
    to: '/profissional/oportunidades',
    label: 'Oportunidades',
    canRead: true,
    roles: ['opportunity'],
    noShowCollapse: true,
    icon: <FontAwesomeIcon style={{ width: '15px' }} icon={faHandshake} />
  },
  productivity: {
    key: 'productivity',
    label: 'Produtividade',
    canRead: true,
    roles: ['productivity'],
    icon: <FontAwesomeIcon icon={faGaugeHigh} />,
    children: [
      {
        key: 'gestao',
        to: '/profissional/produtividade/gestao-tarefas',
        label: 'Gestão de Tarefas',
        roles: ['tasks']
      },
      {
        key: 'cronograma',
        to: '/profissional/produtividade/cronograma-geral',
        label: 'Cronograma Geral',
        roles: ['generalSchedule']
      }
    ],
    renderInCollapse: () => (
      <>
        <CollapseItem to="/profissional/produtividade/gestao-tarefas" title="Gestão de Tarefas" roles={['tasks']} />
        <CollapseItem
          to="/profissional/produtividade/cronograma-geral"
          title="Cronograma Geral"
          roles={['generalSchedule']}
        />
      </>
    )
  },
  financial: {
    key: 'financial',
    label: 'Financeiro',
    canRead: true,
    roles: ['financial'],
    icon: <FontAwesomeIcon icon={faCoins} />,
    children: [
      {
        key: 'administrativo',
        to: '/profissional/financeiro/resumo',
        label: 'Administrativo',
        roles: ['administrative']
      },
      {
        key: 'projetos',
        to: '/profissional/pagamentos?userType=provider',
        label: 'Projetos',
        roles: ['financialProject']
      }
    ],
    renderInCollapse: () => (
      <>
        <CollapseItem to="/profissional/produtividade/gestao-tarefas" title="Gestão de Tarefas" roles={['tasks']} />
        <CollapseItem
          to="/profissional/produtividade/cronograma-geral"
          title="Cronograma Geral"
          roles={['generalSchedule']}
        />
      </>
    )
  },
  tools: {
    key: 'tools',
    label: 'Ferramentas',
    canRead: true,
    roles: ['tools'],
    icon: <FontAwesomeIcon icon={faScrewdriverWrench} />,
    children: [
      {
        key: 'templates',
        to: '/profissional/ferramentas/meus-templates/todos',
        label: 'Templates',
        roles: ['template']
      },
      {
        key: 'moodboard',
        to: '/profissional/moodboard',
        label: 'Moodboards',
        roles: ['moodboard']
      },
      {
        key: 'produtos',
        to: '/profissional/catalogo/meus-produtos-servicos',
        label: 'Produtos e Serviços',
        roles: ['products']
      },
      {
        key: 'grupos',
        to: '/profissional/catalogo/grupos-e-categorias',
        label: 'Grupos e Categorias',
        roles: ['group', 'categories']
      },
      {
        key: 'portfolio',
        to: '/profissional/meu-site',
        label: 'Meu Site',
        roles: ['portfolio']
      },
      {
        key: 'catalogo',
        to: '/profissional/catalogo',
        label: 'Catálogo Vobi',
        roles: ['catalog']
      },
      {
        key: 'extensao',
        to: 'https://go.vobi.com.br/9wMpqd/',
        label: 'Extensão da Captura de Produtos',
        external: true
      }
    ],
    renderInCollapse: () => (
      <>
        <CollapseItem to="/profissional/ferramentas/meus-templates/todos" title="Meus Templates" roles={['template']} />
        <CollapseItem to="/profissional/moodboard" title="Moodboard" roles={['moodboard']} />
        <CollapseItem
          to="/profissional/catalogo/meus-produtos-servicos"
          title="Produtos e Serviços"
          roles={['products']}
        />
        <CollapseItem
          to="/profissional/catalogo/grupos-e-categorias"
          title="Grupos e Categorias"
          roles={['group', 'categories']}
        />
        <CollapseItem to="/profissional/meu-site" title="Meu Site" roles={['portfolio']} />
        <CollapseItem to="/profissional/catalogo" title="Catalogo Vobi" roles={['catalog']} />

        {/* <CollapseItem to="/profissional/briefing-geral" title="Formulário de Briefing" roles={['briefing']} /> */}
      </>
    )
  },
  contacts: {
    key: 'contacts',
    to: '/profissional/contatos',
    label: 'Contatos',
    roles: ['customersAndSuppliers'],
    canRead: true,
    noShowCollapse: true,
    icon: <FontAwesomeIcon style={{ width: '14px' }} icon={faUserGroup} />
  }
};

const customer = {
  dashboard: {
    key: 'dashboard',
    to: '/cliente/dashboard',
    noShowCollapse: true,
    label: 'Dashboard',
    canRead: true,
    icon: <FontAwesomeIcon icon={faHome} />
  },
  budget: {
    key: 'budget',
    permissionId: 'item',
    to: '/cliente/orcamento',
    label: 'Orçamento',
    canRead: true,
    noShowCollapse: true,
    icon: <FontAwesomeIcon style={{ width: '15px' }} icon={faFileInvoiceDollar} />,
    renderInCollapse: () => <></>
  },
  timeline: {
    key: 'timeline',
    permissionId: 'timeline',
    to: '/cliente/cronograma',
    label: 'Cronograma',
    canRead: true,
    noShowCollapse: true,
    icon: <FontAwesomeIcon style={{ width: '15px' }} icon={faClipboardListCheck} />,
    renderInCollapse: () => <></>
  },
  payments: {
    key: 'payments',
    permissionId: 'payment',
    to: '/cliente/parcelas',
    label: 'Pagamentos',
    canRead: true,
    noShowCollapse: true,
    icon: <FontAwesomeIcon icon={faCoins} />
  },
  financial: {
    key: 'Cobrancas',
    permissionId: 'invoice',
    to: '/cliente/cobrancas',
    label: 'Cobranças',
    canRead: true,
    noShowCollapse: true,
    icon: <FontAwesomeIcon icon={faMoneyCheckDollar} />,
    renderInCollapse: () => <></>
  },
  shopping: {
    key: 'compras',
    permissionId: 'shopping',
    to: '/cliente/compras',
    label: 'Compras',
    canRead: true,
    noShowCollapse: true,
    icon: <FontAwesomeIcon icon={faBagShopping} />,
    renderInCollapse: () => <></>
  },
  files: {
    key: 'Arquivos',
    permissionId: 'file',
    to: '/cliente/arquivos',
    label: 'Arquivos',
    canRead: true,
    noShowCollapse: true,
    icon: <FontAwesomeIcon style={{ width: '15px' }} icon={faFile} />,
    renderInCollapse: () => <></>
  },
  propostas: {
    key: 'Propostas',
    permissionId: 'proposal',
    to: '/cliente/propostas',
    label: 'Propostas',
    canRead: true,
    noShowCollapse: true,
    icon: <FontAwesomeIcon style={{ width: '14px', height: '12px' }} icon={faHandshake} />,
    renderInCollapse: () => <></>
  },
  anotacoes: {
    key: 'Anotacoes',
    permissionId: 'note',
    to: '/cliente/anotacoes',
    label: 'Anotações',
    canRead: true,
    noShowCollapse: true,
    icon: <FontAwesomeIcon icon={faNotebook} />,
    renderInCollapse: () => <></>
  }
};

export const navItems = { operator, provider, customer };
