import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Spacer } from '@homehero/hero-style';
import { faDollarSign } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// style
import css from './FilterRange.module.scss';

// components
import Align from '../Align/Align';

const FilterRange = ({ data, onClick, closeModal }) => {
  const [amounts, setAmounts] = useState(data || { min: null, max: null });
  const { min, max } = amounts;

  const handleChange = e => {
    const { name, value } = e.target;
    setAmounts(prevState => {
      return { ...prevState, [name]: Number(value) };
    });
  };

  const handleClick = () => {
    closeModal && closeModal();
    onClick(amounts);
  };

  return (
    <div className={css['c-filter-range']}>
      <Spacer mbLg={1} mbMd={1} mbSm={1} mbXs={1}>
        <span>Insira o valor</span>
      </Spacer>
      <div className={css['c-filter-range__inputs']}>
        <Input value={min} name="min" onChange={handleChange} icon={<FontAwesomeIcon icon={faDollarSign} />} />
        <span>até</span>
        <Input value={max} name="max" onChange={handleChange} icon={<FontAwesomeIcon icon={faDollarSign} />} />
      </div>
      <Spacer mtLg={2} mtMd={2} mtSm={2} mtXs={2}>
        <Align align="right">
          <Button id="apply-filter-button" size={1} type="default" container="outlined" noShadow onClick={handleClick}>
            Aplicar filtro
          </Button>
        </Align>
      </Spacer>
    </div>
  );
};

FilterRange.propTypes = {
  data: PropTypes.instanceOf(Object),
  onClick: PropTypes.func,
  closeModal: PropTypes.func
};

export default FilterRange;
