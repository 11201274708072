import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, CLoseButton } from './BulkActions.styles';
import { LeftTextContainer } from './SpecificationBulkActions.styles';
import formatCurrency from '../../lib/helpers/formatCurrency';
import useViewport from '../../_Hooks/useViewport';

const BulkActions = ({
  text,
  handleClose,
  children,
  showTotal,
  counter,
  total,
  selectedTotal,
  isInstalment,
  ...props
}) => {
  const { isMobile } = useViewport(window.innerWidth);

  const _total = useMemo(() => {
    return formatCurrency(total, { currencySymbol: 'R$' });
  }, [total]);

  const _selectedTotal = useMemo(() => {
    return formatCurrency(selectedTotal, { currencySymbol: 'R$' });
  }, [selectedTotal]);

  const Total = () => (
    <LeftTextContainer>
      {!showTotal && !isMobile() && (
        <div>
          <p>Valor aprovado: </p>
          <strong>{_total}</strong>
        </div>
      )}
      {!isMobile() && (
        <div>
          <p>
            {isInstalment
              ? `${counter} parcela${counter > 1 ? 's' : ''} selecionada${counter > 1 ? 's' : ''}`
              : `${counter} ite${counter > 1 ? 'ns' : 'm'} selecionado${counter > 1 ? 's' : ''}`}
          </p>
          <strong>{_selectedTotal}</strong>
        </div>
      )}
      {isMobile() && (
        <div>
          <p>{counter}</p>
          <strong>{_selectedTotal}</strong>
        </div>
      )}
    </LeftTextContainer>
  );

  return counter > 0 ? (
    <Container {...props}>
      {!!handleClose && isMobile() && (
        <CLoseButton onClick={handleClose}>
          <FontAwesomeIcon icon={faTimes} />
        </CLoseButton>
      )}
      {text ? <p style={{ whiteSpace: 'nowrap' }}>{text}</p> : <Total />}
      <div>
        {children}
        {!!handleClose && !isMobile() && (
          <CLoseButton onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </CLoseButton>
        )}
      </div>
    </Container>
  ) : null;
};

BulkActions.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  handleClose: PropTypes.func,
  children: PropTypes.instanceOf(Object),
  showTotal: PropTypes.bool,
  counter: PropTypes.number,
  total: PropTypes.number,
  selectedTotal: PropTypes.number,
  isInstalment: PropTypes.bool
};

export default BulkActions;
