/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import { parseSearchableOptions } from '../../lib/helpers/helper';
import { SelectDebouceStyled } from './Select.style';

// Hooks
import useCRUD from '../../_Hooks/useCRUD';

const DebounceSelect = ({
  id,
  model,
  searchColumnsModel,
  customLabel = null,
  customValue = null,
  modelOptions = null,
  fetchOptions,
  debounceTimeout = 800,
  showSearch = true,
  placeholder,
  customOptions = null,
  ...props
}) => {
  const [valueSelect, setValueSelect] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  const _modelOptions = modelOptions || { where: { isActive: true } };
  const { handleGet } = useCRUD({
    model,
    immediatelyLoadData: false
  });

  const handleGetModel = refetchOptions => {
    fetchRef.current += 1;
    const fetchId = fetchRef.current;
    setOptions([]);
    setFetching(true);
    handleGet({ refetchOptions }).then(list => {
      if (fetchId !== fetchRef.current) {
        return;
      }
      const parsedOption = parseSearchableOptions(list, customLabel, customValue);
      setOptions(parsedOption);
      setFetching(false);
    });
  };

  const debounceFetcher = useMemo(() => {
    const loadOptionsDebounce = value => {
      const dynamicWhere = searchColumnsModel.map(index => ({
        [index]: { like: `%${value}%` }
      }));
      const refetchOptions = {
        ..._modelOptions,
        where: {
          ...(_modelOptions?.where || {}),
          or: dynamicWhere
        }
      };
      handleGetModel(refetchOptions);
    };

    return debounce(loadOptionsDebounce, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  useEffect(() => {
    if (!showSearch) {
      handleGetModel(_modelOptions);
    }
  }, []);

  return (
    <SelectDebouceStyled
      id={id}
      ref={fetchRef}
      labelInValue
      allowClear
      showSearch={showSearch}
      showArrow={!showSearch && customOptions}
      mode="multiple"
      value={valueSelect}
      filterOption={false}
      onSearch={!customOptions ? debounceFetcher : false}
      onChange={newValue => {
        setValueSelect(newValue);
      }}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={customOptions || options}
      placeholder={placeholder}
    />
  );
};

Select.propTypes = {
  id: PropTypes.string,
  model: PropTypes.string,
  modelOptions: PropTypes.instanceOf(Object),
  customLabel: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array, PropTypes.string]),
  customValue: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array, PropTypes.string]),
  onChange: PropTypes.func,
  dark: PropTypes.bool
};

export default DebounceSelect;
