import * as Yup from 'yup';
import React, { useCallback } from 'react';
import LinkedTaskSelection from '../../../components/Select/LinkedTaskSelection';
import { idReferenceNull, text, date } from '../schema';

const taskSchema = Yup.object().shape({
  name: text,
  description: text,
  id: text,
  status: idReferenceNull,
  priority: idReferenceNull,
  startDate: date,
  endDate: date,
  idRefurbish: idReferenceNull,
  idResponsible: idReferenceNull
});

const taskMapping = ({ idTask, idRefurbish, idTemplate, idCompany, canChangeRefurbish, generalTask, execution }) => ({
  name: {
    type: 'text',
    name: 'Título da tarefa',
    id: 'title-task'
  },
  idResponsible: {
    name: 'Responsável',
    type: 'select',
    id: 'responsible',
    placeholder: 'Responsável',
    model: 'user',
    modelOptions: { where: { idCompany, userType: { or: ['provider', 'guest'] } }, order: [['name']] }
  },
  estimativeDuration: {
    type: 'masked',
    name: 'Horas estimadas'
  },
  realDuration: {
    type: 'masked',
    name: 'Horas realizadas'
  },
  startDate: {
    name: 'Data de início',
    type: 'antDate',
    allowClear: true,
    hasChildren: true,
    md: 6
  },
  startHours: {
    name: 'Hora de início',
    type: 'timeHours',
    allowClear: true,
    md: 6,
    parent: { name: 'startDate' }
  },
  endDate: {
    name: 'Data de fim',
    type: 'antDate',
    allowClear: true,
    hasChildren: true,
    md: 6
  },
  endHours: {
    name: 'Hora de fim',
    type: 'timeHours',
    allowClear: true,
    parent: { name: 'endDate' },
    md: 6
  },
  priority: {
    type: 'select',
    name: 'Prioridade',
    dataType: 'taskPriority',
    id: 'taskPriority'
  },
  status: {
    type: 'select',
    name: 'Status',
    dataType: 'taskStatus'
  },

  ...(canChangeRefurbish && {
    idRefurbish: {
      name: 'Projeto',
      type: 'select',
      model: 'refurbish',
      modelOptions: {
        where: { idStatus: execution.id },
        order: [['name'], ['id']]
      },
      hasChildren: true
    }
  }),
  idStep: {
    name: 'Etapa do projeto',
    type: 'select',
    readOnly: !idRefurbish && !idTemplate,
    placeholder: 'Geral',
    parent: canChangeRefurbish && {
      name: 'idRefurbish',
      lazyLoad: !idRefurbish && !idTemplate,
      makeRefetchOptions: ({ field, value }) => ({
        include: [{ model: 'refurbishStep', where: { [field]: value } }],
        order: [['name']]
      })
    },
    model: 'step',
    modelOptions: {
      include: [
        idTemplate
          ? { model: 'templateStep', where: { idTemplate } }
          : { model: 'refurbishStep', where: { idRefurbish } }
      ],
      order: [['name']]
    }
  },
  ...(!generalTask &&
    idTask && {
      idParent: {
        type: 'custom',
        name: 'Vincular tarefa',
        // eslint-disable-next-line react/prop-types
        Component: ({ setField, value }) => {
          const _onChange = useCallback(_value => {
            return setField(_value);
          }, []);
          return (
            <LinkedTaskSelection
              id={idTask}
              idReference={idRefurbish || idTemplate}
              setLinkedTask={_onChange}
              isTemplate={!!idTemplate}
              linkedTask={value}
            />
          );
        }
      }
    }),
  description: {
    type: 'editorSimple',
    name: 'Descrição',
    xs: 24
  }
});

export { taskMapping, taskSchema };
