import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Card, Text, Loader } from '@homehero/hero-style';
import { Tooltip, Checkbox } from 'antd';
import { faEye } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, fonts } from '../../styles/style';
import { formatSinceTime, getFileIcon } from '../../lib/helpers/helper';
import css from './FileCard.module.scss';

const FileCard = ({
  data,
  onSelect,
  isSelected,
  onlyIcon,
  width = '60%',
  height = '100%',
  tooltip = false,
  viewFileFunction,
  onToggleCheckbox,
  checked
}) => {
  const classes = classNames({
    [css['c-file-card']]: true,
    [css['c-file-card--selected']]: isSelected
  });

  const timeToShow = formatSinceTime(data.createdAt);

  return onlyIcon ? (
    <div className={css['c-file-card__icon']}>
      {getFileIcon({
        filename: data?.filename,
        width,
        height,
        imageElement: <div className={css['file-image']} style={{ backgroundImage: `url("${data?.fullpath}")` }} />
      })}
    </div>
  ) : (
    <Card className={classes} onClick={onSelect}>
      {data.loading ? (
        <div className={css.centered}>
          <Loader />
        </div>
      ) : (
        <>
          <Checkbox
            checked={checked}
            onClick={e => {
              e.stopPropagation();
            }}
            style={{ position: 'absolute', top: 4, left: 4 }}
            onChange={e => {
              onToggleCheckbox({ isChecked: e.target.checked, file: data });
            }}
          />
          <div className={css['c-file-card__icon']}>
            {getFileIcon({
              filename: data?.filename,
              width,
              height,
              imageElement: (
                <div className={css['file-image']} style={{ backgroundImage: `url("${data?.fullpath}")` }} />
              )
            })}
            <button
              onClick={e => {
                e.stopPropagation();
                viewFileFunction();
              }}
            >
              <FontAwesomeIcon icon={faEye} />
            </button>
          </div>
          <div className={css['c-file-card__text']}>
            {tooltip ? (
              <Tooltip placement="bottom" title={data.filename}>
                <Text style={{ fontSize: fonts.sizeSm }}>{data.filename}</Text>
              </Tooltip>
            ) : (
              <Text style={{ fontSize: fonts.sizeSm }}>{data.filename}</Text>
            )}
            <Text style={{ fontSize: fonts.sizeXs, color: colors.neutral60 }}>{timeToShow}</Text>
          </div>
        </>
      )}
    </Card>
  );
};

FileCard.propTypes = {
  tooltip: PropTypes.bool,
  onSelect: PropTypes.func,
  data: PropTypes.instanceOf(Object).isRequired,
  isSelected: PropTypes.bool,
  onlyIcon: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
  viewFileFunction: PropTypes.func,
  onToggleCheckbox: PropTypes.func,
  checked: PropTypes.bool
};

export default FileCard;
