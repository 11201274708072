import React, { useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import PropTypes from 'prop-types';
import useCRUD from '../../_Hooks/useCRUD';

import './RichTextEditor.module.scss';
import { colors } from '../../styles/style';

const baseURL = process.env.REACT_APP_API_URL || '';

const RichTextEditor = ({
  onChange,
  onBlur,
  value,
  width,
  height,
  plugins,
  toolbar,
  quickbar,
  menubar,
  toolbarSticky,
  imageCaption,
  toolbarMode,
  isPublic,
  outputFormat,
  disabled,
  images = [],
  ...otherProps
}) => {
  const { handleCreate } = useCRUD({
    model: 'media',
    immediatelyLoadData: false
  });
  const [imageArray, setImageArray] = useState(images || []);
  const handleImageUpload = (blobInfo, success, failure) => {
    const formData = new FormData();
    formData.append('file', blobInfo.blob());
    formData.append('isPublic', isPublic);
    handleCreate({ values: formData, refresh: false })
      .then(url => {
        setImageArray(prevState => [...prevState, url.location.replace(/&/g, '&amp;')]);
        success(url.location);
      })
      .catch(err => failure(err));
  };

  const defaultQuickbar = 'bold italic underline | quicklink quickimage';

  return (
    <Editor
      initialValue={value}
      outputFormat={outputFormat}
      disabled={disabled}
      init={{
        selector: 'textarea',
        height: height || 500,
        width,
        menubar,
        toolbar_sticky: toolbarSticky,
        image_caption: imageCaption,
        toolbar_mode: toolbarMode,
        plugins,
        toolbar,
        quickbars_selection_toolbar: quickbar || defaultQuickbar,
        images_upload_handler: handleImageUpload,
        images_upload_url: `${baseURL}/media`,
        content_style: `body { font-size: 14px; font-family: muli, sans-serif;  color: ${colors.neutral30};}
        body::before {color: ${colors.neutral60} !important; font-weight: 300;}`,
        ...otherProps
      }}
      onEditorChange={(content, editor) => onChange(content, imageArray, editor)}
      onBlur={onBlur}
      value={value}
    />
  );
};

RichTextEditor.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  plugins: PropTypes.oneOfType([PropTypes.array]),
  toolbar: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  quickbar: PropTypes.string,
  toolbarMode: PropTypes.string,
  menubar: PropTypes.bool,
  toolbarSticky: PropTypes.bool,
  imageCaption: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isPublic: PropTypes.bool,
  outputFormat: PropTypes.string,
  disabled: PropTypes.bool,
  images: PropTypes.instanceOf(Array)
};
RichTextEditor.defaultProps = {
  menubar: false,
  value: '',
  toolbarSticky: true,
  imageCaption: true,
  toolbarMode: 'sliding',
  plugins: [
    ' paste  searchreplace autolink autosave save directionality code visualblocks ',
    'visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking',
    'toc insertdatetime advlist lists wordcount textpattern noneditable help charmap'
  ],
  toolbar:
    ' | bold italic underline | alignleft aligncenter alignright alignjustify |' +
    ' numlist bullist | preview print | image  link table',
  quickbar: '',
  width: '100%',
  height: null,
  onBlur: f => f,
  isPublic: false,
  disabled: false
};

export default RichTextEditor;
