import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { Text } from '@homehero/hero-style';
import { Row, Col } from 'antd';

import useViewport from '../../_Hooks/useViewport';
import FileCard from '../Card/FileCard';
import FileUpload from '../File/FileUpload';

import css from './AttachmentList.module.scss';
import { TypeCol, StyledSelect } from './AttachmentList.style';

// Hook
import useCRUD from '../../_Hooks/useCRUD';
import CenteredLoader from '../Loader/CenteredLoader';
import { fonts } from '../../styles/style';

const AttachmentList = ({ style, files, setFiles, readOnly, idRefurbish, preventUpload }) => {
  const { user } = useSelector(state => state.authReducer) || {};
  const { userType } = useSelector(state => state.setup.enums);
  const { handleUpdate: updateFileType } = useCRUD({
    model: 'file',
    immediatelyLoadData: false
  });
  const { isMobile } = useViewport(window.innerWidth);
  const [isLoading, setLoading] = useState(false);

  const handleRemoveFile = index => {
    setFiles(prev => {
      prev.splice(index, 1);
      return [...prev];
    });
  };

  const handleAddFile = newFiles => {
    setFiles(prev => [...prev, ...newFiles]);
    setLoading(false);
  };

  const handleChangeFileType = index => filetype => {
    const file = files[index];
    if (file && file.id) {
      updateFileType({ id: file.id, values: { ...file, idRefurbish, filetype }, refresh: false }).then();
    }

    setFiles(prev => {
      const _prev = [...(prev || [])];
      _prev[index].filetype = filetype;

      return [..._prev];
    });
  };

  return (
    <div className={css['c-attachment']} style={style}>
      {isLoading ? (
        <CenteredLoader />
      ) : (
        <>
          {files?.length ? (
            <div style={{ width: '100%' }}>
              {!isMobile() && (
                <Row className={css['c-attachment--header']}>
                  <Col md={12}>
                    <Text>Arquivo</Text>
                  </Col>
                  <Col md={12}>
                    <Text>Tipo</Text>
                  </Col>
                </Row>
              )}
              {files?.map((file, index) => (
                <Row gutter={8} key={file.fullpath} className={css['c-attachment--list']}>
                  <Col md={2} sm={2} xs={3}>
                    <div style={{ width: '32px', height: '32px' }}>
                      <FileCard data={file} onlyIcon width={24} height={32} />
                    </div>
                  </Col>
                  <Col md={10} sm={10} xs={21} className={css['c-attachment--vertical-center']}>
                    <a href={file.fullpath} target="_blank" rel="noopener noreferrer">
                      <Text ellipsis>{file.filename}</Text>
                    </a>
                  </Col>
                  <TypeCol md={12} sm={12} xs={24}>
                    <StyledSelect
                      style={{ minWidth: '50%' }}
                      dataType="fileType"
                      name="type"
                      value={file.filetype}
                      size="small"
                      disabled={readOnly}
                      onChange={handleChangeFileType(index)}
                    />
                    {!readOnly && user?.userType !== userType?.customer?.value ? (
                      <div className="c-attachment--action">
                        <span>
                          <FontAwesomeIcon icon={faTrashAlt} onClick={() => handleRemoveFile(index)} />
                        </span>
                      </div>
                    ) : null}
                  </TypeCol>
                </Row>
              ))}
            </div>
          ) : (
            <p>Nenhum arquivo adicionado.</p>
          )}
        </>
      )}

      {!readOnly && !preventUpload ? (
        <>
          <Text style={{ fontSize: fonts.sizeSm, marginTop: 10, marginBottom: 10 }}>
            Selecione um arquivo de no máximo 50mb
          </Text>
          <FileUpload
            id="file-input"
            onChange={handleAddFile}
            multiple
            showGallery={false}
            text="Adicionar documento"
            listType={null}
            buttonProps={{ type: 'primary' }}
            type="dragger"
            startLoading={setLoading}
          />
        </>
      ) : null}
    </div>
  );
};

AttachmentList.propTypes = {
  files: PropTypes.instanceOf(Object),
  setFiles: PropTypes.func,
  readOnly: PropTypes.bool,
  idRefurbish: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  preventUpload: PropTypes.bool,
  style: PropTypes.instanceOf(Object)
};

export default AttachmentList;
