import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { colors, breakpoints } from '../../styles/style';

const StyledDiv = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 4px;
  background-color: ${props => colors[props.status]};
  padding: 0;
  margin-right: 12px;
  @media (max-width: ${breakpoints.tablet}) {
    width: 16px;
    height: 16px;
    border-radius: 2px;
    margin: 4px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const StatusSelector = ({ status, text, icon, titleTooltip }) => (
  <Tooltip placement="bottom" title={titleTooltip}>
    <Container>
      {icon ? (
        <FontAwesomeIcon icon={icon} color={colors[status?.style]} style={{ marginRight: 8 }} />
      ) : (
        <StyledDiv status={status?.style} />
      )}
      {text}
    </Container>
  </Tooltip>
);

StatusSelector.propTypes = {
  status: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.instanceOf(Object),
  titleTooltip: PropTypes.string
};

export default StatusSelector;
