import styled from 'styled-components';
import { breakpoints, colors, fonts, spaces } from '../../styles/style';

const StyledRow = styled.div`
  @media (min-width: ${breakpoints.desktop}) {
    display: flex;
    justify-content: ${props => (props.alignLeft ? 'flex-start' : 'space-between')};
  }
  width: 100%;
  margin-bottom: ${spaces.space1};
  padding-bottom: ${props => props.extraPadding && spaces.space3};
`;

const StyledLabel = styled.span`
  font-size: ${fonts.fontSm};
  font-weight: 500;
  color: ${colors.neutralSecondary40};
`;

const StyledData = styled.span`
  font-size: ${fonts.fontSm};
  color: ${colors.neutralSecondary60};

  text-align: right;
  margin-left: ${spaces.space0};
`;

const StyledInstallmentData = styled.div`
  padding-right: ${spaces.space1};
  margin-bottom: ${spaces.space3};
  border-bottom: 1px solid ${colors.neutralSecondary95};
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
`;

const StyledFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  gap: ${spaces.space2};
  justify-content: flex-end;
  @media (max-width: ${breakpoints.tablet}) {
    justify-content: center;
  }
`;

export { StyledLabel, StyledRow, StyledData, StyledInstallmentData, StyledDiv, StyledFooter };
