import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { Tooltip } from 'antd';
import css from './ProductCard.module.scss';
import formatCurrency from '../../lib/helpers/formatCurrency';
import useViewport from '../../_Hooks/useViewport';
import ImageInput from '../Input/ImageInput';

const ProductCard = ({
  data = {},
  AddComponent = null,
  showBorder = true,
  unit,
  onSelect = null,
  showZero,
  openBlank,
  largeImage,
  redirectUrl,
  imageSize = '100%'
}) => {
  const { isMobile } = useViewport(window.innerWidth);
  const [isHovering, setIsHovering] = useState(isMobile());

  const handleHover = value => event => {
    event.preventDefault();
    setIsHovering(isMobile() || value);
  };

  const baseClass = unit ? 'c-product-card-unit' : 'c-product-card';
  const pictureClass = classNames({
    [css[`${baseClass}__picture`]]: true,
    [css[`${baseClass}__picture--lg`]]: largeImage
  });

  const classes = classNames({
    [css[baseClass]]: true,
    [css[`${baseClass}--border`]]: showBorder,
    [css[`${baseClass}--no-click`]]: !redirectUrl
  });

  return (
    <div className={classes} onMouseEnter={handleHover(true)} onMouseLeave={handleHover(false)}>
      {AddComponent && isHovering ? (
        <div role="presentation" style={{ position: 'absolute', right: 16 }} onClick={e => e.stopPropagation()}>
          <AddComponent size="1x" onSelect={onSelect} data={data} />
        </div>
      ) : null}
      <Link to={redirectUrl || '#'} target={openBlank && redirectUrl ? '_blank' : ''}>
        <div className={pictureClass}>
          <ImageInput
            key={`image${data?.id}`}
            id={data?.id}
            value={data?.image}
            isModal={openBlank}
            disabled
            size={imageSize}
          />
        </div>
        <div className={css[`${baseClass}__description`]}>
          <Tooltip animation="perspective" description={data.name || '-'}>
            <p className={css[`${baseClass}__title`]}>{data.name}</p>
          </Tooltip>
          {data.supplier?.name ? (
            <span className={css[`${baseClass}__supplier`]}>{data.supplier.name}</span>
          ) : (
            data.store && <span className={css[`${baseClass}__store`]}>{data.store}</span>
          )}
        </div>
        <div className={css[`${baseClass}__price`]}>
          <p>
            {Number(data.price) || showZero
              ? formatCurrency(Number(data.price), { currencySymbol: 'R$' })
              : 'Indisponível'}
            {data.measurementUnit ? `/${data.measurementUnit}` : ''}
          </p>
        </div>
      </Link>
    </div>
  );
};

ProductCard.propTypes = {
  data: PropTypes.instanceOf(Object),
  onSelect: PropTypes.func,
  AddComponent: PropTypes.instanceOf(Object),
  showBorder: PropTypes.bool,
  showZero: PropTypes.bool,
  unit: PropTypes.bool,
  openBlank: PropTypes.bool,
  largeImage: PropTypes.bool,
  redirectUrl: PropTypes.string,
  imageSize: PropTypes.number
};

export default ProductCard;
