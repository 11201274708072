import styled from 'styled-components';

import { breakpoints } from '../../styles/style';

import TooltipIcon from '../Icons/TooltipIcon';

export const BulkActionsChildrenContainer = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-left: 8px;
  }
`;

export const LeftTextContainer = styled.div`
  display: flex;

  div {
    display: flex;
    flex-direction: column;
    margin-left: auto;

    @media (max-width: ${breakpoints.tablet}) {
      flex-direction: row;
      align-items: center;
    }

    p {
      margin-bottom: 4px;
      width: auto;

      @media (max-width: ${breakpoints.tablet}) {
        margin-right: 16px;
        margin-bottom: 0px;
      }
    }
  }

  div + div {
    margin-left: 16px;
  }
`;

export const StyledIcon = styled(TooltipIcon)`
  svg {
    height: 18px;
    width: 18px;
    margin-left: 16px;
  }
`;

export const ModalItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  p {
    margin-left: 8px;
    font-size: 18px;
  }
`;
