import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import 'antd/lib/date-picker/style/index';
import ptBR from 'antd/es/locale/pt_BR';
import moment from 'moment';
import * as dayjs from 'dayjs';
import toObject from 'dayjs/plugin/toObject';
import { faCalendarWeek } from '@fortawesome/pro-regular-svg-icons';
import { Tooltip, Input } from 'antd';
import Text from 'antd/lib/typography/Text';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { colors } from '../../styles/style';
import Button from '../Button/Button';
import UseOutsideClick from '../../_Hooks/useOutsideClick';
import useViewport from '../../_Hooks/useViewport';
import { StyledPicker, StyledTimePicker, StyledPanel, StyledIcon, StyledBlockCalendar } from './RangePicker.styled';

dayjs.extend(toObject);

const format = 'HH:mm';

const RangePicker = ({
  style,
  styleCalendar,
  onChange,
  value = [],
  children,
  showTime = false,
  disabled,
  isDelayed,
  hideTxtPicker,
  ghost,
  buttonProps,
  isFilter,
  submitOnOk,
  taskCustomCalendar,
  isGantt,
  taskBasedDate,
  isParent,
  ...props
}) => {
  const { isMobile } = useViewport(window.innerWidth);
  const [isOpen, setIsOpen] = useState(false);
  const [dateRange, setDateRange] = useState([]);
  const [shouldSubmit, setShouldSubmit] = useState(false);
  // const [durationTime, setDurationTime] = useState(0);
  const [checkTaskBasedDate, setCheckTaskBasedDate] = useState(taskBasedDate);

  const preventClose = useRef(null);
  const wrapperRef = useRef(null);
  const inputNumberRef = useRef(null);
  const initialDate = useRef(0);
  const durationTime = useRef(null);

  const submit = () => {
    setIsOpen(false);
    if (shouldSubmit) onChange({ start: dateRange[0], end: dateRange[1], taskBasedDate: checkTaskBasedDate });
  };

  const onElementFocus = (element, index) => {
    if (initialDate.current === index) {
      element.focus();
    }
  };

  useEffect(() => {
    const focusDate = document.querySelectorAll('.vobi-range-picker > .ant-picker-input');
    if (focusDate[initialDate.current]) {
      focusDate[initialDate.current].children[0].focus();
    }
    if (focusDate[0]) {
      const initInput = focusDate[0].children?.[0];
      const endInput = focusDate[1]?.children?.[0];
      initInput.addEventListener('click', () => {
        initialDate.current = 0;
        initInput.focus();
      });
      endInput.addEventListener('click', () => {
        initialDate.current = 1;
        endInput.focus();
      });
      initInput.addEventListener('focus', () => onElementFocus(endInput, 1));
    }
  }, [isOpen]);

  useEffect(() => {
    const focusDate = document.querySelectorAll('.vobi-range-picker > .ant-picker-input');
    if (focusDate[initialDate.current]) {
      focusDate[initialDate.current].children[0].focus();
    }
  }, [initialDate.current]);

  UseOutsideClick(
    wrapperRef,
    () => {
      setDateRange([value?.length ? value[0] : null, value?.length ? value[1] : null]);
      setIsOpen(false);
      if (!submitOnOk && shouldSubmit)
        onChange({ start: dateRange[0], end: dateRange[1], taskBasedDate: checkTaskBasedDate });
    },
    preventClose
  );

  const changeTime = (index, time) => {
    const objValue = dayjs(time).toObject();
    const newTime = dayjs(dateRange[index])
      .minute(objValue.minutes)
      .hour(objValue.hours);
    setDateRange(prev => {
      const aux = prev;
      aux[index] = newTime;
      return aux;
    });
  };

  const changeDate = (date, targetDate) => {
    const objTargetDate = dayjs(targetDate).toObject();
    const _date =
      date && dayjs(date).isValid()
        ? dayjs(date)
        : dayjs()
            .hour(targetDate?.hour())
            .minute(targetDate?.minute());
    return _date
      .year(objTargetDate.years)
      .month(objTargetDate.months)
      .date(objTargetDate.date);
  };

  useEffect(() => {
    checkTaskBasedDate && setDateRange(value?.length ? [value[0], value[1]] : [null, null]);
  }, [checkTaskBasedDate]);

  useEffect(() => {
    setDateRange(value?.length ? [value[0], value[1]] : [null, null]);
  }, [value]);

  useEffect(() => {
    const start = dayjs(dateRange?.[0])
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0);
    const end = dayjs(dateRange?.[1])
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0);
    durationTime.current = Number.isNaN(end.diff(start, 'day')) ? null : end.diff(start, 'day') + 1;
  }, [dateRange]);

  const OpenNewCalendarBtn = child => {
    if (child || isMobile()) {
      return (
        <Button
          size={isMobile() ? 'small' : undefined}
          noPadding
          id="open-new-calendar"
          style={style}
          onClick={e => {
            e.stopPropagation();
            setIsOpen(disabled ? false : !isOpen);
          }}
          ghost={hideTxtPicker ? ghost : true}
          {...buttonProps}
        >
          {child || (
            <>
              <span style={{ color: isDelayed ? colors.error50 : null, display: 'flex' }}>
                <div style={{ marginRight: isGantt ? '34px' : undefined }}>
                  {dateRange?.[0] && dayjs(dateRange?.[0]).isValid() ? (
                    `${dayjs(dateRange?.[0])
                      .format('DD MMM')
                      .toString()} `
                  ) : (
                    <StyledIcon style={{ marginBottom: '-1px', width: 28 }} icon={faCalendarWeek} />
                  )}
                </div>
                {!isGantt && '-'}
                <div>
                  {dateRange?.[1] && dayjs(dateRange?.[1]).isValid() ? (
                    `  ${dayjs(dateRange?.[1])
                      .format('DD MMM')
                      .toString()}`
                  ) : (
                    <StyledIcon style={{ marginBottom: '-1px', width: 28 }} icon={faCalendarWeek} />
                  )}
                </div>
              </span>
              <div style={{ width: '4px' }} />
            </>
          )}
        </Button>
      );
    }
    return (
      <div
        style={{
          display: 'flex',
          paddingRight: '44px'
        }}
      >
        <Button
          noPadding
          style={style}
          onClick={e => {
            e.stopPropagation();
            if (taskCustomCalendar) initialDate.current = 0;
            setIsOpen(disabled ? false : !isOpen);
          }}
          ghost={hideTxtPicker ? ghost : true}
          {...buttonProps}
        >
          <>
            <span
              style={{
                color: isDelayed ? colors.error50 : null,
                width: '60px',
                paddingRight: !isGantt && '68px'
              }}
            >
              {dateRange?.[0] && dayjs(dateRange?.[0]).isValid() ? (
                dayjs(dateRange?.[0])
                  .format('DD MMM')
                  .toString()
              ) : (
                <Tooltip
                  placement="bottom"
                  overlayStyle={{ whiteSpace: 'pre-line', maxWidth: 270 }}
                  title="Data de início"
                >
                  <span>
                    <StyledIcon style={{ marginLeft: !isGantt && '10px' }} icon={faCalendarWeek} />
                  </span>
                </Tooltip>
              )}{' '}
              {!taskCustomCalendar && dateRange?.[1] && dayjs(dateRange?.[1]).isValid()
                ? ` - ${dayjs(dateRange?.[1])
                    .format('DD MMM')
                    .toString()}`
                : null}
            </span>
          </>
        </Button>
        {taskCustomCalendar && (
          <Button
            style={style}
            onClick={e => {
              e.stopPropagation();
              if (taskCustomCalendar) initialDate.current = 1;
              setIsOpen(disabled ? false : !isOpen);
            }}
            ghost={hideTxtPicker ? ghost : true}
            {...buttonProps}
          >
            <>
              <span
                style={{
                  color: isDelayed ? colors.error50 : null,
                  width: '50px',
                  paddingLeft: isGantt ? '23px' : '0px'
                }}
              >
                {dateRange?.[1] && dayjs(dateRange?.[1]).isValid() ? (
                  dayjs(dateRange?.[1])
                    .format('DD MMM')
                    .toString()
                ) : (
                  <Tooltip
                    placement="bottom"
                    overlayStyle={{ whiteSpace: 'pre-line', maxWidth: 270 }}
                    title="Data de fim"
                  >
                    <span>
                      <StyledIcon style={{ paddingLeft: isGantt ? '8px' : '0px' }} icon={faCalendarWeek} />
                    </span>
                  </Tooltip>
                )}
              </span>
            </>
          </Button>
        )}
      </div>
    );
  };

  return isOpen ? (
    <div role="presentation" ref={wrapperRef} onClick={e => e.stopPropagation()}>
      {hideTxtPicker ? OpenNewCalendarBtn(children) : null}
      <StyledPicker
        style={styleCalendar}
        className={`${hideTxtPicker ? 'hideTxtPicker' : ''} vobi-range-picker`}
        {...props}
        renderExtraFooter={() => {
          return showTime ? (
            <div
              style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '44px', position: 'relative' }}
            >
              <div style={{ flex: 5 }}>
                <span>Horário de início: </span>
                <StyledTimePicker
                  format={format}
                  value={dateRange?.[0] ? moment(dateRange?.[0]?.format(format), format) : moment('00:00', 'HH:mm')}
                  minuteStep={5}
                  onChange={val => changeTime(0, val)}
                  popupClassName="time-picker-popup"
                />
              </div>
              <div style={{ display: 'flex', flex: 6, alignItems: 'center', justifyContent: 'space-around' }}>
                <span>Horário de fim: </span>
                <StyledTimePicker
                  format={format}
                  value={dateRange?.[1] ? moment(dateRange?.[1]?.format(format), format) : moment('00:00', 'HH:mm')}
                  minuteStep={5}
                  onChange={val => changeTime(1, val)}
                />
                <Button type="primary" onClick={() => submit({ start: dateRange[0], end: dateRange[1] })}>
                  Ok
                </Button>
              </div>
            </div>
          ) : (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '4px'
                }}
              >
                {isFilter ? (
                  <p style={{ color: colors.primary40 }}>Filtre as tarefas pela data de término</p>
                ) : (
                  <>
                    <div
                      style={{ display: 'flex' }}
                      role="presentation"
                      onClick={() => {
                        inputNumberRef.current.focus();

                        setTimeout(() => {
                          inputNumberRef.current.focus();
                        }, 1);
                      }}
                    >
                      <p>Duração</p>
                      <Input
                        ref={inputNumberRef}
                        disabled={checkTaskBasedDate}
                        size="small"
                        placeholder="Dias"
                        style={{ width: '70px', height: '27px', margin: '0px 8px', alignSelf: 'center' }}
                        value={durationTime.current}
                        onChange={e => {
                          const val = Number(e.target.value);
                          let [start, end] = dateRange;
                          if ((!start && !end) || !val) {
                            durationTime.current = val || null;
                            setDateRange([start, end]);
                            return;
                          }
                          if (!start || (end && initialDate.current === 1)) {
                            start = changeDate(
                              start,
                              dayjs(end)
                                .subtract(val - 1, 'day')
                                .hour(0)
                                .minute(0)
                            );
                          } else {
                            end = changeDate(
                              end,
                              dayjs(start)
                                .add(val - 1, 'day')
                                .hour(23)
                                .minute(59)
                            );
                          }
                          durationTime.current = val;
                          setDateRange([start, end]);
                        }}
                      />
                      {isParent && (
                        <Checkbox
                          checked={checkTaskBasedDate}
                          onChange={() => {
                            setCheckTaskBasedDate(!checkTaskBasedDate);
                            setShouldSubmit(true);
                          }}
                        >
                          <p style={{ fontSize: 12, color: colors.neutralSecondary40 }}>
                            Vincular com datas de ínicio e fim das tarefas
                          </p>
                        </Checkbox>
                      )}
                    </div>
                  </>
                )}
                <Button type="primary" onClick={() => submit({ start: dateRange[0], end: dateRange[1] })}>
                  Ok
                </Button>
              </div>
              {checkTaskBasedDate && (
                <StyledBlockCalendar>
                  <Text
                    style={{
                      zIndex: 1002,
                      fontSize: 20,
                      fontWeight: 500,
                      textAlign: 'center'
                    }}
                  >
                    Para editar a data da etapa, remova o vínculo com as datas das tarefas.
                  </Text>
                </StyledBlockCalendar>
              )}
            </>
          );
        }}
        locale={ptBR.DatePicker}
        placeholder={['Início', 'Fim']}
        open
        readOnly
        value={dateRange}
        onCalendarChange={(dates, aux, key) => {
          if (checkTaskBasedDate) return;
          const [start, end] = dateRange;
          const [dateStart, dateEnd] = dates || [];
          let _start = dateStart ? changeDate(start, dateStart) : null;
          let _end = dateEnd ? changeDate(end, dateEnd) : null;

          if (!showTime) {
            _start = !start && _start ? dayjs(_start)?.startOf('day') : _start;
            _end =
              !end && _end
                ? dayjs(_end)
                    ?.hour(23)
                    ?.minute(59)
                : _end;
          }
          if (key?.range === 'start' && durationTime.current) {
            _end = _start
              ?.add(durationTime.current - 1, 'day')
              ?.hour(end?.hour() || 23)
              ?.minute(end?.minute() || 59);
          }
          if (key?.range === 'end' && durationTime.current && _end && !_start) {
            _start = _end
              ?.subtract(durationTime.current - 1, 'day')
              ?.hour(start?.hour() || 0)
              ?.minute(start?.minute() || 0);
          }

          setDateRange([_start, _end]);
          setShouldSubmit(true);
        }}
        panelRender={current => {
          return <StyledPanel ref={preventClose}>{current}</StyledPanel>;
        }}
      />
    </div>
  ) : (
    OpenNewCalendarBtn(children)
  );
};

RangePicker.propTypes = {
  style: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  children: PropTypes.instanceOf(Object),
  isDelayed: PropTypes.bool,
  showTime: PropTypes.bool,
  disabled: PropTypes.bool,
  hideTxtPicker: PropTypes.bool,
  ghost: PropTypes.bool,
  buttonProps: PropTypes.instanceOf(Object),
  styleCalendar: PropTypes.instanceOf(Object),
  isFilter: PropTypes.bool,
  submitOnOk: PropTypes.bool,
  taskCustomCalendar: PropTypes.bool,
  isGantt: PropTypes.bool,
  taskBasedDate: PropTypes.bool,
  isParent: PropTypes.bool
};

export default RangePicker;
