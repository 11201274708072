import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import Text from 'antd/lib/typography/Text';
import Button from '../Button/Button';
import css from './PreviewModal.module.scss';
import useViewport from '../../_Hooks/useViewport';
import InfoSidebar from '../Sidebar/InfoSidebar';
import PreviewFiles from '../Content/PreviewFiles';
import { isExtensionSupported } from '../../lib/helpers/helper';
import Modal from './Modal';
import useReplaceUrl from '../../_Hooks/useReplaceUrl';

const PreviewModal = ({
  directionFile,
  file,
  fileType,
  onToggleModal = f => f,
  copyLinkParam,
  idRefurbish,
  sideBarTab,
  size,
  ...otherProps
}) => {
  const _fileType = fileType.toLowerCase();

  const { isMobile } = useViewport(window.innerWidth);

  const { returnToOriginalUrl } = useReplaceUrl({
    preventTrigger: !copyLinkParam,
    urlToReplace: `/${copyLinkParam}/${file?.id}`
  });

  return (
    <div className={css['c-preview-modal']}>
      <Modal
        open
        noPadding
        hideFooter
        onClose={() => {
          returnToOriginalUrl();
          onToggleModal(false);
        }}
        style={{ display: 'flex' }}
        height={size || '100%'}
        width={size || '100%'}
      >
        {isMobile() ? (
          <div className={css['c-preview-modal__content']}>
            <div className={css['c-preview-modal__file']}>
              {isExtensionSupported(_fileType) ? (
                <PreviewFiles fileType={_fileType} path={file.src || file.fullpath || file.fullPath} />
              ) : (
                <Text style={{ fontSize: '16px' }}> Pré-visualização não suportada </Text>
              )}
            </div>
          </div>
        ) : (
          <div style={{ display: 'flex', height: '100%' }}>
            <div className={css['c-preview-modal__content']}>
              {!!directionFile && (
                <div>
                  <Button noPadding type="primary" onClick={() => directionFile()}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </Button>
                </div>
              )}
              <div className={css['c-preview-modal__file']}>
                {isExtensionSupported(_fileType) ? (
                  <PreviewFiles fileType={_fileType} path={file.src || file.fullpath || file.fullPath} />
                ) : (
                  <Text style={{ fontSize: '16px' }}> Pré-visualização não suportada </Text>
                )}
              </div>
              {!!directionFile && (
                <div>
                  <Button noPadding type="primary" onClick={() => directionFile(true)}>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </Button>
                </div>
              )}
            </div>
            <InfoSidebar
              onToggleModal={onToggleModal}
              folder={{ shared: true }}
              data={file}
              hasPadding={false}
              isModal
              model="file"
              isSupported={isExtensionSupported(_fileType)}
              idRefurbish={idRefurbish}
              selectedTab={sideBarTab}
              {...otherProps}
            />
          </div>
        )}
      </Modal>
    </div>
  );
};

PreviewModal.propTypes = {
  file: PropTypes.instanceOf(Object),
  directionFile: PropTypes.func,
  onToggleModal: PropTypes.func,
  fileType: PropTypes.string,
  showTabs: PropTypes.bool,
  copyLinkParam: PropTypes.string,
  idRefurbish: PropTypes.number,
  sideBarTab: PropTypes.string,
  size: PropTypes.string
};

export default PreviewModal;
