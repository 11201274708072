import styled, { css } from 'styled-components';
import { colors, fonts, spaces } from '../../styles/style';

export const CardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: ${spaces.space1};
  padding-left: ${spaces.space2};
  padding-right: ${spaces.space1};
`;

export const InlineItems = styled.div`
  display: flex;
  gap: ${spaces.space1};
  padding-right: ${spaces.space2};
  padding-left: ${spaces.space2};
  align-items: center;
  .flex--1 {
    flex: 1;
  }
  .value-field-wrapper {
    flex: ${props => (props.isView ? 1 : 3)};
  }
  .percentual-field-wrapper {
    width: 80px;
  }
  .installment-field-wrapper {
    ${props =>
      !props.isView
        ? css`
            width: 56px;
          `
        : css`
            flex: 1;
          `}
  }
  .field {
    ${props =>
      !props.isView &&
      css`
        height: 32px;
      `}

    display: flex;
    align-items: center;
  }
`;

export const StyledCardMobile = styled.div`
  margin-bottom: ${spaces.space2};
  background-color: ${colors.primary99};
  border: 1px solid ${colors.white};
  border-radius: ${spaces.space1};
  box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.1);
  padding-top: ${spaces.space2};
  padding-bottom: ${spaces.space1};
`;

export const StyledCardRow = styled.div`
  padding-bottom: ${spaces.space2};
`;

export const StyledStatus = styled.p`
  font-size: ${fonts.sizeSm};
  display: flex;
  align-items: center;
  height: 32px;
  color: ${props => (props.statusColor ? props.statusColor : colors.neutral40)};
`;
export const Text = styled.p`
  font-size: ${fonts.sizeSm};
  color: ${props => (!props.$color ? colors.neutral50 : props.$color)};
  ${props =>
    props.$withMarginBottom &&
    css`
      margin-bottom: ${spaces.space1};
    `}
`;
export const StyledText = styled(Text)`
  color: ${colors.neutral40};
  margin-bottom: ${spaces.space1};
`;
