import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors, spaces, fonts } from '../../styles/style';
import { getMinutesFromHHMM, toHHMM } from '../../lib/helpers/helper';
import Input from './Input';

const StyledMaskInput = styled(Input)`
  &:focus {
    box-shadow: none;
    border-color: ${colors.neutral60};
  }

  &:hover {
    border: 1px solid ${colors.neutral60};
    cursor: text;
    border-radius: ${spaces.space1};
  }

  &:focus {
    background-color: white;
  }

  border-radius: 8px;
  transition: all 0.3s;
  border: 0;
  width: 100%;
  padding: 4px 11px;
  background-color: transparent;
  font-size: ${fonts.sizeSm};
`;

const MaskedInput = ({ value, onBlur = () => {}, style, type = 'tel,', readOnly, id }) => {
  const [_value, setValue] = useState();
  const [focus, setFocus] = useState(false);

  const handleBlur = e => {
    const newValue = getMinutesFromHHMM(e.target.value);
    const minutes = Math.max(0, newValue);
    if (minutes) setValue(toHHMM(minutes));
    setFocus(false);

    if (newValue !== (value || 0)) {
      onBlur(minutes || null);
    }
  };

  const handleKey = e => {
    if (e.key === 'Enter') {
      e.target.blur();
      setValue(e.target.value);
    }
  };

  useEffect(() => {
    setValue(toHHMM(value));
  }, [value]);

  return !readOnly ? (
    <StyledMaskInput
      id={id}
      style={style}
      placeholder={focus ? '0:00' : ''}
      onFocus={() => setFocus(true)}
      type={type}
      onKeyDown={handleKey}
      onChange={e => setValue(e.target?.value)}
      value={_value}
      onBlur={handleBlur}
      onClick={e => e.stopPropagation()}
    />
  ) : (
    <span>{_value || ''}</span>
  );
};

MaskedInput.propTypes = {
  style: PropTypes.instanceOf(Object),
  onBlur: PropTypes.func,
  value: PropTypes.number,
  type: PropTypes.string,
  readOnly: PropTypes.bool,
  id: PropTypes.string
};

export default MaskedInput;
