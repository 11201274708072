import html2canvas from 'html2canvas';
import html2pdf from 'html2pdf.js';
import JSPDF from 'jspdf';

export const print = ({ elementId, name = 'cronograma', orientation = 'landscape' }) => {
  window.scrollTo(0, 0);
  const Element = document.getElementById(elementId);
  const doc = new JSPDF({ orientation });

  return html2canvas(Element, {
    letterRendering: 1,
    allowTaint: true,
    useCORS: true,
    width: Element.scrollWidth,
    height: Element.scrollHeight,
    scrollX: 0,
    scrollY: 0,
    windowWidth: document.documentElement.offsetWidth,
    windowHeight: document.documentElement.offsetHeight
  }).then(canvas => {
    const imgData = canvas.toDataURL('image/png');
    const imgWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    let position = 0;

    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
      heightLeft -= pageHeight;
    }
    doc.save(`${name}.pdf`);
  });
};

export const printPdf = ({ elementId, name = 'cronograma', orientation = 'landscape' }) => {
  window.scrollTo(0, 0);
  const Element = document.getElementById(elementId);

  const opt = {
    margin: [10, 0],
    filename: `${name}.pdf`,
    image: { type: 'jpeg', quality: 0.7 },
    html2canvas: {
      scale: 2,
      allowTaint: true,
      useCORS: true,
      dpi: 192
    },
    jsPDF: {
      orientation,
      unit: 'mm',
      format: 'a4'
    },
    pagebreak: { mode: '', avoid: ['.image', '.ant-table-row', 'p'] }
  };

  return html2pdf()
    .set(opt)
    .from(Element)
    .save();
};
