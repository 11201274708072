import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  faInfoCircle,
  faCommentAlt,
  faChevronCircleLeft,
  faChevronCircleRight
} from '@fortawesome/free-solid-svg-icons';
import { StyledIcon, StyledChevron, StyledRenderList, StyleInfoSidebar } from './InfoSidebar.style';

import RenderFileInfo from './RenderFileInfo';
import StyledTabs, { TabPane } from '../Tabs/Tabs';
import CommentList from '../List/CommentList';
import RenderNoteInfo from './RenderNoteInfo';

const infoComponents = {
  'refurbish-notes': RenderNoteInfo,
  file: RenderFileInfo
};

const InfoSidebar = ({
  data,
  isModal,
  idRefurbish,
  selectedTab = 'info',
  showComments = true,
  model,
  isFolder,
  onShareItem,
  ...props
}) => {
  const { user } = useSelector(state => state.authReducer);
  const [isExpanded, setIsExpanded] = useState(true);
  const [selectedIndexSideBar, setSelectedIndexSideBar] = useState(selectedTab);

  const RenderInfo = infoComponents[model];

  useEffect(() => setSelectedIndexSideBar(selectedTab), [selectedTab]);

  return isExpanded ? (
    <StyleInfoSidebar>
      {!isModal && <StyledChevron onClick={() => setIsExpanded(false)} icon={faChevronCircleRight} />}
      <StyledTabs
        fullWidth
        activeKey={selectedIndexSideBar || 'info'}
        animated={false}
        onChange={key => setSelectedIndexSideBar(key)}
      >
        <TabPane key="info" tab="Informações">
          <StyledRenderList>
            {data?.id ? (
              <RenderInfo
                setSelectedIndexSideBar={setSelectedIndexSideBar}
                setIsExpanded={setIsExpanded}
                idRefurbish={idRefurbish}
                user={user}
                isModal={isModal}
                data={data}
                onShareItem={onShareItem}
                isFolder={isFolder}
                {...props}
              />
            ) : (
              <span>Nenhum item selecionado</span>
            )}
          </StyledRenderList>
        </TabPane>
        {showComments && !isFolder && (
          <TabPane key="comentarios" tab="Comentários" style={{ paddingLeft: 16 }}>
            {data ? (
              <CommentList data={data} idRefurbish={idRefurbish} model={model} />
            ) : (
              <p>Nenhum arquivo selecionado.</p>
            )}
          </TabPane>
        )}
      </StyledTabs>
    </StyleInfoSidebar>
  ) : (
    <div style={{ flex: 0.1, height: '100%', position: 'relative', backgroundColor: '#FFFFFF' }}>
      <StyledChevron onClick={() => setIsExpanded(true)} icon={faChevronCircleLeft} />
      <div
        role="presentation"
        style={{ display: 'flex', flexDirection: 'column', paddingTop: '72px' }}
        onClick={() => setIsExpanded(true)}
      >
        <div>
          <StyledIcon icon={faInfoCircle} onClick={() => setSelectedIndexSideBar('info')} />
        </div>
        <div>
          <StyledIcon icon={faCommentAlt} onClick={() => setSelectedIndexSideBar('comentarios')} />
        </div>
      </div>
    </div>
  );
};

InfoSidebar.propTypes = {
  data: PropTypes.instanceOf(Object),
  isModal: PropTypes.bool,
  idRefurbish: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selectedTab: PropTypes.string,
  showComments: PropTypes.bool,
  model: PropTypes.string,
  isFolder: PropTypes.bool,
  onShareItem: PropTypes.func
};

export default InfoSidebar;
