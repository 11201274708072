import styled from 'styled-components';
import { Typography } from 'antd';

import { fonts, colors } from '../../styles/style';

const { Text } = Typography;

const StyledText = styled(Text)`
  color: ${colors.neutral50};
  font-size: ${props => (props.size ? fonts[props.size] : null)};
  padding-top: 8px;
`;

const StyledContentDiv = styled.div`
  background-color: ${colors.white};
  min-height: 64px;
  border-radius: 8px;
`;

const DivTotal = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export { StyledText, StyledContentDiv, DivTotal };
