import styled from 'styled-components';

import { colors, fonts, spaces } from '../../../styles/style';

const SectionTitle = styled.p`
  font-size: ${fonts.sizeLg};
  padding-top: ${spaces.space2};
  border-top: ${({ border }) => (border ? `1px solid ${colors.neutral90}` : '')};
`;

export { SectionTitle };
