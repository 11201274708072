import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { faArrowLeft, faEye, faInfoCircle, faShareAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { useHistory, useLocation, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { Space } from 'antd';
import { toast } from 'react-toastify';
import qs from 'qs';
import dayjs from 'dayjs';
import { Context } from '../../contexts/GeneralContext';
import Button from '../../components/Button/Button';
import { colors, spaces } from '../../styles/style';
import {
  Header,
  LeftHeaderContent,
  RightHeaderContent,
  StatusContainer,
  SubTitle,
  Title,
  Info,
  Container,
  Content,
  Footer,
  Line,
  ShowModalEmptyPayment,
  EmptyText,
  ExitCustomerViewButton
} from './Payment.style';
import useViewport from '../../_Hooks/useViewport';
import SharePaymentModal from '../../components/Modal/SharePaymentModal';
import useFormState from '../../_Hooks/useFormState';
import PaymentBasicForm from '../../components/Form/PaymentBasicForm';
import PaymentFormItem from '../../components/Form/PaymentFormItem';
import useCRUD from '../../_Hooks/useCRUD';
import PaymentAttachmentContent from '../../components/Content/PaymentAttachmentContent';

import CenteredLoader from '../../components/Loader/CenteredLoader';
import PaymentNotesForm from '../../components/Form/PaymentNotesForm';
import PaymentConfiguration from '../../components/Payments/PaymentConfiguration';
import { getColorByStatusPayment } from '../../lib/helpers/helper';
import ConfirmModal from '../../components/Modal/ConfirmModal';
import formatCurrency from '../../lib/helpers/formatCurrency';
import { paymentSchema } from '../../lib/mapping/Form/paymentSchema';
import formatNumber from '../../lib/helpers/formatNumber';
import PaymentLoadingModal from '../../components/Modal/PaymentLoadingModal';
import Modal from '../../components/Modal/Modal';

let _d;
const Payment = ({ isView, isPublic, idRefurbish, ...props }) => {
  const location = useLocation();
  const { projeto, oportunidade, cliente } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { userType } = useSelector(state => state.setup.enums);
  const { user } = useSelector(state => state.authReducer) || {};

  const isCustomer = user?.userType === userType?.customer?.value;
  const idRefurbishFromQuery = projeto || oportunidade;
  const refurbishType = projeto ? 'projeto' : 'oportunidade';
  const redirectTo = idRefurbishFromQuery
    ? `/profissional/${refurbishType}s/perfil/${idRefurbishFromQuery}/cobrancas`
    : `/${isCustomer ? 'cliente' : 'profissional/financeiro'}/cobrancas`;
  const { paymentStatuses, financialCategories, refurbishStatus } = useSelector(state => state.setup.enums);
  const { id } = useParams();
  const history = useHistory();
  const [isRevision, setIsRevision] = useState(false);
  const [extraValueErrors, setExtraValueErrors] = useState({});

  const [isCustomerView, setCustomerView] = useState(isCustomer);
  const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [totalInstallmentError, setTotalInstallmentError] = useState('');
  const [shareModal, setShareModal] = useState({ paymentCreated: false, paymentId: '' });
  const [_refurbish, setRefurbish] = useState(null);
  const [showLoadingModal, setShowLoadingModal] = useState(false);

  const { data, loading, handleGet, handleUpdate, handleCreate, handleDelete } = useCRUD({
    model: `payment${isPublic ? '/public' : ''}`,
    pathOptions: id ? `/${id}` : '',
    immediatelyLoadData: false
  });

  const { handleGet: getCustomer } = useCRUD({
    model: 'companyCustomer',
    immediatelyLoadData: false
  });

  const { handleGet: getRefurbish } = useCRUD({
    model: 'refurbish',
    immediatelyLoadData: false
  });

  const statusColor = getColorByStatusPayment(data?.paymentStatus?.id, paymentStatuses);

  const _formatCurrency = number =>
    number
      ? formatCurrency(number, {
          currencySymbol: 'R$ '
        })
      : null;

  const _formatNumber = number => (number ? formatNumber(number) : null);

  const { formState, handleBlur, handleChange, setField, isValid, handleSubmit, setTouched } = useFormState(
    data
      ? {
          ...data,
          discount: _formatCurrency(data?.discount),
          shipping: _formatCurrency(data?.shipping),
          taxes: _formatCurrency(data?.taxes),
          extraValues: null
        }
      : {
          name: '',
          billingDate: dayjs(_d).format('YYYY-MM-DD'),
          idRefurbish: Number(idRefurbishFromQuery),
          idCompanyCustomer: cliente,
          idFinancialCategory: financialCategories.serviceSelling,
          installments: [],
          paymentItems: [],
          paymentTypes: [],
          total: 0,
          idCompany: user?.company?.id,
          company: user?.company,
          billType: 'income',
          isCharge: true
        },
    paymentSchema,
    true
  );

  useEffect(() => {
    _d = new Date();
  }, []);

  useEffect(() => {
    if (data?.refurbish) setRefurbish(data.refurbish);
  }, [data]);

  const { values, touched, errors } = formState;

  const handleLoad = () =>
    handleGet({
      refetchOptions: {
        where: { ...((idRefurbish && { idRefurbish }) || {}) },
        include: [
          { model: 'installment', include: ['installmentStatus', 'paymentType'] },
          'refurbish',
          'paymentItems',
          'paymentTypes',
          'paymentStatus',
          'company',
          'companyCustomer',
          'files'
        ]
      }
    });
  useEffect(() => {
    if (id) {
      handleLoad();
    }
  }, [id]);

  const { isMobile } = useViewport(window.innerWidth);

  const isTotalInstallmentValid = () => {
    const { installments, total } = values;
    const _total = parseFloat(total.toFixed(2));
    const totalOfInstallments = parseFloat(
      installments.reduce((acc, curr) => acc + parseFloat(curr.price), 0).toFixed(2)
    );
    if (totalOfInstallments > _total || totalOfInstallments < _total) {
      setTotalInstallmentError(
        `O valor total das parcelas deve ser ${formatCurrency(total, {
          currencySymbol: 'R$ '
        })}`
      );
      return false;
    }
    if (totalInstallmentError) setTotalInstallmentError('');
    return true;
  };

  const handleRevisionAndCreate = e => {
    const isExtraValuesWithErrors = Object.keys(extraValueErrors).length !== 0;
    const totalInstallmentisValid = isTotalInstallmentValid();
    if (!isValid()) {
      toast.error('Por favor verifique os erros de formulário antes da revisão!');
    }
    handleSubmit(() => {
      if (isExtraValuesWithErrors || !totalInstallmentisValid) {
        toast.error('Por favor verifique os erros de formulário antes da revisão!');
        return;
      }
      setIsRevision(true);
      document.getElementById('main-content').scrollTo(0, 0);
    })(e);
  };

  const handleBack = () => {
    if (isRevision) {
      setIsRevision(false);
    } else {
      history.push(redirectTo);
    }
  };

  const handleCancel = () => {
    history.push(redirectTo);
  };

  const handleConfirmCancel = () => {
    handleUpdate({ values: { id: data.id, idPaymentStatus: 4 }, refresh: false, verb: 'patch' }).then(() => {
      history.push(redirectTo);
    });
  };

  const handleCustomerView = () => {
    setCustomerView(true);
  };

  const handleBackFromCustomerView = () => {
    setCustomerView(false);
  };

  const handleSave = isDraft => {
    if (!isDraft) {
      const isExtraValuesWithErrors = Object.keys(extraValueErrors).length !== 0;
      const totalInstallmentisValid = isTotalInstallmentValid();
      if (!isValid() || isExtraValuesWithErrors || !totalInstallmentisValid) {
        toast.error('Por favor verifique os erros de formulário antes da revisão!');
        return;
      }
    }

    const func = id ? handleUpdate : handleCreate;
    let idPaymentStatus = values.paymentStatus?.id;
    if (isDraft) idPaymentStatus = paymentStatuses.draft;
    else if (isRevision) idPaymentStatus = paymentStatuses.open;

    func({
      values: {
        ...values,
        idPaymentStatus,
        isCharge: true,
        discount: _formatNumber(values.discount),
        taxes: _formatNumber(values.taxes),
        shipping: _formatNumber(values.shipping)
      },
      refresh: false,
      noLoading: isDraft
    }).then(resp => {
      if (resp.error) return;

      if (isRevision) {
        setShareModal({ paymentId: resp.id, paymentCreated: true });
      } else if (isDraft) {
        toast.success('Rascunho salvo com sucesso.');
        history.push({
          pathname: `/profissional/cobrancas/editar/${resp.id}`,
          search: location.search
        });
      } else {
        toast.success('Cobrança editada com sucesso.');
        history.push(`/profissional/cobrancas/editar/${resp.id}`);
      }
    });
  };

  const handleShareModalClose = () => {
    if (shareModal.paymentCreated) {
      history.push({
        pathname: `/profissional/cobrancas/visualizar/${shareModal.paymentId}`,
        search: location.search
      });
    } else {
      setShareModal({ paymentId: '', paymentCreated: false });
    }
  };

  const handleShare = () => {
    setShareModal({ paymentId: id, paymentCreated: false });
  };

  const handleDeletePayment = () => {
    setShowConfirmDeleteModal(true);
  };

  const showModalLoading = callback => {
    setShowLoadingModal(true);
    setTimeout(() => {
      callback();
      setShowLoadingModal(false);
    }, 1500);
  };

  const handleConfirmDelete = () => {
    if (id) {
      handleDelete({ refresh: false, values: { event: 'invoice-deleted' } }).then(() => {
        showModalLoading(() => {
          history.push(redirectTo);
        });
      });
    }
  };
  const handleCancelPayment = () => {
    setShowConfirmCancelModal(true);
  };

  const handleEdit = () => {
    history.push({
      pathname: `/profissional/cobrancas/editar/${id}`,
      search: location.search
    });
  };

  const renderTitle = () => {
    if (isPublic) return `Cobrança ${id ? `#${id}` : ''}`;
    if (isView) return `Detalhes da cobrança ${id ? `#${id}` : ''}`;
    return `${id ? 'Editar' : 'Criar'} cobrança ${id ? `#${id}` : ''}`;
  };

  return loading ? (
    <CenteredLoader />
  ) : (
    <>
      {(isRevision || (isCustomerView && !isCustomer)) && (
        <Info>
          {isCustomerView && !isCustomer && (
            <ExitCustomerViewButton
              type="text"
              primaryBorderColor={!isMobile() ? 'primary30' : 'primary30'}
              noPadding={isMobile()}
              size={1}
              onClick={handleBackFromCustomerView}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
              <div className="hideMobile">Sair deste modo</div>
            </ExitCustomerViewButton>
          )}
          <FontAwesomeIcon icon={faInfoCircle} size="lg" />
          <span>
            Você está vendo o que seu cliente verá se você compartilhar a cobrança {!isCustomerView && 'após a criação'}
            .
          </span>
        </Info>
      )}
      {loading || (id && !data) ? (
        <CenteredLoader />
      ) : (
        <Context
          data={{
            values,
            setField,
            isView: isView || isRevision,
            isRevision,
            touched,
            errors,
            handleChange,
            handleBlur,
            statusColor,
            extraValueErrors,
            setExtraValueErrors,
            getCustomer,
            getRefurbish,
            isCustomerView,
            isPublic,
            isCharge: true,
            handleLoad,
            handleCancel,
            totalInstallmentError,
            _refurbish,
            setRefurbish,
            isCreate: !id,
            setTouched
          }}
        >
          <Container {...props}>
            <Header>
              <LeftHeaderContent>
                {!isPublic && (
                  <Button id="back-icon" text onClick={handleBack}>
                    <FontAwesomeIcon icon={faLongArrowLeft} />
                  </Button>
                )}

                <div>
                  <Title>{renderTitle()}</Title>
                  {isView && !isPublic && (
                    <SubTitle>(DATA DA CRIAÇÃO: {dayjs(values.createdAt).format('DD/MM/YYYY [às] HH:mm:ss')})</SubTitle>
                  )}
                </div>
              </LeftHeaderContent>
              {(isView || isRevision || isCustomerView) && (
                <RightHeaderContent>
                  {values.paymentStatus?.name && (
                    <StatusContainer $withMarginRight={!isPublic}>
                      Status: <span style={{ color: statusColor }}>{values.paymentStatus?.name}</span>
                    </StatusContainer>
                  )}

                  {!isPublic &&
                    !isRevision &&
                    !isCustomerView &&
                    values.idPaymentStatus !== paymentStatuses.cancelled &&
                    data?.idPaymentStatus !== paymentStatuses.draft && (
                      <>
                        <Button
                          type="primary"
                          text={isMobile()}
                          noPadding={isMobile()}
                          style={{ marginRight: `${spaces.space2}` }}
                          onClick={handleShare}
                        >
                          {isMobile() && <FontAwesomeIcon size="lg" icon={faShareAlt} />}
                          <div className="hideMobile">Compartilhar</div>
                        </Button>
                        <Button
                          type="primary"
                          text={isMobile()}
                          noPadding={isMobile()}
                          style={{ marginRight: `${spaces.space2}` }}
                          onClick={handleCustomerView}
                        >
                          {isMobile() && <FontAwesomeIcon size="lg" icon={faEye} />}
                          <div className="hideMobile">Ver como cliente</div>
                        </Button>
                      </>
                    )}
                </RightHeaderContent>
              )}
            </Header>
            <Content>
              <Space direction="vertical" size={16} style={{ width: '100%' }}>
                <PaymentBasicForm />
                <Line />
                <PaymentFormItem />
                <Line />
                <PaymentConfiguration isCustomerView={isCustomerView} />
                <Line />
                <PaymentNotesForm />
                <Line />
                <PaymentAttachmentContent />
              </Space>
            </Content>
            {!isPublic && !isCustomerView && (
              <Footer>
                {isView && (
                  <>
                    <Button type="text" className="withMarginRight" noPadding onClick={handleDeletePayment}>
                      Excluir
                    </Button>
                    {values.idPaymentStatus !== 4 && (
                      <>
                        <Button type="text" noPadding className="withMarginRight" onClick={handleCancelPayment}>
                          Cancelar
                        </Button>
                        <Button type="primary" onClick={handleEdit}>
                          Editar
                        </Button>
                      </>
                    )}
                  </>
                )}
                {isRevision && !isCustomer && (
                  <>
                    <Button type="text" noPadding className="withMarginRight" onClick={handleBack}>
                      Voltar
                    </Button>
                    <Button type="primary" onClick={() => handleSave(false)}>
                      Criar cobrança
                    </Button>
                  </>
                )}
                {!isView && !isRevision && (
                  <>
                    <Button type="text" noPadding className="withMarginRight" onClick={handleCancel}>
                      Voltar
                    </Button>
                    {id && values?.idPaymentStatus !== 1 ? (
                      <Button type="primary" onClick={() => handleSave(false)}>
                        Salvar
                      </Button>
                    ) : (
                      <>
                        <Button
                          type="text"
                          noPadding
                          className="withMarginRight"
                          style={{ color: `${colors.primary40}` }}
                          onClick={() => handleSave(true)}
                        >
                          Salvar rascunho
                        </Button>
                        <Button type="primary" onClick={handleRevisionAndCreate}>
                          Revisar e criar
                        </Button>
                      </>
                    )}
                  </>
                )}
              </Footer>
            )}
          </Container>
        </Context>
      )}
      <PaymentLoadingModal open={showLoadingModal} title="Estamos excluindo sua cobrança." />
      {shareModal?.paymentId && (
        <SharePaymentModal
          paymentId={shareModal?.paymentId}
          idRefurbish={_refurbish.id}
          setRefurbish={setRefurbish}
          paymentCreated={shareModal?.paymentCreated}
          onClose={handleShareModalClose}
          isOpportunity={refurbishStatus?.opportunity?.includes(data?.refurbish?.idStatus)}
        />
      )}

      {showConfirmCancelModal && (
        <ConfirmModal
          title="Cancelar cobrança"
          alertInfo="Essa operação não poderá ser desfeita"
          text="Ao cancelar, a cobrança continuará visível para você e para seu cliente. "
          onSubmit={handleConfirmCancel}
          onClose={() => setShowConfirmCancelModal(false)}
          modalWidth={340}
        />
      )}
      {!loading && !data && isView && (
        <ShowModalEmptyPayment>
          <Modal open hideFooter closable={false} width={832} height={128} sectionStyle={{ marginTop: spaces.space4 }}>
            <EmptyText>{`A cobrança ${isMobile() ? '' : `#${id}`} não está disponível`}</EmptyText>
          </Modal>
        </ShowModalEmptyPayment>
      )}
      {showConfirmDeleteModal && (
        <ConfirmModal
          title="Excluir cobrança"
          alertInfo="Essa operação não poderá ser desfeita"
          text="Ao excluir, a cobrança será removida permanente da sua conta e ninguém mais poderá acessá-la."
          onSubmit={handleConfirmDelete}
          onClose={() => setShowConfirmDeleteModal(false)}
          modalWidth={340}
        />
      )}
    </>
  );
};

Payment.propTypes = {
  isView: PropTypes.bool,
  isPublic: PropTypes.bool,
  installment: PropTypes.instanceOf(Object),
  idRefurbish: PropTypes.number
};

export default Payment;
