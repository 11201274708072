import styled from 'styled-components';
import Text from 'antd/es/typography/Text';
import { fonts, breakpoints, spaces } from '../../styles/style';
import Tabs from '../Tabs/Tabs';

const StyledText = styled(Text)`
  font-size: ${fonts.sizeLg};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 50%;

  @media (min-width: ${breakpoints.tablet}) {
    margin: 0 16px;
    font-size: ${fonts.sizeSm};
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`;

const StyledContainerBeforeList = styled.div`
  display: flex;
  padding: 20px 16px 0px 16px;

  > div {
    display: flex;
    flex-direction: column;
  }

  div {
    span {
      line-height: 30px;
      a {
        margin: 0px 5px;
      }
    }
    button {
      float: right;
    }
    &:nth-child(2) {
      padding: 0px 0px;
    }
  }
  @media (max-width: ${breakpoints.tablet}) {
    flex-wrap: wrap;
    padding: 0px 16px;
    div {
      flex-basis: 100%;
      margin: 5px 0px;
      button {
        float: none;
      }
    }
  }
`;

const StyledTemplateList = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: ${spaces.space2} 0;
  width: 100%;
`;

const StyledTabs = styled(Tabs)`
  background-color: transparent;

  .ant-tabs-extra-content {
    display: flex;
    flex: 1;
  }

  .ant-tabs-nav {
    border: none;
  }
  @media (max-width: ${breakpoints.tablet}) {
    .ant-tabs-nav {
      display: block;
    }
    .ant-tabs-extra-content {
      margin-top: 10px;
    }
  }
`;

const StyledFooter = styled.div`
  width: 100%;

  .pagination {
    margin-top: ${spaces.space2};
  }
`;

const TitleContainer = styled.div`
  @media (max-width: 970px) {
    max-width: 400px;
    word-wrap: break-word;
  }

  @media (max-width: 690px) {
    max-width: 300px;
    word-wrap: break-word;
  }
`;

export { StyledText, StyledTemplateList, StyledTabs, StyledFooter, StyledContainerBeforeList, TitleContainer };
