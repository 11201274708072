import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Context
import useCRUD from '../../_Hooks/useCRUD';

// Helpers
import formatCurrency from '../../lib/helpers/formatCurrency';
import formatNumber from '../../lib/helpers/formatNumber';

// schema
import { itemSchema, newFieldsMapping as itemMapping } from '../../lib/mapping/Form/itemSchema';

// components
import Form from '../Form/Form';
import ItemAttachmentContent from '../Content/ItemAttachmentContent';
import useViewport from '../../_Hooks/useViewport';
import Tabs, { TabPane } from '../Tabs/Tabs';
import CenteredLoader from '../Loader/CenteredLoader';
import Description from '../Content/Description';
import { mappingDescriptionConf } from '../../lib/mapping/Description/mappingDescriptionConf';

import { Container, TabContent, StyledDrawer } from './EditItemModal.styles';
import ShareLinkDropdown from '../Dropdown/ShareLinkDropdown';
import { colors, spaces } from '../../styles/style';
import CommentList from '../List/CommentList';

const formId = 'modal-customer-form';

const EditItemModal = ({
  id,
  idRefurbish,
  onClose = f => f,
  model = 'refurbish-items',
  options = {
    include: [
      idRefurbish && 'files',
      'supplier',
      {
        model: 'costCenter',
        as: 'costCenter',
        attributes: ['id', 'name']
      },
      {
        model: 'refurbishGroup',
        as: 'refurbishGroup',
        attributes: ['id', 'name']
      }
    ],
    where: { idRefurbish }
  },
  readOnly,
  tab = '0',
  isInstalment
}) => {
  const { user } = useSelector(state => state.authReducer);
  const { userType } = useSelector(state => state.setup.enums);
  const isCustomer = user.userType === userType.customer.value;

  const { refurbishItemType, refurbishItemStatus } = useSelector(state => state.setup.enums);
  const { data, loading, handleUpdate } = useCRUD({
    model,
    pathOptions: id ? `/${id}` : '',
    options
  });

  const { supplier } = data?.refurbishItem || data || {};

  const [files, setFiles] = useState([]);
  const [observation, setObservation] = useState('');
  const [selectedTab, setSelectedTab] = useState(tab || '0');
  const { isMobile } = useViewport(window.innerWidth);

  useEffect(() => {
    if (data && data.id) {
      setFiles(data.files || []);
      setObservation(data.observation || '');
    }
  }, [data]);

  const handleSubmit = submitData => {
    handleUpdate({
      values:
        selectedTab !== '0'
          ? {
              idRefurbish,
              observation,
              files,
              ...(data.status !== submitData.status &&
                submitData.status === refurbishItemStatus.approved.value && { isApproved: true })
            }
          : {
              ...submitData,
              idRefurbish,
              image: null,
              price: formatNumber(submitData.price),
              observation,
              files,
              ...(data.status !== submitData.status &&
                submitData.status === refurbishItemStatus.approved.value && { isApproved: true })
            },
      refresh: false
    }).then(resp => {
      if (!resp.error) {
        onClose(true);
      }
    });
  };

  const titles = [
    data && data.type === refurbishItemType.labor ? 'Detalhes do serviço' : 'Detalhes do produto',
    'Anexos',
    'Dados do fornecedor',
    'Comentários'
  ];

  return (
    <StyledDrawer
      open
      title={titles[selectedTab]}
      onClose={() => onClose(false)}
      formId={formId}
      width={isMobile() ? '100%' : 560}
      hideFooter={isCustomer}
      footer={readOnly ? [] : null}
      onSubmit={selectedTab !== '0' ? handleSubmit : () => {}}
      headerExtraContent={() => (
        <ShareLinkDropdown id={id} whatsapp urlContext="o" style={{ marginLeft: spaces.space1 }} />
      )}
    >
      {loading ? (
        <CenteredLoader />
      ) : (
        <Container>
          <Tabs activeKey={selectedTab} onChange={key => setSelectedTab(key)}>
            {!isInstalment && (
              <TabPane key="0" tab="Detalhes">
                <TabContent>
                  <Form
                    id={formId}
                    mapping={itemMapping({ ...data, isCustomer })}
                    schema={itemSchema}
                    displayButtons={false}
                    onSubmit={handleSubmit}
                    data={{ ...data, price: formatCurrency(data?.price || 0) } || {}}
                  />
                </TabContent>
              </TabPane>
            )}

            {idRefurbish && (
              <TabPane key="1" tab="Anexos">
                <TabContent>
                  <ItemAttachmentContent
                    data={{ files, observation }}
                    setFiles={setFiles}
                    setObservation={setObservation}
                    readOnly={isCustomer}
                    idRefurbish={idRefurbish}
                  />
                </TabContent>
              </TabPane>
            )}

            {!isInstalment && (
              <TabPane key="2" tab="Dados do fornecedor">
                <TabContent>
                  <Description
                    title="Dados bancários"
                    mapping={mappingDescriptionConf.bank}
                    column={3}
                    data={supplier || {}}
                    background={null}
                    noPadding
                  />
                  <Description
                    style={{ paddingTop: 16, borderTop: `1px solid ${colors.neutral90}` }}
                    title="Dados da empresa"
                    mapping={mappingDescriptionConf.supplier}
                    data={supplier || {}}
                    background={null}
                    noPadding
                  />
                </TabContent>
              </TabPane>
            )}
            {idRefurbish && !isInstalment && (
              <TabPane key="3" tab="Comentários">
                <TabContent>
                  <CommentList data={data} idRefurbish={idRefurbish} model={model} isWithinDrawer />
                </TabContent>
              </TabPane>
            )}
          </Tabs>
        </Container>
      )}
    </StyledDrawer>
  );
};

EditItemModal.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  idRefurbish: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func,
  model: PropTypes.string,
  options: PropTypes.instanceOf(Object),
  readOnly: PropTypes.bool,
  tab: PropTypes.string,
  isInstalment: PropTypes.bool
};

export default memo(EditItemModal);
