import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/pro-regular-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { Tooltip } from 'antd';
import SelectCustomerRefurbish from '../../../components/Select/SelectCustomerRefurbish';
import { colors } from '../../../styles/style';

const mappingMobileSubHeader = {
  profissional: {
    projetos: {
      title: 'Meus projetos',
      EndContent: () => <div style={{ width: 24 }} />,
      perfil: {
        title: 'Informações do projeto',
        EndContent: () => <div style={{ width: 24 }} />
      },
      novo: {
        title: 'Novo Projeto',
        EndContent: () => <div style={{ width: 24 }} />
      },
      editar: {
        title: 'Editar projeto',
        EndContent: () => <div style={{ width: 24 }} />
      }
    },
    configuracoes: {
      'dados-pessoais': {
        title: 'Informações de Dados Pessoais',
        EndContent: () => <div style={{ width: 24 }} />
      },
      'meu-negocio': {
        title: 'Informações do Meu Negócio',
        EndContent: () => <div style={{ width: 24 }} />
      },
      'dados-bancarios': {
        title: 'Informação dos dados bancários',
        EndContent: () => <div style={{ width: 24 }} />
      },
      'trocar-senha': {
        title: 'Trocar senha',
        EndContent: () => <div style={{ width: 24 }} />
      },
      equipe: {
        title: 'Minha equipe',
        EndContent: () => <div style={{ width: 24 }} />
      },
      etapas: {
        title: 'Etapas do projeto',
        EndContent: () => <div style={{ width: 24 }} />
      },
      visitante: {
        title: 'Meus visitantes',
        EndContent: () => <div style={{ width: 24 }} />,
        novo: {
          title: 'Novo Visitante',
          EndContent: () => <div style={{ width: 24 }} />
        }
      }
    },
    notificacoes: {
      title: 'Todas',
      EndContent: () => (
        <Link to="/profissional/notificacoes/configuracoes">
          <FontAwesomeIcon color={colors.neutralSecondary40} size="lg" icon={faCog} />
        </Link>
      ),
      configuracoes: {
        title: 'Configurações',
        EndContent: () => (
          <Tooltip
            placement="bottomLeft"
            title={`Você sempre receberá notificações pela Plataforma Vobi,
             mas também é possível receber pelo número de celular e e-mail do seu cadastro.`}
          >
            <FontAwesomeIcon color={colors.primary40} icon={faInfoCircle} />
          </Tooltip>
        )
      }
    },
    oportunidades: {
      perfil: {
        title: 'Informações da oportunidade',
        EndContent: () => <div style={{ width: 24 }} />
      },
      editar: {
        title: 'Editar oportunidade',
        EndContent: () => <div style={{ width: 24 }} />
      },
      novo: {
        title: 'Nova oportunidade',
        EndContent: () => <div style={{ width: 24 }} />
      }
    },
    contatos: {
      clientes: {
        title: 'Informações do cliente',
        EndContent: () => <div style={{ width: 24 }} />,
        novo: {
          title: 'Novo Cliente',
          EndContent: () => <div style={{ width: 24 }} />
        }
      },
      fornecedores: {
        title: 'Informações do fornecedor',
        EndContent: () => <div style={{ width: 24 }} />,
        novo: {
          title: 'Novo fornecedor',
          EndContent: () => <div style={{ width: 24 }} />
        },
        editar: {
          title: 'Editar fornecedor',
          EndContent: () => <div style={{ width: 24 }} />
        }
      }
    },
    catalogo: {
      title: 'Itens do catálogo',
      EndContent: () => <div style={{ width: 24 }} />,
      'minhas-categorias': {
        title: 'Categorias',
        EndContent: () => <div style={{ width: 24 }} />,
        novo: {
          title: 'Nova categoria',
          EndContent: () => <div style={{ width: 24 }} />
        },
        editar: {
          title: 'Editar categoria',
          EndContent: () => <div style={{ width: 24 }} />
        }
      },
      'meus-produtos-servicos': {
        title: 'Meus Produtos e Serviços',
        EndContent: () => <div style={{ width: 24 }} />,
        novo: {
          title: 'Novo item',
          EndContent: () => <div style={{ width: 24 }} />
        },
        editar: {
          title: 'Editar item',
          EndContent: () => <div style={{ width: 24 }} />
        }
      }
    },
    'meu-site': {
      informacoes: {
        title: 'Informações do portfolio',
        EndContent: () => <div style={{ width: 24 }} />
      },
      configuracoes: {
        title: 'Informações de configurações',
        EndContent: () => <div style={{ width: 24 }} />
      },
      portfolio: {
        title: 'Informações do portfolio',
        EndContent: () => <div style={{ width: 24 }} />,
        novo: {
          title: 'Novo portfolio',
          EndContent: () => <div style={{ width: 24 }} />
        },
        editar: {
          title: 'Editar portfolio',
          EndContent: () => <div style={{ width: 24 }} />
        }
      }
    },
    produtividade: {
      'gestao-tarefas': {
        title: 'Gestão Tarefas',
        EndContent: () => <div style={{ width: 24 }} />
      },
      'cronograma-geral': {
        title: 'Cronograma',
        EndContent: () => <div style={{ width: 24 }} />
      }
    },
    ferramentas: {
      'meus-templates': {
        novo: {
          title: 'Novo template',
          EndContent: () => <div style={{ width: 24 }} />
        },
        editar: {
          title: 'Editar template',
          EndContent: () => <div style={{ width: 24 }} />
        }
      }
    }
  },

  cliente: {
    notificacoes: {
      title: () => <SelectCustomerRefurbish />,
      EndContent: () => (
        <Link to="/cliente/notificacoes/configuracoes">
          <FontAwesomeIcon color={colors.neutralSecondary40} size="lg" icon={faCog} />
        </Link>
      ),
      configuracoes: {
        title: 'Configurações',
        EndContent: () => (
          <Tooltip
            placement="bottomLeft"
            title={`Você sempre receberá notificações pela Plataforma Vobi,
                      mas também é possível receber pelo número de celular e e-mail do seu cadastro.`}
          >
            <FontAwesomeIcon color={colors.primary40} icon={faInfoCircle} />
          </Tooltip>
        )
      }
    },
    configuracoes: {
      title: 'Configurações',
      EndContent: () => (
        <Tooltip
          placement="bottomLeft"
          title={`Você sempre receberá notificações pela Plataforma Vobi,
                mas também é possível receber pelo número de celular e e-mail do seu cadastro.`}
        >
          <FontAwesomeIcon color={colors.primary40} icon={faInfoCircle} />
        </Tooltip>
      ),
      'dados-pessoais': {
        title: 'Cadastro dados pessoais',
        EndContent: () => <div style={{ width: 24 }} />
      }
    },
    cronograma: {
      title: 'Informações do cronograma',
      EndContent: () => <div style={{ width: 24 }} />
    },
    orcamento: {
      title: 'Informações do orçamento',
      EndContent: () => <div style={{ width: 24 }} />
    },
    propostas: {
      title: 'Informações da proposta',
      EndContent: () => <div style={{ width: 24 }} />
    },
    parcelas: {
      title: 'Informações das parcelas',
      EndContent: () => <div style={{ width: 24 }} />
    },
    compras: {
      title: 'Informações de compras',
      EndContent: () => <div style={{ width: 24 }} />
    },
    arquivos: {
      title: 'Informações do arquivo',
      EndContent: () => <div style={{ width: 24 }} />
    },
    solicitacoes: {
      title: 'Informações das solicitações de pagamento',
      EndContent: () => <div style={{ width: 24 }} />
    }
  }
};

export { mappingMobileSubHeader };
