import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import styled from 'styled-components';
import generateRangePicker from 'antd/lib/date-picker/generatePicker/generateRangePicker';
import 'antd/lib/date-picker/style/index';
import { TimePicker } from 'antd';
import * as dayjs from 'dayjs';
import toObject from 'dayjs/plugin/toObject';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, fonts } from '../../styles/style';
import './DatePicker.scss';

dayjs.extend(toObject);

const Picker = generateRangePicker(dayjsGenerateConfig);

const StyledPicker = styled(Picker)`
  z-index: 1000;
  &.hideTxtPicker {
    overflow: hidden;
    display: flex;
    width: 0;
    height: 0;
    border: none;
  }
  &.ant-picker {
    font-family: ${fonts.family};
    border-radius: ${props => (props.size === 'small' ? '4px' : '8px')};
    color: ${colors.neutral30};

    input {
      color: ${colors.neutral30};
    }

    &:hover {
      border-color: ${colors.neutral30};
    }
  }

  &.ant-picker-small {
    input {
      font-size: ${fonts.sizeSm};
    }
  }

  &.ant-picker-focused {
    border-color: ${colors.neutral30};
    box-shadow: none;
  }
`;

const StyledTimePicker = styled(TimePicker)`
  .ant-picker-panel-container
    .ant-picker-panel
    .ant-picker-time-panel
    .ant-picker-content
    .ant-picker-time-panel-column {
    &::-webkit-scrollbar {
      display: none;
    }

    &:hover {
      &::-webkit-scrollbar {
        display: block;
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        -webkit-border-radius: 8px;
        border-radius: 8px;
      }

      &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 8px;
        border-radius: 8px;
        background: ${colors.primary40};
      }
    }
  }
`;

const StyledPanel = styled.div`
  font-family: ${fonts.family};
  border-radius: 4px;
  font-size: 12px;
  color: ${colors.neutral30};

  .ant-picker-header {
    border-bottom: none;
    span {
      color: ${colors.primary40};
    }
  }

  .ant-picker-content {
    thead {
      border-bottom: 1px solid ${colors.neutral90};
      tr > th {
        color: ${colors.neutral60};
      }
    }

    .ant-picker-cell {
      color: ${colors.neutral60};

      &.ant-picker-cell-in-view {
        color: ${colors.neutral30};
      }

      &.ant-picker-cell-today,
      .ant-picker-cell-today {
        border-radius: 12px;
      }
    }
  }
`;

const StyledIcon = styled(FontAwesomeIcon)`
  width: 14px;
  height: 14px;
  opacity: 0.5;
  color: ${colors.neutralSecondary40};
  &:hover {
    opacity: 1;
  }
`;

const StyledBlockCalendar = styled.div`
  z-index: 1001;
  width: 560px;
  height: 268px;
  position: absolute;
  background-color: rgba(240, 243, 250, 0.9);
  top: 0;
  margin-top: 6px;
  margin-left: -12px;
  border-radius: 4px;
  padding: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export { StyledPicker, StyledTimePicker, StyledPanel, StyledIcon, StyledBlockCalendar };
