import React, { useEffect, useState } from 'react';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { MaskedInput } from '@homehero/hero-style';
import { useContextHook } from '../../contexts/GeneralContext';
import { Container, PlusButton, RowValue } from './PaymentExtraValuesForm.styled';
import formatCurrency from '../../lib/helpers/formatCurrency';
import Button from '../Button/Button';
import formatNumber from '../../lib/helpers/formatNumber';
import { calcPercentageByValue, calcValuesByPercentage } from '../../lib/helpers/helper';
import { StyledStrashIcon } from './PaymentFormItem.styled';
import { spaces } from '../../styles/style';

const PaymentExtraValuesForm = () => {
  const { values, setField, isView, extraValueErrors, setExtraValueErrors } = useContextHook();
  const { paymentItems, discount, shipping, taxes, paidValue, openAmount, total } = values || {};
  const [subtotal, setSubtotal] = useState(0);
  const [addDiscount, setAddDiscount] = useState(formatNumber(discount) > 0);
  const [discountPercentage, setDiscountPercentage] = useState();
  const [addShipping, setAddShipping] = useState(formatNumber(shipping) > 0);
  const [shippingPercentage, setShippingPercentage] = useState();
  const [addTaxes, setAddTaxes] = useState(formatNumber(taxes) > 0);
  const [taxesPercentage, setTaxesPercentage] = useState();

  const resetField = (property, setPercentageField, setState) => {
    setField(property)(null);
    setPercentageField('0,00');
    setState(false);
  };

  useEffect(() => {
    let _subtotal = 0;
    if (paymentItems?.length) {
      paymentItems.forEach(i => {
        _subtotal += Number(i.quantity) * Number(i.price);
      });

      setSubtotal(Number(_subtotal?.toFixed(2) || 0));
    }
  }, [values]);

  useEffect(() => {
    const _total =
      formatNumber(subtotal || 0) +
      formatNumber(shipping || 0) +
      formatNumber(taxes || 0) -
      formatNumber(discount || 0);

    setField('total')(_total);
  }, [subtotal, discount, shipping, taxes]);

  useEffect(() => {
    if (formatNumber(discount) > 0)
      calcPercentageByValue({ setPercentage: setDiscountPercentage, field: discount, subtotal });
    else if (formatNumber(discountPercentage) > 0)
      calcValuesByPercentage({ property: 'discount', percentageField: discountPercentage, setField, subtotal });

    if (formatNumber(shipping) > 0)
      calcPercentageByValue({ setPercentage: setShippingPercentage, field: shipping, subtotal });
    else if (formatNumber(shippingPercentage) > 0)
      calcValuesByPercentage({ property: 'shipping', percentageField: shippingPercentage, setField, subtotal });

    if (formatNumber(taxes) > 0) calcPercentageByValue({ setPercentage: setTaxesPercentage, field: taxes, subtotal });
    else if (formatNumber(shippingPercentage) > 0)
      calcValuesByPercentage({ property: 'taxes', percentageField: taxesPercentage, setField, subtotal });
  }, [subtotal]);

  useEffect(() => {
    setExtraValueErrors({});
    if (total < 0) {
      setExtraValueErrors(prev => ({ ...prev, total: 'O valor total não pode ser negativo' }));
    }
  }, [total]);

  const renderInput = ({ percentage, setPercentage, setAddState, property }) => (
    <>
      <RowValue>
        <Button text onClick={() => resetField(property, setPercentage, setAddState)}>
          <StyledStrashIcon icon={faTrashAlt} text="Remover" />
        </Button>
        <MaskedInput
          type="currency"
          value={percentage}
          placeholder="0,00%"
          onChange={e => {
            setPercentage(e?.target?.value);
            calcValuesByPercentage({ property, percentageField: e?.target?.value, setField, subtotal });
          }}
          maskOptions={{
            suffix: '%',
            prefix: '',
            includeThousandsSeparator: false,
            allowDecimal: true
          }}
          style={{ height: spaces.space3, width: spaces.space10, paddingRight: 4, paddingLeft: 4 }}
        />

        <MaskedInput
          type="currency"
          value={values[property]}
          onChange={e => {
            setField(property)(e.target.value);
            calcPercentageByValue({ setPercentage, field: formatNumber(e.target.value), subtotal });
          }}
          defaultValue={formatCurrency(values[property], {
            currencySymbol: 'R$ '
          })}
          maskOptions={{
            prefix: 'R$',
            thousandsSeparatorSymbol: '.',
            allowDecimal: true
          }}
          size="small"
          style={{ flex: 4, marginLeft: 8, height: spaces.space3, paddingRight: 4, paddingLeft: 4, fontSize: '12px' }}
        />
      </RowValue>
      {extraValueErrors?.[property] && <small>{extraValueErrors?.[property]}</small>}
    </>
  );

  return (
    <Container>
      {isView ? (
        <div>
          <RowValue className="subTotal">
            <div>
              <p>Subtotal</p>
            </div>
            <div>
              <p>{formatCurrency(subtotal, { currencySymbol: 'R$ ' })}</p>
            </div>
          </RowValue>
          {discount && formatNumber(discount) > 0 ? (
            <RowValue>
              <div>
                <p>Desconto</p>
              </div>
              <div>
                <p>- {formatCurrency(formatNumber(discount), { currencySymbol: 'R$ ' })}</p>
              </div>
            </RowValue>
          ) : null}
          {taxes && formatNumber(taxes) ? (
            <RowValue>
              <div>
                <p>Impostos</p>
              </div>
              <div>
                <p>{formatCurrency(formatNumber(taxes), { currencySymbol: 'R$ ' })}</p>
              </div>
            </RowValue>
          ) : null}
          {shipping && formatNumber(shipping) ? (
            <RowValue>
              <div>
                <p>Frete</p>
              </div>
              <div>
                <p>{formatCurrency(formatNumber(shipping), { currencySymbol: 'R$ ' })}</p>
              </div>
            </RowValue>
          ) : null}
          <RowValue className="totalValue">
            <div>
              <p>Total</p>
            </div>
            <div>
              <p>{formatCurrency(formatNumber(total), { currencySymbol: 'R$ ' })}</p>
            </div>
          </RowValue>
          <RowValue className="paidValue">
            <div>
              <p>Total pago</p>
            </div>
            <div>
              <p className="value">{formatCurrency(formatNumber(paidValue || 0), { currencySymbol: 'R$ ' })}</p>
            </div>
          </RowValue>
          <RowValue className="restValue">
            <div>
              <p>Em aberto</p>
            </div>
            <div>
              <p className="value">
                {formatCurrency(formatNumber(formatNumber(openAmount || 0)), {
                  currencySymbol: 'R$ '
                })}
              </p>
            </div>
          </RowValue>
        </div>
      ) : (
        <div>
          <RowValue className="subTotal">
            <div>
              <p>Subtotal</p>
            </div>
            <div>
              <p>{formatCurrency(subtotal, { currencySymbol: 'R$ ' })}</p>
            </div>
          </RowValue>
          <RowValue>
            <div>
              <p>Desconto</p>
            </div>
            {addDiscount ? (
              renderInput({
                percentage: discountPercentage,
                setPercentage: setDiscountPercentage,
                setAddState: setAddDiscount,
                property: 'discount'
              })
            ) : (
              <div>
                <PlusButton text type="primary" onClick={() => setAddDiscount(true)} noPadding>
                  + Adicionar
                </PlusButton>
              </div>
            )}
          </RowValue>
          <RowValue>
            <div>
              <p>Impostos</p>
            </div>
            {addTaxes ? (
              renderInput({
                percentage: taxesPercentage,
                setPercentage: setTaxesPercentage,
                setAddState: setAddTaxes,
                property: 'taxes'
              })
            ) : (
              <div>
                <PlusButton text type="primary" onClick={() => setAddTaxes(true)} noPadding>
                  + Adicionar
                </PlusButton>
              </div>
            )}
          </RowValue>
          <RowValue>
            <div>
              <p>Frete</p>
            </div>
            {addShipping ? (
              renderInput({
                percentage: shippingPercentage,
                setPercentage: setShippingPercentage,
                setAddState: setAddShipping,
                property: 'shipping'
              })
            ) : (
              <div>
                <PlusButton text type="primary" onClick={() => setAddShipping(true)} noPadding>
                  + Adicionar
                </PlusButton>
              </div>
            )}
          </RowValue>
          <RowValue className="totalValue">
            <div>
              <p>Total</p>
            </div>
            <div>
              <p>{formatCurrency(total, { currencySymbol: 'R$ ' })}</p>
            </div>
            {extraValueErrors?.total && <small>{extraValueErrors?.total}</small>}
          </RowValue>
        </div>
      )}
    </Container>
  );
};

PaymentExtraValuesForm.propTypes = {};

export default PaymentExtraValuesForm;
