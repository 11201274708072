import React from 'react';
import PropTypes from 'prop-types';

const PowerpointFile = ({ width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 64 77">
      <title>pptx</title>
      <desc>Created with Sketch.</desc>
      <g id="Files" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Artboard" transform="translate(-227.000000, -15.000000)">
          <g id="pptx" transform="translate(228.000000, 16.000000)">
            <path
              d="M4.89473684,3.26086957 C3.99573684,3.26086957 3.26315789,3.99130435 3.26315789,4.89130435 L3.26315789,34.2391304 L-7.10542736e-15,34.2391304 L-7.10542736e-15,4.89130435 C-7.10542736e-15,2.19456522 2.19610526,0 4.89473684,0 L40.7894737,0 C41.2218421,0 41.6378947,0.171195652 41.943,0.477717391 L61.5219474,20.0429348 C61.8286842,20.3478261 62,20.7619565 62,21.1956522 L62,70.1086957 C62,72.8054348 59.8038947,75 57.1052632,75 L4.89473684,75 C2.19610526,75 -7.10542736e-15,72.8054348 -7.10542736e-15,70.1086957 L-7.10542736e-15,55.8043478 L3.26315789,55.8043478 L3.26315789,70.1086957 C3.26315789,71.0086957 3.99573684,71.7391304 4.89473684,71.7391304 L57.1052632,71.7391304 C58.0042632,71.7391304 58.7368421,71.0086957 58.7368421,70.1086957 L58.736,22.826 L40.7894737,22.826087 C39.8888421,22.826087 39.1578947,22.0972826 39.1578947,21.1956522 L39.1578947,4.16254918 C39.1578947,3.66456528 39.5580219,3.26086957 40.049567,3.26086957 L4.89473684,3.26086957 Z M56.429,19.565 L42.421,5.566 L42.4210526,19.5652174 L56.429,19.565 Z"
              id="Combined-Shape"
              stroke="#FFFFFF"
              strokeWidth="1.8"
              fill="#F7B500"
              opacity="0.900000036"
            />
            <path
              d="M50.5555556,34 C51.3528889,34 52,34.6556 52,35.4666667 L52,54.5333333 C52,55.3444 51.3528889,56 50.5555556,56 L1.44444444,56 C0.647111111,56 0,55.3444 0,54.5333333 L0,35.4666667 C0,34.6556 0.647111111,34 1.44444444,34 L50.5555556,34 Z"
              id="base"
              stroke="#FFFFFF"
              strokeWidth="1.8"
              fillOpacity="0.1"
              fill="#E1A603"
            />
            <path
              d="M14.336,48 L14.336,47.176 L13.512,47.176 L13.512,48 L14.336,48 Z M16.2807273,48 L16.2807273,45.496 L17.8967273,45.496 C18.4780606,45.496 18.9340606,45.3533333 19.2647273,45.068 C19.5953939,44.7826667 19.7607273,44.392 19.7607273,43.896 C19.7607273,43.4 19.5967273,43.0106667 19.2687273,42.728 C18.9407273,42.4453333 18.4833939,42.304 17.8967273,42.304 L17.8967273,42.304 L15.6247273,42.304 L15.6247273,48 L16.2807273,48 Z M17.8407273,44.952 L16.2807273,44.952 L16.2807273,42.848 L17.8407273,42.848 C18.2620606,42.848 18.5793939,42.9373333 18.7927273,43.116 C19.0060606,43.2946667 19.1127273,43.5546667 19.1127273,43.896 C19.1127273,44.2373333 19.0060606,44.4986667 18.7927273,44.68 C18.5793939,44.8613333 18.2620606,44.952 17.8407273,44.952 L17.8407273,44.952 Z M21.5614546,48 L21.5614546,45.496 L23.1774546,45.496 C23.7587879,45.496 24.2147879,45.3533333 24.5454546,45.068 C24.8761212,44.7826667 25.0414546,44.392 25.0414546,43.896 C25.0414546,43.4 24.8774546,43.0106667 24.5494546,42.728 C24.2214546,42.4453333 23.7641212,42.304 23.1774546,42.304 L23.1774546,42.304 L20.9054546,42.304 L20.9054546,48 L21.5614546,48 Z M23.1214546,44.952 L21.5614546,44.952 L21.5614546,42.848 L23.1214546,42.848 C23.5427879,42.848 23.8601212,42.9373333 24.0734546,43.116 C24.2867879,43.2946667 24.3934546,43.5546667 24.3934546,43.896 C24.3934546,44.2373333 24.2867879,44.4986667 24.0734546,44.68 C23.8601212,44.8613333 23.5427879,44.952 23.1214546,44.952 L23.1214546,44.952 Z M28.0741818,48 L28.0741818,42.864 L30.0581818,42.864 L30.0581818,42.304 L25.4261818,42.304 L25.4261818,42.864 L27.4101818,42.864 L27.4101818,48 L28.0741818,48 Z M31.0749091,48 L32.8349091,45.584 L34.6029091,48 L35.3789091,48 L33.2109091,45.088 L35.2829091,42.304 L34.5069091,42.304 L32.8429091,44.592 L31.1789091,42.304 L30.3949091,42.304 L32.4589091,45.08 L30.2909091,48 L31.0749091,48 Z"
              id=".PPTX"
              fill="#F7B500"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

PowerpointFile.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
};

PowerpointFile.defaultProps = {
  width: 65,
  height: 77
};

export default PowerpointFile;
