import { parse } from 'cookie';

const isDev = process.env.REACT_APP_CUSTOM_NODE_ENV === `development`;
let vobiCookies;
const connection = { current: null };
const onMessageCallbacks = [];

const resetOnMessageCallbacks = () => {
  onMessageCallbacks.splice(0);
};

const getVobiCookies = () => {
  const { cookie } = document || {};
  return encodeURIComponent(parse(cookie)[`vobi.cookies`]);
};

const getDefaultURL = cookies => {
  const DEV_WSS = isDev ? `?Cookie=vobi.cookies=${cookies}` : '';
  const url = `${process.env.REACT_APP_WEBSOCKET_URL}${DEV_WSS}`;

  return url;
};

const onError = error => {
  // eslint-disable-next-line no-console
  console.log(`WebSocket error: ${error}`);
};
const heartbeat = () => {
  // eslint-disable-next-line no-use-before-define
  const { current: conn } = connection;
  if (!conn || conn.readyState !== conn.OPEN) return;
  conn.send('');
  setTimeout(heartbeat, 540000);
};

const onOpen = () => {
  heartbeat();
};

const onClose = () => {
  setTimeout(() => {
    // eslint-disable-next-line no-use-before-define
    const conn = connection.current;
    if (conn && conn.readyState === conn.OPEN) return;
    // eslint-disable-next-line no-use-before-define
    connect();
  }, 10000);
};

const onMessage = (messageCallback, id) => {
  if (!onMessageCallbacks.find(m => m.id === id)) {
    onMessageCallbacks.push({ id, messageCallback });
  }

  connection.current.onmessage = message => {
    onMessageCallbacks.forEach(({ messageCallback: callback }) => {
      callback(message);
    });
  };
};

const connect = () => {
  const currCookies = getVobiCookies();
  if (!connection.current || currCookies !== vobiCookies) {
    vobiCookies = currCookies;
    resetOnMessageCallbacks();

    connection.current = new WebSocket(getDefaultURL(vobiCookies), []);
    connection.current.onclose = onClose;
    connection.current.onerror = onError;
    connection.current.onopen = onOpen;
  }
};

export { connect, onMessage };
