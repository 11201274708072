import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import Button from './Button';
import { toKebabCase } from '../../lib/helpers/helper';
import AnalyticsServiceFactory from '../../lib/analytics/analytics';

const analytics = AnalyticsServiceFactory();

const HowDoesItWorksButton = ({
  id,
  children,
  icon,
  label = 'Como funciona',
  withoutIcon = false,
  onlyIcon,
  ...props
}) => {
  return (
    <Button id={id} type="primary" text {...props} onClick={() => analytics.track({ event: toKebabCase(id) })}>
      {!withoutIcon && <FontAwesomeIcon icon={icon || faInfoCircle} />}
      {!onlyIcon && (
        <span style={{ marginLeft: 4 }} className="hideMobile">
          {label}
        </span>
      )}
      {children}
    </Button>
  );
};

HowDoesItWorksButton.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.instanceOf(Object),
  icon: PropTypes.instanceOf(Object),
  withoutIcon: PropTypes.bool,
  onlyIcon: PropTypes.bool
};

export default HowDoesItWorksButton;
