import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Modal from './Modal';
import useCRUD from '../../_Hooks/useCRUD';
import LinkedTaskSelection from '../Select/LinkedTaskSelection';
import HowDoesItWorksButton from '../Button/HowDoesItWorksButton';

const RenderLinkedTypeStyle = styled.div`
  display: contents;
  span {
    margin-left: 16px;
    padding: 2px 5px;
    border-radius: 4px;
    background: ${props => props.color};
  }
`;

const RenderLinkedType = ({ options }) => {
  return (
    <RenderLinkedTypeStyle color={options.step.refurbishSteps[0].color}>
      {options.name}
      <span>{options.step.name}</span>
    </RenderLinkedTypeStyle>
  );
};

RenderLinkedType.propTypes = {
  options: PropTypes.instanceOf(Object)
};

const LinkTaskModal = ({ row, onClose = f => f, reload = () => {}, onRefresh, ...props }) => {
  const { id } = row;
  const [linkedTask, setLinkedTask] = useState();
  const [linkedTaskName, setLinkedTaskName] = useState();
  const { loading, handleUpdate, handleDelete } = useCRUD({
    model: 'task',
    immediatelyLoadData: false
  });

  const StyledTitleModal = styled.div`
    margin-top: -4px;
    display: flex;
    justify-content: space-between;
    padding-right: 16px;
    align-items: center;
  `;

  const handleDeleteLink = () => {
    handleDelete({
      values: { idChild: id, idParent: row?.idParent },
      deletePathOptions: '/connection'
    }).then(() => {
      onClose(true);
      reload();
      onRefresh && onRefresh(row, true);
    });
  };

  const handleLinkedTask = () => {
    if (!linkedTask) {
      return handleDeleteLink();
    }
    if (linkedTask) {
      return handleUpdate({
        values: { idChild: id, idParent: linkedTask },
        updatePathOptions: '/connection',
        refresh: false
      }).then(() => {
        onClose(true);
        reload();
        const updatedRow = {
          ...row,
          idParent: linkedTask,
          parent: {
            id: linkedTask,
            name: linkedTaskName
          }
        };
        onRefresh && onRefresh(updatedRow, true);
      });
    }
    onClose(true);
    return null;
  };

  return (
    <Modal
      onSubmit={handleLinkedTask}
      onClose={() => onClose(true)}
      open
      sectionStyle={{ padding: 0 }}
      title={
        <StyledTitleModal>
          <span>Vincular tarefa com sua antecessora</span>
          <HowDoesItWorksButton id="howPredecessorsWorks" />
        </StyledTitleModal>
      }
      width={530}
      loading={loading}
    >
      <span style={{ fontSize: 14 }}>Essa tarefa será dependente das datas da tarefa antecessora escolhida</span>
      <LinkedTaskSelection
        style={{ marginTop: '16px' }}
        id={id}
        setLinkedTask={setLinkedTask}
        linkedTask={linkedTask}
        setLinkedTaskName={setLinkedTaskName}
        {...props}
      />
    </Modal>
  );
};

LinkTaskModal.propTypes = {
  onClose: PropTypes.func,
  row: PropTypes.instanceOf(Object),
  reload: PropTypes.func,
  onRefresh: PropTypes.func
};

export default LinkTaskModal;
