import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider } from 'antd';
import styled from 'styled-components';
import { colors, spaces, fonts, breakpoints } from '../../styles/style';

const Container = styled.div`
  > div {
    margin-top: ${spaces.space2};
  }

  .paymentDate {
    display: flex;

    label {
      margin-right: ${spaces.space2};
    }
  }
`;

const ViewContainer = styled.div`
  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: ${spaces.space2};
  }

  @media (max-width: ${breakpoints.tablet}) {
    .title {
      display: flex;
      flex-direction: column;
      margin-bottom: ${spaces.space2};
    }
  }

  .displayData {
    display: flex;
    justify-content: space-between;
    background-color: ${colors.neutral95};
    border-radius: ${spaces.space1};
    padding: ${spaces.space4} ${spaces.space3};

    @media (max-width: ${breakpoints.tablet}) {
      flex-direction: column;
      .customerData {
        margin-left: 0px;
      }
      .companyData {
        flex-direction: column;
        border-right: 0px;
        margin-bottom: ${spaces.space2};
        border-bottom: 1px solid ${colors.neutral90};
        padding-bottom: ${spaces.space2};

        .logoContainer {
          margin-bottom: ${spaces.space1};
        }
      }
    }

    @media (min-width: ${breakpoints.desktop}) {
      .companyData {
        display: flex;
        border-right: 1px solid ${colors.neutral90};
        > div {
          margin-left: ${spaces.space2};
        }
        flex: 1;
      }

      .customerData {
        flex: 1;
        margin-left: ${spaces.space8};
      }
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  .statusContainer {
    font-size: ${fonts.sizeMd};
    margin-top: ${spaces.space1};
    margin-bottom: ${spaces.space1};
    @media (min-width: ${breakpoints.desktop}) {
      display: none;
    }
  }
`;
const SubTitle = styled.p`
  font-size: ${fonts.sizeLg};
  font-weight: 500;
  color: ${colors.neutral40};
`;

const Text = styled.p`
  font-size: ${fonts.sizeSm};
  color: ${colors.neutral50};
`;

const ErrorStyled = styled.small`
  color: ${colors.error50};
`;

const CustomerInformation = styled.div`
  display: flex;
  gap: ${spaces.space1};
`;

const SelectDivider = styled(Divider)`
  margin: ${spaces.space1} 0;
`;

const IconCog = styled(FontAwesomeIcon)`
  color: ${colors.primary40};
  padding-right: ${spaces.space1};
`;
export { Container, ViewContainer, SubTitle, Text, ErrorStyled, CustomerInformation, SelectDivider, IconCog };
