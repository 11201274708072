import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import CardList from './MobileCardList';
import Table from '../Table/Table';
import useViewport from '../../_Hooks/useViewport';

const TableOrList = ({ list = [], idRefurbish, grouped, ItemModal, refresh = f => f, moveRowProps = {}, ...props }) => {
  const { isMobile } = useViewport(window.innerWidth);
  const [currentItem, setCurrentItem] = useState(false);
  const [groupOpen, setGroupOpen] = useState(null);
  const RenderList = isMobile() ? CardList : Table;

  const moveRow = useCallback(
    (dragRow, dropRow) => {
      const childrenColumnName = props?.childrenColumnName || 'children';
      const { moveList = [], submitMove = f => f, idParentPropName = 'idParent', isDefaultOrder = true } = moveRowProps;

      if (!isDefaultOrder) return;

      // check if the item can move and if it is dropping at the same place
      if (dragRow?.record?.canMove === false || dragRow?.id === dropRow?.id) return;

      // check if it is dragging a folder inside another folder
      if ((dropRow.isExpanded || dropRow?.[idParentPropName]) && dragRow.record?.[childrenColumnName]?.length) return;

      const dragRowIdParent = dragRow.record?.[idParentPropName] || null;
      const dropRowIdParent = dropRow.isExpanded ? dropRow.id : dropRow[idParentPropName] || null;
      const parentItemIndex = !dropRowIdParent ? -1 : moveList.findIndex(l => l.id === dropRowIdParent);
      const currentWorkingList = parentItemIndex < 0 ? moveList : moveList[parentItemIndex][childrenColumnName];

      let beforeBoundary = 0;
      let afterBoundary = 0;

      // inserting into parent
      if (dropRow.isExpanded || (!dragRowIdParent && dropRowIdParent)) {
        beforeBoundary = currentWorkingList?.[currentWorkingList.length - 1]?.order || 0;
        afterBoundary = Number(beforeBoundary) + 1;
      }
      // removing from parent
      else if (dragRowIdParent && !dropRowIdParent) {
        const fromIndex = moveList.findIndex(p => p.id === dragRowIdParent);
        const toIndex = moveList.findIndex(p => p.id === dropRow.id);

        beforeBoundary = fromIndex > toIndex ? moveList[fromIndex - 1]?.order || 0 : moveList[fromIndex]?.order;
        afterBoundary =
          fromIndex > toIndex ? moveList[fromIndex].order : moveList[fromIndex + 1]?.order || Number(dropRow.order) + 1;
      }
      // moving from one parent to another
      else if (dragRowIdParent !== dropRowIdParent) {
        beforeBoundary = currentWorkingList?.[currentWorkingList.length - 1]?.order || 0;
        afterBoundary = Number(beforeBoundary) + 1;
      }
      // moving downwards
      else if (dragRow.index < dropRow.index) {
        beforeBoundary = dropRow.order;
        afterBoundary = currentWorkingList?.[dropRow.index + 1]?.order || Number(dropRow.order) + 1;
      }
      // moving upwards
      else {
        beforeBoundary = currentWorkingList?.[dropRow.index - 1]?.order || 0;
        afterBoundary = dropRow.order;
      }

      const order = (Number(beforeBoundary || 0) + Number(afterBoundary)) / 2;

      submitMove({ values: { ...dragRow.record, order, [idParentPropName]: dropRowIdParent }, dropRow });
    },
    [list, moveRowProps]
  );

  return (
    <>
      {grouped?.value && list.length ? (
        list.map(group => (
          <RenderList
            key={`item${group.id}`}
            list={group.item}
            group={group}
            allowEditGroup={group?.id !== 1}
            groupOpen={groupOpen}
            setGroupOpen={setGroupOpen}
            disabledByParent={!!group?.name}
            setCurrentItem={setCurrentItem}
            moveRow={Object.keys(moveRowProps).length ? moveRow : null}
            grouped={grouped}
            {...props}
          />
        ))
      ) : (
        <RenderList
          key={`item${idRefurbish}`}
          list={list}
          setCurrentItem={setCurrentItem}
          moveRow={Object.keys(moveRowProps).length ? moveRow : undefined}
          refresh={refresh}
          {...props}
        />
      )}
      {currentItem && ItemModal ? (
        <ItemModal
          id={currentItem}
          onClose={() => {
            setCurrentItem(null);
            refresh(currentItem);
          }}
          idRefurbish={idRefurbish}
          canChangeRefurbish={!idRefurbish}
        />
      ) : null}
    </>
  );
};

TableOrList.propTypes = {
  list: PropTypes.oneOfType([PropTypes.array]).isRequired,
  idRefurbish: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  grouped: PropTypes.instanceOf(Object),
  ItemModal: PropTypes.instanceOf(Object),
  moveRowProps: PropTypes.instanceOf(Object),
  refresh: PropTypes.func,
  childrenColumnName: PropTypes.string
};

export default TableOrList;
