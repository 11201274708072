import { MaskedInput } from '@homehero/hero-style';
import styled from 'styled-components';
import { colors, spaces, fonts, breakpoints } from '../../styles/style';
import Button from '../Button/Button';

const Container = styled.div`
  border-radius: 4px;
  border: 1px solid ${colors.neutral90};
  width: 100%;
  padding: ${spaces.space1} ${spaces.space2} 0 ${spaces.space2};
  font-size: ${fonts.sizeSm};

  small {
    color: ${colors.error40};
    flex-basis: 100%;
    text-align: end;
  }
  @media (max-width: ${breakpoints.tablet}) {
    width: 100%;
  }
`;

const RowValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: ${spaces.space4};
  flex: 6;

  > div {
    p {
      text-align: right;
      color: ${colors.neutral60};
      font-weight: 400;
    }
  }

  div:first-child {
    flex: 2;

    p {
      text-align: left;
    }
  }

  &.totalValue {
    background-color: ${colors.neutral90};
    margin: ${spaces.space1} -${spaces.space2} 0 -${spaces.space2};
    padding: ${spaces.space2};
    font-size: ${fonts.sizeMd};
    font-weight: 500;
    flex-wrap: wrap;
    p {
      color: ${colors.neutral40};
      font-weight: 500;
    }
  }

  &.paidValue,
  &.subTotal {
    p {
      color: ${colors.neutral50};
      font-weight: 400;
    }
  }

  &.paidValue {
    background-color: ${colors.neutral95};
    margin: 0 -${spaces.space2};
    padding: 0 ${spaces.space2};
    padding-top: 8px;
    p {
      color: ${colors.neutral60};
      font-weight: 400;
    }

    .value {
      font-size: ${fonts.sizeSm};
      color: ${colors.success40};
    }
  }

  &.restValue {
    background-color: ${colors.neutral95};
    margin: 0 -${spaces.space2};
    padding: 0 ${spaces.space2};
    font-weight: 500;
    p {
      color: ${colors.neutral40};
      font-weight: 500;
    }
    font-weight: 500;
    .value {
      color: ${colors.warningSecondary70};
    }
  }
`;

const PlusButton = styled(Button)`
  margin-left: auto;
`;

const StyledMaskedInput = styled(MaskedInput)`
  flex: 4;
  margin-left: 8;
  height: 24px;
`;

export { Container, RowValue, PlusButton, StyledMaskedInput };
