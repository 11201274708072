import React from 'react';
import * as PropTypes from 'prop-types';
import formatCurrency from '../../helpers/formatCurrency';
import { isImgUrl } from '../../helpers/helper';

import UpdateDataStatus from '../../../components/Select/UpdateDataStatus';
import ReleasePaymentButton from '../../../components/Button/ReleasePaymentButton';
import Tag from '../../../components/Tag/Tag';
import emptyImg from '../../../components/Images/empty-state-image.svg';
// eslint-disable-next-line import/no-cycle
import TableActionDropDown from '../../../components/Dropdown/TableActionDropDown';

const Image = ({ image, name }) =>
  !image || isImgUrl(image) ? (
    <img style={{ maxWidth: '80%', height: 'auto', maxHeight: '50px' }} src={image || emptyImg} alt={name} />
  ) : (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '40px' }}>
      <i className={`fa fa-${image}`} />
    </div>
  );

Image.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string
};

const paymentColumns = ({ orderIsPaid, isProvider, paymentStatus, instalmentStatusEnum, isBO, hideAction }) => [
  { title: 'Empresa', dataIndex: ['refurbishItem', 'supplier', 'name'], key: 'supplier' },
  { title: 'Item', dataIndex: ['refurbishItem', 'name'], key: 'refurbishItem' },
  { title: 'Descrição da parcela', dataIndex: 'description', key: 'description' },
  {
    title: 'Total',
    dataIndex: 'price',
    key: 'price',
    render: val => formatCurrency(val, { currencySymbol: 'R$' })
  },
  ...(!hideAction
    ? [
        {
          title: '',
          key: 'action',
          width: 200,
          render: (val, row) => {
            const { id, status, instalmentOrders, refurbishItem } = row || {};
            const { idRefurbish } = refurbishItem || {};

            const _status = paymentStatus.find(l => Number(l.value) === Number(status));

            return (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {isBO ? (
                  <UpdateDataStatus
                    id={row.id}
                    value={row.status}
                    dataType="paymentStatus"
                    model="refurbish-item-instalment"
                    field="status"
                    otherValues={{ idOrder: row.instalmentOrders.idOrder }}
                  />
                ) : (
                  <>
                    {orderIsPaid && isProvider && instalmentStatusEnum.allowTransfer.includes(Number(row.status)) ? (
                      <ReleasePaymentButton
                        id={id}
                        isPaid={instalmentStatusEnum.released === Number(status)}
                        idOrder={instalmentOrders?.idOrder}
                        idRefurbish={idRefurbish}
                      />
                    ) : (
                      <Tag title={_status.label} type="simple" {..._status} />
                    )}
                  </>
                )}
                <TableActionDropDown
                  isInstalment
                  key={`action${row.id}`}
                  item={row}
                  mappingObj="instalmentItem"
                  idRefurbish={idRefurbish}
                  idParent={row?.idParent}
                  model="refurbish-item-instalment"
                  options={{
                    include: [
                      {
                        model: 'RefurbishItems',
                        include: ['supplier', 'refurbish']
                      },
                      'files'
                    ],
                    where: { idRefurbish }
                  }}
                />
              </div>
            );
          }
        }
      ]
    : [])
];

const productColumns = ({ orderIsPaid, purchaseStatus, isBO, isProvider, hideAction }) => [
  { title: 'Imagem', dataIndex: 'image', key: 'img', render: (val, row) => <Image name={row.name} image={val} /> },
  { title: 'Nome', width: 250, dataIndex: 'name', key: 'name' },
  { title: 'Empresa', dataIndex: ['supplier', 'name'], key: 'supplier' },
  {
    title: 'Valor',
    dataIndex: 'price',
    key: 'price',
    render: val => formatCurrency(val, { currencySymbol: 'R$' })
  },
  { title: 'Qtd.', dataIndex: 'quantity', key: 'quantity' },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
    render: val => formatCurrency(val, { currencySymbol: 'R$' })
  },
  ...(!hideAction
    ? [
        {
          title: '',
          key: 'action',
          width: 200,
          render: (val, row) => {
            if (orderIsPaid) return null;
            const { id, _purchaseStatus, idRefurbish } = row || {};

            const status = purchaseStatus.find(l => Number(l.value) === Number(_purchaseStatus));

            return (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {isBO ? (
                  <UpdateDataStatus
                    id={id}
                    field="purchaseStatus"
                    value={_purchaseStatus}
                    dataType="purchaseStatus"
                    model="refurbish-items"
                  />
                ) : (
                  <Tag title={status.label} type="simple" {...status} />
                )}
                <TableActionDropDown
                  key={`action${row.id}`}
                  item={row}
                  mappingObj="instalmentItem"
                  idRefurbish={idRefurbish}
                  isProvider={isProvider}
                  idParent={row?.idParent}
                />
              </div>
            );
          }
        }
      ]
    : [])
];

export { paymentColumns, productColumns };
