export default value => {
  // should check if it is string since replace is an String method.
  // eslint-disable-next-line no-restricted-globals
  return value && isNaN(value)
    ? Number(
        value
          .replace('%', '')
          .replace('R$', '')
          .replace(/\./g, '')
          .replace(/\s/g, '')
          .replace(',', '.')
      )
    : Number(value);
};
