import React from 'react';
import * as Yup from 'yup';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { name, text, idReference as idReferenceSchema } from '../schema';

import SelectDropdown from '../../../components/Dropdown/SelectDropdown';
import Button from '../../../components/Button/Button';
import { colors } from '../../../styles/style';

const schema = isModal =>
  Yup.object().shape({
    name: name.min(3, 'Nome deve ter pelo menos 3 caracteres'),
    ...(!isModal && { type: idReferenceSchema }),
    description: text
  });

const schemaApplyTemplate = () =>
  Yup.object().shape({
    idReference: idReferenceSchema.required('Campo obrigatório')
  });

const applyTemplateMapping = () => ({
  idReference: {
    name: 'Selecione',
    type: 'select',
    placeholder: 'Projetos',
    model: 'refurbish',
    modelOptions: {
      include: [
        {
          model: 'RefurbishStatus',
          as: 'status',
          where: { isActive: true, idStage: 2 }
        }
      ],
      order: [['name', 'asc']]
    },
    md: 24
  },
  fieldsHeader: {
    type: 'fixed',
    md: 24
  },
  hasSchedule: {
    type: 'checkbox',
    name: 'Cronograma',
    md: 6,
    defaultValue: true,
    style: { display: 'flex', justifyContent: 'space-evenly' }
  },
  hasBudget: {
    type: 'checkbox',
    name: 'Orçamento',
    md: 6,
    defaultValue: true,
    style: { display: 'flex', justifyContent: 'space-evenly' }
  },
  hasFolders: {
    type: 'checkbox',
    name: 'Pastas',
    md: 6,
    defaultValue: true,
    style: { display: 'flex', justifyContent: 'space-evenly' }
  },
  hasNotes: {
    type: 'checkbox',
    name: 'Anotações',
    md: 6,
    defaultValue: true,
    style: { display: 'flex', justifyContent: 'space-evenly' }
  }
});

const templateMapping = ({ isMobile, isModal, idTemplate, type, showTemplateType, isOperator, handleModal }) => ({
  image: {
    name: 'Imagem de capa',
    tooltipText: 'Tamanho máximo 50mb',
    type: 'image',
    loading: true,
    size: isModal ? '175px' : '230px',
    md: isModal ? 7 : 6
  },
  name: {
    name: 'Nome do template',
    type: 'text',
    placeholder: `Escreva aqui`,
    md: isModal && showTemplateType ? 12 : 15
  },
  ...(showTemplateType && {
    type: {
      name: 'Tipo',
      type: 'select',
      dataType: 'templateType',
      md: 5,
      readOnly: true
    }
  }),
  ...(!isModal && {
    type: {
      name: 'Tipo de template',
      type: 'select',
      dataType: 'templateType',
      placeholder: 'Tipo',
      readOnly: !!idTemplate,
      md: 3
    }
  }),
  fixed: {
    type: 'fixed',
    md: isModal ? 7 : 6
  },
  description: {
    name: 'Descrição do template',
    type: 'editorSimple',
    placeholder: `Escreva aqui`,
    style: { ...(!isMobile && { marginTop: isModal ? '-138px' : '-185px' }), heigth: '160px' },
    md: isModal ? 17 : 18,
    rows: !isModal ? 7 : null
  },
  ...(!isModal &&
    idTemplate &&
    type === 2 && {
      copyProjectLink: {
        type: 'custom',
        xs: 24,
        Component: () => (
          <>
            <Button
              type="text"
              onClick={() => handleModal()}
              style={{ color: `${colors.primary40}` }}
              icon={<FontAwesomeIcon icon={faCopy} />}
            >
              Copiar um projeto existente
              <FontAwesomeIcon icon={faQuestionCircle} />
            </Button>
          </>
        )
      }
    }),
  ...(isModal &&
    type !== 1 && {
      fieldsHeader: {
        type: 'fixed',
        md: 24
      },
      hasSchedule: {
        type: 'checkbox',
        name: 'Cronograma',
        md: 6,
        defaultValue: true,
        style: { display: 'flex', justifyContent: 'space-evenly' }
      },
      hasBudget: {
        type: 'checkbox',
        name: 'Orçamento',
        md: 6,
        defaultValue: true,
        style: { display: 'flex', justifyContent: 'space-evenly' }
      },
      hasFolders: {
        type: 'checkbox',
        name: 'Pastas',
        md: 6,
        defaultValue: true,
        style: { display: 'flex', justifyContent: 'space-evenly' }
      },
      hasNotes: {
        type: 'checkbox',
        name: 'Anotações',
        md: 6,
        defaultValue: true,
        style: { display: 'flex', justifyContent: 'space-evenly' }
      }
    }),
  ...(isModal && {
    fieldsHeader: {
      type: 'fixed',
      md: 24
    },
    hasBudget: {
      type: 'checkbox',
      name: 'Orçamento',
      md: 6,
      defaultValue: true,
      style: { display: 'flex', justifyContent: 'space-evenly' }
    }
  }),
  ...(isOperator && {
    ...(isModal && {
      fixed2: {
        type: 'fixed',
        md: isModal ? 0 : 6
      },
      operatorFieldsHeader: {
        type: 'text',
        readOnly: true,
        defaultValue: 'Outras opções',
        md: isModal ? 24 : 18
      }
    }),
    ...(!isModal && {
      fixed3: {
        type: 'fixed',
        md: 6
      }
    }),
    community: {
      type: 'checkbox',
      name: 'Comunidade',
      md: isModal ? 6 : 2,
      defaultValue: false,
      style: { display: 'flex', justifyContent: 'space-evenly', marginTop: '30px' }
    },
    ...(!isModal && {
      company: {
        name: 'Criado por',
        type: 'custom',
        md: 6,
        // eslint-disable-next-line react/prop-types
        Component: ({ value, setFieldCustom }) => {
          return (
            <SelectDropdown
              model="company"
              onSelect={selected => {
                setFieldCustom('company')(selected);
                setFieldCustom('idCompany')(selected?.id);
              }}
              disabled={!!idTemplate}
              canCreate={false}
              data={value}
            />
          );
        }
      }
    })
  })
});

export { schema, schemaApplyTemplate, templateMapping, applyTemplateMapping };
