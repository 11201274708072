import React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { Typography } from 'antd';
import ImageInput from '../../../components/Input/ImageInput';
import formatCurrency from '../../helpers/formatCurrency';
import EditableInput from '../../../components/Input/EditableInput';
import formatNumber from '../../helpers/formatNumber';
import TableActionDropDown from '../../../components/Dropdown/TableActionDropDown';
import StatusSelector from '../../../components/StatusSelector/StatusSelector';

import { breakpoints, colors, fonts } from '../../../styles/style';
import ColumnWrapper from '../../../components/Table/ColumnWrapper';
import { isImgUrl } from '../../helpers/helper';

const hasParent = (row, refurbishItemType) => row.type !== refurbishItemType.parent && row.idParent;

StatusSelector.propTypes = {
  status: PropTypes.instanceOf(Object)
};

const { Text } = Typography;

const StyledMobileTextsDiv = styled.div`
  display: flex;
  justify-content: space-around;
  flex: 5;
  flex-direction: column;
  padding: 4px 12px;

  span {
    color: ${colors.neutral50};
  }

  .title {
    flex: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.6;
  }

  .content {
    display: flex;
    justify-content: space-between;
    flex: 1;
    flex-flow: wrap;

    @media (max-width: ${breakpoints.tablet}) {
      svg {
        position: absolute;
        right: ${props => (props.isChildren ? '16px' : '8px')};
        top: 0;
      }
    }
  }

  .supplier {
    color: ${colors.primary40};
  }
  .total {
    font-size: ${fonts.sizeSm};
  }
`;

const StyledMobileDiv = styled.div`
  @media (max-width: ${breakpoints.tablet}) {
    position: absolute;
    right: ${props => (props.isChildren ? '8px' : 0)};
    top: 0;
  }
`;

const columns = ({
  unitsArray = [],
  serializedUnitsArray,
  statusArray = [],
  serializedStatusArray,
  expandedRows,
  refurbishItemType = {},
  isCustomer,
  idRefurbish,
  handleChange = f => f,
  handleLoad = f => f,
  forceCellUpdate,
  list,
  lastAdd,
  isMobile,
  printColumns = [],
  isTemplate,
  isApply,
  isCustomerView,
  isUserAnonymous,
  supplierList,
  groupList,
  categoryList,
  refetchLists = f => f
}) => {
  const isPrint = !!printColumns?.length;
  const allColumns = [
    {
      title: '',
      dataIndex: 'image',
      key: 'image',
      colSpan: 2,
      className: 'image',
      renderMobile: true,
      render: (val, row) => {
        let _val = val;
        if (row.type === refurbishItemType.parent)
          _val = expandedRows?.current?.includes(row.id) || row.open ? 'folderOpen' : 'folder';

        return (
          <ImageInput
            cors={isPrint}
            key={`image${row.id}`}
            id={row.id}
            value={_val}
            images={row.images}
            onSubmit={({ image }) => {
              const spliceValue = row.images?.length ? row.images.slice(1) : [];
              handleChange(row, 'images')([image, ...spliceValue]);
            }}
            disabled={!!isCustomer || row.isDisabled || isPrint || isMobile || isApply || !!_val}
            onClick={row.onClick}
          />
        );
      }
    },
    {
      title: 'Item',
      dataIndex: 'name',
      key: 'name',
      sorter: !isPrint && !isTemplate,
      className: 'col-grow-3',
      render: (val, row) => {
        const { onClick = f => f } = row;
        if (isPrint && row.link) {
          const link = isImgUrl(row.link) ? row.link : `${row.link}`;
          if (isPrint)
            return (
              <a href={row.link && link} target="_blank">
                {row?.name || '-'}
              </a>
            );
          return (
            <a href={link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>
              {val}
            </a>
          );
        }

        return isPrint ? (
          val
        ) : (
          <EditableInput
            key={`name${row.id}`}
            value={val}
            row={row}
            onChange={handleChange(row, 'name')}
            disabled={!!isCustomer || row.isDisabled || isApply}
            initEdit={lastAdd?.current === row.id}
            withEditButton
            onClick={onClick}
            rowId={row.id}
          />
        );
      },
      shouldCellUpdate: (prevRecord, nextRecord) =>
        prevRecord?.name !== nextRecord?.name || prevRecord?.link !== nextRecord?.link
    },
    {
      title: 'Fornecedor',
      dataIndex: 'supplier',
      key: 'idSupplier',
      className: 'col-grow-1',
      align: 'left',
      sorter: !isPrint && !isTemplate,
      render: (val, row) =>
        isPrint ? (
          val?.name
        ) : (
          <ColumnWrapper
            value={val}
            model="supplier"
            loadList={supplierList}
            onSelect={handleChange(row, 'idSupplier')}
            disabled={isCustomer || isApply}
            refetch={refetchLists('supplier')}
          />
        ),
      shouldCellUpdate: (prevRecord, nextRecord) =>
        prevRecord.idSupplier !== nextRecord.idSupplier || forceCellUpdate === 'supplier'
    },
    {
      title: 'Grupo',
      dataIndex: 'refurbishGroup',
      key: 'idRefurbishGroup',
      className: 'col-grow-1 left',
      sorter: !isPrint && !isTemplate,
      render: (val, row) => {
        if (isPrint) return val?.name;

        return row.type === refurbishItemType.parent ? null : (
          <ColumnWrapper
            createData={!isTemplate ? { idRefurbish } : undefined}
            value={val}
            model="refurbishGroup"
            loadList={groupList}
            onSelect={handleChange(row, 'idRefurbishGroup')}
            disabled={isCustomer || isApply}
            refetch={refetchLists('group')}
          />
        );
      },
      shouldCellUpdate: (prevRecord, nextRecord) =>
        prevRecord.idRefurbishGroup !== nextRecord.idRefurbishGroup || forceCellUpdate === 'group'
    },
    {
      title: 'Categoria',
      dataIndex: 'costCenter',
      key: 'idCostCenter',
      className: 'col-grow-1',
      sorter: !isPrint && !isTemplate,
      render: (val, row) => {
        if (isPrint) return val?.name;

        return row.type === refurbishItemType.parent ? null : (
          <ColumnWrapper
            value={val}
            model="costCenter"
            loadList={categoryList}
            onSelect={handleChange(row, 'idCostCenter')}
            disabled={isCustomer || isApply}
            refetch={refetchLists('category')}
          />
        );
      },
      shouldCellUpdate: (prevRecord, nextRecord) =>
        prevRecord.idCostCenter !== nextRecord.idCostCenter || forceCellUpdate === 'category'
    },
    {
      title: 'Valor unit.',
      dataIndex: 'price',
      key: 'price',
      className: 'width-110',
      sorter: !isPrint && !isTemplate,
      render: (val, row) => {
        if (isPrint) return val ? formatCurrency(val, { currencySymbol: 'R$' }) : null;

        return row.type === refurbishItemType.parent ? null : (
          <EditableInput
            key={`price${row.id}`}
            noWrap
            value={val ? formatCurrency(val, { currencySymbol: 'R$' }) : null}
            row={row}
            onChange={value => handleChange(row, 'price')(formatNumber(value))}
            disabled={!!isCustomer || row.isDisabled || isApply}
            rowId={row.id}
          />
        );
      },
      shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.price !== nextRecord.price
    },
    {
      title: 'Qtd.',
      dataIndex: 'quantity',
      key: 'quantity',
      className: 'width-70',
      sorter: !isPrint && !isTemplate,
      render: (val, row) => {
        if (isPrint) return formatCurrency(val);

        return row.type === refurbishItemType.parent ? null : (
          <EditableInput
            key={`qtd${row.id}`}
            noWrap
            value={formatCurrency(val)}
            row={row}
            onChange={value => handleChange(row, 'quantity')(formatNumber(value))}
            disabled={!!isCustomer || row.isDisabled || isApply}
            rowId={row.id}
          />
        );
      },
      shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.quantity !== nextRecord.quantity
    },
    {
      title: 'Un.',
      dataIndex: 'idUnit',
      key: 'idUnit',
      className: 'width-70',
      sorter: !isPrint && !isTemplate,
      render: (val, row) => {
        const unit = serializedUnitsArray[val] || val;

        return isPrint ? (
          <div>{unit?.label || '-'}</div>
        ) : (
          <ColumnWrapper
            value={unit}
            _key="value"
            onSelect={handleChange(row, 'idUnit')}
            loadList={unitsArray}
            cleanValue={null}
            canCreate={false}
            disabled={isCustomer || isApply}
          />
        );
      },
      shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.idUnit !== nextRecord.idUnit
    },
    {
      title: 'Total',
      dataIndex: 'total',
      className: 'width-110',
      key: 'total',
      render: (val, row) => <div key={`total${row.id}`}>{formatCurrency(val, { currencySymbol: 'R$' })}</div>,
      shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.total !== nextRecord.total
    },
    {
      title: '',
      dataIndex: 'name',
      renderMobile: true,
      onlyMobile: true,
      render: (val, row = {}) => {
        const { longPressEvent = f => f } = row;
        return (
          <StyledMobileTextsDiv key={`content${row.id}`} isChildren={row.isChildren} {...(longPressEvent(row) || {})}>
            <div className="title">{row?.name}</div>
            <div className="content">
              <Text className="supplier">{row.supplier?.name}</Text>
              <Text className="total">
                <b>{formatCurrency(row.total, { currencySymbol: 'R$' })}</b>
              </Text>
              <Text>{row.quantity} Un</Text>
            </div>
            <Text>{row?.costCenter?.name}</Text>
          </StyledMobileTextsDiv>
        );
      },
      shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.status !== nextRecord.status
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      className: isPrint ? 'col-grow-1' : 'width-70',
      sorter: !isPrint && !isTemplate,
      renderMobile: true,
      onlyRefurbish: true,
      render: (val, row) => {
        const status = serializedStatusArray[val] || val;

        if (isPrint) return <div>{status?.label || '-'}</div>;
        return (
          <StyledMobileDiv key={`status${row.id}`} isChildren={row.isChildren}>
            {row.isDisabled || isCustomerView || isUserAnonymous ? (
              <StatusSelector status={status} />
            ) : (
              <ColumnWrapper
                value={status}
                _key="value"
                onSelect={handleChange(row, 'status')}
                loadList={statusArray}
                showClean={false}
                canCreate={false}
                IconSelector={({ item }) => <StatusSelector status={item} />}
              >
                {row?.status ? <StatusSelector status={status || val} titleTooltip="Alterar status" /> : '-'}
              </ColumnWrapper>
            )}
          </StyledMobileDiv>
        );
      },
      shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.status !== nextRecord.status
    },
    {
      title: '',
      key: 'action',
      className: 'width-90',
      renderMobile: true,
      render: (val, row, index) => {
        if (isPrint) return null;

        let mappingKey = isTemplate ? 'templateItem' : 'specificationItem';

        if (row.type === refurbishItemType.parent) {
          mappingKey = isTemplate ? 'templateItemParent' : 'specificationParent';
        } else if (hasParent(row, refurbishItemType)) {
          mappingKey = isTemplate ? 'templateItemChildren' : 'specificationChildren';
        }

        const parentItemIndex = !row.idParent ? -1 : list.findIndex(l => l.id === row.idParent);
        const currentWorkingList = parentItemIndex < 0 ? list : list[parentItemIndex].children;

        const beforeBoundary = Number(row.order);
        const afterBoundary = Number(currentWorkingList?.[index + 1]?.order || beforeBoundary + 1);

        const newOrder = (Number(beforeBoundary) + Number(afterBoundary)) / 2;

        return !isApply ? (
          <TableActionDropDown
            key={`action${row.id}`}
            item={{ ...row, newOrder }}
            model={isTemplate ? 'template-item' : 'refurbish-items'}
            mappingObj={mappingKey}
            idRefurbish={idRefurbish}
            afterSubmit={handleLoad}
            idParent={row?.idParent}
            allowCopy
            isGetById
          />
        ) : null;
      },
      shouldCellUpdate: (prevRecord, nextRecord) =>
        prevRecord.status !== nextRecord.status ||
        prevRecord.order !== nextRecord.order ||
        hasParent(prevRecord, refurbishItemType) !== hasParent(nextRecord, refurbishItemType) ||
        prevRecord.files?.length !== nextRecord.files?.length ||
        prevRecord.link !== nextRecord.link ||
        forceCellUpdate === 'action' ||
        prevRecord.countFile !== nextRecord.countFile
    }
  ];

  if (isPrint) {
    return allColumns.filter(c => printColumns.includes(c.key) && !c.onlyMobile && c.dataIndex);
  }

  const _allColumns = isTemplate ? allColumns.filter(c => !c.onlyRefurbish) : allColumns;

  return isMobile ? _allColumns.filter(c => c.renderMobile) : _allColumns.filter(c => !c.onlyMobile);
};

export { columns };
