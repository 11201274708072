/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';

import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Modal from './Modal';
import Button from '../Button/Button';
import useViewport from '../../_Hooks/useViewport';

const ArrowButton = styled(Button)`
  border: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${props => css`
    ${props.direction}: 12px
  `}
`;

const ImageModal = ({ length, onClose, changeImage, cors, alt, src, ...props }) => {
  const { isTablet } = useViewport(window.innerWidth);

  return (
    <Modal open hideFooter noPadding onClose={onClose} height={isTablet() ? 400 : 600} {...props}>
      <div aria-hidden="true" onClick={e => e.stopPropagation()} style={{ height: '100%' }}>
        {length > 1 && (
          <ArrowButton
            noPadding
            type="primary"
            onClick={() => {
              changeImage('left');
            }}
            direction="left"
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </ArrowButton>
        )}
        <img
          crossOrigin={cors ? 'anonymous' : null}
          alt={alt}
          style={{
            display: 'block',
            margin: 'auto',
            height: '100%',
            maxHeight: isTablet() ? 400 : 600,
            width: 'auto',
            maxWidth: '700px',
            objectFit: 'contain'
          }}
          src={src}
        />
        {length > 1 && (
          <ArrowButton
            noPadding
            type="primary"
            onClick={() => {
              changeImage('right');
            }}
            direction="right"
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </ArrowButton>
        )}
      </div>
    </Modal>
  );
};

ImageModal.propTypes = {
  length: PropTypes.number,
  onClose: PropTypes.func,
  changeImage: PropTypes.func,
  cors: PropTypes.bool,
  alt: PropTypes.string,
  src: PropTypes.string
};

export default ImageModal;
