import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { useDrag, useDrop } from 'react-dnd';
import { faTrashAlt, faGripDotsVertical } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import Button from '../Button/Button';
import { borderSolid, colors, spaces } from '../../styles/style';
import EditableInput from '../Input/EditableInput';
import ColorPickerDropDown from '../Dropdown/ColorPickerDropDown';

const { Text } = Typography;

const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding: 4px;
  margin-bottom: 8px;
  cursor: grab;

  .firstColumn {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 8px;
  }

  .otherColumns {
    display: flex;
    justify-content: center;
    padding-left: 8px;
  }

  .icon {
    margin: ${spaces.space1};
  }

  ${props =>
    props.$isOver &&
    css`
      opacity: 0.5 !important;
    `}

  ${props =>
    props.$showBackground &&
    css`
      background: #eef1f9;
    `}

  ${props =>
    props.$maxWidth &&
    css`
      max-width: ${props.$maxWidth};
    `}
`;

const StepHeader = styled.div`
  margin: 16px 0;
  padding-bottom: 8px;
  border-bottom: ${borderSolid};

  .title {
    margin-bottom: ${spaces.space1};
  }
`;

const Steps = ({ list, onSubmit, onChange, onDelete, isOnboarding, $listItemStyles }) => {
  const { user } = useSelector(state => state.authReducer);
  const { userType } = useSelector(state => state.setup.enums);
  const [kanbanList, setKanbanList] = useState([]);
  const [otherList, setOtherList] = useState([]);

  const isBO = user.userType === userType.operator.value;

  const moveRow = useCallback(
    (dragRow, dropRow, dragIndex, dropIndex) => {
      if (dragRow?.order === dropRow?.order) return;

      let beforeBoundary = 0;
      let afterBoundary = 0;

      if (dragIndex < dropIndex) {
        beforeBoundary = dropRow?.order;
        afterBoundary = list?.[dropIndex + 1]?.order || Number(dropRow?.order) + 1;
      } else {
        beforeBoundary = list?.[dropIndex - 1]?.order || 0;
        afterBoundary = dropRow?.order;
      }

      const order = (Number(beforeBoundary || 0) + Number(afterBoundary)) / 2;

      onChange(dragRow, { order, isKanban: dropRow?.isKanban });
    },
    [list, kanbanList]
  );

  useEffect(() => {
    setKanbanList([...list?.filter(l => l.isKanban)]);
    setOtherList([...list?.filter(l => !l.isKanban)]);
  }, [list]);

  const RenderList = ({ item, index, showBackground }) => {
    const ref = useRef();
    const { id, name, color, order } = item || {};

    const [{ isDragging }, dragRef] = useDrag({
      item: { type: 'row', dragItem: item, dragIndex: index },
      collect: monitor => ({
        isDragging: monitor.isDragging()
      })
    });

    const [{ isOver }, dropRef] = useDrop({
      accept: 'row',
      collect: monitor => {
        return { isOver: monitor.isOver() };
      },
      drop(_item) {
        const { dragItem, dragIndex } = _item;
        moveRow(dragItem, item, dragIndex, index);
      }
    });

    const opacity = isDragging ? 0 : 1;
    dragRef(dropRef(ref));

    return (
      <ListItem ref={ref} style={{ opacity }} $isOver={isOver} $showBackground={showBackground} {...$listItemStyles}>
        <div className="icon">
          <FontAwesomeIcon icon={faGripDotsVertical} />
        </div>
        <div className="firstColumn">
          <EditableInput key={`name${id}`} value={name} onChange={val => onChange(item, { name: val })} />
        </div>
        {isBO && (
          <div className="otherColumns">
            <EditableInput
              key={`order${id}`}
              value={order}
              onChange={val => onChange(item, { order: val })}
              style={{ width: '100%' }}
            />
          </div>
        )}
        <div className="otherColumns">
          <ColorPickerDropDown color={color} onSelect={val => onChange(item, { color: val })} />
        </div>
        <div className="otherColumns">
          <Button id="step-delete" type="text" onClick={() => onDelete(item)} style={{ height: '100%', padding: 0 }}>
            <FontAwesomeIcon color={colors.error50} icon={faTrashAlt} />
          </Button>
        </div>
      </ListItem>
    );
  };

  RenderList.propTypes = {
    item: PropTypes.instanceOf(Object),
    index: PropTypes.number,
    showBackground: PropTypes.bool
  };

  return (
    <>
      <StepHeader>
        <div className="title">
          <b>Etapas do projeto</b>
        </div>
        {isOnboarding ? (
          <Text>Ajuste a ordem e altere os textos do jeito que precisar.</Text>
        ) : (
          <Text>
            Aqui estão listadas as etapas que você está utilizando para gerenciar seus projetos na visualização de
            Kanban.
          </Text>
        )}
      </StepHeader>
      <ul>
        {kanbanList?.map((kanbanItem, index) => (
          <RenderList item={kanbanItem} index={index} key={kanbanItem?.id} showBackground />
        ))}
      </ul>
      {!isOnboarding && (
        <>
          <br />
          <StepHeader>
            <Text>
              <div className="title">
                <b>Demais etapas</b>
              </div>
              Etapas disponíves para você utilizar na gestão dos seus projetos e cronogramas.
            </Text>
          </StepHeader>
          <ul>
            {otherList?.map((otherItem, index) => (
              <RenderList item={otherItem} index={index + (kanbanList?.length || 0)} key={otherItem?.id} />
            ))}
          </ul>
        </>
      )}
      <div>
        <Button
          id="add-step"
          type="text"
          style={{ color: colors.primary40 }}
          onClick={() => onSubmit({ values: { name: '', isKanban: isOnboarding } })}
        >
          + Nova etapa
        </Button>
      </div>
    </>
  );
};

Steps.propTypes = {
  list: PropTypes.instanceOf(Array),
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  isOnboarding: PropTypes.bool,
  $listItemStyles: PropTypes.instanceOf(Object)
};

export default Steps;
