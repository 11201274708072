import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import useViewport from '../../_Hooks/useViewport';

import Select from './Select';
import BulkButton from '../Button/BulkButton';

const SelectContainer = styled.div`
  position: relative;
  .ant-select-dropdown,
  .ant-select-dropdown-placement-topLeft {
    width: ${props => props.width}px !important;
  }
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border: 0 !important;
    background: transparent !important;
  }
`;

const BulkActionsSelect = ({
  placeholder,
  options,
  width = 240,
  onChange,
  observe,
  minWidth = 100,
  maxWidth = 300,
  style,
  icon,
  ...props
}) => {
  const [value, setValue] = useState();

  const { isMobile } = useViewport(window.innerWidth);

  useEffect(() => {
    setValue(undefined);
  }, [observe]);

  const handleChange = val => {
    setValue(val);
    onChange(val);
  };

  return isMobile() ? (
    <BulkButton list={options} onClick={handleChange} icon={icon} />
  ) : (
    <SelectContainer id={placeholder} width={width}>
      <StyledSelect
        getPopupContainer={() => document.getElementById(placeholder)}
        value={value}
        options={options}
        placeholder={placeholder}
        onChange={handleChange}
        style={{ minWidth, maxWidth, ...style }}
        dark
        {...props}
      />
    </SelectContainer>
  );
};

BulkActionsSelect.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  width: PropTypes.number,
  onChange: PropTypes.func,
  observe: PropTypes.instanceOf(Array),
  minWidth: PropTypes.number,
  maxWidth: PropTypes.number,
  style: PropTypes.instanceOf(Object),
  icon: PropTypes.node
};

export default BulkActionsSelect;
