import styled from 'styled-components';
import { borderSolid, breakpoints, spaces } from '../../styles/style';

const StepBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StepHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: ${borderSolid};
  padding: ${spaces.space1};
`;

const StepContent = styled.div`
  height: 100%;
  overflow: auto;
`;

const StepFooter = styled.div`
  padding: ${props => (props.$fullWidth ? spaces.space2 : `${spaces.space2} 30%`)};
  display: flex;
  justify-content: space-between;
  border-top: ${borderSolid};

  @media (max-width: ${breakpoints.tablet}) {
    padding: ${spaces.space2};
    margin: -${props => (props.$fullWidth ? spaces.space3 : 0)};

    .back {
      position: absolute;
      top: 60px;

      span {
        display: none;
      }
    }
  }
`;

export { StepBox, StepHeader, StepContent, StepFooter };
