import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Space, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import useCRUD from '../../_Hooks/useCRUD';

import Box from '../../components/Box/Box';
import Image from '../../components/Images/steps.svg';
import { colors, fonts } from '../../styles/style';
import ConfirmModal from '../../components/Modal/ConfirmModal';
import StepList from '../../components/List/StepList';

const { Text } = Typography;

const Steps = ({ hideHeader, skipConfirmation, isOnboarding, $listItemStyles }) => {
  const { user } = useSelector(state => state.authReducer);
  const { userType } = useSelector(state => state.setup.enums);

  const isBO = user.userType === userType.operator.value;

  const [changedStep, setChangedStep] = useState(null);
  const options = isBO
    ? {
        where: { id: { gte: 2 }, idCompany: null },
        order: [['order'], ['createdAt']]
      }
    : {
        onlyMine: true,
        where: { id: { gte: 2 }, ...(isOnboarding && { isKanban: true }) },
        order: [['order'], ['id']]
      };

  const { list, handleCreate, handleUpdate, handleDelete, loading, totalItems } = useCRUD({
    model: 'step',
    options
  });

  const handleConfirm = () => {
    if (!changedStep) {
      return;
    }

    const { id, name, action, order } = changedStep;

    const updateOrDelete = action === 'delete' ? handleDelete({ id }) : handleUpdate({ id, values: { name, order } });

    updateOrDelete.then();
  };

  useEffect(() => {
    if (skipConfirmation) handleConfirm();
  }, [changedStep]);

  return (
    <>
      {!hideHeader && (
        <Box elevatio n={null} mb={2}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Space direction="vertical" size={16} style={{ marginRight: 24 }}>
              <Text style={{ fontSize: fonts.sizeLg }}>Etapas</Text>
              <Text style={{ color: colors.neutral60, fontSize: 12 }}>
                Ao alterar o nome de uma etapa que esteja em uso em algum projeto, essa alteração irá refletir também no
                projeto.
                <br />
                <br />
                Ao remover uma etapa que esteja em uso em algum projeto, essa etapa será removida também no projeto.
              </Text>
            </Space>
            <img src={Image} alt="Imagem" />
          </div>
        </Box>
      )}
      <Box>
        <DndProvider backend={HTML5Backend}>
          <StepList
            list={list}
            onSubmit={handleCreate}
            onChange={(item, values) => handleUpdate({ id: item.id, values })}
            onDelete={item =>
              setChangedStep({
                id: item?.id,
                name: item?.name,
                refurbishes: item?.refurbishes?.length,
                action: 'delete'
              })
            }
            totalItems={totalItems}
            loading={loading}
            isOnboarding={isOnboarding}
            $listItemStyles={$listItemStyles}
          />
        </DndProvider>
      </Box>
      {changedStep && !skipConfirmation ? (
        <ConfirmModal
          text={`Deseja realmente ${changedStep === 'delete' ? 'remover' : 'alterar'} esta etapa? `}
          description={
            changedStep.refurbishes > 0
              ? `Essa etapa é usada em ${changedStep.refurbishes} reformas que serão impactadas com sua ação.`
              : null
          }
          onSubmit={handleConfirm}
          onClose={() => setChangedStep(null)}
        />
      ) : null}
    </>
  );
};

Steps.propTypes = {
  hideHeader: PropTypes.bool,
  skipConfirmation: PropTypes.bool,
  isOnboarding: PropTypes.bool,
  $listItemStyles: PropTypes.instanceOf(Object)
};

export default Steps;
