import Text from 'antd/lib/typography/Text';
import styled from 'styled-components';
import Button from '../../components/Button/Button';

import { breakpoints, colors, fonts, spaces } from '../../styles/style';

export const Container = styled.div`
  background-color: #ffff;
  margin: ${spaces.space2};
  border-radius: ${spaces.space1};
  @media (max-width: ${breakpoints.tablet}) {
    margin: 0;
    border-radius: 0;
    border-top: 1px solid ${colors.neutralSecondary95};
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${spaces.space2};
  min-height: ${spaces.space7};
  font-size: ${fonts.sizeLg};
  border-bottom: 1px solid ${colors.neutralSecondary95};
  @media (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Title = styled.span`
  color: ${colors.neutralSecondary40};
  font-size: ${fonts.sizeLg};
  line-height: ${spaces.space3};
  font-weight: 500;
`;

export const SubTitle = styled.span`
  color: ${colors.neutralSecondary60};
  font-size: ${fonts.sizeSm};
  line-height: ${spaces.space2};
  font-weight: 500;
`;

export const LeftHeaderContent = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${spaces.space1};
  div {
    display: flex;
    flex-direction: column;
  }
`;
export const RightHeaderContent = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${breakpoints.tablet}) {
    //display: none;
  }
`;

export const StatusContainer = styled.div`
  color: ${colors.neutralSecondary40};
  font-size: ${fonts.sizeMd};
  margin-right: ${props => (props.$withMarginRight ? spaces.space4 : '0px')};
  @media (max-width: ${breakpoints.tablet}) {
    display: none;
  }
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${spaces.space5};
  background-color: ${colors.primary90};
  font-size: ${fonts.sizeSm};
  font-weight: 400;
  line-height: 18px;
  color: ${colors.primary30};
  span {
    margin-left: ${spaces.space2};
  }
  svg {
    color: ${colors.primary60};
  }
  padding-right: ${spaces.space2};
  padding-left: ${spaces.space2};

  @media (max-width: ${breakpoints.mobile}) {
    svg {
      margin-left: 20px;
    }
  }
`;

export const Content = styled.div`
  padding: ${spaces.space2};
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: ${spaces.space2};
  height: ${spaces.space8};
  font-size: ${fonts.sizeLg};
  border-top: 1px solid ${colors.neutralSecondary95};

  .withMarginRight {
    margin-right: ${spaces.space4};
  }

  @media (max-width: ${breakpoints.mobile}) {
    .withMarginRight {
      margin-right: ${spaces.space2};
    }
  }
`;

export const Line = styled.div`
  width: 100%;
  border-top: 1px solid ${colors.neutralSecondary95};
`;

export const ShowModalEmptyPayment = styled.div`
  background-color: ${colors.black};
  opacity: 0.6;
  width: 100%;
  height: 100%;
  position: absolute;
`;

export const EmptyText = styled(Text)`
  font-size: ${fonts.sizeH6};
  font-weight: bold;
`;

export const ExitCustomerViewButton = styled(Button)`
  background-color: ${colors.primary90};
  color: ${colors.primary30};
  position: absolute;
  left: 0px;
`;
