import React from 'react';
import PropTypes from 'prop-types';

import { Tabs as AntdTabs } from 'antd';
import styled from 'styled-components';
import { colors, fonts, spaces } from '../../styles/style';

const { TabPane } = AntdTabs;
const StyledLabel = styled.label`
  display: inline-block;
  font-size: ${props => props.$fontSize};
  color: ${props => props.$color};
  font-weight: ${props => props.$fontWeight};
  margin-bottom: ${spaces.space1};
`;

const Label = ({ fontSize = fonts.sizeMd, color = colors.neutral40, fontWeight = 500, children, ...props }) => {
  return (
    <StyledLabel $fontSize={fontSize} $color={color} $fontWeight={fontWeight} {...props}>
      {children}
    </StyledLabel>
  );
};

Label.propTypes = {
  fontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fontWeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.instanceOf(Object)])
};

export default Label;

export { TabPane };
