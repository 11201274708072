import React, { useState, useEffect } from 'react';

// components
import AttachmentList from '../List/AttachmentList';
import FileUpload from '../File/FileUpload';

import { AttachmentsFirstSection } from './PaymentAttachmentContent.style';
import { spaces } from '../../styles/style';
import { useContextHook } from '../../contexts/GeneralContext';
import SimpleAccordion from '../Accordion/SimpleAccordion';

const PaymentAttachmentContent = () => {
  const { values, isView, setField, isCreate } = useContextHook();
  const [files, setFiles] = useState(values?.files || []);
  const handleAddFile = newFiles => {
    setFiles(prev => [...prev, ...newFiles]);
  };

  useEffect(() => {
    setField('files')(files);
  }, [files]);

  return (
    <SimpleAccordion title="Anexos" initOpen={!!files.length || isCreate}>
      <div style={{ maxWidth: '500px' }}>
        {!isView ? (
          <AttachmentsFirstSection>
            <div>
              <FileUpload
                style={{ marginTop: 0, marginLeft: `-${spaces.space1}` }}
                id="file-input"
                onChange={handleAddFile}
                multiple
                showGallery={false}
                text="Adicionar anexo"
                listType={null}
                buttonProps={{ type: 'primary' }}
                fullWidth
                noPadding
              />
              <span>(Tamanho máximo 10mb)</span>
            </div>
            <AttachmentList preventUpload files={files} setFiles={setFiles} />
          </AttachmentsFirstSection>
        ) : (
          <>
            <AttachmentList preventUpload files={files} setFiles={setFiles} readOnly={isView} />
          </>
        )}
      </div>
    </SimpleAccordion>
  );
};

export default PaymentAttachmentContent;
