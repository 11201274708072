import React, { useEffect } from 'react';
import { Button, Modal, Loader, ModalContent, ModalFooter, ModalHeader } from '@homehero/hero-style';
import PropTypes from 'prop-types';

// components
import { simpleFormSchema, simpleFormMapping } from '../../lib/mapping/Form/simpleFormSchema';
import useCRUD from '../../_Hooks/useCRUD';
import Form from '../Form/Form';

const formId = 'refurbishGroupForm';

const RefurbishGroupsModal = ({ idRefurbish, id, open, onClose, grouped }) => {
  const { data, loading, handleUpdate, handleCreate, handleGet } = useCRUD({
    model: grouped?.value,
    immediatelyLoadData: false
  });

  useEffect(() => {
    if (open && id) {
      handleGet({
        refetchPathOptions: `/${id}`
      }).then();
    }
  }, [open, id]);

  const handleSubmit = submitData => {
    const upsert = id
      ? handleUpdate({ id, values: { ...submitData }, refresh: false })
      : handleCreate({ values: { ...submitData, idRefurbish }, refresh: false });

    upsert.then(({ err }) => {
      if (!err) onClose(true);
    });
  };

  const _mapping = simpleFormMapping(grouped?.label);

  const title = `${id ? 'Atualizar' : 'Novo'} ${grouped?.label}`;

  // Todo change to generic form
  return (
    <Modal title={title} contentLabel={title} open={open} onCancel={() => onClose()} center ariaHideApp={false}>
      <ModalHeader title={title} description="Preencha todas as informações corretamente" />
      <ModalContent>
        {loading ? (
          <Loader />
        ) : (
          <Form
            id={formId}
            schema={simpleFormSchema}
            mapping={_mapping}
            loading={loading}
            onSubmit={handleSubmit}
            data={data}
            displayButtons={false}
          />
        )}
      </ModalContent>
      <ModalFooter>
        <Button container="outlined" type="primary" onClick={onClose}>
          Cancelar
        </Button>
        <Button type="primary" submit loading={loading} form={formId}>
          Enviar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

RefurbishGroupsModal.propTypes = {
  idRefurbish: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  grouped: PropTypes.instanceOf(Object)
};

export default RefurbishGroupsModal;
