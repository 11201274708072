import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Upload } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUpload } from '@fortawesome/pro-light-svg-icons';
import { useSelector } from 'react-redux';
import ImgCrop from 'antd-img-crop';
import { toast } from 'react-toastify';
import { CoverImageDragger, StyledDragger, StyledUpload, CoverImageUploadContainer } from './FileUpload.style';
import { uploadMultipart } from '../../lib/helpers/uploadMultipart';

import useCRUD from '../../_Hooks/useCRUD';
import Gallery from '../Gallery/Gallery';
import { colors, spaces } from '../../styles/style';
import Button from '../Button/Button';
import ProductGallery from '../Gallery/ProductGallery';
import ImageModal from '../Modal/ImageModal';

const FileUpload = ({
  id,
  multiple = true,
  onChange = f => f,
  onRemove = f => f,
  showGallery = true,
  text = 'Clique para enviar o arquivo',
  startLoading = f => f,
  initialFiles = [],
  children,
  buttonProps,
  galleryProps,
  type,
  textArray,
  limitSize,
  onSuccessUpload,
  onErrorUpload,
  icon,
  tooltipText,
  iconSize,
  subType,
  onUploadButtonClick,
  secondaryButtonText,
  onSecondaryButtonClick,
  fullWidth,
  noPadding,
  galleryType = 'common',
  buttonObrigatory,
  noMobileMargin,
  ...props
}) => {
  const _initialFiles = Array.isArray(initialFiles) ? initialFiles : [initialFiles];
  const [files, setFiles] = useState(_initialFiles.map(item => ({ fullpath: item?.fullpath || item, status: 'done' })));
  const [selectedFileIndex, setSelectedFileIndex] = useState(0);
  const [showImageModal, setShowImageModal] = useState(false);
  const { user } = useSelector(state => state.authReducer);
  const { handleCreate: uploadFile } = useCRUD({
    model: 'media',
    pathOptions: '/start',
    immediatelyLoadData: false
  });

  const upload = (fileObject, onSuccess, onError) => {
    const file = {
      lastModified: fileObject.lastModified,
      lastModifiedDate: fileObject.lastModifiedDate,
      name: fileObject.name,
      size: fileObject.size,
      type: fileObject.type
    };

    uploadFile({ values: { file, limitSize }, refresh: false })
      .then(data => {
        const { signUrls, uploadId } = data;
        return uploadMultipart(fileObject, signUrls).then(parts => {
          uploadFile({ values: { file, uploadId, parts }, postPathOptions: '/complete', refresh: false }).then(f => {
            onSuccess && onSuccess(f);
            onSuccessUpload && onSuccessUpload(f);
          });
        });
      })
      .catch(err => {
        onError && onError(err);
        onErrorUpload && onErrorUpload(err);
      });
  };

  const handleModalOk = file => {
    startLoading();
    upload(file);
  };

  const customRequest = option => {
    const { onSuccess, onError, file } = option;
    upload(file, onSuccess, onError);
  };
  const handleBeforeUpload = file => {
    if (!limitSize) return true;
    if (file.size > limitSize) {
      toast.error('O tamanho da foto excedeu o limite!');
      return false;
    }
    return true;
  };

  const handleRemove = index => {
    const file = files[index];
    const newArr = [...files];
    newArr.splice(index, 1);

    setFiles(newArr);
    onRemove(file?.id, index);
  };

  const changeFile = direction => {
    setSelectedFileIndex(index => {
      if (direction === 'right') {
        return index === files?.length - 1 ? 0 : index + 1;
      }

      return index === 0 ? files?.length - 1 : index - 1;
    });
  };

  const openImageModalInIndex = index => {
    setShowImageModal(true);
    setSelectedFileIndex(index);
  };

  const _props = {
    name: 'file',
    headers: {
      authorization: 'authorization-text'
    },
    customRequest,
    fileList: files,
    onChange(info) {
      startLoading(true);
      const { file, fileList } = info;

      setFiles(
        fileList
          .filter(_file => _file.status !== 'error')
          .map(_file => ({
            uid: _file.uid,
            filename: _file.name || _file.filename,
            fullpath: _file.response?.location || _file.fullpath,
            status: _file.status
          }))
      );

      if (fileList.every(f => f.status === 'done')) {
        if (multiple) {
          onChange(
            textArray
              ? fileList.map(f => f.response?.location || f.fullpath)
              : fileList.map(f => ({ fullpath: f.response?.location || f.fullpath, filename: f.name }))
          );
        } else {
          onChange(
            textArray
              ? file.response?.location
              : { fullpath: file.response?.location || file.fullpath, filename: file.name }
          );
        }

        if (!showGallery) {
          setFiles([]);
        }
      }
    },
    showUploadList: false,
    listType: 'picture-card',
    multiple,
    type,
    ...props
  };

  const uploadComponent = useMemo(() => {
    if (type === 'dragger') {
      return (
        <StyledDragger {..._props}>
          <FontAwesomeIcon icon={faCloudUpload} />
          <p className="ant-upload-text">{text}</p>
        </StyledDragger>
      );
    }

    if (type === 'avatar') {
      return (
        <ImgCrop shape="round" modalOk="Enviar" onModalOk={handleModalOk} modalTitle="Posicione sua foto">
          <Upload showUploadList={false} accept="image/png, image/jpeg, image/jpg" beforeUpload={handleBeforeUpload}>
            {subType === 'button' ? (
              <Button text onClick={onUploadButtonClick}>
                <FontAwesomeIcon icon={icon} size={iconSize} color={colors.primary40} style={{ cursor: 'pointer' }} />
                {text || 'Enviar'}
              </Button>
            ) : (
              <Tooltip title={tooltipText}>
                <FontAwesomeIcon icon={icon} size={iconSize} color="white" style={{ cursor: 'pointer' }} />
              </Tooltip>
            )}
          </Upload>
        </ImgCrop>
      );
    }

    if (type === 'coverImage') {
      return (
        <CoverImageUploadContainer>
          <ImgCrop
            shape="rect"
            aspect={5.55}
            modalOk="Aplicar"
            onModalOk={handleModalOk}
            modalTitle="Posicione sua imagem"
          >
            <CoverImageDragger
              id="drag-image-input"
              showUploadList={false}
              accept="image/png, image/jpeg, image/jpg"
              beforeUpload={handleBeforeUpload}
            >
              <p id="drag-image-text" style={{ marginBottom: spaces.space1 }}>
                Arraste uma imagem para cá
              </p>
              <p style={{ marginBottom: spaces.space1, fontSize: '12px' }}>ou se preferir</p>
              {subType === 'button' && (
                <Button
                  id="select-file-button"
                  type="primary"
                  onClick={onUploadButtonClick}
                  style={{ width: '148px', marginBottom: spaces.space1 }}
                >
                  {text || 'Enviar'}
                </Button>
              )}

              {secondaryButtonText && (
                <Button type="ghost" style={{ width: '148px' }} onClick={onSecondaryButtonClick}>
                  {secondaryButtonText}
                </Button>
              )}
            </CoverImageDragger>
          </ImgCrop>
        </CoverImageUploadContainer>
      );
    }

    if (type === 'pickCoverImage') {
      return (
        <ImgCrop
          shape="rect"
          modalOk="Aplicar"
          onModalOk={e =>
            handleModalOk(e, f => {
              setFiles([{ fullpath: f?.location || f?.fullpath || f, status: 'done' }]);
              onChange(f?.location);
            })
          }
          modalTitle="Posicione sua imagem"
        >
          <StyledUpload fullWidth={fullWidth} noPadding={noPadding} {..._props}>
            <FontAwesomeIcon icon={faCloudUpload} />
            <p className="ant-upload-text">{text}</p>
          </StyledUpload>
        </ImgCrop>
      );
    }

    return (
      <StyledUpload fullWidth={fullWidth} noPadding={noPadding} noMobileMargin={noMobileMargin} {..._props}>
        {showGallery && !buttonObrigatory ? (
          <>
            <FontAwesomeIcon icon={faCloudUpload} />
            <p className="ant-upload-text">{text}</p>
          </>
        ) : (
          <Button
            icon={!buttonProps?.$noIcon && <FontAwesomeIcon icon={faCloudUpload} />}
            disabled={user.isCustomerView}
            {...buttonProps}
          >
            {text || 'Enviar'}
          </Button>
        )}
      </StyledUpload>
    );
  }, [user, showGallery, _props, type, buttonProps, tooltipText]);

  if (showGallery) {
    return galleryType === 'new' ? (
      <>
        <ProductGallery
          images={files}
          uploadComponent={uploadComponent}
          onDelete={handleRemove}
          openImageModalInIndex={openImageModalInIndex}
        />
        {showImageModal && (
          <ImageModal
            src={files[selectedFileIndex]?.fullpath}
            changeImage={changeFile}
            length={files?.length}
            onClose={() => setShowImageModal(false)}
          />
        )}
      </>
    ) : (
      <div>
        <Gallery onRemove={handleRemove} images={files} noPreview unique={!multiple} {...galleryProps}>
          {uploadComponent}
        </Gallery>
      </div>
    );
  }

  return <div style={props.style}>{uploadComponent}</div>;
};

FileUpload.propTypes = {
  id: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  showGallery: PropTypes.bool,
  text: PropTypes.string,
  startLoading: PropTypes.func,
  initialFiles: PropTypes.instanceOf(Array),
  children: PropTypes.instanceOf(Object),
  buttonProps: PropTypes.instanceOf(Object),
  galleryProps: PropTypes.instanceOf(Object),
  textArray: PropTypes.bool,
  type: PropTypes.string,
  subType: PropTypes.string,
  limitSize: PropTypes.number,
  onSuccessUpload: PropTypes.func,
  onErrorUpload: PropTypes.func,
  tooltipText: PropTypes.string,
  icon: PropTypes.instanceOf(Object),
  iconSize: PropTypes.string,
  style: PropTypes.instanceOf(Object),
  onUploadButtonClick: PropTypes.func,
  onSecondaryButtonClick: PropTypes.func,
  secondaryButtonText: PropTypes.string,
  fullWidth: PropTypes.bool,
  noPadding: PropTypes.bool,
  galleryType: PropTypes.string,
  buttonObrigatory: PropTypes.bool,
  noMobileMargin: PropTypes.bool
};

export default FileUpload;
