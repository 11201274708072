import React from 'react';
import PropTypes from 'prop-types';
import ConfirmModal from '../Modal/ConfirmModal';
import Dropdown from './Dropdown';
import EditInfoModal from '../Modal/EditInfoModal';
import LinkTaskModal from '../Modal/linkTaskModal';
import CopyLinkButton from '../Button/CopyLinkButton';

const TaskActionDropdown = ({
  id,
  model = 'refurbish-step',
  row,
  afterSubmit = f => f,
  isRoot = false,
  placement,
  generalTask,
  ...props
}) => {
  const taskModel = generalTask ? 'generalTask' : 'task';

  return (
    <Dropdown
      slim
      padding="0 8px"
      data={row}
      mappingKey={isRoot ? 'default' : taskModel}
      model={isRoot ? model : 'task'}
      afterSubmit={afterSubmit}
      trigger={['click']}
      placement={placement}
      modal={{
        confirmModal: params => (
          <ConfirmModal
            {...params}
            {...props}
            {...(isRoot
              ? {
                  text: 'Ao realizar esta ação suas tarefas serão movidas para a etapa Geral.',
                  title: 'Remover etapa do projeto'
                }
              : null)}
          />
        ),
        editItemModal: params => <EditInfoModal {...params} {...props} id={id} />,
        linkTaskModal: params => <LinkTaskModal {...params} {...props} row={row} />
      }}
      button={{
        copyLink: ({ onClose, ...params }) => (
          <CopyLinkButton onClose={onClose} eventButton="task" urlContext="t" idData={id} {...params} />
        )
      }}
    />
  );
};

TaskActionDropdown.propTypes = {
  id: PropTypes.number,
  row: PropTypes.instanceOf(Object),
  afterSubmit: PropTypes.func,
  isRoot: PropTypes.bool,
  idRefurbish: PropTypes.number,
  model: PropTypes.string,
  placement: PropTypes.string,
  generalTask: PropTypes.bool
};

export default TaskActionDropdown;
