import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Text from 'antd/lib/typography/Text';
import styled from 'styled-components';
import { colors, fonts, spaces } from '../../styles/style';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spaces.space3} ${spaces.space4};
`;

const Title = styled(Text)`
  font-size: ${fonts.sizeMd};
  font-weight: 500;
  color: ${colors.neutral40};
  padding-bottom: ${spaces.space1};
`;

const Subtitle = styled.p`
  font-size: ${fonts.sizeSm};
  font-weight: 400;
  color: ${colors.neutral60};
  padding-bottom: ${spaces.space2};
`;

const ListItems = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;

const Card = styled.div`
  display: flex;
  width: 100%;
  height: ${spaces.space5};
  align-items: center;
  justify-content: space-between;
  padding: ${spaces.space1} ${spaces.space2};
  border-radius: ${spaces.space1};
  background-color: ${colors.neutral95};
  margin-bottom: ${spaces.space1};
  span {
    color: ${colors.neutral40};
  }
  :hover {
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 8%);
  }
`;

const StyledStrashIcon = styled(FontAwesomeIcon)`
  color: ${colors.error50};
  padding-left: ${spaces.space2};
  height: ${fonts.sizeMd};
  :hover {
    cursor: pointer;
  }
`;

const IconCheck = styled(FontAwesomeIcon)`
  color: ${colors.primary40};
  padding-left: ${spaces.space2};
  height: ${fonts.sizeMd};
  :hover {
    cursor: pointer;
  }
`;

export { Content, Title, Subtitle, ListItems, Card, StyledStrashIcon, IconCheck };
