import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { ModalContent, Row, Col, Pagination, Tabs, TabList, Tab, TabPanel, Text, Input } from '@homehero/hero-style';
import isEqual from 'react-fast-compare';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-light-svg-icons';
import Modal from './Modal';
import style from './SearchModal.module.scss';
// Hooks

import useCRUD from '../../_Hooks/useCRUD';
// components
import CenteredLoader from '../Loader/CenteredLoader';
import NoContent from '../NoContent/NoContent';
import NoProducts from '../Images/NoProducts';
import NoCatalogProductV2 from '../Images/NoCatalogProductv2';
import ProductCard from '../Card/ProductCard';
import Filters from '../Filters/Filters';
import AddToProjectButton from '../Button/AddToProjectButton';
import useViewport from '../../_Hooks/useViewport';
import { fonts, breakpoints } from '../../styles/style';
import { hasPermission } from '../../routes/Common/PrivateRoute';
import BubbleModalButton from '../Button/BubbleModalButton';

const StyledText = styled(Text)`
  font-size: ${fonts.sizeLg};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 50%;

  @media (min-width: ${breakpoints.tablet}) {
    margin: 0 16px;
    font-size: ${fonts.sizeSm};
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`;

const ITEMS_PAGE = 18;
const PAGINATION = {
  offset: 1,
  limit: ITEMS_PAGE
};

const images = {
  initialState: ({ title, description }) => (
    <NoContent
      renderButton={false}
      title={title || 'Comece a pesquisar..'}
      image={<NoProducts />}
      description={description || 'São milhares de produtos para ajudar você a fornecer o melhor para seus clientes.'}
    />
  ),
  notFound: ({ keyword, suggestion, onClick }) => {
    const Description = () => (
      <>
        Não foram encontrados resultados para sua busca de <b>{keyword}</b>.
        {suggestion ? (
          <span style={{ marginLeft: '2px' }}>
            Talvez você queira pesquisar por {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className={style.cursor} aria-hidden="true" onClick={() => onClick(suggestion, true)}>
              {suggestion}
            </a>
          </span>
        ) : (
          ''
        )}
      </>
    );
    return (
      <NoContent renderButton={false} title="Oh-Oh..." image={<NoCatalogProductV2 />} description={<Description />} />
    );
  }
};

images.initialState.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
};

images.notFound.propTypes = {
  keyword: PropTypes.string,
  suggestion: PropTypes.string,
  onClick: PropTypes.func
};

const SearchModal = ({ onClose = f => f, onSubmit }) => {
  const { user } = useSelector(state => state.authReducer);
  const { refurbishItemType } = useSelector(state => state.setup.enums);
  const { refurbishItemTypes } = useSelector(state => state.setup.systemData);
  const { plans, permissions } = useSelector(state => state.setup);
  const [queryString, setQueryString] = useState(PAGINATION);
  const [keyword, setKeyword] = useState('');
  const [tabIndex, setTabIndex] = useState(0);
  const [hasSearched, setHasSearched] = useState(false);
  const [searchFilters, setSearchFilters] = useState(null);
  const [costCenterFilters, setCostCenterFilters] = useState([]);
  const [typeFilters, setTypeFilters] = useState([]);
  const { isMobile } = useViewport(window.innerWidth);

  const _hasPermissionProduct = hasPermission(user, ['products'], plans, permissions);

  const { data, list, loading, handleGet, totalItems } = useCRUD({
    model: 'search',
    immediatelyLoadData: false
  });
  const { list: itemList, loading: itemLoading, totalItems: itemsTotalItems, handleGet: getMyItems } = useCRUD({
    model: 'item',
    options: { limit: 20, include: ['supplier'], where: { type: refurbishItemType.notParent } }
  });

  const { list: listCostCenter } = useCRUD({
    model: 'cost-center',
    options: {
      where: {
        isActive: true
      }
    },
    immediatelyLoadData: true
  });

  const searchRef = useRef({});
  const { didYouMean, filters } = data || {}; // refineQuery,
  const forceUpdate = useRef(false);

  const handleSearch = _query => {
    const { keyword: _keyword, where, offset, limit } = _query;
    const name = _keyword ? { name: { like: `%${_keyword}%` } } : {};

    const type = where?.type || refurbishItemType.notParent;
    return tabIndex === 0
      ? handleGet({ refetchOptions: _query })
      : getMyItems({
          refetchOptions: {
            where: { ...where, ...name, type },
            offset,
            limit,
            include: ['supplier']
          }
        });
  };

  const handleQuery = useCallback(
    _query => {
      const { keyword: _keyword } = _query;
      if (isEqual(searchRef.current, _query) || (!searchRef.current?.keyword && !_keyword && tabIndex === 0)) return;
      setKeyword(_keyword);
      setHasSearched(!!_keyword);
      handleSearch(_query).then(() => {
        searchRef.current = _query;
      });
    },
    [tabIndex]
  );

  const handleForceUpdate = (_keyword, _forceUpdate) => {
    forceUpdate.current = !!_forceUpdate;
    setKeyword(_keyword);
  };

  const handleQueryStringChange = e => {
    const { value } = e.target;
    setKeyword(value);
    setSearchFilters(null);
    setQueryString({ ...queryString, offset: 1, keyword: value, where: {} });
  };

  const handlePagination = page => {
    const newQueryString = { ...queryString, offset: page };
    setQueryString(newQueryString);
  };

  // @Todo redo Filters to use ARRAY DATA STRUCTURE and key instead of Preço for instance
  const handleFilter = _filters => {
    const { order, Preço: price, Marca: brand = [], Loja: store = [] } = _filters;

    const { min, max } = price || {};
    const _brand = brand.map(({ value }) => value);
    const _store = store.map(({ value }) => value);

    const _min = min ?? 0;
    const _price = [Math.min(_min, max), Math.max(_min, max)];

    const newQueryString = {
      ...queryString,
      order,
      where: { price: _price, brand: _brand, store: _store },
      offset: 1
    };
    setQueryString(newQueryString);
  };

  const handleMyProductsFilter = _filters => {
    const { Categoria: costCenter = [], Tipo: type = [] } = _filters;
    const _costCenter = costCenter.map(({ value }) => value);
    const _type = type.map(({ value }) => value);

    const newQueryString = {
      ...queryString,
      where: { idCostCenter: _costCenter, type: _type },
      offset: 1
    };

    const newCostCenterFilter = costCenterFilters.map(item => ({ ...item, checked: _costCenter.includes(item.id) }));
    const newTypeFilter = typeFilters.map(item => ({ ...item, checked: _type.includes(item.value) }));
    setCostCenterFilters(newCostCenterFilter);
    setTypeFilters(newTypeFilter);
    setQueryString(newQueryString);
  };

  const handleSubmit = submitData => {
    const newData = {
      ...submitData,
      create: true,
      ...(tabIndex === 0 ? { idSearch: submitData.id } : { internalId: submitData.id })
    };
    onSubmit(newData);
  };

  const shouldSearchRef = useRef(true);

  useEffect(() => {
    if (filters) {
      setSearchFilters(filters);
    }
  }, [filters]);

  useEffect(() => {
    if (listCostCenter && listCostCenter.length) {
      setCostCenterFilters(listCostCenter.map(({ name, id }) => ({ checked: false, label: name, value: id })));
    }
  }, [listCostCenter]);

  useEffect(() => {
    if (refurbishItemTypes && refurbishItemTypes.length) {
      setTypeFilters(
        refurbishItemTypes.filter(p => !p.hideFilter).map(({ label, value }) => ({ checked: false, label, value }))
      );
    }
  }, [refurbishItemTypes]);

  useEffect(() => {
    const _queryString = {
      ...PAGINATION,
      keyword,
      tabIndex
    };
    shouldSearchRef.current = false;
    setQueryString(_queryString);
  }, [tabIndex]);

  useEffect(() => {
    const timer =
      shouldSearchRef.current &&
      setTimeout(() => {
        handleQuery(queryString);
      }, 400);
    shouldSearchRef.current = true;
    return () => clearTimeout(timer);
  }, [queryString, handleQuery]);

  const _totalItems = tabIndex === 0 ? totalItems : itemsTotalItems;
  const _loading = tabIndex === 0 ? loading : itemLoading;
  const displayList = tabIndex === 0 ? list : itemList;

  const NoContentInfo = images[!hasSearched ? 'initialState' : 'notFound'];

  const productsEmpty =
    tabIndex === 1
      ? {
          title: ' Nenhum item cadastrado. ',
          description: `Cadastre seus próprios itens e economize tempo em seus orçamentos.
            \n Para começar, basta acessar o menu Catálogo e clicar em Meus Produtos e Serviços.`
        }
      : {};

  const RenderList = useMemo(
    () =>
      !_totalItems ? (
        <Row className={style.error}>
          <NoContentInfo
            keyword={keyword}
            suggestion={didYouMean || null}
            onClick={handleForceUpdate}
            {...productsEmpty}
          />
        </Row>
      ) : (
        <div className={style['product-list']}>
          {displayList &&
            displayList.map(_data => (
              <div key={_data.id} className={style.product}>
                <ProductCard
                  data={_data}
                  AddComponent={AddToProjectButton}
                  onSelect={handleSubmit}
                  showBorder={false}
                  openBlank
                  showZero
                  redirectUrl={
                    tabIndex === 1
                      ? `/${user.type}/catalogo/meus-produtos-servicos/editar/${_data.id}`
                      : `/catalogo/produto/${_data.id}`
                  }
                />
              </div>
            ))}
        </div>
      ),
    [_totalItems, displayList, tabIndex, user, keyword, didYouMean, AddToProjectButton, handleForceUpdate]
  );

  return (
    <Modal
      open
      hideFooter
      title="Procurar itens"
      onClose={() => onClose(false)}
      width={isMobile() ? '100%' : '80%'}
      height={isMobile() ? '94%' : '90%'}
      className={style[`search-modal`]}
    >
      <ModalContent className={style['modal-content']}>
        <Tabs
          selectedIndex={tabIndex}
          onSelect={index => {
            if (!_hasPermissionProduct) {
              return setTabIndex(0);
            }
            return setTabIndex(index);
          }}
        >
          <div className={style['modal-content-header']}>
            <Row between="lg">
              <Col lg={8} md={8} sm={8} xs={12}>
                <Input
                  id="keyword"
                  name="keyword"
                  placeholder="Pesquisar (ex: Porcelanato )"
                  onChange={handleQueryStringChange}
                  value={keyword}
                />
              </Col>
              <Col lg={4} md={4} sm={4} xs={12}>
                <TabList>
                  <Tab>Produtos Vobi</Tab>
                  {_hasPermissionProduct ? (
                    <Tab>Meus Produtos e Serviços</Tab>
                  ) : (
                    <Tab>
                      <BubbleModalButton feature="products">
                        <div>
                          Meus Produtos e Serviços <FontAwesomeIcon icon={faLock} />
                        </div>
                      </BubbleModalButton>
                    </Tab>
                  )}
                </TabList>
                <TabPanel />
                <TabPanel />
              </Col>
            </Row>
            {tabIndex === 0 ? (
              <Row className={style.filters} between="lg">
                {hasSearched && (
                  <Filters key="search" shrinkStyle filters={searchFilters} onFilter={handleFilter}>
                    {hasSearched ? (
                      <StyledText>
                        <b>{totalItems}</b> itens encontrados
                      </StyledText>
                    ) : null}
                  </Filters>
                )}
              </Row>
            ) : (
              <Row className={style.filters} between="lg">
                <Filters
                  key="myproducts"
                  shrinkStyle
                  filters={[
                    {
                      items: costCenterFilters,
                      key: 'costCenter',
                      name: 'Categoria',
                      showScreen: true,
                      type: 'default'
                    },
                    {
                      items: typeFilters,
                      key: 'type',
                      name: 'Tipo',
                      showScreen: true,
                      type: 'default'
                    }
                  ]}
                  onFilter={handleMyProductsFilter}
                  displaySorting={false}
                />
              </Row>
            )}
          </div>
          {_loading ? <CenteredLoader /> : RenderList}
        </Tabs>
      </ModalContent>
      <div>
        {_totalItems > 0 ? (
          <Pagination
            activePage={Number(queryString.offset || 1)}
            itemsCountPerPage={ITEMS_PAGE}
            totalItemsCount={_totalItems}
            pageRangeDisplayed={5}
            onChange={handlePagination}
          />
        ) : null}
      </div>
    </Modal>
  );
};

SearchModal.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};

export default SearchModal;
