import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareNodes } from '@fortawesome/pro-regular-svg-icons';

import styled from 'styled-components';
import useViewport from '../../_Hooks/useViewport';

import Dropdown from './Dropdown';
import CopyLinkButton from '../Button/CopyLinkButton';

import { colors, spaces } from '../../styles/style';
import Button from '../Button/Button';

const StyledTagLink = styled.a`
  font-weight: 300;
  :hover {
    font-weight: 500;
  }
`;

const ShareLinkDropdown = ({ id, urlContext, style, whatsapp, ...props }) => {
  const { isMobile } = useViewport(window.innerWidth);

  return (
    <Dropdown
      id="share-link-dropdown"
      padding={`0 ${spaces.space1}`}
      mappingKey={whatsapp ? 'shareLinkWithWhatsapp' : 'shareLink'}
      trigger={['click']}
      button={{
        copyLink: ({ onClose: closeDropdown, ...params }) => (
          <CopyLinkButton onClose={closeDropdown} eventButton="task" urlContext={urlContext} idData={id} {...params} />
        ),
        shareWhatsappLink: () => (
          <Button text>
            <StyledTagLink
              target="_blank"
              href={`https://wa.me/?text=Olá%20veja%20esse%20item%20do%20seu%20projeto%20na%20plataforma:%20
              ${window.location.origin}/${urlContext}/${id}
              `}
            >
              WhatsApp
            </StyledTagLink>
          </Button>
        )
      }}
      {...props}
    >
      <Tooltip title="Compartilhar">
        <FontAwesomeIcon
          id="share-link-icon"
          style={{ padding: 0, ...style }}
          icon={faShareNodes}
          size={isMobile() ? 'sm' : 'lg'}
          color={colors.primary40}
        />
      </Tooltip>
    </Dropdown>
  );
};

ShareLinkDropdown.propTypes = {
  id: PropTypes.number,
  urlContext: PropTypes.string,
  style: PropTypes.instanceOf(Object),
  whatsapp: PropTypes.bool
};

export default ShareLinkDropdown;
