import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import {
  faLayerGroup,
  faBookmark,
  faTrashAlt,
  faMagic,
  faEllipsisV,
  faStore,
  faFolders,
  faTag,
  faSquare,
  faCopy
} from '@fortawesome/pro-regular-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useCRUD from '../../_Hooks/useCRUD';
import useViewport from '../../_Hooks/useViewport';

import { sumByField } from '../../lib/helpers/helper';

import BulkAction from './BulkAction';
import BulkActionsSelect from '../Select/BulkActionsSelect';
import ConfirmModal from '../Modal/ConfirmModal';
import AddToTemplateModal from '../Modal/AddToTemplateModa';
import TooltipIcon from '../Icons/TooltipIcon';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import Dropdown from '../Dropdown/Dropdown';

import { BulkActionsChildrenContainer, ModalItem } from './SpecificationBulkActions.styles';
import MobileSelectMenu from './MobileSelectMenu';

const SpecificationBulkActions = ({
  idRefurbish,
  selectedItems,
  selectedRowKeys,
  setSelectedRowKeys,
  setSelectedItems,
  list,
  setList,
  handleLoad,
  showStatus,
  items,
  showAll,
  supplierList,
  groupList,
  categoryList,
  isGrouped,
  isTemplate,
  isDefaultOrder
}) => {
  const [showModalTemplate, setShowModalTemplate] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [idTemplate, setIdTemplate] = useState();
  const [listTemplate, setListTemplate] = useState([]);
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [selectedRowKeysObj, setSelectedRowKeysObj] = useState({});

  useEffect(() => {
    const obj = {};
    selectedRowKeys.forEach(key => {
      obj[key] = true;
    });

    setSelectedRowKeysObj(obj);
  }, [selectedRowKeys]);

  const { isMobile } = useViewport(window.innerWidth);

  const { refurbishItemStatus: status } = useSelector(state => state.setup.systemData);
  const { user } = useSelector(state => state.authReducer);
  const { userType } = useSelector(state => state.setup.enums) || {};
  const { refurbishItemType } = useSelector(state => state.setup.enums) || {};
  const counter = selectedItems.filter(value => value.type !== refurbishItemType.parent).length;
  const isOperator = user.userType === userType.operator.value;
  const isCustomer = user.userType === userType.customer.value;

  const statusList = status.map(priority => ({ value: priority.value, label: priority.label }));

  const { handleCreate: handleCreateTemplate } = useCRUD({
    model: `refurbish-items/template`,
    immediatelyLoadData: false
  });

  const { handleGet: handleGetTemplates } = useCRUD({
    model: 'template',
    options: { onlyMine: !isOperator },
    immediatelyLoadData: false
  });

  const { handleCreate: handleBulkCreate, handleUpdate: handleBulkUpdate, handleDelete: handleBulkDelete } = useCRUD({
    model: isTemplate ? 'template-item/bulk' : `refurbish-items/bulk`,
    immediatelyLoadData: false
  });

  const { handleCreate: duplicateItems } = useCRUD({
    model: 'refurbish-items/bulk/duplicate',
    immediatelyLoadData: false
  });

  const idsToDuplicate = () => {
    const selectedIds = [];

    const serializeSelectedIds = item => {
      if (item.children?.length) {
        const selectedChildrenIds = [];

        let everyChildIsSelected = true;

        item.children.forEach(child => {
          const isSelected = selectedRowKeysObj[child.id];
          if (isSelected) {
            selectedChildrenIds.push(child.id);
          } else {
            everyChildIsSelected = false;
          }
        });

        if (everyChildIsSelected) {
          selectedIds.push(item.id);
        } else {
          selectedIds.push(...selectedChildrenIds);
        }
      } else if (selectedRowKeysObj[item.id]) {
        selectedIds.push(item.id);
      }
    };

    if (isGrouped) {
      list.forEach(group => {
        group.item.forEach(serializeSelectedIds);
      });
    } else {
      list.forEach(serializeSelectedIds);
    }

    return selectedIds;
  };

  const handleDuplicateItems = () => {
    duplicateItems({
      values: {
        ids: idsToDuplicate()
      },
      refresh: false
    }).then(() => {
      handleLoad();
      const listEndWarn = `(no final da lista)`;
      toast.success(`
        ${counter} ite${counter > 1 ? 'ns' : 'm'} duplicado${counter > 1 ? 's' : ''}
        ${isDefaultOrder ? listEndWarn : ''}.
      `);
    });
  };

  const handleSelectItem = (key, _items) => {
    setSelectedRowKeys(key);
    setSelectedItems(_items);
  };

  const reloadList = () => {
    handleLoad() && handleSelectItem([], []);
  };

  const handleChange = values => {
    const { field } = values;
    const selectedIds = [...selectedRowKeys];

    if (list && (field === 'idSupplier' || field === 'status')) {
      list.forEach(item => {
        if (item.children?.length) {
          const everyChildIsSelected = item.children.every(child => {
            return selectedRowKeysObj[child.id];
          });

          if (everyChildIsSelected) {
            selectedIds.push(item.id);
          }
        }
      });
    }

    handleBulkUpdate({ values: { id: selectedIds, ...values }, refresh: false }).then(data => {
      if (isGrouped) return reloadList();

      const hashList = data?.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {});
      return setList(prevList =>
        prevList.map(item => {
          return {
            ...(hashList[item.id] || item),
            children: item?.children.map(child => {
              return hashList[child.id] || child;
            })
          };
        })
      );
    });
  };

  const _handleBulkCreate = (path, message) => {
    handleBulkCreate({
      values: {
        id: selectedRowKeys,
        idRefurbish
      },
      postPathOptions: path,
      displayToast: message || 'Operação realizada com sucesso!',
      refresh: false
    }).then(resp => {
      if (!resp?.error) {
        reloadList();
      }
    });
  };

  const handleGroup = () => _handleBulkCreate();

  const addToproductsAndServices = () =>
    _handleBulkCreate('/copy', 'Itens adicionados em Meus Produtos e Serviços com sucesso!');

  const _handleBulkDelete = ids => {
    handleBulkDelete({
      deleteOptions: {
        where: { idRefurbish, id: ids }
      },
      displayToast: 'Operação realizada com sucesso!',
      refresh: false
    }).then(() => {
      if (isGrouped) return reloadList();

      const hashList = ids.reduce((acc, cur) => ({ ...acc, [cur]: true }), {});
      const newArray = [];
      return setList(prevList => {
        prevList.forEach(item => {
          const hasChildren = item.children?.length > 0;

          if (hashList[item.id] && !hasChildren) {
            return;
          }

          if (hasChildren) {
            const newChildrenArray = item.children.filter(child => {
              return !hashList[child.id];
            });

            if (newChildrenArray.length === 0) return;

            newArray.push({ ...item, children: newChildrenArray });

            return;
          }

          newArray.push(item);
        });

        return newArray;
      });
    });
  };

  const handleClose = () => {
    setSelectedRowKeys([]);
    setSelectedItems([]);
  };

  const handleShowModalTemplate = () => {
    handleGetTemplates().then(res => {
      setListTemplate(res);
      setShowModalTemplate(true);
    });
  };

  const createOrApplyTemplate = ({ name, description, type }) => {
    handleCreateTemplate({
      values: {
        idRefurbish,
        description,
        name,
        idItems: [...new Set(selectedItems.map(e => e.idParent || e.id))],
        idTemplate,
        type
      },
      displayToast: isCreate ? 'Template criado com sucesso' : 'Operação realizada com sucesso!',
      refresh: false
    });
    setIsCreate(false);
    setShowModalTemplate(false);
    handleClose();
  };

  const total = useMemo(() => (items ? sumByField(items, 'total') : null), [items]);

  const selectedTotal = useMemo(
    () =>
      sumByField(
        selectedItems.filter(value => value.type !== refurbishItemType.parent),
        'total'
      ),
    [selectedItems]
  );

  if (counter <= 0) return null;

  return isCustomer && !showStatus ? null : (
    <BulkAction
      counter={counter}
      total={total}
      selectedTotal={selectedTotal}
      handleClose={handleClose}
      showTotal={showAll}
    >
      {!isMobile() && (
        <BulkActionsChildrenContainer>
          {!isCustomer && (
            <>
              <BulkActionsSelect
                options={[{ label: '-', value: 1 }, ...supplierList]}
                placeholder="Fornecedor"
                onChange={value => handleChange({ field: 'idSupplier', value, idRefurbish })}
                observe={selectedRowKeys}
              />
              <BulkActionsSelect
                options={[{ label: '-', value: 1 }, ...groupList]}
                placeholder="Grupo"
                onChange={value => handleChange({ field: 'idRefurbishGroup', value, idRefurbish })}
                observe={selectedRowKeys}
              />
              <BulkActionsSelect
                options={[{ label: '-', value: 1 }, ...categoryList]}
                placeholder="Categoria"
                onChange={value => handleChange({ field: 'idCostCenter', value, idRefurbish })}
                observe={selectedRowKeys}
              />
            </>
          )}

          {showStatus && !user?.anonymous && (
            <BulkActionsSelect
              options={statusList}
              placeholder="Status"
              onChange={value => handleChange({ field: 'status', value, idRefurbish })}
              observe={selectedRowKeys}
              width={120}
            />
          )}

          {!isCustomer && (
            <>
              <TooltipIcon
                text="Realizar agrupamento"
                icon={faLayerGroup}
                onClick={handleGroup}
                style={{ height: 18, width: 18, marginLeft: 16, cursor: 'pointer' }}
              />
              {!isTemplate && (
                <TooltipIcon
                  text="Adicionar aos meus produtos e serviços"
                  icon={faBookmark}
                  onClick={addToproductsAndServices}
                  style={{ height: 18, width: 18, marginLeft: 16, cursor: 'pointer' }}
                />
              )}
              {!isTemplate && (
                <TooltipIcon
                  text="Criar template ou adicionar a um template"
                  icon={faMagic}
                  onClick={handleShowModalTemplate}
                  style={{ height: 18, width: 18, marginLeft: 16, cursor: 'pointer' }}
                />
              )}
              {!isTemplate && (
                <TooltipIcon
                  text="Duplicar itens"
                  icon={faCopy}
                  onClick={handleDuplicateItems}
                  style={{ height: 18, width: 18, marginLeft: 16, cursor: 'pointer' }}
                />
              )}
              <TooltipIcon
                text="Excluir itens selecionados"
                icon={faTrashAlt}
                onClick={() => setShowConfirmModal(true)}
                style={{ height: 18, width: 18, marginLeft: 16, cursor: 'pointer' }}
              />
            </>
          )}
        </BulkActionsChildrenContainer>
      )}

      {isMobile() && (
        <>
          <Button noPadding text onClick={() => setShowOptionsModal(true)}>
            <FontAwesomeIcon icon={faEllipsisV} size="2x" />
          </Button>

          {showOptionsModal && (
            <Modal title="Opções" open hideFooter onClose={() => setShowOptionsModal(false)}>
              {!isCustomer && (
                <>
                  <Dropdown
                    menu={
                      <MobileSelectMenu
                        onClick={(field, option) => handleChange({ field, value: option.value || option.id })}
                        options={[{ label: '-', value: 1 }, ...supplierList]}
                        field="idSupplier"
                      />
                    }
                  >
                    <ModalItem>
                      <FontAwesomeIcon icon={faStore} />
                      <p>Alterar fornecedor</p>
                    </ModalItem>
                  </Dropdown>

                  <Dropdown
                    menu={
                      <MobileSelectMenu
                        onClick={(field, option) => handleChange({ field, value: option.value || option.id })}
                        options={[{ label: '-', value: 1 }, ...groupList]}
                        field="idGroup"
                      />
                    }
                  >
                    <ModalItem>
                      <FontAwesomeIcon icon={faFolders} />
                      <p>Alterar grupo</p>
                    </ModalItem>
                  </Dropdown>

                  <Dropdown
                    menu={
                      <MobileSelectMenu
                        onClick={(field, option) => handleChange({ field, value: option.value || option.id })}
                        options={[{ label: '-', value: 1 }, ...categoryList]}
                        field="idCostCenter"
                      />
                    }
                  >
                    <ModalItem>
                      <FontAwesomeIcon icon={faTag} />
                      <p>Alterar categoria</p>
                    </ModalItem>
                  </Dropdown>
                </>
              )}

              {showStatus && !user?.anonymous && (
                <Dropdown menu={<MobileSelectMenu options={statusList} field="status" />}>
                  <ModalItem>
                    <FontAwesomeIcon icon={faSquare} />
                    <p>Alterar status</p>
                  </ModalItem>
                </Dropdown>
              )}

              {!isCustomer && (
                <>
                  <ModalItem onClick={handleGroup}>
                    <FontAwesomeIcon icon={faLayerGroup} />
                    <p>Realizar agrupamento</p>
                  </ModalItem>
                  {!isTemplate && (
                    <ModalItem onClick={addToproductsAndServices}>
                      <FontAwesomeIcon icon={faBookmark} />
                      <p>Adicionar a minha biblioteca</p>
                    </ModalItem>
                  )}
                  {!isTemplate && (
                    <ModalItem onClick={handleShowModalTemplate}>
                      <FontAwesomeIcon icon={faMagic} />
                      <p>Criar ou adicionar a um template</p>
                    </ModalItem>
                  )}
                  {!isTemplate && (
                    <ModalItem onClick={handleDuplicateItems}>
                      <FontAwesomeIcon icon={faCopy} />
                      <p>Duplicar itens</p>
                    </ModalItem>
                  )}
                  <ModalItem onClick={() => setShowConfirmModal(true)}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                    <p>Excluir itens selecionados</p>
                  </ModalItem>
                </>
              )}
            </Modal>
          )}
        </>
      )}

      {showModalTemplate && (
        <AddToTemplateModal
          createOrApplyTemplate={createOrApplyTemplate}
          isCreate={isCreate}
          listTemplate={listTemplate}
          onClose={() => {
            setShowModalTemplate(false);
            setIsCreate(false);
          }}
          setIdTemplate={setIdTemplate}
          setIsCreate={setIsCreate}
        />
      )}

      {showConfirmModal && (
        <ConfirmModal
          text={`Tem certeza que deseja apagar ${counter} ite${counter > 1 ? 'ns' : 'm'} selecionado${
            counter > 1 ? 's' : ''
          }?`}
          onSubmit={() => {
            _handleBulkDelete(selectedRowKeys);
            handleClose();
            setShowConfirmModal(false);
          }}
          onClose={() => setShowConfirmModal(false)}
        />
      )}
    </BulkAction>
  );
};

SpecificationBulkActions.propTypes = {
  idRefurbish: PropTypes.number,
  selectedItems: PropTypes.instanceOf(Array),
  selectedRowKeys: PropTypes.instanceOf(Array),
  setSelectedRowKeys: PropTypes.func,
  setSelectedItems: PropTypes.func,
  setList: PropTypes.func,
  handleLoad: PropTypes.func,
  showStatus: PropTypes.bool,
  items: PropTypes.instanceOf(Array),
  showAll: PropTypes.bool,
  isGrouped: PropTypes.bool,
  supplierList: PropTypes.instanceOf(Array),
  groupList: PropTypes.instanceOf(Array),
  categoryList: PropTypes.instanceOf(Array),
  isTemplate: PropTypes.bool,
  list: PropTypes.instanceOf(Array),
  isDefaultOrder: PropTypes.bool
};

export default SpecificationBulkActions;
