import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import SimpleAccordion from '../Accordion/SimpleAccordion';
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import { ButtonsContainer, NotesContainer, NoteTitle, NoteTooltip } from './PaymentNotesForm.style';
import { useContextHook } from '../../contexts/GeneralContext';
import Button from '../Button/Button';
import { colors } from '../../styles/style';

const PaymentNotesForm = () => {
  const { values, isView, setField, isCustomerView, isPublic, isCharge, isCreate } = useContextHook();
  const { information, observation, annotation } = values || {};

  const [notesVisible, setNotesVisible] = useState({});

  const handleNotesClick = type => {
    setNotesVisible(prev => ({ ...prev, [type]: true }));
  };

  const handleTextEditorChange = field => content => {
    setField(field)(content);
  };
  return (
    <SimpleAccordion title="Anotações" initOpen={!!observation || !!annotation || !!information || isCreate}>
      <NotesContainer $isView={isView}>
        {isCharge && (notesVisible.information || information) ? (
          <div style={{ width: '100%' }}>
            <NoteTitle>Informações de pagamento</NoteTitle>
            {!isView && (
              <NoteTooltip
                style={{ color: colors.primary40 }}
                icon={faInfoCircle}
                color={colors.primary40}
                text="Insira aqui as informações de pagamento como conta bancária,
               pix ou outra informação relacionada ao pagamento."
                size="lg"
              />
            )}
            <RichTextEditor
              onChange={handleTextEditorChange('information')}
              value={information}
              height={100}
              toolbar={false}
              statusbar={false}
              outputFormat="html"
              disabled={isView}
              placeholder="Insira aqui as informações de pagamento como conta bancária, 
              pix ou outra informação relacionada ao pagamento."
            />
          </div>
        ) : null}
        {isCharge && (notesVisible.observation || observation) ? (
          <div style={{ width: '100%' }}>
            <NoteTitle>Observações</NoteTitle>
            <RichTextEditor
              onChange={handleTextEditorChange('observation')}
              value={observation}
              height={100}
              toolbar={false}
              statusbar={false}
              outputFormat="html"
              disabled={isView}
              placeholder="Insira aqui observações relevantes para o seu cliente."
            />
          </div>
        ) : null}

        {(notesVisible.annotation || annotation) && !isCustomerView && !isPublic && (
          <div style={{ width: '100%' }}>
            <NoteTitle>Notas Internas</NoteTitle>
            {!isView && (
              <NoteTooltip
                style={{ color: colors.primary40 }}
                icon={faInfoCircle}
                color={colors.primary40}
                text={
                  isCharge
                    ? 'As notas internas não são visíveis pelo seu cliente.'
                    : 'Registre informações adicionais utilizando as notas.'
                }
                size="lg"
              />
            )}
            <RichTextEditor
              onChange={handleTextEditorChange('annotation')}
              value={annotation}
              height={100}
              toolbar={false}
              statusbar={false}
              outputFormat="html"
              disabled={isView}
              placeholder={
                isCharge
                  ? 'Insira aqui as informações que não serão compartilhadas com o cliente.'
                  : 'Insira aqui as observações que desejar.'
              }
            />
          </div>
        )}
        {isView && !annotation && !observation && !information && <p>Nenhuma anotação adicionada!</p>}
      </NotesContainer>

      {!isView && (
        <ButtonsContainer>
          {!notesVisible.information && !information && isCharge ? (
            <div>
              <Button text type="primary" noPadding onClick={() => handleNotesClick('information')}>
                <FontAwesomeIcon icon={faPlus} />
                Informações de pagamento
              </Button>
            </div>
          ) : null}

          {!notesVisible.observation && !observation && isCharge ? (
            <div>
              <Button text type="primary" noPadding onClick={() => handleNotesClick('observation')}>
                <FontAwesomeIcon icon={faPlus} />
                Observações
              </Button>
            </div>
          ) : null}
          {!notesVisible.annotation && !annotation && (
            <div>
              <Button text type="primary" noPadding onClick={() => handleNotesClick('annotation')}>
                <FontAwesomeIcon icon={faPlus} />
                Notas internas
              </Button>
            </div>
          )}
        </ButtonsContainer>
      )}
    </SimpleAccordion>
  );
};

export default PaymentNotesForm;
