import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import CenteredLoader from '../Loader/CenteredLoader';

import { StyledDiv, StyledDrawer, StyledSubtitle, Header, StyledSubTitle } from './Drawer.styles';
import HowDoesItWorksButton from '../Button/HowDoesItWorksButton';

const Drawer = ({
  title = 'Titulo modal',
  subtitle,
  open,
  onSubmit = () => {},
  onClose = () => {},
  width = 600,
  placement,
  submitText = 'Confirmar',
  cancelText = 'Cancelar',
  children,
  loading,
  footer,
  hideFooter,
  formId,
  closeLeft = false,
  headerExtraContent: HeaderExtraContent,
  extraContentOnRight,
  styleDiv = {},
  HowDoesItWorks,
  aditionalInformation,
  subTitle,
  ...props
}) => {
  const _footer = footer || (
    <>
      <Button onClick={onClose} loading={loading}>
        {cancelText}
      </Button>
      <Button id="submit-task" htmlType="submit" type="primary" loading={loading} form={formId} onClick={onSubmit}>
        {submitText}
      </Button>
    </>
  );

  const Title = (
    <div style={{ ...styleDiv }}>
      <StyledDiv>
        <Header extraContentOnRight={extraContentOnRight}>
          <span>{title}</span>

          {HeaderExtraContent ? <HeaderExtraContent /> : null}
        </Header>
        {HowDoesItWorks && <HowDoesItWorksButton id={HowDoesItWorks} />}
      </StyledDiv>
      {subTitle && <StyledSubTitle>{subTitle}</StyledSubTitle>}

      {aditionalInformation && aditionalInformation}
    </div>
  );

  return (
    <StyledDrawer
      title={Title}
      visible={open}
      onClose={onClose}
      placement={placement}
      width={width}
      footer={hideFooter ? null : _footer}
      $closeLeft={closeLeft}
      {...props}
    >
      {subtitle ? <StyledSubtitle>{subtitle}</StyledSubtitle> : null}
      {loading ? <CenteredLoader /> : <section style={{ paddingTop: props.padding || '16px' }}>{children}</section>}
    </StyledDrawer>
  );
};

Drawer.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  open: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  placement: PropTypes.string,
  submitText: PropTypes.string,
  cancelText: PropTypes.string,
  children: PropTypes.instanceOf(Object).isRequired,
  loading: PropTypes.bool,
  footer: PropTypes.instanceOf(Object),
  hideFooter: PropTypes.bool,
  formId: PropTypes.string,
  closeLeft: PropTypes.bool,
  headerExtraContent: PropTypes.instanceOf(Object),
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  styleDiv: PropTypes.instanceOf(Object),
  HowDoesItWorks: PropTypes.string,
  aditionalInformation: PropTypes.instanceOf(Object),
  extraContentOnRight: PropTypes.func,
  subTitle: PropTypes.string
};

export default Drawer;
