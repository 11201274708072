import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import ItemDetailButton from '../Button/ItemDetailButton';
import Dropdown from './Dropdown';
import EditItemModal from '../Modal/EditItemModal';
import ConfirmModal from '../Modal/ConfirmModal';
// eslint-disable-next-line import/no-cycle
import ChildrenItemsModal from '../Modal/ChildrenItemsModal';
import { breakpoints, spaces } from '../../styles/style';
import FileUpload from '../File/FileUpload';
import useCRUD from '../../_Hooks/useCRUD';
import CopyLinkButton from '../Button/CopyLinkButton';
import RedirectLinkButton from '../Button/EditpaymentButton';
import EditInstallmentDrawer from '../Drawer/EditInstallmentDrawer';
import RegisterInstallmentModal from '../Modal/RegisterInstallmentModal';
import SharePaymentModal from '../Modal/SharePaymentModal';

const StyledDiv = styled.div`
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${breakpoints.tablet}) {
    flex-direction: ${props => props.mobileDirection};
    padding-top: ${props => (props.noPadding ? 0 : spaces.space4)};
  }
`;

const TableActionDropDown = ({
  model = 'refurbish-items',
  options,
  item,
  mappingObj,
  idRefurbish,
  afterSubmit,
  idParent,
  noPadding,
  redirectViewDetailClick = false,
  isGetById = false,
  mobileDirection = 'column',
  redirectPaperClick = false,
  redirectUrl,
  isCustomerView
}) => {
  const { user } = useSelector(state => state.authReducer);
  const { userType } = useSelector(state => state.setup.enums);
  const history = useHistory();
  const MappingEdit = {
    installment: <EditInstallmentDrawer eventButton="editpayment" idRefurbish={idRefurbish} id={item?.id} />
  };
  const RenderModal = MappingEdit[model] || (
    <EditItemModal model={model} options={options} idParent={idParent} tab="0" id={item.id} idRefurbish={idRefurbish} />
  );

  const mappingKey = mappingObj || 'instalmentParent';

  const children = item.children?.length ? item.children.map(c => c.id) : [];
  const isCustomer = user.userType === userType.customer.value;

  const { handleGet, handleUpdate } = useCRUD({
    model,
    idRefurbish,
    pathOptions: item?.id ? `/${item?.id}` : '',
    options: {
      include: ['files']
    },
    immediatelyLoadData: false
  });

  const handleUploadFile = (newFiles, onClose) => {
    const itemPromisse = isGetById ? handleGet() : Promise.resolve(item);
    itemPromisse.then(data => {
      const files = [...(data?.files || []), ...newFiles];
      handleUpdate({
        values: {
          idRefurbish,
          files
        },
        refresh: false
      }).then(resp => {
        if (!resp.error) {
          onClose(true);
          toast.success('Anexo adicionado');
        }
      });
    });
  };

  return (
    <StyledDiv onClick={e => e.stopPropagation()} noPadding={noPadding} mobileDirection={mobileDirection}>
      <ItemDetailButton
        show={!!item.link}
        icon="faLink"
        onClick={() => {
          window.open(item.link);
        }}
      />
      <ItemDetailButton
        show={!!item.files?.length || item.countFile > 0}
        icon="faPaperclip"
        titleTooltip="Ver anexos"
        onClick={() => {
          if (redirectPaperClick) history.push(redirectUrl);
        }}
      >
        {RenderModal}
      </ItemDetailButton>
      {isCustomer && (
        <div style={{ marginLeft: 8 }}>
          <ItemDetailButton
            titleTooltip="Ver detalhes"
            onClick={() => {
              if (redirectViewDetailClick) history.push(redirectUrl);
            }}
          >
            {RenderModal}
          </ItemDetailButton>
        </div>
      )}
      <>
        {!isCustomer && !isCustomerView && (
          <Dropdown
            slim
            data={{ id: item.id, order: item.order, newOrder: item.newOrder, idRefurbish, children }}
            mappingKey={mappingKey}
            trigger={['click']}
            model={model}
            options={{ where: { idRefurbish } }}
            button={{
              uploadFile: ({ onClose, ...params }) => (
                <FileUpload onChange={newFiles => handleUploadFile(newFiles, onClose)} {...params} />
              ),
              copyLink: ({ onClose, ...params }) => (
                <CopyLinkButton onClose={onClose} eventButton="item" urlContext="o" idData={item?.id} {...params} />
              ),
              redirectLink: ({ onClose, ...params }) => (
                <RedirectLinkButton onClose={onClose} idData={item?.id} {...params} />
              )
            }}
            modal={{
              editItem: ({ ...params }) => (
                <EditItemModal model={model} options={options} idParent={idParent} {...params} />
              ),
              confirmModal: ConfirmModal,
              modal: ({ ...params }) => <ChildrenItemsModal data={item?.children} hideAction {...params} />,
              editPaymentModal: EditInstallmentDrawer,
              registerPayment: RegisterInstallmentModal,
              sharePayment: SharePaymentModal
            }}
            afterSubmit={resp => (!resp?.error && afterSubmit ? afterSubmit(resp) : null)}
          />
        )}
      </>
    </StyledDiv>
  );
};

TableActionDropDown.propTypes = {
  model: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  options: PropTypes.instanceOf(Object),
  item: PropTypes.instanceOf(Object),
  mappingObj: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  idRefurbish: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  afterSubmit: PropTypes.func,
  idParent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  allowCopy: PropTypes.bool,
  noPadding: PropTypes.bool,
  installment: PropTypes.bool,
  redirectViewDetailClick: PropTypes.bool,
  isGetById: PropTypes.bool,
  refurbishType: PropTypes.string,
  mobileDirection: PropTypes.string,
  redirectPaperClick: PropTypes.bool,
  redirectUrl: PropTypes.string,
  isCustomerView: PropTypes.bool
};

export default TableActionDropDown;
