import React from 'react';
import * as dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import DocumentFileIcon from '../../components/Icons/DocumentFile';
import PowerpointFileIcon from '../../components/Icons/PowerpointFile';
import PhotoshopFileIcon from '../../components/Icons/PhotoshopFile';
import PdfFileIcon from '../../components/Icons/PdfFile';
import XLSFileIcon from '../../components/Icons/XLSFile';
import UnknownFileIcon from '../../components/Icons/UnknownFile';
import formatCurrency from './formatCurrency';
import formatNumber from './formatNumber';
import { colors } from '../../styles/style';

dayjs.extend(relativeTime);

const bytesToSize = bytes => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  return `${Math.round(bytes / 1024 ** i, 2)} ${sizes[i]}`;
};

const removeAccents = str =>
  (str &&
    str
      .toString()
      .toLowerCase()
      .replace(/[àáâãäå]/g, 'a')
      .replace(/[èéêë]/g, 'e')
      .replace(/[ìíîï]/g, 'i')
      .replace(/[òóôõö]/g, 'o')
      .replace(/[ùúûü]/g, 'u')
      .replace(/[ýÿ]/g, 'y')
      .replace(/æ/g, 'ae')
      .replace(/œ/g, 'oe')
      .replace(/ñ/g, 'n')
      .replace(/ç/g, 'c')) ||
  str;

const getFileType = fullType => {
  if (!fullType) return null;
  const split = fullType.split('.');
  return split[split.length - 1];
};

const getFileIcon = ({ filename, width, height, imageElement }) => {
  const ext = (filename && filename.split('.')[1]) || '';
  switch (ext.toLowerCase()) {
    case 'pdf': {
      return <PdfFileIcon width={width} height={height} />;
    }
    case 'pptx':
    case 'ppt': {
      return <PowerpointFileIcon width={width} height={height} />;
    }
    case 'psd': {
      return <PhotoshopFileIcon width={width} height={height} />;
    }
    case 'doc':
    case 'docx': {
      return <DocumentFileIcon width={width} height={height} />;
    }
    case 'xls': {
      return <XLSFileIcon width={width} height={height} />;
    }
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'svg':
    case 'gif': {
      return imageElement;
    }
    default: {
      return <UnknownFileIcon width={width} height={height} />;
    }
  }
};

const getFileName = fullpath => {
  if (!fullpath) {
    return '';
  }
  const { pathname } = new URL(fullpath);
  const paths = pathname.split('/');
  return decodeURIComponent(paths[paths.length - 1]);
};

const isImgUrl = url =>
  // eslint-disable-next-line
  /(((https?:\/\/)|(\/\/)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/.test(
    url
  );

const isImgIcon = url => !isImgUrl(url);

const saveFilesForm = ({ files, handleCreate, isPublic }) => {
  const _files = Array.from(files) || [];

  return Promise.all(
    _files.map(file => {
      const formData = new FormData();
      formData.append('file', file);

      if (isPublic) {
        formData.append('isPublic', 'isPublic');
      }
      return handleCreate({ values: formData, refresh: false });
    })
  ).then(result => result.map(res => res.location || res.fullpath));
};

const parseSearchableOptions = (opts, customLabel, customValue) =>
  opts && opts.constructor === Array
    ? opts.map(opt => {
        let label = opt?.name || opt?.label;
        if (customLabel && typeof customLabel === 'function') {
          label = customLabel(opt);
        } else if (customLabel && opt?.[customLabel]) {
          label = opt?.[customLabel]?.constructor === Object ? opt?.[customLabel].name : opt?.[customLabel];
          label = label?.trim() ? label?.trim() : opt?.name;
        }
        return { value: opt?.[customValue] || opt?.id || opt?.value, label: label || '-' };
      })
    : [];

const getSelectItem = ({ value, opts }) => {
  return opts.find(o => o.value === value) || {};
};

const capitalize = s => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const toKebabCase = str => str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();

const padLeft = (number, pad = '0000') => (pad + number).slice(-4);

const sumByField = (values, field) =>
  values?.map(item => Number(item[field] || 0)).reduce((next, prev) => next + prev, 0);

const supportedExtensions = ['jpg', 'jpeg', 'svg+xml', 'png', 'pdf'];
const isExtensionSupported = extension =>
  supportedExtensions.map(s => s.toLowerCase()).includes(extension.toLowerCase());

const getProperty = (obj, path) => {
  if (!path || !obj) return obj;
  const properties = path.split('.');
  return getProperty(obj[properties.shift()], properties.join('.'));
};

const formatSinceTime = date => {
  const createdDate = new Date(date);
  const hoursSinceCreatedDate = (new Date().getTime() - createdDate.getTime()) / (1000 * 60 * 60);

  if (hoursSinceCreatedDate > 48) {
    return `${dayjs(createdDate).format('DD [de] MMMM, YYYY [às] HH:mm')}`;
  }

  return dayjs(createdDate).fromNow();
};

const getInitials = name =>
  name
    ?.split(' ')
    .map(s => s.charAt(0))
    .join('')
    .slice(0, 2);

const hexToRgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

const transformResponsibleFilter = filter => {
  const { idResponsible, ..._filter } = filter || {};
  return idResponsible?.includes(null)
    ? {
        ..._filter,
        idResponsible: { or: [...idResponsible, null] }
      }
    : filter;
};

const exportItems = ({ filePrefix, resp }) => {
  const translateModelExportCsv = {
    item: 'Catálogo',
    supplier: 'Fornecedores',
    'company-customer': 'Clientes',
    refurbish: 'Projetos',
    opportunity: 'Oportunidades',
    'refurbish-item-instalment': 'Pagamentos',
    task: 'Tarefas',
    shopping: 'Compras',
    refurbishItems: 'Orçamento',
    payment: 'Cobranças',
    income: 'Receitas',
    expense: 'Despesas',
    results: 'Resultados'
  };
  const link = window.document.createElement('a');
  link.setAttribute('href', `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURIComponent(resp)}`);
  link.setAttribute(
    'download',
    `${translateModelExportCsv[filePrefix] || filePrefix}_${dayjs().format('DD-MM-YYYY_HH-mm-ss')}.csv`
  );
  link.click();
};

const exportCsvToModel = ({
  where,
  include,
  handleGet,
  refetchOptions,
  order = [['createdAt', 'desc']],
  model = 'Arquivo',
  typeExport,
  prefixName
}) => {
  const suffix = typeExport || model;
  return handleGet({
    refetchPathOptions: `/export${suffix ? `/${suffix}` : ''}`,
    refetchOptions: refetchOptions || {
      where,
      include,
      order
    }
  }).then(resp => exportItems({ filePrefix: prefixName || model, resp }));
};

const maskHours = hours => {
  const _hours = hours.split(':')[0];
  if (parseInt(_hours, 10) <= 99) return '99';
  if (parseInt(_hours, 10) > 99) return '999';
  return '999';
};

const getMinutesFromHHMM = value => {
  if (typeof value !== 'string') return value;
  const [str1, str2] = value.split(':');

  const val1 = Number(str1);
  const val2 = Number(str2);

  if (!Number.isNaN(val1) && Number.isNaN(val2)) {
    return val1;
  }

  if (!Number.isNaN(val1) && !Number.isNaN(val2)) {
    return val1 * 60 + val2;
  }

  return 0;
};

const toHHMM = mins => {
  if (!mins) return null;
  const minNum = parseInt(mins.toString(), 10);
  const hours = Math.floor(minNum / 60);
  const minutes = Math.floor(minNum) % 60;

  return [hours, minutes]
    .map(val => (val < 10 ? `0${val}` : val))
    .join(':')
    .replace(/^0/, '');
};

const groupTaskWithDate = list => {
  if (!list?.length) return {};
  return list?.reduce((acc, cur) => {
    if (!acc[dayjs(cur.createdAt).format('DD, MMMM, YYYY')]) {
      acc[dayjs(cur.createdAt).format('DD, MMMM, YYYY')] = [];
    }
    acc[dayjs(cur.createdAt).format('DD, MMMM, YYYY')].push(cur);
    return acc;
  }, {});
};

const getColorByStatusPayment = (idStatus, status) => {
  switch (idStatus) {
    case status.draft: {
      return colors.primary40;
    }
    case status.open: {
      return colors.warningSecondary70;
    }
    case status.paid: {
      return colors.success40;
    }
    case status.cancelled: {
      return colors.error40;
    }
    case status.pendingPayment: {
      return colors.warningSecondary70;
    }
    default: {
      return colors.primary40;
    }
  }
};

const getColorByStatusInstallment = (idStatus, status) => {
  switch (idStatus) {
    case status.pendingPayment: {
      return colors.warningSecondary70;
    }
    case status.paid: {
      return colors.success40;
    }
    default: {
      return colors.primary40;
    }
  }
};
const calcValuesByPercentage = ({ property, percentageField, setField, subtotal }) => {
  const calcValue = (formatNumber(subtotal) * formatNumber(percentageField)) / 100;
  setField(
    property,
    formatCurrency(calcValue, {
      currencySymbol: 'R$ '
    }),
    false
  );
};

const calcPercentageByValue = ({ setPercentage, field, subtotal }) => {
  const calcValue = formatCurrency((formatNumber(field) / formatNumber(subtotal)) * 100);

  setPercentage(subtotal > 0 ? calcValue : 0);
};

const getToday = ({ onlyDate = true } = {}) => {
  const now = new Date();
  return onlyDate ? new Date(now.getFullYear(), now.getMonth(), now.getDate()) : now;
};

const getYesterday = ({ onlyDate = true } = {}) => {
  const now = new Date();
  return onlyDate ? new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1) : now;
};

const getTomorrow = ({ onlyDate = true } = {}) => {
  const now = new Date();
  return onlyDate ? new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1) : now;
};

export {
  bytesToSize,
  removeAccents,
  getFileType,
  getFileName,
  isImgUrl,
  saveFilesForm,
  parseSearchableOptions,
  getSelectItem,
  capitalize,
  toKebabCase,
  padLeft,
  sumByField,
  supportedExtensions,
  isExtensionSupported,
  getProperty,
  formatSinceTime,
  getInitials,
  hexToRgba,
  transformResponsibleFilter,
  exportCsvToModel,
  maskHours,
  getMinutesFromHHMM,
  toHHMM,
  getFileIcon,
  groupTaskWithDate,
  isImgIcon,
  getColorByStatusPayment,
  calcValuesByPercentage,
  calcPercentageByValue,
  getColorByStatusInstallment,
  getToday,
  getYesterday,
  getTomorrow
};
