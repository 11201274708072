import React from 'react';
import PropTypes from 'prop-types';

import css from './PreviewFiles.module.scss';

const PreviewFiles = ({ fileType, path, alt }) => {
  switch (fileType) {
    case 'jpg':
    case 'jpeg':
    case 'svg+xml':
    case 'png':
    case 'image/jpg':
    case 'image/jpeg':
    case 'image/png':
      return <img src={path} alt={alt} className={css['c-preview-files__img']} />;
    case 'pdf':
      return (
        <object data={path} type="text/html" className={css['c-preview-files__pdf']}>
          Pré visualização PDF
        </object>
      );
    default:
      return null;
  }
};

PreviewFiles.propTypes = {
  fileType: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  alt: PropTypes.string
};

export default PreviewFiles;
