import styled from 'styled-components';
import { colors, fonts, spaces } from '../../styles/style';

const StyledNotificationDropdown = styled.div`
  width: 460px;
  padding-top: ${spaces.space1};
  border-radius: ${spaces.space2};
  background-color: ${colors.white};
  box-shadow: 1px 1px 6px ${colors.neutralSecondary50};
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spaces.space1};
  padding: 0 ${spaces.space2};

  h5 {
    color: ${colors.neutralSecondary40};
  }
`;

const Footer = styled.div`
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.primary40};
  font-size: ${fonts.sizeLg};
  font-weight: 600;
`;

const StyledNotificationDropdownItem = styled.a`
  height: 80px;
  padding: 0 ${spaces.space2};
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${colors.neutralSecondary90};
  }
`;
const ItemText = styled.div`
  flex: 1;
  margin-left: ${spaces.space3};
`;

const ItemTextDescription = styled.p`
  color: ${colors.neutralSecondary30};
`;

const ItemTextTime = styled.span`
  color: ${colors.neutralSecondary50};
`;

export {
  StyledNotificationDropdown,
  StyledNotificationDropdownItem,
  ItemText,
  ItemTextDescription,
  ItemTextTime,
  Title,
  Footer
};
