import { useHistory, useLocation } from 'react-router';

const useBackToContext = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  return () => {
    if (pathname?.includes('/perfil')) {
      const context = pathname?.split('/perfil', 1);
      history.replace(context?.[0]);
      return null;
    }
    if (pathname?.includes('/editar')) {
      const context = pathname?.split('/editar', 1);
      history.replace(pathname?.includes('/meus-templates') ? `${context?.[0]}/todos` : context?.[0]);
      return null;
    }
    history.goBack();
    return null;
  };
};

export default useBackToContext;
