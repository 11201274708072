import React from 'react';
import PropTypes from 'prop-types';

// components
import { SimpleTable as SimpleStyledTable } from './Table.styled';

const SimpleTable = ({ data, columns }) => {
  return <SimpleStyledTable pagination={false} size="small" dataSource={data} columns={columns} />;
};

SimpleTable.propTypes = {
  data: PropTypes.instanceOf(Array),
  columns: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.instanceOf(Array)]).isRequired
};

export default SimpleTable;
