import { useEffect } from 'react';
import { useLocation } from 'react-router';

const useReplaceUrl = ({ preventTrigger = true, urlToReplace }) => {
  const originalUrl = useLocation();

  useEffect(() => {
    if (!preventTrigger) {
      window.history.replaceState(null, '', urlToReplace);
    }
  }, [preventTrigger]);

  const returnToOriginalUrl = () => {
    window.history.replaceState(null, '', originalUrl.pathname);
  };

  return { returnToOriginalUrl };
};

export default useReplaceUrl;
