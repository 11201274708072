import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// components
import TableOrList from '../List/TableOrList';
import { columns as scheduleColumns } from '../../lib/mapping/TableOrList/scheduleColumns';
import useViewport from '../../_Hooks/useViewport';
import AddTableButton from '../Button/AddTableButton';
import { parseArrayAsObject } from '../../lib/helpers/parseArrayAsObject';

import AddTaskModal from '../Modal/AddTaskModal';
import EditInfoModal from '../Modal/EditInfoModal';
import TaskBulkActions from '../BulkActions/TaskBulkActions';

import { useContextHook } from '../../contexts/GeneralContext';
import PdfTable from './PdfTable';
import SelectDropdown from '../Dropdown/SelectDropdown';
import LinkTaskModal from '../Modal/linkTaskModal';
import useScheduleData from './useScheduleData';

const childrenColumnName = 'tasks';

const ScheduleTable = ({
  idReference,
  referModel = 'refurbish',
  referKey = 'idRefurbish',
  referOptions,
  stepOptions,
  generalTask,
  template,
  isApply,
  defaultOrder,
  idCompany,
  handleCloseBulkAction,
  counter,
  selectedRowKeys,
  selectedItems,
  forceLoad,
  onSelectItem,
  dateRangeDisplay,
  ...props
}) => {
  const { isMobile } = useViewport(window.innerWidth);
  const { taskStatus, taskPriority } = useSelector(state => state.setup.systemData);
  const { userType, taskStatus: taskStatusEnum } = useSelector(state => state.setup.enums);
  const { user } = useSelector(state => state.authReducer);
  const isCustomer = user.userType === userType.customer.value;

  const [serializedStatusArray, setSerializedStatusArray] = useState({});
  const [serializedPrioritiesArray, setSerializedPrioritiesArray] = useState({});
  const [editModalId, setEditModalId] = useState(null);
  const [currentData, setCurrentData] = useState(null);
  const [selectStepModal, setSelectStepModal] = useState(false);
  const [linkTaskModal, setLinkTaskModal] = useState(false);

  const { refurbish, filter, isPrintList, setIsPrintList } = useContextHook();

  const {
    list: referList,
    loadData: handleLoadStep,
    loadTasks,
    handleAdd,
    handleChange,
    handleRefresh,
    handleBulkChange,
    handleBulkDelete,
    handleBulkRefresh,
    refreshRow,
    expandedRows,
    isDefaultOrder,
    listOrder,
    handleSort
  } = useScheduleData({
    referModel,
    referOptions,
    referKey,
    idReference,
    filter,
    generalTask,
    setSelectStepModal,
    dateRangeDisplay,
    isMobile,
    setEditModalId,
    defaultOrder
  });

  const _refreshRow = (...params) => {
    if (isDefaultOrder) refreshRow(...params);
  };

  const AddButtonStep = params => (
    <AddTableButton
      {...params}
      text="+ Adicionar etapa"
      model="step"
      type={isMobile() ? 'button' : 'select'}
      modelOptions={stepOptions}
      handleCreate={values => handleAdd({ idStep: values })}
      onCreateStep={setSelectStepModal}
    />
  );

  const _stepList = useMemo(() => {
    return referList?.map(
      l =>
        ({
          ...l.step,
          color: l.color,
          [`${referKey}Step`]: l.id,
          startDate: l.startDate,
          endDate: l.endDate,
          order: l.order,
          canMove: l.step?.id !== 1,
          taskBasedDate: l.taskBasedDate,
          estimativeDuration: l.estimativeDuration,
          realDuration: l.realDuration
        } || [])
    );
  }, [referList]);

  const taskList = useMemo(() => {
    return _stepList?.flatMap(step =>
      step.tasks?.map((task, index) => {
        return { ...task, ...(index + 1 < step.tasks.length && { nextOrder: step.tasks[index + 1].order }) };
      })
    );
  }, [_stepList]);

  const handleSelectAllTasks = (isSelectedAll, taskItems, idStep) => {
    let newSelectedRowKeys = [...selectedRowKeys];
    let newSelectedItems = [...selectedItems];
    const selectedRowKeysObj = {};
    selectedRowKeys.forEach(key => {
      selectedRowKeysObj[key] = true;
    });
    if (isSelectedAll) {
      const keysToRemove = {};
      taskItems.forEach(task => {
        if (selectedRowKeysObj[task?.id]) {
          keysToRemove[task.id] = true;
        }
      });
      newSelectedRowKeys = newSelectedRowKeys.filter(key => !keysToRemove[key]);
      newSelectedItems = newSelectedItems.filter(item => !keysToRemove[item?.id]);
    } else {
      expandedRows.current = [...expandedRows.current, idStep];
      taskItems.forEach(task => {
        if (!selectedRowKeysObj[task?.id]) {
          newSelectedRowKeys.push(task.id);
          newSelectedItems.push(task);
        }
      });
    }
    onSelectItem(newSelectedRowKeys, newSelectedItems);
  };

  const _stepColumns = scheduleColumns({
    isMobile: isMobile(),
    handleChange,
    handleAdd,
    handleRefresh,
    refreshRow,
    priorityList: taskPriority,
    statusList: taskStatus,
    serializedPrioritiesArray,
    serializedStatusArray,
    expandedRows,
    taskStatusEnum,
    userType: user.type,
    [referKey]: idReference,
    onlyTasks: generalTask,
    isApply,
    headerExtraContent: 'teste',
    idCompany,
    taskList,
    isDefaultOrder,
    setLinkTaskModal,
    setCurrentData,
    handleSelectAllTasks,
    selectedRowKeys
  });

  useEffect(() => {
    handleLoadStep();
  }, [listOrder, referOptions, forceLoad]);

  useEffect(() => {
    const object = parseArrayAsObject(taskStatus, true);
    setSerializedStatusArray(object);
  }, [taskStatus]);

  useEffect(() => {
    const object = parseArrayAsObject(taskPriority, true);
    setSerializedPrioritiesArray(object);
  }, [taskPriority]);

  const handleSubmitMoveRow = ({ values, dropRow }) => {
    if (!isApply) {
      const isChildren = !values[`${referKey}Step`];
      const id = values[`${referKey}Step`] || values.id;
      const idStep = isChildren ? { idStep: dropRow.idStep || dropRow.id } : {};
      handleChange({ id, order: values.order, ...idStep }, isChildren, false);
    }
  };

  const onOpenParent = idStep => {
    loadTasks(idStep, { forceReload: true });
  };

  return (
    <>
      <TableOrList
        list={_stepList}
        AddComponent={!isApply ? AddButtonStep : null}
        isAddItemDropdown={false}
        columns={_stepColumns}
        parentKey="idStep"
        childrenColumnName={childrenColumnName}
        disableDropOnParent
        expandedRows={expandedRows}
        moveRowProps={{
          moveList: _stepList,
          submitMove: handleSubmitMoveRow,
          idParentPropName: 'idStep',
          isDefaultOrder
        }}
        rowChildrenSelection={false}
        paddingFirstColumn={16}
        idRefurbish={idReference}
        refresh={handleRefresh}
        ItemModal={EditInfoModal}
        onSort={handleSort}
        onClick={({ id, [childrenColumnName]: childrenList, idStep }) => {
          const isStep = !idStep;

          if (!isStep && !isApply) setEditModalId(id);
          else setEditModalId(null);

          if (isStep && !childrenList && expandedRows?.current?.includes(id)) loadTasks(id);
        }}
        margin="0 0"
        selectedRowKeys={selectedRowKeys}
        selectedItems={selectedItems}
        onSelectItem={onSelectItem}
        onOpenParent={onOpenParent}
        {...props}
      />
      {editModalId && (
        <EditInfoModal
          id={editModalId}
          onClose={() => {
            setEditModalId(null);
            handleRefresh(editModalId);
          }}
          options={{ where: { [referKey]: idReference } }}
          idCompany={idCompany}
          {...{ [referKey]: idReference }}
        />
      )}
      {selectStepModal && (
        <div style={{ display: 'flex', justifyContent: 'space-between', minWidth: 300 }}>
          <SelectDropdown
            placeholder="Selecione uma etapa"
            model="step"
            options={stepOptions}
            onSelect={({ id: idStep }) => handleAdd({ idStep })}
            isOpen
            onClose={() => setSelectStepModal(false)}
            initialFocus={false}
          />
        </div>
      )}
      <PdfTable
        title="Exportar para PDF"
        list={_stepList}
        columns={_stepColumns}
        childrenColumnName={childrenColumnName}
        serializedPrioritiesArray={serializedPrioritiesArray}
        columnsMapping={scheduleColumns}
        serializedStatusArray={serializedStatusArray}
        paddingFirstColumn={8}
        pdfProps={{
          onClose: () => setIsPrintList(false),
          open: isPrintList,
          refurbish,
          name: 'etapas',
          orientationPdf: 'portrait',
          customTip: 'Você pode expandir e recolher as tarefas clicando nas etapas'
        }}
      />
      <TaskBulkActions
        counter={counter}
        selectedRowKeys={selectedRowKeys}
        handleChange={values => handleBulkChange(values, selectedRowKeys)}
        handleDelete={handleBulkDelete}
        handleClose={handleCloseBulkAction}
        idCompany={idCompany}
        selectedTasks={selectedItems}
        handleRefresh={handleBulkRefresh}
        isDefaultOrder={isDefaultOrder}
      />

      {!isMobile() && !isCustomer && !template && (
        <AddTaskModal refurbish={refurbish} filter={filter} onRefresh={handleRefresh} />
      )}
      {linkTaskModal && (
        <LinkTaskModal
          idReference={idReference}
          isTemplate={template}
          onRefresh={_refreshRow}
          row={currentData}
          onClose={() => setLinkTaskModal(false)}
        />
      )}
    </>
  );
};

ScheduleTable.propTypes = {
  idReference: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  referModel: PropTypes.string,
  referKey: PropTypes.string,
  referOptions: PropTypes.instanceOf(Object),
  stepOptions: PropTypes.instanceOf(Object),
  onSelectItem: PropTypes.func,
  generalTask: PropTypes.bool,
  isApply: PropTypes.bool,
  defaultOrder: PropTypes.oneOfType([PropTypes.array]),
  idCompany: PropTypes.number,
  handleCloseBulkAction: PropTypes.func,
  counter: PropTypes.number,
  selectedRowKeys: PropTypes.instanceOf(Array),
  template: PropTypes.bool,
  serializedPriorities: PropTypes.instanceOf(Array),
  forceLoad: PropTypes.number,
  selectedItems: PropTypes.instanceOf(Array),
  dateRangeDisplay: PropTypes.instanceOf(Object)
};

export default ScheduleTable;
