import React from 'react';
import LogoIcon from '../../components/Icons/Logo';
import Payment from './Payment';
import { Header, Container, Content } from './PaymentViewPublic.style';

const PaymentViewPublic = () => {
  return (
    <Container>
      <Header>
        <div>
          <LogoIcon scale={0.6} />
        </div>
      </Header>
      <Content>
        <Payment isView isPublic style={{ maxWidth: '992px', width: '100%' }} />
      </Content>
    </Container>
  );
};

export default PaymentViewPublic;
