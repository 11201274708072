import styled from 'styled-components';
import { spaces, fonts } from '../../styles/style';

import FileUpload from '../File/FileUpload';

const AttachmentsFirstSection = styled.section`
  display: flex;
  flex-direction: column;

  strong {
    font-size: ${fonts.sizeH6};
    font-weight: 400;
  }

  p {
    font-size: ${fonts.sizeMd};
  }

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: ${spaces.space2};

    span {
      font-size: ${fonts.sizeSm};
    }
  }
`;

const UploadButton = styled(FileUpload)`
  margin-top: 0px;
  span {
    width: auto;
  }
`;

export { AttachmentsFirstSection, UploadButton };
