import styled from 'styled-components';
import { colors } from '../../styles/style';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.neutral95};
  overflow: auto;
  height: 100vh;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffff;
  height: 64px;
  width: 100%;
  position: absolute;
  z-index: 999;
  div {
    max-width: 992px;
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 64px;
`;
