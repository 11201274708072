import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarsStaggered, faChevronDown, faChevronUp, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { faFlag, faCheckCircle, faDiagramNested } from '@fortawesome/pro-solid-svg-icons';

import * as dayjs from 'dayjs';

import styled, { css } from 'styled-components';

import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { faExternalLink } from '@fortawesome/pro-light-svg-icons';
import EditableInput from '../../../components/Input/EditableInput';
import ColorPickerDropDown from '../../../components/Dropdown/ColorPickerDropDown';
import StatusSelector from '../../../components/StatusSelector/StatusSelector';

import ColumnWrapper from '../../../components/Table/ColumnWrapper';
import TaskActionDropdown from '../../../components/Dropdown/TaskActionDropdown';
import TooltipIcon from '../../../components/Icons/TooltipIcon';
import MaskedInput from '../../../components/Input/MaskedInput';

import { getInitials, toHHMM } from '../../helpers/helper';
import RangePicker from '../../../components/Datepicker/RangePicker';
import Avatar from '../../../components/AvatarEditor/Avatar';

import { breakpoints, colors, fonts, spaces } from '../../../styles/style';

const StyledButton = styled.button`
  color: ${colors.neutralSecondary40};
  font-size: ${fonts.sizeSm};
  &:hover {
    color: ${colors.neutralSecondary10};
  }
  background: transparent;
  border: 0;
  cursor: pointer;
`;

const StepNameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${fonts.sizeMd};

  span {
    color: ${colors.neutralSecondary40};
    font-size: ${fonts.sizeSm};
  }
`;

const ColorAndNameContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: ${props => props.paddingLeft}px;
`;

const Name = styled.div`
  max-width: 280px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding-left: ${spaces.space0};
  overflow: hidden;
`;

const IconsDiv = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  min-width: 175px;
  margin-left: ${spaces.space1};

  * {
    margin-right: ${spaces.space2};
  }

  svg {
    cursor: pointer;
  }
`;

const ChecklistContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
  color: ${props => props.$color};
`;

const TaskNameContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: ${fonts.sizeSm};

  svg {
    margin-left: ${spaces.space1};
    cursor: pointer;
  }
  .linkedTask {
    display: none;
  }
  &:hover {
    .checkCircle {
      color: ${p => p.$checklistColorHover};
    }
    .linkedTask {
      display: block;
    }
    .renameItem {
      display: block;
    }

    ${props =>
      props.showIconOnHover &&
      css`
        .checkCircle {
          display: block;
        }
      `}
  }


  ${props =>
    props.showIconOnHover &&
    css`
      .checkCircle {
        display: none;
      }
    `}

  @media (max-width: ${breakpoints.tablet}) {
    .checkCircle {
      display: none;
      padding-left: ${spaces.space2};
    }
    padding-left: ${spaces.space2};
    flex: 5;
  }
`;

const validDate = date => {
  if (!date) return '';

  return dayjs(date).isValid()
    ? `${dayjs(date)
        .format('DD MMM YY')
        .toString()}`
    : '';
};

const isChecklistChanged = (prevChecklist, nextChecklist) => {
  if (prevChecklist?.length !== nextChecklist?.length) return true;
  const prevDoneCount = prevChecklist?.filter(item => item.checked)?.length;
  const nextDoneCount = nextChecklist?.filter(item => item.checked)?.length;
  return prevDoneCount !== nextDoneCount;
};

const handleParentName = _row => {
  if (_row?.idParent) {
    return _row.parent?.name;
  }
  return null;
};

const isParent = _row => {
  return _row?.idParent === undefined;
};

const columns = ({
  isMobile,
  handleChange,
  handleAdd,
  handleRefresh,
  onAfterSubmitDropdown,
  statusList,
  priorityList,
  serializedStatusArray,
  serializedPrioritiesArray,
  expandedRows,
  taskStatusEnum,
  userType,
  idRefurbish,
  idTemplate,
  isApply,
  EditDrawer,
  setEditModalId,
  idCompany,
  printColumns = [],
  taskList,
  generalTask,
  isDefaultOrder,
  setLinkTaskModal,
  setCurrentData,
  canChangeRefurbish = false,
  handleSelectAllTasks,
  selectedRowKeys
}) => {
  const isPrint = !!printColumns?.length;
  const isTemplate = !!idTemplate;
  const shouldRenderMoreColumns = window.innerWidth >= breakpoints?.wide?.split('px')[0];
  const allColumns = [
    {
      title: generalTask ? 'Tarefas' : 'Etapas/tarefas',
      dataIndex: 'name',
      key: 'name',
      className: 'col-grow-5',
      renderMobile: true,
      render: (val, row) => {
        const { tasks, taskCount, onClick = f => f, longPressEvent = () => {} } = row;
        if (isPrint) return <div style={{ paddingLeft: '8px' }}>{row?.name || '-'}</div>;

        if (isParent(row)) {
          const _taskCount = tasks != null ? tasks.length : taskCount || 0;
          const icon = expandedRows?.current?.includes(row.id) || row.open ? faChevronUp : faChevronDown;
          const isGeneral = row.id === 1;
          const selectedTasksObj = {};
          if (selectedRowKeys) {
            selectedRowKeys.forEach(key => {
              selectedTasksObj[key] = true;
            });
          }
          const isSelectedAll = tasks?.every(task => selectedTasksObj[task.id]);
          return (
            <StepNameContainer onClick={onClick} key={`step-container-${row.id}`}>
              <ColorAndNameContainer paddingLeft={isGeneral || isMobile ? 20 : null}>
                {!isGeneral && !isMobile ? (
                  <ColorPickerDropDown
                    color={row.color ? row.color : colors.neutralSecondary70}
                    onSelect={value =>
                      handleChange(
                        {
                          id: idTemplate ? row.idTemplateStep : row.idRefurbishStep,
                          color: value
                        },
                        false
                      )
                    }
                  />
                ) : null}
                <Name>{val}</Name>
              </ColorAndNameContainer>
              <IconsDiv>
                {isParent(row) ? (
                  <FontAwesomeIcon icon={icon} color={colors.primary40} />
                ) : (
                  <div style={{ width: 12 }} />
                )}
                {!isApply ? (
                  <Tooltip placement="top" title="Adicionar tarefa">
                    <FontAwesomeIcon
                      icon={faPlus}
                      color={colors.primary40}
                      onClick={e => {
                        e.stopPropagation();
                        handleAdd({ idStep: row.id }, true);
                      }}
                    />
                  </Tooltip>
                ) : null}

                {isGeneral || isApply ? (
                  <div />
                ) : (
                  <TaskActionDropdown
                    id={row.idRefurbishStep}
                    idTemplate={idTemplate}
                    model={idTemplate ? 'template-step' : 'refurbish-step'}
                    row={{ id: idTemplate ? row.idTemplateStep : row.idRefurbishStep }}
                    afterSubmit={submit => handleRefresh(row.id, null, { submit, row })}
                    isRoot
                  />
                )}
                {_taskCount != null ? (
                  <Tooltip
                    title={
                      _taskCount && !idTemplate
                        ? `${isSelectedAll ? 'Desselecionar' : 'Selecionar'} todas as tarefas`
                        : null
                    }
                  >
                    <StyledButton
                      id="teste"
                      type="primary"
                      onClick={e => {
                        e.stopPropagation();
                        _taskCount && !idTemplate && handleSelectAllTasks(isSelectedAll, tasks, row.id);
                      }}
                      text
                    >
                      {_taskCount} tarefa{_taskCount !== 1 && 's'}
                    </StyledButton>
                  </Tooltip>
                ) : null}
              </IconsDiv>
            </StepNameContainer>
          );
        }

        const totalItemsChecklist = row.checklist?.length;
        const totalItemsChecklistDone = row.checklist?.filter(item => item.checked).length;
        const checklistIconColor =
          totalItemsChecklist === totalItemsChecklistDone ? colors.success70 : colors.neutral60;

        const checklistIconColorHover =
          totalItemsChecklist === totalItemsChecklistDone ? colors.success70 : colors.neutralSecondary40;

        return (
          <TaskNameContainer
            key={`task-name-${row.id}`}
            onClick={onClick}
            showIconOnHover={row.status !== 4}
            $checklistColorHover={checklistIconColorHover}
          >
            {isMobile ? (
              <p {...(longPressEvent(row) || {})}>{val}</p>
            ) : (
              <EditableInput
                style={{ alignSelf: 'center' }}
                key={`name${row?.id}`}
                value={val}
                row={row}
                onChange={name => handleChange({ id: row?.id, name }).then(() => handleRefresh(row?.id))}
                disabled={isApply}
                rowId={row.id}
              />
            )}
            {row?.checklist && totalItemsChecklist !== 0 && (
              <Tooltip title="Checklist">
                <ChecklistContainer $color={checklistIconColor}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <FontAwesomeIcon icon={faBarsStaggered} />
                  </div>

                  <div style={{ fontSize: '12px', marginLeft: '3px', fontWeight: 500, paddingTop: '1px' }}>
                    {totalItemsChecklistDone}/{totalItemsChecklist}
                  </div>
                </ChecklistContainer>
              </Tooltip>
            )}
            {!isApply && (
              <TooltipIcon
                style={{ height: 16, width: 16 }}
                className="checkCircle"
                text="Concluir tarefa"
                icon={faCheckCircle}
                iconColor={row.status === taskStatusEnum?.finished?.id ? colors.success70 : colors.neutralSecondary40}
                onClick={e => {
                  e.stopPropagation();
                  return !isApply
                    ? handleChange(
                        { id: row.id, status: taskStatusEnum.finished.id },
                        true,
                        row.status !== taskStatusEnum.finished.id
                      ).then(() => handleRefresh(row.id))
                    : null;
                }}
              />
            )}
            {!generalTask && (
              <TooltipIcon
                style={{ height: 16, width: 16 }}
                className="linkedTask"
                text={handleParentName(row) || 'Vincular tarefa'}
                icon={faDiagramNested}
                iconColor={row.idParent ? colors.primary40 : colors.neutralSecondary40}
                onClick={e => {
                  e.stopPropagation();
                  if (!isApply) {
                    setCurrentData(row);
                    setLinkTaskModal(true);
                  }
                }}
              />
            )}
          </TaskNameContainer>
        );
      },
      shouldCellUpdate: (prevRecord, nextRecord) =>
        prevRecord.name !== nextRecord.name ||
        prevRecord.status !== nextRecord.status ||
        prevRecord.tasks !== nextRecord.tasks ||
        prevRecord.tasks ||
        prevRecord.color !== nextRecord.color ||
        prevRecord.status !== nextRecord.status ||
        isChecklistChanged(prevRecord.checklist, nextRecord.checklist) ||
        !isDefaultOrder ||
        prevRecord.idParent !== nextRecord.idParent ||
        handleParentName(prevRecord) !== handleParentName(nextRecord) ||
        prevRecord.taskCount !== nextRecord.taskCount
    },
    ...(generalTask
      ? [
          {
            title: 'Projeto',
            dataIndex: ['refurbish', 'name'],
            key: 'idRefurbish',
            className: 'col-grow-2',
            sorter: true,
            render: (val, row) => {
              const { id, refurbish } = row;
              if (isPrint) {
                return row?.refurbish?.name;
              }

              return (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <ColumnWrapper
                    value={refurbish}
                    model="refurbish"
                    onSelect={_idRefurbish => {
                      return handleChange({ id, idRefurbish: _idRefurbish }).then(() => handleRefresh(row.id));
                    }}
                    options={{
                      order: [['name']]
                    }}
                    cleanValue={null}
                    canCreate={false}
                  />
                  {refurbish && (
                    <Link
                      to={`/${userType}/projetos/perfil/${refurbish.id}/cronograma`}
                      style={{ margin: spaces.space1 }}
                    >
                      <FontAwesomeIcon icon={faExternalLink} color={colors.primary40} size="xs" />
                    </Link>
                  )}
                </div>
              );
            },
            shouldCellUpdate: false
          }
        ]
      : []),
    ...(generalTask
      ? [
          {
            title: 'Etapa do projeto',
            dataIndex: ['step', 'name'],
            key: 'idStep',
            className: 'col-grow-2',
            sorter: true,
            render: (val, row) => {
              const { id, step } = row;

              if (isPrint) {
                return row?.step?.name;
              }
              return (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <ColumnWrapper
                    value={step}
                    disabled={!row?.refurbish?.id}
                    model={row?.refurbish?.id && 'step'}
                    options={{
                      include: [{ attributes: ['id'], model: 'refurbish', where: { id: row?.refurbish?.id } }],
                      order: [['name']]
                    }}
                    onSelect={_idStep => handleChange({ id, idStep: _idStep }).then(() => handleRefresh(row.id))}
                    cleanValue={1}
                    canCreate={false}
                  />
                </div>
              );
            },
            shouldCellUpdate: false
          }
        ]
      : []),
    {
      title: (
        <div style={{ display: 'flex', justifyContent: 'space-between', minWidth: '100px', marginLeft: '-5px' }}>
          <p>Inicio</p>
          <p>Fim</p>
        </div>
      ),
      dataIndex: 'endDate',
      key: 'endDate',
      className: 'col-grow-2',
      renderMobile: true,
      sorter: true,
      render: (val, row) => {
        const { longPressEvent = () => {} } = row;
        const { id, onClick = f => f } = row;
        const _id = !isParent(row) ? id : row[idTemplate ? 'idTemplateStep' : 'idRefurbishStep'];
        if (isPrint) {
          return (
            <div
              style={{
                marginLeft: row.startDate ? '0' : '60px',
                color: row.isDelayed && 'red'
              }}
              {...(longPressEvent(row) || {})}
            >
              {`${validDate(row.startDate)} - ${validDate(row.endDate)}`}
            </div>
          );
        }
        return id !== 1 ? (
          <div
            key={`endDate-${row.id}`}
            role="presentation"
            onClick={onClick}
            style={{ alignSelf: 'center', ...(isMobile && { flex: '2 1 10%' }) }}
            {...(longPressEvent(row) || {})}
          >
            <RangePicker
              taskCustomCalendar
              size="small"
              format="DD MMM"
              value={[row.startDate ? dayjs(row.startDate) : null, row.endDate ? dayjs(row.endDate) : null]}
              onChange={value =>
                handleChange(
                  { id: _id, startDate: value.start, endDate: value.end, taskBasedDate: value.taskBasedDate },
                  !isParent(row)
                ).then(() => handleRefresh(row.id, null, { row, shouldUpdateDescendants: true }))
              }
              disabled={isMobile || isApply}
              isDelayed={row.isDelayed}
              buttonProps={{ shape: 'text' }}
              isParent={isParent(row)}
              taskBasedDate={row.taskBasedDate}
            />
          </div>
        ) : null;
      },
      shouldCellUpdate: (prevRecord, nextRecord) =>
        prevRecord.startDate !== nextRecord.startDate ||
        prevRecord.endDate !== nextRecord.endDate ||
        prevRecord.isDelayed !== nextRecord.isDelayed
    },
    ...(shouldRenderMoreColumns
      ? [
          {
            title: 'Horas estimadas',
            dataIndex: 'duration',
            key: 'duration',
            className: 'width-110',
            render: (val, row) => {
              if (isPrint || isApply) return <div>{`${toHHMM(row.estimativeDuration) || ''}`}</div>;

              return (
                <MaskedInput
                  id="time-extimated"
                  readOnly={isParent(row)}
                  type="tel"
                  value={row.estimativeDuration}
                  onBlur={value =>
                    handleChange({ id: row.id, estimativeDuration: value }, true, null, {
                      row,
                      shouldUpdateDescendants: true
                    }).then(() => handleRefresh(row.id, null))
                  }
                  style={{ maxWidth: '90px' }}
                />
              );
            },
            shouldCellUpdate: (prevRecord, nextRecord) =>
              prevRecord.estimativeDuration !== nextRecord.estimativeDuration
          }
        ]
      : []),
    ...(shouldRenderMoreColumns
      ? [
          {
            title: 'Horas realizadas',
            dataIndex: 'estimativeDuration',
            key: 'estimativeDuration',
            className: 'width-110',
            render: (val, row) => {
              if (isPrint || isApply) return <span>{`${toHHMM(row.realDuration) || ''}`}</span>;

              return (
                <MaskedInput
                  readOnly={isParent(row)}
                  value={row.realDuration}
                  onBlur={value =>
                    handleChange({ id: row.id, realDuration: value }, true, null, {
                      row,
                      shouldUpdateDescendants: true
                    }).then(() => handleRefresh(row.id, null))
                  }
                  style={{ maxWidth: '90px' }}
                />
              );
            },
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.realDuration !== nextRecord.realDuration
          }
        ]
      : []),
    {
      title: 'Responsável',
      dataIndex: ['user', 'name'],
      key: 'idResponsible',
      className: 'col-grow-1',
      sorter: true,
      render: (val, row) => {
        if (isPrint) {
          return <div>{row?.user?.name || '-'}</div>;
        }
        const { id, user } = row;
        return !isParent(row) ? (
          <ColumnWrapper
            model="user"
            options={{ where: { isActive: true, idCompany }, order: [['name']] }}
            value={user}
            onSelect={idResponsible => handleChange({ id, idResponsible }).then(() => handleRefresh(row.id))}
            cleanValue={null}
            canCreate={false}
            IconSelector={({ item }) => (
              <Avatar size="small" src={item.avatarFullpath}>
                {getInitials(item.name)}
              </Avatar>
            )}
            disabled={isApply}
          >
            {user && (
              <Avatar size="small" src={user?.avatarFullpath}>
                {getInitials(user?.name)}
              </Avatar>
            )}
          </ColumnWrapper>
        ) : null;
      },
      shouldCellUpdate: (prevRecord, nextRecord) =>
        prevRecord.idResponsible !== nextRecord.idResponsible || !isDefaultOrder
    },
    {
      title: 'Prioridade',
      dataIndex: 'priority',
      key: 'priority',
      className: 'col-grow-1',
      renderMobile: true,
      sorter: true,
      render: (val, row) => {
        const { id } = row;
        const { longPressEvent = () => {} } = row;
        const value = serializedPrioritiesArray[val];

        if (isPrint) return <div>{value?.label || '-'}</div>;

        return !isParent(row) ? (
          <>
            {isMobile ? (
              <div style={{ flex: 1 }} {...(longPressEvent(row) || {})}>
                {row?.priority ? <StatusSelector icon={faFlag} status={value} /> : '-'}
              </div>
            ) : (
              <ColumnWrapper
                value={value}
                _key="value"
                onSelect={priority => handleChange({ id, priority }).then(() => handleRefresh(row.id))}
                loadList={priorityList}
                cleanValue={null}
                canCreate={false}
                showClean={false}
                IconSelector={({ item }) => <StatusSelector icon={faFlag} status={item} />}
                disabled={isApply}
              >
                {row?.priority ? <StatusSelector icon={faFlag} status={value} /> : '-'}
              </ColumnWrapper>
            )}
          </>
        ) : null;
      },
      shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.priority !== nextRecord.priority || !isDefaultOrder
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      className: 'col-grow-1',
      renderMobile: true,
      sorter: true,
      render: (val, row) => {
        const { id } = row;
        const { longPressEvent = () => {} } = row;
        const value = serializedStatusArray[val];
        const statusSerialized = serializedStatusArray[val] || val;

        if (isPrint) return <div>{statusSerialized?.label}</div>;
        return !isParent(row) ? (
          <>
            {isMobile ? (
              <div style={{ flex: 1 }} {...(longPressEvent(row) || {})}>
                {row?.status ? <StatusSelector status={value} /> : '-'}
              </div>
            ) : (
              <ColumnWrapper
                value={value}
                _key="value"
                onSelect={status =>
                  handleChange({ id, status }, true, status === taskStatusEnum.finished.id).then(() =>
                    handleRefresh(row.id)
                  )
                }
                loadList={statusList}
                canCreate={false}
                showClean={false}
                IconSelector={({ item }) => <StatusSelector status={item} />}
                disabled={isApply}
              >
                {row?.status ? <StatusSelector status={value} /> : '-'}
              </ColumnWrapper>
            )}
          </>
        ) : null;
      },
      shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.status !== nextRecord.status || !isDefaultOrder
    },
    {
      title: '',
      dataIndex: '',
      key: 'action',
      className: 'col-grow-1',
      renderMobile: true,
      render: (val, row) => {
        const { id } = row;

        if (isPrint) return <div />;
        return !isParent(row) && !isApply ? (
          <div role="presentation" onClick={e => e.stopPropagation()} style={{ ...(isMobile && { flex: 1 }) }}>
            <TaskActionDropdown
              id={id}
              row={{
                ...row,
                ...(generalTask
                  ? { duplicateInLastOrder: true }
                  : { nextOrder: taskList?.find(task => task?.id === row?.id)?.nextOrder })
              }}
              idReference={isTemplate ? idTemplate || row?.idTemplate : idRefurbish || row?.idRefurbish}
              afterSubmit={(resp, task) => {
                if (onAfterSubmitDropdown) return onAfterSubmitDropdown(task);
                return resp && resp.actionType !== 'delete'
                  ? handleRefresh(resp.newId, row.id, { submit: resp })
                  : handleRefresh(row.id, null, { submit: resp });
              }}
              isTemplate={isTemplate}
              editDrawer={EditDrawer}
              setEditModalId={setEditModalId}
              className="action"
              placement="bottomRight"
              generalTask={generalTask || !!idTemplate}
              canChangeRefurbish={canChangeRefurbish}
            />
          </div>
        ) : null;
      }
    }
  ];

  if (isPrint) {
    return allColumns.filter(c => printColumns.includes(c.key) && !c.onlyMobile && c.dataIndex);
  }

  return isMobile ? allColumns.filter(c => c.renderMobile) : allColumns.filter(c => !c.onlyMobile);
};

export { columns };
