import React, { useEffect, useState } from 'react';
import { Switch } from 'antd';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { colors, fonts, spaces } from '../../styles/style';
import Button from '../Button/Button';
import Form from '../Form/Form';

import { CustomTitle, EnableShareContainer, ShareText, StyledModal, SubTitle } from './SharePaymentModal.style';
import { mappingForm, schema } from '../../lib/mapping/Form/sharePaymentSchema';
import useCRUD from '../../_Hooks/useCRUD';
import CenteredLoader from '../Loader/CenteredLoader';

const SharePaymentModal = ({ paymentCreated, paymentId, idRefurbish, onClose, isOpportunity }) => {
  const [inputKey, setInputKey] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refurbish, setRefurbish] = useState({});

  const { handleCreate } = useCRUD({
    model: 'payment',
    pathOptions: '/send-email-share',
    immediatelyLoadData: false
  });
  const { loading: loadingRefurbish, handleGet, handleUpdate } = useCRUD({
    model: 'refurbish',
    immediatelyLoadData: false
  });
  const handleCopyToClipboard = () => {
    const input = document.getElementById('link');
    navigator.clipboard.writeText(input.value);
    toast.success('Link copiado!');
  };

  useEffect(() => {
    handleGet({ refetchPathOptions: `/${idRefurbish}` }).then(resp => setRefurbish(resp));
  }, []);

  const link = `${window.location.origin}/c/${paymentId}`;

  const onSubmit = formData => {
    if (formData.email === '') {
      toast.error('É obrigatório inserir um e-mail!');
      return;
    }
    setLoading(true);
    handleCreate({
      values: {
        paymentId,
        ...formData
      },
      refresh: false,
      displayToast: 'Link enviado com sucesso'
    }).then(resp => {
      setLoading(false);
      if (!resp.error) setInputKey(!inputKey);
      else toast.error('Não foi possível enviar, tente novamente!');
    });
  };

  const handleChange = checked => {
    handleUpdate({
      id: refurbish.id,
      values: {
        itemsToShare: { ...refurbish.itemsToShare, invoice: checked }
      }
    }).then(resp => {
      setRefurbish(resp);
    });
  };
  return (
    <StyledModal
      sectionStyle={{
        display: 'flex',
        flexDirection: 'column',
        paddingleft: spaces.space1,
        paddinRight: spaces.space1,
        marginBottom: `-${spaces.space3}`
      }}
      footer={
        <>
          <span />
          <Button type="text" onClick={() => onClose(false)}>
            Fechar
          </Button>
        </>
      }
      title={
        paymentCreated ? (
          <div>
            <FontAwesomeIcon icon={faCheck} style={{ fontSize: fonts.sizeH6, color: colors.success40 }} />
            <CustomTitle>Cobrança criada com sucesso!</CustomTitle>
          </div>
        ) : (
          'Compartilhar'
        )
      }
      open
      width="544px"
      onClose={() => onClose(false)}
    >
      <SubTitle>Para o seu cliente ter acesso, basta compartilhar o link abaixo.</SubTitle>
      <ShareText>COMPARTILHAR VIA:</ShareText>
      <Form
        id="emailSharingForm"
        data={{ email: '', link }}
        key={inputKey}
        mapping={mappingForm(handleCopyToClipboard, link, loading)}
        schema={schema}
        displayButtons={false}
        onSubmit={onSubmit}
      />
      {loadingRefurbish ? (
        <CenteredLoader />
      ) : (
        <EnableShareContainer>
          {!isOpportunity && refurbish && (
            <>
              <div className="enableShareText">
                <Switch checked={refurbish?.itemsToShare?.invoice} onChange={handleChange} />
                <span>Habilitar cobranças no portal do cliente.</span>
              </div>
              <p>
                Ao habilitar, você permite que o seu cliente veja todos as cobranças no portal do cliente do projeto que
                você compartilhou com ele.
              </p>
            </>
          )}
          <div>
            <Button type="primary" id="knowMoreShareInvoice" text noPadding>
              Saiba mais
            </Button>
          </div>
        </EnableShareContainer>
      )}
    </StyledModal>
  );
};

SharePaymentModal.propTypes = {
  paymentCreated: PropTypes.bool,
  paymentId: PropTypes.string,
  idRefurbish: PropTypes.number,
  onClose: PropTypes.func,
  isOpportunity: PropTypes.bool
};

export default SharePaymentModal;
