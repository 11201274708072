import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledRow = styled.tr`
  min-height: 50px;
  cursor: pointer;
`;

const CommonRow = ({ record = {}, childrenColumnName, className, isExpanded, ...restProps }) => (
  <StyledRow
    key={`row${record?.id}`}
    className={`${className} ${isExpanded ? 'expanded-parent' : ''}`}
    {...restProps}
  />
);

CommonRow.propTypes = {
  record: PropTypes.instanceOf(Object),
  childrenColumnName: PropTypes.string,
  isExpanded: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.instanceOf(Object)
};

export default CommonRow;
