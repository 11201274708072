// TODO: check invalid values
const FormSubmitData = (values, mapping, keepEmpty = true) => {
  let newValues = {};

  Object.keys(values).forEach(key => {
    const { parseFunc } = mapping[key] || {};

    if (!values[key]) {
      newValues = keepEmpty ? { ...newValues, [key]: parseFunc ? parseFunc(values[key]) : values[key] } : newValues;
      return;
    }

    const filterObj = mapping?.find && mapping?.find(f => f.filterName === key);
    if (filterObj?.filterFunc) {
      newValues = { ...newValues, [key]: values[key], ...filterObj.filterFunc(values[key]) };

      return;
    }

    if (parseFunc) {
      newValues = { ...newValues, [key]: parseFunc(values[key]) };

      return;
    }
    const isArray = Array.isArray(values[key]);
    if (values[key] && (isArray || values[key].value || values[key].value === false || values[key].value === 0)) {
      newValues[key] = isArray ? values[key].map(item => item.value || item) : (newValues[key] = values[key].value);

      return;
    }

    newValues[key] = values[key];
  });

  return newValues;
};

export { FormSubmitData };
