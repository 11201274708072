import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import useCRUD from '../_Hooks/useCRUD';
import { saveRefurbish } from '../store/refurbish/actions/action';
import CenteredLoader from '../components/Loader/CenteredLoader';
import { mappingRoutes } from '../lib/mapping/Routes/mappingRoutes';

const RedirectLink = () => {
  const { user } = useSelector(state => state.authReducer) || {};
  const { userType } = useSelector(state => state.setup.enums);
  const isCustomer = user.userType === userType.customer.value;
  const { id, tab } = useParams();
  const history = useHistory();
  const { type } = user || {};
  const {
    location: { pathname }
  } = history;

  const letter = pathname?.split('/')?.[1];

  const { model, childrenPath, queryName, options, isTask, isFolder } = mappingRoutes[letter](user);

  const { data, loading } = useCRUD({
    model,
    pathOptions: `/${id}`,
    options
  });

  const dispatch = useDispatch();

  let idRefurbish = null;

  if ((queryName !== 'arquivo' && queryName !== 'nota') || data?.shared || !isCustomer) {
    idRefurbish = data?.idRefurbish || data?.refurbishes?.[0]?.id;
  }

  useEffect(() => {
    if (!loading && idRefurbish && (data?.refurbish || data?.refurbishes?.[0])) {
      dispatch(saveRefurbish(data?.refurbish || data?.refurbishes?.[0]));
    }
  }, [loading]);

  let userPath;

  if (!loading) {
    if (isCustomer && idRefurbish) {
      userPath = `/cliente/${childrenPath}?${queryName}=${data?.id}${tab ? `&tab=${tab}` : ''}`;
    } else if (!loading && !data && isCustomer) {
      userPath = `/cliente/${childrenPath}${tab ? `?tab=${tab}` : ''}`;
    } else if (!isTask && idRefurbish) {
      userPath = `/profissional/projetos/perfil/${idRefurbish}/${childrenPath}?${queryName}=${data?.id}${
        tab ? `&tab=${tab}` : ''
      }`;
    } else {
      userPath = `/profissional/${childrenPath}?${queryName}=${data?.id}`;
    }
    history.push(userPath);
  }
  if (!loading && !isFolder && ((isTask && !data) || (!idRefurbish && !isTask))) {
    toast.error('Link invalido');
    history.push(`/${type}/dashboard`);
  }
  return <CenteredLoader className="expansive" />;
};

export default RedirectLink;
