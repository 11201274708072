import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faImageLandscape } from '@fortawesome/pro-regular-svg-icons';

import { Gallery, RightContent, EmptyImage, AddImageButton, EmptyText, Grid } from './ProductGallery.styles';
import { colors } from '../../styles/style';

const ProductGallery = ({ images, productName, uploadComponent, onDelete, openImageModalInIndex }) => {
  const { user } = useSelector(state => state.authReducer);
  const { userType } = useSelector(state => state.setup.enums);
  const isCustomer = user.userType === userType.customer.value;

  const secondaryImages = useMemo(() => {
    const [, ...copyOfImages] = images;
    return copyOfImages;
  }, [images]);

  return (
    <Gallery disableFilterOnImageHover={isCustomer}>
      {images?.length ? (
        // eslint-disable-next-line
        <span id="expand-product-image" onClick={() => openImageModalInIndex(0)}>
          <img src={images[0]?.fullpath} alt={productName} />
          {!isCustomer && (
            <FontAwesomeIcon
              icon={faTrashCan}
              color={colors.error60}
              onClick={e => {
                e.stopPropagation();
                onDelete(0);
              }}
            />
          )}
        </span>
      ) : (
        <EmptyImage>
          <FontAwesomeIcon icon={faImageLandscape} size="4x" />
        </EmptyImage>
      )}
      <RightContent>
        {images?.length ? (
          <Grid>
            {secondaryImages.map((image, index) => (
              // eslint-disable-next-line
              <span key={`gallery-image-${index}`} onClick={() => openImageModalInIndex(index + 1)}>
                <img src={image?.fullpath} alt={productName} />
                {!isCustomer && (
                  <FontAwesomeIcon
                    id="remove-product-image"
                    icon={faTrashCan}
                    color={colors.error60}
                    onClick={e => {
                      e.stopPropagation();
                      onDelete(index + 1);
                    }}
                  />
                )}
              </span>
            ))}
          </Grid>
        ) : (
          <EmptyText>
            <p>Esse produto não possui imagem.</p>
          </EmptyText>
        )}
        {!isCustomer && <AddImageButton>{uploadComponent}</AddImageButton>}
      </RightContent>
    </Gallery>
  );
};

ProductGallery.propTypes = {
  images: PropTypes.instanceOf(Array),
  productName: PropTypes.string,
  onDelete: PropTypes.func,
  uploadComponent: PropTypes.instanceOf(Object),
  openImageModalInIndex: PropTypes.func
};

export default ProductGallery;
