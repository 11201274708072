const mappingRoutes = {
  o: props => ({
    model: 'refurbish-items',
    childrenPath: 'orcamento',
    queryName: 'item',
    options: {
      where: { '$refurbish.users.id$': props?.id },
      include: {
        model: 'refurbish',
        include: ['users']
      }
    }
  }),
  a: props => ({
    model: 'file',
    childrenPath: 'arquivos',
    queryName: 'arquivo',
    options: {
      where: { '$refurbishes.users.id$': props?.id },
      include: {
        model: 'refurbish',
        as: 'refurbishes',
        include: ['users']
      }
    }
  }),
  n: props => ({
    model: 'refurbish-notes',
    childrenPath: 'anotacoes',
    queryName: 'nota',
    options: {
      where: { '$refurbish.users.id$': props?.id },
      include: {
        model: 'refurbish',
        include: ['users']
      }
    }
  }),
  t: props => ({
    model: 'task',
    childrenPath: 'produtividade/gestao-tarefas',
    queryName: 'tarefa',
    isTask: true,
    options: {
      where: { idCompany: props?.idCompany },
      include: {
        model: 'refurbish',
        required: false,
        include: ['users']
      }
    }
  }),
  p: props => ({
    model: 'folder',
    childrenPath: 'arquivos',
    queryName: 'pasta',
    isFolder: true,
    options: {
      where: { idCompany: props?.idCompany },
      include: {
        model: 'refurbish',
        required: false,
        include: ['users']
      }
    }
  })
};

export { mappingRoutes };
