import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSearch, faMessageLines, faLock } from '@fortawesome/pro-light-svg-icons';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useHistory } from 'react-router-dom';
import { Pagination } from '@homehero/hero-style';
import { Typography } from 'antd';

// permissions
import { hasPermission } from '../../routes/Common/PrivateRoute';

// Hooks
import useCRUD from '../../_Hooks/useCRUD';
import useViewport from '../../_Hooks/useViewport';

// context
import { useContextHook as useRefurbish, Context as TemplateContext } from '../../contexts/Context';

// components
import CenteredLoader from '../Loader/CenteredLoader';
import NoContent from '../NoContent/NoContent';
import NoTemplates from '../Images/NoTemplates';
import TemplateCard from '../Card/TemplateCard';
import EditOrCreateTemplate from '../../_Pages/Tools/Templates/EditOrCreateTemplate';
import ConfirmModal from './ConfirmModal';
import Button from '../Button/Button';
import { TabPane } from '../Tabs/Tabs';

import { StyledDiv, StyledBtnCard } from '../Card/TemplateCard.styled';
import {
  StyledFooter,
  StyledTabs,
  StyledTemplateList,
  StyledContainerBeforeList,
  TitleContainer
} from './TemplatesModal.styled';
import Modal from './Modal';
import Input from '../Input/Input';
import { colors } from '../../styles/style';
import BubbleModalButton from '../Button/BubbleModalButton';

const { Text } = Typography;

const ITEMS_PAGE = 11;
const PAGINATION = {
  offset: 1,
  limit: ITEMS_PAGE
};

const images = {
  initialState: ({ title, description }) => (
    <NoContent
      title={title || 'Otimize seu tempo'}
      image={<NoTemplates />}
      description={
        description ||
        'Crie seu primeiro template para aplicar nos seus orçamentos, ' +
          'na criação de projetos, cronogramas, tarefas e muito mais!'
      }
      renderButton={false}
    />
  ),
  notFound: ({ keyword }) => {
    const Description = () => (
      <>
        Não foram encontrados resultados para sua busca de <b>{keyword}</b>
      </>
    );
    return <NoContent renderButton={false} title="Oh-Oh..." image={<NoTemplates />} description={<Description />} />;
  }
};

images.initialState.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
};

images.notFound.propTypes = {
  keyword: PropTypes.string
};

const TemplatesModal = ({
  onClose = f => f,
  isRefurbish,
  onSubmitApply,
  isApply = false,
  idRefurbishRow,
  isOpportunity,
  isExistingProject = false,
  title = 'Central de templates'
}) => {
  const { data: refurbish } = useRefurbish();
  const { id: idRefurbish } = refurbish || { id: idRefurbishRow };
  const { templateType, subItemsInitialValue } = useSelector(state => state.setup.enums);
  const [queryString, setQueryString] = useState(PAGINATION);
  const [keyword, setKeyword] = useState('');
  const [tabIndex, setTabIndex] = useState('all');
  const [hasSearched, setHasSearched] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [idApplyTemplate, setIdApplyTemplate] = useState(null);
  const [subItems, setSubItems] = useState({ ...subItemsInitialValue });
  const history = useHistory();
  const { isMobile } = useViewport(window.innerWidth);
  const { user } = useSelector(state => state.authReducer);
  const { plans, permissions } = useSelector(state => state.setup);
  const shouldSearchRef = useRef(false);

  const _hasPermissionTemplate = hasPermission(user, ['template'], plans, permissions);

  const routeChange = () => {
    history.push('/profissional/ferramentas/meus-templates/novo');
  };

  const applyOpportunity = () => {
    onSubmitApply().then(() => {
      toast.success('Parabéns, sua oportunidade foi transformada em projeto!');
      history.push(`/profissional/projetos/perfil/${idRefurbish}/geral`);
    });
  };

  const handleBtnCardClick = () => {
    if (!isRefurbish && !isOpportunity) {
      history.push(`/profissional/ferramentas/meus-templates/novo`);
    }
    if (isOpportunity && isRefurbish) {
      applyOpportunity();
    }
    if (isRefurbish && !isOpportunity) {
      history.push(`/profissional/projetos/novo`);
      onClose(true);
    }
  };

  const onSubmit = item => {
    item[0]?.id &&
      history.push(
        `/profissional/projetos/perfil/${idRefurbish || item[0]?.id}/${isOpportunity ? 'orcamento' : 'geral'}`
      );
    onClose(true);
  };
  const mapTabQuery = {
    all: { onlyMine: null, onlyNull: null },
    vobi: { onlyMine: null, onlyNull: true },
    myTemplates: { onlyMine: true },
    community: {
      where: { community: true, type: isRefurbish ? templateType.refurbish.value : templateType.budget.value }
    }
  };

  const { list, handleCreate, loading, handleGet, totalItems } = useCRUD({
    model: 'template',
    immediatelyLoadData: false
  });

  const handleSearch = () => {
    const { name, where, ..._query } = queryString;
    return handleGet({
      refetchOptions: {
        where: {
          ...where,
          type: isRefurbish ? templateType.refurbish.value : templateType.budget.value,
          name: name ? { like: `%${name}%` } : {}
        },
        order: [
          ['idCompany', 'DESC'],
          ['createdAt', 'DESC']
        ],
        ..._query
      }
    });
  };

  const create = values => {
    return handleCreate({
      values,
      postPathOptions: '/apply',
      displayToast: 'Template aplicado com sucesso',
      refresh: true
    });
  };

  const applyTemplate = () => {
    const _subItems = Object.entries(subItems).reduce((acc, [key, { checked }]) => ({ ...acc, [key]: checked }), {});

    if (isRefurbish && isApply) {
      const promise = isOpportunity ? onSubmitApply() : Promise.resolve();
      return promise
        .then(
          create({
            ..._subItems,
            idTemplate: idApplyTemplate,
            isRefurbish,
            idRefurbish
          })
        )
        .then(history.push(`/profissional/projetos/perfil/${idRefurbish}/geral`));
    }
    return create({
      ..._subItems,
      isRefurbish,
      idTemplate: idApplyTemplate,
      source: 'Template',
      ...(!isRefurbish && { idRefurbish })
    }).then(onSubmit);
  };

  const handleQueryStringChange = e => {
    const { value } = e.target;
    setHasSearched(!!value);
    setKeyword(value);
    setQueryString({ ...queryString, name: value, offset: 1 });
    shouldSearchRef.current = true;
  };

  const handlePagination = page => {
    const newQueryString = { ...queryString, offset: page };
    setQueryString(newQueryString);
  };

  useEffect(() => {
    const { onlyNull, onlyMine, where, ...newQueryString } = queryString;
    shouldSearchRef.current = true;
    setQueryString({ ...newQueryString, ...mapTabQuery[tabIndex] });
  }, [tabIndex]);

  useEffect(() => {
    const timer =
      shouldSearchRef.current &&
      setTimeout(() => {
        handleSearch();
        shouldSearchRef.current = true;
      }, 400);
    return () => clearTimeout(timer);
  }, [queryString]);

  const NoContentInfo = images[!hasSearched ? 'initialState' : 'notFound'];

  const RenderList = () =>
    loading ? (
      <CenteredLoader />
    ) : (
      <>
        {!totalItems ? (
          <NoContentInfo keyword={keyword} />
        ) : (
          <StyledTemplateList>
            {isExistingProject === false && (
              <StyledDiv role="presentation" onClick={() => handleBtnCardClick()}>
                <StyledBtnCard id="start-scratch">
                  <div className="btn-container">
                    <div>
                      <FontAwesomeIcon icon={faPlus} size="2x" color="#0091FF" />
                      <h6>{isOpportunity ? 'Converter em projeto' : 'Começar do zero'}</h6>
                    </div>
                  </div>
                </StyledBtnCard>
              </StyledDiv>
            )}
            {list &&
              list.map(item => (
                <TemplateCard
                  data={item}
                  key={item.id}
                  onSelect={() => {
                    setSelectedItem(item);
                    setSubItems(prev => {
                      const newSubItems = prev;
                      Object.keys(prev).forEach(subItem => {
                        newSubItems[subItem].checked = true;
                      });
                      return newSubItems;
                    });
                  }}
                  onApply={() => setIdApplyTemplate(item.id)}
                  icon={isRefurbish ? 'faFolder' : 'faReceipt'}
                  logo={!item.idCompany}
                />
              ))}
            <StyledFooter>
              <Pagination
                activePage={Number(queryString.offset || 1)}
                itemsCountPerPage={ITEMS_PAGE}
                totalItemsCount={totalItems}
                pageRangeDisplayed={5}
                onChange={handlePagination}
              />
            </StyledFooter>
          </StyledTemplateList>
        )}
      </>
    );

  const RenderButtomCreateTemplate = () => {
    return (
      <StyledContainerBeforeList>
        <div>
          <span style={{ flex: 1, width: '100%', marginRight: 8 }}>
            {isOpportunity // eslint-disable-next-line
              ? 'Para prosseguir, selecione um template com informações já preenchidas ou clique em converter em projeto para começar do zero.'
              : 'Selecione um template ou crie um projeto do zero.'}
          </span>
          <Text id="howTemplateWorks" style={{ color: colors.primary40, cursor: 'pointer' }}>
            Clique aqui para saber mais
          </Text>
        </div>
        {!isMobile() && (
          <div style={{ marginLeft: 'auto' }}>
            {_hasPermissionTemplate ? (
              <Button text type="primary" onClick={() => routeChange()}>
                + Novo template
              </Button>
            ) : (
              <BubbleModalButton feature="template">
                <Button text type="primary">
                  <FontAwesomeIcon icon={faLock} /> Novo template
                </Button>
              </BubbleModalButton>
            )}
          </div>
        )}
      </StyledContainerBeforeList>
    );
  };

  return (
    <Modal
      open
      noPadding
      title={
        <>
          <div style={{ display: 'flex' }}>
            {selectedItem && (
              <FontAwesomeIcon
                icon={faArrowLeft}
                onClick={() => setSelectedItem(null)}
                style={{ cursor: 'pointer', marginRight: 8 }}
              />
            )}
            <TitleContainer>
              {isOpportunity ? 'Parabéns! Sua oportunidade será convertida em projeto!' : title}
            </TitleContainer>
            {!isMobile() && (
              <Button
                text
                id="sendFeedback"
                type="primary"
                style={{ fontSize: 12, position: 'absolute', top: 12, right: 72 }}
              >
                <FontAwesomeIcon style={{ marginRight: '8px' }} icon={faMessageLines} /> Nos dê um feedback
              </Button>
            )}
          </div>
        </>
      }
      onClose={() => onClose(true)}
      width={isMobile() ? '100%' : '80%'}
      height={isMobile() ? '94%' : '90%'}
      hideFooter
    >
      {selectedItem ? (
        <TemplateContext model="template" immediatelyLoadData={false}>
          <EditOrCreateTemplate
            idApply={selectedItem.id}
            setConfirmApply={setIdApplyTemplate}
            community={selectedItem.community}
            subItemsProps={isRefurbish ? { subItems, setSubItems } : null}
          />
        </TemplateContext>
      ) : (
        <StyledTabs
          activeKey={tabIndex}
          onChange={setTabIndex}
          tabBarExtraContent={
            <>
              <Input
                prefix={<FontAwesomeIcon icon={faSearch} style={{ marginRight: 8 }} />}
                id="keyword"
                name="keyword"
                placeholder="Procurar um template"
                onChange={handleQueryStringChange}
                value={keyword}
              />
            </>
          }
          style={{ padding: '0 16px' }}
        >
          <TabPane key="all" tab="Todos">
            <RenderButtomCreateTemplate />
            <RenderList />
          </TabPane>
          <TabPane key="vobi" tab="Vobi">
            <RenderButtomCreateTemplate />
            <RenderList />
          </TabPane>
          {_hasPermissionTemplate ? (
            <TabPane key="myTemplates" tab="Meus templates">
              <RenderButtomCreateTemplate />
              <RenderList />
            </TabPane>
          ) : (
            <TabPane
              disabled
              tab={
                <BubbleModalButton feature="template">
                  <div>
                    Meus templates <FontAwesomeIcon icon={faLock} />
                  </div>
                </BubbleModalButton>
              }
            />
          )}
          <TabPane key="community" tab="Comunidade">
            <RenderButtomCreateTemplate />
            <RenderList />
          </TabPane>
        </StyledTabs>
      )}
      {idApplyTemplate && (
        <ConfirmModal
          text={`Ao aplicar este template,
           todos os itens que fazem parte das abas escolhidas serão adicionados ao seu projeto.
           Você poderá alterar livremente todos esses itens sem que o template seja afetado e também não
           afetará outros projetos.`}
          onSubmit={applyTemplate}
          onClose={() => setIdApplyTemplate(null)}
        />
      )}
    </Modal>
  );
};

TemplatesModal.propTypes = {
  onClose: PropTypes.func,
  isRefurbish: PropTypes.bool,
  onSubmitApply: PropTypes.func,
  isOpportunity: PropTypes.bool,
  idRefurbishRow: PropTypes.number,
  isApply: PropTypes.bool,
  isExistingProject: PropTypes.bool,
  title: PropTypes.string
};

export default TemplatesModal;
