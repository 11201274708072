import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Typography, Checkbox, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';

import UseOutsideClick from '../../../_Hooks/useOutsideClick';

import { colors, spaces } from '../../../styles/style';

const { Text } = Typography;

const SubItemsTemplateMenu = ({ onClose, initialItems = {} }) => {
  const [items, setItems] = useState(initialItems);

  const ref = useRef(null);

  UseOutsideClick(ref, () => {
    onClose(items);
  });

  return (
    <div ref={ref}>
      {Object.entries(items).map(([key, { checked, label, parent, tooltip }]) => (
        <div
          onClick={() => {
            setItems(prev => {
              const itemToChange = items[key];
              itemToChange.checked = !checked;
              const newSubItems = { ...prev, [key]: itemToChange };

              if (parent && itemToChange.checked) {
                newSubItems[parent].checked = true;
              }

              return newSubItems;
            });
          }}
          role="presentation"
          key={key}
          style={{ padding: spaces.space1, ...(parent && { margin: spaces.space1 }) }}
        >
          <Checkbox checked={checked} />
          <Text style={{ margin: `0 ${spaces.space1}` }}>{label}</Text>
          {tooltip && (
            <Tooltip placement="bottom" title={tooltip}>
              <FontAwesomeIcon icon={faQuestionCircle} color={colors.primary40} />
            </Tooltip>
          )}
        </div>
      ))}
    </div>
  );
};

SubItemsTemplateMenu.propTypes = {
  onClose: PropTypes.func,
  initialItems: PropTypes.instanceOf(Object)
};

export default SubItemsTemplateMenu;
