import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import styled, { css } from 'styled-components';
import AnalyticsServiceFactory from '../../lib/analytics/analytics';
import Button from './Button';

const StyledCopyButton = styled(Button)`
  ${props =>
    props.$underline &&
    css`
      &:hover {
        text-decoration: underline !important;
      }
    `}
  ${props =>
    props.$height &&
    css`
      height: ${props.$height};
    `}
`;

const CopyLinkButton = ({
  onClose = () => {},
  eventButton,
  customEvent,
  urlContext,
  idData,
  customUrl,
  underline,
  height,
  ...params
}) => {
  const analytics = AnalyticsServiceFactory();

  const handleCopyLink = () => {
    analytics.track({ event: customEvent || `project-${eventButton}-copy-link` });
    const urlToCopy = customUrl || `${window.location.origin}/${urlContext}/${idData}`;
    navigator.clipboard.writeText(urlToCopy);
    toast.success('Link copiado');
  };
  return (
    <StyledCopyButton
      padding="8px 24px"
      {...params}
      $underline={underline}
      $height={height}
      onClick={() => {
        handleCopyLink();
        onClose();
      }}
    />
  );
};

CopyLinkButton.propTypes = {
  onClose: PropTypes.func,
  eventButton: PropTypes.string,
  urlContext: PropTypes.string,
  idData: PropTypes.number,
  customUrl: PropTypes.string,
  underline: PropTypes.bool,
  height: PropTypes.string,
  customEvent: PropTypes.string
};

export default CopyLinkButton;
