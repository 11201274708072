import React, { useMemo, useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import fetchCEP from 'cep-promise';
import { Label, Form as FormComponent, Button } from '@homehero/hero-style';
import { Col, Row, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import useFormState from '../../_Hooks/useFormState';
import FormItemComponentCreator from './FormItemCreator';
import { FormSubmitData } from '../../lib/helpers/formFunctions';
import { colors, spaces } from '../../styles/style';

const StyledLabel = styled.div`
  margin-bottom: ${spaces.space0};
`;
const WarningCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-top: 10px;
  min-height: 20px;
  > span {
    font-weight: 500;
    color: red;
  }
`;

const Form = ({
  data = null,
  schema,
  mapping,
  setTabIndex = f => f,
  id = '',
  loading = false,
  onSubmit,
  displayButtons = true,
  create = false,
  margin = '8px 0',
  marginTop = '0',
  displayLabel = true,
  forceUpdate = { current: false },
  onlyContent,
  keepOldValues,
  children = null,
  customButtonTitle,
  small,
  saveWarningMessage = '',
  initialParentValues = {},
  cleanForm,
  ...props
}) => {
  const [parentValues, setParentValues] = useState(initialParentValues);
  const mappingObj = mapping.constructor === Object ? mapping : mapping(data || {});
  const mappingKeys = Object.keys(mappingObj);
  const _data = Object.fromEntries(mappingKeys.map(e => [e, mappingObj[e]?.defaultValue || null]));
  const initialState = { ..._data, ...data };
  const { formState, handleChange, handleBlur, handleSubmit, setField, setValues, valuesChanged, reset } = useFormState(
    initialState,
    schema
  );
  const { values, touched, errors } = formState;
  const buttonTitle = create ? 'Enviar' : 'Salvar';

  useEffect(() => {
    if (errors && Object.keys(touched).length) setTabIndex(0);
  }, [errors]);

  const handleChangeSelect = (key, selectValue, multiple, hasChildren) => {
    if (hasChildren) setParentValues(prev => ({ ...prev, [key]: selectValue }));
    if (multiple && selectValue) return setField(key)([...selectValue]);
    return setField(key)(selectValue);
  };

  const handleChangeZipcode = field => e => {
    const zipcode = e.target.value;
    if (zipcode && zipcode.replace(/_/g, '').length >= 9) {
      fetchCEP(zipcode).then(resp =>
        setValues({
          ...values,
          state: resp?.state || resp?.uf,
          city: resp.city,
          neighborhood: resp.neighborhood,
          street: resp.street,
          [field]: resp.cep
        })
      );
    }
  };

  const handleRemoveImage = field => index => {
    const newArr = [...(Array.isArray(values[field]) ? values[field] : [values[field]])];
    newArr.splice(index, 1);
    if (newArr.length > 0) {
      setField(field)(newArr);
    } else {
      setField(field)(null);
    }
  };

  useEffect(() => {
    if (forceUpdate.current) {
      // eslint-disable-next-line no-param-reassign
      forceUpdate.current = false;
      const newData = keepOldValues ? { ...values, ...data } : data;
      setValues(newData);
    }
  }, [data, forceUpdate.current]);

  const handleSubmitForm = () => {
    if (cleanForm) {
      reset(initialState);
    }
    return onSubmit(FormSubmitData(values, mappingObj));
  };

  const _mapping = useMemo(() => (mapping.constructor === Object ? mapping : mapping(values)), [data, mapping, values]);
  const FormOrContent = useMemo(() => (onlyContent ? ({ children: _children }) => _children : FormComponent), [
    onlyContent
  ]);

  return (
    <FormOrContent id={id} onSubmit={handleSubmit(handleSubmitForm)} {...props}>
      <Row gutter={16}>
        {Object.keys(_mapping).map(property => {
          const { value, name = '', xs, md, sm, offset, options, style, tooltipText, type, model } = _mapping[property];
          const formValue = value
            ? value.reduce((acc, valueProperty) => `${acc ? `${acc}&` : acc}${values[valueProperty] || 0}`, '')
            : values[type === 'string' && model ? model : property];
          const nameOrLabel = options?.find(o => (o.id || o.value) === Number(formValue));
          const disableValue = nameOrLabel?.name || nameOrLabel?.label || null;
          const handleFunctions = {
            setField,
            handleChange,
            handleRemoveImage,
            handleBlur,
            handleChangeSelect,
            handleChangeZipcode
          };
          return (
            <Col
              key={property}
              xs={xs || 24}
              md={md || 12}
              sm={sm || 12}
              offset={offset}
              style={{ margin: !offset && margin, ...style }}
            >
              {displayLabel && (
                <StyledLabel>
                  <Label name={property}>{name}</Label>
                  {tooltipText && (
                    <Tooltip placement="bottom" title={tooltipText}>
                      <FontAwesomeIcon
                        style={{ marginLeft: spaces.space1 }}
                        color={colors.primary40}
                        icon={faInfoCircle}
                      />
                    </Tooltip>
                  )}
                </StyledLabel>
              )}
              <FormItemComponentCreator
                formId={id}
                key={`item${property}`}
                mapping={_mapping}
                disableValue={disableValue}
                formValue={formValue}
                property={property}
                handleFunctions={handleFunctions}
                small={small}
                parentValues={parentValues}
              />
              {touched[property] && errors[property] && (
                <small style={{ color: colors.error50 }}>{errors[property][0]}</small>
              )}
            </Col>
          );
        })}
        {children && <div style={{ padding: 8 }}>{children}</div>}
      </Row>

      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '30px', marginTop }}>
        {saveWarningMessage && valuesChanged && (
          <Row justify="start">
            <WarningCol>
              <span>{saveWarningMessage}</span>
            </WarningCol>
          </Row>
        )}

        {displayButtons && !onlyContent && (
          <Row justify="end">
            <Col>
              <Button type="primary" loading={loading} submit>
                {customButtonTitle || buttonTitle}
              </Button>
            </Col>
          </Row>
        )}
      </div>
    </FormOrContent>
  );
};

Form.propTypes = {
  data: PropTypes.instanceOf(Object),
  mapping: PropTypes.instanceOf(Object).isRequired,
  schema: PropTypes.instanceOf(Object).isRequired,
  id: PropTypes.string,
  margin: PropTypes.string,
  loading: PropTypes.bool,
  create: PropTypes.bool,
  customButtonTitle: PropTypes.string,
  displayLabel: PropTypes.bool,
  displayButtons: PropTypes.bool,
  setTabIndex: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  forceUpdate: PropTypes.instanceOf(Object),
  children: PropTypes.instanceOf(Object),
  onlyContent: PropTypes.bool,
  keepOldValues: PropTypes.bool,
  small: PropTypes.bool,
  saveWarningMessage: PropTypes.string,
  marginTop: PropTypes.string,
  initialParentValues: PropTypes.instanceOf(Object),
  cleanForm: PropTypes.bool
};

export default Form;
