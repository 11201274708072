import React from 'react';
import classNames from 'classnames';
import { Heading, ShapeAction } from '@homehero/hero-style';
import PropTypes from 'prop-types';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useSelector } from 'react-redux';
import css from './NotesHeader.module.scss';

const NotesHeader = ({ counter, onClick, isApply }) => {
  const { user } = useSelector(state => state.authReducer);
  const { userType } = useSelector(state => state.setup.enums);
  const isCustomer = user.userType === userType.customer.value;
  const classes = classNames({
    [css['notes-list-header']]: true
  });

  return (
    <div className={classes}>
      <Heading level={3} title={`${counter} Nota${counter > 1 ? 's' : ''}`} />
      {!isApply && !isCustomer ? (
        <ShapeAction id="new-note-icon" type="primary" shape="squared" size={1} isButton onClick={onClick}>
          <FontAwesomeIcon icon={faPlus} />
        </ShapeAction>
      ) : null}
    </div>
  );
};

NotesHeader.propTypes = {
  counter: PropTypes.number,
  onClick: PropTypes.func,
  isApply: PropTypes.bool
};

NotesHeader.defaultProps = {
  counter: 0,
  onClick: f => f
};

export default NotesHeader;
