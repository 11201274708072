import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import Button from '../Button/Button';
import MobileModal from '../Modal/Modal';
import useViewport from '../../_Hooks/useViewport';
// style
import style from './FilterButton.module.scss';
import { breakpoints, colors, spaces } from '../../styles/style';

const StyledButton = styled(Button)`
  @media (max-width: ${breakpoints.tablet}) {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
`;

const StyledDiv = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  ${props =>
    props.position === 'bottom-left' &&
    css`
      right: -100%;
      left: auto;
    `}
  height: auto;
  background-color: ${colors.white};
  padding: ${spaces.space2};
  border-radius: ${spaces.space1};
  box-shadow: 0 ${spaces.space1} ${spaces.space2} 0 rgb(0 0 0 / 8%);
  z-index: 9999;
`;

const FilterButton = ({ text = 'Button', children, outline = true, closeOnClick, position = 'bottom-right' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isMobile } = useViewport(window.innerWidth);

  const handleClick = () => {
    if (closeOnClick && isOpen === true) {
      setIsOpen(false);
    }
  };

  const handleBlur = event => {
    if (!event.currentTarget.contains(event.relatedTarget) && !isMobile()) {
      setIsOpen(false);
    }
  };

  const classes = classNames({
    [style['filter-button']]: true,
    [style['filter-button--outline']]: !outline
  });

  const Modal = () =>
    Array.isArray(children)
      ? children
      : React.Children.map(children, child => {
          return React.cloneElement(child, {
            closeModal: () => setIsOpen(false)
          });
        });

  return (
    <div className={classes} onBlur={handleBlur} role="button" tabIndex={0}>
      <StyledButton text onClick={() => setIsOpen(!isOpen)}>
        {text} {!isMobile() && <FontAwesomeIcon icon={!isOpen ? faCaretDown : faCaretUp} />}
      </StyledButton>
      {isOpen && (
        <>
          <MobileModal open={isMobile()} hideFooter title="Filtro" onClose={() => setIsOpen(false)}>
            {children}
          </MobileModal>
          <div className="hideMobile">
            <StyledDiv role="presentation" position={position} onClick={handleClick}>
              <Modal />
            </StyledDiv>
          </div>
        </>
      )}
    </div>
  );
};

FilterButton.propTypes = {
  text: PropTypes.string,
  children: PropTypes.instanceOf(Object),
  outline: PropTypes.bool,
  closeOnClick: PropTypes.bool,
  position: PropTypes.string
};

export default FilterButton;
