import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import Input from '../Input/Input';
import EditableInput from '../Input/EditableInput';
import Button from '../Button/Button';
import { colors } from '../../styles/style';

const Checklist = ({ list = [], onChange }) => {
  const handleChange = (item, key, value) => {
    const itemIndex = list.findIndex(_item => _item === item);
    const newList = list;
    newList[itemIndex][key] = value;
    onChange(newList);
  };

  const createItem = () => {
    const newItem = { title: '', checked: false };
    const newList = list;
    newList.push(newItem);
    onChange(newList);
  };

  const removeItem = item => {
    const itemIndex = list.findIndex(_item => _item === item);
    const newList = list;
    newList.splice(itemIndex, 1);
    onChange(newList);
  };

  return (
    <List
      dataSource={list}
      locale={{ emptyText: 'Nenhum item' }}
      renderItem={(item, index) => (
        <List.Item key={index}>
          <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
            <Input
              style={{ width: '20px', minWidth: '20px', height: '20px', marginRight: 8 }}
              type="checkbox"
              checked={item.checked}
              onChange={e => handleChange(item, 'checked', e.target.checked)}
            />
            <EditableInput value={item.title} onChange={value => handleChange(item, 'title', value)} initEdit={false} />
          </div>
          <FontAwesomeIcon icon={faTrash} color={colors.error50} onClick={() => removeItem(item)} />
        </List.Item>
      )}
      footer={
        <Button id="add-item" text style={{ color: colors.primary40 }} onClick={createItem}>
          + Adicionar item
        </Button>
      }
    />
  );
};

Checklist.propTypes = {
  list: PropTypes.instanceOf(Array),
  onChange: PropTypes.func
};

export default Checklist;
