import styled, { css } from 'styled-components';
import { Select as AntSelect } from 'antd';

import { colors, fonts } from '../../styles/style';

const SelectStyled = styled(AntSelect)`
  text-align: initial;
  width: 100%;

  .ant-select-selector {
    box-shadow: none !important;
    border-color: ${colors.neutral60} !important;

    &:hover,
    &:focus {
      border-color: ${colors.neutralSecondary40} !important;
    }
  }

  &:not(.ant-select-customize-input) .ant-select-selector {
    height: ${props => (props.height ? `${props.height}px` : '32px')};
    align-items: center;
  }

  ${props =>
    props.$dark &&
    css`
      .ant-select-selection-search-input {
        color: #fff !important;
      }

      .ant-select-selector {
        background-color: ${colors.neutral20} !important;
        color: #fff !important;
        border: 1px solid #fff !important;
        box-shadow: none !important;
      }

      .ant-select-arrow {
        color: #fff;
      }

      .ant-select-clear {
        background-color: ${colors.neutral20};
        color: #fff;
      }
    `}
`;

const SelectDebouceStyled = styled(AntSelect)`
  width: 100%;

  .ant-select-selector {
    box-shadow: none !important;
    border-color: ${colors.neutral60} !important;

    &:hover,
    &:focus {
      border-color: ${colors.neutralSecondary40} !important;
    }

    &.ant-select-item-option-content {
      font-size: ${fonts.sizeXs};
    }
  }
`;

export { SelectStyled, SelectDebouceStyled };
