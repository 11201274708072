import React, { memo, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import dayjs from 'dayjs';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { useHistory } from 'react-router';
import TooltipIcon from '../Icons/TooltipIcon';
import useCRUD from '../../_Hooks/useCRUD';

import useViewport from '../../_Hooks/useViewport';

import { getColorByStatusPayment } from '../../lib/helpers/helper';
import formatCurrency from '../../lib/helpers/formatCurrency';
import Drawer from './Drawer';
import {
  StyledData,
  StyledInstallmentData,
  StyledLabel,
  StyledRow,
  StyledFooter
} from './EditInstallmentDrawer.styles';
import ItemAttachmentContent from '../Content/ItemAttachmentContent';
import { colors, spaces } from '../../styles/style';
import Button from '../Button/Button';
import RegisterInstallmentModal from '../Modal/RegisterInstallmentModal';
import ConfirmModal from '../Modal/ConfirmModal';
import { getPaymentLabel, getViewPaymentUrl } from '../../lib/helpers/payment';

const formId = 'drawer-installment-form';

const EditInstallmentDrawer = ({ idRefurbish, onClose = f => f, id }) => {
  const { user } = useSelector(state => state.authReducer);
  const { userType } = useSelector(state => state.setup.enums);
  const [registerPayment, setRegisterPayment] = useState(false);
  const [files, setFiles] = useState([]);
  const [shouldReload, setShouldReload] = useState(false);
  const isCustomer = user.userType === userType.customer.value;
  const [installmentData, setInstallmentData] = useState();

  const [showConfirmPendingModal, setShowConfirmPendingModal] = useState(false);
  const { installmentStatuses, billType: billTypeEnum } = useSelector(state => state.setup.enums);
  const history = useHistory();

  const { data, handleUpdate } = useCRUD({
    pathOptions: `/${id}`,
    options: {
      where: { idRefurbish },
      include: ['paymentType', 'installmentStatus', 'files', { model: 'payment', include: 'paymentTypes' }]
    },
    model: 'installment'
  });

  const pendingPayment = installmentData?.idInstallmentStatus === installmentStatuses?.pendingPayment;

  const { isMobile } = useViewport(window.innerWidth);

  const statusColor = getColorByStatusPayment(installmentData?.installmentStatus?.id, installmentStatuses);

  const label = getPaymentLabel({ ...installmentData?.payment, billTypeEnum });
  const path = getViewPaymentUrl({ ...installmentData?.payment, billTypeEnum });

  const submitData = installment => {
    handleUpdate({
      values: installment,
      updatePathOptions: `/${installmentData.id}`
    }).then(() => onClose(shouldReload));
  };

  const handleConfirmPending = () => {
    handleUpdate({
      updatePathOptions: `/${installmentData.id}`,
      values: { idInstallmentStatus: installmentStatuses.pendingPayment, paidDate: null, idPaymentType: null }
    });
  };

  const handleConfirmPayment = updateData => {
    handleUpdate(updateData).then(() => {
      setShouldReload(true);
      setRegisterPayment(false);
    });
  };

  useEffect(() => {
    if (data && data.id) {
      setFiles(data.files || []);
      setInstallmentData(data);
    }
  }, [data]);

  const footer = useMemo(() => {
    return (
      <StyledFooter>
        <Button
          id="close-drawer"
          type={isCustomer ? 'primary' : 'text'}
          onClick={() => onClose(shouldReload)}
          noPadding={!isCustomer && isMobile()}
        >
          Fechar
        </Button>
        {!isCustomer && (
          <>
            <Button
              id="open-register-installment"
              htmlType="submit"
              type="primary"
              form={formId}
              onClick={() => (pendingPayment ? setRegisterPayment(true) : setShowConfirmPendingModal(true))}
              noPadding={isMobile()}
            >
              {pendingPayment ? 'Marcar como pago' : 'Voltar para aguardando'}
            </Button>
            <Button
              id="submit-task"
              htmlType="submit"
              type="primary"
              onClick={() => submitData({ ...data, files })}
              form={formId}
              noPadding={isMobile()}
            >
              Salvar
            </Button>
          </>
        )}
      </StyledFooter>
    );
  }, [data, files]);

  const isDefeated =
    dayjs(installmentData?.dueDate).isBefore(dayjs(new Date()), 'day') &&
    installmentData?.idInstallmentStatus === installmentStatuses?.pendingPayment;

  return (
    installmentData && (
      <>
        <Drawer
          zIndex={!isMobile() && 100}
          open
          title="Detalhes da parcela"
          subTitle={`#${installmentData.id}`}
          onClose={() => onClose(shouldReload)}
          formId={formId}
          width={isMobile() ? '100%' : 604}
          footer={footer}
        >
          <>
            <StyledInstallmentData>
              <StyledRow alignLeft>
                <StyledLabel>Descrição:</StyledLabel>
                <StyledData>{installmentData?.description}</StyledData>
              </StyledRow>
              <StyledRow>
                <StyledLabel>Status da parcela:</StyledLabel>
                <StyledData style={{ color: isDefeated ? colors?.error60 : statusColor }}>
                  {isDefeated ? 'Vencido' : installmentData?.installmentStatus?.name}
                </StyledData>
              </StyledRow>
              <StyledRow>
                <StyledLabel>Valor da parcela:</StyledLabel>
                <StyledData>{formatCurrency(installmentData?.price, { currencySymbol: 'R$' })}</StyledData>
              </StyledRow>
              <StyledRow>
                <StyledLabel>Vencimento da parcela:</StyledLabel>
                <StyledData>{dayjs(installmentData?.dueDate).format('DD/MM/YYYY')}</StyledData>
              </StyledRow>
              <StyledRow extraPadding>
                <StyledLabel>Formas de pagamento:</StyledLabel>

                <StyledData>
                  {installmentData?.payment?.paymentTypes?.map((type, index) => {
                    const arrLength = installmentData?.payment?.paymentTypes.length;
                    const divider = arrLength === 1 || index === arrLength - 1 ? '' : ', ';
                    return `${type?.name}${divider}` || '';
                  })}
                </StyledData>
              </StyledRow>
              <StyledRow>
                <StyledLabel>Data de pagamento:</StyledLabel>
                <StyledData>
                  {installmentData?.paidDate ? dayjs(installmentData?.paidDate).format('DD/MM/YYYY') : ''}
                </StyledData>
              </StyledRow>
              <StyledRow extraPadding>
                <StyledLabel>Pago com:</StyledLabel>
                <StyledData>{installmentData?.paymentType?.name || ''}</StyledData>
              </StyledRow>
              <StyledRow>
                <StyledLabel>Nome da {label}:</StyledLabel>
                <span>
                  <StyledData>{installmentData?.payment?.name}</StyledData>
                  {!isCustomer && (
                    <TooltipIcon
                      onClick={() => history.push(path)}
                      text={`Ver ${label}`}
                      style={{ color: colors.primary40, paddingLeft: spaces.space2 }}
                      icon={faArrowUpRightFromSquare}
                    />
                  )}
                </span>
              </StyledRow>
              <StyledRow extraPadding>
                <StyledLabel>Id da {label}:</StyledLabel>
                <StyledData>{`#${installmentData?.payment?.id}`}</StyledData>
              </StyledRow>
            </StyledInstallmentData>
            <ItemAttachmentContent
              data={{ files }}
              readOnly={isCustomer}
              idRefurbish={idRefurbish}
              setFiles={setFiles}
              textEditor={false}
              hasDescription={false}
              maxSize="10mb"
            />
          </>
        </Drawer>
        {registerPayment && (
          <RegisterInstallmentModal
            onClose={() => {
              setRegisterPayment(false);
            }}
            installment={installmentData}
            onSubmit={(_, reload) => {
              setShouldReload(reload);
              setRegisterPayment(false);
            }}
            handleConfirmPayment={handleConfirmPayment}
          />
        )}
        {showConfirmPendingModal && (
          <ConfirmModal
            text="Deseja voltar a parcela para status 'Aguardando'?"
            onSubmit={(_, reload) => {
              handleConfirmPending();
              setShouldReload(reload);
              setShowConfirmPendingModal(false);
            }}
            onClose={() => setShowConfirmPendingModal(false)}
          />
        )}
      </>
    )
  );
};

EditInstallmentDrawer.propTypes = {
  idRefurbish: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default memo(EditInstallmentDrawer);
