import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Space, Typography } from 'antd';
import CenteredLoader from '../../../components/Loader/CenteredLoader';
import { Columns, Content } from './Onboarding.styled';
import useCRUD from '../../../_Hooks/useCRUD';
import Input from '../../../components/Input/Input';
import Image from '../../../components/Images/firstProject.svg';
import NavigationButton from '../../../components/Button/NavigationButton';

const { Text } = Typography;

const FirstProject = ({ onReturn, onSubmit }) => {
  const [name, setName] = useState();
  const { loading, handleCreate } = useCRUD({
    model: 'refurbish',
    immediatelyLoadData: false
  });

  const handleSubmit = () => {
    handleCreate({ values: { name, idStatus: 5 }, refresh: false }).then(resp => {
      if (!resp?.error) onSubmit();
    });
  };

  return (
    <Content>
      <Columns>
        <div className="stepColumn">
          <Space size={40} direction="vertical">
            <Space size={12} direction="vertical">
              <h6>Crie o seu primeiro projeto</h6>
              <Text>
                Informe um nome para algum projeto seu que está em <br /> andamento ou siga nossa sugestão.
              </Text>
            </Space>
            {loading ? (
              <CenteredLoader />
            ) : (
              <div>
                <Text>
                  <b>Nome do projeto</b>
                </Text>
                <br />
                <br />
                <Input
                  type="text"
                  placeholder="Ex: Projeto da Ana"
                  onChange={e => setName(e?.target?.value)}
                  style={{ width: 200 }}
                />
              </div>
            )}
          </Space>
          <NavigationButton onSubmit={handleSubmit} onReturn={onReturn} canSubmit={!!name} $fullWidth />
        </div>
        <div className="secondColumn">
          <img src={Image} alt="Imagem" />
        </div>
      </Columns>
    </Content>
  );
};

FirstProject.propTypes = {
  onReturn: PropTypes.func,
  onSubmit: PropTypes.func
};

export default FirstProject;
