import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';

// hooks
import useCRUD from '../../_Hooks/useCRUD';

// components
import Select from './Select';

const UpdateDataStatus = ({ id, field, value, model, dataType, otherValues }) => {
  const [currentStatus, setCurrentStatus] = useState(value);

  const { handleUpdate, loading } = useCRUD({
    model,
    pathOptions: `/${id}`,
    immediatelyLoadData: false
  });

  const updateStatus = status => setCurrentStatus(status);

  const handleChange = _value =>
    handleUpdate({ values: { [field]: _value, ...otherValues }, refresh: false }).finally(() => updateStatus(_value));

  return <Select dataType={dataType} value={currentStatus} onChange={handleChange} size="small" disabled={loading} />;
};

UpdateDataStatus.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  field: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  model: PropTypes.string,
  dataType: PropTypes.string,
  otherValues: PropTypes.instanceOf(Object)
};

UpdateDataStatus.displayName = 'UpdateDataStatus';

export default memo(UpdateDataStatus);
