import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

const useUrlParams = ({ preventTrigger, urlParams = [] }) => {
  const history = useHistory();
  const { location } = history;
  const [loading, setLoading] = useState(true);
  const [foundParams, setFoundParams] = useState({});

  const search = useRef(location.search);

  useEffect(() => {
    if (preventTrigger || !search?.current) {
      setLoading(false);
      return;
    }

    const searchParams = new URLSearchParams(search.current);

    urlParams.forEach(param => {
      const urlParam = searchParams.get(param);
      if (urlParam) setFoundParams(prev => ({ ...prev, [param]: urlParam }));
    });

    search.current = null;
  }, [search.current]);

  return { loading, foundParams };
};

export default useUrlParams;
