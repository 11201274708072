import React from 'react';
import { Button, Text, Grid, Row, Col, Input, Spacer } from '@homehero/hero-style';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/pro-solid-svg-icons';
import { useSelector } from 'react-redux';
import css from './ProductToProjectModal.module.scss';

// hooks
import useFormState from '../../_Hooks/useFormState';
import useCRUD from '../../_Hooks/useCRUD';

// components
import ProductCard from '../Card/ProductCard';
import Select from '../Select/Select';
import Modal from './Modal';
import CenteredLoader from '../Loader/CenteredLoader';
import useViewport from '../../_Hooks/useViewport';

const schema = Yup.object().shape({
  name: Yup.string().required('Informe o nome do projeto.')
});

const ItemToProjectModal = ({ idProduct, onSubmit, onClose, item, AddComponent }) => {
  const { isMobile } = useViewport(window.innerWidth);
  const { refurbishItemType, refurbishStatus } = useSelector(state => state.setup.enums);
  const { formState, handleSubmit, handleBlur, handleChange, reset } = useFormState({ name: '' }, schema, true);
  const { values, errors, touched } = formState;

  const { data, loading } = useCRUD({
    model: 'catalog/products',
    pathOptions: `/${idProduct}`,
    immediatelyLoadData: !!idProduct
  });
  const { handleCreate: addItem } = useCRUD({ model: 'refurbish-items', immediatelyLoadData: false });
  const { list: refurbishes, handleCreate } = useCRUD({
    model: 'refurbish',
    options: {
      order: [['createdAt', 'desc']],
      where: { isActive: true, idStatus: 5 }
    }
  });

  const handleProject = _data => {
    if (!_data || (!item && !data)) {
      return null;
    }

    const _item = item
      ? { ...item, internalId: item.id }
      : { ...data, type: refurbishItemType.product, idSearch: data.id };
    return addItem({
      values: { ..._item, idRefurbish: _data },
      refresh: false
    }).then(({ error }) => {
      if (!error) {
        const project = refurbishes?.find(r => r.id === _data);
        if (project) onSubmit(project.name);
      }
    });
  };

  const Submit = () => {
    handleCreate({ values: { ...values, idStatus: refurbishStatus.execution.id } }).then(newRef => {
      handleProject(newRef.id).then(() => {
        onSubmit(newRef.name);
        reset();
      });
    });
  };

  return (
    <div onMouseLeave={e => e.stopPropagation()}>
      <Modal title="Salvar Item" open onClose={() => onClose(false)} hideFooter width={550}>
        {loading ? (
          <CenteredLoader />
        ) : (
          <Grid cla>
            <Row>
              <Col xs={4}>
                <ProductCard
                  imageSize={isMobile() ? 90 : 150}
                  data={item || data}
                  AddComponent={AddComponent}
                  showBorder={false}
                />
              </Col>
              <Col xs={8} className={css['c-product-project__data']}>
                <Row>
                  <Col xs={12}>
                    <Spacer mbLg={2} mbMd={2} mbSm={2} mbXs={2}>
                      <Text lg>Selecione o projeto</Text>
                    </Spacer>
                    {refurbishes.length ? (
                      <Select
                        model="refurbish"
                        name="refurbish"
                        placeholder="Nenhum projeto selecionado"
                        onChange={handleProject}
                        value={null}
                        options={refurbishes}
                        showSearch
                      />
                    ) : null}
                  </Col>
                </Row>
                <Row bottom="xs" className={css['c-product-project__data__bottom']}>
                  <Col xs={8} error={touched.name && !!errors.name}>
                    <Input
                      id="name"
                      name="name"
                      placeholder="Criar novo projeto"
                      icon={<FontAwesomeIcon icon={faPlusSquare} />}
                      value={values.name}
                      onChange={handleChange('name')}
                      onBlur={handleBlur('name')}
                      autoComplete="off"
                    />
                    {touched.name && errors.name && <small>{errors.name[0]}</small>}
                  </Col>
                  <Col xs={4}>
                    <Button type="primary" onClick={handleSubmit(Submit)} disabled={loading}>
                      Criar
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Grid>
        )}
      </Modal>
    </div>
  );
};

ItemToProjectModal.propTypes = {
  item: PropTypes.instanceOf(Object),
  idProduct: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  AddComponent: PropTypes.instanceOf(Object)
};

export default ItemToProjectModal;
