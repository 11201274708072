import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Input as AntInput } from 'antd';
import { colors } from '../../styles/style';

const StyledInput = styled(AntInput)`
  ${props =>
    props.withoutArrows &&
    css`
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    `}

  &:focus {
    box-shadow: none;
    border-color: ${colors.neutralSecondary40};
  }

  &:hover {
    border-color: ${colors.neutralSecondary40};
  }
`;

const Input = ({ type, ...props }) => {
  const inputTypes = {
    password: <StyledInput.Password {...props} />,
    textarea: <StyledInput.TextArea autoSize={{ minRows: 3 }} {...props} />
  };
  return inputTypes[type] || <StyledInput type={type} {...props} />;
};

Input.propTypes = {
  type: PropTypes.string
};

export default Input;
