import styled, { css } from 'styled-components';
import { breakpoints, colors, fonts, spaces } from '../../styles/style';
import TooltipIcon from '../Icons/TooltipIcon';

export const NotesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${spaces.space2};

  @media (max-width: ${breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
  }

  ${props =>
    props.$isView &&
    css`
      iframe {
        background-color: ${colors.neutral80} !important;
      }
      .tox-tinymce {
        border: 0px !important;
      }
    `}
`;

export const NoteTitle = styled.div`
  font-size: ${fonts.sizeMd};
  font-weight: 500;
  color: ${colors.neutralSecondary40};
  margin-bottom: ${spaces.space1};
  line-height: 21px;
  display: inline-block;
`;

export const NoteTooltip = styled(TooltipIcon)`
  margin-left: 10px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
