import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'antd';
import { breakpoints, spaces, colors } from '../../styles/style';

const StyledDiv = styled.div`
  padding-right: ${props => (props.$paddingRight ? spaces.space1 : 0)};
  @media (max-width: ${breakpoints.tablet}) {
    padding: ${spaces.space1} ${spaces.space2};
  }
`;

const ItemDetailButton = ({
  show = true,
  icon = 'faExternalLinkAlt',
  children,
  onClick,
  titleTooltip,
  paddingRight
}) => {
  const [showModal, setShowModal] = useState(false);

  if (!show) {
    return <div style={{ flexGrow: 1 }} />;
  }

  const RenderModal = () =>
    React.cloneElement(children, {
      onClose: () => setShowModal(false)
    });

  return (
    <StyledDiv $paddingRight={paddingRight}>
      <Tooltip placement="bottomRight" title={titleTooltip} align={{ offset: [10, 0] }}>
        <FontAwesomeIcon
          style={{ flexGrow: 1, cursor: 'pointer' }}
          icon={icons[icon]}
          color={colors.primary40}
          onClick={e => {
            e.stopPropagation();
            onClick && onClick();
            setShowModal(true);
          }}
        />
      </Tooltip>
      {showModal && children && <RenderModal />}
    </StyledDiv>
  );
};

ItemDetailButton.propTypes = {
  show: PropTypes.bool,
  icon: PropTypes.string,
  children: PropTypes.instanceOf(Object),
  onClick: PropTypes.func,
  titleTooltip: PropTypes.string,
  paddingRight: PropTypes.bool
};

export default ItemDetailButton;
