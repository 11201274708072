import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const FadeIn = ({ duration, fadeInStart, fadeOutStart, onFadeOut, children }) => {
  const [transform, setTransform] = useState({ opacity: 0 });

  useEffect(function initFadeIn() {
    const timeoutFadeIn = setTimeout(() => {
      setTransform({ opacity: 1 });
    }, fadeInStart);

    const timeoutFadeOut = setTimeout(() => {
      if (fadeOutStart == null) return;
      setTransform({ opacity: 0 });
      if (onFadeOut) onFadeOut();
    }, fadeOutStart);

    return () => {
      clearTimeout(timeoutFadeIn);
      clearTimeout(timeoutFadeOut);
    };
  }, []);

  return (
    <div
      style={{
        transition: `opacity ${duration}ms ease`,
        opacity: 0,
        ...transform
      }}
    >
      {children}
    </div>
  );
};

FadeIn.propTypes = {
  duration: PropTypes.number,
  fadeInStart: PropTypes.number,
  fadeOutStart: PropTypes.number,
  onFadeOut: PropTypes.func,
  children: PropTypes.instanceOf(Object)
};

FadeIn.defaultProps = {
  duration: 2000,
  fadeInStart: 0
};

export default FadeIn;
