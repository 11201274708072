import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  List,
  ListItem,
  CardLine,
  CardItem,
  Button,
  Input,
  Spacer,
  EmptyCardItem
} from '@homehero/hero-style';
import PropTypes from 'prop-types';

import { saveRefurbish } from '../../store/refurbish/actions/action';

// hooks
import useCRUD from '../../_Hooks/useCRUD';

const SelectRefurbishModal = ({ open, onSubmit, onCancel }) => {
  const dispatch = useDispatch();
  const refurbish = useSelector(state => state.refurbishReducer);
  const { user } = useSelector(state => state.authReducer);
  const [refurbishes, setRefurbishes] = useState([]);

  // hook
  const { data, loading } = useCRUD({
    model: 'user',
    options: {
      include: {
        model: 'refurbish',
        where: { isShared: true },
        required: false,
        include: [{ model: 'user', where: { userType: 'provider' }, required: false }]
      }
    },
    pathOptions: `/${user.id}`,
    showToast: false
  });

  const [_refurbish, setRefurbish] = useState({});

  useEffect(() => {
    if (data && data.refurbishes) {
      setRefurbishes(data.refurbishes);

      if (data.refurbishes.length === 1) {
        dispatch(saveRefurbish(data.refurbishes[0]));
      }
    }
  }, [data]);

  const handleChange = item => {
    setRefurbish(item);
  };

  const handleSelect = e => {
    e.preventDefault();

    dispatch(saveRefurbish(_refurbish));

    toast.success('Reforma alterada com sucesso');

    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <Modal open={open} contentLabel="refurbishSelect" widthLevel={2}>
      <ModalHeader title="Selecione um projeto" />
      <ModalContent>
        <List>
          {refurbishes && refurbishes.length
            ? refurbishes.map(item => (
                <ListItem key={item.id}>
                  <CardLine>
                    <EmptyCardItem>
                      <Spacer mtLg={1}>
                        <Input
                          id="refurbish"
                          name="refurbish"
                          type="radio"
                          value={item.id}
                          defaultChecked={refurbish && refurbish.id === item.id}
                          onChange={() => handleChange(item)}
                        />
                      </Spacer>
                    </EmptyCardItem>
                    <CardItem value={item.id} label="#" small />
                    <CardItem value={item.name} label="Nome" md={3} ellipsis small />
                    <CardItem
                      small
                      value={`${item.street || '-'} ${item.number ? `,${item.number}` : ''} ${
                        item.complement ? `,${item.complement}` : ''
                      }`}
                      label="Endereço"
                      ellipsis
                      md={6}
                    />
                  </CardLine>
                </ListItem>
              ))
            : null}
        </List>
      </ModalContent>
      <ModalFooter>
        {onCancel ? (
          <Button onClick={onCancel} container="outlined" type="primary">
            Cancelar
          </Button>
        ) : null}
        <Button type="primary" onClick={handleSelect} disabled={loading}>
          Selecionar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

SelectRefurbishModal.propTypes = {
  open: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

SelectRefurbishModal.defaultProps = {
  open: false,
  onSubmit: null,
  onCancel: null
};

export default SelectRefurbishModal;
