import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import parse from 'html-react-parser';
import { toast } from 'react-toastify';
import Content from '../../components/Content/Content';
import NotesList from '../../components/List/NotesList';
import ModalConfirm from '../../components/Modal/ConfirmModal';
import RichTextEditor from '../../components/RichTextEditor/RichTextEditor';
import HowDoesItWorksButton from '../../components/Button/HowDoesItWorksButton';
import emptyNotes from '../../components/Images/emptyNotes.svg';

import useCRUD from '../../_Hooks/useCRUD';
import useUrlParams from '../../_Hooks/useUrlParams';
import { useContextHook } from '../../contexts/Context';
import { colors, spaces } from '../../styles/style';

import css from './Notes.module.scss';
import NoContent from '../../components/NoContent/NoContent';
import Button from '../../components/Button/Button';
import useViewport from '../../_Hooks/useViewport';
import InfoSidebar from '../../components/Sidebar/InfoSidebar';
import { Context as InfoSidebarContext } from '../../contexts/GeneralContext';
import Modal from '../../components/Modal/Modal';
import CenteredLoader from '../../components/Loader/CenteredLoader';

const Notes = ({ idTemplate, isApply, forceLoad, isProject }) => {
  const { isMobile } = useViewport(window.innerWidth);
  const { data, handleUpdate: updateRefurbish, setData: setRefurbishData } = useContextHook() || {};

  const { id: idRefurbish } = data || {};
  const itemsToShare = data.itemsToShare || { note: true };

  const { user } = useSelector(state => state.authReducer);
  const { userType, refurbishStatus } = useSelector(state => state.setup.enums);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSidebarModal, setShowSidebarModal] = useState(false);
  const [selectedNote, setSelectedNote] = useState({});
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [sideBarTab, setSideBarTab] = useState(null);
  const isOpportunity = refurbishStatus?.opportunity.includes(data?.idStatus);

  const shouldSave = useRef(false);

  const isCustomer = user.userType === userType.customer.value;

  const { loading, list: notes, handleCreate, handleUpdate, handleDelete, handleGet } = useCRUD({
    model: 'refurbish-notes',
    options: {
      order: [['updatedAt', 'desc']],
      where: {
        ...(isCustomer ? { shared: { ne: null } } : {}),
        ...(idTemplate != null ? { idTemplate } : { idRefurbish })
      }
    },
    immediatelyLoadData: false
  });

  const { loading: loadingNotification, foundParams } = useUrlParams({
    preventTrigger: Object.keys(selectedNote)?.length,
    urlParams: ['tab', 'nota']
  });

  const richTextValue =
    typeof selectedNote?.note !== 'string' ? notes && notes[0] && notes[0].note : selectedNote?.note;
  const images = selectedNote?.images || [];

  const toggleDeleteModal = show => setShowDeleteModal(show);

  const handleShare = () => {
    if (!itemsToShare.note) setShowConfirmModal(true);
  };

  const handleConfirmShare = () => {
    const values = { isShared: data.isShared, itemsToShare: { ...itemsToShare, note: true } };
    updateRefurbish({ id: idRefurbish, values, updatePathOptions: '/share', refresh: false }).then(resp => {
      if (!resp.error) {
        setRefurbishData({ ...data, itemsToShare: resp.itemsToShare });
      }
    });
  };

  const handleSubmit = _values => {
    const values = {
      ..._values,
      idRefurbish: !idTemplate ? idRefurbish : null,
      idTemplate,
      shared: _values?.values?.shared,
      event: _values?.event || `${isProject ? 'project' : 'opportunity'}-note-${_values.id ? 'edited' : 'created'}`
    };
    if (isApply) return Promise.resolve();

    if (!values.id) {
      return handleCreate({ values }).then(({ id }) => {
        shouldSave.current = false;
        if (!isMobile()) {
          setSelectedNote(prevState => ({ ...prevState, id }));
        }
      });
    }
    return handleUpdate({ id: values.id, values }).then(res => {
      if (_values.switched) {
        shouldSave.current = false;
        setSelectedNote(res);
      }
    });
  };

  const handleEditorChange = (content, imagesArray) => {
    if (!shouldSave.current) {
      return;
    }
    setSelectedNote(prevState => {
      return {
        ...prevState,
        note: content,
        images: imagesArray.filter(image => image && content.includes(image))
      };
    });
  };

  const handleSelect = currentSelected => {
    shouldSave.current = false;
    setSelectedNote({
      ...currentSelected,
      images: notes?.find(p => p.id === currentSelected?.id)?.images
    });
  };

  const handleAddButton = () => {
    const newNotes = {
      note: 'Nova anotação',
      images: []
    };
    shouldSave.current = true;
    setSelectedNote(newNotes);
  };

  const handleModal = result => {
    if (result) {
      handleDelete({
        id: selectedNote.id,
        values: { event: `${isProject ? 'project' : 'opportunity'}-note-deleted` }
      }).then(resp => {
        shouldSave.current = false;
        setSelectedNote(isMobile() ? {} : resp?.[0] || {});
        toggleDeleteModal(false);
        setShowSidebarModal(false);
      });
    }
  };

  useEffect(() => {
    handleGet().then(res => !isMobile() && setSelectedNote(res?.[0] || {}));
  }, [forceLoad]);

  useEffect(() => {
    if (loadingNotification) return undefined;
    let timer;
    if (selectedNote && Object.keys(selectedNote).length && shouldSave.current) {
      timer = setTimeout(() => {
        handleSubmit({ ...selectedNote });
      }, 500);
    }
    if (selectedNote && Object.keys(selectedNote).length && !shouldSave.current) shouldSave.current = true;
    return () => clearTimeout(timer);
  }, [selectedNote]);

  useEffect(() => {
    if (!foundParams?.nota || loading) {
      return;
    }

    const found = notes?.find(note => note.id === Number(foundParams?.nota));
    if (!found) {
      toast.error('Nota não encontrada.');
      return;
    }
    if (foundParams?.tab) setSideBarTab(foundParams?.tab);
    window.history.replaceState(null, '', window.location.pathname);
    setSelectedNote(found);
  }, [foundParams, loading]);

  const ButtonsNoContent = () => {
    return (
      <div style={{ display: 'flex', justifyContent: isMobile() ? ' center' : 'flex-start' }}>
        <Button id="new-note" type="primary" style={{ marginRight: '10px' }} onClick={() => handleAddButton()}>
          Criar anotação
        </Button>
        <Button id="view-example" ghost type="primary">
          Ver exemplo
        </Button>
      </div>
    );
  };

  const EmptyState = () => (
    <NoContent
      title="Conheça o bloco de anotações"
      description={`Registre o andamento do seu projeto, crie atas de reunião, escreva notas sobre os pontos
      mais importantes, insira links e imagens e ainda compartilhe informações com seu cliente.`}
      btnTitle="Criar anotação"
      image={<img src={emptyNotes} alt="emptyNotes" />}
      showButtonIcon={false}
      renderButton={false}
      extraButton={ButtonsNoContent}
      style={{ marginLeft: 'auto', marginRight: 'auto' }}
    />
  );

  const handleCancelShare = () => {
    setShowConfirmModal(false);
  };

  const duplicateNote = currentNote => {
    handleCreate({
      values: { ...currentNote, idRefurbish },
      idRefurbish,
      postPathOptions: '/duplicate',
      displayToast: true
    }).then(res => {
      setSelectedNote(res);
      setShowSidebarModal(false);
    });
  };

  const handleChangeShared = (id, newShared) => {
    const shared = newShared ? null : new Date();
    const verb = 'patch';
    handleUpdate(
      {
        id,
        updatePathOptions: '/shared',
        values: {
          idRefurbish,
          shared
        },
        refresh: false,
        verb
      },
      true
    ).then(res => {
      shouldSave.current = false;
      setSelectedNote(res);
      handleShare();
    });
  };

  return loadingNotification ? (
    <CenteredLoader />
  ) : (
    <InfoSidebarContext data={{ setShowSidebarModal }}>
      <Content>
        <div style={{ display: 'flex', justifyContent: selectedNote?.id && isMobile() ? 'space-between' : 'end' }}>
          {selectedNote?.id && (
            <FontAwesomeIcon
              className="showMobile"
              color={colors.primary40}
              icon={faArrowLeft}
              onClick={() => {
                setSelectedNote({});
              }}
              style={{ margin: '5px 0 0 5px' }}
            />
          )}
          {!idTemplate && <HowDoesItWorksButton id="howProjectNotesWorks" />}
        </div>
        <div className={css.notes}>
          {!(selectedNote?.id && isMobile()) && (
            <NotesList
              list={notes}
              onClick={handleSelect}
              selected={selectedNote}
              onAdd={handleAddButton}
              onDelete={() => toggleDeleteModal(true)}
              isApply={isApply}
              handleCreateNote={duplicateNote}
              handleChangeShared={handleChangeShared}
              isOpportunity={isOpportunity}
            />
          )}

          {notes?.length ? (
            <>
              {isApply ? (
                <p style={{ padding: spaces.space2 }}>{parse(richTextValue || '')}</p>
              ) : (
                <>
                  {selectedNote?.id ? (
                    <div style={{ borderRight: '1px solid #CCCCCC', width: '100%' }}>
                      <RichTextEditor
                        key={`note-${selectedNote.id}`}
                        onChange={handleEditorChange}
                        value={richTextValue}
                        images={images}
                        statusbar={false}
                        disabled={isCustomer}
                        height={isMobile() ? 'calc(100vh - 200px)' : '100%'}
                        toolbar={isCustomer ? false : undefined}
                      />
                    </div>
                  ) : (
                    <div style={{ width: '100%' }}>{!isMobile() && <CenteredLoader />}</div>
                  )}
                  {!isMobile() && (
                    <InfoSidebar
                      data={selectedNote}
                      isShared={false}
                      isCustomer={isCustomer}
                      hasDescription={false}
                      isProject
                      idRefurbish={idRefurbish}
                      handleChangeShared={handleChangeShared}
                      handleRemove={() => setShowDeleteModal(true)}
                      model="refurbish-notes"
                      isModuleShared={itemsToShare.note}
                      selectedTab={sideBarTab}
                      handleCreateNote={duplicateNote}
                      isOpportunity={isOpportunity}
                    />
                  )}
                </>
              )}
            </>
          ) : (
            !loading && !isCustomer && !isApply && <EmptyState />
          )}
        </div>
        {showDeleteModal ? (
          <ModalConfirm
            text="Deseja realmente apagar a anotação?"
            onSubmit={handleModal}
            onClose={() => toggleDeleteModal(false)}
          />
        ) : null}
        {showSidebarModal && isMobile() && (
          <Modal
            open
            noPadding
            hideFooter
            onClose={() => setShowSidebarModal(false)}
            style={{ display: 'flex' }}
            height="100%"
            width="100%"
          >
            <InfoSidebar
              data={selectedNote}
              isShared={false}
              isCustomer={isCustomer}
              hasDescription={false}
              isProject={isProject}
              idRefurbish={idRefurbish}
              handleRemove={() => setShowDeleteModal(true)}
              model="refurbish-notes"
              isModuleShared={itemsToShare.note}
              selectedTab={sideBarTab}
              handleCreateNote={duplicateNote}
              handleChangeShared={handleChangeShared}
            />
          </Modal>
        )}
        {showConfirmModal && (
          <ModalConfirm
            confirmButtonText="Ativar"
            cancelButtonText="Não ativar"
            title="Compartilhar módulo de anotações"
            onSubmit={handleConfirmShare}
            onClose={handleCancelShare}
            text="O compartilhamento com o cliente do módulo de anotações não está ativado, deseja ativar?"
          />
        )}
      </Content>
    </InfoSidebarContext>
  );
};

Notes.propTypes = {
  idTemplate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isApply: PropTypes.bool,
  forceLoad: PropTypes.number,
  isProject: PropTypes.bool
};

export default Notes;
