import styled, { css } from 'styled-components';
import { Upload } from 'antd';
import { colors, spaces, fonts, breakpoints } from '../../styles/style';

const { Dragger } = Upload;
export const StyledUpload = styled(Upload)`
  width: ${({ fullWidth }) => (fullWidth ? 'auto' : spaces.space10)};
  padding: 0 8px;

  .ant-upload {
    border-color: ${colors.primary40};
    background-color: transparent;
    margin: 0;
    height: ${props => (props.listType === 'picture-card' ? '80px' : '100%')};

    &.ant-upload-select-picture-card > .ant-upload {
      display: block;
      padding: ${spaces.space2} ${spaces.space1};
      font-size: ${fonts.sizeSm};
      font-weight: 600;
      color: ${colors.primary40};
    }

    ${props =>
      props.large &&
      css`
        height: 100%;
        width: 160px;
      `}
  }

  ${props =>
    props.type === 'button' &&
    css`
      padding: 0 !important;
      .ant-upload {
        width: 100%;
        button {
          width: 100%;
          @media (max-width: ${breakpoints.tablet}) {
            justify-content: flex-start;
            margin-left: ${props.noMobileMargin ? '0px' : spaces.space2};
          }
        }
      }
    `}
`;

export const StyledDragger = styled(Dragger)`
  margin: 0;
  &.ant-upload-drag {
    background-color: transparent;

    p {
      &.ant-upload-text {
        font-size: ${fonts.sizeSm};
        padding: 0 4px;
        margin: 0;
      }
    }

    .ant-upload {
      padding: 4px;

      .ant-upload-drag-container {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;

export const CoverImageUploadContainer = styled.div`
  width: 100%;
  > span {
    width: 100%;
  }
`;
export const CoverImageDragger = styled(Dragger)`
  &.ant-upload-drag {
    width: 100%;
    height: 216px;
    border: 1px solid rgba(47, 108, 227, 0.1);
    background: rgba(47, 108, 227, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 4px;

    .ant-upload {
      padding: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      .ant-upload-drag-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;
