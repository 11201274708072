import styled, { css } from 'styled-components';
import Button from '../Button/Button';
import { fonts, spaces } from '../../styles/style';

export const Container = styled.div`
  width: 100%;
`;

export const ChevronButton = styled(Button)`
  border: 0 !important;
  padding: 0;
  margin-left: ${spaces.space2};
  cursor: pointer;
`;

export const Body = styled.div`
  padding-top: ${spaces.space2};
  ${props =>
    !props.$show &&
    css`
      display: none;
    `}
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.div`
  font-size: ${fonts.sizeLg};
  font-weight: 500;
  cursor: pointer;
`;
