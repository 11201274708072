import React, { useEffect, useMemo, useState } from 'react';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faCopy } from '@fortawesome/pro-light-svg-icons';
import { Switch, Space, Tooltip } from 'antd';

import { Text } from '@homehero/hero-style';
import styled, { css } from 'styled-components';
import { faUserFriends } from '@fortawesome/pro-solid-svg-icons';

import { useSelector } from 'react-redux';
import Button from '../Button/Button';
import { colors } from '../../styles/style';
import CopyLinkButton from '../Button/CopyLinkButton';

const StyledSharedText = styled.div`
  justify-content: space-evenly;
  align-items: center;
  margin-top: 16px;
  display: flex;
  background-color: ${colors.primary80};
  ${props =>
    props.$warning &&
    css`
      border: 1px ${colors.warning90} solid;
      background-color: ${colors.warning95};
    `}
  width: ${props => props.width};
  border-radius: 4px;
  height: ${props => props.height};

  svg {
    margin-left: 5px;
    margin-right: 5px;
  }
`;

const RenderNoteInfo = ({
  isProject,
  isCustomer,
  data,
  idRefurbish,
  user,
  handleRemove,
  isModuleShared,
  handleCreateNote,
  handleChangeShared = f => f,
  isOpportunity
}) => {
  const [shared, setShared] = useState(data?.shared);
  const { userType } = useSelector(state => state.setup.enums);

  const isWarningMessage = !isModuleShared && data.shared;

  const canDelete = user?.userType === userType.provider.value;

  useEffect(() => {
    setShared(!!data?.shared);
  }, [data]);

  const renderText = useMemo(() => {
    if (isWarningMessage)
      return `Seu cliente não tem acesso aos itens compartilhados pois o
              compartilhamento do módulo de anotações não está ativo.`;
    return 'Esta anotação está compartilhada';
  }, [isWarningMessage]);

  return (
    <>
      <Space direction="vertical" size={16}>
        {!isCustomer && isProject && data?.id > 1 ? (
          <>
            <div>
              <Text style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }} sm bold>
                Informações da anotação
              </Text>
            </div>
            <div>
              <Tooltip title="Copie o link dessa anotação para enviar para seu cliente ou equipe">
                <CopyLinkButton
                  underline
                  noPadding
                  height="auto"
                  eventButton="note"
                  customUrl={`${window.location.origin}/n/${data?.id}`}
                  text
                  type="primary"
                >
                  Copiar Link
                </CopyLinkButton>
              </Tooltip>
            </div>
            {isProject && !isOpportunity && (
              <div>
                <div style={{ display: 'flex' }}>
                  <div style={{ flex: 2 }}>
                    <Text style={{ marginBottom: '4px' }} sm>
                      Compartilhar anotação
                    </Text>
                    <Text xs>Habilite para compartilhar com seu cliente</Text>
                  </div>
                  <div>
                    <Switch
                      id="share-note"
                      disabled={isCustomer}
                      checked={!!data.shared}
                      onChange={() => handleChangeShared(data.id, shared)}
                      size="small"
                    />
                  </div>
                </div>
                {data.shared && !isCustomer && (
                  <StyledSharedText height={isWarningMessage ? '55px' : '24px'} $warning={isWarningMessage}>
                    <FontAwesomeIcon
                      icon={faUserFriends}
                      color={isWarningMessage ? colors.neutral50 : colors.primary40}
                    />
                    <Text xs>{renderText}</Text>
                  </StyledSharedText>
                )}
              </div>
            )}
          </>
        ) : null}
        {isCustomer ? (
          <div>
            <Tooltip title="Copie o link dessa anotação para enviar para seu profissional">
              <CopyLinkButton
                underline
                noPadding
                height="auto"
                eventButton="note"
                customUrl={`${window.location.origin}/n/${data?.id}`}
                text
                type="primary"
              >
                Copiar link
              </CopyLinkButton>
            </Tooltip>
          </div>
        ) : null}
        <div>
          <Text style={{ marginBottom: '4px' }} sm bold>
            Data de criação
          </Text>
          <Text xs>{data ? dayjs(data.createdAt).format('DD [de] MMMM, YYYY [às] HH:mm') : '-'}</Text>
        </div>
        <div>
          <Text style={{ marginBottom: '4px' }} sm bold>
            Última atualização
          </Text>
          <Text xs>{data ? dayjs(data.updatedAt).format('DD [de] MMMM, YYYY [às] HH:mm') : '-'}</Text>
        </div>
      </Space>
      <div>
        <div style={{ display: 'flex', marginTop: 8 }}>
          {data?.id > 1 && canDelete ? (
            <>
              {!isOpportunity && (
                <div style={{ flex: 1 }}>
                  <Button
                    id="delete-note-icon"
                    size="small"
                    style={{ width: '100%', color: colors.secondary60, borderColor: colors.secondary60 }}
                    ghost
                    type="primary"
                    icon={<FontAwesomeIcon icon={faCopy} />}
                    onClick={() => handleCreateNote(data, idRefurbish)}
                  >
                    Criar uma cópia
                  </Button>
                </div>
              )}
              <div style={{ flex: 1, marginLeft: 8 }}>
                <Button
                  id="delete-note-icon"
                  size="small"
                  style={{ width: '100%' }}
                  ghost
                  type="danger"
                  icon={<FontAwesomeIcon icon={faTrashAlt} />}
                  onClick={() => handleRemove(true)}
                >
                  Excluir nota
                </Button>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

RenderNoteInfo.propTypes = {
  data: PropTypes.instanceOf(Object),
  user: PropTypes.instanceOf(Object),
  isProject: PropTypes.bool,
  isCustomer: PropTypes.bool,
  idRefurbish: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleRemove: PropTypes.func,
  isModuleShared: PropTypes.bool,
  handleChangeShared: PropTypes.func,
  handleCreateNote: PropTypes.func,
  isOpportunity: PropTypes.bool
};

export default RenderNoteInfo;
