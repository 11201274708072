import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { faTrashAlt, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import Drawer from './Drawer';
import useCRUD from '../../_Hooks/useCRUD';
import Tabs, { TabPane } from '../Tabs/Tabs';

import { colors } from '../../styles/style';
import Button from '../Button/Button';
import Input from '../Input/Input';
import ConfirmModal from '../Modal/ConfirmModal';
import { capitalize } from '../../lib/helpers/helper';
import { hasPermission } from '../../routes/Common/PrivateRoute';
import { Card, Content, IconCheck, ListItems, StyledStrashIcon, Subtitle, Title } from './financialCategories.styles';
import EditableInput from '../Input/EditableInput';
import useViewport from '../../_Hooks/useViewport';

const RenderContent = ({
  subtitle,
  list,
  setItemToDelete,
  addFinancial,
  onCreate,
  setAddFinancial,
  billType,
  onChange
}) => {
  const [name, setName] = useState('');
  const title = billType === 'income' ? 'Cobranças e receitas' : 'Despesas';
  return (
    <Content>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <ListItems>
        {list &&
          list.map(item => {
            return (
              <Card id={`category-${item?.id}`}>
                <EditableInput
                  key={`name-${item.id}`}
                  value={item.name}
                  onChange={val => onChange(item, { name: val })}
                />

                <Tooltip title="Remover categoria" placement="topLeft">
                  <StyledStrashIcon
                    id={`remove-category-${item?.id}`}
                    icon={faTrashAlt}
                    onClick={() => setItemToDelete(item)}
                  />
                </Tooltip>
              </Card>
            );
          })}
      </ListItems>
      {addFinancial && (
        <Card>
          <Input
            id="add-financial-input"
            onChange={e => setName(e.target.value)}
            onPressEnter={e => onCreate({ values: { name: e?.target?.value, isActive: true, billType } })}
          />
          <IconCheck icon={faCheck} onClick={() => onCreate({ values: { name, isActive: true, billType } })} />
          <StyledStrashIcon icon={faXmark} onClick={() => setAddFinancial(false)} />
        </Card>
      )}
      {!addFinancial && (
        <Button
          id="add-financial"
          type="primary"
          text
          style={{ marginRight: 'auto' }}
          onClick={() => {
            setAddFinancial(true);
            setTimeout(() => document.getElementById('add-financial-input').focus(), 100);
          }}
        >
          + Adicionar categoria
        </Button>
      )}
    </Content>
  );
};

RenderContent.propTypes = {
  subtitle: PropTypes.string,
  list: PropTypes.instanceOf(Array),
  setItemToDelete: PropTypes.func,
  addFinancial: PropTypes.bool,
  onCreate: PropTypes.func,
  setAddFinancial: PropTypes.func,
  billType: PropTypes.string,
  onChange: PropTypes.func
};

const FinancialCategories = ({ onClose, tab }) => {
  const { isMobile } = useViewport(window.innerWidth);
  const [_billType, setBillType] = useState(tab);
  const { user } = useSelector(state => state.authReducer) || {};
  const { plans, permissions } = useSelector(state => state.setup);
  const [list, setList] = useState([]);
  const [addFinancial, setAddFinancial] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(false);
  const hasExpensePermission = hasPermission(user, ['administrative'], plans, permissions);
  const subtitle = 'Além das categorias padrão da Vobi, você pode adicionar ou excluir suas categorias livremente.';
  const textToDelete = 'ao excluir a categoria, ela será removida permanente da sua lista.';
  const { handleGet, handleCreate, handleDelete, handleUpdate } = useCRUD({
    model: 'financialCategory',
    immediatelyLoadData: false
  });

  const handleLoad = () =>
    handleGet({
      refetchOptions: {
        include: [{ model: 'payment', paranoid: false }],
        where: { billType: _billType, isActive: true },
        onlyMine: true,
        order: [['name', 'asc']]
      }
    }).then(resp => setList(resp));

  useEffect(() => {
    if (_billType) {
      handleLoad();
    }
  }, [_billType]);

  const onCreate = data => {
    handleCreate(data).then(resp => {
      if (!resp.error) {
        setList(prev => prev.concat(resp));
        setAddFinancial(false);
      }
    });
  };

  const onUpdate = (item, newValue) => {
    handleUpdate({ values: newValue, updatePathOptions: `/${item?.id}` });
  };

  const onDelete = data => {
    handleDelete({ id: data.id, displayToast: true }).then(resp => {
      if (!resp.error) {
        setList(prev => prev.filter(item => item.id !== data.id));
      }
    });
  };

  return (
    <Drawer
      title="Configuração das categorias financeiras"
      open
      padding="0px"
      formId="customerForm"
      onClose={() => onClose()}
      footer={
        <Button style={{ marginLeft: 'auto' }} id="close-financial-drawer" type="primary" onClick={() => onClose()}>
          Fechar
        </Button>
      }
    >
      <Tabs
        activeKey={_billType}
        onChange={key => setBillType(key)}
        tabBarStyle={{
          paddingTop: 0,
          borderBottom: `1px solid ${colors.neutralSecondary90}`,
          position: 'sticky',
          top: 0
        }}
      >
        {hasExpensePermission && (
          <TabPane id="new-notification-tab" key="expense" tab="Despesas">
            <RenderContent
              subtitle={subtitle}
              list={list}
              setItemToDelete={setItemToDelete}
              addFinancial={addFinancial}
              onCreate={onCreate}
              setAddFinancial={setAddFinancial}
              billType={_billType}
            />
          </TabPane>
        )}
        <TabPane id="new-notification-tab" key="income" tab="Cobranças e receitas">
          <RenderContent
            subtitle={subtitle}
            list={list}
            setItemToDelete={setItemToDelete}
            addFinancial={addFinancial}
            onCreate={onCreate}
            setAddFinancial={setAddFinancial}
            billType={_billType}
            onChange={onUpdate}
          />
        </TabPane>
      </Tabs>
      {itemToDelete && (
        <ConfirmModal
          modalWidth={isMobile() ? 500 : 344}
          onClose={() => setItemToDelete(false)}
          alertInfo="Essa operação não poderá ser desfeita."
          description={
            itemToDelete?.payments?.length
              ? `Essa categoria está sendo utilizada em ${itemToDelete?.payments?.length} lançamento${
                  itemToDelete?.payments?.length > 1 ? 's' : ''
                }, ${textToDelete}`
              : capitalize(textToDelete)
          }
          onSubmit={() => onDelete(itemToDelete)}
        />
      )}
    </Drawer>
  );
};

FinancialCategories.propTypes = {
  onClose: PropTypes.func,
  tab: PropTypes.string
};

export default FinancialCategories;
