import styled, { css } from 'styled-components';
import { Drawer as AntDrawer } from 'antd';
import { colors, fonts, breakpoints, spaces } from '../../styles/style';

export const StyledDrawer = styled(AntDrawer)`
  max-width: 100%;

  .ant-drawer-header {
    border: none;
    padding-bottom: ${spaces.space2};
    display: flex;
    align-items: center;
    .ant-drawer-title {
      font-family: ${fonts.familySecondary};
      font-weight: 300;
      font-size: ${fonts.sizeH6};
      width: 100%;
    }

    .ant-drawer-extra {
      display: flex;
      align-items: center;
    }
  }
  .ant-drawer-close {
    left: ${props => (props.$closeLeft ? 0 : null)};
    right: ${props => (props.$isMenuMobile ? 'auto' : 0)};
    padding: ${props => (props.$isMenuMobile ? `${spaces.space3} 0` : '')};
  }

  .ant-drawer-body {
    padding-top: 0;
    overflow: hidden auto;
    padding-left: ${props => props.padding};
    padding-right: ${props => props.padding};
    background-color: ${props => props.backgroundColor || props.backgroundcolor};
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: ${spaces.space1};
    }
    ::-webkit-scrollbar-track {
      background: ${colors.neutralSecondary90};
    }
    ::-webkit-scrollbar-thumb {
      background: ${colors.neutralSecondary70};
      border-radius: 6px;
    }
  }

  .ant-drawer-footer {
    display: flex;
    justify-content: space-between;
    background-color: ${colors.neutral90};
    padding: ${spaces.space1};
  }

  @media (min-width: ${breakpoints.desktop}) {
    .ant-modal-title {
      font-size: ${fonts.sizeH5};
    }

    .ant-drawer-footer {
      padding: 12px ${spaces.space3};
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    .ant-drawer-content-wrapper {
      width: 100% !important;
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    .ant-drawer-content-wrapper {
      width: 90%;
    }
  }
`;

export const StyledSubtitle = styled.p`
  padding-top: 0;
  font-size: ${fonts.sizeMd};
  color: ${colors.neutral50};
`;

export const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: ${spaces.space4};
  width: 100%;
`;

export const Header = styled.header`
  ${({ extraContentOnRight }) =>
    extraContentOnRight &&
    css`
      display: flex;
      justify-content: space-between;
      width: 100%;
    `}
`;

export const StyledSubTitle = styled.span`
  font-size: ${fonts.sizeSm};
  color: ${colors.neutralSecondary40};
`;
