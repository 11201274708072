import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Loader } from '@homehero/hero-style';

import { fonts } from '../../styles/style';

const StyledLoadingDiv = styled.div`
  ${({ className, $fontSize }) => {
    switch (className) {
      case 'centered':
        return `
        margin: auto;
        width: fit-content;
        padding-top: 40px;
        `;
      case 'expansive':
        return `
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        font-size: ${$fontSize || fonts.sizeH6};
        `;
      default:
        return '';
    }
  }}
`;

const CenteredLoader = ({ text = 'Carregando...', className = 'centered', fontSize }) => (
  <StyledLoadingDiv className={className} $fontSize={fonts[fontSize]}>
    <Loader text={text} />
  </StyledLoadingDiv>
);
CenteredLoader.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  fontSize: PropTypes.string
};

export default CenteredLoader;
