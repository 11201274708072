import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Space, Typography, Row, Input, Tooltip } from 'antd';
import { faSparkles, faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ImageParser from '../ImageParser/ImageParser';
import Select from '../Select/Select';
import Modal from '../Modal/Modal';
import CenteredLoader from '../Loader/CenteredLoader';
import { StyledText, ColMarginRight, ColMarginLeft, ColMargin } from './ExportEstimateToPdfDrawer.styled';
import Drawer from './Drawer';
import Box from '../Box/Box';
import FileUpload from '../File/FileUpload';
import { fonts, colors, spaces } from '../../styles/style';
import { printPdf } from '../../lib/helpers/exportToPdf';

const { Text } = Typography;
const { TextArea } = Input;

const ExportToPdfDrawer = ({
  open,
  onClose,
  name = 'orçamento',
  orientationPdf = 'landscape',
  customTip,
  refurbish,
  CheckComponent,
  PdfComponent,
  afterSubmit,
  printFunction = printPdf
}) => {
  const { user } = useSelector(state => state.authReducer) || {};
  const [title, setTitle] = useState(name);
  const [description, setDescription] = useState(`Descrição de ${name}`);
  const [logo, setLogo] = useState(user?.company?.logo || refurbish?.company?.logo);
  const [orientation, setOrientation] = useState(orientationPdf);
  const [isPrinting, setIsPrinting] = useState(false);
  const { userType } = useSelector(state => state.setup.enums);
  const isCustomer = user.userType === userType.customer.value;
  const orientationOptions = [
    { name: 'Retrato', value: 'portrait' },
    { name: 'Paisagem', value: 'landscape' }
  ];

  const onSubmit = () => {
    setIsPrinting(true);
    return printFunction({ elementId: 'renderPdf', name: 'arquivo', orientation }).then(res => {
      setIsPrinting(false);
      afterSubmit(res);
    });
  };

  const titleLowerCase = word => {
    return word.toLowerCase().replace(/(?:^|\s)(?!da|de|do)\S/g, l => l.toUpperCase());
  };

  const vobiTip = `Escolha a visualização ou aplique filtros na aba de ${title}
  para poder exportar somente o que deseja`;

  return (
    <>
      <Drawer
        title="Configurar e baixar PDF"
        submitText="Baixar"
        onClose={onClose}
        open={open}
        onSubmit={onSubmit}
        width="100vw"
        backgroundColor={colors.neutral90}
        padding="0px"
      >
        <Row style={{ backgroundColor: 'white', paddingLeft: 32, paddingRight: 24 }}>
          <Row>
            <ColMargin xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
              <div style={{ background: '#E2F2FF', padding: '8px', borderRadius: '4px' }}>
                <FontAwesomeIcon style={{ color: '#0091FF', marginRight: '8px' }} icon={faSparkles} />
                <Text>
                  <strong>Dica Vobi: </strong>
                  {customTip || vobiTip}
                </Text>
              </div>
            </ColMargin>
          </Row>
        </Row>
        <Row style={{ backgroundColor: 'white', paddingLeft: 24, paddingRight: 24, paddingTop: 16, paddingBottom: 16 }}>
          <ColMarginLeft xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
            <Row>
              {!isCustomer && (
                <ColMargin xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                  <StyledText>Titulo do PDF</StyledText>
                  <div style={{ paddingTop: 8 }}>
                    <Input type="text" value={titleLowerCase(title)} onChange={e => setTitle(e.target.value)} />
                  </div>
                </ColMargin>
              )}
              <ColMargin xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                <StyledText>Orientação do PDF</StyledText>
                <div style={{ paddingTop: 8 }}>
                  <Select
                    name="idOrientation"
                    options={orientationOptions}
                    value={orientation}
                    placeholder="Selecione..."
                    onChange={selected => setOrientation(selected)}
                  />
                </div>
              </ColMargin>
              {isCustomer && (
                <ColMargin xxl={19} xl={19} lg={19} md={19} sm={19} xs={19}>
                  {CheckComponent}
                </ColMargin>
              )}
            </Row>
            {!isCustomer && (
              <ColMargin xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                <StyledText>Descrição/Observações</StyledText>

                <div style={{ paddingTop: 8 }}>
                  <TextArea
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    style={{ width: '100vw' }}
                  />
                </div>
              </ColMargin>
            )}
          </ColMarginLeft>
          <ColMarginRight xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
            {!isCustomer && (
              <div style={{ display: 'flex', paddingLeft: 16 }}>
                <div style={{ width: '170px', paddingTop: '8px' }}>
                  <StyledText>Logo do negócio</StyledText>
                  <Tooltip placement="bottom" title="Tamanho máximo 50mb">
                    <FontAwesomeIcon
                      style={{ marginLeft: spaces.space1 }}
                      color={colors.primary40}
                      icon={faInfoCircle}
                    />
                  </Tooltip>
                  <div style={{ marginRight: 159, paddingTop: '8px', marginLeft: -8 }}>
                    <FileUpload
                      onChange={image => setLogo(image?.fullpath)}
                      multiple={false}
                      accept="image/*"
                      galleryProps={{ small: false }}
                      onRemove={() => setLogo(null)}
                      large
                    />
                  </div>
                </div>
                <ColMargin xxl={19} xl={19} lg={19} md={19} sm={19} xs={19}>
                  {CheckComponent}
                </ColMargin>
              </div>
            )}
          </ColMarginRight>
        </Row>
        <div style={{ paddingTop: 16, paddingLeft: 42 }}>
          <Text style={{ fontSize: fonts.sizeLg }}>
            <strong>Visualização do PDF:</strong>
          </Text>
        </div>
        <div style={{ padding: 16 }}>
          <Box id="renderPdf">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Space direction="vertical" size={16}>
                <Text style={{ fontSize: fonts.sizeH5 }}>{titleLowerCase(title)}</Text>
                {!isCustomer && <Text style={{ color: colors.neutral40, whiteSpace: 'pre-line' }}>{description}</Text>}
              </Space>
              <Space size={16}>
                <ImageParser cors src={logo} logo />
              </Space>
            </div>
            {PdfComponent}
          </Box>
        </div>
      </Drawer>
      <Modal
        open={isPrinting}
        closable={false}
        hideCloseBtn
        hideFooter
        title="Estamos gerando seu PDF"
        width="500px"
        height="240px"
      >
        <CenteredLoader text="Aguarde..." />
      </Modal>
    </>
  );
};

ExportToPdfDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.instanceOf(Object),
  name: PropTypes.string,
  orientationPdf: PropTypes.string,
  customTip: PropTypes.string,
  refurbish: PropTypes.instanceOf(Object),
  PdfComponent: PropTypes.instanceOf(Object),
  CheckComponent: PropTypes.instanceOf(Object),
  afterSubmit: PropTypes.func,
  printFunction: PropTypes.func
};

export default ExportToPdfDrawer;
