import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styles/style';

const Div = styled.div`
  border-left: 12px solid ${colors.primary40};
  border-bottom: 12px solid transparent;
  position: absolute;
  top: 0;
  left: 0;
`;

const ParentBadget = () => {
  return <Div />;
};

export default ParentBadget;
