import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { fonts, colors, spaces, breakpoints } from '../../styles/style';

const StyleInfoSidebar = styled.div`
  flex: 1;
  height: 100%;
  position: relative;
  background-color: ${colors.white};
`;

const StyledIcon = styled(FontAwesomeIcon)`
  margin: 0 ${spaces.space2} ${spaces.space3} ${spaces.space2};
  font-size: ${fonts.sizeLg};
  color: ${colors.primary40};
  cursor: pointer;
`;

const StyledRenderList = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 16px;
`;

const StyledChevron = styled(FontAwesomeIcon)`
  position: absolute;
  font-size: ${fonts.sizeLg};
  top: 26px;
  left: -9px;
  background-color: #ffffff;
  z-index: 2;
  cursor: pointer;

  @media (max-width: ${breakpoints.tablet}) {
    display: none;
  }
`;

export { StyleInfoSidebar, StyledIcon, StyledChevron, StyledRenderList };
