import dayjs from 'dayjs';
import { sumByField } from './helper';

const calculatePercentage = (price, total) =>
  total !== 0 ? parseFloat(((parseFloat(price || 0) * 100) / parseFloat(total)).toFixed(2)) : 0;

const calculatePriceByPercentage = (percentage, total) =>
  parseFloat(((parseFloat(percentage || 0) / 100) * parseFloat(total)).toFixed(2));

const sortByDueDate = arr =>
  arr.sort((a, b) => {
    if (dayjs(a.dueDate).isAfter(b.dueDate)) return 1;
    if (dayjs(a.dueDate).isBefore(b.dueDate)) return -1;
    return 0;
  });
const sortByStatus = arr =>
  arr.sort((a, b) => {
    if (a.idInstallmentStatus === 2) return -1;
    if (b.idInstallmentStatus === 2) return 1;
    return 0;
  });

const getLastDueDate = arr => {
  if (arr.length === 0) return null;
  return dayjs(arr[arr.length - 1].dueDate);
};

const generateInstallments = (quantity, total, installments, otherData, resetInstallments = false) => {
  const _total = parseFloat(total || 0);
  const paidInstallments = installments?.filter(item => item.idInstallmentStatus === 2) || [];

  if (paidInstallments.length > quantity) {
    return { error: 'Número de parcelas deve ser maior que a quantidade de parcelas pagas' };
  }

  const installmentsValue = sumByField(installments, 'price') || 0;

  if ((quantity === installments?.length && installmentsValue === _total) || _total <= 0)
    return sortByDueDate(installments);

  if (quantity === installments.length && paidInstallments.length === 0 && !resetInstallments) {
    let totalPrice = 0;
    const newInstallments = installments.map((item, index) => {
      let price = calculatePriceByPercentage(item.percentage, _total);
      if (index === installments.length - 1) price = _total - totalPrice;
      else totalPrice += price;
      return { ...item, price };
    });
    return sortByDueDate(newInstallments);
  }

  const paidValue = sumByField(paidInstallments, 'price') || 0;

  if (paidValue === _total) return sortByDueDate(paidInstallments);

  const quantityWithoutPaidQuantity = quantity - paidInstallments.length;
  const valueWithoutPaidValue = _total - paidValue;

  let newArray = Array(quantity).fill(null);

  const price = parseFloat((valueWithoutPaidValue / quantityWithoutPaidQuantity).toFixed(2));
  const lastPrice = parseFloat((valueWithoutPaidValue - price * (quantityWithoutPaidQuantity - 1)).toFixed(2));

  let dueDate = getLastDueDate(installments) || dayjs(otherData?.billingDate || new Date());
  let totalOfPercentage = 0;
  const installmentSortedByStatus = sortByStatus(installments);
  newArray = newArray.map((_, index) => {
    const isLastItemOfArray = index === quantity - 1;
    const hasInstallment = installmentSortedByStatus[index];
    let newPrice = isLastItemOfArray ? lastPrice : price;
    let newDescription = !otherData
      ? hasInstallment.description
      : `${otherData?.paymentName || 'Sua descrição aqui'} ${hasInstallment?.number || index + 1}/${quantity}`;

    if (hasInstallment?.idInstallmentStatus === 2) {
      newPrice = parseFloat(hasInstallment.price);
      newDescription = hasInstallment.description;
    }
    let percentage = calculatePercentage(newPrice, _total);

    if (!isLastItemOfArray) {
      totalOfPercentage += percentage;
    } else {
      percentage = parseFloat((100 - totalOfPercentage).toFixed(2));
    }

    if (hasInstallment) {
      return {
        ...hasInstallment,
        percentage,
        price: newPrice,
        description: newDescription
      };
    }
    dueDate = dayjs(otherData?.billingDate).add(index + 1, 'month');

    return {
      idPayment: otherData?.idPayment,
      installmentStatus: {
        name: 'Aguardando'
      },
      percentage,
      price: newPrice,
      idCompany: otherData?.idCompany,
      idInstallmentStatus: 1,
      dueDate,
      description: newDescription
    };
  });

  return sortByDueDate(newArray).map((item, index) => ({ ...item, number: index + 1 }));
};

const recalculateInstallments = (current, value, installments) => {
  if (!current) return null;
  const _installments = [...installments];
  const { index, installment } = current;
  _installments[index] = installment;
  const previousInstallments = _installments.slice(0, index + 1);
  const restOfInstallments = _installments.slice(index + 1);
  const totalPriceOfPrevious = sumByField(previousInstallments, 'price') || 0;
  const totalToDistribute = value - totalPriceOfPrevious;

  const _restInstallments =
    totalToDistribute > 0
      ? generateInstallments(restOfInstallments.length, totalToDistribute, restOfInstallments, null, true)
      : [];
  let totalOfPercentage = 0;
  const _newInstallments = previousInstallments.concat(_restInstallments).map((item, i) => {
    let percentage = 0;
    if (i !== _installments.length - 1) {
      percentage = calculatePercentage(item.price, value);
      totalOfPercentage += percentage;
    } else {
      percentage = parseFloat((100 - totalOfPercentage).toFixed(2));
    }

    return { ...item, percentage, number: i + 1 };
  });

  return _newInstallments;
};

export { recalculateInstallments, generateInstallments, calculatePercentage, calculatePriceByPercentage };
