import React from 'react';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import styled from 'styled-components';
import generatePicker from 'antd/lib/date-picker/generatePicker';
import 'antd/lib/date-picker/style/index';
import ptBR from 'antd/es/locale/pt_BR';
import { colors, fonts } from '../../styles/style';

const Picker = generatePicker(dayjsGenerateConfig);

const StyledPicker = styled(Picker)`
  &.ant-picker {
    font-family: ${fonts.family};
    border-radius: ${props => (props.size === 'small' ? '4px' : '8px')};
    color: ${colors.neutral30};

    input {
      color: ${colors.neutral30};
    }

    &:hover {
      border-color: ${colors.neutral30};
    }
  }

  &.ant-picker-small {
    input {
      font-size: ${fonts.sizeSm};
    }
  }

  &.ant-picker-focused {
    border-color: ${colors.neutral30};
    box-shadow: none;
  }
`;

const StyledPanel = styled.div`
  font-family: ${fonts.family};
  border-radius: 4px;
  font-size: 12px;
  color: ${colors.neutral30};

  .ant-picker-header {
    border-bottom: none;
    span {
      color: ${colors.primary40};
    }
  }

  .ant-picker-content {
    thead {
      border-bottom: 1px solid ${colors.neutral90};
      tr > th {
        color: ${colors.neutral60};
      }
    }

    .ant-picker-cell {
      color: ${colors.neutral60};

      &.ant-picker-cell-in-view {
        color: ${colors.neutral30};
      }

      &.ant-picker-cell-today,
      .ant-picker-cell-today {
        border-radius: 12px;
      }
    }
  }
`;

const DatePicker = ({ ...props }) => (
  <StyledPicker
    {...props}
    locale={ptBR.DatePicker}
    panelRender={current => {
      return <StyledPanel>{current}</StyledPanel>;
    }}
  />
);

export default DatePicker;
