import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import Button from '../Button/Button';
import { colors, fonts } from '../../styles/style';

// style
import css from './NoContent.module.scss';

const NoContent = ({
  image,
  icon,
  title,
  description = 'Adicione um novo item para vê-lo na lista',
  renderButton = true,
  btnTitle = 'Adicionar novo',
  onClick,
  showButtonIcon = true,
  extraButton: ExtraButton,
  style,
  buttonId
}) => {
  const history = useHistory();
  const { pathname } = history.location;

  const handleClick = () => {
    if (onClick) {
      return onClick();
    }
    return history.push(`${pathname}/novo`);
  };

  return (
    <div className={css['c-no-content']} style={style}>
      {image ? <div className={css['c-no-content__image']}>{image}</div> : null}
      <div className={css[image ? 'c-no-content__info-left' : 'c-no-content__info']}>
        {icon && (
          <div className={css['c-no-content__icon']}>
            <FontAwesomeIcon icon={icon} size="3x" />
          </div>
        )}
        <div className={css['c-no-content__content']}>
          {title ? <h6>{title}</h6> : null}
          {description ? (
            <p
              style={{
                whiteSpace: 'normal',
                color: colors.neutralSecondary60,
                fontSize: fonts.sizeLg
              }}
            >
              {description}
            </p>
          ) : null}
          {renderButton ? (
            <Button align={image ? 'auto' : 'center'} id={buttonId} type="primary" onClick={handleClick}>
              {showButtonIcon && <FontAwesomeIcon icon={faPlus} />}
              {btnTitle}
            </Button>
          ) : (
            ExtraButton && <ExtraButton />
          )}
        </div>
      </div>
    </div>
  );
};

NoContent.propTypes = {
  btnTitle: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  image: PropTypes.instanceOf(Object),
  icon: PropTypes.instanceOf(Object),
  onClick: PropTypes.func,
  renderButton: PropTypes.bool,
  title: PropTypes.string,
  showButtonIcon: PropTypes.bool,
  extraButton: PropTypes.instanceOf(Object),
  style: PropTypes.instanceOf(Object),
  buttonId: PropTypes.string
};

export default NoContent;
