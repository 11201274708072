import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { toast } from 'react-toastify';
import Dropdown from './Dropdown';
import Button from '../Button/Button';
import TemplatesModal from '../Modal/TemplatesModal';
import EditInfoModal from '../Modal/EditInfoModal';
import { spaces } from '../../styles/style';

const QuickCreateActions = () => {
  return (
    <Dropdown
      alignLeftOptions
      mappingKey="quickCreateActions"
      trigger={['click']}
      modal={{
        templateModal: params => <TemplatesModal {...params} isRefurbish />,
        editInfoModal: params => (
          <EditInfoModal
            {...params}
            canChangeRefurbish
            afterSubmit={() => toast.success('Tarefa criada com sucesso')}
            title="Nova tarefa"
            copyLinkParam={null}
            initData={{ status: 1 }}
            generalTask
          />
        )
      }}
    >
      <Button
        type="primary"
        style={{ width: spaces.space10, height: spaces.space4, justifyContent: 'flex-start', padding: spaces.space1 }}
        id="quick-create-actions-button"
      >
        <FontAwesomeIcon icon={faPlus} style={{ marginRight: spaces.space1 }} />
        Novo
      </Button>
    </Dropdown>
  );
};

export default QuickCreateActions;
