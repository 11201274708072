import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import { Content, FinishSteps, Cell } from './Onboarding.styled';
import Finish1 from '../../../components/Images/finish1.svg';
import Finish2 from '../../../components/Images/finish2.svg';
import Finish3 from '../../../components/Images/finish3.svg';
import GooglePlay from '../../../components/Images/google-play.svg';
import AppleStore from '../../../components/Images/apple-store.svg';
import Button from '../../../components/Button/Button';

const { Text } = Typography;

const Finished = ({ onSubmit }) => (
  <Content $center $paddingTop="70px">
    <h5>Pronto! Sua jornada começa agora.</h5>
    <Text>Explore nossas funcionalidades e comece agora mesmo!</Text>
    <FinishSteps>
      <Cell>
        <div className="cell-content">
          <img src={Finish1} alt="Passo 1" />
          <Text className="large">1. Baixe nosso aplicativo</Text>
          <br />
          <Text className="info-text">
            Revolucione seu escritório e tenha a gestão da sua empresa na palma de suas mãos.
          </Text>
          <br />
          <div className="grid-footer">
            <Link to={{ pathname: 'https://play.google.com/store/apps/details?id=com.app.vobi' }} target="_blank">
              <img src={GooglePlay} alt="Google Play" />
            </Link>
            <Link to={{ pathname: 'https://apps.apple.com/br/app/vobi/id1604435355' }} target="_blank">
              <img src={AppleStore} alt="Apple Store" />
            </Link>
          </div>
        </div>
      </Cell>
      <Cell>
        <div className="cell-content">
          <img src={Finish2} alt="Passo 2" />
          <Text>2. Agende seu treinamento</Text>
          <br />
          <Text className="info-text">Receba treinamento ao vivo dos nossos especialistas.</Text>
          <br />
          <div className="grid-footer">
            <Link to={{ pathname: 'https://vobi.com.br/suporte?from=onboarding' }} target="_blank">
              AGENDAR
            </Link>
          </div>
        </div>
      </Cell>
      <Cell>
        <div className="cell-content">
          <img src={Finish3} alt="Passo 3" />
          <Text>3. Convide sua equipe</Text>
          <br />
          <Text className="info-text">Não esqueça de adicionar sua equipe também.</Text>
          <br />
          <div className="grid-footer">
            <Link to={{ pathname: '/profissional/configuracoes/equipe?from=onboarding' }} target="_blank">
              CONVIDAR
            </Link>
          </div>
        </div>
      </Cell>
    </FinishSteps>
    <Button type="primary" onClick={onSubmit}>
      Ir para plataforma
    </Button>
  </Content>
);

Finished.propTypes = {
  onSubmit: PropTypes.func
};

export default Finished;
