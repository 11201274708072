import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/pro-light-svg-icons';
import { faPenToSquare } from '@fortawesome/pro-solid-svg-icons';
import { colors } from '../../styles/style';
import TooltipIcon from '../Icons/TooltipIcon';
import { EditContainer, StyledDiv } from './EditableInput.style';

const EditableInput = ({
  icon = false,
  value,
  onChange = () => {},
  noWrap = false,
  disabled,
  initEdit,
  map,
  withEditButton,
  onClick,
  rowId,
  ...props
}) => {
  const [_value, setValue] = useState(value || '-');
  const [edit, setEdit] = useState(initEdit);

  useEffect(() => {
    setValue(value);
  }, [value]);

  useEffect(() => {
    if (rowId) {
      const expandedRows = document.getElementsByClassName('expanded-row');
      const element = document.querySelector(`[data-row-key="${rowId}"]`);
      if (edit && element) {
        element.setAttribute('draggable', false);
        expandedRows.forEach(item => item.setAttribute('draggable', false));
      } else if (element) {
        element.setAttribute('draggable', true);
        expandedRows.forEach(item => item.setAttribute('draggable', true));
      }
    }
  }, [edit]);

  const handleBlur = ({ target: { value: newValue } = {} }) => {
    if (!value && !newValue) setEdit(false);
    else if (edit && value !== newValue) {
      let _newValue = newValue;
      if (map) {
        _newValue = map(newValue);
        setValue(_newValue);
      }
      onChange(_newValue);
    }

    setEdit(false);
  };

  const handleKey = e => {
    if (e.key === 'Escape') {
      setEdit(false);
      setValue(value);
    }
    if (e.key === 'Enter') {
      handleBlur({ target: { value: _value } });
    }
  };

  if (!disabled && edit) {
    return (
      <Input
        style={{ fontSize: '12px' }}
        autoFocus
        placeholder=""
        value={_value}
        onKeyDown={handleKey}
        onChange={e => setValue(e.target.value)}
        onBlur={handleBlur}
        onClick={e => e.stopPropagation()}
      />
    );
  }

  if (withEditButton) {
    return (
      <EditContainer onClick={onClick}>
        <StyledDiv role="presentation" cursor="pointer" disabled noWrap={noWrap} {...props}>
          {_value || '-'}
        </StyledDiv>
        {!disabled && (
          <TooltipIcon
            style={{ height: 16, width: 16 }}
            className="renameItem"
            text="Renomear"
            icon={faPenToSquare}
            iconColor={colors.neutralSecondary40}
            onClick={() => {
              setEdit(true);
            }}
          />
        )}
      </EditContainer>
    );
  }

  return (
    <StyledDiv
      role="presentation"
      disabled={disabled}
      noWrap={noWrap}
      onClick={e => {
        e.stopPropagation();
        setEdit(true);
      }}
      {...props}
    >
      {_value || '-'}
      {icon && <FontAwesomeIcon icon={faPencilAlt} size="xs" />}
    </StyledDiv>
  );
};

EditableInput.propTypes = {
  icon: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  noWrap: PropTypes.bool,
  disabled: PropTypes.bool,
  initEdit: PropTypes.bool,
  map: PropTypes.func,
  withEditButton: PropTypes.bool,
  onClick: PropTypes.func,
  rowId: PropTypes.number
};

export default EditableInput;
