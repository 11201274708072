import { Card } from 'antd';
import styled from 'styled-components';
import { fonts, spaces } from '../../styles/style';

const StyledCard = styled(Card)`
  cursor: pointer;
  height: 100%;
  width: 300px;

  .ant-card-body {
    padding: ${spaces.space1};
    .ant-card-meta {
      font-size: ${fonts.sizeMd};
    }

    .ant-card-meta-title {
      font-size: ${fonts.sizeMd};
    }
  }
`;

const StyledBtnCard = styled(Card)`
  cursor: pointer;
  height: 100%;
  width: 300px;
  background-color: rgba(0, 145, 255, 0.04);
  &.ant-card-bordered {
    border: 1px solid #0091ff;
  }
  .btn-container {
    height: 135px;
    width: 100%;
    position: relative;
    div {
      text-align: center;
      width: 180px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    div > h4 {
      font-weight: 500;
      color: #0091ff;
    }
  }
`;

const StyleTag = styled.div`
  width: 48px;
  height: ${spaces.space3};
  text-align: center;
  position: absolute;
  z-index: 1;
  top: 15px;
  left: 15px;
  background-color: #ffffff;

  img {
    vertical-align: middle;
  }
`;

const StyledDiv = styled.div`
  margin: ${spaces.space2};
  position: relative;
  button {
    display: none;
    z-index: 2;
    position: absolute;
    right: -${spaces.space1};
    top: -${spaces.space1};
  }
  :hover {
    button {
      display: block;
    }
    p {
      display: block;
    }
  }
`;

export { StyledDiv, StyledCard, StyleTag, StyledBtnCard };
