import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import useCRUD from '../../_Hooks/useCRUD';
import { parseSearchableOptions, removeAccents } from '../../lib/helpers/helper';
import Button from '../Button/Button';
import { SelectStyled } from './Select.style';
import { parseArrayAsObject } from '../../lib/helpers/parseArrayAsObject';

const Select = ({
  id,
  value,
  color,
  dataType,
  model,
  options,
  modelOptions = null,
  customLabel = null,
  customValue = null,
  valueField,
  setupName = 'systemData',
  dark,
  onChange,
  allowCreate,
  children,
  lazyLoad,
  refetchOptions,
  disabled,
  createParams,
  ...props
}) => {
  const { [dataType]: systemData = [] } = useSelector(state => state.setup[setupName]);
  const [_options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [selectedObj, setSelectedObj] = useState('');
  const [_disabled, setDisabled] = useState(disabled);
  const [_value, setValue] = useState(value);
  const [offset, setOffset] = useState(1);
  const selectRef = useRef(null);

  const _modelOptions = modelOptions || { where: { isActive: true } };
  const { loading, totalItems, list, handleGet, handleCreate } = useCRUD({
    model,
    options: { ..._modelOptions, limit: 10, offset },
    immediatelyLoadData: false
  });

  const handleCreateOption = () =>
    handleCreate({ values: { ...createParams, name: searchValue }, refresh: false }).then(newItem => {
      onChange(newItem?.id);

      handleGet().then(() => {
        setSearchValue('');
        if (selectRef?.current?.blur) selectRef.current.blur();
      });
    });

  const filterFunction = (input, option) => {
    if (!input) {
      return true;
    }

    if (customLabel) {
      const _customLabel = option?.label?.props?.children?.toString()?.toLowerCase();
      return !_customLabel || removeAccents(_customLabel).indexOf(removeAccents(input.toLowerCase())) >= 0;
    }

    return option.label && removeAccents(option.label).indexOf(removeAccents(input.toLowerCase())) >= 0;
  };

  useEffect(() => {
    setValue(value);

    if (model && value && !options) {
      handleGet({ refetchPathOptions: `/${value}` }).then(item => {
        if (item) {
          const parsedOption = parseSearchableOptions([item], customLabel, customValue);
          setOptions(prev => {
            const object = parseArrayAsObject(prev);
            return [...(prev || []), ...parsedOption.filter(p => !object[p?.[customValue] || p?.value])];
          });
        }
      });
    }
  }, [value]);

  useEffect(() => {
    if (model && !options) {
      const opt = refetchOptions || _modelOptions;
      handleGet({
        refetchOptions: {
          ...opt,
          where: {
            ...opt?.where,
            ulike: {
              [`${model}.name`]: `%${removeAccents(searchValue)}%`
            },
            ...(_value && { id: { ne: _value } })
          },
          limit: 10,
          offset
        }
      });
    }
  }, [refetchOptions, offset, searchValue]);

  useEffect(() => {
    if (list?.length) {
      const parsedOption = parseSearchableOptions(list, customLabel, customValue);
      setDisabled(!allowCreate && parsedOption.length === 0);
      setOptions(prev => {
        const object = parseArrayAsObject(prev);
        return [...(prev || []), ...parsedOption.filter(p => !object[p?.[customValue] || p?.value])];
      });
    }
  }, [list]);

  useEffect(() => {
    if (options?.length) {
      setOptions(parseSearchableOptions(options, customLabel, customValue));
    } else if (systemData?.length) {
      const parsedOption = systemData
        .filter(item => !item._type)
        .map(item => ({ value: item.value, label: item.label }));

      setOptions(parsedOption);
    }
  }, [options, lazyLoad]);

  useEffect(() => {
    if (_options?.length) {
      setSelectedObj(_options.find(o => o.value === _value || Number(o.value) === Number(_value)));
    }
  }, [_value, _options]);

  const onPopupScroll = e => {
    e.persist();
    const { target } = e;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight && totalItems > _options?.length) {
      setOffset(prev => prev + 1);
    }
  };

  return (
    <SelectStyled
      ref={selectRef}
      id={id}
      {...(children ? { value: children.value } : selectedObj || { value: undefined })}
      {...(children ? { value: children.value } : { options: _options })}
      loading={loading}
      $dark={dark}
      searchValue={searchValue}
      showSearch
      onSearch={search => setSearchValue(search)}
      notFoundContent={
        allowCreate && model && searchValue ? (
          <Button type="link" onClick={handleCreateOption}>
            {`+ criar "${searchValue}"`}
          </Button>
        ) : null
      }
      onChange={onChange}
      filterOption={filterFunction}
      disabled={_disabled}
      onPopupScroll={onPopupScroll}
      {...props}
    />
  );
};

Select.propTypes = {
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.number, PropTypes.string]),
  color: PropTypes.instanceOf(Object),
  dataType: PropTypes.string,
  model: PropTypes.string,
  multiple: PropTypes.bool,
  options: PropTypes.instanceOf(Array),
  modelOptions: PropTypes.instanceOf(Object),
  customLabel: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array, PropTypes.string]),
  customValue: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array, PropTypes.string]),
  onChange: PropTypes.func,
  setupName: PropTypes.string,
  dark: PropTypes.bool,
  valueField: PropTypes.string,
  allowCreate: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array, PropTypes.string]),
  refresh: PropTypes.number,
  lazyLoad: PropTypes.bool,
  refetchOptions: PropTypes.instanceOf(Object),
  disabled: PropTypes.bool,
  createParams: PropTypes.instanceOf(Object)
};

export default Select;
