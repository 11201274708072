import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, Space, Tooltip, Typography } from 'antd';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';

import { AlmostThereColumns, Content } from './Onboarding.styled';
import Input from '../../../components/Input/Input';
import Select from '../../../components/Select/Select';
import NavigationButton from '../../../components/Button/NavigationButton';
import useViewport from '../../../_Hooks/useViewport';

import { colors } from '../../../styles/style';

const { Text } = Typography;

const CompanyDetails = ({ data, isValid, onChange, onReturn, onSubmit }) => {
  const { isMobile: isMobileFn } = useViewport(window.innerWidth);
  const { serviceList } = useSelector(state => state.setup.systemData);

  const [_data, setData] = useState(data);
  const [currentSubStep, setCurrentSubStep] = useState(1);

  const isMobile = isMobileFn();
  const canSubmit = (isMobile && currentSubStep === 1 && _data?.onboarding?.services?.length > 0) || isValid;

  const handleSubmit = (...args) => {
    if (!isMobile || currentSubStep === 2) onSubmit(...args);
    else setCurrentSubStep(2);
  };

  const handleChange = e => {
    setData(prev => {
      const s = { onboarding: { ...prev?.onboarding, ...e } };
      onChange(s);
      return s;
    });
  };

  return (
    <Content>
      <AlmostThereColumns>
        {currentSubStep === 1 ? (
          <div className="column">
            <Space size={16} direction="vertical">
              <h6>Estamos quase lá!</h6>
              <Text>
                <b>Quais serviços você oferece? </b> <small>(múltipla escolha)</small>
              </Text>
              <Checkbox.Group value={_data?.onboarding?.services} onChange={e => handleChange({ services: e })}>
                <Space size={12} direction="vertical">
                  {serviceList.map(s => (
                    <Checkbox value={s.value} key={`service${s.value}`}>
                      {s.label}
                      {s.tooltipText && (
                        <Tooltip title={s.tooltipText}>
                          <span id={s.tooltipId}>
                            <FontAwesomeIcon style={{ marginLeft: 4 }} color={colors.primary40} icon={faInfoCircle} />
                          </span>
                        </Tooltip>
                      )}
                    </Checkbox>
                  ))}
                </Space>
              </Checkbox.Group>
            </Space>
          </div>
        ) : null}
        {!isMobile || currentSubStep === 2 ? (
          <div className="column">
            <Space size={16} direction="vertical">
              <h6>{isMobile ? 'Estamos quase lá!' : <>&nbsp;</>}</h6>
              <Text>
                <b>Quantos projetos sua empresa está executando no momento?</b>
              </Text>
              <Input
                type="text"
                placeholder="Ex: 04"
                value={_data?.onboarding?.projectCount}
                onChange={e => handleChange({ projectCount: e?.target?.value })}
                style={{ width: '200px' }}
              />
              <Text>
                <b>Você possui informações organizadas para serem migradas para plataforma?</b>
              </Text>
              <Select
                id="migrationData"
                name="migrationData"
                type="select"
                placeholder="Escolha uma opção"
                value={_data?.onboarding?.migrationData}
                onChange={e => handleChange({ migrationData: e })}
                dataType="migrationDataList"
                style={{ width: '200px' }}
              />
              <Text>
                <b>Seus projetos são principalmente comerciais ou residenciais?</b>
              </Text>
              <Select
                id="projectType"
                name="projectType"
                type="select"
                placeholder="Escolha uma opção"
                value={_data?.onboarding?.projectType}
                onChange={e => handleChange({ projectType: e })}
                dataType="projectTypeList"
                style={{ width: '200px' }}
              />
            </Space>
          </div>
        ) : null}
      </AlmostThereColumns>
      <NavigationButton onSubmit={handleSubmit} onReturn={onReturn} canSubmit={canSubmit} $fullWidth={isMobile} />
    </Content>
  );
};

CompanyDetails.propTypes = {
  data: PropTypes.instanceOf(Object),
  isValid: PropTypes.bool,
  onReturn: PropTypes.func,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func
};

export default CompanyDetails;
