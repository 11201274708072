import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import { spaces, breakpoints } from '../../styles/style';

const Container = styled.div`
  padding: ${props => (props.padding || props.padding === 0 ? `${props.padding}px` : spaces.space2)};

  h3,
  h4 {
    &:first-of-type {
      margin-top: 0;
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    padding: ${props => (props.padding || props.padding === 0 ? `${props.padding}px` : spaces.space1)};
  }
`;

const Content = ({ children, className, padding, ...otherProps }) => {
  return (
    <Container className={className} padding={padding} {...otherProps}>
      {children}
    </Container>
  );
};

Content.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  padding: PropTypes.number
};

export default Content;
