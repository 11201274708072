import * as Yup from 'yup';
import React from 'react';

import { faWrench } from '@fortawesome/pro-solid-svg-icons';
import { name } from '../schema';
import formatNumber from '../../helpers/formatNumber';

import { SectionTitle } from './itemSchema.styles';
import formatCurrency from '../../helpers/formatCurrency';

const productMapping = {
  brand: {
    name: 'Qual a marca?',
    type: 'text',
    placeholder: `Ex: "Deca"`
  },
  images: {
    name: 'Imagens',
    tooltipText: 'Tamanho máximo 50mb',
    type: 'file',
    xs: 24,
    textArray: true,
    text: 'Enviar'
  }
};

const laborMapping = {
  description: {
    name: 'Qual descrição?',
    type: 'text',
    xs: 24,
    placeholder: `Ex: "Orçamento de Marcenaria de todos os cômodos"`
  }
};

const commonFieldsMapping = ({ id, type }) => ({
  ...(!id
    ? {
        type: {
          name: 'Qual tipo de item*',
          type: 'select',
          placeholder: 'Selecione o tipo',
          dataType: 'refurbishItemTypes'
        }
      }
    : {}),
  name: {
    name: 'Item',
    type: 'text',
    placeholder: `Informe o nome`
  },
  ...(type !== 3
    ? {
        idSupplier: {
          name: 'Qual a fornecedor?',
          type: 'select',
          placeholder: 'Nome do Fornecedor',
          model: 'supplier',
          allowCustomOptions: true,
          allowClear: true
        }
      }
    : {}),
  idRefurbishGroup: {
    name: 'Qual o grupo?',
    type: 'select',
    placeholder: 'Selecione o grupo',
    model: 'refurbishGroup',
    allowCustomOptions: true,
    allowClear: true,
    modelOptions: {
      order: [['name', 'asc']]
    }
  },
  idCostCenter: {
    name: 'Qual a categoria?',
    type: 'select',
    placeholder: 'Selecione a categoria',
    model: 'costCenter',
    allowCustomOptions: true,
    allowClear: true
  },
  price: {
    name: 'Valor',
    type: 'currency',
    placeholder: 'R$ 0,00',
    defaultValue: '0,00',
    maskOptions: { allowNegative: true },
    parseFunc: val => formatNumber(val)
  },
  link: {
    name: 'Qual o link do item?',
    type: 'text'
  },
  description: {
    name: 'Descrição',
    type: 'textarea',
    placeholder: 'Informe a descrição (opcional)',
    md: 24
  },
  ...(type === 1 ? productMapping : laborMapping)
});

const newFieldsMapping = ({ type, isCustomer }) => {
  return {
    header: {
      type: 'custom',
      xs: 24,
      md: 24,
      Component: () => <SectionTitle style={{ paddingTop: 0 }}>{type === 1 ? 'Produto' : 'Serviço'}</SectionTitle>
    },
    name: {
      name: 'Nome:',
      type: isCustomer ? 'string' : 'textarea',
      placeholder: `Informe o nome`,
      md: 24,
      disabled: isCustomer
    },
    ...(type === 1
      ? {
          images: {
            name: 'Imagens:',
            tooltipText: isCustomer ? null : 'Tamanho máximo 50mb',
            type: 'file',
            md: 24,
            textArray: true,
            text: 'Adicionar imagem',
            galleryType: 'new',
            buttonProps: { type: 'primary', $noIcon: true },
            buttonObrigatory: true,
            listType: null,
            fullWidth: true,
            noPadding: true,
            disabled: isCustomer
          }
        }
      : {}),
    body: {
      type: 'custom',
      xs: 24,
      md: 24,
      Component: () => <SectionTitle border>{`Detalhes do ${type === 1 ? 'Produto' : 'Serviço'}`}</SectionTitle>
    },
    ...(type !== 3
      ? {
          idSupplier: {
            name: 'Fornecedor:',
            type: isCustomer ? 'string' : 'select',
            displayStringAs: 'name',
            placeholder: 'Nome do fornecedor',
            model: 'supplier',
            allowCustomOptions: true,
            allowClear: true,
            md: 24,
            disabled: isCustomer
          }
        }
      : {}),
    price: {
      name: 'Valor:',
      type: isCustomer ? 'string' : 'currency',
      prefix: 'R$',
      placeholder: 'R$ 0,00',
      defaultValue: '0,00',
      maskOptions: { allowNegative: true },
      parseFunc: val => formatNumber(val),
      md: 12,
      disabled: isCustomer
    },
    ...(type === 1
      ? {
          brand: {
            name: 'Marca:',
            type: isCustomer ? 'string' : 'text',
            placeholder: 'Marca',
            md: 12,
            disabled: isCustomer
          }
        }
      : {}),
    idRefurbishGroup: {
      name: 'Grupo:',
      type: isCustomer ? 'string' : 'select',
      displayStringAs: 'name',
      placeholder: 'Grupo',
      model: 'refurbishGroup',
      allowCustomOptions: true,
      allowClear: true,
      md: 12,
      disabled: isCustomer
    },
    idCostCenter: {
      name: 'Categoria:',
      type: isCustomer ? 'string' : 'select',
      displayStringAs: 'name',
      placeholder: 'Categoria',
      model: 'costCenter',
      allowCustomOptions: true,
      allowClear: true,
      md: 12,
      disabled: isCustomer
    },
    link: {
      name: 'Link:',
      type: isCustomer ? 'string' : 'text',
      placeholder: `Informe o link do ${type === 1 ? 'produto' : 'serviço'}`,
      md: type === 1 ? 24 : 12,
      disabled: isCustomer
    },
    ...(type === 1
      ? {
          height: {
            name: 'Altura:',
            type: isCustomer ? 'string' : 'number',
            placeholder: '0',
            md: 6,
            suffix: 'cm',
            withoutArrows: true,
            disabled: isCustomer
          },
          width: {
            name: 'Largura:',
            type: isCustomer ? 'string' : 'number',
            placeholder: '0',
            suffix: 'cm',
            withoutArrows: true,
            md: 6,
            disabled: isCustomer
          },
          length: {
            name: 'Comprimento:',
            type: isCustomer ? 'string' : 'number',
            placeholder: '0',
            md: 6,
            suffix: 'cm',
            withoutArrows: true,
            disabled: isCustomer
          },
          weight: {
            name: 'Peso:',
            type: isCustomer ? 'string' : 'number',
            placeholder: '0',
            md: 6,
            suffix: 'kg',
            withoutArrows: true,
            disabled: isCustomer
          }
        }
      : {}),
    footer: {
      type: 'custom',
      xs: 24,
      md: 24,
      Component: () => <SectionTitle border>Descrição</SectionTitle>
    },
    description: {
      type: isCustomer ? 'string' : 'textarea',
      placeholder: 'Informe a descrição (opcional)',
      md: 24,
      disabled: isCustomer
    }
  };
};

const inlineFieldsMapping = idCompany => ({ type = 1, quantity, price }) => ({
  ...(type === 1
    ? {
        images: {
          name: '',
          type: 'image',
          md: 1,
          style: { paddingTop: '14px', display: 'flex', placeContent: 'center' }
        }
      }
    : {
        icon: {
          type: 'icon',
          icon: faWrench,
          md: 1,
          style: { paddingTop: '26px', display: 'flex', placeContent: 'center' }
        }
      }),
  name: {
    name: 'Item',
    type: 'text',
    placeholder: `Nome`,
    md: 6,
    id: 'add-item-name-input'
  },
  idSupplier: {
    name: 'Fornecedor',
    type: 'select',
    placeholder: 'Fornecedor',
    model: 'supplier',
    modelOptions: { where: { idCompany: idCompany || null }, order: [['name']] },
    allowCustomOptions: true,
    allowClear: true,
    md: 3
  },
  idRefurbishGroup: {
    name: 'Grupo',
    type: 'select',
    placeholder: 'Grupo',
    model: 'refurbishGroup',
    modelOptions: { where: { idCompany: idCompany || null }, order: [['name']] },
    allowCustomOptions: true,
    allowClear: true,
    md: 3
  },
  idCostCenter: {
    name: 'Categoria',
    type: 'select',
    placeholder: 'Categoria',
    model: 'costCenter',
    modelOptions: { where: { idCompany: idCompany || null }, order: [['name']] },
    allowCustomOptions: true,
    allowClear: true,
    md: 3
  },
  price: {
    name: 'Valor unit.',
    type: 'currency',
    placeholder: 'R$ 0,00',
    defaultValue: '0,00',
    maskOptions: { allowNegative: true },
    parseFunc: val => formatNumber(val),
    md: 2
  },
  quantity: {
    name: 'Quantidade',
    type: 'number',
    placeholder: 'Quantidade',
    defaultValue: '1',
    maskOptions: { allowNegative: true },
    parseFunc: val => formatNumber(val),
    md: 2
  },
  idUnit: {
    name: 'Unidade',
    type: 'select',
    placeholder: 'Un.',
    dataType: 'unit',
    allowClear: true,
    md: 2
  },
  total: {
    name: 'Total',
    type: 'custom',
    placeholder: 'R$ 0,00',
    defaultValue: 'R$ 0,00',
    md: 2,
    style: { paddingRight: '4px', paddingLeft: '4px' },
    Component: () => (
      <p>{formatCurrency(formatNumber(quantity || 0) * formatNumber(price || 0), { currencySymbol: 'R$' })}</p>
    )
  }
});

const inlineFieldsMappingTemplate = idCompany => props => inlineFieldsMapping(idCompany)(props);
const inlineFieldsMappingSpecification = idCompany => props => inlineFieldsMapping(idCompany)(props);

const itemSchema = Yup.object().shape({
  type: Yup.string().nullable(),
  name: name.min(3, 'Nome deve ter pelo menos 3 caracteres'),
  idCostCenter: Yup.object().nullable(),
  idRefurbishGroup: Yup.object().nullable(),
  idSupplier: Yup.object().nullable(),
  link: Yup.string().nullable()
});

export {
  itemSchema,
  commonFieldsMapping,
  productMapping,
  laborMapping,
  inlineFieldsMappingTemplate,
  inlineFieldsMappingSpecification,
  newFieldsMapping
};
