import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@homehero/hero-style';

import SubHeader from '../components/Header/SubHeader';
import Content from '../components/Content/Content';

const NoAccess = ({ hasAccess }) =>
  hasAccess === null ? (
    <div style={{ height: 630, paddingTop: 10 }}>
      <iframe
        style={{ width: '100%', height: '100%' }}
        title="upgradeModal"
        src={`${process.env.REACT_APP_BUBBLE_URL}/assinatura-upgrade-modal`}
      />
    </div>
  ) : (
    <>
      <SubHeader title="Acesso inválido" level={5} withBackLink />
      <Content>
        <Text>Você não tem permissão para acessar essa página</Text>
      </Content>
    </>
  );

NoAccess.propTypes = {
  hasAccess: PropTypes.bool
};

export default NoAccess;
