import * as Yup from 'yup';

import AboutYou from './AboutYou';
import MainChallenges from './MainChallenges';
import CompanyDetails from './CompanyDetails';
import StepsSettings from './StepsSettings';
import FirstProject from './FirstProject';
import Presentation from './Presentation';
import Finished from './Finished';

export default {
  'bem-vindo': {
    Content: Presentation,
    hideFooter: true,
    nextStep: 'sobre-voce'
  },
  'sobre-voce': {
    Content: AboutYou,
    previousStep: 'bem-vindo',
    nextStep: 'principais-desafios',
    hideFooter: false,
    schema: Yup.object().shape({
      onboarding: Yup.object().shape({
        configDescription: Yup.number().required(),
        configExperience: Yup.number().required()
      })
    })
  },
  'principais-desafios': {
    Content: MainChallenges,
    previousStep: 'sobre-voce',
    nextStep: 'mais-detalhes',
    hideFooter: false,
    schema: Yup.object().shape({
      onboarding: Yup.object().shape({
        mainChallenge: Yup.number().required()
      })
    })
  },
  'mais-detalhes': {
    Content: CompanyDetails,
    hideFooter: true,
    previousStep: 'principais-desafios',
    nextStep: 'configure-etapas',
    schema: Yup.object().shape({
      onboarding: Yup.object().shape({
        services: Yup.number().required(),
        projectCount: Yup.number().required(),
        projectType: Yup.number().required(),
        migrationData: Yup.number().required()
      })
    })
  },
  'configure-etapas': {
    Content: StepsSettings,
    hideFooter: true,
    previousStep: 'mais-detalhes',
    nextStep: 'primeiro-projeto'
  },
  'primeiro-projeto': {
    Content: FirstProject,
    hideFooter: true,
    previousStep: 'configure-etapas',
    nextStep: 'pronto'
  },
  pronto: {
    Content: Finished,
    hideFooter: true
  }
};
