import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Modal as AntModal } from 'antd';
import Button from '../Button/Button';
import { colors, fonts, breakpoints, spaces } from '../../styles/style';
import CenteredLoader from '../Loader/CenteredLoader';

const StyledModal = styled(AntModal)`
  max-width: 100%;
  padding-bottom: 0;
  border-radius: 16px;

  .ant-modal-content {
    border-radius: 16px;
    height: 100%;
    overflow: auto;
    ::-webkit-scrollbar {
      display: none;
    }
  }

  .ant-modal-header {
    border: none;
    padding-bottom: ${spaces.space2};
    border-bottom: ${props => (props.headerLine ? `1px solid ${colors.neutralSecondary95}` : 'none')};
    .ant-modal-title {
      font-family: ${fonts.familySecondary};
      font-weight: 300;
      font-size: ${fonts.sizeH6};
    }
  }

  .ant-modal-body {
    padding-top: 0;

    ${props =>
      props.noPadding &&
      css`
        padding: 0;
        height: 100%;
      `}

    .mainSection {
      padding-top: ${props => (props.noPadding ? 0 : 16)}px;
      height: 100%;
    }

    p {
      color: ${colors.neutral50};
    }
  }

  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
    background-color: ${colors.neutral90};
    padding: ${spaces.space1};
  }

  @media (min-width: ${breakpoints.desktop}) {
    .ant-modal-title {
      font-size: ${fonts.sizeH5};
    }

    .ant-modal-footer {
      padding: 12px ${spaces.space3};
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    margin: 0 auto;
    vertical-align: bottom !important;

    .ant-modal-content {
      border-radius: 16px 16px 0 0;
    }
    .ant-modal-footer {
      background-color: ${colors.white};
    }
    .ant-modal-title {
      font-family: ${fonts.family};
    }

    ${props =>
      props.left &&
      css`
        max-width: 87%;
        height: 100%;
        vertical-align: top !important;
        left: -7%;
        .ant-modal-content {
          border-radius: 0;
        }
        .ant-modal-close {
          left: 0;
        }
        .ant-modal-body {
          p {
            font-size: ${fonts.sizeLg};
          }
          svg {
            font-size: ${fonts.sizeH6};
          }
        }
      `}
  }
`;

const StyledSubtitle = styled.p`
  padding-top: 0;
  font-size: ${fonts.sizeMd};
  color: ${colors.neutral50};
`;

const Modal = ({
  title,
  subtitle,
  open,
  formId,
  onSubmit = () => {},
  onClose = () => {},
  width = 700,
  centered = true,
  submitText = 'Confirmar',
  cancelText = 'Cancelar',
  children,
  loading,
  loadingText,
  footer,
  hideFooter,
  noPadding,
  height,
  left,
  sectionStyle,
  closable = true,
  headerLine,
  zIndex
}) => {
  const _footer = footer || (
    <>
      <Button onClick={onClose} loading={loading}>
        {cancelText}
      </Button>
      <Button onClick={onSubmit} form={formId} htmlType="submit" type="primary" loading={loading}>
        {submitText}
      </Button>
    </>
  );
  // eslint-disable-next-line no-restricted-globals
  const _width = width && isNaN(width) ? width : `${width}px`;
  // eslint-disable-next-line no-restricted-globals
  const _height = height && isNaN(height) ? height : `${height}px`;

  return (
    <StyledModal
      title={title}
      visible={open}
      onCancel={onClose}
      width={_width}
      centered={centered}
      footer={hideFooter ? null : _footer}
      noPadding={noPadding}
      height={_height}
      left={left}
      closable={closable}
      headerLine={headerLine}
      zIndex={zIndex}
    >
      {subtitle ? <StyledSubtitle>{subtitle}</StyledSubtitle> : null}
      {loading ? (
        <CenteredLoader text={loadingText} />
      ) : (
        <section style={sectionStyle} className="mainSection">
          {children}
        </section>
      )}
    </StyledModal>
  );
};

Modal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  subtitle: PropTypes.string,
  open: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  centered: PropTypes.bool,
  submitText: PropTypes.string,
  cancelText: PropTypes.string,
  children: PropTypes.instanceOf(Object).isRequired,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
  footer: PropTypes.instanceOf(Object),
  hideFooter: PropTypes.bool,
  noPadding: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  left: PropTypes.bool,
  formId: PropTypes.string,
  sectionStyle: PropTypes.instanceOf(Object),
  closable: PropTypes.bool,
  headerLine: PropTypes.bool,
  zIndex: PropTypes.number
};

export default Modal;
