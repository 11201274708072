import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@homehero/hero-style';
import { useSelector } from 'react-redux';

// hooks
import useCRUD from '../../_Hooks/useCRUD';

// components
import ConfirmModal from '../Modal/ConfirmModal';

const ReleasePaymentButton = ({ id, isPaid, idOrder, idRefurbish }) => {
  const [showModal, setShowModal] = useState(false);
  const [_isPaid, setPaid] = useState(isPaid);

  const {
    paymentStatus: { released }
  } = useSelector(state => state.setup.enums);

  const { handleUpdate, loading } = useCRUD({
    model: 'refurbish-item-instalment',
    pathOptions: `/${id}`,
    immediatelyLoadData: false
  });

  const toggleModal = show => setShowModal(show);

  const handleClick = () => {
    const sendValues = { status: released, sendEmail: true, idOrder, idRefurbish };
    handleUpdate({ values: sendValues, refresh: false }).then(resp => {
      toggleModal(false);
      if (resp && !resp.error) {
        setPaid(true);
      }
    });
  };

  const btnText = _isPaid ? 'Pagamento liberado' : 'Liberar Pagamento';

  return (
    <>
      <Button
        style={{ padding: '8px 16px' }}
        type="primary"
        onClick={() => toggleModal(true)}
        size={1}
        disabled={_isPaid || loading}
      >
        {btnText}
      </Button>
      {showModal && (
        <ConfirmModal
          text="Deseja realmente liberar  este pagamento?"
          onSubmit={handleClick}
          onClose={() => toggleModal(false)}
        />
      )}
    </>
  );
};

ReleasePaymentButton.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isPaid: PropTypes.bool.isRequired,
  idOrder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  idRefurbish: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default memo(ReleasePaymentButton);
