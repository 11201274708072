import * as Yup from 'yup';
import React from 'react';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import { faCopy } from '@fortawesome/pro-solid-svg-icons';
import Button from '../../../components/Button/Button';

const schema = Yup.object().shape({
  email: Yup.string().email('Email inválido')
});

const mappingForm = (handleCopyToClipboard, link, loading) => {
  return {
    email: {
      name: 'E-mail',
      type: 'text',
      placeholder: 'E-mail',
      xs: 24,
      md: 19
    },
    button: {
      type: 'custom',
      Component: () => (
        <Button
          loading={loading}
          type="primary"
          style={{
            marginLeft: 'auto',
            width: '100%',
            marginTop: '25px'
          }}
          htmlType="submit"
          form="emailSharingForm"
        >
          Enviar
        </Button>
      ),
      md: 5
    },
    link: {
      name: 'Link',
      type: 'text',
      placeholder: 'Link',
      xs: 24,
      md: 19,
      disabled: true
    },
    linkButton: {
      type: 'custom',
      Component: () => (
        <div
          style={{
            width: '100%',
            marginTop: '25px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start'
          }}
        >
          <Tooltip placement="bottom" title="Copiar link" style={{ marginRight: '16px' }}>
            <Button type="primary" text onClick={handleCopyToClipboard}>
              <FontAwesomeIcon icon={faCopy} style={{ fontSize: 16 }} />
            </Button>
          </Tooltip>
          <Tooltip placement="bottom" title="Enviar via whatsapp">
            <a
              href={`https://wa.me/?text=Olá,%20tudo%20bem?%20Gostaria
              %20de%20compartilhar%20uma%20cobrança%20com%20você,%20acesse%20agora%20%20através%20desse%20link:%20
                  ${link}
                  `}
              target="_blank"
            >
              <FontAwesomeIcon icon={faWhatsapp} style={{ fontSize: 16 }} />
            </a>
          </Tooltip>
        </div>
      ),
      md: 5
    }
  };
};

export { schema, mappingForm };
