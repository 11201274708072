import styled from 'styled-components';
import { breakpoints } from '../../styles/style';

const ContentTable = styled.div`
  font-size: 12px;
  box-shadow: 0 8px 16px 0 #00000014;
  border-radius: 8px;
  margin: ${props => props.margin};
  @media (min-width: ${breakpoints.tablet}) {
    background-color: #fff;
  }
`;

export default ContentTable;
