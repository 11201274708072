import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, ShapeAction, Text } from '@homehero/hero-style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faDownload, faSearchPlus } from '@fortawesome/pro-regular-svg-icons';
import UnknownFileIcon from '../Icons/UnknownFile';
import CenteredLoader from '../Loader/CenteredLoader';

import css from './GalleryItem.module.scss';

const GalleryItem = ({ image, index, onRemove, onSelectImage, noDownload, noPreview, small, unique, className }) => {
  const classes = classNames({
    [css['c-gallery-item']]: true,
    [css['c-gallery-item--small']]: small,
    [css['c-gallery-item--unique']]: unique,
    [className]: true
  });

  return (
    <div className={classes}>
      {image.status === 'uploading' ? (
        <CenteredLoader text="" />
      ) : (
        <>
          <div className={css['c-gallery-item__bg']} />
          {image.fullpath && !image.fullpath.toLowerCase().match(/(jpg|jpeg|gif|png|webp)((\?.*)$|$)/gm) ? (
            <div>
              <UnknownFileIcon />
              <Text sm ellipsis>
                {image.filename}
              </Text>
            </div>
          ) : (
            <div
              style={{ backgroundImage: `url("${image.fullpath || image.src}")` }}
              className={css['c-gallery-item__img']}
            />
          )}
          <div className={css['c-gallery-item__hover']}>
            {!noPreview ? (
              <Button
                noShadow
                container="outlined"
                type="disabled"
                size={1}
                hasSVG
                onClick={() => onSelectImage(image, index)}
              >
                <FontAwesomeIcon icon={faSearchPlus} />
                Pré-visualizar
              </Button>
            ) : null}
            <span>
              {!noDownload ? (
                <a href={image.fullpath} target="_blank" rel="noopener noreferrer">
                  {small ? (
                    <ShapeAction size={1} isButton shape="squared">
                      <FontAwesomeIcon icon={faDownload} />
                    </ShapeAction>
                  ) : (
                    <Button noShadow container="outlined" type="disabled" hasSVG size={1}>
                      <FontAwesomeIcon icon={faDownload} />
                      Baixar
                    </Button>
                  )}
                </a>
              ) : null}
              <ShapeAction onClick={() => onRemove(index)} fill="danger" size={1} isButton shape="squared">
                <FontAwesomeIcon icon={faTrash} />
              </ShapeAction>
            </span>
          </div>
        </>
      )}
    </div>
  );
};

GalleryItem.propTypes = {
  image: PropTypes.instanceOf(Object),
  onRemove: PropTypes.func,
  index: PropTypes.number,
  onSelectImage: PropTypes.func,
  small: PropTypes.bool,
  noDownload: PropTypes.bool,
  noPreview: PropTypes.bool,
  unique: PropTypes.bool,
  className: PropTypes.string
};

export default GalleryItem;
