import { store } from '../../config/redux-store';

const getOptions = user => {
  const { setup } = store.getState();

  let options;

  if (user?.userType === setup.enums?.userType.provider.value) options = { integrations: { All: true } };
  if (user?.userType === setup.enums?.userType.customer.value || !user)
    options = { integrations: { All: false, Mixpanel: true } };

  return options;
};

const SegmentPlugin = (_segment = window.analytics) => {
  let segment = _segment;
  if (typeof _segment?.ready === 'function') {
    _segment.ready(() => {
      segment = window.analytics;
    });
  }

  const identify = user => {
    const options = getOptions(user);
    if (!user || !options) return;

    segment?.identify && segment.identify(user.userId, user, options);
    segment?.group && user?.companyId && segment.group(user?.companyId, {}, options);
  };

  const track = ({ event, user, eventProperties = {} }) => {
    const options = getOptions(user);
    if (!user || !options) return;

    segment?.track && segment.track(event, eventProperties, options);
  };

  const pageview = ({ user, eventProperties }) => {
    const options = getOptions(user);
    if (!options) return;

    segment?.page && segment.page(null, null, eventProperties, options);
  };

  return { identify, track, pageview };
};

export default SegmentPlugin;
