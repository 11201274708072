import * as Yup from 'yup';
import { idReference, name, date, installments, paymentTypes } from '../schema';

const paymentSchema = Yup.object().shape({
  name,
  billingDate: date,
  idCompanyCustomer: idReference,
  idRefurbish: idReference,
  paymentItems: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required('Campo obrigatório'),
        price: Yup.string()
          .nullable()
          .required('Campo obrigatório'),
        quantity: Yup.number(),
        total: Yup.number(),
        description: Yup.string()
      })
    )
    .min(1, 'Campo obrigatório'),
  installments,
  paymentTypes,
  total: Yup.number().moreThan(0, 'Valor deve ser diferente de zero')
});

export { paymentSchema };
