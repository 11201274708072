import { faMagic, faCheck, faCopy, faEmptySet } from '@fortawesome/pro-regular-svg-icons';

const nameAsc = { label: 'Nome A-z', order: [['name', 'ASC']], verb: 'get' };
const nameDesc = { label: 'Nome Z-a', order: [['name', 'DESC']], verb: 'get' };
const createdAtAsc = { label: 'Criado em (crescente)', order: [['createdAt', 'ASC']], verb: 'get' };
const createdAtDesc = { label: 'Criado em (decrescente)', order: [['createdAt', 'DESC']], verb: 'get' };

const priceAsc = { label: 'Menor preço', order: ['price', 'ASC'], verb: 'get' };
const priceDesc = { label: 'Maior preço', order: ['price', 'DESC'], verb: 'get' };
const topSale = { label: 'Recomendado', order: [], verb: 'get' };

const bar = { bar: true, label: 'bar' };
const editItem = { label: 'Editar', id: 'edit', modal: 'editItem', verb: 'update', modalParams: { tab: '0' } };
const addItem = [
  {
    label: 'Procurar um item no catálogo',
    externalAction: true,
    modal: 'searchModal',
    values: { type: 1 },
    verb: 'post',
    id: 'Search-item-catalog'
  },
  {
    label: 'Criar um novo produto',
    verb: 'post',
    id: 'Create-new-product',
    values: { type: 1 }
  },
  {
    label: 'Criar um novo serviço',
    id: 'Create-new-service',
    verb: 'post',
    values: { type: 2 }
  }
];
const deleteAction = {
  label: 'Apagar',
  verb: 'delete',
  id: 'delete',
  modal: 'confirmModal',
  modalParams: { text: 'Deseja realmente apagar esse item?' },
  displayToast: 'Operação realizada com sucesso!'
};

const duplicateItem = {
  label: 'Duplicar este item',
  verb: 'post',
  id: 'duplicate-item',
  pathOptions: '/duplicate'
};
const removeItemFromGroup = {
  label: 'Retirar item do grupo',
  verb: 'put',
  id: 'remove-item-group',

  pathOptions: '/remove-group'
};

const cancelProject = {
  label: 'Cancelar projeto',
  modal: 'confirmModal',
  id: 'cancel-project',
  verb: 'put',
  modalParams: {
    text: 'Deseja realmente cancelar o projeto?'
  },
  values: { idStatus: 7 },
  iconProps: {
    icon: faEmptySet
  }
};
const duplicateProject = {
  id: 'duplicate-project',
  label: 'Duplicar projeto',
  modal: 'useAsTemplateModal',
  modalParams: {
    title: 'Novo Projeto',
    model: 'refurbish',
    pathOptions: '/duplicate',
    textHeader: `Você pode duplicar um projeto com suas etapas e tarefas, itens no orçamento,
    estrutura de pastas (sem os arquivos) e também suas anotações`,
    text: `Escolha o que deseja duplicar deste projeto.`,
    successText: 'Projeto duplicado com sucesso',
    modalType: 'Projeto',
    source: 'Projeto'
  },
  iconProps: {
    icon: faCopy
  }
};
const finishProject = {
  id: 'finish-project',
  label: 'Finalizar projeto',
  verb: 'put',
  modal: 'confirmModal',
  modalParams: {
    text: 'Deseja realmente finalizar o projeto?'
  },
  values: { idStatus: 6 },
  iconProps: {
    icon: faCheck
  }
};
const addToMyProducts = {
  label: 'Adicionar à meus produtos',
  verb: 'post',
  id: 'add-products',
  pathOptions: '/copy',
  displayToast: 'Produto adicionado em Meus Produtos com sucesso!'
};
const applyTemplatetoProject = {
  label: 'Salvar como template',
  modal: 'useAsTemplateModal',
  id: 'new-Template',
  modalParams: {
    title: 'Novo Template',
    model: 'template',
    pathOptions: '/refurbishToTemplate',
    textHeader: `Você pode criar um template de projeto com suas etapas e tarefas, itens no orçamento,
    estrutura de pastas (sem os arquivos) e também suas anotações. Assim, você terá sempre disponível esse
    template para utilizar em futuros projetos, sem precisar criar do zero.`,
    successText: 'Template criado com sucesso',
    text: 'Escolha o que deseja em seu template.',
    modalType: 'Template',
    source: 'Template'
  },
  iconProps: {
    icon: faMagic
  }
};

const taskDetails = {
  label: 'Detalhes',
  modal: 'editItemModal'
};

const copyLinkButton = {
  button: 'copyLink',
  buttonParams: {
    text: true,
    children: 'Copiar link'
  }
};
const attachmentButton = {
  label: 'Adicionar anexo',
  button: 'uploadFile',
  id: 'add-attachment',
  buttonParams: {
    multiple: true,
    id: 'add-attachment',
    buttonProps: { text: true, $noIcon: true },
    text: 'Adicionar anexo',
    type: 'button',
    showGallery: false,
    listType: null,
    noMobileMargin: true
  }
};

const cancelPayment = {
  label: 'Cancelar cobrança',
  modal: 'confirmModal',
  id: 'cancel-payment',
  verb: 'patch',
  modalParams: {
    text: 'Deseja realmente cancelar essa cobrança?'
  },
  values: { idPaymentStatus: 4 }
};

const paidInstallment = {
  label: 'Marcar como pago',
  modal: 'registerPayment',
  id: 'paid-installment',
  model: 'installment'
};

const unpaidInstallment = {
  label: 'Voltar para aguardando',
  verb: 'put',
  id: 'unpaid-installment',
  model: 'installment',
  values: { idInstallmentStatus: 1, paidDate: null, idPaymentType: null }
};

export {
  nameAsc,
  nameDesc,
  createdAtAsc,
  createdAtDesc,
  priceAsc,
  priceDesc,
  topSale,
  editItem,
  deleteAction,
  addItem,
  duplicateItem,
  removeItemFromGroup,
  cancelProject,
  duplicateProject,
  finishProject,
  addToMyProducts,
  applyTemplatetoProject,
  bar,
  taskDetails,
  copyLinkButton,
  attachmentButton,
  cancelPayment,
  paidInstallment,
  unpaidInstallment
};
