import styled from 'styled-components';

import { colors, breakpoints } from '../../styles/style';

export const Container = styled.div`
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: fixed;
  bottom: 48px;
  left: 50%;

  //O +45px é por causa da barra lateral que tem 90px
  transform: translate(calc(-50% + 45px), 0);

  @media (max-width: ${breakpoints.tablet}) {
    transform: translate(-50%, 0);

    width: 90vw;
  }

  border-radius: 8px;
  padding: 12px;
  background-color: ${colors.neutralSecondary30};
  color: ${colors.white};

  p {
    min-width: 100px;

    @media (min-width: 1150px) {
      min-width: 140px;
    }

    @media (max-width: ${breakpoints.tablet}) {
      min-width: 0;
    }
  }

  > div {
    display: flex;
    align-items: center;
  }
`;

export const CLoseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  height: 32px;
  width: 32px;
  border: 0;
  border-radius: 50%;
  background-color: ${colors.primary40};
  color: ${colors.white};
  transform: translateX(28px);

  @media (max-width: ${breakpoints.tablet}) {
    transform: translateX(0px);
    background-color: transparent;
    font-size: 18px;
  }
`;

export const ModalItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  p {
    margin-left: 8px;
    font-size: 18px;
  }
`;

export const BulkActionsChildrenContainer = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-left: 8px;
  }
`;
