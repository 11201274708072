import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Checkbox } from 'antd';
import isEqual from 'react-fast-compare';
import { colors, spaces } from '../../styles/style';

const StyleItem = styled.div`
  display: flex;
  min-height: ${props => props.$minHeight}px;
  position: relative;
  align-items: center;
  justify-content: space-between;

  ${props =>
    props.$searchList &&
    css`
      flex-wrap: wrap;
      justify-content: flex-start;
      padding: ${spaces.space1} ${spaces.space2} ${spaces.space1} ${spaces.space1};

      > span {
        flex-basis: 50%;
        padding: ${spaces.space1} 0;
      }

      .actions {
        position: absolute;
        right: 0px;
      }
    `}
`;

const StyleCheckbox = styled.div`
  position: absolute;
  left: ${props => props.left};
  top: ${props => props.top};
`;

const MobileCardItem = ({
  item,
  isChildren,
  open,
  columns,
  checked,
  longPressEvent,
  minHeight = 88,
  lastChildren = false,
  onClick = f => f,
  searchList
}) => {
  return (
    <StyleItem
      key={`mobile-card-item-${item.id}`}
      role="presentation"
      $minHeight={minHeight}
      $searchList={searchList}
      onClick={onClick}
    >
      {isChildren && (
        <div style={{ flex: 1, display: 'flex', marginLeft: 4, alignSelf: 'normal' }}>
          <div style={{ width: 1, background: colors.neutral60, height: lastChildren ? '50%' : '100%' }} />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: 8, background: colors.neutral60, height: 1 }} />
            <div style={{ width: 8, border: `1px solid ${colors.neutral60}`, height: 7 }} />
          </div>
        </div>
      )}
      {checked && (
        <StyleCheckbox
          top={columns[0]?.dataIndex === 'image' || !isChildren ? '4px' : '30px'}
          left={columns[0]?.dataIndex === 'image' ? '4px' : '10px'}
        >
          <Checkbox checked={checked} />
        </StyleCheckbox>
      )}
      {columns.map(col =>
        col.render ? (
          col.render(item[col.dataIndex], { ...item, isChildren, open, longPressEvent, onClick })
        ) : (
          <div>{item[col.dataIndex]}</div>
        )
      )}
    </StyleItem>
  );
};

MobileCardItem.propTypes = {
  item: PropTypes.instanceOf(Object),
  isChildren: PropTypes.bool,
  open: PropTypes.bool,
  columns: PropTypes.instanceOf(Array),
  checked: PropTypes.bool,
  longPressEvent: PropTypes.func,
  minHeight: PropTypes.number,
  lastChildren: PropTypes.bool,
  searchList: PropTypes.bool,
  onClick: PropTypes.func
};

export default React.memo(MobileCardItem, isEqual);
