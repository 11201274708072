import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';

import { colors } from '../../styles/style';

import Button from '../Button/Button';
import Draggable from '../Draggable/Draggable';
import EditInfoModal from './EditInfoModal';
import eventBus from '../../lib/helpers/eventBus';

const today = new Date(new Date().setHours(0, 0, 0, 0));
const tomorrow = new Date(new Date().setHours(24, 0, 0, 0));

const AddTaskModal = ({ refurbish, filter, onRefresh, generalTask } = {}) => {
  const [editModalId, setEditModalId] = useState(null);
  const [defaultPositionX, setDefaultPositionX] = useState(window.innerWidth - 200);

  const onMenuCollapsed = data => {
    const offset = data?.detail?.collapsed ? 200 : 375;
    setDefaultPositionX(window.innerWidth - offset);
  };

  useEffect(() => {
    eventBus.on('menuCollapsed', onMenuCollapsed);
    eventBus.dispatch('isMenuCollapsed');
    return () => eventBus.remove('menuCollapsed', onMenuCollapsed);
  }, []);

  const getInitData = _filter =>
    _filter?.endDate?.gte?.valueOf?.() === today?.valueOf?.() &&
    _filter?.endDate?.lte?.valueOf?.() === tomorrow?.valueOf?.()
      ? { status: 1, idStep: 1, idRefurbish: refurbish?.id, startDate: today, endDate: tomorrow }
      : { status: 1, idStep: 1, idRefurbish: refurbish?.id };

  const AddMsg = () => (
    <p>
      Tarefa adicionada no final da lista,{' '}
      <b>
        <u>clique aqui</u>
      </b>{' '}
      para editá-la.
    </p>
  );

  const AddTaskBtn = () => {
    return (
      <Draggable onClick={() => setEditModalId(0)} defaultPosition={{ x: defaultPositionX, y: 0 }}>
        <div
          style={{
            width: '48px',
            height: '48px',
            position: 'fixed',
            backgroundColor: colors.primary40,
            display: 'flex',
            alignItems: 'center',
            borderRadius: 24,
            bottom: 24,
            zIndex: 5
          }}
        >
          <Button type="primary" text>
            <Tooltip placement="top" title="Nova tarefa">
              <FontAwesomeIcon icon={faPlus} rotation={90} size="2x" color={colors.white} style={{ margin: '7px' }} />
            </Tooltip>
          </Button>
        </div>
      </Draggable>
    );
  };

  return (
    <>
      <AddTaskBtn />
      {editModalId != null && (
        <EditInfoModal
          id={editModalId}
          canChangeRefurbish={!refurbish?.id}
          initData={getInitData(filter)}
          idRefurbish={refurbish?.id}
          generalTask={generalTask}
          title={!editModalId ? 'Nova tarefa' : 'Editar'}
          onClose={(_, { task } = {}) => {
            setEditModalId(null);
            if (task?.id) {
              onRefresh && onRefresh(task);
              const _msg = editModalId ? 'Tarefa editada com sucesso.' : <AddMsg />;
              toast.success(_msg, {
                position: 'top-right',
                autoClose: 10000,
                onClick: () => {
                  setEditModalId(task.id);
                }
              });
            }
          }}
          copyLinkParam={null}
        />
      )}
    </>
  );
};

AddTaskModal.propTypes = {
  refurbish: PropTypes.instanceOf(Object),
  filter: PropTypes.instanceOf(Object),
  onRefresh: PropTypes.func,
  generalTask: PropTypes.bool
};

export default AddTaskModal;
