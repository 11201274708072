import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import useCRUD from '../_Hooks/useCRUD';

import CenteredLoader from '../components/Loader/CenteredLoader';
import { SETUP } from '../store/setup/action/action';

const Authentication = ({ children }) => {
  const dispatch = useDispatch();
  const { data, loading } = useCRUD({ model: 'setup' });
  const { list, loading: loadingPlan } = useCRUD({ model: 'plan', options: { include: ['permissions'] } });
  const { list: permissionList, loading: loadingPermissions } = useCRUD({ model: 'permission' });

  useEffect(() => {
    if (data) {
      dispatch({ type: SETUP.ENUMS, payload: data });
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (data) {
      dispatch({ type: SETUP.PLANS, payload: list });
    }
  }, [list, dispatch]);

  useEffect(() => {
    if (data) {
      dispatch({ type: SETUP.PERMISSIONS, payload: permissionList });
    }
  }, [permissionList, dispatch]);

  return loading || loadingPlan || loadingPermissions ? <CenteredLoader className="expansive" /> : children;
};

Authentication.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired
};

export default Authentication;
