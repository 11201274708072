import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import gtmIds from './gtm-ids';
import Button from '../Button/Button';

import SelectRefurbish from '../Modal/RefurbishListModal';

import { colors, spaces, fonts } from '../../styles/style';
import UserMenuOptions from './UserMenuOptions';

const StyledButton = styled(Button)`
  svg {
    margin: 0;
  }
`;

const StyledButtonDiv = styled.div`
  svg {
    margin: 0;
  }
`;

const ListItem = styled.li`
  text-align: initial;
  font-size: 0.75rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: ${colors.neutral90};
  }
  a,
  button,
  .gtm-item-div {
    &:hover {
      background-color: ${colors.neutral90};
    }
    border: none !important;
    background: none;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: ${colors.neutral50};
    transition: all 0.3s ease-in-out;
    font-weight: 400;
    height: 50px;
    width: 100%;
    font-size: ${fonts.sizeSm};
    padding: ${spaces.space2} ${spaces.space3};
    svg {
      margin-right: ${spaces.space1};
    }
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  background: ${colors.white};
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 8%);
  white-space: nowrap;
  z-index: 9999;
  border-radius: 8px;
  overflow: hidden;
  top: ${props => (props.$below ? '100%' : 'unset')};
  bottom: ${props => (props.$above ? '100%' : 'unset')};
  right: ${props => (props.$before ? '100%' : 'unset')};
`;

const OptionsHeader = styled.div`
  padding: ${spaces.space2} ${spaces.space3};
  text-align: start;
  border-bottom: 1px solid ${colors.neutralSecondary95};
  font-weight: bold;
  min-width: 224px;
`;

const UserMenu = ({ toggleMenu, above, before, below, wrapperRef }) => {
  const { ChangePasswordDrawer, options, params, UpgradeOption } = UserMenuOptions({ toggleMenu, wrapperRef });

  const _menuMapping = options;
  const { isCustomer, isMobile, openRefurbish, handleCancel, user } = params;

  return (
    <div>
      {isCustomer && !isMobile() ? (
        <SelectRefurbish open={openRefurbish} onSubmit={handleCancel} onCancel={handleCancel} />
      ) : null}
      <ChangePasswordDrawer />
      <DropdownMenu $above={above} $before={before} $below={below}>
        <OptionsHeader>Minha área</OptionsHeader>
        <ul>
          {_menuMapping.map(({ title, icon, onClick, to, shouldRender, key, external, upgrade }) => {
            if (!shouldRender) return null;

            if (onClick)
              return (
                <ListItem key={key}>
                  <StyledButton id={key} onClick={onClick}>
                    <FontAwesomeIcon icon={icon} />
                    {title}
                  </StyledButton>
                </ListItem>
              );
            if (upgrade) {
              return <UpgradeOption />;
            }

            if (to)
              return (
                <ListItem key={key}>
                  <Link
                    id={key}
                    style={{ color: colors.neutral50 }}
                    to={external ? { pathname: to } : `/${user.type + to}`}
                    target={external ? '_blank' : null}
                  >
                    <FontAwesomeIcon icon={icon} />
                    {title}
                  </Link>
                </ListItem>
              );
            return (
              <ListItem key={key}>
                <StyledButtonDiv className="gtm-item-div" id={gtmIds[key]} data-gtm-id={gtmIds[key]}>
                  <FontAwesomeIcon icon={icon} />
                  {title}
                </StyledButtonDiv>
              </ListItem>
            );
          })}
        </ul>
      </DropdownMenu>
    </div>
  );
};

UserMenu.propTypes = {
  toggleMenu: PropTypes.func,
  above: PropTypes.bool,
  below: PropTypes.bool,
  before: PropTypes.bool,
  wrapperRef: PropTypes.instanceOf(Object)
};
UserMenu.defaultProps = {
  toggleMenu: f => f,
  above: false,
  below: false,
  before: false,
  wrapperRef: null
};

export default UserMenu;
