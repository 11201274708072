import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dropdown as AntDropDown, Menu } from 'antd';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import { faLock } from '@fortawesome/pro-duotone-svg-icons';
import { Link } from 'react-router-dom';
import Button from '../Button/Button';
import { spaces, colors } from '../../styles/style';
import { allDropdown } from '../../lib/mapping/Dropdown/allDropdown';
import useCRUD from '../../_Hooks/useCRUD';
import Modal from '../Modal/Modal';
import useViewport from '../../_Hooks/useViewport';
import BubbleModalButton from '../Button/BubbleModalButton';
import { hasPermission } from '../../routes/Common/PrivateRoute';

const StyledDropDown = styled(AntDropDown)`
  cursor: pointer;
  padding: ${props => (props.padding ? `${props.padding}` : `${spaces.space1} ${spaces.space2}`)};
`;

const DropdownBar = styled.div`
  border-top: 1px solid ${colors.neutral60};
  border-radius: ${spaces.space1};
  width: 80%;
  margin: auto;
`;

const BubbleModalButtonContentDiv = styled.div`
  display: flex;
  align-items: center;
`;

const IconBox = styled.div`
  height: ${spaces.space4};
  width: ${spaces.space4};
  margin: ${props => (props.$right ? `0 0 0 ${spaces.space1}` : `0 ${spaces.space1} 0 0`)};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const StyledMenu = styled(Menu)`
  padding: 0;
  border: none;

  .ant-dropdown-menu-item {
    padding: 0;
    &:first-of-type {
      border-radius: ${spaces.space1} ${spaces.space1} 0 0;
    }

    &:last-of-type {
      border-radius: 0 0 ${spaces.space1} ${spaces.space1};
    }

    &:hover {
      background-color: ${colors.neutral90};

      .custom-btn {
        font-weight: 500;
        span {
          font-weight: 500;
        }
        color: ${colors.neutralSecondary40};
      }
    }

    .custom-btn {
      font-weight: 300;
      span {
        font-weight: 300;
      }
      padding: ${spaces.space2} ${props => (props.$alignLeftOptions ? spaces.space2 : spaces.space4)};
      width: 100%;
    }

    ${props =>
      props.$alignLeftOptions &&
      `
        .custom-btn {
           justify-content: left;
         }`};
  }
`;

const Dropdown = ({
  data,
  slim,
  mappingKey = 'default',
  modal = {},
  button = {},
  menu,
  model,
  options,
  forceSubmit,
  afterSubmit = f => f,
  buttonType = 'primary',
  customActions = null,
  ghost = true,
  children,
  customTrigger = {},
  className,
  onItemClick,
  alignLeftOptions = false,
  buttonPadding = false,
  ...props
}) => {
  const { handleGet, handleCreate, handleUpdate, handleDelete } = useCRUD({
    model,
    options,
    immediatelyLoadData: false
  });

  const actionFunctions = customActions || {
    get: ({ pathOptions: refetchPathOptions, options: refetchOptions, ...params }) =>
      handleGet({ refetchPathOptions, refetchOptions, ...params }),
    post: ({ pathOptions: postPathOptions, options: postOptions, ...params }) =>
      handleCreate({ postPathOptions, postOptions, refresh: false, ...params }),
    put: ({ pathOptions: updatePathOptions, options: updateOptions, ...params }) =>
      handleUpdate({ updatePathOptions, updateOptions, refresh: false, ...params }),
    delete: ({ pathOptions: deletePathOptions, options: deleteOptions, ...params }) =>
      handleDelete({ deletePathOptions, deleteOptions, refresh: false, ...params }),
    patch: ({ pathOptions: updatePathOptions, options: updateOptions, ...params }) =>
      handleUpdate({ updatePathOptions, updateOptions, verb: 'patch', refresh: false, ...params })
  };

  const [showModal, setShowModal] = useState({});
  const [showRenderModal, setShowRenderModal] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const { isMobile } = useViewport(window.innerWidth);
  const { user } = useSelector(state => state.authReducer);
  const { plans, permissions } = useSelector(state => state.setup);

  const { setFunction = f => f, value: customTriggerValue } = customTrigger;

  const handleSubmit = (key, newData = {}) => {
    const { id } = data || {};
    const { create } = newData || {};
    const { verb, pathOptions, displayToast, values, options: opts = {}, order, label } = key || {};
    const submitOptions = { ...options, ...opts };
    const afterSubmitParams = { id, actionType: verb, oldData: data };
    setShowRenderModal(false);
    setShowModal({});
    if (forceSubmit) {
      return forceSubmit({
        id,
        values: { ...data, ...newData, ...values },
        create,
        pathOptions,
        displayToast,
        refresh: false,
        refetchOptions: submitOptions,
        order,
        label,
        fromModal: key.modal
      })?.then(() => afterSubmit(afterSubmitParams));
    }
    return actionFunctions[verb]
      ? actionFunctions[verb]({
          id,
          values: { ...data, ...newData, ...values },
          pathOptions,
          displayToast,
          options: submitOptions
        }).then(resp => {
          return afterSubmit({ ...afterSubmitParams, newId: resp?.id, response: resp });
        })
      : afterSubmit(afterSubmitParams);
  };

  const handleClick = (key, index, e) => {
    if (isMobile()) {
      e.stopPropagation();
      e.preventDefault();
    }

    if (key.externalAction) {
      onItemClick({ ...key, values: { ...key.values, ...data } });
      return;
    }
    if (key.modal) {
      setShowModal({ [index]: true });
      return;
    }

    handleSubmit(key);
  };

  const onClose = (index, reload = true, resp) => {
    setShowRenderModal(false);
    setFunction(false);
    setShowModal({ [index]: false });
    reload && afterSubmit(null, resp);
  };

  useEffect(() => {
    setShowRenderModal(customTriggerValue);
  }, [customTriggerValue]);

  useEffect(() => {
    switch (typeof mappingKey) {
      case 'string':
        setDropdownOptions(allDropdown[mappingKey]);
        break;
      case 'function':
        setDropdownOptions(allDropdown[mappingKey(data)]);
        break;
      default:
        setDropdownOptions(mappingKey);
    }
  }, [mappingKey]);

  const optionContent = ({ iconProps, label }) => (
    <>
      {iconProps && (
        <IconBox>
          <FontAwesomeIcon icon={iconProps.icon} color={iconProps.color || colors.primary40} />
        </IconBox>
      )}
      {label}
    </>
  );

  const RenderOption = ({ optionData, index, ...buttonProps }) => {
    if (optionData.roles) {
      const _hasPermission = hasPermission(user, optionData.roles, plans, permissions);
      if (!_hasPermission) {
        return _hasPermission === null ? (
          <BubbleModalButton>
            <Button {...buttonProps} style={{ justifyContent: 'space-between' }} type="link">
              <BubbleModalButtonContentDiv>{optionContent(optionData)}</BubbleModalButtonContentDiv>
              <IconBox $right>
                <FontAwesomeIcon icon={faLock} color={colors.neutral80} />
              </IconBox>
            </Button>
          </BubbleModalButton>
        ) : null;
      }
    }

    if (optionData.redirectTo) {
      return (
        <Link to={optionData.redirectTo}>
          <Button {...buttonProps} type="link">
            {optionContent(optionData)}
          </Button>
        </Link>
      );
    }

    if (optionData.bar) {
      return <DropdownBar />;
    }

    return (
      <Button {...buttonProps} type="link" onClick={e => handleClick(optionData, index, e)}>
        {optionContent(optionData)}
      </Button>
    );
  };

  RenderOption.propTypes = {
    optionData: PropTypes.instanceOf(Object),
    index: PropTypes.number
  };

  const _menu = (
    <StyledMenu $alignLeftOptions={alignLeftOptions}>
      {menu ||
        dropdownOptions?.map((key, index) => {
          const ModalOption = modal[key.modal];
          const CustomButton = button[key.button];
          const RenderItemButton = CustomButton || RenderOption;

          return (
            <React.Fragment key={`menu${key.label}`}>
              <Menu.Item key={`menuItem${key.label}`}>
                <RenderItemButton
                  id={key.id}
                  onClose={reload => onClose(index, reload)}
                  optionData={key}
                  index={index}
                  className="custom-btn"
                  {...key.buttonParams}
                />
              </Menu.Item>
              {showModal[index] && ModalOption ? (
                <ModalOption
                  key={key.label}
                  onSubmit={newData => handleSubmit(key, newData)}
                  onClose={(reload, resp) => onClose(index, reload, resp)}
                  {...data}
                  {...key.modalParams}
                />
              ) : null}
            </React.Fragment>
          );
        })}
    </StyledMenu>
  );

  const MobileButton = children
    ? () =>
        React.cloneElement(children, {
          onClick: e => {
            e.stopPropagation();
            setShowRenderModal(true);
            setFunction(true);
          }
        })
    : null;

  return isMobile() ? (
    <>
      {MobileButton ? (
        <MobileButton />
      ) : (
        <Button
          type={buttonType}
          shape={slim ? 'text' : null}
          ghost={ghost}
          onClick={e => {
            e.stopPropagation();
            setShowRenderModal(true);
          }}
          style={{ padding: spaces.space1 }}
        >
          <FontAwesomeIcon icon={faEllipsisV} />
        </Button>
      )}
      {showRenderModal && (
        <Modal
          title="Opções"
          open
          hideFooter
          onClose={e => {
            e.stopPropagation();
            setShowRenderModal(false);
            setFunction(false);
          }}
        >
          {_menu}
        </Modal>
      )}
    </>
  ) : (
    <StyledDropDown overlay={_menu} overlayStyle={{ zIndex: 9999 }} overlayClassName="c-dropdown" slim {...props}>
      {children || (
        <Button
          style={{ padding: buttonPadding }}
          type={buttonType}
          shape={slim ? 'text' : null}
          ghost={ghost}
          onClick={e => e.stopPropagation()}
          icon={<FontAwesomeIcon icon={faEllipsisV} />}
        />
      )}
    </StyledDropDown>
  );
};

Dropdown.propTypes = {
  data: PropTypes.instanceOf(Object),
  slim: PropTypes.oneOfType([PropTypes.bool, PropTypes.instanceOf(Object)]),
  mappingKey: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Array)]),
  modal: PropTypes.instanceOf(Object),
  button: PropTypes.instanceOf(Object),
  menu: PropTypes.instanceOf(Object),
  model: PropTypes.string,
  options: PropTypes.instanceOf(Object),
  forceSubmit: PropTypes.func,
  afterSubmit: PropTypes.func,
  buttonType: PropTypes.string,
  ghost: PropTypes.bool,
  children: PropTypes.instanceOf(Object),
  customActions: PropTypes.instanceOf(Object),
  customTrigger: PropTypes.instanceOf(Object),
  className: PropTypes.string,
  onItemClick: PropTypes.func,
  alignLeftOptions: PropTypes.bool,
  buttonPadding: PropTypes.string
};

export default Dropdown;
