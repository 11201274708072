import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Dropdown } from '@homehero/hero-style';
import styled from 'styled-components';

import Avatar from '../AvatarEditor/Avatar';
import UserMenu from './UserMenu';
import { getInitials } from '../../lib/helpers/helper';
import { colors, breakpoints } from '../../styles/style';

const AvatarButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  cursor: pointer;
  border: none;
  border-top: ${props => (props.$noBorder ? 'none' : `solid 1px ${colors.neutral90}`)};
  background-color: transparent;
  padding: 0;
  @media (max-width: ${breakpoints.tablet}) {
    border: none;
    height: auto;
  }
`;

const UserProfile = ({ above, before, onClick = f => f, below, size = 'large' }) => {
  const { user } = useSelector(state => state.authReducer);

  const wrapperRef = useRef(null);
  const [showMenu, setShowMenu] = useState(false);

  const handleClick = () => setShowMenu(!showMenu);

  return (
    <div role="presentation" onClick={onClick} style={{ padding: '0 8px' }} ref={wrapperRef}>
      <Dropdown>
        {showMenu ? (
          <UserMenu toggleMenu={handleClick} wrapperRef={wrapperRef} above={above} before={before} below={below} />
        ) : null}
        <AvatarButton id="avatar-button" $noBorder={below} onClick={handleClick}>
          <Avatar size={size} src={user.avatarFullpath}>
            {getInitials(user.name)}
          </Avatar>
        </AvatarButton>
      </Dropdown>
    </div>
  );
};

UserProfile.propTypes = {
  above: PropTypes.bool,
  below: PropTypes.bool,
  before: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.string
};

export default UserProfile;
