import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Button as AntButton } from 'antd';
import { spaces, colors, fonts, breakpoints } from '../../styles/style';

const StyledButton = styled(AntButton)`
  display: flex;
  align-self: ${props => props.align || 'center'};
  align-items: center;
  padding: ${props => (props.$noPadding ? spaces.space1 : `${spaces.space1} ${spaces.space3}`)};
  white-space: nowrap;
  width: min-content;
  justify-content: center;
  background-position: center;
  font-weight: 500;
  color: ${colors.neutralSecondary40};
  cursor: pointer;
  font-size: ${fonts.sizeSm};
  transition: transform 0.5s;
  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${fonts.sizeMd};
  }
  svg {
    margin: 4px;
  }

  ${props =>
    props.$fullWidth &&
    css`
      width: 100%;
      justify-content: center;
    `}

  &.ant-btn-sm {
    font-size: ${fonts.sizeXs};
    padding: ${spaces.space2};
  }

  &.ant-btn-round {
    border-radius: ${props => props.$borderRadius || 24}px;
  }

  &.ant-btn-background-ghost {
    color: ${colors.neutralSecondary40};

    &:focus,
    &:hover {
      border-color: ${colors.neutralSecondary30};
      color: ${colors.neutralSecondary30};
    }
  }

  &:focus,
  &:hover {
    border-color: ${colors.neutralSecondary30};
    color: ${colors.neutralSecondary30};
  }

  &.ant-btn-default {
    border: solid 1px currentColor;

    &:active {
      color: currentColor;
      border: solid 1px currentColor;
      transform: scale(0.92);
    }
  }
  ${({ disabled }) =>
    !disabled &&
    css`
      &.ant-btn-primary {
        color: ${props => (props.$text ? colors.primary40 : colors.white)};
        border: solid 1px ${props => colors[props.$primaryBorderColor] || colors.primary40};

        &.ant-btn-background-ghost {
          color: ${colors.primary40};

          &:focus,
          &:hover {
            border-color: ${colors.primary30};
            color: ${colors.primary30};
          }
        }

        &:focus {
          background-color: ${colors.primary40};
        }
        &:hover {
          color: ${props => (props.$text ? colors.primary30 : colors.white)};
          border-color: ${colors.primary30};
          background-color: ${colors.primary30};
        }
      }
    `}

  &.ant-btn-success {
    color: ${props => (props.$text ? colors.success70 : colors.white)};
    background: ${colors.success70};
    border: solid 1px ${colors.success70};

    &.ant-btn-background-ghost {
      color: ${colors.success70};

      &:focus,
      &:hover {
        border-color: ${colors.success60};
        color: ${colors.success60};
      }
    }

    &:focus {
      background: ${colors.success70};
    }
    &:hover {
      color: ${props => (props.$text ? colors.success60 : colors.white)};
      border-color: ${colors.success60};
      background-color: ${colors.success60};
    }
  }

  &.ant-btn-warning {
    color: ${props => (props.$text ? colors.warning70 : colors.white)};
    background: ${colors.warning70};
    border: solid 1px ${colors.warning70};

    &.ant-btn-background-ghost {
      color: ${colors.warning70};

      &:focus,
      &:hover {
        border-color: ${colors.warning60};
        color: ${colors.warning60};
      }
    }

    &:focus {
      background: ${colors.warning70};
    }
    &:hover {
      color: ${props => (props.$text ? colors.warning60 : colors.white)};
      border-color: ${colors.warning60};
      background-color: ${colors.warning60};
    }
  }

  &.ant-btn-secondary {
    color: ${props => (props.$text ? colors.secondary60 : colors.white)};
    background: ${colors.secondary60};
    border: solid 1px ${colors.secondary60};

    &.ant-btn-background-ghost {
      color: ${colors.secondary60};

      &:focus,
      &:hover {
        border-color: ${colors.secondary50};
        color: ${colors.secondary50};
      }
    }

    &:focus {
      background: ${colors.secondary60};
    }
    &:hover {
      color: ${props => (props.$text ? colors.secondary50 : colors.white)};
      border-color: ${colors.secondary50};
      background-color: ${colors.secondary50};
    }
  }

  &.ant-btn-danger {
    color: ${props => (props.$text ? colors.error50 : colors.white)};
    background: ${colors.error50};
    border: solid 1px ${colors.error50};

    &.ant-btn-background-ghost {
      color: ${colors.error50};

      &:focus,
      &:hover {
        border-color: ${colors.error40};
        color: ${colors.error40};
      }
    }

    &:focus {
      background: ${colors.error50};
    }
    &:hover {
      color: ${props => (props.$text ? colors.error40 : colors.white)};
      border-color: ${colors.error40};
      background-color: ${colors.error40};
    }
  }

  &.ant-btn-white {
    background: ${colors.white};
    border: solid 1px ${colors.white};

    &.ant-btn-background-ghost {
      color: ${colors.white};
    }

    &:focus,
    &:hover {
      border-color: ${colors.white};
      color: ${colors.white};
    }
  }

  &.ant-btn-text,
  &.ant-btn-link {
    border: none;
    padding: ${props => (props.$noPadding ? 0 : spaces.space1)};
    background: none;

    &:after {
      animation: none !important;
    }

    &:active {
      border: none;
    }
  }

  ${props =>
    props.$text &&
    css`
      border: none !important;
      padding: ${props.$noPadding ? 0 : spaces.space1} !important;
      background: none !important;
      box-shadow: none !important;

      &:after {
        animation: none !important;
      }

      &:active {
        border: none !important;
      }
    `}
`;

const Button = ({
  id,
  type = 'default',
  shape = 'round',
  loading,
  children,
  fullWidth,
  noPadding,
  text,
  borderRadius,
  primaryBorderColor = 'primary40',
  ...props
}) => (
  <StyledButton
    id={id}
    type={type}
    shape={shape}
    loading={loading}
    $fullWidth={fullWidth}
    $noPadding={noPadding}
    $text={text}
    $borderRadius={borderRadius}
    $primaryBorderColor={primaryBorderColor}
    {...props}
  >
    {children}
  </StyledButton>
);

Button.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  shape: PropTypes.string,
  loading: PropTypes.bool,
  fullWidth: PropTypes.bool,
  noPadding: PropTypes.bool,
  text: PropTypes.bool,
  borderRadius: PropTypes.number,
  primaryBorderColor: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)])
};

export default Button;
