import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DraggableComponent from 'react-draggable';

const Draggable = ({ children, onClick, defaultPosition }) => {
  const [dragPosition, setDragPosition] = useState({});

  return (
    <DraggableComponent
      onStart={(e, data) => setDragPosition({ x: data.x, y: data.y })}
      onStop={(e, data) => {
        if (Math.abs(data.x - dragPosition.x) <= 8 && Math.abs(data.y - dragPosition.y) <= 8) onClick();
      }}
      defaultPosition={defaultPosition || { x: window.innerWidth / 2 - 50, y: window.innerHeight - 250 }}
    >
      {children}
    </DraggableComponent>
  );
};

Draggable.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.func,
  defaultPosition: PropTypes.instanceOf(Object)
};

export default Draggable;
