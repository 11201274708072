import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import React, { useState } from 'react';
import PropTypes, { instanceOf } from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Body, ChevronButton, Container, Header, Title } from './SimpleAccordion.style';

const SimpleAccordion = ({ children, initOpen, title }) => {
  const [showBody, setShowBody] = useState(initOpen);
  return (
    <Container>
      <Header>
        <Title onClick={() => setShowBody(prev => !prev)}>{title}</Title>
        <ChevronButton id="share-chevron" onClick={() => setShowBody(prev => !prev)}>
          <FontAwesomeIcon icon={!showBody ? faChevronDown : faChevronUp} style={{ marginRight: 4 }} size="lg" />
        </ChevronButton>
      </Header>
      <Body $show={showBody}>{children}</Body>
    </Container>
  );
};

SimpleAccordion.propTypes = {
  title: PropTypes.string,
  initOpen: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, instanceOf(Object)])
};

export default SimpleAccordion;
