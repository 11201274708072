import { capitalize as capitalizeHelper } from './helper';

export const getPaymentLabel = ({ billType, isCharge, billTypeEnum, capitalize }) => {
  const result = isCharge ? 'cobrança' : billTypeEnum[billType];

  return capitalize ? capitalizeHelper(result) : result;
};

export const getViewPaymentUrl = ({ billType, isCharge, id, billTypeEnum, idRefurbish, refurbishType }) => {
  return isCharge
    ? `/profissional/cobrancas/visualizar/${id}${idRefurbish ? `?${refurbishType}=${idRefurbish}` : ''}`
    : `/profissional/financeiro/${billTypeEnum[billType]}s/editar/${id}`;
};
