import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowLeft } from '@fortawesome/pro-light-svg-icons';

import css from './SubHeader.module.scss';
import Button from '../Button/Button';
import useBackToContext from '../../_Hooks/useBackToContext';

const SubHeader = ({ title, children, className, Actions, HasDropdown, showBack = true }) => {
  const backToContext = useBackToContext();
  const newClasses = classNames(css.header, className);
  if (!children && !title && !Actions) return null;

  return (
    <div className={newClasses}>
      <header>
        <div className={classNames(css[className], css['header-heading'])}>
          <div className={css.subTitle}>
            {showBack && (
              <Button id="back-icon" text onClick={backToContext}>
                <FontAwesomeIcon icon={faLongArrowLeft} />
              </Button>
            )}
            {HasDropdown ? (
              <div className={css['header-dropdown']}>
                {title && <h6>{title}</h6>}
                {HasDropdown && <HasDropdown />}
              </div>
            ) : (
              title && <h6>{title}</h6>
            )}
          </div>

          {Actions && (
            <div className={css['header-actions']}>
              <Actions />
            </div>
          )}
        </div>
        {children && <div className={css['header-content']}>{children}</div>}
      </header>
    </div>
  );
};

SubHeader.propTypes = {
  Actions: PropTypes.instanceOf(Object),
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  className: PropTypes.string,
  HasDropdown: PropTypes.instanceOf(Object),
  showBack: PropTypes.bool
};

export default SubHeader;
