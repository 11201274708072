import styled from 'styled-components';
import { colors, fonts, spaces } from '../../styles/style';

export const AlertDivConfirmModal = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${spaces.space0};
  height: ${spaces.space3};
  border: 1px solid ${colors.warningSecondary90};
  gap: ${spaces.space1};
  background-color: ${colors.warningSecondary95};
  border-radius: ${spaces.space0};
  min-width: 100%;
  span {
    color: ${colors.warningSecondary50};
    font-size: ${fonts.sizeSm};
  }
`;

export const Description = styled.p`
  color: ${colors.neutral50};
`;
