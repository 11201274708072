import React, { useEffect, useState } from 'react';
import qs from 'qs';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import useCRUD from '../_Hooks/useCRUD';

const Embed = ({ page, height = '90vh' }) => {
  const { search } = useLocation();

  const [hasAccess, setHasAccess] = useState();
  const { user } = useSelector(state => state.authReducer) || {};
  const { userType } = useSelector(state => state.setup.enums);
  const isCustomer = user.userType === userType.customer.value;

  const { handleGet } = useCRUD({
    model: 'refurbish',
    showToast: false,
    immediatelyLoadData: false
  });

  useEffect(() => {
    const { idRefurbish } = qs.parse(search, { ignoreQueryPrefix: true });

    if (!idRefurbish) {
      setHasAccess(true);
      return;
    }

    handleGet({
      refetchOptions: {
        include: [
          {
            model: 'user',
            where: isCustomer ? { id: user?.id } : { idCompany: user.idCompany }
          }
        ]
      },
      refetchPathOptions: `/${idRefurbish}`
    }).then(resp => {
      if (resp && !resp.error) {
        setHasAccess(true);
        return;
      }
      toast.error('Acesso negado');
    });
  }, []);

  return hasAccess ? (
    <iframe
      style={{ width: '100%', height }}
      title="bubbleContent"
      src={`${process.env.REACT_APP_BUBBLE_URL}/${page}${search}`}
    />
  ) : null;
};

Embed.propTypes = {
  page: PropTypes.string,
  height: PropTypes.string
};

export default Embed;
