import * as Yup from 'yup';
import { name, isActive } from '../schema';

const simpleFormSchema = Yup.object().shape({
  name,
  isActive
});

const simpleFormMapping = label => ({
  name: {
    name: `${label}*`,
    type: 'text',
    placeholder: 'Informe o nome da categoria'
  },
  isActive: {
    type: 'checkbox',
    name: 'Ativo?',
    xs: 6,
    defaultValue: true
  }
});

export { simpleFormSchema, simpleFormMapping };
