import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import parse from 'html-react-parser';
import styled from 'styled-components';
import { Text } from '@homehero/hero-style';
import * as Yup from 'yup';
import { faCommentsAlt, faCommentAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useSelector } from 'react-redux';
import { Input, List } from 'antd';
import { spaces, colors, fonts } from '../../styles/style';
import css from './CommentList.module.scss';
import CenteredLoader from '../Loader/CenteredLoader';
import Dropdown from '../Dropdown/Dropdown';
import ConfirmModal from '../Modal/ConfirmModal';
import Button from '../Button/Button';
import Avatar from '../AvatarEditor/Avatar';
import { formatSinceTime, getInitials } from '../../lib/helpers/helper';

import useCRUD from '../../_Hooks/useCRUD';
import useFormState from '../../_Hooks/useFormState';

const schema = Yup.object().shape({
  comment: Yup.string()
    .required('Campo obrigatório')
    .test({
      test: value => value.replace(/<("[^"]*?"|'[^']*?'|[^'">])*>/g, '').length > 0
    })
});
const initialState = {
  comment: ''
};

const StyledTime = styled.time`
  font-size: ${fonts.sizeXs};
  color: ${colors.neutral60};
  font-weight: 100;
  margin-top: ${spaces.space1};
`;
const StyledName = styled(Text)`
  max-width: 95%;
  word-break: break-all;
`;

// Notification Settings @todo create data NotificationConfig.js
const CommentList = ({ data, idRefurbish, model, isWithinDrawer }) => {
  const { formState, handleChange, isValid, reset } = useFormState(initialState, schema);
  const { values } = formState;
  const { comment } = values;
  const { user } = useSelector(state => state.authReducer);
  const [limit, setLimit] = useState(10);

  const { list: comments, handleGet, totalItems, handleCreate, loading } = useCRUD({
    model,
    immediatelyLoadData: false
  });

  const _handleGet = () => {
    return handleGet({
      refetchOptions: {
        include: [{ model: 'comments', paranoid: false }],
        where: { id: data?.id, idRefurbish },
        limit,
        order: [['createdAt', 'desc']]
      }
    });
  };

  const handleComment = () => {
    if (!isValid()) return;

    handleCreate({
      postPathOptions: `/comments`,
      values: { comment, user, idRefurbish, idData: data.id, model },
      refresh: false
    }).then(resp => {
      if (resp.error) return false;
      reset(initialState);
      return _handleGet();
    });
  };

  useEffect(() => {
    setLimit(10);
  }, [data]);

  useEffect(() => {
    _handleGet();
  }, [data, limit]);

  const _comments = comments[0];

  const loadMore =
    _comments?.comments?.length && _comments?.comments?.length < totalItems ? (
      <List.Item>
        <Button ghost fullWidth type="primary" size="small" shape="text" onClick={() => setLimit(limit + 10)}>
          Carregar mais comentários
        </Button>
      </List.Item>
    ) : null;

  return loading && !comments?.length ? (
    <CenteredLoader />
  ) : (
    <div className={`${css['c-file-comments']} ${isWithinDrawer ? css.drawer : ''}`}>
      <div style={{ padding: '8px 0' }}>
        <StyledName>{data.filename || 'Comentários'}</StyledName>
      </div>
      <List level={3} loadMore={loadMore}>
        {_comments?.comments?.length ? (
          _comments?.comments?.map(item => {
            const isMine = item.user && item.user.id === user.id;
            const timeToShow = formatSinceTime(item.createdAt);
            return (
              <List.Item key={item.id} style={{ border: 0 }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flex: 1,
                    ...(!isMine && { flexDirection: 'row-reverse' })
                  }}
                >
                  <div style={{ marginRight: 8 }}>
                    <Avatar size="large" src={item.user?.avatarFullpath}>
                      {getInitials(item.user?.name)}
                    </Avatar>
                  </div>
                  <div style={{ flex: 1 }}>
                    <Text sm bold>
                      {item.user.name}
                    </Text>
                    <div>{parse(item.comment)}</div>
                    <StyledTime>{timeToShow}</StyledTime>
                  </div>
                  {item.user.id === user.id ? (
                    <div>
                      <Dropdown
                        slim
                        model="comments"
                        data={item}
                        trigger={['click']}
                        modal={{
                          confirmModal: ConfirmModal
                        }}
                        afterSubmit={() => {
                          _handleGet();
                        }}
                      />
                    </div>
                  ) : null}
                </div>
              </List.Item>
            );
          })
        ) : (
          <div className={css['c-no-comments']}>
            <FontAwesomeIcon icon={faCommentsAlt} />
            <Text xs>Começe uma conversa deixando uma mensagem ou anotação.</Text>
          </div>
        )}
      </List>
      {!user?.anonymous && (
        <div className={css.action}>
          <div style={{ flex: 1, marginRight: 16 }}>
            <Input
              id="input-comment"
              placeholder="Deixe seu comentário..."
              value={comment}
              onChange={handleChange('comment')}
            />
          </div>
          <div>
            <Button
              id="send-comment"
              shape={null}
              size="small"
              type="primary"
              onClick={handleComment}
              disabled={user.isCustomerView}
            >
              <FontAwesomeIcon icon={faCommentAlt} />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

CommentList.propTypes = {
  data: PropTypes.instanceOf(Object),
  idRefurbish: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  model: PropTypes.string,
  isWithinDrawer: PropTypes.bool
};

export default CommentList;
