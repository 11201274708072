import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Input, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import useFormInput from '../../_Hooks/useFormInput';
import Modal from './Modal';
import { colors } from '../../styles/style';
import { AlertDivConfirmModal, Description } from './ConfirmModal.styled';

const { TextArea } = Input;

const ConfirmModal = ({
  text,
  description,
  onSubmit,
  showReason,
  onClose,
  title = 'Confirmar ação',
  placeholder,
  redirectTo,
  alertInfo,
  modalWidth = 500
}) => {
  const reason = useFormInput(null);
  const history = useHistory();

  const handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();

    if (onSubmit) {
      onSubmit({ archiveReason: reason.value } || true);
      onClose(false);
      if (redirectTo) history.push(redirectTo);
    } else {
      onClose(true);
    }
  };

  return (
    <Modal
      open
      center
      title={title}
      contentLabel="Confirmação"
      onSubmit={handleSubmit}
      onClose={() => onClose(false)}
      width={modalWidth}
    >
      <Space size={12} direction="vertical">
        {alertInfo && (
          <AlertDivConfirmModal>
            <FontAwesomeIcon icon={faCircleInfo} color={colors.warningSecondary80} />
            <span>{alertInfo}</span>
          </AlertDivConfirmModal>
        )}
        <p>{text}</p>
        {description ? <Description>{description}</Description> : null}
        {showReason ? (
          <TextArea id="input-text" {...reason} toolbar={false} placeholder={placeholder} style={{ height: '100px' }} />
        ) : null}
      </Space>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  description: PropTypes.string,
  text: PropTypes.string,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  showReason: PropTypes.bool,
  title: PropTypes.string,
  redirectTo: PropTypes.string,
  placeholder: PropTypes.string,
  alertInfo: PropTypes.string,
  modalWidth: PropTypes.number
};

export default ConfirmModal;
