import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import { faUpload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faFolderOpen, faWrench } from '@fortawesome/pro-solid-svg-icons';
import { toast } from 'react-toastify';
import emptyImg from '../Images/empty-state-image.svg';
import wrenchImg from '../Images/Medium_Copy.svg';
import { isImgUrl } from '../../lib/helpers/helper';
import useCRUD from '../../_Hooks/useCRUD';
import { colors } from '../../styles/style';
import { uploadMultipart } from '../../lib/helpers/uploadMultipart';
import useViewport from '../../_Hooks/useViewport';
import CenteredLoader from '../Loader/CenteredLoader';

import { FlexDiv, StyleLabel, StyledImg } from './ImageInputs.style';
import ImageModal from '../Modal/ImageModal';

const icons = {
  folder: {
    icon: faFolder,
    color: colors.primary40,
    iconSize: '2x'
  },
  wrench: {
    icon: faWrench,
    modalIcon: wrenchImg
  },
  folderOpen: {
    icon: faFolderOpen,
    color: colors.primary40,
    iconSize: '2x'
  }
};

const ImageInput = ({
  id,
  value,
  size = '32px',
  disabled,
  onSubmit = f => f,
  onClick = f => f,
  cors,
  isModal,
  loading,
  images = []
}) => {
  const { handleCreate: uploadFile } = useCRUD({
    model: 'media',
    pathOptions: '/start',
    immediatelyLoadData: false
  });

  const { isMobile } = useViewport(window.innerWidth);
  const [showImageModal, setShowImageModal] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const changeImage = direction => {
    setSelectedImageIndex(index => {
      if (direction === 'right') {
        return index === images?.length - 1 ? 0 : index + 1;
      }

      return index === 0 ? images?.length - 1 : index - 1;
    });
  };

  const handleModalClick = e => {
    if (!isMobile() && disabled) {
      e.preventDefault();
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      setShowImageModal(!showImageModal);
    }
  };

  const handleImageUpload = e => {
    const {
      files: [fileObject = null]
    } = e.target || { files: [] };

    if (!fileObject) {
      return null;
    }

    const file = {
      lastModified: fileObject.lastModified,
      lastModifiedDate: fileObject.lastModifiedDate,
      name: fileObject.name,
      size: fileObject.size,
      type: fileObject.type
    };

    setLoadingFile(!disabled);

    return uploadFile({ values: { file }, refresh: false })
      .then(data => {
        const { signUrls, uploadId } = data;
        return uploadMultipart(fileObject, signUrls).then(parts => {
          uploadFile({ values: { file, uploadId, parts }, postPathOptions: '/complete', refresh: false }).then(f => {
            onSubmit({ image: f.location });
            setLoadingFile(disabled);
          });
        });
      })
      .catch(() => {
        toast.error('Erro ao fazer upload da imagem');
      });
  };

  const { icon, color, iconSize, modalIcon } = icons[value] || {};

  if (!value || isImgUrl(value)) {
    let _val = value;
    if (isImgUrl(value) && cors) {
      _val = _val.indexOf('?') >= 0 ? `${_val}&t=${new Date().getTime()}` : `${_val}?t=${new Date().getTime()}`;
    }
    const imageModalUrl = images?.length > 1 ? images[selectedImageIndex] : _val;
    return (
      <>
        {value || disabled ? (
          <label htmlFor={`image${id}`} style={{ display: 'flex', alignSelf: 'center', width: size }}>
            <div onClick={handleModalClick} aria-hidden="true">
              <StyledImg crossOrigin={cors ? 'anonymous' : null} size={size} alt={_val} src={_val || emptyImg} />
            </div>
          </label>
        ) : (
          <StyleLabel htmlFor={`image${id}`} onClick={e => e.stopPropagation()} disabled={disabled} size={size}>
            <FontAwesomeIcon icon={faUpload} size="lg" />
          </StyleLabel>
        )}
        {!disabled && (
          <input
            id={`image${id}`}
            style={{ display: 'none' }}
            onClick={e => e.stopPropagation()}
            type="file"
            onChange={handleImageUpload}
          />
        )}
        {showImageModal && imageModalUrl && (
          <ImageModal
            length={images?.length}
            src={imageModalUrl}
            alt={_val}
            cors={cors}
            changeImage={changeImage}
            onClose={handleModalClick}
          />
        )}
        {loadingFile && loading ? <CenteredLoader className="" /> : null}
      </>
    );
  }

  return isModal && modalIcon ? (
    <label htmlFor={`image${id}`} style={{ display: 'flex', alignSelf: 'center' }}>
      <StyledImg size={size} disabled={disabled} src={modalIcon} />
    </label>
  ) : (
    <FlexDiv>
      <FontAwesomeIcon icon={icon} size={isMobile() ? 'lg' : iconSize} color={color} onClick={onClick} />
    </FlexDiv>
  );
};

ImageInput.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  images: PropTypes.instanceOf(Array),
  disabled: PropTypes.bool,
  cors: PropTypes.bool,
  isModal: PropTypes.bool,
  onSubmit: PropTypes.func,
  size: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool
};

export default React.memo(ImageInput, isEqual);
