import * as React from 'react';

function NoCatalogProductV2() {
  return (
    <svg width="228px" height="190px" viewBox="0 0 228 190" version="1.1">
      <title>undraw_empty_cart_co35</title>
      <g id="Desktop---Orçamento---Profissional-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="v2---Modal-buscar-produtos---empty" transform="translate(-307.000000, -351.000000)" fillRule="nonzero">
          <g id="undraw_empty_cart_co35" transform="translate(307.000000, 351.000000)">
            <path
              d="M10.935194,180.71163 C14.0340792,186.510608 20.6110286,189 20.6110286,189 C20.6110286,189 22.1636901,182.087361 19.064805,176.28837 C15.9659199,170.489379 9.38897044,168 9.38897044,168 C9.38897044,168 7.83631141,174.912639 10.935194,180.71163 Z"
              id="Path"
              fill="#2F2E41"
            />
            <path
              d="M12.9676354,179.425909 C18.7666239,182.891405 21,189.774836 21,189.774836 C21,189.774836 13.8313531,191.039589 8.0323646,187.574091 C2.23337611,184.108592 0,177.225163 0,177.225163 C0,177.225163 7.16864433,175.960412 12.9676354,179.425909 Z"
              id="Path"
              fill="#00B0FF"
            />
            <rect id="Rectangle" fill="#F2F2F2" x="105" y="9" width="36" height="1" />
            <rect id="Rectangle" fill="#F2F2F2" x="130" y="9" width="1" height="5" />
            <rect id="Rectangle" fill="#F2F2F2" x="115" y="9" width="1" height="5" />
            <rect id="Rectangle" fill="#F2F2F2" x="123" y="33" width="36" height="1" />
            <rect id="Rectangle" fill="#F2F2F2" x="133" y="29" width="1" height="5" />
            <rect id="Rectangle" fill="#F2F2F2" x="148" y="29" width="1" height="5" />
            <rect id="Rectangle" fill="#F2F2F2" x="170" y="45" width="36" height="1" />
            <rect id="Rectangle" fill="#F2F2F2" x="180" y="40" width="1" height="5" />
            <rect id="Rectangle" fill="#F2F2F2" x="196" y="40" width="1" height="5" />
            <rect id="Rectangle" fill="#F2F2F2" x="167" y="163" width="36" height="1" />
            <rect id="Rectangle" fill="#F2F2F2" x="176" y="158" width="1" height="5" />
            <rect id="Rectangle" fill="#F2F2F2" x="192" y="158" width="1" height="5" />
            <rect id="Rectangle" fill="#F2F2F2" x="106" y="81" width="36" height="1" />
            <rect id="Rectangle" fill="#F2F2F2" x="116" y="77" width="1" height="5" />
            <rect id="Rectangle" fill="#F2F2F2" x="131" y="77" width="1" height="5" />
            <rect id="Rectangle" fill="#F2F2F2" x="117" y="142" width="36" height="1" />
            <rect id="Rectangle" fill="#F2F2F2" x="127" y="138" width="1" height="5" />
            <rect id="Rectangle" fill="#F2F2F2" x="142" y="138" width="1" height="5" />
            <rect id="Rectangle" fill="#F2F2F2" x="174" y="124" width="36" height="1" />
            <rect id="Rectangle" fill="#F2F2F2" x="184" y="119" width="1" height="5" />
            <rect id="Rectangle" fill="#F2F2F2" x="199" y="119" width="1" height="5" />
            <polygon
              id="Path"
              fill="#2F2E41"
              points="92 178 32 178 32 177.569615 91.5682177 177.569615 91.5682177 156.68977 37.0440482 156.68977 34.3953274 151.186256 34.7845396 151 37.3156859 156.259133 92 156.259133"
            />
            <circle id="Oval" fill="#3F3D56" cx="39.5" cy="184.5" r="4.5" />
            <circle id="Oval" fill="#3F3D56" cx="84.5" cy="184.5" r="4.5" />
            <circle id="Oval" fill="#3F3D56" cx="138" cy="88" r="3" />
            <path
              d="M97.9813738,150 L30.5833439,150 L16,102 L113,102 L112.911743,102.282418 L97.9813738,150 Z M30.9024574,149.56561 L97.6649876,149.56561 L112.411891,102.43438 L16.5830421,102.43438 L30.9024574,149.56561 Z"
              id="Shape"
              fill="#2F2E41"
            />
            <polygon
              id="Path"
              fill="#F2F2F2"
              points="93.6846609 147 33.9296132 147 21 105 107 105 106.921781 105.247306"
            />
            <polygon
              id="Path"
              fill="#2F2E41"
              points="115.408365 98 115 97.8877027 117.787146 87 134 87 134 87.4378825 118.112201 87.4378825"
            />
            <rect id="Rectangle" fill="#2F2E41" x="21" y="116.783646" width="88" height="1" />
            <rect id="Rectangle" fill="#2F2E41" x="26" y="132.783646" width="78" height="1" />
            <rect id="Rectangle" fill="#2F2E41" x="64.78326" y="102" width="1" height="47" />
            <polygon
              id="Rectangle"
              fill="#2F2E41"
              transform="translate(83.215370, 125.896564) rotate(-86.249190) translate(-83.215370, -125.896564) "
              points="59.4742102 125.677021 106.95648 125.682556 106.95653 126.116106 59.4742607 126.110571"
            />
            <polygon
              id="Rectangle"
              fill="#2F2E41"
              transform="translate(46.645640, 125.896571) rotate(-3.729000) translate(-46.645640, -125.896571) "
              points="46.4261132 102.155413 46.8596636 102.155463 46.8651664 149.637729 46.431616 149.637679"
            />
            <rect id="Rectangle" fill="#2F2E41" x="0" y="189" width="228" height="1" />
            <path
              d="M152,15.727279 C152,15.727279 155.689189,26.3636425 153.418917,28 C151.148644,29.6363725 161.081081,43 161.081081,43 L173,39.7272726 L166.472972,28.5454532 C166.472972,28.5454532 165.621621,17.3636363 165.621621,15.7272713 C165.621621,14.0909063 152,15.727279 152,15.727279 Z"
              id="Path"
              fill="#DDBABF"
            />
            <path
              d="M152,15.727279 C152,15.727279 155.689189,26.3636425 153.418917,28 C151.148644,29.6363725 161.081081,43 161.081081,43 L173,39.7272726 L166.472972,28.5454532 C166.472972,28.5454532 165.621621,17.3636363 165.621621,15.7272713 C165.621621,14.0909063 152,15.727279 152,15.727279 Z"
              id="Path"
              fill="#000000"
              opacity="0.1"
            />
            <path
              d="M145.48509,90.9810348 C145.48509,90.9810348 144.393638,104.509477 145.48509,111.544265 C146.576541,118.579053 148.21372,138.060011 148.21372,138.060011 C148.21372,138.060011 148.21372,174.85737 154.216702,175.398506 C160.219684,175.939643 164.585492,176.480782 165.131217,174.316231 C165.676941,172.15168 162.402589,171.069405 164.039765,169.98713 C165.676941,168.904855 166.222668,165.116892 164.039765,160.787791 C161.856862,156.45869 164.039765,112.62654 164.039765,112.62654 L174.408556,140.224561 C174.408556,140.224561 175.5,162.952342 176.591459,166.199168 C177.68291,169.445994 175.5,175.398506 179.320086,175.939645 C183.140165,176.480784 187.505974,173.233956 189.688877,172.15168 C191.87178,171.069405 186.414523,171.069405 187.505974,170.528259 C188.597426,169.987112 191.87178,168.363708 190.780329,167.822569 C189.688877,167.28143 188.597426,141.306826 188.597426,141.306826 C188.597426,141.306826 185.595932,85.8402279 181.775853,83.6756824 C177.955774,81.5111369 175.500015,85.2394426 175.500015,85.2394426 L145.48509,90.9810348 Z"
              id="Path"
              fill="#2F2E41"
            />
            <path
              d="M155.2,173 L155.2,177.450444 C155.2,177.450444 150.250001,189.548112 155.2,189.548112 C160.149999,189.548112 164,190.801774 164,189.132859 L164,174.112611 L155.2,173 Z"
              id="Path"
              fill="#2F2E41"
            />
            <path
              d="M186.8,173 L186.8,177.450444 C186.8,177.450444 191.749999,189.548111 186.8,189.548111 C181.850001,189.548111 178,190.801776 178,189.132858 L178,174.112611 L186.8,173 Z"
              id="Path"
              fill="#2F2E41"
            />
            <circle id="Oval" fill="#DDBABF" cx="159" cy="14" r="10" />
            <path
              d="M156.081081,32 C156.081081,32 158.783785,40.1373374 163.108109,38.509871 L167.432433,36.8823943 L175,88.9613697 C175,88.9613697 169.054053,97.6411977 160.405405,92.2163052 C151.756757,86.7914127 156.081081,32 156.081081,32 Z"
              id="Path"
              fill="#00B0FF"
            />
            <path
              d="M164,30.1750966 L166.423077,25 C166.423077,25 180.692308,31.8093391 182.846154,33.9883274 C185,36.1673157 185,39.4357982 185,39.4357982 L181.230768,53.0544738 C181.230768,53.0544738 182.307691,83.0155567 182.307691,84.1050585 C182.307691,85.1945603 186.076923,91.1867692 183.384614,89.0077809 C180.692305,86.8287926 180.153845,84.6498043 178,87.9182867 C175.846153,91.1867692 171,95 171,95 L164,30.1750966 Z"
              id="Path"
              fill="#3F3D56"
            />
            <path
              d="M182.46359,69 L180.848635,84.1401887 C180.848635,84.1401887 171.158962,92.7917251 173.312223,93.3324474 C175.465484,93.8731697 176.542114,91.7102754 176.542114,91.7102754 C176.542114,91.7102754 180.310322,95.4953239 182.463582,93.3324474 C184.616843,91.1695709 190,70.6221643 190,70.6221643 L182.46359,69 Z"
              id="Path"
              fill="#DDBABF"
            />
            <path
              d="M159.558234,0.0326548681 C157.349643,-0.0438761964 154.986123,-0.0817331372 153.123978,1.07191288 C152.267197,1.65087839 151.515717,2.36393054 150.900295,3.18188667 C149.086669,5.40423695 147.519614,8.20046083 148.193284,10.9577893 L148.97573,10.6619942 C148.975704,11.3980107 148.8069,12.1248195 148.481528,12.7898405 C148.591709,12.4792858 148.960703,12.9813152 148.861917,13.2954504 L148,16.0363246 C149.416879,15.5328418 151.179405,16.5522536 151.39512,18 C151.493626,14.816449 151.834342,11.1659245 154.498621,9.30182725 C155.842204,8.36177022 157.542629,8.0747957 159.178678,7.81996035 C160.687969,7.58486312 162.270197,7.36068093 163.715842,7.84230339 C165.161486,8.32392585 166.39255,9.75702579 166.064817,11.204302 C166.73139,10.9817315 167.476894,11.4320219 167.806313,12.0362092 C168.135732,12.6403966 168.153037,13.3531309 168.162951,14.0364366 C168.873486,14.5231835 169.6821,13.5566371 169.971675,12.7613497 C170.651397,10.894567 171.255568,8.90741707 170.8894,6.95985398 C170.523231,5.01229088 168.885963,3.15109522 166.843647,3.14448987 C167.091542,2.88056075 167.223803,2.53351528 167.212456,2.17675925 L165.529115,2.03889421 C165.956463,1.98024131 166.349478,1.77939528 166.640853,1.47075345 C165.965004,2.19161689 160.65116,0.0705243809 159.558234,0.0326548681 Z"
              id="Path"
              fill="#2F2E41"
            />
            <path
              d="M159.193129,29.0846172 C159.193129,29.0846172 154.789906,24.7459608 153.205254,25.0117298 C151.620602,25.2774987 149.457027,29.0846172 149.457027,29.0846172 C149.457027,29.0846172 136.475552,33.4356083 137.016445,37.7865994 C137.557339,42.1375905 143.507178,63.3486707 143.507178,63.3486707 C143.507178,63.3486707 148.375233,88.9107447 144.048074,91.6301128 C139.720916,94.349481 164.602077,101.419843 165.142971,98.1565995 C165.683864,94.8933562 166.765664,62.260923 165.142971,57.3660593 C163.520278,52.4711956 159.193129,29.0846172 159.193129,29.0846172 Z"
              id="Path"
              fill="#3F3D56"
            />
            <path
              d="M178,38 L184.622503,38 C184.622503,38 189.247275,58.4615377 189.782858,60.6153863 C190.318442,62.7692349 191.389621,73 190.854027,73 C190.318434,73 179.606755,70.8461539 179.606755,72.4615397 L178,38 Z"
              id="Path"
              fill="#3F3D56"
            />
            <path
              d="M178,88 L151.984389,88 C149.445657,76.5784733 149.233542,65.5432506 151.984389,55 L178,55 C173.80165,65.353614 173.539289,76.3132339 178,88 Z"
              id="Path"
              fill="#F2F2F2"
            />
            <path
              d="M142,74.5565059 L149.512196,77.6360322 C149.512196,77.6360322 164,79.6890385 164,74.0432699 C164,68.3975013 149.512196,71.4770132 149.512196,71.4770132 L144.682604,70 L142,74.5565059 Z"
              id="Path"
              fill="#DDBABF"
            />
            <path
              d="M142.773333,34 L136.319998,35.6125083 L131.480004,64.0999912 C131.480004,64.0999912 129.866661,71.624989 132.555559,72.1624876 C135.244458,72.6999861 142.773333,77 142.773333,77 C142.773333,77 143.848888,68.9375011 146,68.9375011 L140.622226,64.6374898 L143.848894,45.8250066 L142.773333,34 Z"
              id="Path"
              fill="#3F3D56"
            />
            <rect id="Rectangle" fill="#F2F2F2" x="15" y="89" width="36" height="1" />
            <rect id="Rectangle" fill="#F2F2F2" x="25" y="85" width="1" height="5" />
            <rect id="Rectangle" fill="#F2F2F2" x="40" y="85" width="1" height="5" />
            <rect id="Rectangle" fill="#F2F2F2" x="28" y="14" width="36" height="1" />
            <rect id="Rectangle" fill="#F2F2F2" x="53" y="15" width="1" height="5" />
            <rect id="Rectangle" fill="#F2F2F2" x="37" y="15" width="1" height="5" />
            <rect id="Rectangle" fill="#F2F2F2" x="63" y="64" width="36" height="1" />
            <rect id="Rectangle" fill="#F2F2F2" x="89" y="65" width="1" height="5" />
            <rect id="Rectangle" fill="#F2F2F2" x="73" y="65" width="1" height="5" />
            <rect id="Rectangle" fill="#F2F2F2" x="3" y="64" width="36" height="1" />
            <rect id="Rectangle" fill="#F2F2F2" x="28" y="64" width="1" height="5" />
            <rect id="Rectangle" fill="#F2F2F2" x="13" y="64" width="1" height="5" />
            <rect id="Rectangle" fill="#F2F2F2" x="46" y="39" width="36" height="1" />
            <rect id="Rectangle" fill="#F2F2F2" x="55" y="34" width="1" height="5" />
            <rect id="Rectangle" fill="#F2F2F2" x="71" y="34" width="1" height="5" />
          </g>
        </g>
      </g>
    </svg>
  );
}

NoCatalogProductV2.propTypes = {};
NoCatalogProductV2.defaultProps = {};

export default NoCatalogProductV2;
