import * as React from 'react';

function NoProduct(props) {
  return (
    <svg width={212} height={171} {...props}>
      <g fillRule="nonzero" fill="none">
        <path
          fill="#3F3D56"
          d="M0 163.771h83v1H0zM196.562 87l-.291-.106c-.064-.023-6.42-2.384-9.397-7.737-2.977-5.354-1.52-11.8-1.504-11.863l.069-.294.29.106c.064.023 6.42 2.384 9.397 7.737 2.977 5.354 1.52 11.8 1.504 11.863l-.068.294zm-9.21-8.092c2.516 4.526 7.539 6.846 8.811 7.376.242-1.323 1-6.67-1.514-11.192-2.515-4.521-7.539-6.845-8.812-7.376-.242 1.323-1 6.67 1.514 11.192z"
        />
        <path
          d="M188.733 78.24c5.246 3.198 7.267 9.552 7.267 9.552s-6.486 1.168-11.733-2.031c-5.246-3.2-7.267-9.553-7.267-9.553s6.486-1.168 11.733 2.031z"
          fill="#D0CDE1"
        />
        <path
          d="M195.5 106c-9.108-.01-16.49-7.51-16.5-16.764V89h33v.236c-.01 9.254-7.392 16.754-16.5 16.764zm-16.034-16.528c.127 8.905 7.269 16.056 16.034 16.056 8.765 0 15.907-7.151 16.034-16.056h-32.068z"
          fill="#3F3D56"
        />
        <path d="M195.5 103c5.799 0 10.5-4.925 10.5-11h-21c0 6.075 4.701 11 10.5 11z" fill="#005AC3" />
        <ellipse fill="#2F2E41" cx={52} cy={13.5} rx={12} ry={11.5} />
        <path
          d="M48.05 22s1.6 11.035-1.373 13.617C43.704 38.2 51.48 49 51.48 49L62 31.626s-8.004-2.113-3.888-8.452L48.05 22z"
          fill="#FFDFE3"
        />
        <path
          d="M44 53.117l-1.656 6.428L40.684 66 35 65.532l2.605-10.775s-.473-10.072-.71-12.415a3.139 3.139 0 01.097-1.283A1.458 1.458 0 0138.316 40L44 53.117z"
          fill="#575A89"
        />
        <path
          d="M50 141.6l-6.522 1.4-.635-5.952-2.284-21.47-.559-5.245L47.609 108l-.05 1.867-.168 6.3a27.81 27.81 0 01.729 3.752c.32 2.388.44 4.803.358 7.214C48.261 133.667 50 141.6 50 141.6z"
          fill="#FFDFE3"
        />
        <path
          d="M43 142.076L49.913 140l3.79 9.686 5.353 12.914s6.912 9.456-.446 8.303c-7.36-1.154-8.474-2.999-8.474-3.921v-1.614l-.892.23-.67-.692-.891-6.918s-2.007-1.845-1.115-4.382L43 142.076z"
          fill="#2F2E41"
        />
        <path
          d="M48 120.985l-.08.213s-1.199 11.265-5.086 16.063l-.417.739L40 116.674 41.518 111h5.889l-.178 6.258c.347 1.222.605 2.467.771 3.727z"
          fill="#000"
          opacity={0.2}
        />
        <path
          d="M52 110l-3.795 10.132s-1.163 11.191-4.935 15.957L38.382 145s-5.804-2.072-5.358-2.072c.447 0 3.796-11.744 3.796-11.744l3.125-13.355L41.992 110H52z"
          fill="#FFDFE3"
        />
        <path
          d="M39 142.076L32.087 140l-3.79 9.686-5.353 12.914s-6.912 9.456.446 8.303c7.36-1.154 8.474-2.999 8.474-3.921v-1.614l.892.23.67-.692.891-6.918s2.007-1.845 1.115-4.382L39 142.076z"
          fill="#2F2E41"
        />
        <circle fill="#FFDFE3" cx={54} cy={17} r={8} />
        <path
          d="M44 53.752L42.252 60l-1.502-.1a3.673 3.673 0 00-.5-1.594c-.5-.911 0-.228 1-1.139 1-.91.25-.455-.5-1.594-.75-1.138.5-3.643.5-3.643C39.85 51.02 38 41 38 41l6 12.752z"
          fill="#000"
          opacity={0.2}
        />
        <path
          d="M52.176 44.88l-4.88-10.298L38 40.851s3.021 9.627 4.648 10.746c0 0-1.162 2.463-.465 3.582.697 1.12 1.394.672.465 1.567-.93.896-1.394.224-.93 1.12.269.484.427 1.019.465 1.567L64.261 61l1.394-3.806s2.789-5.15 1.86-12.313L71 32.567 61.24 31l-9.064 13.88z"
          fill="#575A89"
        />
        <path
          d="M64.269 60.044s3.925 8.516-.045 22.403c-3.97 13.888-7.472 28.47-7.472 28.47L38.772 113l-.467-8.101-6.305-.926s.934-26.155 3.736-32.636C38.538 64.857 41.21 59 41.21 59l23.06 1.044z"
          fill="#2F2E41"
        />
        <path fill="#000" opacity={0.2} d="M38.667 104.351l4.35-28.158.454.07-4.35 28.158z" />
        <path
          d="M59.966 10.864c-.491-1.41-1.487-2.564-2.776-3.216-1.595-.798-3.455-.734-5.203-.468-2.018.306-4.138.947-5.44 2.593-.836 1.056-1.238 2.406-1.62 3.719-.442 1.517-.888 3.058-.927 4.644.006 1.099.11 2.194.31 3.273.057.592.218 1.169.473 1.7.286.514.809.926 1.374.889-1.138-2.145-.99-4.788.381-6.777.329-.44.685-.857 1.068-1.247l2.907-3.099c.892-.95 1.823-1.929 3.014-2.395 1.39-.53 2.94-.3 4.136.61.492.387.9.878 1.2 1.442.212.398.707 1.681.707 1.681s.572-2.837.396-3.35z"
          fill="#2F2E41"
        />
        <ellipse fill="#A0616A" cx={45.5} cy={17.5} rx={1} ry={1.5} />
        <circle fill="#2F2E41" cx={47} cy={4} r={4} />
        <path
          d="M55.043 75H40.957c-.738 0-1.336-.567-1.336-1.267L39 56.267c0-.7.598-1.267 1.337-1.267h15.326c.739 0 1.337.567 1.337 1.267l-.62 17.466c0 .7-.6 1.267-1.337 1.267z"
          fill="#3F3D56"
        />
        <circle fill="#D0CDE1" cx={48} cy={59} r={1} />
        <path
          d="M64.561 60l-7.466.883s-12.71-.656-9.607 3.205c3.104 3.86 10.2.681 10.2.681L67 64.315 64.561 60zM36.576 62.012s-2.442 1.157-1.246 3.28c1.195 2.122 6.85 4.269 7.585 1.112.735-3.157-3.499-4.153-4.55-4.303a8.798 8.798 0 00-1.79-.089z"
          fill="#FFDFE3"
        />
        <path
          d="M67.637 32.935L70.565 32a6.287 6.287 0 011.953 3.273c.488 2.104 8.297 25.948 4.393 27.818C73.006 64.96 66.173 68 66.173 68L63 60.052l5.125-2.571-1.952-10.286s-1.953-14.26 1.464-14.26z"
          fill="#575A89"
        />
        <path d="M161 105h-58V47h58v58zm-57.547-.453h57.094V47.453h-57.094v57.094z" fill="#3F3D56" />
        <path fill="#005AC3" d="M110 53h45v45h-45z" />
        <path
          d="M126.388 85c-1.321-4.193-2.988-7.163-5.244-9.349l-.144-.14.116-.164c4.317-6.164 7.88-12.194 10.589-17.923l.2-.424.208.42c3.46 7.003 6.982 12.864 10.77 17.92l.117.158-.133.144c-2.742 2.98-4.52 6.038-5.434 9.35l-.438-.127c.92-3.332 2.69-6.408 5.409-9.398-3.685-4.942-7.12-10.646-10.49-17.418-2.67 5.575-6.138 11.427-10.313 17.405 2.24 2.227 3.903 5.222 5.22 9.403l-.433.143z"
          fill="#3F3D56"
        />
        <path fill="#3F3D56" d="M131.771 58h1v18h-1z" />
        <circle fill="#3F3D56" cx={132} cy={76} r={1} />
        <path fill="#CFCCE0" d="M123.739 96.005l17.845-11.84.254.38-17.845 11.842z" />
        <path
          d="M122.028 89.378c-.785-.575-1.006-1.138-1.028-1.198l.424-.178-.212.089.211-.091c.013.035 1.451 3.486 10.709 1.602 9.695-1.973 10.825 1.103 10.868 1.235l-.43.163c-.008-.025-1.138-2.793-10.354-.917-6.042 1.23-8.888.247-10.188-.705z"
          fill="#3F3D56"
        />
        <path
          fill="#3F3D56"
          d="M120.75 88.021l2.254-.425.427 2.249-2.255.426zM142.1 90.395l2.254-.426.427 2.249-2.255.426z"
        />
        <circle fill="#CFCCE0" cx={132} cy={91} r={1} />
        <circle fill="#CFCCE0" cx={123} cy={96} r={1} />
        <circle fill="#CFCCE0" cx={141} cy={84} r={1} />
        <path fill="#3F3D56" d="M131.771 76h1v4h-1zM99 104.771h111v1H99z" />
      </g>
    </svg>
  );
}

export default NoProduct;
