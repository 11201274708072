import Text from 'antd/es/typography/Text';
import styled from 'styled-components';
import { colors, spaces } from '../../styles/style';
import DatePicker from '../Datepicker/Datepicker';
import Input from '../Input/Input';
import Select from '../Select/Select';

const StyledText = styled(Text)`
  color: ${colors.neutralSecondary40};
  padding-bottom: ${spaces.space1};
`;

const StyledInput = styled(Input)`
  width: 240px;
  height: ${spaces.space4};
  border-radius: ${spaces.space1};
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${props => (props.noPadding ? 0 : spaces.space3)};
`;

const StyledDatePicker = styled(DatePicker)`
  width: 240px;
`;

const StyledSelect = styled(Select)`
  width: 240px;
`;

export { StyledSelect, StyledDatePicker, StyledDiv, StyledInput, StyledText };
