/* eslint-disable react/no-unknown-property */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEye, faCloudDownload, faExternalLink } from '@fortawesome/pro-light-svg-icons';
import { faUserFriends } from '@fortawesome/pro-solid-svg-icons';
import { Switch, Space, Tooltip } from 'antd';
import { Text } from '@homehero/hero-style';
import { toast } from 'react-toastify';
import saveAs from 'save-as';

import TextArea from 'antd/lib/input/TextArea';
import useViewport from '../../_Hooks/useViewport';

import { bytesToSize, getFileName, getFileType, isExtensionSupported } from '../../lib/helpers/helper';

import EditableInput from '../Input/EditableInput';
import Button from '../Button/Button';
import Select from '../Select/Select';
import ConfirmModal from '../Modal/ConfirmModal';
import ProjectOrOpportunityEventService from '../../lib/gtm/opportunity';
import CopyLinkButton from '../Button/CopyLinkButton';
import { getNameWithoutExtension, getExtension } from '../../lib/helpers/file';

import { colors } from '../../styles/style';
import useCRUD from '../../_Hooks/useCRUD';

const gtmEventService = ProjectOrOpportunityEventService();

const StyledSharedText = styled.div`
  justify-content: space-evenly;
  align-items: center;
  margin-top: 16px;
  display: flex;
  background-color: ${colors.primary80};
  ${props =>
    props.$warning &&
    css`
      border: 1px ${colors.warning90} solid;
      background-color: ${colors.warning95};
    `}
  width: ${props => props.width};
  border-radius: 4px;
  height: ${props => props.height};

  svg {
    margin-left: 5px;
    margin-right: 5px;
  }
`;

const BtnContainer = styled.div`
  display: flex;
  margin-top: 10px;

  #visualization-file {
    min-width: 150px;
    padding: 14px;
    margin-right: 10px;
  }
  #open-file,
  #download-file,
  #delete-file {
    border-radius: 8px;
    padding: 14px;
    margin-right: 10px;
    max-width: 30px;
    span {
      display: none;
    }
  }
  #btn-open span {
    display: none;
  }
`;

const RenderFileInfo = ({
  isFolderShared,
  isProject,
  isCustomer,
  data,
  isFolder,
  setSelectedIndexSideBar = f => f,
  selectedTab = 'info',
  idRefurbish,
  selectFile = f => f,
  onToggleModal,
  user,
  isModal,
  onShareItem = f => f,
  isModuleShared,
  onChangeFileFolder = f => f,
  reload = f => f,
  viewImage
}) => {
  const { isMobile } = useViewport(window.innerWidth);
  const shouldSave = useRef(false);
  const { handleUpdate, handleDelete } = useCRUD({
    model: isFolder ? 'folder' : 'file',
    immediatelyLoadData: false
  });

  const [name, setName] = useState(isFolder ? data?.name : data?.filename);
  const [description, setDescription] = useState(data?.description);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { user: userFile = {}, id, fullpath } = data || {};
  const _name = name || getFileName(fullpath);
  const isMine = user.userType === userFile?.userType;
  const { userType } = useSelector(state => state.setup.enums);
  const isSupported = data ? isExtensionSupported(getFileType(_name)) : false;
  const isFileShared = !isFolder && (!!data?.shared || isFolderShared);
  const isWarningMessage = !isModuleShared && (isFileShared || isFolderShared);

  const canEdit = ((isMine && !isFolder) || (isFolder && !isCustomer)) && !user.isCustomerView && !user.anonymous;

  const handleChangeName = values => {
    handleUpdate({
      id: data?.id,
      values: {
        idRefurbish,
        name: data?.name,
        ...values
      },
      refresh: false,
      noLoading: true
    }).then(resp => {
      if (!resp.error) {
        reload();
      }
    });
  };

  const handleChangeSelectFolder = idFolder =>
    handleUpdate({
      id: data?.id,
      values: { idFolder, idRefurbish, event: `${isProject ? 'project' : 'opportunity'}-file-moved` },
      refresh: false
    }).then(resp => {
      if (!resp.error) {
        onChangeFileFolder(idFolder);
      }
    });

  useEffect(() => {
    setSelectedIndexSideBar(selectedTab);
    setDescription(data?.description);
    setName(isFolder ? data?.name : data?.filename);
    shouldSave.current = false;
  }, [data]);

  const handleRemove = shouldRemove => {
    setShowConfirmModal(false);

    if (shouldRemove) {
      handleDelete({ id, refresh: false }).then(resp => {
        if (resp?.error) return null;
        selectFile(null);
        reload();
        return null;
      });
      if (onToggleModal) onToggleModal(false);
    }
  };

  const downloadFile = (filePath, fileName) => {
    fetch(`${filePath}&t=${new Date().getTime()}`)
      .then(res => res.blob())
      .then(blob => {
        saveAs(blob, fileName);
      })
      .catch(() => toast.error('Erro ao fazer download do arquivo'));
  };
  const handleChangeSwitch = oldShared => {
    handleUpdate(
      {
        id: data.id,
        updatePathOptions: '/share',
        values: {
          idRefurbish,
          shared: oldShared ? null : new Date()
        },
        refresh: false,
        verb: isFolder ? 'patch' : 'put'
      },
      true
    ).then(() => {
      reload();
      if (!oldShared) {
        onShareItem();
      }
    });
  };

  const renderText = useMemo(() => {
    if (isWarningMessage)
      return `Seu cliente não tem acesso aos itens compartilhados pois o
              compartilhamento do módulo de arquivos não está ativo.`;
    if (isFolderShared) {
      if (isFolder) return 'Esta pasta e todos os arquivos dentro dela estão compartilhados';
      return 'Este arquivo está compartilhado, pois a pasta está compartilhada';
    }
    return 'Este arquivo está compartilhado';
  }, [isWarningMessage, isFolderShared, isFolder]);

  const getNameWithExtension = newName => {
    let result = getNameWithoutExtension(newName);

    let extension = getExtension(newName);
    if (!extension) {
      extension = getExtension(_name);
    }

    if (extension) result += `.${extension}`;

    return result;
  };

  const onFileOrFolderNameInputChange = newName => {
    if (!isFolder)
      handleChangeName({ name: getNameWithoutExtension(newName), filename: getNameWithExtension(newName) });
    else handleChangeName({ name: newName });
  };

  return (
    <>
      <Space direction="vertical" size={16}>
        <div style={{ marginTop: '8px', wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}>
          {data?.id > 1 ? (
            <EditableInput
              icon
              value={name}
              onChange={onFileOrFolderNameInputChange}
              map={!isFolder ? getNameWithExtension : undefined}
              disabled={!canEdit}
            />
          ) : (
            <Text style={{ height: 32, paddingTop: 9 }}>{name}</Text>
          )}
        </div>
        {isFolder && data?.id !== 1 ? (
          <div>
            <TextArea
              placeholder="Descrição da pasta"
              onChange={e => setDescription(e?.target?.value)}
              value={description}
              disabled={user?.userType !== userType.provider.value}
              onBlur={() => (data?.description !== description ? handleChangeName({ description }) : f => f)}
            />
          </div>
        ) : null}
        {!isFolder ? (
          <Tooltip
            title={
              isCustomer
                ? 'Copie o link desse arquivo para enviar para profissional'
                : 'Copie o link desse arquivo para enviar para seu cliente ou equipe'
            }
          >
            <CopyLinkButton
              underline
              noPadding
              height="auto"
              eventButton="file"
              customUrl={`${window.location.origin}/a/${data?.id}`}
              text
              type="primary"
            >
              Copiar link
            </CopyLinkButton>
          </Tooltip>
        ) : null}
        {canEdit && !isCustomer ? (
          <>
            <div>
              <Text style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }} sm bold>
                Configurações {isFolder ? ' da pasta' : ' do arquivo'}
              </Text>
            </div>
            <div>
              {isProject && data.id !== 1 && (
                <div style={{ display: 'flex' }}>
                  <div style={{ flex: 2 }}>
                    <Text style={{ marginBottom: '4px' }} sm>
                      Compartilhar {isFolder ? ' pasta' : ' arquivo'}
                    </Text>
                    <Text xs>Habilite para compartilhar com seu cliente</Text>
                  </div>
                  <div>
                    <Switch
                      disabled={isFolder && !isCustomer ? null : isFolderShared}
                      checked={!!data.shared || isFolderShared}
                      onChange={() => handleChangeSwitch(data.shared)}
                      size="small"
                    />
                  </div>
                </div>
              )}
              {(isFileShared || (isFolder && isFolderShared)) && (
                <StyledSharedText
                  height="36px"
                  width={!isWarningMessage && !isFolderShared ? '200px' : undefined}
                  $warning={isWarningMessage}
                >
                  <FontAwesomeIcon
                    icon={faUserFriends}
                    color={isWarningMessage ? colors.neutral50 : colors.primary40}
                  />
                  <Text xs>{renderText}</Text>
                </StyledSharedText>
              )}
            </div>
          </>
        ) : null}
        <div>
          <Text style={{ marginBottom: '4px' }} sm bold>
            Data {isFolder ? ' da criação' : ' do envio'}:
          </Text>
          <Text xs>{data ? dayjs(data.createdAt).format('DD [de] MMMM, YYYY [às] HH:mm') : '-'}</Text>
        </div>
        {!isFolder ? (
          <div>
            <Text style={{ marginBottom: '4px' }} sm bold>
              Tamanho:
            </Text>
            <Text xs>{Number(data?.filesize) ? bytesToSize(data?.filesize) : 'N/A'}</Text>
          </div>
        ) : null}
        {!isCustomer && !isModal && !isFolder ? (
          <div>
            <Text style={{ marginBottom: '8px' }} sm bold>
              Pasta:
            </Text>
            <Select
              id="dropdown-file"
              value={data?.idFolder}
              onChange={handleChangeSelectFolder}
              style={{ width: '100%' }}
              model="folder"
              modelOptions={{ where: { or: [{ idRefurbish }, { id: 1 }] } }}
              customValue="id"
              customLabel="name"
            />
          </div>
        ) : null}
        {isFolder && (
          <div>
            <Text style={{ margin: 'auto auto 4px auto' }} sm bold>
              Arquivos
            </Text>
            <Text xs>{data?.files?.length}</Text>
          </div>
        )}
      </Space>
      <BtnContainer>
        {!isFolder && (
          <>
            {(!isModal || viewImage) && (
              <Tooltip placement="top" title="Visualizar">
                <Button
                  id="visualization-file"
                  ghost
                  fullWidth
                  size="small"
                  type={isSupported ? 'primary' : 'default'}
                  onClick={() => onToggleModal(true)}
                  disabled={!isSupported}
                  icon={<FontAwesomeIcon icon={faEye} />}
                >
                  {isSupported ? 'Visualizar' : 'Pré-visualização não suportada'}
                </Button>
              </Tooltip>
            )}
            {data?.id > 1 && !isMobile() ? (
              <Tooltip placement="top" title="Fazer Download">
                <Button
                  id="download-file"
                  size="small"
                  ghost
                  shape=""
                  type="primary"
                  icon={<FontAwesomeIcon icon={faCloudDownload} />}
                  onClick={() => downloadFile(data?.fullpath, data?.filename)}
                >
                  <span>Baixar</span>
                </Button>
              </Tooltip>
            ) : null}
            <Tooltip placement="top" title="Abrir">
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={data?.fullpath}
                download={!data?.isExternal}
                onClick={() => gtmEventService.onFileOpen(isProject)}
              >
                <Button
                  style={{ width: '100%' }}
                  size="small"
                  ghost
                  type="primary"
                  id="open-file"
                  icon={<FontAwesomeIcon icon={faExternalLink} />}
                >
                  Abrir
                </Button>
              </a>
            </Tooltip>
          </>
        )}
        {data?.id > 1 && canEdit ? (
          <Tooltip placement="top" title="Excluir">
            <Button
              id={isFolder ? 'delete-folder' : 'delete-file'}
              size="small"
              style={{ width: '100%' }}
              ghost
              type="danger"
              icon={<FontAwesomeIcon icon={faTrashAlt} />}
              onClick={() => setShowConfirmModal(true)}
            >
              {!isFolder ? 'Excluir arquivo' : 'Excluir pasta'}
            </Button>
          </Tooltip>
        ) : null}
      </BtnContainer>
      {showConfirmModal && (
        <ConfirmModal
          text={`Deseja realmente remover est${isFolder ? 'a pasta' : 'e arquivo'}?`}
          onSubmit={handleRemove}
          onClose={() => setShowConfirmModal(false)}
        />
      )}
    </>
  );
};

RenderFileInfo.propTypes = {
  data: PropTypes.instanceOf(Object),
  setSelectedIndexSideBar: PropTypes.func,
  user: PropTypes.instanceOf(Object),
  isFolderShared: PropTypes.bool,
  isProject: PropTypes.bool,
  isCustomer: PropTypes.bool,
  isFolder: PropTypes.bool,
  selectedTab: PropTypes.string,
  idRefurbish: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selectFile: PropTypes.instanceOf(Object),
  onToggleModal: PropTypes.func,
  isModal: PropTypes.bool,
  onShareItem: PropTypes.func,
  isModuleShared: PropTypes.bool,
  onChangeFileFolder: PropTypes.func,
  reload: PropTypes.func,
  viewImage: PropTypes.bool
};

export default RenderFileInfo;
