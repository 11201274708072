import { useRef, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import useCRUD from '../../_Hooks/useCRUD';
import { transformResponsibleFilter } from '../../lib/helpers/helper';

const refetchTaskOptions = {
  include: ['parent', 'user']
};

const generalStep = {
  id: 1,
  name: 'Geral',
  order: '1.00000',
  taskBasedDate: true
};

const useScheduleData = ({
  referModel = 'refurbish',
  referKey = 'idRefurbish',
  referOptions,
  idReference,
  filter,
  generalTask,
  setSelectStepModal,
  dateRangeDisplay,
  isMobile,
  setEditModalId,
  defaultOrder = [
    ['order', 'ASC NULLS FIRST'],
    ['order', 'ASC']
  ]
}) => {
  const [list, setList] = useState([]);
  const [isDefaultOrder, setIsDefaultOrder] = useState(true);
  const [listOrder, setListOrder] = useState(defaultOrder);
  const [childrenOrder, setChildrenOrder] = useState(defaultOrder);
  const expandedRows = useRef([]);
  const listRef = useRef(list);

  const {
    handleGet: handleGetTask,
    handleCreate: handleCreateTask,
    handleUpdate: handleUpdateTask,
    handleDelete: handleDeleteTask
  } = useCRUD({
    model: 'task',
    immediatelyLoadData: false
  });

  const {
    handleGet: handleGetReferStep,
    handleCreate: handleCreateReferStep,
    handleUpdate: handleUpdateReferStep
  } = useCRUD({
    model: `${referModel}-step`,
    immediatelyLoadData: false
  });

  const { handleGet: getCount } = useCRUD({
    model: 'task',
    pathOptions: '/countTasksByModel/step',
    immediatelyLoadData: false
  });

  const clearTasks = () => {
    expandedRows.current = [];
    setList(prev => {
      return prev?.map(item => {
        return { ...item, step: { ...item?.step, tasks: undefined } };
      });
    });
  };

  useEffect(() => {
    clearTasks();
  }, [listOrder, childrenOrder]);

  useEffect(() => {
    expandedRows.current = []; // close all
  }, [filter]);

  useEffect(() => {
    listRef.current = list;
  }, [list]);

  const getAggregators = where => {
    return getCount({
      refetchOptions: {
        where: {
          [referKey]: idReference,
          ...where,
          ...transformResponsibleFilter(filter)
        }
      }
    }).then(res => {
      if (res?.error) return {};
      const [count, dates] = res || [];
      const countMap = count?.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {}) || {};
      return dates?.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.id]: {
            ...cur,
            ...countMap[cur.id]
          }
        }),
        {}
      );
    });
  };

  const updateAggregators = (aggregators, lst) => {
    setList(prev => {
      return [...(lst || prev || [])].map(item => {
        const shouldMerge = !!aggregators?.[item.step.id];
        return {
          ...item,
          ...(item.taskBasedDate &&
            shouldMerge && {
              startDate: aggregators?.[item.step.id]?.minDate,
              endDate: aggregators?.[item.step.id]?.maxDate,
              estimativeDuration: aggregators?.[item.step.id]?.estimativeDuration,
              realDuration: aggregators?.[item.step.id]?.realDuration
            }),
          step: {
            tasks: null,
            ...item.step,
            ...(shouldMerge && { taskCount: aggregators?.[item.step.id]?.taskCount })
          }
        };
      });
    });
  };

  const loadData = () => {
    clearTasks();
    return Promise.all([
      handleGetReferStep({
        refetchOptions: { ...referOptions, order: listOrder },
        generateLoading: false
      }),
      getAggregators()
    ]).then(([result, aggregators]) => {
      const _list = [{ step: { ...generalStep } }, ...(result || [])];
      updateAggregators(aggregators, _list);
    });
  };

  const loadTasks = (idStep, { forceReload } = {}) => {
    if (!idStep) return Promise.resolve();
    const parentIndex = list?.findIndex(p => p?.step?.id === idStep);
    if (!forceReload && list?.[parentIndex]?.step?.tasks) {
      return Promise.resolve();
    }

    return handleGetTask({
      refetchOptions: {
        attributes: { exclude: ['lastModifiedBy'] },
        ...refetchTaskOptions,
        where: {
          idStep,
          [referKey]: idReference,
          ...transformResponsibleFilter(filter)
        },
        order: childrenOrder
      }
    }).then(children => {
      setList(prev => {
        const _list = [...prev];
        const _parent = _list?.[parentIndex];
        if (_parent?.step) _parent.step.tasks = children;
        return _list;
      });
    });
  };

  const refreshStep = (data, action) => {
    if (!data) return;
    setList(lst => {
      const newList = [...lst];

      const idx = lst.findIndex(p => p.id === data.id);

      const tasks = lst[idx]?.step?.tasks;
      const _data = {
        ...data,
        step: {
          ...data?.step,
          tasks,
          taskCount: lst[idx]?.step?.taskCount || 0
        }
      };

      if (idx === -1) {
        return [...lst, _data];
      }

      newList.splice(idx, 1);

      if (action !== 'delete') {
        const orderIndex = newList.findIndex(p => +_data.order < +p.order);
        newList.splice(orderIndex === -1 ? newList.length : orderIndex, 0, _data); // insert on correct order
      }

      return newList;
    });
  };

  const refreshTask = (data, action) => {
    setList(lst => {
      const newList = [...lst];
      const indexStep = lst.findIndex(item => item.step.id === data.idStep);
      const { tasks } = newList[indexStep].step;
      if (tasks === undefined) return lst; // children not loaded yet
      const indexTask = tasks.findIndex(p => p.id === data.id);

      if (indexTask === -1) {
        // remove the task from any step before inserting it again to the list:
        newList.forEach(item => {
          const taskIdx = item.step?.tasks?.findIndex(p => p.id === data.id);
          if (taskIdx >= 0) item.step.tasks.splice(taskIdx, 1);
        });
      } else {
        tasks.splice(indexTask, 1);
      }

      if (action !== 'delete') {
        const orderIndex = tasks.findIndex(p => +data.order < +p.order);
        tasks.splice(orderIndex === -1 ? tasks.length : orderIndex, 0, data); // insert on correct order
      }

      newList.splice(indexStep, 1, { ...newList[indexStep], step: { ...newList[indexStep].step, tasks } });

      return newList;
    });
  };

  const refreshRow = (data, action) => {
    if (!data) return;
    const _isChildren = data?.idParent !== undefined;

    if (!_isChildren) refreshStep(data, action);
    else refreshTask(data, action);
  };

  const handleAdd = ({ idStep } = {}, isTask) => {
    const create =
      isTask || generalTask
        ? handleCreateTask({
            values: {
              ...(dateRangeDisplay?.value &&
                dateRangeDisplay.value !== 'all' && { startDate: new Date(), endDate: new Date() }),
              name: '',
              [referKey]: idReference || null,
              idStep: idStep || 1
            },
            noLoading: true,
            refresh: false
          }).then(task => {
            if (!isMobile?.()) {
              expandedRows.current = [...expandedRows.current, idStep];
            } else if (task?.id) {
              setEditModalId(task.id);
            }
            return task;
          })
        : handleCreateReferStep({
            values: { [referKey]: idReference, idStep },
            noLoading: true,
            refresh: false,
            postOptions: { ...referOptions, order: listOrder }
          });

    if (!generalTask) {
      return loadTasks(idStep)
        .then(() => {
          expandedRows.current = [...expandedRows.current, idStep];
        })
        .then(() => create)
        .then(data => {
          setSelectStepModal && setSelectStepModal(false);
          if (!data?.error) {
            refreshRow(data?.dataValues || data, isTask, 'add');
          }

          return data;
        });
    }

    return create;
  };

  const getDescendantsSteps = taskId => {
    if (!listRef.current) return [];

    const linkedTasks = {};

    listRef.current.forEach(p => {
      if (p.step.tasks == null) return;
      p.step.tasks.forEach(t => {
        linkedTasks[t.idParent] = { id: t.id, idStep: t.idStep };
      });
    });

    const steps = {};

    let next = linkedTasks[taskId];
    while (next?.id) {
      steps[next.idStep] = true;
      next = linkedTasks[next.id];
    }

    return Object.keys(steps).map(p => Number(p));
  };

  const loadDescendantsSteps = taskId => {
    if (!taskId) return;
    getAggregators()
      .then(aggregators => updateAggregators(aggregators))
      .then(() => getDescendantsSteps(taskId).map(p => loadTasks(p, { forceReload: true })));
  };

  const handleChange = (values, isChildren = true, isFinished, { shouldUpdateDescendants } = {}) => {
    const update = isChildren ? handleUpdateTask : handleUpdateReferStep;
    const updateOptions = isChildren ? {} : { ...referOptions, order: listOrder };
    return update({ id: values.id, values, noLoading: true, refresh: false, updateOptions }).then(resp => {
      if (!resp.error) {
        const _responseData = resp?.dataValues || resp;
        refreshRow(_responseData, isChildren, 'update');
        if (shouldUpdateDescendants) loadDescendantsSteps(_responseData?.id);
        if (isFinished) toast && toast.success('Tarefa concluída com sucesso!');
      }

      return resp;
    });
  };

  const handleSort = ({ key, order }) => {
    const _key = !key || Array.isArray(key) ? key : [key];

    const _order = _key?.length && _key.includes('endDate') ? [[..._key, order]] : defaultOrder;

    const _childrenOrder = _key?.length ? [[..._key, order]] : defaultOrder;

    setIsDefaultOrder(_key?.length === 0);
    setListOrder(_order);
    setChildrenOrder(_childrenOrder);
  };

  const handleRefresh = (id, _, { row, submit, shouldUpdateDescendants } = {}) => {
    if (!id) {
      return null;
    }

    if (shouldUpdateDescendants) {
      return loadDescendantsSteps(row?.id || id);
    }

    const isParent = row && row.idParent === undefined;

    const _get = isParent
      ? handleGetReferStep({
          refetchOptions: { ...referOptions, where: { ...referOptions.where, id } },
          generateLoading: false
        })
      : handleGetTask({
          refetchOptions: { ...refetchTaskOptions, where: { ...refetchTaskOptions.where, id } }
        });

    return _get.then(resp => {
      const item = submit?.actionType === 'delete' ? submit?.oldData : resp?.[0];
      refreshRow(item, submit?.actionType);
    });
  };

  const handleBulkRefresh = items => {
    if (!Array.isArray(items)) return;
    items.forEach(p => refreshRow(p));
  };

  const handleBulkChange = (values, selectedRows) => {
    handleUpdateTask({ values: { ids: selectedRows, ...values }, refresh: false, updatePathOptions: '/bulk' }).then(
      data => {
        const hashList = data.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {});
        setList(prevList =>
          prevList.map(refurbishStep => {
            const tasks = refurbishStep?.step?.tasks?.map(item => {
              return hashList[item.id] || item;
            });

            return { ...refurbishStep, step: { ...refurbishStep.step, tasks } };
          })
        );
      }
    );
  };

  const handleBulkDelete = ids => {
    handleDeleteTask({ refresh: false, deletePathOptions: '/bulk', values: { ids } });
    const hashList = ids.reduce((acc, cur) => ({ ...acc, [cur]: cur }), {});
    setList(prevList =>
      prevList.map(refurbishStep => {
        const tasks = refurbishStep?.step?.tasks?.filter(item => typeof hashList[item.id] !== 'number');
        return { ...refurbishStep, step: { ...refurbishStep.step, tasks } };
      })
    );
  };

  const onAfterSubmitDropdown = (resp, onCloseParams) => {
    return resp?.actionType === 'delete'
      ? refreshRow(resp?.oldData, resp?.actionType)
      : refreshRow(resp?.task || resp?.response || resp || onCloseParams?.task, resp?.actionType);
  };

  return {
    list,
    setList,
    loadData,
    loadTasks,
    refreshRow,
    handleAdd,
    handleChange,
    handleSort,
    handleRefresh,
    handleBulkChange,
    handleBulkDelete,
    handleBulkRefresh,
    expandedRows,
    onAfterSubmitDropdown,
    isDefaultOrder,
    listOrder
  };
};

export default useScheduleData;
