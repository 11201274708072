import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Space, Checkbox } from 'antd';

import { StyledText, StyledContentDiv, DivTotal } from './PdfTable.styled';
import ExportEstimateToPdfDrawer from '../Drawer/ExportEstimateToPdfDrawer';
import Table from './Table';
import formatCurrency from '../../lib/helpers/formatCurrency';
import { sumByField } from '../../lib/helpers/helper';
import { parseArrayAsObject } from '../../lib/helpers/parseArrayAsObject';

const createColumnsFromCheckBox = (columns, totalPrice) => {
  const checkBoxes = totalPrice ? [{ key: 'checkTotalPrice', value: `totalPrice`, label: 'Total Geral' }] : [];
  columns.forEach(column =>
    checkBoxes.push({
      key: column.key,
      value: column.key,
      label: column.key === 'image' ? 'Imagem' : column.title,
      checked: true
    })
  );
  return checkBoxes;
};

const PdfTable = ({
  list,
  grouped,
  columns,
  columnsMapping,
  childrenColumnsMapping,
  onlyTasks,
  total,
  pdfProps,
  ...props
}) => {
  const { unit: unitsArray, refurbishItemStatus: specificationStatus, taskStatus } = useSelector(
    state => state.setup.systemData
  );
  const { user } = useSelector(state => state.authReducer) || {};
  const [tableColumns, setTableColumns] = useState([]);
  const [tableChildrenColumns, setTableChildrenColumns] = useState([]);
  const [tableConfigCheckboxes, setTableConfigCheckboxes] = useState([]);
  const [serializedUnitsArray, setSerializedUnits] = useState({});
  const [totalPrice, setTotalPrice] = useState(true);
  const [showTotal, setShowTotal] = useState(true);
  const [serializedStatusArray, setSerializedStatusArray] = useState({});
  const [selectedConfigCheckbox, setSelectedConfigCheckbox] = useState([]);
  const { paymentStatus } = useSelector(state => state.setup.systemData);

  const [groupList, setGroupList] = useState([]);

  const onChangeConfig = checkedValues => {
    if (checkedValues.length > 0) {
      setTableColumns(
        columnsMapping({
          list,
          isPrint: true,
          printColumns: checkedValues,
          unitsArray,
          serializedUnitsArray,
          serializedStatusArray,
          userType: user.type,
          onlyTasks,
          ...props
        })
      );
    }
    setSelectedConfigCheckbox(checkedValues);
    setTotalPrice(checkedValues.includes('totalPrice'));
    setShowTotal(checkedValues.includes('total'));
  };

  useEffect(() => {
    const _columns = columnsMapping({
      list,
      printColumns: columns.map(c => c.key),
      unitsArray,
      serializedUnitsArray,
      serializedStatusArray,
      userType: user.type,
      ...props
    });

    const _childrenColumns =
      childrenColumnsMapping &&
      childrenColumnsMapping({
        isPrint: true,
        paymentStatus,
        ...props
      });

    setTableChildrenColumns(_childrenColumns);
    setTableColumns(_columns);
    setTableConfigCheckboxes(createColumnsFromCheckBox(_columns, total));
    setGroupList(list.map(e => (!e?.item?.length ? null : e)).filter(Boolean));
  }, [list]);

  useEffect(() => {
    const object = parseArrayAsObject(unitsArray, true);
    setSerializedUnits(object);
  }, [unitsArray]);

  useEffect(() => {
    setSelectedConfigCheckbox(tableConfigCheckboxes.map(config => config.value));
  }, [tableConfigCheckboxes]);

  useEffect(() => {
    const object = parseArrayAsObject(props.schedule ? taskStatus : specificationStatus, true);
    setSerializedStatusArray(object);
  }, [specificationStatus, taskStatus]);

  const getItems = () => {
    const items = grouped?.value ? list.reduce((acc, cur) => [...acc, ...(cur.item || [])], []) : list;
    return items.filter(value => value.type);
  };

  const RenderCheckDiv = (
    <StyledContentDiv>
      <StyledText>Configurar colunas</StyledText>
      <div style={{ display: 'flex', paddingTop: '8px' }}>
        <Checkbox.Group
          key="checkGroupKey"
          value={selectedConfigCheckbox}
          options={tableConfigCheckboxes}
          onChange={onChangeConfig}
        />
      </div>
    </StyledContentDiv>
  );

  const _Table = (
    <>
      {totalPrice === true ? (
        <DivTotal>
          {total && (
            <Space direction="vertical" size={8}>
              {formatCurrency(sumByField(getItems(), 'total'), {
                currencySymbol: 'Total Geral: R$'
              })}
            </Space>
          )}
        </DivTotal>
      ) : (
        <div />
      )}
      <div>
        {grouped?.value && list.length ? (
          groupList.map(group => (
            <Table
              rowChildrenSelection={false}
              key={`item${group.id}`}
              defaultExpandAllRows
              defaultExpandAllGroups
              list={group.item}
              group={group}
              columns={tableColumns}
              childrenColumns={tableChildrenColumns}
              pagination={false}
              border
              stickyTop={null}
              showTotal={showTotal}
              {...props}
            />
          ))
        ) : (
          <Table
            paddingFirstColumn={0}
            rowChildrenSelection={false}
            defaultExpandAllRows
            key="item"
            list={list}
            columns={tableColumns}
            childrenColumns={tableChildrenColumns}
            pagination={false}
            border
            stickyTop={null}
            showTotal={showTotal}
            {...props}
          />
        )}
      </div>
    </>
  );

  return <ExportEstimateToPdfDrawer {...pdfProps} PdfComponent={_Table} CheckComponent={RenderCheckDiv} />;
};

PdfTable.propTypes = {
  list: PropTypes.instanceOf(Object),
  grouped: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.bool]),
  columns: PropTypes.instanceOf(Object),
  columnsMapping: PropTypes.instanceOf(Object),
  serializedPriorities: PropTypes.instanceOf(Array),
  serializedPrioritiesArray: PropTypes.instanceOf(Object),
  schedule: PropTypes.bool,
  childrenColumnsMapping: PropTypes.instanceOf(Object),
  total: PropTypes.bool,
  onlyTasks: PropTypes.bool,
  pdfProps: PropTypes.instanceOf(Object)
};

export default PdfTable;
