import React from 'react';
import ReactDOM from 'react-dom';
import { toast } from 'react-toastify';
import TagManager from 'react-gtm-module';

import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.scss';
import 'react-toastify/dist/ReactToastify.css';

import 'moment/locale/pt-br';

// @Todo remove GTM TAG ID
const tagManagerArgs = {
  gtmId: 'GTM-KNSPN5G',
  auth: process.env.REACT_APP_GTM_AUTH,
  preview: process.env.REACT_APP_GTM_PREVIEW
};

TagManager.initialize(tagManagerArgs);

window.analytics.load(process.env.REACT_APP_SEGMENT_WRITE_KEY);

toast.configure();

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
