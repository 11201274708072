import React, { memo } from 'react';
import { Heading } from '@homehero/hero-style';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Space, Tooltip } from 'antd';
import gtmIds from '../NavBar/gtm-ids';
import SelectCustomerRefurbish from '../Select/SelectCustomerRefurbish';
import QuickCreateActions from '../Dropdown/QuickCreateActions';
import Notification from '../Notification/Notification';

import css from './MainHeader.module.scss';
import Button from '../Button/Button';
import UserProfile from '../NavBar/UserProfile';
import { spaces } from '../../styles/style';

const MainHeader = ({ toggle, noShowCollapse, level, className, isProfile, title = '' }) => {
  const { user } = useSelector(state => state.authReducer);
  const { userType } = useSelector(state => state.setup.enums);
  const isCustomer = user.userType === userType.customer.value;
  const isProvider = user.userType === userType.provider.value;
  const isAnonymous = !!user?.anonymous;

  const newClasses = classNames({
    [css['main-header']]: true,
    [css['main-header--isProfile']]: isProfile,
    [css['main-header--collapse']]: toggle && !noShowCollapse
  });

  return (
    <div className={newClasses}>
      <Helmet>
        <title>Vobi | {title}</title>
      </Helmet>
      <header>
        <div className={classNames(css[className], css['main-header__heading'])}>
          {title && <Heading title={title} level={level} gray />}
          {!isAnonymous && (
            <div className={css['main-header__actions']}>
              <Space size={16}>
                {isCustomer && (
                  <Button id="firstStepsTour" type="primary" className="hideMobile">
                    Primeiros passos
                  </Button>
                )}
                {isCustomer && <SelectCustomerRefurbish />}
                {isProvider && <QuickCreateActions />}
                <div
                  style={{
                    height: spaces.space4,
                    width: spaces.space4,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer'
                  }}
                  id={gtmIds.help}
                  data-gtm-id={gtmIds.help}
                >
                  <Tooltip placement="bottom" title="Ajuda">
                    <FontAwesomeIcon icon={faCircleQuestion} />
                  </Tooltip>
                </div>
                <Notification notificationUniqueKey="MainHeader" />
                {!user.isCustomerView && !user.anonymous ? <UserProfile below before size="default" /> : null}
              </Space>
            </div>
          )}
        </div>
      </header>
    </div>
  );
};

MainHeader.propTypes = {
  toggle: PropTypes.bool,
  noShowCollapse: PropTypes.bool,
  level: PropTypes.number.isRequired,
  className: PropTypes.string,
  isProfile: PropTypes.bool,
  title: PropTypes.string
};

export default memo(MainHeader);
