import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Checkbox, Col, Row, Space, Tooltip } from 'antd';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { MaskedInput } from '@homehero/hero-style';
import useViewport from '../../_Hooks/useViewport';

import {
  Container,
  Configuration,
  PaymentTypesContainer,
  ContainerCheckboxes,
  Text,
  TableContainer,
  ContentRow
} from './PaymentConfiguration.styles';
import Select from '../Select/Select';
import { useContextHook } from '../../contexts/GeneralContext';
import useCRUD from '../../_Hooks/useCRUD';
import { SubTitle } from '../Form/PaymentFormItem.styled';
import { colors, spaces } from '../../styles/style';
import Label from '../Label/Label';
import Input from '../Input/Input';
import DatePicker from '../Datepicker/Datepicker';
import { getColorByStatusInstallment } from '../../lib/helpers/helper';
import PaymentConfigurationMobileCard from '../List/PaymentConfigurationMobileCard';
import { Line } from '../../_Pages/Payments/Payment.style';
import formatCurrency from '../../lib/helpers/formatCurrency';
import formatNumber from '../../lib/helpers/formatNumber';
import { ErrorStyled } from '../Form/PaymentBasicForm.styled';
import { allDropdown } from '../../lib/mapping/Dropdown/allDropdown';
import TableActionDropDown from '../Dropdown/TableActionDropDown';
import {
  calculatePercentage,
  calculatePriceByPercentage,
  generateInstallments,
  recalculateInstallments
} from '../../lib/helpers/installment';

const PaymentConfiguration = ({ isCustomerView }) => {
  const { user } = useSelector(state => state.authReducer) || {};
  const {
    handleLoad,
    values,
    setField,
    isView,
    isPublic,
    errors,
    touched,
    totalInstallmentError,
    isRevision,
    setTouched
  } = useContextHook();

  const {
    name,
    installments,
    paymentTypes,
    total: chargeTotal,
    value: billValue,
    id,
    billingDate,
    isCharge,
    billType
  } = values || {};
  const total = isCharge ? chargeTotal || 0 : billValue || 0;
  const { installmentStatuses, paymentStatuses } = useSelector(state => state.setup.enums);
  const { list: paymentTypeList } = useCRUD({ model: 'paymentType' });
  const paymentNamesList = paymentTypeList.map(type => type.name);
  const defaultTypesNameList = paymentTypes?.map(type => type.name);

  const [options, setOptions] = useState([]);
  const [numberOfInstalments, setNumberOfInstalments] = useState(installments?.length || 0);

  const { isMobile } = useViewport(window.innerWidth);

  const row = useRef();

  const handleChange = (index, field, value) => {
    row.current = {
      index,
      installment: installments[index]
    };
    row.current.installment[field] = value;
    if (field === 'percentage') row.current.installment.price = calculatePriceByPercentage(total, value);
    else if (field === 'price') row.current.installment.percentage = calculatePercentage(total, value);
    else {
      installments[index] = row.current.installment;
      setField('installments')(installments);
      row.current = null;
    }
  };

  const handleBlur = () => {
    const _installments = recalculateInstallments(row.current, total, installments);
    if (!_installments) return;
    setField('installments')(_installments);
    row.current = null;
  };

  const updateFieldWhenChangingNumberOfInstallments = number => {
    const newInstallments = generateInstallments(number, total, installments, {
      paymentName: name,
      idCompany: user?.idCompany,
      idPayment: id,
      billingDate
    });
    if (!Array.isArray(newInstallments) && newInstallments.error) {
      toast.error(newInstallments.error);
      return;
    }
    setNumberOfInstalments(newInstallments.length);
    setField('installments')(newInstallments);
  };

  useEffect(() => {
    const array = [
      {
        label: 'Selecione',
        value: 0
      },
      {
        label: 'À vista',
        value: 1
      }
    ];

    for (let i = 2; i < 49; i++) {
      array.push({ label: `${i}x`, value: i });
    }

    setOptions(array);
  }, []);

  useEffect(() => {
    updateFieldWhenChangingNumberOfInstallments(numberOfInstalments);
  }, [total]);

  useEffect(() => {
    if (options.length > 0) {
      setNumberOfInstalments(installments?.length || 0);
    }
  }, [installments, options]);

  const handleChangeCheckboxGroup = selectedTypes => {
    const array = paymentTypeList.filter(type => selectedTypes.includes(type.name));

    setField('paymentTypes')(array);
  };

  const paymentCondition = useMemo(() => {
    if (numberOfInstalments === 1) return 'Á vista';
    if (numberOfInstalments === 0) return '';
    return `${numberOfInstalments}x`;
  }, [numberOfInstalments]);

  const showInstallmentActions =
    isView &&
    !isPublic &&
    values?.idPaymentStatus !== paymentStatuses.cancelled &&
    values?.idPaymentStatus !== paymentStatuses.draft &&
    !isRevision;

  const getPaymentConditionTooltipText = () => {
    if (isCharge) return 'Para interagir o valor total da cobrança deve ser diferente de zero';
    return `Para interagir o valor da ${billType === 'expense' ? 'despesa' : 'receita'} deve ser diferente de zero`;
  };

  const hasValueError = (touched.total && errors?.total) || (touched.value && errors?.value);

  const ConfigurationBox = (
    <>
      <SubTitle>Pagamento</SubTitle>
      <Configuration>
        <div>
          <p>Condições de pagamento</p>
          {!isView ? (
            <Tooltip title={`${total > 0 ? '' : getPaymentConditionTooltipText()}`}>
              <div style={{ width: isMobile() ? '50%' : '184px' }}>
                <Select
                  value={numberOfInstalments}
                  onChange={val => {
                    setTouched(isCharge ? 'total' : 'value')(true);
                    updateFieldWhenChangingNumberOfInstallments(val);
                  }}
                  options={options}
                />
                {hasValueError && <ErrorStyled>{errors?.total || errors?.value}</ErrorStyled>}
              </div>
            </Tooltip>
          ) : (
            <Text>{paymentCondition}</Text>
          )}
        </div>
        <div>
          <p>Forma de pagamento</p>
          <PaymentTypesContainer>
            <ContainerCheckboxes
              disabled={isView}
              defaultValue={defaultTypesNameList}
              options={!isView ? paymentNamesList : defaultTypesNameList}
              onChange={selectedTypes => handleChangeCheckboxGroup(selectedTypes)}
            >
              <Checkbox />
            </ContainerCheckboxes>
          </PaymentTypesContainer>
          {touched.paymentTypes && errors?.paymentTypes && <ErrorStyled>{errors?.paymentTypes}</ErrorStyled>}
        </div>
      </Configuration>
    </>
  );

  return isMobile() ? (
    <Container>
      {ConfigurationBox}
      {installments && (
        <PaymentConfigurationMobileCard
          list={installments}
          isView={isView}
          isPublic={isPublic}
          isRevision={isRevision}
          numberOfInstalments={numberOfInstalments}
          installmentStatuses={installmentStatuses}
          handleChange={handleChange}
          handleBlur={handleBlur}
          handleLoad={handleLoad}
          touched={touched}
          errors={errors}
          paymentStatus={values?.idPaymentStatus}
          paymentStatuses={paymentStatuses}
          showInstallmentActions={showInstallmentActions}
        />
      )}
      {touched.installments && errors?.installments && (
        <div style={{ marginBottom: spaces.space1, marginLeft: spaces.space2 }}>
          <ErrorStyled>{errors?.installments}</ErrorStyled>
        </div>
      )}

      {totalInstallmentError && (
        <div style={{ marginBottom: spaces.space1, marginLeft: spaces.space2 }}>
          <ErrorStyled>{totalInstallmentError}</ErrorStyled>
        </div>
      )}
    </Container>
  ) : (
    <Space direction="vertical" size={16} style={{ width: '100%', marginBottom: '16px' }}>
      {ConfigurationBox}
      <Line />
      <SubTitle>Parcelas</SubTitle>
      <TableContainer>
        <div className="tableHeader">
          <Row>
            <Col md={2} xs={12}>
              <Label htmlFor="installmentName">Parcela</Label>
            </Col>
            <Col md={3} xs={12}>
              <Label htmlFor="installmentPrice">Valor</Label>
            </Col>
            {!isView && (
              <Col md={3} xs={12}>
                <Label htmlFor="installmentPercent">Percentual</Label>
              </Col>
            )}

            <Col md={3} xs={12}>
              <Label htmlFor="installmentDueDate">Vencimento</Label>
            </Col>
            <Col md={!isView ? 10 : 13} xs={12}>
              <Label htmlFor="installmentDescription">Descrição</Label>
            </Col>
            <Col md={!isPublic ? 2 : 3} xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <Label htmlFor="installmentStatus">Status</Label>
            </Col>
            {showInstallmentActions ? (
              <Col md={1} xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Label htmlFor="installmentActions">Ações</Label>
              </Col>
            ) : null}
          </Row>
        </div>
        <div className="tableBody">
          {installments?.length ? (
            installments?.map((item, index) => {
              const isDefeated =
                dayjs(item.dueDate).isBefore(dayjs(new Date()), 'day') &&
                item.idInstallmentStatus === installmentStatuses.pendingPayment;
              const color = getColorByStatusInstallment(item.idInstallmentStatus, installmentStatuses);
              return (
                <React.Fragment key={`${index + 1}-${installments.length}`}>
                  <Row gutter={[16, 16]} className="tableRow">
                    <ContentRow>
                      <Col md={2} xs={12}>
                        <Text>{`${index + 1}/${installments.length}`}</Text>
                      </Col>
                      <Col md={3} xs={12}>
                        {!isView ? (
                          <MaskedInput
                            disabled={item.idInstallmentStatus === 2}
                            type="currency"
                            value={formatCurrency(item.price, {
                              currencySymbol: 'R$ '
                            })}
                            onChange={e => handleChange(index, 'price', formatNumber(e.target.value))}
                            onBlur={handleBlur}
                            maskOptions={{
                              prefix: 'R$',
                              thousandsSeparatorSymbol: '.',
                              allowDecimal: true
                            }}
                          />
                        ) : (
                          <Text>
                            {formatCurrency(item.price, {
                              currencySymbol: 'R$ '
                            })}
                          </Text>
                        )}
                        {touched.installments && errors[`installments[${index}].price`] && (
                          <ErrorStyled>{errors[`installments[${index}].price`]}</ErrorStyled>
                        )}
                      </Col>
                      {!isView && (
                        <Col md={3} xs={12}>
                          <MaskedInput
                            disabled={item.idInstallmentStatus === 2}
                            type="currency"
                            value={formatCurrency(item.percentage, {
                              currencySymbol: ''
                            })}
                            onChange={e => handleChange(index, 'percentage', formatNumber(e.target.value))}
                            onBlur={handleBlur}
                            maskOptions={{
                              suffix: '%',
                              prefix: '',
                              includeThousandsSeparator: false,
                              allowDecimal: true
                            }}
                          />
                          {touched.installments && errors[`installments[${index}].percentage`] && (
                            <ErrorStyled>{errors[`installments[${index}].percentage`]}</ErrorStyled>
                          )}
                        </Col>
                      )}

                      <Col md={3} xs={12}>
                        {!isView ? (
                          <DatePicker
                            disabled={item.idInstallmentStatus === 2}
                            id="billingDate"
                            name="billingDate"
                            style={{ width: '100%' }}
                            format="DD/MM/YYYY"
                            allowClear={false}
                            value={item?.dueDate ? dayjs(item?.dueDate) : null}
                            onChange={value => handleChange(index, 'dueDate', value)}
                          />
                        ) : (
                          <Text>{item?.dueDate ? dayjs(item?.dueDate).format('DD/MM/YYYY') : ''}</Text>
                        )}
                        {touched.installments && errors[`installments[${index}].dueDate`] && (
                          <ErrorStyled>{errors[`installments[${index}].dueDate`]}</ErrorStyled>
                        )}
                      </Col>
                      <Col md={!isView ? 10 : 13} xs={12}>
                        {!isView ? (
                          <Input
                            disabled={item.idInstallmentStatus === 2}
                            type="text"
                            id="itemDescription"
                            size="middle"
                            name="itemDescription"
                            placeholder="Descrição do item"
                            value={item.description}
                            onChange={e => handleChange(index, 'description', e.target.value)}
                          />
                        ) : (
                          <Text>{item?.description}</Text>
                        )}
                        {touched.installments && errors[`installments[${index}].description`] && (
                          <ErrorStyled>{errors[`installments[${index}].description`]}</ErrorStyled>
                        )}
                      </Col>
                      <Col md={isPublic ? 3 : 2} xs={12} className="statusCol">
                        <Text style={{ color: isDefeated ? colors.error60 : color }}>
                          {isDefeated ? 'Vencido' : item?.installmentStatus?.name || ''}
                        </Text>
                      </Col>
                      {showInstallmentActions ? (
                        <Col md={1} xs={12}>
                          <TableActionDropDown
                            key={`action${item.id}`}
                            item={item}
                            mappingObj={allDropdown.paymentChildren({ installment: item })}
                            model="installment"
                            installment
                            afterSubmit={handleLoad}
                            isCustomerView={isCustomerView}
                            isGetById
                          />
                        </Col>
                      ) : null}
                    </ContentRow>
                  </Row>
                  {index !== installments.length - 1 && <Line />}
                </React.Fragment>
              );
            })
          ) : (
            <p className="emptyState">Selecione as condições de pagamento</p>
          )}
        </div>
        {touched.installments && errors?.installments && (
          <div style={{ marginBottom: spaces.space1, marginLeft: spaces.space2 }}>
            <ErrorStyled>{errors?.installments}</ErrorStyled>
          </div>
        )}

        {totalInstallmentError && (
          <div style={{ marginBottom: spaces.space1, marginLeft: spaces.space2 }}>
            <ErrorStyled>{totalInstallmentError}</ErrorStyled>
          </div>
        )}
      </TableContainer>
    </Space>
  );
};

PaymentConfiguration.propTypes = {
  isCustomerView: PropTypes.bool
};

export default PaymentConfiguration;
