import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isEqual from 'react-fast-compare';
import MobileCardItem from './MobileCardItem';
import GroupHeader from '../Header/GroupHeader';
import Draggable from '../Draggable/Draggable';
import NoContent from '../NoContent/NoContent';
import emptySpecification from '../Images/emptySpecification.svg';
import emptyInstalments from '../Images/emptyInstalments.svg';
import emptyShopping from '../Images/emptyShopping.svg';
// context
import useLongPress from '../../_Hooks/useLongPress';

import { colors } from '../../styles/style';

const emptyStateMap = isCustomer => ({
  specification: {
    description: !isCustomer ? `Você ainda não adicionou nenhum item` : 'Nenhum item encontrado neste orçamento',
    image: <img src={emptySpecification} alt="emptySpecification" />
  },
  instalments: {
    description: !isCustomer
      ? `Não há nenhum item aprovado no seu orçamento para gerar parcelas`
      : 'Nenhum item encontrado no orçamento para gerar parcelas',
    image: <img src={emptyInstalments} alt="emptyInstalments" />
  },
  shopping: {
    description: `Não há nenhum produto aprovado para essa lista de compras`,
    image: <img src={emptyShopping} alt="emptyShopping" />
  },
  default: {
    description: 'Nenhum item encontrado',
    image: null
  }
});

const MobileCardList = ({
  list = [],
  columns,
  childrenColumns,
  selectedItems = [],
  selectedRowKeys = [],
  onSelectItem = f => f,
  group,
  onSubmit = f => f,
  refetch = f => f,
  setShowFooter = f => f,
  AddComponent,
  setCurrentItem,
  groupOpen,
  setGroupOpen,
  minHeight,
  childrenColumnName = 'children',
  searchList,
  grouped,
  isCustomer,
  emptyStateKey = 'default',
  onOpenParent
}) => {
  const idReference = group?.id ? group.id : null;
  const [openParent, setOpenParent] = useState([]);
  const [canSelectItems, setCanSelectItems] = useState(false);
  const [showMenuModal, setShowMenuModal] = useState(false);
  const isGroupOpen = !idReference || idReference === groupOpen;

  const emptyState = emptyStateMap(isCustomer)[emptyStateKey];

  useEffect(() => {
    if (selectedRowKeys.length === 0) {
      setCanSelectItems(false);
      setShowFooter(false);
    }
  }, [selectedRowKeys, selectedItems]);

  const selectItem = item => {
    if (selectedRowKeys.includes(item.id)) {
      const keys = selectedRowKeys;
      const items = selectedItems;
      let removeKeys = [];

      if (item.children?.length) {
        removeKeys = item.children.map(childrenItem => childrenItem.id);
      }

      if (item.isChildren) {
        const parentId = item?.idParent;
        if (!items.find(i => i?.idParent === parentId)) {
          removeKeys.push(parentId);
        }
      }

      removeKeys.push(item.id);
      const _keys = keys.filter(key => !removeKeys.includes(key));
      const _items = items.filter(i => !removeKeys.includes(i.id));
      onSelectItem(_keys, _items);
    } else {
      const addKeys = [];
      if (item[childrenColumnName]?.length) {
        item[childrenColumnName].forEach(childrenItem => {
          addKeys.push(childrenItem.id);
        });
      }
      if (item.isChildren) {
        const parentId = item?.idParent;
        if (!selectedRowKeys.includes(parentId)) {
          addKeys.push(parentId);
        }
      }
      addKeys.push(item.id);
      onSelectItem([...selectedRowKeys, ...addKeys], [...selectedItems]);
    }
  };

  const onLongPress = (e, value) => {
    selectedItems.push(value);
    selectItem(value);
    setCanSelectItems(true);
    setShowFooter(true);
  };

  const onClick = (e, value) => {
    e.stopPropagation();
    if (canSelectItems) {
      selectedItems.push(value);
      selectItem(value);
    } else if (value[childrenColumnName] !== undefined) {
      if (openParent.filter(f => f === value.id).length) {
        setOpenParent(openParent.filter(f => f !== value.id));
      } else {
        setOpenParent(prev => [...prev, value.id]);
        onOpenParent && onOpenParent(value.id);
      }
    } else if (setCurrentItem) {
      setCurrentItem(value.id);
    }
  };

  const longPressEvent = useLongPress(onLongPress, onClick, { delay: 500 });

  return (
    <>
      {group?.name && (
        <div role="presentation" onClick={() => setGroupOpen(prev => (prev === group?.id ? null : group?.id))}>
          <GroupHeader icon={isGroupOpen ? faCaretDown : faCaretUp} group={group} refetch={refetch} grouped={grouped} />
        </div>
      )}
      {isGroupOpen ? (
        <>
          {list.length ? (
            list.map(item => {
              const { id: itemId, [childrenColumnName]: children = [] } = item || {};
              const isOpen = !!openParent.filter(f => f === itemId).length;
              return (
                <div
                  key={`card-item-parent-${itemId}`}
                  role="presentation"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: colors.white,
                    margin: '8px 0',
                    borderRadius: 4,
                    border: selectedRowKeys.includes(itemId) ? `1px solid ${colors.primary40}` : 'none'
                  }}
                >
                  <MobileCardItem
                    item={item}
                    columns={columns}
                    open={isOpen}
                    minHeight={minHeight}
                    checked={selectedRowKeys.includes(itemId)}
                    longPressEvent={longPressEvent}
                    onClick={e => onClick(e, item)}
                    searchList={searchList}
                  />
                  {isOpen && children
                    ? children.map((childrenItem, index) => (
                        <MobileCardItem
                          key={`card-item-child-${childrenItem.id}`}
                          item={childrenItem}
                          columns={childrenColumns || columns}
                          minHeight={minHeight}
                          checked={selectedRowKeys.includes(childrenItem.id)}
                          isChildren
                          longPressEvent={longPressEvent}
                          lastChildren={children.length - 1 === index}
                          onClick={e => onClick(e, childrenItem)}
                        />
                      ))
                    : null}
                </div>
              );
            })
          ) : (
            <NoContent
              description={emptyState?.description}
              image={emptyState?.image}
              showButtonIcon={false}
              renderButton={false}
            />
          )}
          {!canSelectItems && AddComponent ? (
            <Draggable onClick={() => setShowMenuModal(true)} defaultPosition={{ x: window.innerWidth / 2 - 50, y: 0 }}>
              <div
                style={{
                  width: '48px',
                  height: '48px',
                  position: 'fixed',
                  backgroundColor: colors.primary40,
                  display: 'flex',
                  alignItems: 'center',
                  borderRadius: 24,
                  bottom: 24
                }}
              >
                <AddComponent
                  handleCreate={data => {
                    onSubmit(data);
                    setShowMenuModal(false);
                  }}
                  createParams={{ idReference }}
                  customTrigger={{ setFunction: setShowMenuModal, value: showMenuModal }}
                >
                  <FontAwesomeIcon icon={faPlus} rotation={90} size="2x" color={colors.white} />
                </AddComponent>
              </div>
            </Draggable>
          ) : null}
        </>
      ) : null}
    </>
  );
};

MobileCardList.propTypes = {
  list: PropTypes.instanceOf(Array),
  columns: PropTypes.instanceOf(Array),
  childrenColumns: PropTypes.instanceOf(Array),
  selectedItems: PropTypes.instanceOf(Array),
  selectedRowKeys: PropTypes.instanceOf(Array),
  onSelectItem: PropTypes.func,
  group: PropTypes.instanceOf(Object),
  onSubmit: PropTypes.func,
  refetch: PropTypes.func,
  setShowFooter: PropTypes.func,
  setCurrentItem: PropTypes.func,
  AddComponent: PropTypes.instanceOf(Object),
  groupOpen: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setGroupOpen: PropTypes.func,
  minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  childrenColumnName: PropTypes.string,
  searchList: PropTypes.bool,
  grouped: PropTypes.instanceOf(Object),
  isCustomer: PropTypes.bool,
  emptyStateKey: PropTypes.string,
  onOpenParent: PropTypes.func
};

export default React.memo(MobileCardList, isEqual);
