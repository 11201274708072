import AnalyticsServiceFactory from '../analytics/analytics';
import { getFileName, getFileType } from '../helpers/helper';

const getSource = isProject => {
  if (isProject == null) return { source: null };
  return { source: isProject ? 'project' : 'opportunity' };
};

const entityMap = {
  companyCustomer: 'customer'
};

const OpportunityEventService = service => {
  const analyticsService = AnalyticsServiceFactory(service);

  return {
    onFileUpload: (files, isProject) => {
      const parsedFiles = files.map(f => {
        const filename = getFileName(f.fullpath);
        return { filename, filetype: getFileType(filename)?.toLowerCase() };
      });

      analyticsService.track({
        event: isProject ? 'project-file-uploaded' : 'opportunity-file-uploaded',
        eventProperties: { files: parsedFiles }
      });
    },
    onFileSeen: isProject =>
      analyticsService.track({
        event: 'file-seen',
        eventProperties: { ...getSource(isProject) }
      }),
    onFileOpen: isProject =>
      analyticsService.track({
        event: 'file-opened',
        eventProperties: { ...getSource(isProject) }
      }),
    onFileDownload: isProject =>
      analyticsService.track({
        event: 'file-downloaded',
        eventProperties: { ...getSource(isProject) }
      }),
    onSharedEmailSent: project =>
      analyticsService.track({
        event: 'project-shared-email-sent',
        eventProperties: { project }
      }),
    onProjectLinked: project =>
      analyticsService.track({
        event: 'project-shared-linked',
        eventProperties: { company: undefined, project }
      }),
    onAddKanbanStep: step =>
      analyticsService.track({
        event: 'project-kanban-step-added',
        eventProperties: { step }
      }),
    onExport: (entity, fileType = 'csv') =>
      analyticsService.track({
        event: `${entityMap[entity] || entity}-exported`,
        eventProperties: { fileType }
      }),
    onImport: ({ entity, fileType = 'csv', idUser, idCompany, itemCount, status, message }) =>
      analyticsService.track({
        event: 'import',
        eventProperties: {
          fileType,
          type: entityMap[entity] || entity,
          userId: idUser,
          companyId: idCompany,
          itemCount,
          status,
          message
        }
      })
  };
};

export default OpportunityEventService;
