import styled, { css } from 'styled-components';
import { Checkbox } from 'antd';

import { spaces, colors, breakpoints, fonts } from '../../styles/style';

export const Container = styled.div`
  position: relative;
  display: inline-block;
  margin: auto ${spaces.space1};
`;

export const WatcherCheckbox = styled(Checkbox)`
  margin: ${spaces.space1};
`;

export const EyeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  padding: ${spaces.space2};

  div {
    position: absolute;
    top: ${spaces.space1};
    right: 0;
    background-color: ${colors.primary40};
    color: ${colors.white};
    border-radius: ${spaces.space0};
    height: 18px;
    width: 18px;
    font-size: ${fonts.sizeXs};
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const UserList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  ${props =>
    props.position === 'bottom-left' &&
    css`
      right: -100%;
      left: auto;
    `}
  height: auto;
  background-color: ${colors.white};
  padding: ${spaces.space2};
  border-radius: ${spaces.space1};
  box-shadow: 0 ${spaces.space1} ${spaces.space2} 0 rgb(0 0 0 / 8%);
  z-index: 10;

  li {
    display: flex;
    flex-direction: row;
    align-items: center;

    input {
      margin-right: ${spaces.space1};
    }

    label {
      margin-top: ${spaces.space0};
    }
  }

  li + li {
    margin-top: ${spaces.space1};
  }

  @media (max-width: ${breakpoints.tablet}) {
    transform: translateX(-50%);
  }
`;
