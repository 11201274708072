import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// components
import { DatePicker } from '@homehero/hero-style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu } from 'antd';
import Dropdown from '../Dropdown/Dropdown';
import Button from './Button';
import StatusSelector from '../StatusSelector/StatusSelector';
import { fonts, breakpoints } from '../../styles/style';

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${fonts.sizeLg};
  }
`;

const BulkButton = ({ onClick, icon, list, shouldBeList, iconColor, children, ...props }) => {
  const [showDropDown, setShowDropDown] = useState(false);

  const toggleDropdown = show => setShowDropDown(show);

  const handleClick = value => {
    onClick(value);
    toggleDropdown(true);
  };

  const componentType = {
    default: (title, value, style) => (
      <Button style={{ fontWeight: 300 }} text onClick={() => handleClick(value)}>
        {style ? <StatusSelector status={{ style }} /> : null}
        {title}
      </Button>
    ),
    date: (title, value) => (
      <DatePicker
        name="predictionDate"
        placeholderText="Informe a data"
        value={value}
        selected={value}
        onChange={e => handleClick(e)}
        open
        inline
      />
    )
  };

  const hasList = !!list?.length;

  const MyMenu = () => (
    <Menu style={{ maxHeight: '300px', overflow: 'auto' }}>
      {list?.map(({ label, value, type = 'default', style = null }) => (
        <Menu.Item key={value}>{componentType[type](label, value, style)}</Menu.Item>
      ))}
    </Menu>
  );

  return !hasList && !shouldBeList ? (
    <>
      <Button onClick={onClick} text noPadding {...props}>
        <StyledDiv>
          <FontAwesomeIcon icon={icon} color={iconColor} />
          {children}
        </StyledDiv>
      </Button>
    </>
  ) : (
    <Dropdown buttonType="text" trigger={['click']} menu={<MyMenu />}>
      <Button noPadding onClick={() => toggleDropdown(!showDropDown)} text {...props}>
        <StyledDiv>
          <FontAwesomeIcon icon={icon} color={iconColor} />
          {children}
        </StyledDiv>
      </Button>
    </Dropdown>
  );
};

BulkButton.propTypes = {
  icon: PropTypes.instanceOf(Object),
  list: PropTypes.instanceOf(Array),
  onClick: PropTypes.func,
  shouldBeList: PropTypes.bool,
  iconColor: PropTypes.string,
  children: PropTypes.instanceOf(Object)
};

BulkButton.displayName = 'BulkButton';

export default BulkButton;
