import React, { useState, useEffect, useRef } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import PropTypes from 'prop-types';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import Input from '../Input/Input';
import { parseSearchableOptions, removeAccents } from '../../lib/helpers/helper';

// components
import Align from '../Align/Align';
import CenteredLoader from '../Loader/CenteredLoader';
import useCRUD from '../../_Hooks/useCRUD';

// style
import css from './FilterDefault.module.scss';
import Button from '../Button/Button';

const StyledCheckbox = styled(Input)`
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin-right: 8px;
  border-radius: 4px;
`;

const FilterDefault = ({ onClick, options, name, closeModal, appliedFilters }) => {
  const offset = useRef(0);
  const stopLoad = useRef(false);
  const [data, setData] = useState([]);
  const [selectAll, setSelectAll] = useState(true);
  const [searchText, setSearchText] = useState('');
  const appliedFiltersIds = appliedFilters?.[name]?.reduce(
    (obj, item) => ({ ...obj, [item.id || item.value]: item.checked }),
    {}
  );
  const { handleGet, list, loading, totalItems } = useCRUD({
    model: options?.model,
    immediatelyLoadData: false
  });

  const handleChange = e => {
    const { value } = e.target;
    offset.current = 0;
    setSearchText(value);
  };

  const handleChangeCB = (e, id) => {
    setData(prev => {
      const lst = [...prev];
      const idx = prev.findIndex(item => (item.id || item.value) === id);
      lst[idx].checked = !lst[idx].checked;
      setSelectAll(!lst.find(element => element.checked));
      return lst;
    });
  };

  const handleClick = () => {
    closeModal && closeModal();
    return onClick(
      data.filter(p => p.checked),
      name
    );
  };

  const handleSelectAll = () => {
    setData(prev => {
      return prev.map(el => ({
        ...el,
        checked: selectAll
      }));
    });
  };

  const loadMore = () => {
    const { modelOptions = { where: { isActive: true } } } = options;
    const { where } = modelOptions;
    const newOptions = {
      ...modelOptions,
      where: {
        ...where,
        ...(searchText && { ulike: { [`${options?.model}.name`]: `%${removeAccents(searchText)}%` } })
      },
      offset: offset.current,
      limit: 10
    };
    handleGet({ refetchOptions: { ...newOptions } });
  };

  const [infiniteRef] = useInfiniteScroll({
    loading,
    hasNextPage: totalItems > data.length && !loading,
    onLoadMore: () => {
      if (!stopLoad.current) {
        offset.current += 1;
        loadMore();
      }
    },
    disabled: false,
    rootMargin: '0px 0px 0px 0px'
  });

  useEffect(() => {
    stopLoad.current = true;

    if (Array.isArray(options)) {
      const _data = searchText
        ? data?.filter(l => removeAccents(l?.name || l?.label)?.includes(removeAccents(searchText)))
        : parseSearchableOptions(options);

      setData(_data);

      return () => {};
    }

    const timer = setTimeout(() => {
      offset.current = 0;
      loadMore();
    }, 400);

    return () => clearTimeout(timer);
  }, [searchText]);

  useEffect(() => {
    if (Array.isArray(options)) {
      setData(
        parseSearchableOptions(options).map(item => {
          if (appliedFiltersIds?.[item.value]) {
            setSelectAll(false);
          }
          return { ...item, checked: !!appliedFiltersIds?.[item.value] };
        })
      );
    } else {
      const parsedList = parseSearchableOptions(list).map(item => {
        if (appliedFiltersIds?.[item.id]) {
          setSelectAll(false);
        }
        return { ...item, checked: !!appliedFiltersIds?.[item.id] };
      });
      setData(prev => (offset.current > 1 ? [...prev, ...parsedList] : parsedList));
      stopLoad.current = false;
    }
  }, [list]);

  return (
    <div>
      <Input onChange={handleChange} icon={<FontAwesomeIcon icon={faSearch} />} />
      <Button
        type="primary"
        text
        onClick={() => {
          setSelectAll(prev => !prev);
          handleSelectAll();
        }}
      >
        {!selectAll ? 'Limpar fitros selecionados' : 'Selecionar todos os filtros'}
      </Button>
      <div className={css['c-filter-default']}>
        <div style={{ minHeight: 220 }}>
          {data?.map(item =>
            !item?.hideFilter ? (
              <div className={css['c-filter-default__checkbox']} key={`${item.id}_${item.value}`}>
                <StyledCheckbox
                  id={`checkbox-${item?.label?.toLowerCase()?.replace(/\s+/g, '')}`}
                  type="checkbox"
                  onChange={e => handleChangeCB(e, item.id || item.value)}
                  checked={item.checked}
                />
                <p id={`label${item?.label?.toLowerCase()?.replace(/\s+/g, '')}`}>{item.label}</p>
              </div>
            ) : null
          )}
        </div>
        {totalItems > data.length && (
          <div ref={infiniteRef}>
            <CenteredLoader />
          </div>
        )}
      </div>
      <Align align="right">
        <Button
          id="apply-filter-button"
          size={1}
          type="default"
          container="outlined"
          noShadow
          onClick={handleClick}
          fullWidth
        >
          Aplicar filtro
        </Button>
      </Align>
    </div>
  );
};

FilterDefault.propTypes = {
  onClick: PropTypes.func,
  options: PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.instanceOf(Object)]),
  name: PropTypes.string,
  closeModal: PropTypes.func,
  appliedFilters: PropTypes.instanceOf(Object)
};

export default FilterDefault;
