import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Option } from 'antd/lib/mentions';
import useViewport from '../../_Hooks/useViewport';

import Modal from './Modal';
import Form from '../Form/Form';
import Select from '../Select/Select';
import Button from '../Button/Button';

import { colors, spaces, breakpoints, fonts } from '../../styles/style';

import { schema, templateMapping } from '../../lib/mapping/Form/templateSchema';

const StyledModal = styled.div`
  margin-top: 8px;
  justify-content: space-between;
  width: 100%;
  display: flex;
  gap: 20px;
`;

const RenderTemplateTypeStyle = styled.div`
  display: contents;
  span {
    margin-left: 16px;
    padding: 2px 5px;
    border-radius: 4px;
    color: ${props => (props.options.type === 1 ? colors.secondary60 : colors.primary40)};
    background: ${props => (props.options.type === 1 ? colors.secondary95 : colors.neutralSecondary90)};
  }
`;

const TemplateModal = styled.div`
  border: solid 1px ${colors.primary40};
  padding: ${spaces.space1} ${spaces.space2};
  border-radius: 16px;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${breakpoints.tablet}) {
    border: none;
    justify-content: flex-start;
    overflow: auto;
  }
  span {
    font-size: ${fonts.sizeSm};
    font-weight: 100;
    display: inline-flex;
    b {
      font-weight: 600;
      margin-right: ${spaces.space0};
      @media (max-width: ${breakpoints.tablet}) {
        margin-right: ${spaces.space1};
        font-size: ${fonts.sizeLg};
      }
    }
    li {
      width: 100%;
      button {
        padding: ${spaces.space1} ${spaces.space2};
      }
    }
  }
  button {
    padding: 0;
    height: auto;
    border-radius: 0;
    margin-left: ${spaces.space2};
    @media (max-width: ${breakpoints.tablet}) {
      margin-left: 0;
    }
  }
  div {
    @media (max-width: ${breakpoints.tablet}) {
      &:last-child {
        button {
          color: ${colors.white};
          margin-left: ${spaces.space1};
        }
      }
    }
  }
  .c-bulk-actions-list {
    display: flex;
    @media (max-width: ${breakpoints.tablet}) {
      margin-left: 0;
      overflow: hidden;
    }
  }
`;

const AddToTemplateModal = ({ isCreate, createOrApplyTemplate, onClose, setIsCreate, listTemplate, setIdTemplate }) => {
  const { isMobile } = useViewport(window.innerWidth);

  const RenderTemplateType = ({ options }) => {
    return (
      <RenderTemplateTypeStyle options={options}>
        {options.name}
        <span>{options.type === 1 ? 'Orçamento' : 'Projeto'}</span>
      </RenderTemplateTypeStyle>
    );
  };

  RenderTemplateType.propTypes = {
    options: PropTypes.instanceOf(Object)
  };

  return (
    <Modal
      formId="template"
      title={isCreate ? 'Dê um nome para seu template' : 'Adicionar a um template existente'}
      open
      onSubmit={!isCreate && createOrApplyTemplate}
      onClose={onClose}
    >
      {isCreate ? (
        <Form
          id="template"
          mapping={templateMapping({ isMobile: isMobile(), isModal: true, type: 1 })}
          schema={schema(false)}
          onSubmit={createOrApplyTemplate}
          data={{ type: 1 }}
          keepOldValues
          marginTop={isMobile() ? '0' : '-20px'}
          displayButtons={false}
        />
      ) : (
        <TemplateModal>
          <StyledModal>
            <Select style={{ width: '100%' }} placeholder="Selecione um template" onChange={id => setIdTemplate(id)}>
              {listTemplate.map(opt => (
                <Option value={opt.id} label={<RenderTemplateType options={opt} />}>
                  <RenderTemplateType options={opt} />
                </Option>
              ))}
            </Select>

            <Button text style={{ color: colors.primary40 }} onClick={() => setIsCreate(true)}>
              + Criar template
            </Button>
          </StyledModal>
        </TemplateModal>
      )}
    </Modal>
  );
};

AddToTemplateModal.propTypes = {
  isCreate: PropTypes.bool,
  createOrApplyTemplate: PropTypes.string,
  onClose: PropTypes.func,
  setIsCreate: PropTypes.func,
  listTemplate: PropTypes.instanceOf(Array),
  setIdTemplate: PropTypes.func
};

export default AddToTemplateModal;
