import styled, { css } from 'styled-components';
import { breakpoints, colors, fonts, spaces } from '../../styles/style';

export const StyledDiv = styled.div`
  padding: ${spaces.space0};
  border: 1px solid transparent;
  white-space: ${props => (props.noWrap ? 'nowrap' : null)};
  min-height: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${breakpoints.tablet}) {
    flex-basis: 100%;
  }

  svg {
    display: none;
  }

  &:hover {
    border: ${props => !props.disabled && `1px solid ${colors.neutral70}`};
    border-radius: ${spaces.space1};
    cursor: ${props => props.cursor || 'text'};

    svg {
      display: ${props => !props.disabled && 'flex'};
    }
  }
`;
export const EditContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: ${fonts.sizeSm};
  ${props =>
    props.onClick &&
    css`
      cursor: pointer;
    `}

  svg {
    margin-left: ${spaces.space1};
    cursor: pointer;
  }
  .renameItem {
    display: none;
  }
  &:hover {
    .renameItem {
      display: block;
    }
  }
`;
