import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import { Row, Text, Spacer } from '@homehero/hero-style';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Checkbox, Table as TableAnt } from 'antd';
import GroupHeader from '../Header/GroupHeader';
import DraggableRow from './DraggableRow';
import { FlexTable } from './Table.styled';
import CommonRow from './CommonRow';
import ParentBadget from './ParentBadget';
import ContentTable from './ContentTable';
import CenteredLoader from '../Loader/CenteredLoader';

const StyledCheckbox = styled(Checkbox)`
  left: 16px;
  top: 8px;
  position: absolute;
  z-index: 3;
`;

const Table = ({
  list = [],
  group,
  disabledByParent,
  margin = '2% 0',
  onSelectItem,
  selectedRowKeys,
  selectedItems = [],
  childErr = [],
  isCustomer,
  refetch,
  allowEditGroup,
  AddComponent,
  parentKey = 'idParent',
  addItemText,
  addSubItemText,
  onSort = () => {},
  columns,
  childrenColumns,
  childrenColumnName,
  moveRow,
  expandedRows,
  expandEmpty,
  lazyLoad,
  rowSelection,
  showChildrenHeader = false,
  rowChildrenSelection = true,
  paddingFirstColumn = 4,
  isExportToPdf,
  defaultExpandAllRows,
  defaultExpandAllGroups,
  stickyTop = 0,
  borderRadius = 8,
  onClick = f => f,
  border,
  disableDropOnParent,
  grouped,
  showTotal,
  isCustomSelectAll,
  onSelectAll = f => f,
  checkStrictly,
  noCheckBox = false,
  customBorder
}) => {
  const idReference = group && group.id ? group.id : null;
  const childrenColumnNameOrDefault = childrenColumnName || 'children';
  const [toggleTable, setToggle] = useState(!!(group && group.name && !defaultExpandAllGroups));
  const [expanded, setExpanded] = useState(defaultExpandAllRows ? null : expandedRows?.current || []);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [hasSelected, setHasSelected] = useState(false);

  const handleSorting = (pagination, filters, sorter) => {
    let sortValue = {};

    if (sorter?.order === 'ascend') {
      sortValue = { key: sorter.field, order: sorter?.column?.type === 'number' ? 'asc nulls first' : 'asc' };
    } else if (sorter?.order === 'descend') {
      sortValue = { key: sorter.field, order: sorter?.column?.type === 'number' ? 'desc nulls last' : 'desc' };
    }

    return onSort(sortValue);
  };

  const RenderError = ({ id }) => {
    const { error } = childErr.find(e => e.key === id) || {};
    return (
      <Spacer mtLg={1} mlLg={5} mtMd={1} mtSm={1} mtXs={1}>
        <Text danger> {error} </Text>
      </Spacer>
    );
  };

  RenderError.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  };

  useEffect(() => {
    if (!defaultExpandAllRows) {
      setExpanded(expandedRows?.current || []);
    }
  }, [expandedRows?.current]);

  useEffect(() => {
    if (!isCustomSelectAll) return;
    const objectIds = {};
    list.forEach(item =>
      item.instalments.forEach(i => {
        objectIds[i.id] = true;
      })
    );
    const equalKeys = {};
    selectedRowKeys.forEach(item => {
      if (objectIds[item]) {
        equalKeys[item] = true;
      }
    });
    if (Object.keys(equalKeys).length === Object.keys(objectIds).length && Object.keys(objectIds).length > 0) {
      setHasSelected(false);
      setIsSelectedAll(true);
    } else if (!Object.keys(equalKeys).length) {
      setHasSelected(false);
      setIsSelectedAll(false);
    } else {
      setHasSelected(true);
      setIsSelectedAll(false);
    }
  }, [selectedRowKeys, list]);

  const components = {
    body: {
      row: moveRow && list.length ? DraggableRow : CommonRow
    }
  };

  const _rowSelection = {
    checkStrictly,
    selectedRowKeys,
    onSelect: (record, selected) => {
      const _selectedRows = selected
        ? [...selectedItems, record]
        : selectedItems.filter(s => s.id !== record.id && (checkStrictly || s.id !== record?.idParent));

      onSelectItem(
        _selectedRows.map(s => s.id),
        _selectedRows
      );
    },
    onSelectAll: (selected, selectedRows, changedRows) => {
      const ids = changedRows.map(s => s.id);
      const _selectedRows = selected
        ? [...selectedItems, ...changedRows]
        : selectedItems.filter(s => !ids.includes(s.id));
      !isCustomSelectAll && onSelectAll(selected, group?.item);
      onSelectItem(
        _selectedRows.map(s => s.id),
        _selectedRows
      );
    }
  };

  const selectAllItems = e => {
    setIsSelectedAll(e.target.checked);
    setHasSelected(false);
    const allItems = list.flatMap(item => item?.instalments);
    onSelectAll(e.target.checked, group?.item);
    if (e.target.checked) {
      // const allKeys = allItems.map(item => item?.id);
      const allKeysObject = {};
      allItems.forEach(item => {
        allKeysObject[item.id] = item;
      });
      selectedItems.forEach(item => {
        allKeysObject[item.id] = item;
      });
      const keys = Object.keys(allKeysObject).map(item => Number(item));
      const values = Object.values(allKeysObject);
      onSelectItem(keys, values);
      return;
    }
    const allItemsObject = {};
    allItems.forEach(item => {
      allItemsObject[item.id] = true;
    });
    const _selectedRows = selectedItems.filter(s => !allItemsObject[s.id]);
    onSelectItem(
      _selectedRows.map(s => s.id),
      _selectedRows
    );
  };

  const rowParentSelection = {
    checkStrictly: false,
    selectedRowKeys,
    onSelect: (record, selected) => {
      let _selectedRows = [];
      if (!selected) {
        _selectedRows = selectedItems.filter(s => s.id !== record.id && s.id !== record?.idParent);
        record.children?.length &&
          record.children.forEach(r => {
            _selectedRows = _selectedRows.filter(s => s.id !== r.id && s.id !== r?.idParent);
          });
      } else {
        const childrenKeys = record.children?.map(item => item.id);
        if (childrenKeys) _selectedRows = selectedItems.filter(s => !childrenKeys.includes(s.id));
        else _selectedRows = selectedItems;

        _selectedRows = record.children?.length ? [..._selectedRows, ...record.children] : [..._selectedRows, record];
      }

      onSelectItem(
        _selectedRows.map(s => s.id),
        _selectedRows
      );
    },
    onSelectAll: (selected, selectedRows, changedRows) => {
      const ids = changedRows.map(s => s.id);
      const _selectedRows = selected
        ? [...selectedItems, ...changedRows]
        : selectedItems.filter(s => !ids.includes(s.id));
      !isCustomSelectAll && onSelectAll(selected, group?.item);
      onSelectItem(
        _selectedRows.map(s => s.id),
        _selectedRows
      );
    },
    columnWidth: '100%',
    renderCell: (checked, record, index, originNode) => (
      <>
        {record?.type === 3 ? <ParentBadget /> : null}
        {originNode}
      </>
    )
  };

  const expandedRowRender = (parent = {}) => {
    if (parent[childrenColumnNameOrDefault] === null) {
      return <CenteredLoader className="expansive" fontSize="sizeMd" />;
    }

    return (
      <DndProvider backend={HTML5Backend}>
        <TableAnt
          components={components}
          rowSelection={rowChildrenSelection ? _rowSelection : false}
          columns={childrenColumns || columns}
          dataSource={[...(parent[childrenColumnNameOrDefault] || [])]}
          pagination={false}
          showHeader={showChildrenHeader}
          size="small"
          rowKey={row => row.id}
          expandable={{
            expandIconColumnIndex: -1
          }}
          onRow={(record, index) =>
            moveRow
              ? {
                  index,
                  moveRow,
                  record,
                  parentKey,
                  childrenColumnName: childrenColumnNameOrDefault,
                  disableDropOnParent,
                  onClick: () => onClick(record)
                }
              : { record, index, childrenColumnName: childrenColumnNameOrDefault, onClick: () => onClick(record) }
          }
          footer={() =>
            AddComponent && addSubItemText && (!disabledByParent || !parent.isDisabled) ? (
              <Row style={{ padding: '8px 16px' }} key={`footer${parent.id}`}>
                <AddComponent parent={parent} createParams={{ [parentKey]: parent.id, idReference }}>
                  {addSubItemText}
                </AddComponent>
              </Row>
            ) : null
          }
        />
      </DndProvider>
    );
  };

  return (
    <>
      <ContentTable margin={margin}>
        {group && group.name && (
          <div role="presentation" onClick={() => setToggle(prev => !prev)}>
            <GroupHeader
              icon={toggleTable ? faCaretDown : faCaretUp}
              group={group}
              grouped={grouped}
              refetch={refetch}
              // eslint-disable-next-line no-restricted-globals
              allowEdit={!isNaN(group.id) && !isCustomer && allowEditGroup}
              showTotal={showTotal}
            />
          </div>
        )}
        {!toggleTable && (
          <div style={{ position: 'relative' }}>
            <DndProvider backend={HTML5Backend}>
              {isCustomSelectAll && (
                <StyledCheckbox
                  indeterminate={hasSelected}
                  checked={isSelectedAll}
                  onChange={e => {
                    selectAllItems(e);
                  }}
                />
              )}
              <FlexTable
                border={border}
                locale={{ emptyText: 'Nenhum item' }}
                components={components}
                columns={columns}
                dataSource={list}
                rowSelection={rowSelection && !isExportToPdf ? rowParentSelection : false}
                onRow={(record, index) =>
                  moveRow
                    ? {
                        index,
                        moveRow,
                        record,
                        isExpanded: expanded?.includes(record.id),
                        parentKey,
                        childrenColumnName: childrenColumnNameOrDefault,
                        disableDropOnParent,
                        onClick: () => onClick(record)
                      }
                    : {
                        record,
                        index,
                        isExpanded: expanded?.includes(record.id),
                        onClick: () => onClick(record)
                      }
                }
                onChange={handleSorting}
                pagination={false}
                size="small"
                rowKey={row => row.id}
                expandable={{
                  defaultExpandAllRows,
                  childrenColumnName,
                  indentSize: 0,
                  expandedRowRender,
                  rowExpandable: record =>
                    !expandEmpty
                      ? record[childrenColumnName || 'children']?.length
                      : record[childrenColumnName || 'children'] || lazyLoad,
                  expandedRowClassName: () => 'expanded-row',
                  expandRowByClick: true,
                  expandIconColumnIndex: -1,
                  expandedRowKeys: expanded,
                  onExpand: (isExpanded, { id }) => {
                    if (defaultExpandAllRows) return;

                    const keys = [...(isExpanded ? expanded.concat(id) : expanded.filter(k => k !== id))];
                    const _expanded = [...(isExpanded ? keys.concat(id) : keys.filter(k => k !== id))];
                    // eslint-disable-next-line no-param-reassign
                    expandedRows.current = _expanded;
                    setExpanded(_expanded);
                  }
                }}
                $paddingFirstColumn={paddingFirstColumn}
                $stickyTop={stickyTop}
                $borderRadius={borderRadius}
                $noCheckBox={noCheckBox}
                $customBorder={customBorder}
              />
            </DndProvider>
            {!isExportToPdf && AddComponent ? (
              <Row style={{ padding: '8px 16px' }}>
                <AddComponent text={addItemText} createParams={{ idReference }} />
              </Row>
            ) : null}
          </div>
        )}
      </ContentTable>
    </>
  );
};

Table.propTypes = {
  list: PropTypes.oneOfType([PropTypes.array]).isRequired,
  group: PropTypes.instanceOf(Object),
  disabledByParent: PropTypes.bool,
  margin: PropTypes.string,
  onSelectItem: PropTypes.func,
  selectedRowKeys: PropTypes.instanceOf(Array),
  selectedItems: PropTypes.instanceOf(Array),
  refetch: PropTypes.func,
  childErr: PropTypes.oneOfType([PropTypes.array]),
  isCustomer: PropTypes.bool,
  allowEditGroup: PropTypes.bool,
  AddComponent: PropTypes.instanceOf(Object),
  parentKey: PropTypes.string,
  addItemText: PropTypes.string,
  addSubItemText: PropTypes.string,
  onSort: PropTypes.func,
  columns: PropTypes.instanceOf(Array),
  moveRow: PropTypes.func,
  expandedRows: PropTypes.instanceOf(Object),
  expandEmpty: PropTypes.bool,
  lazyLoad: PropTypes.bool,
  childrenColumns: PropTypes.instanceOf(Array),
  childrenColumnName: PropTypes.string,
  rowSelection: PropTypes.bool,
  showChildrenHeader: PropTypes.bool,
  rowChildrenSelection: PropTypes.bool,
  paddingFirstColumn: PropTypes.number,
  isExportToPdf: PropTypes.bool,
  defaultExpandAllRows: PropTypes.bool,
  defaultExpandAllGroups: PropTypes.bool,
  stickyTop: PropTypes.number,
  borderRadius: PropTypes.number,
  onClick: PropTypes.func,
  border: PropTypes.bool,
  disableDropOnParent: PropTypes.bool,
  grouped: PropTypes.instanceOf(Object),
  showTotal: PropTypes.bool,
  isCustomSelectAll: PropTypes.bool,
  onSelectAll: PropTypes.func,
  checkStrictly: PropTypes.bool,
  noCheckBox: PropTypes.bool,
  customBorder: PropTypes.string
};

export default React.memo(Table, isEqual);
