import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Radio, Space } from 'antd';
import Text from 'antd/lib/typography/Text';
import { AboutContent, Content } from './Onboarding.styled';
import useViewport from '../../../_Hooks/useViewport';

const MainChallenges = ({ data, onChange }) => {
  const { isMobile } = useViewport(window.innerWidth);
  const { mainChallengesList } = useSelector(state => state.setup.systemData);

  const [_data, setData] = useState(data);

  const handleChange = val => {
    onChange(val);
    setData(val);
  };

  return (
    <Content>
      <AboutContent>
        <Space size={isMobile() ? 16 : 48} direction="vertical">
          <h5>Configurando a sua conta</h5>
          <Space size={16} direction="vertical">
            <Text>
              <b>Qual o principal desafio que podemos te ajudar a resolver?</b>
            </Text>
            <Radio.Group
              className="radio-group"
              value={_data?.onboarding?.mainChallenge}
              onChange={e => handleChange({ onboarding: { mainChallenge: e.target.value } })}
            >
              <Space size={16} direction="vertical">
                {mainChallengesList.map(s => (
                  <Radio className="radio-options" value={s.value} key={`challenge${s.value}`}>
                    {s.label}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Space>
        </Space>
      </AboutContent>
    </Content>
  );
};

MainChallenges.propTypes = {
  data: PropTypes.instanceOf(Object),
  onChange: PropTypes.func
};

export default MainChallenges;
