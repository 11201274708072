import React from 'react'
import { Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TooltipIcon = ({icon, text, tooltipColor, iconColor, tooltipProps, iconProps, onClick, trigger, style={}, ...props}) => {
  return (
    <Tooltip trigger={trigger} title={text} color={tooltipColor} {...tooltipProps}>
      <FontAwesomeIcon
        icon={icon}
        color={iconColor}
        onClick={onClick}
        style={{...style, cursor: !!onClick ? 'pointer' : 'auto'}}
        {...iconProps}
        {...props}
      />
    </Tooltip>
  )}

export default TooltipIcon
