import { Tabs as AntdTabs } from 'antd';
import styled, { css } from 'styled-components';
import { colors, fonts, breakpoints, spaces } from '../../styles/style';

const { TabPane } = AntdTabs;
const StyledTabs = styled(AntdTabs)`
  height: ${props => (!props.$fullHeight ? '100%' : null)};
  overflow: unset;

  .ant-tabs-content {
    height: ${props => (!props.$fullHeight ? '100%' : null)};
    .ant-tabs-tabpane {
      overflow: unset;
      @media (max-width: ${breakpoints.tablet}) {
        overflow: auto;
      }
    }
  }

  .ant-tabs-nav {
    background-color: ${props => props.background || '#ffffff'};
    padding: ${props => (props.$noPadding ? '0 16px' : '8px 16px 0 16px')};
    min-width: 100%;
    margin: 0;

    .ant-tabs-nav-wrap {
      display: flex;
      align-items: flex-start;
      align-self: flex-end;
      flex: ${props => (props.$fullWidth ? '0 0 100%' : 'auto')};
    }

    @media (max-width: ${breakpoints.tablet}) {
      min-height: ${spaces.space6};
      .ant-tabs-nav-list {
        justify-content: space-between;
      }
    }

    .ant-tabs-nav-list {
      width: ${props => (props.$fullWidth ? '100%' : 'auto')};
    }

    .ant-tabs-nav-operations {
      display: ${props => (props.$showMore ? 'flex' : 'none')};
    }

    @media (min-width: ${breakpoints.desktop}) {
      .ant-tabs-nav-wrap {
        max-width: ${props => (props.isProject ? '128px' : 'none')};
      }
    }
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 ${spaces.space1};
  }

  .ant-tabs-nav::before {
    border: 0;
  }

  .ant-tabs-tab {
    padding: 8px;
    opacity: 0.6;
    transition: all 0.3s linear;
    border: none;
    align-self: flex-end;

    ${props =>
      props.$fullWidth &&
      css`
        flex: 1;
        justify-content: center;
      `}

    :after {
      content: '';
      position: absolute;
      bottom: 1px;
      left: 0;
      right: 0;
      background-color: ${colors.primary40};
      height: 3px;
      border-top-left-radius: 200px;
      border-top-right-radius: 200px;
    }

    :hover {
      opacity: 1;
      color: ${colors.neutral40};
    }

    .ant-tabs-tab-btn {
      color: ${colors.neutral40};
      font-size: ${fonts.sizeSm};
      flex: ${props => (props.$fullWidth ? '0 0 100%' : 'auto')};
    }
  }
  .ant-tabs-tab-active {
    opacity: 1;
  }
  .ant-tabs-ink-bar {
    display: none;
  }
  .ant-tabs-extra-content {
    flex: auto;
    min-height: ${spaces.space5};
  }
`;

export { StyledTabs, TabPane };
