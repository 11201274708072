import React from 'react';
import PropTypes from 'prop-types';

const PdfFile = ({ width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 65 77">
      <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="05-05" transform="translate(-490.000000, -368.000000)" fill="#FF6D4A">
          <g id="Group-11" transform="translate(491.000000, 369.000000)">
            <path
              d="M5.72727273,3.26086957 C4.82563636,3.26086957 4.09090909,3.99130435 4.09090909,4.89130435 L4.09090909,34.2391304 L0.818181818,34.2391304 L0.818181818,4.89130435 C0.818181818,2.19456522 3.02072727,0 5.72727273,0 L41.7272727,0 C42.1609091,0 42.5781818,0.171195652 42.8841818,0.477717391 L62.5205455,20.0429348 C62.8281818,20.3478261 63,20.7619565 63,21.1956522 L63,70.1086957 C63,72.8054348 60.7974545,75 58.0909091,75 L5.72727273,75 C3.02072727,75 0.818181818,72.8054348 0.818181818,70.1086957 L0.818181818,55.8043478 L4.09090909,55.8043478 L4.09090909,70.1086957 C4.09090909,71.0086957 4.82563636,71.7391304 5.72727273,71.7391304 L58.0909091,71.7391304 C58.9925455,71.7391304 59.7272727,71.0086957 59.7272727,70.1086957 L59.7271818,22.826 L41.7272727,22.826087 C40.824,22.826087 40.0909091,22.0972826 40.0909091,21.1956522 L40.0909091,4.16254918 C40.0909091,3.66456528 40.4922097,3.26086957 40.9851962,3.26086957 L5.72727273,3.26086957 Z M57.4141818,19.565 L43.3631818,5.566 L43.3636364,19.5652174 L57.4141818,19.565 Z"
              id="Combined-Shape"
              stroke="#FFFFFF"
              strokeWidth="1.8"
              opacity="0.800000012"
            />
            <path
              d="M50.5555556,32.6086957 C51.3528889,32.6086957 52,33.3375 52,34.2391304 L52,55.4347826 C52,56.336413 51.3528889,57.0652174 50.5555556,57.0652174 L1.44444444,57.0652174 C0.647111111,57.0652174 0,56.336413 0,55.4347826 L0,34.2391304 C0,33.3375 0.647111111,32.6086957 1.44444444,32.6086957 L50.5555556,32.6086957 Z"
              id="base"
              stroke="#FFFFFF"
              strokeWidth="1.8"
              fillOpacity="0.1"
            />
            <text id=".pdf" fontFamily="Muli-Regular, Muli" fontSize="8" fontWeight="normal" letterSpacing="0.07272728">
              <tspan x="15" y="48">
                .PDF
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  );
};

PdfFile.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
};

PdfFile.defaultProps = {
  width: 65,
  height: 77
};

export default PdfFile;
