import styled from 'styled-components';
import { colors, fonts, spaces } from '../../styles/style';
import Modal from './Modal';

export const StyledModal = styled(Modal)`
  .ant-modal-footer {
    background-color: #ffff !important;
  }
  .ant-modal-header {
    border-bottom: 1px solid black;
  }
`;

export const CustomTitle = styled.span`
  font-weight: 500;
  font-size: ${fonts.sizeMd};
  color: ${colors.neutral40};
  margin-left: ${spaces.space2};
`;

export const SubTitle = styled.p`
  color: ${colors.neutralSecondary40};
  font-weight: 500;
  font-size: ${fonts.sizeMd};
  line-height: 21px;
  margin-bottom: ${spaces.space3};
`;

export const ShareText = styled.p`
  color: ${colors.neutralSecondary60};
  font-weight: 500;
  font-size: ${fonts.sizeSm};
  line-height: 15px;
`;

export const EnableShareContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${spaces.space4};
  p {
    font-size: ${fonts.sizeSm};
    line-height: 18px;
    margin-bottom: ${spaces.space1};
  }

  .enableShareText {
    margin-bottom: ${spaces.space1};
    display: flex;
    align-items: center;
    span {
      color: #000;
      margin-left: ${spaces.space2};
      font-size: ${fonts.sizeSm};
    }
  }
`;
