import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Select, Tooltip } from 'antd';
import { Option } from 'antd/lib/mentions';
import styled from 'styled-components';
import useCRUD from '../../_Hooks/useCRUD';
import { colors, spaces } from '../../styles/style';
import useViewport from '../../_Hooks/useViewport';
import { removeAccents } from '../../lib/helpers/helper';

const LinkedTaskSelection = ({
  id,
  idReference,
  setLinkedTask = f => f,
  linkedTask,
  setLinkedTaskName = f => f,
  isTemplate
}) => {
  const { isMobile } = useViewport(window.innerWidth);
  const [task, setTask] = useState(null);
  const [tasksList, setTasksList] = useState([]);
  const [offset, setOffset] = useState();
  const [searchText, setSearchText] = useState();
  const { list, totalItems, handleGet } = useCRUD({
    model: 'task',
    options: {
      include: [
        'descendants',
        {
          model: 'task',
          as: 'parent',
          include: ['step']
        }
      ]
    },
    immediatelyLoadData: false
  });

  const modelHelper = isTemplate
    ? {
        modelText: 'templateSteps',
        singularModelText: 'templateStep',
        where: { idTemplate: idReference }
      }
    : {
        modelText: 'refurbishSteps',
        singularModelText: 'refurbishStep',
        where: { idRefurbish: idReference }
      };

  useEffect(() => {
    handleGet({ refetchPathOptions: `/${id}` }).then(data => {
      setTask(data);
      setOffset(1);
      setLinkedTask(data?.idParent);
    });
  }, []);

  useEffect(() => {
    if (task && offset) {
      const descendantsId = task?.descendants?.map(d => d.id);

      handleGet({
        refetchOptions: {
          order: [['step', modelHelper.modelText, 'order', 'ASC NULLS FIRST'], ['idStep'], ['order']],
          include: [
            {
              model: 'step',
              include: [
                { model: modelHelper.singularModelText, where: modelHelper.where, separate: true, required: false }
              ],
              separate: true
            }
          ],
          where: {
            id: { ne: id },
            ...(descendantsId?.length && { id: { notIn: [...descendantsId, id] } }),
            ...modelHelper.where,
            ...(searchText && {
              ulike: {
                ...modelHelper.where?.ulike,
                'task.name': `%${removeAccents(searchText)}%`
              }
            })
          },
          offset,
          limit: 10
        }
      });
    }
  }, [offset, task, searchText]);

  useEffect(() => {
    const _parent = task?.parent ? [task.parent] : [];
    const _list = list.filter(p => p.id !== task?.parent?.id);
    setTasksList(prev => (offset > 1 ? [...prev, ..._list] : [..._parent, ..._list]));
  }, [list]);

  const RenderLinkedTypeStyle = styled.div`
    display: flex;
    height: 30px;
    align-items: center;
    .step {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      margin-left: ${spaces.space2};
      padding: 0px ${spaces.space1};
      border-radius: 4px;
      color: white;
      background: ${props => props.color};
    }
  `;

  const maxLength = isMobile() ? 30 : 45;

  const RenderLinkedType = ({ options }) => {
    const referenceModelStepName = options?.step?.name || 'Geral';
    const taskName = isMobile() ? `${options.name.substr(0, maxLength - 1)}...` : options.name;
    return (
      <RenderLinkedTypeStyle color={options?.step?.[modelHelper.modelText]?.[0]?.color || colors.neutralSecondary40}>
        {options.name.length >= maxLength && !isMobile() ? (
          <Tooltip title={`${options.name} - ${referenceModelStepName}`} color={colors.secondary60}>
            {`${options.name.substr(0, maxLength - 1)}...`}
          </Tooltip>
        ) : (
          taskName
        )}

        {options.value !== 'remove' ? <div className="step">{referenceModelStepName}</div> : null}
      </RenderLinkedTypeStyle>
    );
  };

  RenderLinkedType.propTypes = {
    options: PropTypes.instanceOf(Object)
  };

  const memoList = useMemo(() => tasksList, [tasksList]);

  const onPopupScroll = e => {
    e.persist();
    const { target } = e;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight && totalItems > tasksList?.length) {
      setOffset(prev => prev + 1);
    }
  };

  let timer;
  const onSearchTextChange = text => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setSearchText(text);
      clearTimeout(timer);
    }, 800);
  };

  return (
    <Select
      value={linkedTask}
      style={{ display: 'flex', flex: 1 }}
      onSelect={(value, option) => {
        setLinkedTaskName(option?.title);
        setLinkedTask(option?.value);
      }}
      showSearch
      allowClear
      optionFilterProp="title"
      filterOption={(input, option) => option?.title?.toLowerCase()?.includes(input?.toLowerCase())}
      onClear={() => {
        setLinkedTaskName(null);
        setLinkedTask(null);
      }}
      onSearch={input => onSearchTextChange(input)}
      onPopupScroll={onPopupScroll}
    >
      {memoList.length &&
        memoList.map(opt => (
          <Option title={opt?.name} key={opt?.id} value={opt?.id}>
            <RenderLinkedType options={opt} />
          </Option>
        ))}
    </Select>
  );
};

LinkedTaskSelection.propTypes = {
  color: PropTypes.string,
  id: PropTypes.number,
  idReference: PropTypes.number,
  setLinkedTask: PropTypes.func,
  linkedTask: PropTypes.number,
  setLinkedTaskName: PropTypes.func,
  isTemplate: PropTypes.bool
};

export default LinkedTaskSelection;
