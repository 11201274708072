import styled, { css } from 'styled-components';
import { breakpoints, colors, spaces } from '../../styles/style';
import Button from '../Button/Button';

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${spaces.space4};

  @media (max-width: ${breakpoints.tablet}) {
    font-size: 22px;
    width: ${spaces.space6};
    margin: ${spaces.space0};
  }
`;

const StyledImg = styled.img`
  height: ${props => props.size};
  width: ${props => props.size};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  border-radius: 4px;
  object-fit: cover;

  @media (max-width: ${breakpoints.tablet}) {
    height: ${props => props.size || spaces.space5};
    width: ${props => props.size || spaces.space5};
    margin: ${spaces.space0};
  }
`;

const StyleLabel = styled.label`
  display: flex;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  border: ${props => (props.disabled ? `1px dashed ${colors.neutral60}` : `1px dashed ${colors.primary40}`)};
  color: ${props => (props.disabled ? colors.neutral60 : colors.primary40)};
  height: ${props => props.size};
  width: ${props => props.size};
  align-self: center;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  @media (max-width: ${breakpoints.tablet}) {
    height: ${spaces.space5};
    width: ${spaces.space5};
    margin: ${spaces.space1};
  }
`;

const ArrowButton = styled(Button)`
  border: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${props =>
    css`
      ${props.direction}: 12px
    `}
`;

export { FlexDiv, StyleLabel, StyledImg, ArrowButton };
