import { useState, useEffect } from 'react';
import logdown from 'logdown';

const logger = logdown('FORM');

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (value) {
      logger.info('=>', value);
    }
  }, [value]);

  const handleChange = event => {
    const { target } = event || {};
    const newValue = target?.type === 'checkbox' ? target?.checked : target?.value;
    if (newValue) return setValue(newValue);
    if (event && !target) return setValue(event);
    return setValue('');
  };

  return {
    value,
    onChange: handleChange
  };
};

export default useFormInput;
