import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './Gallery.module.scss';

// components
import GalleryItem from './GalleryItem';
import PreviewModal from '../Modal/PreviewModal';
import { getFileName, getFileType } from '../../lib/helpers/helper';

const Gallery = ({ images, onRemove, noPreview, noDownload, small, unique, children, itemClassname }) => {
  const [selected, setSelected] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleSelectImage = (currentImage, index) => {
    setSelected(currentImage);
    setSelectedIndex(index);
    setShowModal(!showModal);
  };

  const handleRemove = () => {
    onRemove(selectedIndex);
    setShowModal(!showModal);
  };

  const classes = classNames({
    [css['c-gallery']]: true,
    [css['c-gallery--small']]: small,
    [css['c-gallery--unique']]: unique
  });

  const directionFileFunc = direction => {
    const indexFile = images.indexOf(selected);
    const endIndexFile = images.length - 1;

    if (direction) {
      setSelected(indexFile + 1 > endIndexFile ? images[0] : images[indexFile + 1]);
    } else {
      setSelected(indexFile - 1 < 0 ? images[endIndexFile] : images[indexFile - 1]);
    }
  };

  return (
    <>
      <div style={{ display: 'flex', flexWrap: 'wrap' }} className={classes}>
        {images?.map((item, index) => (
          <>
            <GalleryItem
              image={item}
              index={index}
              key={`${index + 1}-${item.filename}`}
              onRemove={onRemove}
              onSelectImage={handleSelectImage}
              noPreview={noPreview}
              noDownload={noDownload}
              small={small}
              unique={unique}
              className={itemClassname}
            />
          </>
        ))}
        {unique && images[0]?.status === 'done' ? null : children}
      </div>
      {showModal ? (
        <PreviewModal
          showComments={false}
          fileType={getFileType(selected.filename || getFileName(selected.fullpath))}
          file={selected}
          onToggleModal={setShowModal}
          onRemove={handleRemove}
          directionFile={directionFileFunc}
        />
      ) : null}
    </>
  );
};

Gallery.propTypes = {
  images: PropTypes.instanceOf(Array),
  onRemove: PropTypes.func,
  noPreview: PropTypes.bool,
  noDownload: PropTypes.bool,
  small: PropTypes.bool,
  unique: PropTypes.bool,
  children: PropTypes.instanceOf(Object),
  itemClassname: PropTypes.string
};

export default Gallery;
