import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router';
import * as dayjs from 'dayjs';
import useCRUD from '../_Hooks/useCRUD';
import { updatePermissions, updateCompany } from '../store/auth/actions/action';

import SelectRefurbish from '../components/Modal/RefurbishListModal';

import css from './Main.module.scss';
import CenteredLoader from '../components/Loader/CenteredLoader';
import { saveRefurbish } from '../store/refurbish/actions/action';
import flowSteps from './Onboard/ProviderSteps/steps';

const stepKeys = Object.keys(flowSteps);
const firstStep = stepKeys[0];

const Main = ({ toggle, noShowCollapse, redirectUrl, children }) => {
  const classes = classNames({
    [css['c-main']]: true,
    [css['c-main--collapse']]: toggle && !noShowCollapse
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const { location } = history;
  const { user } = useSelector(state => state.authReducer);
  const refurbish = useSelector(state => state.refurbishReducer);
  const { companyStatus, userType, planType } = useSelector(state => state.setup.enums);
  const isProvider = user.userType === userType.provider.value;
  const isCustomer = user.userType === userType.customer.value;

  const options =
    isProvider || user.isCustomerView
      ? { include: [{ model: 'company', include: ['permissions'], required: false }, 'permissions'] }
      : {
          include: [
            {
              model: 'refurbish',
              where: { isShared: true },
              required: false,
              include: [{ model: 'user', where: { userType: userType.provider.value }, required: false }]
            }
          ]
        };
  const pathOptions = `/${user.id}`;
  const { data: userData, handleGet } = useCRUD({
    model: 'user',
    options,
    pathOptions,
    immediatelyLoadData: false
  });
  const { handleUpdate } = useCRUD({
    model: 'company',
    immediatelyLoadData: false
  });

  const { refurbishes = [], company = {} } = userData || {};

  useEffect(() => {
    if (user?.anonymous) return;

    handleGet().then(res => {
      if (!res || res?.error) history.push(`/logout`);

      const { permissions, company: newCompany, refurbishes: resRefurbishes } = res || {};

      if (isProvider || user.isCustomerView) {
        const permissionMap = permissions?.reduce((acc, curr) => ({ ...acc, [curr.id]: true }), {});
        if (user?.permissions?.length !== permissions?.length || !user?.permissions?.every(p => permissionMap[p.id])) {
          dispatch(updatePermissions(permissions));
        }

        if (newCompany) {
          dispatch(updateCompany(newCompany));
        }
      }

      if (!user.isCustomerView && isCustomer) {
        const selectedRefurbish = resRefurbishes.find(r => r.id === refurbish?.id);

        if (JSON.stringify(selectedRefurbish?.itemsToShare || {}) !== JSON.stringify(refurbish?.itemsToShare || {})) {
          dispatch(saveRefurbish(selectedRefurbish || {}));
        }
      }
    });
  }, []);

  useEffect(() => {
    if (company?.endAccess) {
      if (dayjs(company.endAccess).isBefore(dayjs(new Date(new Date().setHours(0, 0, 0, 0))))) {
        handleUpdate({
          values: { idPlan: planType.free.value, endAccess: null },
          updatePathOptions: `/${company.id}/change-plan`,
          refresh: false
        }).then(() => {
          history.push(`/logout`);
        });
      }
    }
  }, [location]);

  if (redirectUrl) {
    history.push(redirectUrl);
    return null;
  }
  if (!userData && !user?.anonymous) return <CenteredLoader />;

  if (isCustomer && userData && !refurbishes?.length && !user.isCustomerView) {
    toast.error('Nenhuma reforma compartilhada com você. Contate seu arquiteto', {
      autoClose: '5000'
    });
    return <Redirect to="/logout" />;
  }

  if (isProvider) {
    if (!company) {
      return <Redirect to="/logout" />;
    }

    if (company?.idStatus === companyStatus?.onBoarding?.id) {
      return <Redirect to="/informacoes-adicionais" />;
    }

    if (company && !company?.onboarding?.finished) {
      return <Redirect to={`/onboarding/${firstStep}`} />;
    }

    if (company?.idStatus !== companyStatus?.active.id) {
      toast.error('Sua conta está bloqueada, entre em contato conosco para resolvermos a situação.', {
        autoClose: '5000'
      });
      return <Redirect to="/logout" />;
    }
  }

  return (
    <>
      {!isCustomer || refurbish?.id ? null : <SelectRefurbish open />}
      <div className={classes}>{children}</div>
    </>
  );
};

Main.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
  noShowCollapse: PropTypes.bool,
  toggle: PropTypes.bool.isRequired,
  redirectUrl: PropTypes.string
};

export default Main;
