import { Col } from 'antd';
import styled from 'styled-components';
import { breakpoints, colors, spaces } from '../../styles/style';
import Select from '../Select/Select';

export const TypeCol = styled(Col)`
  display: flex;
  align-items: center;

  .c-attachment--action {
    align-self: center;
    padding-left: ${spaces.space1};

    svg {
      color: ${colors.error50};
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    margin-top: ${spaces.space1};
  }
`;

export const StyledSelect = styled(Select)`
  height: 32px;

  .ant-select-selector {
    height: 100% !important;
  }
`;
