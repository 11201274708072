import styled, { css } from 'styled-components';
import { Table as TableAnt } from 'antd';
import { colors } from '../../styles/style';

const FlexTable = styled(TableAnt)`
  border-radius: ${props => (props.$customBorder ? props.$customBorder : '8px')};
  padding-bottom: 8px;
  border: ${props => (props.border ? `1px solid ${colors.neutral90}` : null)};

  .ant-table-thead {
    position: ${props => (props.$stickyTop === null ? 'relative' : 'sticky')};
    top: ${props => props.$stickyTop}px;
    z-index: 2;
  }
  .ant-table.ant-table-small {
    font-size: 12px;
    border-radius: 8px;

    .ant-table-thead > tr > th {
      background: ${colors.primary99};
      padding: 8px 4px;
      border-top: 1px solid #f0f0f0;

      &:first-of-type {
        padding: 8px ${props => props.$paddingFirstColumn}px;
      }

      .ant-table-column-sorters {
        padding: 0 4px;
      }

      &.image {
        min-width: 45px;
        page-break-inside: avoid;
      }

      &:first-child {
        border-top-left-radius: ${props => props.$borderRadius}px;
      }

      &:last-child {
        border-top-right-radius: ${props => props.$borderRadius}px;
      }
    }

    .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
      margin: 0;
    }
  }

  .ant-table-column-sorter {
    margin-left: 2px;
    padding-top: 0;
  }

  .ant-table-thead tr {
    display: flex;
  }
  .ant-table-thead th {
    color: ${colors.neutralSecondary40};
    flex: 0;
    min-width: 40px;
    z-index: 1;

    &.actions {
      z-index: 0;
      min-width: 45px;
      display: flex;
      justify-content: flex-end;
    }

    &.width-10 {
      min-width: 10px;
    }
    &.width-50 {
      min-width: 50px;
    }
    &.width-70 {
      min-width: 70px;
    }
    &.width-90 {
      min-width: 90px;
    }
    &.width-110 {
      min-width: 110px;
    }

    &.col-grow-1 {
      flex: 1;
    }
    &.col-grow-2 {
      flex: 2;
    }
    &.col-grow-3 {
      flex: 3;
    }
    &.col-grow-4 {
      flex: 4;
    }
    &.col-grow-5 {
      flex: 5;
    }
  }
  .ant-table-tbody {
    display: block;
  }

  .ant-table.ant-table-empty {
    .ant-table-tbody {
      display: contents;
    }
  }

  .ant-table-tbody > tr.ant-table-expanded-row {
    display: grid;
  }

  .ant-table-tbody > tr.ant-table-row,
  .ant-table-tbody > tr.ant-table-row-selected {
    > td {
      border: 0;
      background: white;
      padding: 0 4px;
      transition: 0s;

      &.image {
        display: flex;
        justify-content: center;
        min-width: 45px;
        height: 50px;
        align-items: center;
      }

      &:first-of-type {
        padding: 0 ${props => props.$paddingFirstColumn}px;
      }
    }
    &:hover > td {
      background: ${colors.primary95};
    }
  }

  .ant-table-tbody > tr.ant-table-row-selected {
    background: ${colors.primary95};
    td {
      background: ${colors.primary95};
    }
  }

  .ant-table-row {
    display: flex;
    overflow: auto;
    border: 0;
    position: relative;
    &:hover {
      background: ${colors.primary95};

      .action {
        height: inherit;
      }
    }
  }
  .ant-table-row td {
    color: ${colors.neutral40};
    flex: 0;
    overflow: auto;
    align-self: center;
    min-width: ${props => (props.$noCheckBox ? 16 : 40)}px;

    &.actions {
      min-width: 45px;
      display: flex;
      justify-content: flex-end;
    }

    &.width-50 {
      min-width: 50px;
    }
    &.width-70 {
      min-width: 70px;
    }
    &.width-90 {
      min-width: 90px;
    }
    &.width-110 {
      min-width: 110px;
    }

    &.col-grow-1 {
      flex: 1;
    }
    &.col-grow-2 {
      flex: 2;
    }
    &.col-grow-3 {
      flex: 3;
    }
    &.col-grow-4 {
      flex: 4;
    }
    &.col-grow-5 {
      flex: 5;
    }
    &.padding-left-12 {
      padding-left: 12px !important;
    }
  }

  .ant-table-row ::-webkit-scrollbar {
    display: none;
  }

  .ant-table-row-level-1 {
    display: none;
  }

  .ant-table-column-sorter-up,
  .ant-table-column-sorter-down {
    font-size: 8px;
  }

  .ant-table-selection-column {
    position: initial;
  }

  .ant-table-footer {
    background: white;
  }

  .expanded-row {
    > td {
      padding: 0 !important;
    }

    .ant-table-wrapper {
      border-left: 2px solid ${colors.primary40};
      background: white;
      padding-left: 10px;
    }
  }

  .expanded-parent {
    border-left: 2px solid ${colors.primary40};
    background: white;
  }

  .ant-empty-image {
    display: none;
  }

  .display {
    padding-left: 6px;
  }

  ${props =>
    props.$noFooter &&
    css`
      .ant-table-placeholder {
        td {
          border-bottom: none;
        }
      }
    `}
`;

const SimpleTable = styled(TableAnt)`
  border-radius: 8px;

  .ant-table.ant-table-small {
    font-size: 12px;
    border-radius: 8px;
    padding: 4px;

    .ant-table-thead > tr > th {
      background: white;
      padding: 8px 4px;

      .ant-table-column-sorters {
        padding: 0 4px;
      }

      &:first-child {
        border-top-left-radius: 8px;
      }

      &:last-child {
        border-top-right-radius: 8px;
      }
    }
  }

  .ant-table-column-sorter {
    margin-left: 2px;
    padding-top: 0;
  }

  .ant-table-thead th {
    color: ${colors.neutralSecondary40};
  }

  .ant-table-row td {
    color: ${colors.neutral40};
    border: 0;
    vertical-align: top;
  }

  .ant-table-column-sorter-up,
  .ant-table-column-sorter-down {
    font-size: 8px;
  }

  .ant-table-footer {
    background: white;
  }
`;

export { FlexTable, SimpleTable };
