import { Checkbox } from 'antd';
import styled, { css } from 'styled-components';
import { breakpoints, colors, fonts, spaces } from '../../styles/style';
import Input from '../Input/Input';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: ${spaces.space4};
`;

const SectionTitle = styled.strong`
  font-size: ${spaces.space2};
  font-weight: 500;
  line-height: ${spaces.space3};
`;

const Configuration = styled.div`
  display: flex;
  margin-bottom: ${spaces.space2};

  > div {
    display: flex;
    flex-direction: column;
    margin-right: ${spaces.space4};

    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      margin-bottom: ${spaces.space1};
    }
  }
  @media (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${colors.neutral90};
  margin: ${spaces.space4} 0;
`;

const PaymentTypesContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: ${spaces.space4};
  @media (max-width: ${breakpoints.tablet}) {
    width: 100%;
  }
`;

const ContainerCheckboxes = styled(Checkbox.Group)`
  display: inline-block;
  @media (max-width: ${breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
  }
`;

const InfoLine = styled.div`
  display: flex;
  gap: ${spaces.space2};
`;

const StyledInput = styled(Input)`
  width: ${spaces.space6};
`;

const Text = styled.p`
  font-size: ${fonts.sizeSm};
  color: ${props => (!props.$color ? colors.neutral50 : props.$color)};
  ${props =>
    props.$withMarginBottom &&
    css`
      margin-bottom: ${spaces.space1};
    `}
`;

const Line = styled.div`
  width: 100%;
  border-top: 1px solid ${colors.neutralSecondary95};
`;

const TableContainer = styled.div`
  border: 1px solid ${colors.neutral90};
  border-radius: ${spaces.space1};
  .tableHeader {
    background-color: ${colors.primary99};
    border-bottom: 1px solid ${colors.neutral90};
    border-radius: ${spaces.space1} ${spaces.space1} 0 0;
    padding: ${spaces.space1} ${spaces.space2} 0px ${spaces.space2};
  }
  .emptyState {
    color: ${colors.neutral60};
    margin: ${spaces.space2} ${spaces.space2} ${spaces.space2} ${spaces.space2};
  }
  .statusCol {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .tableRow {
    padding-left: ${spaces.space2};
    padding-right: ${spaces.space2};
    margin-bottom: ${spaces.space2};
  }
`;

const ContentRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding-top: ${spaces.space2};
`;

export {
  Container,
  SectionTitle,
  Configuration,
  Divider,
  PaymentTypesContainer,
  ContainerCheckboxes,
  InfoLine,
  StyledInput,
  Text,
  Line,
  TableContainer,
  ContentRow
};
