import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-duotone-svg-icons';
import { hasPermission } from '../../routes/Common/PrivateRoute';
import BubbleModalButton from '../Button/BubbleModalButton';
import { colors } from '../../styles/style';

const CollapseItem = ({ to, title, roles, external, ...props }) => {
  const { user } = useSelector(state => state.authReducer);
  const { plans, permissions } = useSelector(state => state.setup);

  const _hasPermission = hasPermission(user, roles, plans, permissions);

  if (_hasPermission === false) {
    return null;
  }

  return _hasPermission ? (
    <li {...props}>
      <Link to={external ? { pathname: to } : to} target={external ? '_blank' : ''}>
        {title}
      </Link>
    </li>
  ) : (
    <li {...props} data-gtm-id={null} style={{ padding: '8px 24px', marginLeft: '36px' }}>
      <BubbleModalButton feature={roles[0]}>
        <div>
          {title} <FontAwesomeIcon icon={faLock} color={colors.neutral80} style={{ marginLeft: '112px' }} />
        </div>
      </BubbleModalButton>
    </li>
  );
};

CollapseItem.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  roles: PropTypes.instanceOf(Array),
  external: PropTypes.bool
};

export default CollapseItem;
