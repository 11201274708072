import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Dropdown, Tooltip } from 'antd';
import { faBell } from '@fortawesome/pro-duotone-svg-icons';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import NotificationDropdown from '../Dropdown/NotificationDropdown';
import { connect, onMessage } from './NotificationWebsockets';
import { useContextHook as useNotification } from '../../contexts/Context';
import useCRUD from '../../_Hooks/useCRUD';
import useViewport from '../../_Hooks/useViewport';
import { spaces } from '../../styles/style';

const userTypes = ['cliente', 'profissional', 'operador'];

const Notification = ({ size = '1x', notificationUniqueKey }) => {
  const { isMobile } = useViewport(window.innerWidth);
  const [unreadIds, setUnreadIds] = useState([]);
  const { user } = useSelector(state => state.authReducer);
  const { userType } = useSelector(state => state.setup.enums);
  const { shouldUpdate, handleGet: handleGetNotificationAll } = useNotification();

  const params = useParams();
  const { rootPage } = params;
  const userTypeForRedirect = userTypes.includes(rootPage) ? `/${rootPage}` : '';

  const { handleGet, handleUpdate } = useCRUD({
    model: 'activity',
    immediatelyLoadData: false,
    showToast: false,
    options: {
      where: { idConsumer: user.id, hasSeen: false },
      include: [
        {
          model: 'user',
          as: 'producer'
        }
      ],
      limit: 5,
      order: [
        ['id', 'DESC'],
        ['createdAt', 'DESC']
      ]
    }
  });
  const unreadOptions = {
    where: { idConsumer: user.id, hasSeen: false },
    order: [
      ['id', 'DESC'],
      ['createdAt', 'DESC']
    ]
  };

  const [activities, setActivities] = useState();
  const [idsToRead, setIdsToRead] = useState([]);
  const [unread, setUnread] = useState(0);

  if (!user?.anonymous) {
    connect();
    onMessage(message => {
      const { data = '{}' } = message || {};
      const _activity = { ...JSON.parse(data), refreshComponent: true };

      const { removeActivity } = _activity;

      handleGetNotificationAll({ displayToast: false }).then();
      if (removeActivity) {
        shouldUpdate.current = true;
        setUnread(prevState => {
          return prevState > 0 ? prevState - 1 : 0;
        });
        return;
      }
      setActivities(prevActivities => {
        const _prevActivities = prevActivities.length >= 5 ? prevActivities.slice(0, -1) : prevActivities;
        return [_activity, ..._prevActivities];
      });
      setIdsToRead(prevIds => {
        const _prevIds = prevIds.length >= 5 ? prevIds.slice(0, -1) : prevIds;
        return [_activity.id, ..._prevIds];
      });
      setUnread(prevUnread => {
        return prevUnread + 1;
      });
    }, notificationUniqueKey);
  }

  useEffect(() => {
    if (user?.anonymous || user?.userType === userType?.operator?.value) return;

    const fetchUnreadNotifications = async () => {
      if (shouldUpdate.current !== null && !shouldUpdate.current) return;
      const [_activity, totalUnread] = await Promise.all([handleGet(), handleGet({ refetchOptions: unreadOptions })]);
      if (_activity?.error) return;
      const ids = _activity?.map(({ id, hasSeen }) => (!hasSeen ? id : null)).filter(e => e);
      setActivities(_activity || []);
      setIdsToRead(ids || []);
      setUnreadIds(totalUnread?.map(u => u.id));
      setUnread(totalUnread?.length || 0);
      shouldUpdate.current = false;
    };
    fetchUnreadNotifications();
  }, [shouldUpdate.current]);

  const markRead = id => {
    const _id = id ? { id } : {};
    if (!idsToRead?.length) return false;

    return handleUpdate({
      values: { hasSeen: true },
      updateOptions: { where: { idUser: user.id, ..._id } }
    }).then(() => {
      handleGet().then(allActivities => {
        const updates = id.length;
        setActivities(allActivities.slice(0, 5));
        setIdsToRead(allActivities.map(act => act.id));
        setUnread(prevUnread => {
          return id && prevUnread - updates > 0 ? prevUnread - updates : 0;
        });
      });
    });
  };
  const updateIdsToRead = visible => {
    !visible && !!idsToRead.length && markRead(idsToRead);
  };

  return (
    <>
      {isMobile() ? (
        <>
          <Link onClick={isMobile() ? () => markRead(unreadIds) : null} to={`${userTypeForRedirect}/notificacoes`}>
            <Badge count={unread} size="small">
              <FontAwesomeIcon icon={faBell} style={{ width: '21px', height: '18px' }} />
            </Badge>
          </Link>
        </>
      ) : (
        <Dropdown
          overlay={
            <NotificationDropdown
              userType={user.type}
              markRead={markRead}
              items={activities}
              clearAll={() => markRead(unreadIds)}
            />
          }
          trigger={['click']}
          onVisibleChange={updateIdsToRead}
        >
          <div
            style={{
              height: spaces.space4,
              width: spaces.space4,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer'
            }}
          >
            <Badge count={unread} size="small">
              <Tooltip placement="bottom" title="Notificações">
                <FontAwesomeIcon icon={faBell} size={size} />
              </Tooltip>
            </Badge>
          </div>
        </Dropdown>
      )}
    </>
  );
};

Notification.propTypes = {
  size: PropTypes.string,
  notificationUniqueKey: PropTypes.string
};

export default Notification;
