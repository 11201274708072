import React, { memo } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { faBars, faCircleQuestion } from '@fortawesome/pro-solid-svg-icons';
import { Text } from '@homehero/hero-style';
import { capitalize } from '../../lib/helpers/helper';
import { useContextHook } from '../../contexts/GeneralContext';

import Notification from '../Notification/Notification';

import css from './MobileMainHeader.module.scss';
import { colors } from '../../styles/style';

const MobileMainHeader = ({ title = '', user }) => {
  const { handleOpenCloseNavBar } = useContextHook();

  return (
    <div className={css['c-mobile-header']}>
      <button type="button" className={css['c-btn']} onClick={handleOpenCloseNavBar}>
        <FontAwesomeIcon style={{ width: '16px', height: '18px' }} icon={faBars} />
      </button>
      <Text>{capitalize(title)}</Text>
      <FontAwesomeIcon
        id="menu-suporte-mobile"
        style={{ marginLeft: '-32px', marginRight: '32px', height: 18 }}
        icon={faCircleQuestion}
        color={colors.primary40}
      />
      {!user?.anonymous && <Notification notificationUniqueKey="MobileMainHeader" />}
    </div>
  );
};

MobileMainHeader.propTypes = {
  title: PropTypes.string,
  user: PropTypes.instanceOf(Object)
};

export default memo(MobileMainHeader);
