import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { StyledTabs, TabPane } from './Tabs.style';

const Tabs = ({ fullWidth, fullHeight, showMore, children, noPadding, background, onTouchMove, ...props }) => {
  useEffect(() => {
    if (!onTouchMove) return () => {};

    const el = document.getElementById('tabs');

    el.addEventListener('touchmove', onTouchMove);

    return () => {
      el.removeEventListener('touchmove', onTouchMove);
    };
  }, [onTouchMove]);

  return (
    <StyledTabs
      id="tabs"
      $fullWidth={fullWidth}
      $fullHeight={fullHeight}
      $showMore={showMore}
      animated={false}
      $noPadding={noPadding}
      background={background}
      {...props}
    >
      {children}
    </StyledTabs>
  );
};

Tabs.propTypes = {
  onChange: PropTypes.func,
  activeKey: PropTypes.string,
  fullWidth: PropTypes.bool,
  fullHeight: PropTypes.bool,
  showMore: PropTypes.bool,
  noPadding: PropTypes.bool,
  children: PropTypes.instanceOf(Object),
  background: PropTypes.string,
  onTouchMove: PropTypes.func
};

export default Tabs;

export { TabPane };
