import React from 'react';
import { Image, Space, Typography } from 'antd';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import FadeIn from '../../../components/Animations/FadeIn';
import Button from '../../../components/Button/Button';
import { Left, PresentationContent, Right } from './Onboarding.styled';
import ProfileImage from '../../../components/Images/cs-avatar.svg';

const { Text } = Typography;

const Avatar = styled(Image)`
  border-radius: 50% 0 50% 0;
`;

const Presentation = ({ onSubmit }) => {
  const { user } = useSelector(state => state.authReducer);

  return (
    <PresentationContent>
      <Left />
      <Space direction="vertical" size={16}>
        <FadeIn>
          <Avatar preview={false} name="avatar-success" src={ProfileImage} />
          <Text className="title">Olá, {user.name}!</Text>
          <Text className="sub-title">
            Seja bem-vindo a experiência <b>Vobi.</b>
          </Text>
        </FadeIn>
        <FadeIn fadeInStart={1500}>
          <Text>
            Oi! Me chamo Fernanda, sou especialista de sucesso <br /> aqui na Vobi e vou te acompanhar nessa jornada!
          </Text>
        </FadeIn>
        <FadeIn fadeInStart={2500}>
          <Button style={{ margin: 'auto' }} type="primary" onClick={onSubmit}>
            Vamos lá!
          </Button>
        </FadeIn>
      </Space>
      <Right />
    </PresentationContent>
  );
};

Presentation.propTypes = {
  onSubmit: PropTypes.func
};

export default Presentation;
