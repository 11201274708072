import React from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';
import { colors, fonts } from '../../styles/style';

const StyledCompanyName = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  -webkit-box-orient: vertical;
  font-size: ${fonts.sizeMd};
  word-break: break-word;
`;

const CompanyProgressDropDown = () => {
  const history = useHistory();
  const { user: { company } = {} } = useSelector(state => state.authReducer);
  const { guideLevel } = useSelector(state => state.setup.enums);
  const levelLabel =
    Object.values(guideLevel)?.find(level => level?.value === company?.guideAchievements?.level)?.label ||
    guideLevel?.basic?.label;

  return (
    <div
      aria-hidden="true"
      id="progressDropDown"
      style={{
        height: '100%',
        width: '100%',
        lineHeight: 'normal'
      }}
      onClick={() => {
        history.push(`/profissional/guia-futuro`);
      }}
    >
      <div style={{ cursor: 'pointer', paddingLeft: '8px' }}>
        <Tooltip title={company?.name || company?.legalName}>
          <StyledCompanyName>{company?.name || company?.legalName}</StyledCompanyName>
        </Tooltip>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <p style={{ fontSize: 12 }}>
            Nível: <span style={{ color: `${colors.primary30}` }}>{levelLabel}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default CompanyProgressDropDown;
