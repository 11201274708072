import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { ConfigProvider } from 'antd';
import ptBR from 'antd/es/locale/pt_BR';
import * as dayjs from 'dayjs';
import * as ptBt from 'dayjs/locale/pt-br';
import utc from 'dayjs/plugin/utc';

import { Route } from 'react-router';
import { Helmet } from 'react-helmet';
import MainRoutes from './routes/MainRoutes';

import { store, persistor } from './lib/config/redux-store';
// teste cloudfare - remover dps
dayjs.locale(ptBt);
dayjs.extend(utc);

function App() {
  return (
    <>
      <Helmet htmlAttributes={{ lang: 'pt-br' }} />
      <ConfigProvider locale={ptBR}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <Route
                path={[
                  '/:rootPage/:page/:subpage/:action',
                  '/:rootPage/:page/:subpage',
                  '/:rootPage/:page',
                  '/:rootPage',
                  '/'
                ]}
                component={MainRoutes}
              />
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </ConfigProvider>
    </>
  );
}

export default App;
