import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Modal from './Modal';
import SimpleTable from '../Table/SimpleTable';
// eslint-disable-next-line import/no-cycle
import { productColumns } from '../../lib/mapping/TableOrList/orderItem';

const ChildrenItemsModal = ({ data, hideAction, ...params }) => {
  const { user } = useSelector(state => state.authReducer);
  const { userType } = useSelector(state => state.setup.enums);
  const { purchaseStatus } = useSelector(state => state.setup.systemData);

  const isProvider = user.userType === userType.provider.value;
  const isBO = user.userType === userType.operator.value;

  const columns = productColumns({
    purchaseStatus,
    isBO,
    isProvider,
    hideAction: true
  });

  return (
    <Modal open title="Itens do agrupamento" hideFooter {...params}>
      <SimpleTable data={data} columns={columns} />
    </Modal>
  );
};

ChildrenItemsModal.propTypes = {
  data: PropTypes.instanceOf(Object),
  hideAction: PropTypes.bool
};

export default ChildrenItemsModal;
