import styled, { css } from 'styled-components';
import { borderSolid, breakpoints, colors, fonts, spaces } from '../../../styles/style';

const PresentationContent = styled.div`
  display: flex;
  margin: ${spaces.space8} 0 0 0;
  justify-content: center;
  position: relative;
  height: calc(100% - ${spaces.space8});
  text-align: center;

  .title {
    font-size: ${fonts.sizeH6};
    font-weight: 500;
    display: block;
    margin-top: ${spaces.space4};
  }

  .sub-title {
    margin: ${spaces.space3} 0 ${spaces.space1} 0;
    font-size: ${fonts.sizeH6};
    display: block;
  }
`;

const Left = styled.div`
  height: 250px;
  width: 20%;
  border: ${borderSolid};
  border-left: 0;
  border-radius: 0 0 50% 0;
  position: absolute;
  left: 0;
  top: 0;
`;

const Right = styled.div`
  height: 400px;
  width: 20%;
  border: ${borderSolid};
  border-right: 0;
  border-bottom: 0;
  position: absolute;
  border-radius: 50% 0 0 0;
  right: 0;
  bottom: 0;
`;

const Content = styled.div`
  overflow: auto;
  height: 100%;
  margin-bottom: -${spaces.space1};
  padding-top: ${props => props.$paddingTop};

  h1,
  h4,
  h5 {
    font-weight: 500;
    padding-bottom: ${spaces.space2};
  }

  @media (max-width: ${breakpoints.tablet}) {
    h1 {
      font-size: ${fonts.sizeH6};
    }
    padding: ${spaces.space7} ${spaces.space3} ${spaces.space3} ${spaces.space3};
  }

  ${props =>
    props.$center &&
    css`
      text-align: center;

      button {
        margin: auto;
      }
    `}
`;

const StepBox = styled.div`
  margin: 0 -${spaces.space3};
  height: calc(100vh - 280px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${breakpoints.tablet}) {
    margin: 0 -${spaces.space2};
  }
`;

const Columns = styled.div`
  display: flex;
  height: 100%;

  .stepColumn {
    flex: 1;
    padding: 56px 100px 0 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .secondColumn {
    flex: 1;
    background: ${colors.neutral90};

    img {
      margin: 25% 0 0 -100px;
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    .stepColumn {
      padding: 0;
    }

    .secondColumn {
      display: none;
    }
  }

  span {
    line-height: ${fonts.sizeLg};
  }
`;

const FinishSteps = styled.div`
  display: flex;
  width: 840px;
  margin: ${spaces.space5} auto;
  border: ${borderSolid};
  border-radius: ${spaces.space1};

  .largeText {
    font-size: ${fonts.sizeLg};
    font-weight: 500;
  }

  @media (max-width: ${breakpoints.tablet}) {
    flex-flow: column nowrap;
    width: 100%;
  }
`;

const AboutContent = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${spaces.space7};

  .ant-select-selector {
    width: 360px !important;
  }

  @media (max-width: ${breakpoints.tablet}) {
    margin-top: unset;
    display: block;

    .ant-select-selector {
      width: calc(100vw - ${spaces.space6}) !important;
    }
  }
`;

const AboutYouCardGroup = styled.div`
  display: flex;
  grid-gap: ${spaces.space2};

  @media (max-width: ${breakpoints.tablet}) {
    flex-wrap: wrap;
  }
`;

const handleColorCard = (position, isSelected) => {
  switch (position) {
    case 1:
      return `
        ${isSelected ? 'border: 2px solid #A88F4E;' : null};
        background: #FFF7EB;
        b {
          color: #A88F4E;
        }
        .info-text {
          color: #A88F4E;
        }
      `;
    case 2:
      return `
      ${isSelected ? 'border: 2px solid #5E73A6;' : null};
      background: #E8EDFF;
      b {
        color: #5E73A6;
      }
      .info-text {
        color: rgba(94, 115, 166, 0.8);
      }
    `;
    case 3:
      return `
      ${isSelected ? 'border: 2px solid #786CBB;' : null};
      background: #E9E5FF;
      b {
        color: #786CBB;
      }
      .info-text {
        color: #988BDC;
      }
    `;
    default:
      return null;
  }
};

const Cell = styled.div`
  border-right: ${borderSolid};

  &:last-of-type {
    border: none;
  }

  .cell-content {
    padding: ${spaces.space3} ${spaces.space3} 0 ${spaces.space3};
    display: flex;
    flex-flow: column nowrap;
    width: 280px;
    height: 100%;

    img {
      height: 120px;
      margin-bottom: ${spaces.space2};
    }

    .grid-footer {
      display: flex;
      align-content: center;
      justify-content: space-around;
      height: ${spaces.space6};
      border-top: ${borderSolid};
      margin-top: auto;

      img {
        height: ${spaces.space4};
        margin-bottom: 0;
      }

      a {
        align-self: center;
      }
    }

    .info-text {
      color: ${colors.primary20};
      opacity: 0.6;
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    border-right: none;
    border-bottom: ${borderSolid};
    .cell-content {
      width: 100%;

      .grid-footer {
        border: none;
      }
    }
  }
`;

const Card = styled.div`
  border-radius: ${spaces.space1};
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: ${spaces.space1};
  cursor: pointer;

  span {
    padding: 0 10px;
  }

  img {
    height: 120px;
    margin-bottom: ${spaces.space2};
  }

  .store-logos {
    display: flex;
    justify-content: space-around;
    align-content: center;
    img {
      height: ${spaces.space4};
      margin-bottom: 0;
    }
  }

  .info-text {
    margin-top: ${spaces.space1};
    font-weight: 400;
    font-size: ${fonts.sizeSm};
    line-height: ${fonts.sizeMd};
    color: ${colors.primary20};
    opacity: 0.6;
  }

  ${props =>
    !props.$isSelected &&
    css`
      border: 1.5px solid white;
      :hover {
        box-shadow: 0 10px 8px 0 rgb(0 0 0 / 11%);
      }
    `};

  ${({ position, $isSelected }) => handleColorCard(position, $isSelected)};

  @media (max-width: ${breakpoints.tablet}) {
    flex-basis: 47%;

    img {
      height: 85px;
      margin-bottom: ${spaces.space2};
    }
  }
`;

const RadioBox = styled.div`
  padding: 0 ${spaces.space1};
  border-radius: ${spaces.space1};
  height: ${spaces.space6};
  display: flex;
  text-align: initial;
  width: 100%;
  color: ${colors.white} !important;
  border: 1px solid ${colors.white} !important;
  box-shadow: none !important;
  align-items: center;
  border-color: ${colors.neutralSecondary40} !important;
  &:hover,
  &:focus {
    border-color: ${colors.neutralSecondary40} !important;
    background-color: #f5f5f5;
  }
`;

const AlmostThereColumns = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: calc(100% - 65px);

  .column {
    padding: ${spaces.space7} ${spaces.space5};
  }

  @media (max-width: ${breakpoints.tablet}) {
    height: calc(100% - 18px);
    justify-content: flex-start;

    .column {
      padding: 0;
    }
  }
`;

export {
  PresentationContent,
  Left,
  Right,
  Content,
  StepBox,
  Columns,
  FinishSteps,
  Cell,
  AboutContent,
  AboutYouCardGroup,
  Card,
  RadioBox,
  AlmostThereColumns
};
