export const colors = {
  primary99: '#FBFBFF',
  primary95: '#EBF0FF',
  primary90: '#D6E2FF',
  primary80: '#ACC7FF',
  primary70: '#7EABFF',
  primary60: '#4F8EFA',
  primary50: '#2F74DE',
  primary40: '#005AC3',
  primary30: '#004396',
  primary20: '#002E6C',
  primary10: '#001A43',
  primary0: '#000F27',

  secondary99: '#FBFFFD',
  secondary95: '#B0FFF6',
  secondary90: '#49FBEB',
  secondary80: '#00DECF',
  secondary70: '#00C0B2',
  secondary60: '#00A296',
  secondary50: '#00867B',
  secondary40: '#006A61',
  secondary30: '#005049',
  secondary20: '#003732',
  secondary10: '#00201D',
  secondary0: '#001513',

  neutral99: '#FBFBFD',
  neutral95: '#F5F7FD',
  neutral90: '#E3E8F6',
  neutral80: '#CFD5E7',
  neutral70: '#A6AFC7',
  neutral60: '#8A94AD',
  neutral50: '#6F778B',
  neutral40: '#565E71',
  neutral30: '#3F4759',
  neutral20: '#283041',
  neutral10: '#131B2C',
  neutral0: '#0A101C',

  neutralSecondary99: '#FCFCFC',
  neutralSecondary95: '#F0F1F5',
  neutralSecondary90: '#E1E1E6',
  neutralSecondary80: '#C5C5CA',
  neutralSecondary70: '#AAAAAE',
  neutralSecondary60: '#909194',
  neutralSecondary50: '#76777A',
  neutralSecondary40: '#5E5E62',
  neutralSecondary30: '#46464A',
  neutralSecondary20: '#303034',
  neutralSecondary10: '#1B1B1F',

  black: '#000000',
  white: '#ffffff',

  error99: '#FFFBFB',
  error95: '#FFEDE9',
  error90: '#FFDAD4',
  error80: '#FFB4A9',
  error70: '#FF897A',
  error60: '#FF5449',
  error50: '#DD3730',
  error40: '#BA1B1B',
  error30: '#930006',
  error20: '#680003',
  error10: '#410001',
  error0: '#220001',

  success99: '#F5FFEE',
  success95: '#D4EEC6',
  success90: '#B7E3A2',
  success80: '#99D87C',
  success70: '#82CF5E',
  success60: '#6AC640',
  success50: '#5BB638',
  success40: '#46A22E',
  success30: '#308E24',
  success20: '#006B14',
  success10: '#004B0E',
  success0: '#002707',

  warning99: '#FFFFFB',
  warning95: '#FFEEDA',
  warning90: '#FFDDB2',
  warning80: '#FFB951',
  warning70: '#E29D34',
  warning60: '#C48319',
  warning50: '#765200',
  warning40: '#845400',
  warning30: '#643F00',
  warning20: '#462A00',
  warning10: '#2A1800',
  warning0: '#150C00',

  warningSecondary99: '#FFFDFB',
  warningSecondary95: '#FFEDE1',
  warningSecondary90: '#FFDCC3',
  warningSecondary80: '#FFB77E',
  warningSecondary70: '#FF8E1B',
  warningSecondary60: '#DE7400',
  warningSecondary50: '#B85F00',
  warningSecondary40: '#944B00',
  warningSecondary30: '#703800',
  warningSecondary20: '#4F2500',
  warningSecondary10: '#301400',
  warningSecondary0: '#150900'
};

export const fonts = {
  family: 'inter, sans-serif',
  familySecondary: 'inter, sans-serif',
  familyAwesomePro: 'Font Awesome 5 Pro, sans-serif',
  sizeLg: '16px',
  sizeMd: '14px',
  sizeSm: '12px',
  sizeXs: '10px',
  sizeH1: '96px',
  sizeH2: '60px',
  sizeH3: '48px',
  sizeH4: '34px',
  sizeH5: '24px',
  sizeH6: '20px',
  weight1: 'normal',
  weight500: '500'
};

export const breakpoints = {
  mobile: '450px',
  tablet: '768px',
  desktop: '980px',
  wide: '1300px'
};

export const space = 8;
export const spaces = {
  space0: `${space / 2}px`,
  space1: `${space}px`,
  space2: `${space * 2}px`,
  space3: `${space * 3}px`,
  space4: `${space * 4}px`,
  space5: `${space * 5}px`,
  space6: `${space * 6}px`,
  space7: `${space * 7}px`,
  space8: `${space * 8}px`,
  space9: `${space * 9}px`,
  space10: `${space * 10}px`
};

export const boxShadow = '2px 2px 4px 0 rgba(0, 0, 0, .05)';

export const opacities = {
  opacity0: 0,
  opacity02: 33
};

export const borderSolid = '1px solid #495e9033';
