import React, { useEffect, useState, useRef, createRef, useLayoutEffect, useMemo, useCallback } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import parse from 'html-react-parser';
import { useSelector } from 'react-redux';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Grid } from '@homehero/hero-style';
import { Typography, Space, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReceipt, faEdit, faChevronUp, faChevronDown, faFolder, faCog } from '@fortawesome/pro-regular-svg-icons';

import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import useCRUD from '../../../_Hooks/useCRUD';
import useViewport from '../../../_Hooks/useViewport';
import { useContextHook } from '../../../contexts/Context';

import SubHeader from '../../../components/Header/SubHeader';
import Content from '../../../components/Content/Content';
import Box from '../../../components/Box/Box';
import Loader from '../../../components/Loader/CenteredLoader';
import Form from '../../../components/Form/Form';
import TableOrList from '../../../components/List/TableOrList';
import { columns as specificationColumns } from '../../../lib/mapping/TableOrList/specificationColumns';
import ImageInput from '../../../components/Input/ImageInput';
import Button from '../../../components/Button/Button';
import SearchModal from '../../../components/Modal/SearchModal';
import UseAsTemplateModal from '../../../components/Modal/UseProjectAsTemplateModal';
import SpecificationBulkActions from '../../../components/BulkActions/SpecificationBulkActions';

import { schema, templateMapping } from '../../../lib/mapping/Form/templateSchema';
import { colors, spaces } from '../../../styles/style';
import AddTableButton from '../../../components/Button/AddTableButton';
import { inlineFieldsMappingTemplate as inlineFieldsMapping, itemSchema } from '../../../lib/mapping/Form/itemSchema';
import { parseArrayAsObject } from '../../../lib/helpers/parseArrayAsObject';
import Tabs, { TabPane } from '../../../components/Tabs/Tabs';
import Notes from '../../Opportunities/Notes';
import RenderFolder from '../../../components/Folder/RenderFolder';
import ScheduleTable from '../../../components/Table/ScheduleTable';
import Dropdown from '../../../components/Dropdown/Dropdown';
import SubItemsTemplateMenu from './SubItemsTemplateMenu';
import EditItemModal from '../../../components/Modal/EditItemModal';

const { Text } = Typography;

const StyledTitle = styled(Text)`
  color: ${colors.neutral50};
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-extra-content {
    display: flex;
    justify-content: flex-end;
    margin-left: ${spaces.space1};
  }
`;
const StyledTabPane = styled(TabPane)`
  background: ${colors.neutral90};
  padding: 0px 3px 0px 3px;
`;

const StyledDescription = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;

  ${props =>
    props.showAll === false &&
    css`
      -webkit-line-clamp: 3;
    `}

  p {
    color: ${colors.neutral40} !important;
  }
  -webkit-box-orient: vertical;
`;

const templateTaskOptions = idTemplate => ({
  include: [
    {
      model: 'step'
    }
  ],
  where: { idTemplate },
  order: ['order', 'ASC NULLS FIRST']
});

const EditOrCreateTemplate = ({ idApply, setConfirmApply, community, subItemsProps }) => {
  const { isMobile } = useViewport(window.innerWidth);
  const { user } = useSelector(state => state.authReducer);
  const { userType, refurbishItemType, templateType } = useSelector(state => state.setup.enums);
  const { unit: unitsArray } = useSelector(state => state.setup.systemData);
  const isOperator = user.userType === userType.operator.value;
  const { id: _idTemplate } = useParams();
  const history = useHistory();
  const isApply = !!idApply;
  const idTemplate = idApply || _idTemplate;
  const isCreateTemplate = !idTemplate;
  const [serializedUnitsArray, setSerializedUnits] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedRowKeys, setSelectRowKeys] = useState([]);
  const [showFooter, setShowFooter] = useState(!isMobile());
  const [seeMore, setSeeMore] = useState(false);
  const [showSeeMore, setShowSeeMore] = useState(false);
  const [showMoreInfo, setShowMoreInfo] = useState(!isMobile());
  const [selectedTab, setSelectedTab] = useState('schedule');
  const [showModalFolder, setShowModalFolder] = useState(false);
  const [showModalCopyProject, setShowModalCopyProject] = useState(false);
  const [searchModal, setSearchModal] = useState({ show: false });
  const [idItemParams, setIdItemParams] = useState(null);
  const { subItems, setSubItems } = subItemsProps || {};

  const expandedRows = useRef([]);
  const ref = createRef();
  const loadAll = useRef(0);

  const handleSelectItem = (key, items) => {
    setSelectRowKeys(key);
    setSelectedItems(items);
  };

  const {
    data,
    loading,
    handleGet,
    handleCreate: handleCreateTemplate,
    handleUpdate: handleUpdateTemplate
  } = useContextHook();

  const options = {
    include: [
      {
        model: 'templateItem',
        as: 'children',
        include: ['supplier', 'refurbishGroup', 'costCenter']
      },
      'supplier',
      'refurbishGroup',
      'costCenter'
    ],
    order: [['order', 'ASC'], [['children', 'order', 'ASC']]],
    where: { idParent: null, idTemplate }
  };

  const {
    list: specificationList,
    setList,
    handleGet: handleGetTemplateItem,
    handleUpdate: handleUpdateTemplateItem,
    handleCreate: handleCreateTemplateItem
  } = useCRUD({
    model: 'template-item',
    options,
    immediatelyLoadData: false
  });

  const { handleCreate: handleCreateItem } = useCRUD({
    model: 'item',
    immediatelyLoadData: false
  });

  const { list: supplierList, handleGet: getSuppliers } = useCRUD({
    model: 'supplier',
    immediatelyLoadData: false
  });

  const { list: groupList, handleGet: getGroups } = useCRUD({
    model: 'refurbishGroup',
    immediatelyLoadData: false
  });

  const { list: categoryList, handleGet: getCategories } = useCRUD({
    model: 'costCenter',
    immediatelyLoadData: false
  });

  useEffect(() => {
    if (showFooter && data) {
      const _refetchOptions = { where: { idCompany: data?.idCompany }, order: [['name', 'asc']] };
      getSuppliers({ refetchOptions: _refetchOptions });
      getGroups({ refetchOptions: _refetchOptions });
      getCategories({ refetchOptions: _refetchOptions });
    }
  }, [showFooter, data]);

  const templateTypeValue = templateType.budget.value;

  const handleLoad = () => handleGetTemplateItem({ refetchOptions: options });

  const onSubmit = submitData => {
    const CreateOrUpdate = isCreateTemplate
      ? handleCreateTemplate({ values: submitData, refresh: false, displayToast: true })
      : handleUpdateTemplate({
          id: idTemplate,
          values: submitData,
          displayToast: true,
          noLoading: true,
          refresh: false
        });

    CreateOrUpdate.then(({ id, error }) => {
      if (!error && isCreateTemplate) {
        history.push(`editar/${id}`);
      }
    });
  };

  const handleSubmitTemplateItems = ({ values }) => {
    const { idParent, idCostCenter, idRefurbishGroup, idSupplier, idSearch, ..._values } = values;

    const itemValues = {
      idSupplier: idSupplier || undefined,
      idCostCenter: idCostCenter || undefined,
      idRefurbishGroup: idRefurbishGroup || undefined,
      ..._values
    };

    if (values?.id) {
      return handleCreateTemplateItem({
        values: {
          ...itemValues,
          ...(idSearch ? { idSearch } : { idItem: values.id }),
          idTemplate,
          idParent
        },
        displayToast: true,
        refresh: false
      }).then(handleLoad);
    }

    if (!values?.name && !isMobile()) {
      return null;
    }

    return handleCreateItem({ values: itemValues, refresh: false, displayToast: false })
      .then(item => {
        return handleCreateTemplateItem({
          values: {
            ...item,
            idItem: item?.id,
            idTemplate,
            idParent
          },
          displayToast: true,
          refresh: false
        });
      })
      .then(handleLoad);
  };

  const templateItemUpdate = ({ id: templateItemId }, field) => value => {
    handleUpdateTemplateItem({
      id: templateItemId,
      values: { [field]: value },
      updateOptions: {},
      refresh: false
    }).then(handleLoad);
  };

  const submitMove = ({ values: { id, order, idParent } }) =>
    handleUpdateTemplateItem({
      id,
      values: { order, idParent },
      updateOptions: {},
      refresh: false
    }).then(handleLoad);

  const _specificationColumns = specificationColumns({
    unitsArray,
    serializedUnitsArray,
    expandedRows,
    refurbishItemType,
    handleChange: templateItemUpdate,
    handleLoad,
    list: specificationList,
    isMobile: isMobile(),
    isApply,
    isTemplate: true
  });
  const handleRowClick = ({ id, type }) => {
    if (type !== 3) {
      setIdItemParams(id);
    }
  };

  useEffect(() => {
    const object = parseArrayAsObject(unitsArray, true);
    setSerializedUnits(object);
  }, [unitsArray]);

  useEffect(() => {
    if (!loading) {
      setSelectedTab(data?.type === templateTypeValue ? 'specification' : selectedTab);
    }
  }, [loading]);

  useEffect(() => {
    if (idTemplate) {
      handleGet({
        refetchOptions: {
          where: { community },
          include: [{ model: 'company', required: isOperator ? false : !isApply }]
        },
        refetchPathOptions: idTemplate ? `/${idTemplate}` : ''
      });

      handleLoad();
    } else {
      handleGet();
    }
  }, [idTemplate]);

  useLayoutEffect(() => {
    if (ref.current?.offsetHeight < ref.current?.children[0]?.scrollHeight) {
      setShowSeeMore(true);
    }
  }, [ref]);

  const handleCloseSearchModal = () => {
    setSearchModal({ show: false });
  };

  const handleSubmitSearchModal = newData => {
    const { values } = searchModal;
    handleSubmitTemplateItems({ values: { ...newData, ...values } });
  };

  const handleItemClick = clickData => {
    const { modal, values } = clickData;
    if (modal === 'searchModal') {
      setSearchModal({ show: true, values });
    }
  };

  const AddButton = useCallback(
    params => (
      <AddTableButton
        {...params}
        model="addTemplateItems"
        type="dropdown"
        handleCreate={({ values }) => handleSubmitTemplateItems({ values })}
        onItemClick={handleItemClick}
        showParentReference
        buttonStyle={{ marginTop: '-6px' }}
        formProps={
          !isMobile()
            ? {
                mapping: inlineFieldsMapping(data?.idCompany),
                schema: itemSchema,
                onSubmit: values => handleSubmitTemplateItems({ values }),
                addText: 'O item criado será adicionado aos "Meus Produtos e Serviços"',
                addTextStyle: { color: colors.neutral60 },
                style: { width: '100%', height: 89 }
              }
            : undefined
        }
      />
    ),
    [idTemplate]
  );

  const handleCopyProjectModal = () => {
    setShowModalCopyProject(!showModalCopyProject);
  };

  const afterSubmitProjectModal = () => {
    loadAll.current += 1;
    handleLoad();
    handleCopyProjectModal();
  };

  const _mapping = useMemo(
    () =>
      templateMapping({
        isMobile: isMobile(),
        idTemplate,
        isOperator,
        type: data?.type,
        handleModal: handleCopyProjectModal
      }),
    [idTemplate, data]
  );

  const _onSubmit = useMemo(() => onSubmit, [idTemplate]);
  const referOptions = useMemo(() => templateTaskOptions(idTemplate), [idTemplate]);
  const stepOptions = useMemo(
    () => ({
      where: {
        '$templateStep.id$': null,
        id: { gte: 2 },
        ...(isOperator && { idCompany: null })
      },
      include: [{ model: 'templateStep', where: { idTemplate }, required: false }],
      order: [['order', 'ASC']],
      onlyMine: !isOperator
    }),
    [idTemplate]
  );

  const SubItemsDropdown = () => {
    return subItems ? (
      <Dropdown
        trigger={['click']}
        overlayStyle={{ zIndex: 1001 }}
        menu={<SubItemsTemplateMenu initialItems={subItems} onClose={newSubItems => setSubItems(newSubItems)} />}
      >
        <Space>
          <FontAwesomeIcon icon={faCog} color={colors.primary40} />
          {!isMobile() && (
            <>
              <Text style={{ color: colors.primary40 }}>Configurar o que aplicar</Text>
              <FontAwesomeIcon icon={faChevronDown} color={colors.primary40} />
              <Tooltip
                placement="bottom"
                title="Caso deseje, você pode escolher os módulos que deseja aplicar ao utilizar este template"
              >
                <FontAwesomeIcon icon={faQuestionCircle} color={colors.primary40} />
              </Tooltip>
            </>
          )}
        </Space>
      </Dropdown>
    ) : (
      <div />
    );
  };

  return (
    <Grid fluid>
      {!isApply && <SubHeader title={`${!isCreateTemplate ? 'Atualizar' : 'Novo'} template`} level={5} />}
      <Content style={{ paddingBottom: 80 }}>
        {loading ? (
          <Loader text="Carregando..." />
        ) : (
          <>
            {isApply ? (
              <Content style={{ display: 'flex', flexDirection: isMobile() ? 'column' : 'row' }}>
                {!isMobile() ? (
                  <div>
                    <ImageInput key={`image${data?.id}`} id={data?.id} value={data?.image} disabled size="200px" />
                  </div>
                ) : null}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 3,
                    margin: '0 16px'
                  }}
                >
                  <Text style={{ color: colors.primary40 }}>
                    {data?.type === templateType.budget.value ? (
                      <>
                        <FontAwesomeIcon icon={faReceipt} /> Orçamento
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faFolder} /> Projeto
                      </>
                    )}
                  </Text>
                  <Text style={{ fontSize: '18px', margin: '32px 0' }}>{data?.name || '-'}</Text>
                  <Space direction="vertical">
                    <StyledTitle>Descrição do template</StyledTitle>
                    <StyledDescription showAll={seeMore} ref={ref}>
                      <div>{parse(data?.description || '')}</div>
                    </StyledDescription>
                    {showSeeMore && (
                      <Button text type="primary" onClick={() => setSeeMore(!seeMore)}>
                        {seeMore ? 'Ver menos' : 'Ver mais'}
                      </Button>
                    )}
                    {isMobile() ? (
                      <>
                        <Button fullWidth type="primary" onClick={() => setConfirmApply(idApply)}>
                          Usar template
                        </Button>
                        {!data?.idCompany || data?.community ? null : (
                          <Link to={`${user.type}/ferramentas/meus-templates/editar/${data?.id}`} target="_blank">
                            <Text style={{ color: colors.primary40 }}>
                              <FontAwesomeIcon icon={faEdit} /> Editar template
                            </Text>
                          </Link>
                        )}
                        <Button text onClick={() => setShowMoreInfo(!showMoreInfo)}>
                          {showMoreInfo ? 'Mostrar menos informações' : 'Mostrar mais informações'}
                          <FontAwesomeIcon icon={showMoreInfo ? faChevronUp : faChevronDown} />
                        </Button>
                      </>
                    ) : null}
                  </Space>
                </div>
                {showMoreInfo ? (
                  <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <Space direction="vertical">
                      <StyledTitle>Criado por</StyledTitle>
                      <Text>{data?.company ? data?.company.name || '-' : 'Vobi'}</Text>
                    </Space>
                    <Space style={{ margin: '16px 0' }} direction="vertical">
                      <StyledTitle>Criado em</StyledTitle>
                      <Text>{data?.createdAt ? dayjs(data?.createdAt).format('DD/MM/YYYY') : '-'}</Text>
                    </Space>
                    {!isMobile() ? (
                      <>
                        {!data?.idCompany || data?.community ? null : (
                          <Link to={`/${user.type}/ferramentas/meus-templates/editar/${data?.id}`} target="_blank">
                            <Text style={{ color: colors.primary40 }}>
                              <FontAwesomeIcon icon={faEdit} /> Editar template
                            </Text>
                          </Link>
                        )}
                        <Button
                          style={{ alignSelf: 'auto', marginTop: 16 }}
                          size="small"
                          type="primary"
                          onClick={() => setConfirmApply(idApply)}
                        >
                          Usar template
                        </Button>
                      </>
                    ) : null}
                  </div>
                ) : null}
              </Content>
            ) : (
              <Box style={{ borderRadius: '8px 8px 0 0' }}>
                <Form
                  id="template"
                  mapping={_mapping}
                  schema={schema(false)}
                  customButtonTitle={isCreateTemplate ? 'Próximo' : null}
                  onSubmit={_onSubmit}
                  data={data}
                  keepOldValues
                  saveWarningMessage="Existem alterações nos detalhes deste template que precisam ser salvas."
                  marginTop={isMobile() ? '0' : '-20px'}
                />
              </Box>
            )}
          </>
        )}
        {data?.id && idTemplate ? (
          <StyledTabs
            activeKey={selectedTab}
            onChange={key => setSelectedTab(key)}
            tabBarExtraContent={<SubItemsDropdown />}
          >
            {data?.type !== templateTypeValue ? (
              <StyledTabPane key="schedule" tab="Cronograma">
                <ScheduleTable
                  idReference={idTemplate}
                  referModel="template"
                  referKey="idTemplate"
                  isApply={isApply}
                  referOptions={referOptions}
                  stepOptions={stepOptions}
                  template
                  forceLoad={loadAll.current}
                  expandEmpty
                  lazyLoad
                />
              </StyledTabPane>
            ) : null}
            <StyledTabPane
              key="specification"
              tab="Orçamento"
              paddingContent="0px 3px 0px 3px"
              backgroundContent={colors.neutral90}
            >
              <TableOrList
                list={specificationList}
                onSelectItem={handleSelectItem}
                selectedItems={selectedItems}
                moveRowProps={!isApply ? { moveList: specificationList, submitMove } : undefined}
                selectedRowKeys={selectedRowKeys}
                columns={_specificationColumns}
                expandedRows={expandedRows}
                setShowFooter={setShowFooter}
                AddComponent={!isApply ? AddButton : null}
                addItemText="+ Adicionar item"
                addSubItemText="+ Adicionar item à"
                rowSelection={!isApply}
                rowChildrenSelection={!isApply}
                margin="0 0"
                onClick={handleRowClick}
              />
            </StyledTabPane>
            {data?.type !== templateTypeValue ? (
              <>
                <TabPane key="file" tab="Arquivos">
                  <Content style={{ backgroundColor: 'white' }}>
                    <RenderFolder
                      idReference={idTemplate}
                      referKey="idTemplate"
                      showModalFolder={showModalFolder}
                      setShowModalFolder={setShowModalFolder}
                      isApply={isApply}
                      forceLoad={loadAll.current}
                    />
                    {!isApply && (
                      <Button
                        text
                        style={{ color: colors.primary40, marginTop: '24px' }}
                        onClick={() => setShowModalFolder(true)}
                      >
                        + Adicionar pasta à este template
                      </Button>
                    )}
                  </Content>
                </TabPane>
                <TabPane key="notes" tab="Anotações">
                  <Content style={{ backgroundColor: 'white', padding: '0px 3px 0px 3px' }}>
                    <Notes idTemplate={idTemplate} isApply={isApply} forceLoad={loadAll.current} />
                  </Content>
                </TabPane>
              </>
            ) : null}
          </StyledTabs>
        ) : null}
        {showModalCopyProject && data ? (
          <UseAsTemplateModal
            title="Escolha o Projeto"
            model="template"
            pathOptions="/refurbishToTemplate"
            textHeader="Você poderá selecionar os módulos que deseja utilizar do projeto selecionado e eles
          serão adicionados neste template."
            successText="Os itens foram adicionados neste template com sucesso"
            text="Escolha o que deseja em seu template."
            source="Template"
            modalType="ApplyProject"
            idTemplate={data.id}
            onClose={() => handleCopyProjectModal()}
            afterSubmit={() => afterSubmitProjectModal()}
          />
        ) : null}
      </Content>
      {searchModal.show && <SearchModal onSubmit={handleSubmitSearchModal} onClose={handleCloseSearchModal} />}
      {!isApply && showFooter && data ? (
        <SpecificationBulkActions
          selectedItems={selectedItems}
          selectedRowKeys={selectedRowKeys}
          setSelectedItems={setSelectedItems}
          setSelectedRowKeys={setSelectRowKeys}
          setList={setList}
          handleLoad={handleLoad}
          idCompany={data?.idCompany}
          supplierList={supplierList}
          groupList={groupList}
          categoryList={categoryList}
          showAll
          isTemplate
        />
      ) : null}
      {idItemParams ? (
        <EditItemModal
          id={idItemParams}
          model="templateItem"
          onClose={() => {
            handleLoad();
            setIdItemParams(null);
          }}
        />
      ) : null}
    </Grid>
  );
};

EditOrCreateTemplate.propTypes = {
  idApply: PropTypes.number,
  setConfirmApply: PropTypes.func,
  community: PropTypes.bool,
  subItemsProps: PropTypes.instanceOf(Object)
};

export default EditOrCreateTemplate;
