import styled, { css } from 'styled-components';
import TooltipIcon from '../Icons/TooltipIcon';
import { colors, spaces, fonts, breakpoints } from '../../styles/style';
import Button from '../Button/Button';

const Container = styled.div`
  > div {
    margin-top: ${spaces.space2};
  }

  .paymentDate {
    display: flex;
    align-items: center;

    .ant-picker {
      margin-right: ${spaces.space2};
    }
  }
`;

const SubTitle = styled.p`
  font-size: ${fonts.sizeLg};
  font-weight: 500;
  color: ${colors.neutral40};
  margin-top: ${spaces.space2};
`;

const StyledStrashIcon = styled(TooltipIcon)`
  color: ${colors.error50};
  :hover {
    cursor: pointer;
  }
`;

const StyledCardMobile = styled.div`
  padding-top: ${spaces.space2};
  padding-bottom: ${spaces.space2};
  background-color: ${colors.primary99};
  border: 1px solid ${colors.white};
  border-radius: 8px;
  box-shadow: 0 12px 12px rgba(0, 0, 0, 0.1);
`;

const StyledCardRow = styled.div`
  padding-bottom: ${spaces.space2};
  padding-right: ${spaces.space2};
  padding-left: ${spaces.space2};
`;

const Line = styled.div`
  width: 100%;
  border-top: 1px solid ${colors.neutralSecondary95};
`;

const InlineItems = styled.div`
  display: flex;
  align-items: center;
  .quantity {
    flex: 1;
  }
  .value {
    flex: 1;
  }
`;
const SubTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const CardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: ${spaces.space2};
  padding-left: ${spaces.space2};
  padding-right: ${spaces.space2};
`;

const StyledText = styled.p`
  margin-bottom: ${spaces.space1};
  .mobileCard {
    margin-top: ${spaces.space2};
  }
`;

const Text = styled.p`
  font-size: ${fonts.sizeSm};
  color: ${props => (!props.$color ? colors.neutral50 : props.$color)};
  ${props =>
    props.$withMarginBottom &&
    css`
      margin-bottom: ${spaces.space1};
    `}
`;
const ExtraValueContainer = styled.div`
  width: 400px;
  @media (max-width: ${breakpoints.tablet}) {
    margin-top: ${spaces.space4} !important;
    width: 100%;
  }
`;

const StyledTextCard = styled(Text)`
  color: ${colors.neutral40};
`;
const StyledButton = styled(Button)`
  margin-top: ${spaces.space2};
`;

const StyledRow = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  padding-top: ${spaces.space2};
`;

const ExtraContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledTableHeader = styled.div`
  background-color: ${colors.primary99};
  border-bottom: 1px solid ${colors.neutral90};
  border-radius: ${spaces.space1} ${spaces.space1} 0 0;
  padding-top: ${spaces.space1};
  padding: ${spaces.space1} ${spaces.space2} 0 ${spaces.space2};
`;

const TableFormItem = styled.div`
  border: 1px solid ${colors.neutral90};
  border-radius: ${spaces.space1};
`;

export {
  Container,
  SubTitle,
  StyledStrashIcon,
  StyledCardMobile,
  StyledCardRow,
  Line,
  InlineItems,
  StyledText,
  Text,
  CardFooter,
  ExtraValueContainer,
  StyledTextCard,
  StyledButton,
  StyledRow,
  ExtraContent,
  SubTitleContainer,
  StyledTableHeader,
  TableFormItem
};
