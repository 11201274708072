import { useEffect } from 'react';

const UseOutsideClick = (ref, func, refPrevent) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (refPrevent?.current && refPrevent?.current?.contains(event.target)) {
        return;
      }

      if (ref.current && !ref.current.contains(event.target) && func) {
        func();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, func]);
};

export default UseOutsideClick;
