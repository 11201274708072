import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { breakpoints, colors } from '../../styles/style';
import SelectDropdown from '../Dropdown/SelectDropdown';

const EditableDiv = styled.div`
  padding: ${props => (props.$showBord ? '4px' : null)};
  border: 1px solid transparent;
  white-space: ${props => (props.noWrap ? 'nowrap' : null)};
  min-height: 28px;
  display: flex;
  align-items: center;
  word-break: break-word;
  width: 100%;

  > svg {
    display: none;
    margin-left: 8px;
  }

  &:hover {
    cursor: pointer;
    border: ${props => (props.$showBord && !props.disabled ? `1px solid ${colors.neutral70}` : null)};
    border-radius: 8px;
    > svg {
      display: block;
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    padding: 0 8px;
  }
`;

const ColumnWrapper = ({
  value,
  _key,
  model,
  options = { where: { isActive: true } },
  onSelect,
  loadList,
  children,
  cleanValue = 1,
  showClean = true,
  disabled,
  ...props
}) => {
  const [isEdit, setEdit] = useState(false);

  const handleClick = e => {
    e.stopPropagation();
    setEdit(true);
  };

  const handleRemove = e => {
    e.stopPropagation();
    onSelect(cleanValue);
    setEdit(false);
  };

  return isEdit && !disabled ? (
    <SelectDropdown
      loadList={loadList}
      key={`${model}${value?.id}`}
      model={model}
      options={options}
      onSelect={val => {
        onSelect(val[_key || 'id'], val?.isNewRecord);
        setEdit(false);
      }}
      data={value}
      isOpen
      onClose={() => setEdit(false)}
      cleanValue={cleanValue}
      showClean={showClean}
      {...props}
    >
      {children}
    </SelectDropdown>
  ) : (
    <>
      <EditableDiv role="presentation" onClick={handleClick} $showBord={!children} disabled={disabled}>
        {children || value?.name || '-'}
        {showClean && !disabled && (children || value) ? (
          <FontAwesomeIcon icon={faTimes} onClick={handleRemove} />
        ) : null}
      </EditableDiv>
    </>
  );
};

ColumnWrapper.propTypes = {
  value: PropTypes.instanceOf(Object),
  _key: PropTypes.string,
  model: PropTypes.string,
  options: PropTypes.instanceOf(Object),
  onSelect: PropTypes.func,
  loadList: PropTypes.instanceOf(Array),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  cleanValue: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  disabled: PropTypes.bool,
  showClean: PropTypes.bool
};

export default ColumnWrapper;
