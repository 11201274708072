import React, { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { DatePicker, MaskedInput, Spacer, Text, Tooltip, Switch, Editor, CardSelect } from '@homehero/hero-style';
import { Tooltip as TooltipAntd, Col, Row, TimePicker } from 'antd';

import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import * as dayjs from 'dayjs';
import Password from 'antd/es/input/Password';

import moment from 'moment';
import AntDatePicker from '../Datepicker/Datepicker';
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import FileUpload from '../File/FileUpload';
import Select from '../Select/Select';
import ProjectResponsibleActions from '../Action/ProjectResponsibleActions';

import Input from '../Input/Input';
import ImageInput from '../Input/ImageInput';
import NewMaskedInput from '../Input/MaskedInput';
import { colors } from '../../styles/style';
import DebounceSelect from '../Select/DebounceSelect';

const FormItemCreator = ({
  formId,
  formValue,
  checked,
  property,
  setProperty,
  mapping,
  small = false,
  handleFunctions,
  nonEditable = false,
  disableValue,
  idParent,
  parentValues
}) => {
  const {
    name,
    Component,
    placeholder,
    type,
    customLabel,
    multiple,
    model,
    modelOptions,
    readOnly,
    dataType,
    setupName,
    noIndicator,
    options,
    customHandle,
    items,
    defaultValue,
    allowCustomOptions,
    onCreateOption,
    format,
    icon,
    iconRender,
    tooltip,
    maskOptions,
    autoComplete,
    allowClear,
    showTime = false,
    size,
    rows,
    loading,
    maskInput,
    hasChildren,
    parent,
    searchColumnsModel,
    customOptions,
    showSearch,
    prefix,
    suffix,
    displayStringAs = 'formValue',
    textColSm,
    ...otherProps
  } = mapping[property];

  const { parseFunc, tooltipText, ...maskedInputOtherProps } = otherProps;

  const {
    setField,
    handleChange,
    handleBlur = () => null,
    handleChangeSelect,
    handleChangeZipcode,
    handleRemoveImage
  } = handleFunctions;
  let element;

  const [refetchOptions, setRefetchOptions] = useState();
  const [_nonEditable, setNonEditable] = useState(readOnly || nonEditable);
  const [_parent] = useState(parent);

  useEffect(() => {
    if (_parent) {
      if (parentValues[_parent?.name]) {
        if (type === 'select')
          setRefetchOptions(_parent?.makeRefetchOptions({ field: _parent?.name, value: parentValues[_parent?.name] }));
        else setNonEditable(false);
      } else if (type !== 'select') {
        setNonEditable(true);
      }
    }
  }, [parentValues, _parent]);

  switch (type) {
    case 'text':
    case 'number':
    case 'phone':
    case 'textarea':
      element = !_nonEditable ? (
        <Input
          id={property}
          size={small ? 'small' : 'middle'}
          name={property}
          type={type}
          prefix={icon ? <FontAwesomeIcon icon={icon} /> : null}
          placeholder={placeholder || `Informe o ${name ? name.toLowerCase() : 'valor'}...`}
          value={formValue || ''}
          onChange={handleChange(property)}
          onBlur={handleBlur(property)}
          autoComplete={autoComplete}
          suffix={suffix}
          {...otherProps}
        />
      ) : (
        <div style={{ paddingLeft: '8px' }}> {format ? format(formValue || '-') : formValue || '-'}</div>
      );

      break;

    case 'password':
      element = !_nonEditable ? (
        <Password
          size={small ? 'small' : 'middle'}
          name={property}
          type={type}
          iconRender={visible => (visible ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />)}
          placeholder={placeholder || `Informe o ${name ? name.toLowerCase() : 'valor'}...`}
          value={formValue || ''}
          onChange={handleChange(property)}
          onBlur={handleBlur(property)}
          autoComplete={autoComplete}
          {...otherProps}
        />
      ) : (
        <div style={{ paddingLeft: '8px' }}> {format ? format(formValue || '-') : formValue || '-'}</div>
      );

      break;

    case 'currency':
    case 'cpf':
    case 'cnpj':
      element = (
        <MaskedInput
          id={property}
          name={property}
          type={type}
          placeholder={placeholder}
          onChange={handleChange(property)}
          value={format ? format(formValue || defaultValue || '') : formValue || defaultValue || ''}
          maskOptions={maskOptions}
          small={small}
          {...maskedInputOtherProps}
        />
      );
      break;
    case 'zipCode':
      element = (
        <MaskedInput
          id={property}
          name={property}
          type={type}
          placeholder={placeholder}
          onChange={handleChange(property)}
          onBlur={handleChangeZipcode(property)}
          value={formValue || defaultValue || ''}
          small={small}
        />
      );
      break;
    case 'switch':
      element = (
        <div style={{ display: 'flex' }}>
          <Switch
            name={property}
            checked={formValue || false}
            onChange={handleChange(property, { target: { type: 'checkbox', checked: !formValue } })}
            onBlur={handleChange(property, { target: { type: 'checkbox', checked: !formValue } })}
            {...otherProps}
          />
          <Spacer mlLg={1} mlMd={1} mlSm={1} mlXs={1}>
            <Text>{customLabel}</Text>
          </Spacer>
        </div>
      );
      break;
    case 'image':
      element = (
        <ImageInput
          key={`${property}${formId}`}
          id={`${property}${formId}`}
          value={formValue}
          onSubmit={({ image }) => setField(property)([image])}
          disabled={_nonEditable}
          size={size}
          loading={loading}
          {...otherProps}
        />
      );
      break;

    case 'fixed':
      element = (
        <>
          <input type="hidden" name={property} value={formValue || ''} />
          <span>{formValue} </span>
        </>
      );
      break;

    case 'select':
      element = (
        <Select
          size={small ? 'small' : null}
          name={property}
          id={property}
          customLabel={customLabel}
          dataType={dataType}
          setupName={setupName}
          model={model}
          modelOptions={modelOptions}
          multiple={multiple}
          placeholder={placeholder || 'Selecione...'}
          value={formValue || ''}
          onChange={_value => handleChangeSelect(property, _value, multiple, hasChildren)}
          onBlur={handleBlur(property)}
          allowClear={allowClear}
          options={options}
          disabled={_nonEditable}
          icon={icon ? <FontAwesomeIcon icon={icon} /> : null}
          showSearch
          allowCreate={allowCustomOptions}
          lazyLoad={parent?.lazyLoad}
          refetchOptions={refetchOptions}
          {...otherProps}
        />
      );
      break;
    case 'debounceSelect':
      element = (
        <DebounceSelect
          id={property}
          searchColumnsModel={searchColumnsModel}
          size={small ? 'small' : null}
          name={property}
          value={formValue || []}
          customLabel={customLabel}
          model={model}
          modelOptions={modelOptions}
          placeholder={placeholder || 'Selecione...'}
          onChange={_value => handleChangeSelect(property, _value, true)}
          onBlur={handleBlur(property)}
          disabled={_nonEditable}
          icon={icon ? <FontAwesomeIcon icon={icon} /> : null}
          showSearch={showSearch}
          customOptions={customOptions}
        />
      );
      break;

    case 'cardSelect':
      element = <CardSelect options={options} selected={formValue} onChange={handleChange} />;
      break;

    case 'radio':
      element = (
        <Row>
          {items.map(item => (
            <React.Fragment key={item.value}>
              <Col sm={3} xs={6}>
                <Input
                  id={formId}
                  name={property}
                  type="radio"
                  value={item.value}
                  checked={formValue === item.value}
                  onChange={() => setField(property)(item.value)}
                  onBlur={handleBlur(property)}
                />
              </Col>
              <Col sm={textColSm || 6} xs={18}>
                <Text>{item.label}</Text>
              </Col>
            </React.Fragment>
          ))}
        </Row>
      );
      break;

    case 'checkbox':
      element = (
        <div style={{ display: 'flex' }}>
          <Input
            name={property}
            style={{ width: '20px', height: '20px' }}
            type="checkbox"
            checked={formValue || false}
            onChange={handleChange(property)}
            onBlur={handleBlur(property)}
            disabled={_nonEditable}
          />
          <Spacer mlLg={1} mlMd={1} mlSm={1} mlXs={1}>
            <Text>{customLabel}</Text>
          </Spacer>
        </div>
      );
      break;

    case 'date':
      element = (
        <DatePicker
          id={property}
          small={small}
          name={property}
          selected={formValue ? dayjs(formValue).toDate() : null}
          onChange={date => setField(property)(date)}
          onBlur={handleBlur(property)}
          disabled={_nonEditable}
        />
      );
      break;
    case 'antDate':
      element = (
        <AntDatePicker
          id={property}
          style={{ width: '100%' }}
          allowClear={allowClear}
          size={small ? 'small' : 'medium'}
          name={property}
          placeholder={placeholder}
          format={showTime ? 'DD MMM - HH:mm' : format || 'DD MMM'}
          value={formValue ? dayjs(formValue) : null}
          onChange={date => {
            handleChangeSelect(property, date, null, hasChildren);
          }}
          onBlur={handleBlur(property)}
          disabled={_nonEditable}
          showTime={showTime ? { format: 'HH:mm', minuteStep: 5 } : false}
        />
      );
      break;
    case 'editorSimple':
      element = (
        <Editor
          id={property}
          toolbar={false}
          name="message"
          rows={rows || 4}
          value={formValue}
          onChange={setField(property)}
          placeholder={placeholder}
          formats={['bold', 'italic', 'link', 'underline', 'indent', 'list']}
        />
      );
      break;
    case 'editor':
      element = (
        <RichTextEditor onChange={setField(property)} value={formValue} placeholder={placeholder} {...otherProps} />
      );
      break;

    case 'file':
      element = (
        <FileUpload
          id={property}
          name={property}
          onChange={setField(property)}
          multiple={multiple}
          onRemove={(id, index) => handleRemoveImage(property)(index)}
          {...otherProps}
          {...customOptions}
          initialFiles={formValue || []}
        />
      );
      break;

    case 'custom':
      element = (
        <Component
          value={formValue}
          id={property}
          formId={formId}
          setField={setField(setProperty || property)}
          setFieldCustom={setField}
          onChange={handleChange(property)}
          onBlur={handleBlur(property)}
          nonEditable={_nonEditable}
          customHandle={customHandle}
          checked={checked}
          idParent={idParent}
        />
      );
      break;

    case 'icon':
      element = <FontAwesomeIcon icon={icon} />;
      break;

    case 'masked':
      element = (
        <NewMaskedInput
          id={property}
          value={formValue}
          onBlur={setField(property)}
          style={{ borderWidth: '1px', borderStyle: ' solid', borderColor: `${colors.neutral70}` }}
        />
      );
      break;

    case 'projectResponsibleActions':
      element = (
        <div style={{ display: 'flex' }}>
          <ProjectResponsibleActions shouldUpdate={false} />
        </div>
      );
      break;

    case 'timeHours':
      element = (
        <TooltipAntd {...(!_nonEditable && { visible: false })} title="Primeiro selecione a data">
          <TimePicker
            id={property}
            disabled={_nonEditable}
            style={{ width: '100%' }}
            defaultOpenValue={moment(formValue || '00:00', 'HH:mm')}
            onChange={hour => setField(property)(hour?.format('HH:mm'))}
            onSelect={hour => setField(property)(hour?.format('HH:mm'))}
            format="HH:mm"
            value={formValue ? moment(formValue, 'HH:mm') : null}
            placeholder="Selecionar hora"
          />
        </TooltipAntd>
      );
      break;

    case 'string':
      element = formValue ? (
        <p>
          {prefix} {formValue[displayStringAs] || formValue || '-'} {suffix}
        </p>
      ) : (
        '-'
      );
      break;

    default:
      // eslint-disable-next-line no-console
      console.log('Invalid type', type);
      break;
  }

  return tooltip ? (
    <Tooltip
      style={{ display: 'inlineblock' }}
      description={type === 'select' ? disableValue : formValue || ''}
      animation="perspective"
    >
      {element}
    </Tooltip>
  ) : (
    element
  );
};

FormItemCreator.propTypes = {
  mapping: PropTypes.instanceOf(Object).isRequired,
  handleFunctions: PropTypes.instanceOf(Object).isRequired,
  formValue: PropTypes.oneOfType([PropTypes.any]),
  formId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  property: PropTypes.string,
  setProperty: PropTypes.string,
  small: PropTypes.bool,
  nonEditable: PropTypes.bool,
  isClearable: PropTypes.bool,
  checked: PropTypes.bool,
  disableValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  idParent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  parentValues: PropTypes.instanceOf(Object),
  displayStringAs: PropTypes.string
};

export default React.memo(FormItemCreator, isEqual);
