import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Heading, Spacer } from '@homehero/hero-style';

// utils
import { sumByField } from '../../lib/helpers/helper';
import formatCurrency from '../../lib/helpers/formatCurrency';

// style
import style from './GroupHeader.module.scss';

// components
import RefurbishGroupModal from '../Modal/RefurbishGroupsModal';

const GroupHeader = ({ group, icon, refetch = () => {}, allowEdit, grouped, showTotal = true }) => {
  const [showGroupModal, setShowGroupModal] = useState(false);

  const handleGroupModal = e => {
    e.stopPropagation();
    e.preventDefault();
    setShowGroupModal(true);
  };

  const groupItems = group?.item ? group.item : null;
  const sum = groupItems ? sumByField(groupItems, 'total') : 0;
  const greaterThanOne = groupItems && groupItems.length > 1;

  return (
    <div className={style['table-heading']}>
      <div className={style['table-heading-actions']}>
        <Heading level={6} title={group.name || ''} style={{ margin: '1em', fontSize: '14px' }} bold withMargin />
        {allowEdit ? (
          <Spacer mrLg={2} mrMd={2} mrSm={2} mrXs={2} inline>
            <FontAwesomeIcon icon={faEdit} size="sm" color="#4E5772" onClick={handleGroupModal} />
          </Spacer>
        ) : (
          <Spacer mrLg={4} mrMd={4} mrSm={4} mrXs={4} inline>
            <div />
          </Spacer>
        )}
      </div>

      <div className={style['table-heading-actions']}>
        {groupItems && showTotal && (
          <Spacer mrLg={2} mrMd={2} mrSm={2} mrXs={2} inline>
            <div className="hideMobile">
              {`Total do${greaterThanOne ? 's' : ''} ite${greaterThanOne ? 'ns' : 'm'}:`}{' '}
            </div>
            <b>{formatCurrency(sum, { currencySymbol: `R$` })}</b>
          </Spacer>
        )}
        <FontAwesomeIcon icon={icon} size="lg" color="#4E5772" />
      </div>
      <RefurbishGroupModal
        id={group.id}
        grouped={grouped}
        onClose={() => {
          refetch('refurbishGroup');
          setShowGroupModal(false);
        }}
        open={showGroupModal}
      />
    </div>
  );
};

GroupHeader.propTypes = {
  group: PropTypes.instanceOf(Object).isRequired,
  icon: PropTypes.instanceOf(Object).isRequired,
  refetch: PropTypes.func,
  allowEdit: PropTypes.bool,
  grouped: PropTypes.instanceOf(Object),
  showTotal: PropTypes.bool
};

export default GroupHeader;
