import React, { useRef, useState } from 'react';
import { Col, Row } from 'antd';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { MaskedInput } from '@homehero/hero-style';
import {
  Container,
  InlineItems,
  Line,
  StyledCardMobile,
  StyledCardRow,
  StyledStrashIcon,
  SubTitle,
  Text,
  CardFooter,
  ExtraValueContainer,
  StyledTextCard,
  StyledButton,
  StyledRow,
  ExtraContent,
  SubTitleContainer,
  StyledTableHeader,
  TableFormItem
} from './PaymentFormItem.styled';
import { useContextHook } from '../../contexts/GeneralContext';
import Label from '../Label/Label';
import Input from '../Input/Input';
import { colors, spaces } from '../../styles/style';
import Button from '../Button/Button';
import PaymentExtraValuesForm from './PaymentExtraValuesForm';
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import formatCurrency from '../../lib/helpers/formatCurrency';
import formatNumber from '../../lib/helpers/formatNumber';
import useViewport from '../../_Hooks/useViewport';
import { ErrorStyled } from './PaymentBasicForm.styled';

const PaymentFormItem = () => {
  const initialValue = { name: '', price: null, quantity: 1, total: 0, description: '' };
  const { values, setField, isView, errors, touched } = useContextHook();
  const { isMobile } = useViewport(window.innerWidth);
  const { paymentItems } = values || {};
  const [_list, setList] = useState(paymentItems?.length ? paymentItems : [initialValue]);
  const row = useRef();
  const createItem = () => {
    setList(prev => [...prev, initialValue]);
  };
  const removeItem = item => {
    const itemIndex = _list.findIndex(_item => _item === item);
    const newList = _list;
    newList.splice(itemIndex, 1);
    setList(newList);
    setField('paymentItems')(newList);
  };
  const updateList = changeState => {
    if (!row.current) return null;
    const { index, data } = row.current;
    const newList = [..._list];
    newList[index] = data;
    if (changeState) setList(newList);
    return newList;
  };
  const onchangeInput = (index, key, value, changeState) => {
    const listItem = _list[index];
    if (key === 'quantity' || key === 'price') {
      const _key = key === 'quantity' ? 'price' : 'quantity';
      listItem.total = (value || 0) * (listItem[_key] || 0);
    }
    listItem[key] = value;
    row.current = { index, data: listItem };
    updateList(changeState);
  };

  const handleBlur = changeState => {
    const newList = updateList(changeState);
    if (newList) {
      setField('paymentItems')(newList);
      row.current = null;
    }
  };

  return isMobile() ? (
    <Container>
      <SubTitleContainer>
        <SubTitle>Produto/Serviço</SubTitle>
        {!isView && (
          <Button id="add-item" text style={{ color: colors.primary40 }} onClick={createItem}>
            + Adicionar item
          </Button>
        )}
      </SubTitleContainer>
      {_list &&
        _list?.map((item, index) => (
          <StyledCardMobile key={`item-${index + 1}`}>
            <StyledCardRow>
              <StyledTextCard>Item</StyledTextCard>
              {!isView ? (
                <Input
                  type="text"
                  id="itemName"
                  size="middle"
                  name="name"
                  placeholder="Nome do item"
                  value={item.name}
                  onChange={e => onchangeInput(index, 'name', e.target.value, true)}
                  onBlur={() => handleBlur(false)}
                />
              ) : (
                <StyledTextCard>{item.name}</StyledTextCard>
              )}

              {touched.paymentItems && (errors[`paymentItems[${index}].name`] || errors.paymentItems) && (
                <ErrorStyled>{errors.paymentItems || errors[`paymentItems[${index}].name`]}</ErrorStyled>
              )}
            </StyledCardRow>
            <InlineItems>
              <StyledCardRow className="value">
                <StyledTextCard>Valor</StyledTextCard>
                {!isView ? (
                  <MaskedInput
                    placeholder="Valor"
                    type="currency"
                    onChange={e => onchangeInput(index, 'price', formatNumber(e.target.value))}
                    onBlur={() => handleBlur(true)}
                    value={formatCurrency(item.price, {
                      currencySymbol: 'R$ '
                    })}
                    maskOptions={{
                      prefix: 'R$',
                      thousandsSeparatorSymbol: '.',
                      allowDecimal: true
                    }}
                    style={{ height: '32px' }}
                  />
                ) : (
                  <Text>
                    {formatCurrency(item.price, {
                      currencySymbol: 'R$ '
                    })}
                  </Text>
                )}
                {touched.paymentItems && (errors[`paymentItems[${index}].price`] || errors.paymentItems) && (
                  <ErrorStyled>{errors.paymentItems || errors[`paymentItems[${index}].price`]}</ErrorStyled>
                )}
              </StyledCardRow>
              <StyledCardRow className="quantity">
                <StyledTextCard>Quantidade</StyledTextCard>
                {!isView ? (
                  <Input
                    type="number"
                    id="quantity"
                    size="middle"
                    name="quantity"
                    placeholder="Quantidade"
                    value={item.quantity}
                    disabled={isView}
                    onChange={e => onchangeInput(index, 'quantity', e.target.value, true)}
                    onBlur={() => handleBlur(false)}
                  />
                ) : (
                  <Text>{item.quantity}</Text>
                )}
                {touched.paymentItems && (errors[`paymentItems[${index}].quantity`] || errors.paymentItems) && (
                  <ErrorStyled>{errors.paymentItems || errors[`paymentItems[${index}].quantity`]}</ErrorStyled>
                )}
              </StyledCardRow>
            </InlineItems>
            <StyledCardRow>
              <StyledTextCard>Descrição</StyledTextCard>
              {!isView ? (
                <RichTextEditor
                  onChange={content => onchangeInput(index, 'description', content, true)}
                  onBlur={() => handleBlur(false)}
                  value={item.description}
                  height={100}
                  toolbar={false}
                  statusbar={false}
                  outputFormat="text"
                  disabled={isView}
                />
              ) : (
                <Text>{item.description}</Text>
              )}
            </StyledCardRow>
            <Line />
            <CardFooter>
              <StyledTextCard>
                Valor total:
                {formatCurrency(item.total, {
                  currencySymbol: 'R$ '
                })}
              </StyledTextCard>
              {!isView && _list?.length > 1 && (
                <StyledStrashIcon icon={faTrashAlt} text="Remover" size="lg" onClick={() => removeItem(item)} />
              )}
            </CardFooter>
          </StyledCardMobile>
        ))}
      {!isView && (
        <StyledButton id="add-item" type="primary" text onClick={createItem}>
          + Adicionar item
        </StyledButton>
      )}
      <ExtraValueContainer>
        <PaymentExtraValuesForm />
      </ExtraValueContainer>
    </Container>
  ) : (
    <Container>
      <SubTitle>Produto/Serviço</SubTitle>
      <TableFormItem>
        <StyledTableHeader>
          <Row>
            <Col md={isView ? 15 : 14} xs={12}>
              <Label htmlFor="installmentItem">Item</Label>
            </Col>
            <Col md={3} xs={12}>
              <Label htmlFor="installmentPrice">Valor</Label>
            </Col>
            <Col md={3} xs={12}>
              <Label htmlFor="installmentQuantity">Quantidade</Label>
            </Col>
            <Col md={3} xs={12} style={isView ? { display: 'flex', justifyContent: 'flex-end' } : {}}>
              <Label htmlFor="installmentTotalPrice">Valor total</Label>
            </Col>
            {!isView && (
              <Col md={1} xs={12}>
                <Label htmlFor="installmentAction">Ações</Label>
              </Col>
            )}
          </Row>
        </StyledTableHeader>
        <div>
          {_list &&
            _list?.map((item, index) => (
              <React.Fragment key={`item-${index + 1}`}>
                <Row gutter={[16, 16]} style={{ padding: `0 ${spaces.space2} ${spaces.space2} ${spaces.space2}` }}>
                  <StyledRow>
                    <Col md={isView ? 15 : 14} xs={12}>
                      {!isView ? (
                        <Input
                          type="text"
                          id="itemName"
                          size="middle"
                          name="itemName"
                          placeholder="Nome do item"
                          initialValue={item.name}
                          value={item.name || ''}
                          onChange={e => onchangeInput(index, 'name', e.target.value, true)}
                          onBlur={() => handleBlur(false)}
                        />
                      ) : (
                        <Text $color={colors.neutral40}>{item.name}</Text>
                      )}

                      {touched.paymentItems && (errors[`paymentItems[${index}].name`] || errors.paymentItems) && (
                        <ErrorStyled>{errors.paymentItems || errors[`paymentItems[${index}].name`]}</ErrorStyled>
                      )}
                    </Col>
                    <Col md={3} xs={12}>
                      {!isView ? (
                        <MaskedInput
                          type="currency"
                          onChange={e => onchangeInput(index, 'price', formatNumber(e.target.value))}
                          onBlur={() => handleBlur(true)}
                          value={formatCurrency(item.price, {
                            currencySymbol: 'R$ '
                          })}
                          maskOptions={{
                            prefix: 'R$',
                            thousandsSeparatorSymbol: '.',
                            allowDecimal: true
                          }}
                          style={{ height: '32px' }}
                        />
                      ) : (
                        <Text>
                          {formatCurrency(item.price, {
                            currencySymbol: 'R$ '
                          })}
                        </Text>
                      )}

                      {touched.paymentItems && (errors[`paymentItems[${index}].price`] || errors.paymentItems) && (
                        <ErrorStyled>{errors.paymentItems || errors[`paymentItems[${index}].price`]}</ErrorStyled>
                      )}
                    </Col>
                    <Col md={3} xs={12}>
                      {!isView ? (
                        <Input
                          type="number"
                          id="quantity"
                          size="middle"
                          name="quantity"
                          placeholder="Quantidade"
                          value={item.quantity}
                          disabled={isView}
                          onChange={e => onchangeInput(index, 'quantity', e.target.value, true)}
                          onBlur={() => handleBlur(false)}
                        />
                      ) : (
                        <Text>{item.quantity}</Text>
                      )}

                      {touched.paymentItems && errors[`paymentItems[${index}].quantity`] && (
                        <ErrorStyled>{errors[`paymentItems[${index}].quantity`]}</ErrorStyled>
                      )}
                    </Col>
                    <Col md={3} xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      {!isView ? (
                        <Input
                          id="total"
                          size="middle"
                          name="total"
                          placeholder="R$ 0,00"
                          disabled
                          value={formatCurrency(item.total, {
                            currencySymbol: 'R$ '
                          })}
                        />
                      ) : (
                        <Text>
                          {formatCurrency(item.total, {
                            currencySymbol: 'R$ '
                          })}
                        </Text>
                      )}
                    </Col>

                    {!isView && _list?.length > 1 && (
                      <Col md={1} xs={12}>
                        <StyledStrashIcon size="lg" text="Remover" icon={faTrashAlt} onClick={() => removeItem(item)} />
                      </Col>
                    )}
                  </StyledRow>
                  <Col md={24} xs={12}>
                    {!isView ? (
                      <Input
                        type="text"
                        id="itemDescription"
                        size="middle"
                        name="itemDescription"
                        placeholder="Descrição do item"
                        value={item.description}
                        disabled={isView}
                        onChange={e => onchangeInput(index, 'description', e.target.value, true)}
                        onBlur={() => handleBlur(false)}
                      />
                    ) : (
                      <Text>{item.description}</Text>
                    )}
                  </Col>
                </Row>
                {index !== _list.length - 1 && <Line />}
              </React.Fragment>
            ))}
        </div>
      </TableFormItem>
      <ExtraContent>
        <div>
          {!isView && (
            <Button id="add-item" text type="primary" onClick={createItem}>
              + Adicionar item
            </Button>
          )}
        </div>
        <ExtraValueContainer>
          <PaymentExtraValuesForm />
        </ExtraValueContainer>
      </ExtraContent>
    </Container>
  );
};

export default PaymentFormItem;
