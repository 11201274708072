import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Drawer from './Drawer';
import Form from '../Form/Form';
import { companyCustomerMapping, companyCustomerSchema } from '../../lib/mapping/Form/companyCustomerSchema';
import useCRUD from '../../_Hooks/useCRUD';

const EditOrCreateCustomer = ({ onClose, idCustomer, setCompanyCustomer }) => {
  const { data, loading, handleGet, handleCreate, handleUpdate } = useCRUD({
    model: 'company-customer',
    immediatelyLoadData: false
  });

  useEffect(() => {
    if (idCustomer) {
      handleGet({ refetchPathOptions: `/${idCustomer}` });
    }
  }, [idCustomer]);

  const handleSubmit = submitData => {
    const updateOrCreate = idCustomer
      ? handleUpdate({
          updatePathOptions: `/${idCustomer}`,
          values: submitData
        })
      : handleCreate({ values: submitData, noLoading: true });
    updateOrCreate.then(resp => {
      if (resp.error) return;
      setCompanyCustomer && setCompanyCustomer(resp);
      onClose();
    });
  };

  return (
    !loading && (
      <Drawer
        title={`${idCustomer ? 'Editar' : 'Cadastrar'} cliente`}
        subtitle="Preencha todas as informações corretamente"
        open
        formId="customerForm"
        onClose={() => onClose()}
      >
        <Form
          id="customerForm"
          displayButtons={false}
          onSubmit={handleSubmit}
          mapping={companyCustomerMapping}
          schema={companyCustomerSchema}
          data={data || { isActive: true }}
        />
      </Drawer>
    )
  );
};

EditOrCreateCustomer.propTypes = {
  onClose: PropTypes.func,
  idCustomer: PropTypes.number,
  setCompanyCustomer: PropTypes.func
};

export default EditOrCreateCustomer;
