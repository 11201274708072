import styled from 'styled-components';
import { breakpoints, colors, fonts, spaces } from '../../styles/style';

const AnonymousContainer = styled.div`
  background-color: ${colors.primary40};
  min-height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-size: ${fonts.sizeLg};
    color: ${colors.white};
    font-weight: 500;
    margin-left: 15px;
    margin-right: 15px;
  }

  @media (max-width: ${breakpoints.tablet}) {
    height: 35px;

    span {
      font-size: ${fonts.sizeMd};
      padding: ${spaces.space1} 0;
    }
  }
`;

const StyledButton = styled.button`
  text-decoration: underline;
  margin-left: 0px;
  cursor: pointer;
  margin-right: ${spaces.space0};
  background-color: transparent;
  border: 0px;
  font-weight: 500;
`;

export { AnonymousContainer, StyledButton };
