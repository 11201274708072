import { faEye, faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { updateUser } from '../../store/auth/actions/action';
import { saveRefurbish } from '../../store/refurbish/actions/action';
import Button from '../Button/Button';
import { colors, fonts } from '../../styles/style';
import useViewport from '../../_Hooks/useViewport';

const Container = styled.div`
  background-color: ${colors.primary40};
  height: ${props => (props.isMobile ? '35px' : '50px')};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-size: ${fonts.sizeLg};
    color: ${colors.white};
    font-weight: 500;
    margin-left: 15px;
    margin-right: 15px;
  }
`;

const CustomerViewAlert = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const refurbish = useSelector(state => state.refurbishReducer);
  const { user } = useSelector(state => state.authReducer);
  const { userType: userTypeEnum } = useSelector(state => state.setup.enums) || {};
  const { isMobile } = useViewport(window.innerWidth);
  const handleClick = () => {
    dispatch(saveRefurbish({}));
    dispatch(
      updateUser({ ...user, userType: userTypeEnum.provider.value, isCustomerView: false, type: 'profissional' })
    );
    history.push(`/profissional/projetos/perfil/${refurbish.id}/geral`);
  };
  return user.isCustomerView ? (
    <Container isMobile={isMobile()}>
      <FontAwesomeIcon icon={faEye} color={colors.white} size="lg" />
      {!isMobile() ? (
        <span>
          Você está visualizando exatamente como seu cliente vê. Saia desse modo para poder fazer alterações no projeto
        </span>
      ) : (
        <span>Visão do cliente</span>
      )}

      <Button
        type="primary"
        primaryBorderColor={!isMobile() ? 'white' : 'primary40'}
        noPadding={isMobile()}
        size={2}
        onClick={handleClick}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
        <div className="hideMobile">Sair deste modo</div>
      </Button>
    </Container>
  ) : null;
};

export default CustomerViewAlert;
