import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { Heading, Spacer, Text } from '@homehero/hero-style';
import RichTextEditor from '../RichTextEditor/RichTextEditor';

// components
import AttachmentList from '../List/AttachmentList';
import FileUpload from '../File/FileUpload';

import { AttachmentsFirstSection } from './ItemAttachmentContent.style';
import { colors, spaces } from '../../styles/style';

const ItemAttachmentContent = ({
  data,
  setObservation,
  setFiles,
  readOnly,
  idRefurbish,
  textEditor = true,
  hasDescription = true,
  maxSize = '50mb'
}) => {
  const { files = [], observation } = data;

  const handleAddFile = newFiles => {
    setFiles(prev => [...prev, ...newFiles]);
  };

  return (
    <>
      {!readOnly ? (
        <>
          <AttachmentsFirstSection>
            <strong>Envie arquivos</strong>
            {hasDescription && <p>Envie arquivos como orçamentos, notas fiscais e o que precisar.</p>}
            <div>
              <FileUpload
                style={{ marginTop: 0, marginLeft: `-${spaces.space2}` }}
                id="file-input"
                onChange={handleAddFile}
                multiple
                showGallery={false}
                text="Adicionar documento"
                listType={null}
                buttonProps={{ type: 'primary' }}
                fullWidth
                noPadding
              />
              <span>(Tamanho máximo do arquivo: {maxSize})</span>
            </div>

            <AttachmentList
              style={{ borderTop: `1px solid ${colors.neutral90}`, paddingTop: spaces.space2 }}
              preventUpload
              files={files}
              setFiles={setFiles}
              idRefurbish={idRefurbish}
            />

            {textEditor && (
              <strong
                style={{
                  marginBottom: spaces.space1,
                  paddingTop: spaces.space2,
                  borderTop: `1px solid ${colors.neutral90}`
                }}
              >
                Observações:
              </strong>
            )}
          </AttachmentsFirstSection>
          {textEditor && (
            <RichTextEditor
              onChange={setObservation}
              value={observation}
              height={100}
              toolbar={false}
              statusbar={false}
              outputFormat="html"
            />
          )}
        </>
      ) : (
        <>
          <AttachmentList
            preventUpload
            files={files}
            setFiles={setFiles}
            readOnly={readOnly}
            idRefurbish={idRefurbish}
          />
          <Spacer myLg={2} myMd={2} mySm={1} myxs={1}>
            {observation && <Heading title="Observação" level={6} />}
          </Spacer>
          <div>{observation ? <Text>{parse(observation)}</Text> : ''}</div>
        </>
      )}
    </>
  );
};

ItemAttachmentContent.propTypes = {
  data: PropTypes.instanceOf(Object),
  setFiles: PropTypes.func,
  readOnly: PropTypes.bool,
  idRefurbish: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setObservation: PropTypes.func,
  textEditor: PropTypes.bool,
  hasDescription: PropTypes.bool,
  maxSize: PropTypes.string
};

export default ItemAttachmentContent;
