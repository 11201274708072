import styled from 'styled-components';
import { Typography, Col } from 'antd';

import { fonts, colors, breakpoints } from '../../styles/style';

const { Text } = Typography;

const StyledText = styled(Text)`
  color: ${colors.neutral50};
  font-size: ${props => (props.size ? fonts[props.size] : null)};
  padding-top: 8px;
`;

const ColMarginRight = styled(Col)`
  @media (min-width: ${breakpoints.desktop}) {
    padding-right: 8px;
  }
`;

const ColMarginLeft = styled(Col)`
  @media (min-width: ${breakpoints.desktop}) {
    padding-left: 8px;
  }
`;

const ColMargin = styled(Col)`
  @media (min-width: ${breakpoints.desktop}) {
    padding: 8px;
  }
`;

export { StyledText, ColMarginRight, ColMarginLeft, ColMargin };
