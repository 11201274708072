import * as Yup from 'yup';

const name = Yup.string()
  .strict(false)
  .trim()
  .min(3, 'O campo deve ter pelo menos 3 caracteres')
  .nullable()
  .required('Campo obrigatório');

const text = Yup.string()
  .strict(false)
  .trim()
  .nullable();

const number = Yup.number().nullable();
const price = Yup.number()
  .typeError('O valor deve ser numérico')
  .moreThan(0, 'O Valor deve ser diferente de zero')
  .required('Campo obrigatório');

const email = Yup.string()
  .email('E-mail inválido')
  .nullable();
const phone = Yup.string()
  .test({
    message: 'Telefone inválido',
    test: value => {
      const len = value ? value.replace(/\D+/g, '').length : 0;
      return len >= 10 || len === 0;
    }
  })
  .nullable();
const website = Yup.string()
  .test({
    message: 'O site não está no formato correto',
    test: value => {
      if (!value) {
        return true;
      }
      const pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$',
        'i'
      ); // fragment locator
      return pattern.test(value);
    }
  })
  .nullable();

const date = Yup.date({ accept: [''] })
  .transform((curr, orig) => (orig === '' ? null : curr))
  .nullable();

const link = Yup.string()
  .nullable()
  .test({
    message: 'A URL informada não está no formato correto',
    test: value => {
      if (!value) return true;

      const pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$',
        'i'
      ); // fragment locator
      return pattern.test(value);
    }
  });

const idReference = Yup.number()
  .nullable()
  .required('Campo obrigatório');

const idReferenceNull = Yup.number().nullable();

const isActive = Yup.bool().nullable();

const isWpp = Yup.bool().default(false);

const password = Yup.string()
  .nullable()
  .required('Campo obrigatório')
  .min(8, 'Senha deve ter pelo menos 8 caracteres')
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
    'Senha deve conter pelo menos uma letra maíuscula, uma letra minúscula e um número'
  );

const passwordConditional = Yup.string().when('id', (id, _schema) =>
  id
    ? _schema
    : _schema
        .required('Campo obrigatório')
        .nullable()
        .min(8, 'Senha deve ter no mínimo 8 caracteres')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
          'Senha deve conter pelo menos uma letra maíuscula, uma letra minúscula e um número'
        )
);

const confirmNewPassword = Yup.string()
  .min(8)
  .nullable()
  .required('Campo obrigatório')
  .oneOf([Yup.ref('newPassword'), null], 'Confirmação de senha errada');

const address = {
  zipcode: Yup.string().nullable(),
  street: Yup.string().nullable(),
  number: Yup.string().nullable(),
  complement: Yup.string().nullable(),
  state: Yup.string().nullable(),
  city: Yup.string().nullable()
};

const installments = Yup.array()
  .of(
    Yup.object().shape({
      price,
      description: Yup.string().required('Campo obrigatório'),
      percentage: Yup.number()
        .moreThan(0, 'O valor não pode zero')
        .required('Campo obrigatório'),
      dueDate: date.required('Campo obrigatório')
    })
  )
  .min(1, 'Adicione pelo menos uma parcela');

const paymentTypes = Yup.array().min(1, 'É obrigatório selecionar uma forma de pagamento');

export {
  name,
  email,
  date,
  link,
  phone,
  isWpp,
  text,
  website,
  idReference,
  isActive,
  password,
  passwordConditional,
  confirmNewPassword,
  number,
  price,
  idReferenceNull,
  address,
  installments,
  paymentTypes
};
