import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Button } from '@homehero/hero-style';
import { Typography } from 'antd';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';
import { hasPermission } from '../../routes/Common/PrivateRoute';
import AddProductToProject from '../Modal/ItemToProjectModal';
import css from './AddToProjectButton.module.scss';
import { colors } from '../../styles/style';

const { Text } = Typography;

const AddToProjectButton = ({ data, onSelect, size = '1x', isDetail, text }) => {
  const [selectedItem, setSelectedItem] = useState(false);
  const { user } = useSelector(state => state.authReducer);
  const { plans, permissions } = useSelector(state => state.setup);

  const handleAdd = e => {
    e.stopPropagation();
    if (onSelect) {
      onSelect(data);
    } else {
      setSelectedItem(data);
    }
  };

  const handleSubmit = project => {
    toast.success(`O item foi salvo no projeto "${project}"`);
    setSelectedItem(null);
  };

  const classes = classNames({
    [css['c-add-project-button__search']]: onSelect,
    [css['c-add-project-button__default']]: !onSelect,
    [css['c-add-project-button__detail']]: isDetail
  });

  return (
    hasPermission(user, ['item'], plans, permissions) && (
      <>
        <div className={css['c-add-project-button']}>
          <Button id="add-item-project" type="primary" onClick={handleAdd} className={classes}>
            {text ? <Text style={{ color: colors.white }}>{text}</Text> : <FontAwesomeIcon icon={faPlus} size={size} />}
            {isDetail && <span>Adicionar em um projeto</span>}
          </Button>
        </div>
        {selectedItem && (
          <AddProductToProject
            idProduct={selectedItem.id}
            onSubmit={handleSubmit}
            onClose={() => setSelectedItem(null)}
          />
        )}
      </>
    )
  );
};

AddToProjectButton.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  size: PropTypes.string,
  onSelect: PropTypes.func,
  isDetail: PropTypes.bool,
  text: PropTypes.string
};

export default AddToProjectButton;
