import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Menu } from 'antd';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useViewport from '../../_Hooks/useViewport';
import { colors, spaces } from '../../styles/style';

const StyledMenu = styled(Menu)`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: ${spaces.space1};
  background-color: ${colors.white};
  width: ${props => (props.isMobile() ? '100px' : '80px')};
  box-shadow: 0 4px 8px 0 #00000024;
`;

const StyledColor = styled.div`
  border-radius: 50%;
  width: ${props => (props.isMobile() ? '16px' : '13px')};
  height: ${props => (props.isMobile() ? '16px' : '13px')};
  background-color: ${props => props.color || colors.white};
  margin: 4px;
  cursor: pointer;
  box-shadow: ${props => (!props.color ? `0 4px 8px 0 #00000024` : 'none')};
`;

const colorItems = ['#0094FF', '#E96291', '#FC8AF2', '#91E1AB', '#8D97FF', '#D64A4A', '#FFA113', '#32CCCC', '#FF866E'];

const ColorPickerDropDown = ({ color, onSelect, disabled = false, icon }) => {
  const { isMobile } = useViewport(window.innerWidth);
  const menu = (
    <StyledMenu isMobile={isMobile}>
      {colorItems.map(_color => (
        <Menu.Item key={_color} style={{ display: 'contents' }}>
          <StyledColor
            key={color}
            role="presentation"
            color={_color}
            isMobile={isMobile}
            onClick={() => onSelect(_color)}
          />
        </Menu.Item>
      ))}
    </StyledMenu>
  );

  return disabled ? (
    <>
      {icon ? (
        <FontAwesomeIcon icon={icon} color={color} />
      ) : (
        <StyledColor role="presentation" color={color} isMobile={isMobile} />
      )}
    </>
  ) : (
    <Dropdown overlay={menu} trigger={['click']}>
      <div>
        {icon ? (
          <FontAwesomeIcon icon={icon} color={color} />
        ) : (
          <StyledColor role="presentation" color={color} isMobile={isMobile} />
        )}
      </div>
    </Dropdown>
  );
};

ColorPickerDropDown.propTypes = {
  color: PropTypes.string,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  icon: PropTypes.instanceOf(Object)
};

export default ColorPickerDropDown;
